import { ApiV10ApplicationSendGeotabRequestEmailPostRequest, ApplicationApi } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useSendGeotabRequest = (): UseMutationResult<
  void,
  Error,
  ApiV10ApplicationSendGeotabRequestEmailPostRequest["applicationId"]
> => {
  const enrollmentApi = useApi(ApplicationApi);
  const mutation = useMutation<void, Error, ApiV10ApplicationSendGeotabRequestEmailPostRequest["applicationId"]>(
    async (applicationId: ApiV10ApplicationSendGeotabRequestEmailPostRequest["applicationId"]) => {
      await enrollmentApi.apiV10ApplicationSendGeotabRequestEmailPost({ applicationId });
    },
  );
  return mutation;
};
