import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const FailIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39999 12C5.39999 8.35019 8.35019 5.39999 12 5.39999C15.6498 5.39999 18.6 8.35019 18.6 12C18.6 15.6498 15.6498 18.6 12 18.6C8.35019 18.6 5.39999 15.6498 5.39999 12ZM14.3694 15.3L15.3 14.3694L12.9306 12L15.3 9.63059L14.3694 8.7L12 11.0694L9.63059 8.7L8.7 9.63059L11.0694 12L8.7 14.3694L9.63059 15.3L12 12.9306L14.3694 15.3Z"
      />
    </SvgIcon>
  );
};

export default FailIcon;
