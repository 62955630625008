/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum InsuranceProgramType {
  Sfa = "sfa",
  Other = "other",
}

export function InsuranceProgramTypeFromJSON(json: any): InsuranceProgramType {
  return InsuranceProgramTypeFromJSONTyped(json, false);
}

export function InsuranceProgramTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceProgramType {
  return json as InsuranceProgramType;
}

export function InsuranceProgramTypeToJSON(value?: InsuranceProgramType | null): any {
  return value as any;
}
