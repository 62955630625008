import { CardField, EditableDeletableCard, formatEinNumber } from "@chq/components";
import { RadiusOfOperation } from "@chq/enrollment-api";
import { LegalEntity, OperatingAuthorityStatus, PrimaryOperationType } from "@chq/policy-management-api";
import { Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  sectionLine: {
    borderBottom: `solid .063rem ${theme.palette.secondary.light}`,
    width: "100%",
    paddingBottom: "0.5rem",
    margin: 0,
  },
  belowSectionLine: {
    paddingTop: "0.5rem",
    textAlign: "center",
    width: "100%",
  },
  card: {
    paddingTop: "1rem",
  },
  container: {
    paddingTop: "0.75rem",
  },
  needHelp: {
    textTransform: "uppercase",
  },
  link: {
    fontWeight: "bold",
  },
}));
type Props = {
  name?: string | null;
  dotNumber?: string | null;
  ein?: string | null;
  mcNumber?: string | null;
  vehicleLocation?: string;
  radiusOfOperation?: RadiusOfOperation;
  entityType?: LegalEntity;
  operatingAuthStatus?: OperatingAuthorityStatus;
  primaryOperationType?: PrimaryOperationType;
};

const PmCompanyInfoCard: React.FC<Props> = ({
  name,
  dotNumber,
  ein,
  mcNumber,
  vehicleLocation,
  radiusOfOperation,
  entityType,
  operatingAuthStatus,
  primaryOperationType,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard
      variant="default"
      titleVariant="h2"
      titleComponent="h3"
      title={t("policy-management-page.company-info-card.title")}
      border={false}
      elevation={1}
      className={classes.card}
    >
      <Grid container className={classes.container}>
        <CardField id="name" label={t("policy-management-page.company-info-card.name")} values={[{ value: name }]} />
        <CardField
          id="dotNumber"
          label={t("policy-management-page.company-info-card.dot-number")}
          values={[{ value: dotNumber }]}
        />
        <CardField id="ein" label={t("company-info-card.ein")} values={[{ value: ein ? formatEinNumber(ein) : "" }]} />
        <CardField id="mcNumber" label={t("company-info-card.mc-number")} values={[{ value: mcNumber }]} />
        <CardField
          id="vehicleLocation"
          label={t("policy-management-page.company-info-card.vehicle-location")}
          values={[{ value: vehicleLocation }]}
        />
        <CardField
          id="radiusOfOperation"
          label={t(`policy-management-page.company-info-card.radius-of-operation`)}
          values={[{ value: t(`qualification-form.radius-operation.radius-options.${radiusOfOperation}`) }]}
        />
        <CardField id="entityType" label={t("company-info-card.entity-type")} values={[{ value: entityType }]} />
        <CardField
          id="operatingAuthStatus"
          label={t("company-info-card.operating-auth-status")}
          values={[{ value: operatingAuthStatus }]}
        />
        <CardField
          id="primaryOperationStatus"
          label={t("company-info-card.primary-operation-status")}
          values={[{ value: primaryOperationType }]}
          valueClass={classes.sectionLine}
          className={classes.sectionLine}
        />
        <Grid item className={classes.belowSectionLine}>
          <Typography variant="h4" component="h3" className={classes.needHelp}>
            {t("policy-management-page.company-info-card.need-help")}
          </Typography>
        </Grid>
        <Grid item className={classes.belowSectionLine}>
          <Typography component="p">
            <Trans i18nKey={"policy-management-page.company-info-card.contact"}>
              <Typography variant="body1" component="span">
                Contact our client success team at
              </Typography>
              <Link href={`mailto:info@carrierhq.com`} variant="body1" className={classes.link}>
                info@carrierhq.com
              </Link>
              <Typography variant="body1" component="span">
                or
              </Typography>
              <Link href={"tel:866-621-4145"} variant="body1" className={classes.link}>
                (866) 621-4145
              </Link>
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default PmCompanyInfoCard;
