/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ChangePasswordResponse,
  ChangePasswordResponseFromJSON,
  ChangePasswordResponseFromJSONTyped,
  ChangePasswordResponseToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface ChangePasswordResponseApiResponse
 */
export interface ChangePasswordResponseApiResponse {
  /**
   *
   * @type {ChangePasswordResponse}
   * @memberof ChangePasswordResponseApiResponse
   */
  data?: ChangePasswordResponse;
  /**
   *
   * @type {ErrorResponse}
   * @memberof ChangePasswordResponseApiResponse
   */
  error?: ErrorResponse;
}

export function ChangePasswordResponseApiResponseFromJSON(json: any): ChangePasswordResponseApiResponse {
  return ChangePasswordResponseApiResponseFromJSONTyped(json, false);
}

export function ChangePasswordResponseApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChangePasswordResponseApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : ChangePasswordResponseFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function ChangePasswordResponseApiResponseToJSON(value?: ChangePasswordResponseApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ChangePasswordResponseToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
