import { EditableDeletableCard } from "@chq/components";
import { Driver } from "@chq/enrollment-api";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  drivers: {
    display: "flex",
    whiteSpace: "pre-wrap",
  },
  title: {
    paddingTop: "1rem",
  },
}));

type Props = {
  drivers: Driver[];
};

const DriversCountCard: React.FC<Props> = ({ drivers }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard
      title={t("drivers-count-card.title", { numberOfDrivers: drivers.length })}
      titleVariant="h2"
      titleComponent="h4"
      variant="default"
      className={classes.title}
      border={false}
    >
      <div className={classes.drivers}>
        {drivers.length > 0 && (
          <Typography variant="h3">
            {drivers.map((driver) => `${driver.firstName} ${driver.lastName}`).join(", ")}
          </Typography>
        )}
      </div>
    </EditableDeletableCard>
  );
};

export default DriversCountCard;
