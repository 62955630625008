import { NextButton } from "@chq/components";
import { Button, CircularProgress, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useHistory } from "react-router";
import { useGetApplicationReview } from "../../../data/enrollment";
import thumbsUpAnimation from "../../../lotties/thumbs-up.json";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";
import QuoteSavedPageContent from "../components/quote-saved-page-content";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "0rem",
  },
  submitButton: {
    minHeight: "56px",
  },
  submitButtonContainer: {
    padding: "1.125rem 0rem",
  },
  textCenter: {
    textAlign: "center",
    maxWidth: "600px",
  },
  congratulationsText: {
    color: theme.palette.success.main,
  },
  containerMargin: {
    marginTop: "1.5rem",
  },
  downloadButton: {
    ...theme.typography.h4,
    margin: "1rem 0 0",
    minHeight: "40px",
    backgroundColor: theme.palette.secondary.light,
  },
  buttonContainer: {
    padding: "0.625rem 0rem 0.375rem",
    margin: ".25rem auto",
  },
}));

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: thumbsUpAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const QuoteBoundPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { data: applicationReview, isLoading: isLoadingApplication } = useGetApplicationReview(
    RouteNames.policyManagementHome,
  );
  const history = useHistory();

  return (
    <QuoteSavedPageContent
      bound={true}
      containerClass={classes.container}
      applicationReview={applicationReview}
      isLoadingApplication={isLoadingApplication}
      topComponent={
        <>
          <Grid item xs={12}>
            <Lottie options={defaultOptions} height={125} width={125} />
          </Grid>
          <Grid item className={classes.containerMargin}>
            <Typography variant={"h2"} className={classNames(classes.congratulationsText, classes.textCenter)}>
              {t("quote-bound-page.congratulations-text")}
            </Typography>
          </Grid>
          <Grid item className={classes.containerMargin}>
            <Typography variant="h3" className={classNames(classes.textCenter)}>
              <strong>{t("quote-bound-page.next-steps")}</strong>
            </Typography>
          </Grid>
          <Grid item className={classes.containerMargin}>
            <Typography variant="h4" className={classes.textCenter}>
              <Trans i18nKey={"quote-bound-page.question-text"}>
                <Link href="mailto:SFARRG@aon.com">SFARRGF@aon.com</Link>
                <Link href="tel:800-492-5756">800-492-5756</Link>
              </Trans>
            </Typography>
          </Grid>
          <Grid item md={8} lg={5}>
            <NextButton
              fullWidth
              variant="contained"
              className={classes.containerMargin}
              disabled={isLoadingApplication}
              onClick={() => {
                history.push(routes.policyManagement.dashboard.path);
              }}
            >
              {isLoadingApplication ? (
                <CircularProgress
                  color="inherit"
                  size="2rem"
                  title="circularProgress"
                  aria-label={t("common.circular-progress-aria-label")}
                />
              ) : (
                t("quote-bound-page.button-text")
              )}
            </NextButton>
          </Grid>
        </>
      }
      bottomComponent={
        <Grid container item xs={12} spacing={1} className={classes.buttonContainer} justify="center">
          <Grid item xs={6} md={4} lg={3}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              className={classes.downloadButton}
              onClick={() => {
                history.push(routes.policyManagement.documents.path);
              }}
            >
              {t("review-application-page.see-my-documents")}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default QuoteBoundPage;
