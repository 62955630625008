import { ApplicationApi, AuthenticationApi } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export enum UserLoginType {
  none = "none",
  password = "password",
  magiclink = "magiclink",
}

export const useUserLoginType = (): UseMutationResult<UserLoginType, Error, string> => {
  const authenticationApi = useApi(AuthenticationApi);
  const applicationApi = useApi(ApplicationApi);
  const mutation = useMutation<UserLoginType, Error, string>(async (email: string) => {
    const { data: applicationExist } = await applicationApi.apiV10ApplicationDoesApplicationExistGet({
      emailAddress: email,
    });
    if (!applicationExist?.doesApplicationExist) {
      return UserLoginType.none;
    }
    const { data: user, error } = await authenticationApi.apiV10AuthenticationUserIdPost({
      userIdRequest: { email },
    });
    if (error) {
      throw new Error(error.message || "");
    }
    const userStatus = await authenticationApi.apiV10AuthenticationUserStatusPost({
      userStatusRequest: { userId: user?.userId },
    });
    if (userStatus.error) {
      throw new Error(userStatus.error.message || "");
    }
    if (userStatus.data?.hasPassword) {
      return UserLoginType.password;
    } else {
      return UserLoginType.magiclink;
    }
  });
  return mutation;
};
