import { CardField, EditableDeletableCard, formatEinNumber } from "@chq/components";
import { LegalEntity, RadiusOfOperation } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.125rem 0.5rem 0rem",
  },
  cardFields: {
    paddingTop: "0.625rem",
  },
}));

type Props = {
  dotNumber?: string | null;
  legalEntity?: LegalEntity;
  einNumber?: string | null;
  radiusOfOperation?: RadiusOfOperation;
};

const CompanyDetailsCard: React.FC<Props> = ({ dotNumber, legalEntity, einNumber, radiusOfOperation }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard
      title={t("review-application-page.company-details-card.title")}
      variant="default"
      titleVariant="h2"
      titleComponent="h3"
      border={false}
      className={classes.container}
    >
      <Grid container className={classes.cardFields}>
        <CardField
          id={t("review-application-page.company-details-card.dot-number.id")}
          label={t("review-application-page.company-details-card.dot-number.label")}
          values={[
            {
              value: Boolean(dotNumber) ? dotNumber : t("review-application-page.company-details-card.required"),
              valueColor: Boolean(dotNumber) ? undefined : "error",
            },
          ]}
        />
        <CardField
          id={t("review-application-page.company-details-card.company-type.id")}
          label={t("review-application-page.company-details-card.company-type.label")}
          values={[{ value: legalEntity }]}
        />
        <CardField
          id={t("review-application-page.company-details-card.tax-id.id")}
          label={t("review-application-page.company-details-card.tax-id.label")}
          values={[{ value: einNumber ? formatEinNumber(einNumber) : "" }]}
        />
        <CardField
          id={t("review-application-page.company-details-card.radius-of-operation.id")}
          label={t("review-application-page.company-details-card.radius-of-operation.label")}
          values={[{ value: radiusOfOperation }]}
        />
      </Grid>
    </EditableDeletableCard>
  );
};

export default CompanyDetailsCard;
