import { ApplicationApi, FactoringApprovalStatus } from "@chq/enrollment-api";
import {
  FactoringApplicationApi,
  FactoringApplicationApiResponse,
  FactoringApplicationCreateRequest,
} from "@chq/factoring-api";
import { useMutation, UseMutationResult } from "react-query";
import { useCachedApplicationReview, useCachedFactoringApplication } from ".";
import { useApi } from "../useApi";
import { useFactoringApi } from "../useFactoringApi";

export const useCreateFactoringApplication = (): UseMutationResult<
  FactoringApplicationApiResponse,
  Error | unknown,
  FactoringApplicationCreateRequest
> => {
  const factoringApplicationApi = useFactoringApi(FactoringApplicationApi);
  const applicationApi = useApi(ApplicationApi);
  const { application, updateApplication } = useCachedApplicationReview();
  const { updateApplication: updateFactoringApplication } = useCachedFactoringApplication();
  const mutation = useMutation(async (request: FactoringApplicationCreateRequest) => {
    const factoringResponse = await factoringApplicationApi.factoringApiV10FactoringApplicationCreateFactoringApplicationPost(
      {
        factoringApplicationCreateRequest: request,
      },
    );
    const enrollmentResponse = await applicationApi.apiV10ApplicationPut({
      application: { ...application?.application, factoringApprovalStatus: FactoringApprovalStatus.Pending },
    });

    const { data: applicationReview } = await applicationApi.apiV10ApplicationGetApplicationReviewGet();

    if (factoringResponse.data) {
      updateFactoringApplication(factoringResponse.data);
    }

    if (enrollmentResponse.data) {
      updateApplication(applicationReview!);
    }

    return factoringResponse;
  });
  return mutation;
};
