/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ApplicationApiResponse,
  ApplicationApiResponseFromJSON,
  ApplicationApiResponseToJSON,
  BooleanApiResponseWithDefaults,
  BooleanApiResponseWithDefaultsFromJSON,
  BooleanApiResponseWithDefaultsToJSON,
} from "../models";

export interface ApiV10QuoteSendEmailGetRequest {
  businessId?: number;
}

/**
 *
 */
export class QuoteApi extends runtime.BaseAPI {
  /**
   * Creates a new Quote
   */
  async apiV10QuoteCreateGetRaw(): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Quote/create`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Creates a new Quote
   */
  async apiV10QuoteCreateGet(): Promise<ApplicationApiResponse> {
    const response = await this.apiV10QuoteCreateGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV10QuoteSendEmailGetRaw(
    requestParameters: ApiV10QuoteSendEmailGetRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponseWithDefaults>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.businessId !== undefined) {
      queryParameters["businessId"] = requestParameters.businessId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Quote/send-email`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseWithDefaultsFromJSON(jsonValue));
  }

  /**
   */
  async apiV10QuoteSendEmailGet(
    requestParameters: ApiV10QuoteSendEmailGetRequest,
  ): Promise<BooleanApiResponseWithDefaults> {
    const response = await this.apiV10QuoteSendEmailGetRaw(requestParameters);
    return await response.value();
  }
}
