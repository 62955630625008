import { OutlinedInput, OutlinedInputProps, Select, TextFieldProps } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import FormControl from "./form-control";

export type TextInputProps = Omit<TextFieldProps, "variant"> & {
  labelAdornment?: React.ReactNode;
  labelEmbellishment?: React.ReactNode;
};

const TextInput: React.FC<TextInputProps> = ({
  autoComplete,
  autoFocus = false,
  children,
  classes,
  className,
  color = "primary",
  defaultValue,
  disabled = false,
  error = false,
  FormHelperTextProps,
  fullWidth = false,
  helperText,
  hiddenLabel,
  id,
  InputLabelProps,
  inputProps,
  InputProps,
  inputRef,
  label,
  labelAdornment,
  multiline = false,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required = false,
  rows,
  rowsMax,
  select = false,
  SelectProps,
  type,
  value,
  labelEmbellishment,
  ...other
}) => {
  const InputMore: Partial<OutlinedInputProps> = {};

  if (InputLabelProps && typeof InputLabelProps.shrink !== "undefined") {
    InputMore.notched = InputLabelProps.shrink;
  }
  if (label) {
    const displayRequired = InputLabelProps?.required ?? required;
    InputMore.label = (
      <React.Fragment>
        {label}
        {displayRequired && "\u00a0*"}
      </React.Fragment>
    );
  }

  if (select) {
    // unset defaults from textbox inputs
    if (!SelectProps || !SelectProps.native) {
      InputMore.id = undefined;
    }
    InputMore["aria-describedby"] = undefined;
  }

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const InputComponent = OutlinedInput;
  const InputElement = (
    <InputComponent
      aria-describedby={helperTextId}
      autoComplete={autoComplete}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      multiline={multiline}
      name={name}
      rows={rows}
      rowsMax={rowsMax}
      type={type}
      value={value}
      id={id}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      inputProps={inputProps}
      {...InputMore}
      {...InputProps}
    />
  );
  return (
    <FormControl
      className={classNames(classes?.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      required={required}
      color={color}
      FormHelperTextProps={FormHelperTextProps}
      helperTextId={helperTextId}
      helperText={helperText}
      labelId={inputLabelId}
      label={label}
      labelAdornment={labelAdornment}
      labelEmbellishment={labelEmbellishment}
      id={id}
      InputLabelProps={InputLabelProps}
      {...other}
    >
      {select ? (
        <Select
          aria-describedby={helperTextId}
          id={id}
          labelId={inputLabelId}
          value={value}
          input={InputElement}
          {...SelectProps}
        >
          {children}
        </Select>
      ) : (
        InputElement
      )}
    </FormControl>
  );
};

export default TextInput;
