/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FactoringAddress,
  FactoringAddressFromJSON,
  FactoringAddressFromJSONTyped,
  FactoringAddressToJSON,
  FactoringCustomer,
  FactoringCustomerFromJSON,
  FactoringCustomerFromJSONTyped,
  FactoringCustomerToJSON,
  FactoringDocument,
  FactoringDocumentFromJSON,
  FactoringDocumentFromJSONTyped,
  FactoringDocumentToJSON,
  FactoringOwner,
  FactoringOwnerFromJSON,
  FactoringOwnerFromJSONTyped,
  FactoringOwnerToJSON,
} from "./";

/**
 *
 * @export
 * @interface FactoringApplication
 */
export interface FactoringApplication {
  /**
   *
   * @type {number}
   * @memberof FactoringApplication
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  contactFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  contactLastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  contactTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  contactEmail?: string | null;
  /**
   *
   * @type {number}
   * @memberof FactoringApplication
   */
  monthlyRevenue?: number;
  /**
   *
   * @type {number}
   * @memberof FactoringApplication
   */
  trucksInFleet?: number;
  /**
   *
   * @type {boolean}
   * @memberof FactoringApplication
   */
  currentlyFactoring?: boolean;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  currentFactoringCompany?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  businessName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  contactPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  einNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  dotNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplication
   */
  mcNumber?: string | null;
  /**
   *
   * @type {FactoringAddress}
   * @memberof FactoringApplication
   */
  businessAddress?: FactoringAddress;
  /**
   *
   * @type {FactoringDocument}
   * @memberof FactoringApplication
   */
  articlesOfIncorporation?: FactoringDocument;
  /**
   *
   * @type {FactoringDocument}
   * @memberof FactoringApplication
   */
  w9?: FactoringDocument;
  /**
   *
   * @type {FactoringDocument}
   * @memberof FactoringApplication
   */
  certificateOfInsurance?: FactoringDocument;
  /**
   *
   * @type {FactoringDocument}
   * @memberof FactoringApplication
   */
  driversLicense?: FactoringDocument;
  /**
   *
   * @type {Array<FactoringDocument>}
   * @memberof FactoringApplication
   */
  otherDocuments?: Array<FactoringDocument> | null;
  /**
   *
   * @type {Array<FactoringOwner>}
   * @memberof FactoringApplication
   */
  factoringOwners?: Array<FactoringOwner> | null;
  /**
   *
   * @type {Array<FactoringCustomer>}
   * @memberof FactoringApplication
   */
  factoringCustomers?: Array<FactoringCustomer> | null;
  /**
   *
   * @type {number}
   * @memberof FactoringApplication
   */
  ownerId?: number | null;
  /**
   *
   * @type {number}
   * @memberof FactoringApplication
   */
  plaidInfoId?: number;
  /**
   *
   * @type {number}
   * @memberof FactoringApplication
   */
  stripeInfoId?: number;
}

export function FactoringApplicationFromJSON(json: any): FactoringApplication {
  return FactoringApplicationFromJSONTyped(json, false);
}

export function FactoringApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FactoringApplication {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    contactFirstName: !exists(json, "contactFirstName") ? undefined : json["contactFirstName"],
    contactLastName: !exists(json, "contactLastName") ? undefined : json["contactLastName"],
    contactTitle: !exists(json, "contactTitle") ? undefined : json["contactTitle"],
    contactEmail: !exists(json, "contactEmail") ? undefined : json["contactEmail"],
    monthlyRevenue: !exists(json, "monthlyRevenue") ? undefined : json["monthlyRevenue"],
    trucksInFleet: !exists(json, "trucksInFleet") ? undefined : json["trucksInFleet"],
    currentlyFactoring: !exists(json, "currentlyFactoring") ? undefined : json["currentlyFactoring"],
    currentFactoringCompany: !exists(json, "currentFactoringCompany") ? undefined : json["currentFactoringCompany"],
    businessName: !exists(json, "businessName") ? undefined : json["businessName"],
    contactPhone: !exists(json, "contactPhone") ? undefined : json["contactPhone"],
    einNumber: !exists(json, "einNumber") ? undefined : json["einNumber"],
    dotNumber: !exists(json, "dotNumber") ? undefined : json["dotNumber"],
    mcNumber: !exists(json, "mcNumber") ? undefined : json["mcNumber"],
    businessAddress: !exists(json, "businessAddress") ? undefined : FactoringAddressFromJSON(json["businessAddress"]),
    articlesOfIncorporation: !exists(json, "articlesOfIncorporation")
      ? undefined
      : FactoringDocumentFromJSON(json["articlesOfIncorporation"]),
    w9: !exists(json, "w9") ? undefined : FactoringDocumentFromJSON(json["w9"]),
    certificateOfInsurance: !exists(json, "certificateOfInsurance")
      ? undefined
      : FactoringDocumentFromJSON(json["certificateOfInsurance"]),
    driversLicense: !exists(json, "driversLicense") ? undefined : FactoringDocumentFromJSON(json["driversLicense"]),
    otherDocuments: !exists(json, "otherDocuments")
      ? undefined
      : json["otherDocuments"] === null
      ? null
      : (json["otherDocuments"] as Array<any>).map(FactoringDocumentFromJSON),
    factoringOwners: !exists(json, "factoringOwners")
      ? undefined
      : json["factoringOwners"] === null
      ? null
      : (json["factoringOwners"] as Array<any>).map(FactoringOwnerFromJSON),
    factoringCustomers: !exists(json, "factoringCustomers")
      ? undefined
      : json["factoringCustomers"] === null
      ? null
      : (json["factoringCustomers"] as Array<any>).map(FactoringCustomerFromJSON),
    ownerId: !exists(json, "ownerId") ? undefined : json["ownerId"],
    plaidInfoId: !exists(json, "plaidInfoId") ? undefined : json["plaidInfoId"],
    stripeInfoId: !exists(json, "stripeInfoId") ? undefined : json["stripeInfoId"],
  };
}

export function FactoringApplicationToJSON(value?: FactoringApplication | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    contactFirstName: value.contactFirstName,
    contactLastName: value.contactLastName,
    contactTitle: value.contactTitle,
    contactEmail: value.contactEmail,
    monthlyRevenue: value.monthlyRevenue,
    trucksInFleet: value.trucksInFleet,
    currentlyFactoring: value.currentlyFactoring,
    currentFactoringCompany: value.currentFactoringCompany,
    businessName: value.businessName,
    contactPhone: value.contactPhone,
    einNumber: value.einNumber,
    dotNumber: value.dotNumber,
    mcNumber: value.mcNumber,
    businessAddress: FactoringAddressToJSON(value.businessAddress),
    articlesOfIncorporation: FactoringDocumentToJSON(value.articlesOfIncorporation),
    w9: FactoringDocumentToJSON(value.w9),
    certificateOfInsurance: FactoringDocumentToJSON(value.certificateOfInsurance),
    driversLicense: FactoringDocumentToJSON(value.driversLicense),
    otherDocuments:
      value.otherDocuments === undefined
        ? undefined
        : value.otherDocuments === null
        ? null
        : (value.otherDocuments as Array<any>).map(FactoringDocumentToJSON),
    factoringOwners:
      value.factoringOwners === undefined
        ? undefined
        : value.factoringOwners === null
        ? null
        : (value.factoringOwners as Array<any>).map(FactoringOwnerToJSON),
    factoringCustomers:
      value.factoringCustomers === undefined
        ? undefined
        : value.factoringCustomers === null
        ? null
        : (value.factoringCustomers as Array<any>).map(FactoringCustomerToJSON),
    ownerId: value.ownerId,
    plaidInfoId: value.plaidInfoId,
    stripeInfoId: value.stripeInfoId,
  };
}
