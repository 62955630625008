import { FactoringOwner } from "@chq/factoring-api";
import { FactoringBusinessDetailsProps as BusinessDetailsFormProps } from "../../components/factoring-business-details";
import { FactoringContactFormProps as BasicInformationFormProps } from "../../components/factoring-contact-form";
import { FormProps as OwnerInformationFormProps } from "../../components/factoring-owner-information-form";
import { Props as ShipperBrokerProps } from "../../components/factoring-shipper-broker-form-wrapper";

export enum FactoringForms {
  basicInformation = "basic-information",
  businessDetails = "business-details",
  ownerInformation = "owner-information",
  shipperBrokerInfo = "shipper-broker-info",
  documents = "documents",
  bankInformation = "bank-information",
}

export type FactoringFormsStateType = {
  [FactoringForms.basicInformation]: BasicInformationFormProps & {
    completed: boolean | undefined;
  };
  [FactoringForms.businessDetails]: BusinessDetailsFormProps & {
    completed: boolean | undefined;
  };
  [FactoringForms.ownerInformation]: OwnerInformationFormProps & {
    owners?: FactoringOwner[];
    completed: boolean | undefined;
  };
  [FactoringForms.shipperBrokerInfo]: ShipperBrokerProps & {
    completed: boolean | undefined;
  };
  [FactoringForms.documents]: {
    documents: string[];
    completed: boolean | undefined;
  };
  [FactoringForms.bankInformation]: { completed: boolean | undefined };
};

export type PaymentMethodFormStateType = {
  plaidFailed: boolean | undefined;
  addAccountDialogOpen: boolean;
  addAccountFormComplete: boolean;
  verifyAccountDialogOpen: boolean;
  verifyAccountFormComplete: boolean;
  accountSetUp: boolean;
};
