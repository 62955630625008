import { DocumentCard, NextButton, PreviousButton, YesNoValues } from "@chq/components";
import { BillingCycle, StripeAccountStatus } from "@chq/enrollment-api";
import { FactoringCustomer, State } from "@chq/factoring-api";
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Formik } from "formik";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import {
  PlaidLinkError,
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkStableEvent,
} from "react-plaid-link";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import AddBankAccountManualModal from "../../../components/add-bank-account-manual-modal";
import AddNewBankAccountConfirmationModal from "../../../components/add-new-bank-account-confirmation-modal";
import FactoringBankInformationForm from "../../../components/factoring-bank-information-form";
import FactoringBusinessDetailsForm, {
  Fields as BusinessDetailsFields,
} from "../../../components/factoring-business-details";
import FactoringBusinessDetailsCard from "../../../components/factoring-business-details-card";
import FactoringBasicInformationCard from "../../../components/factoring-contact-card";
import FactoringBasicInformationForm, {
  Fields as BasicInformationFields,
} from "../../../components/factoring-contact-form";
import FactoringDocumentsForm from "../../../components/factoring-documents-form";
import FactoringOwnerInformationCard from "../../../components/factoring-owner-information-card";
import FactoringOwnerInformationForm, {
  Fields as OwnerInformationFields,
} from "../../../components/factoring-owner-information-form";
import FactoringSection from "../../../components/factoring-section";
import FactoringShipperBrokerCard from "../../../components/factoring-shipper-broker-card";
import { Fields as ShipperBrokerFields, ShipperBroker } from "../../../components/factoring-shipper-broker-form";
import FactoringShipperBrokerForm from "../../../components/factoring-shipper-broker-form-wrapper";
import PaymentMethodForm, {
  Fields,
  PaymentMethodFormProps,
  useFormikConfig,
} from "../../../components/payment-method-form";
import VerifyBankAccountModal from "../../../components/verify-bank-account-modal";
import YourQuoteCard from "../../../components/your-quote-card";
import {
  useAddPlaidAccount,
  useCompleteFactoringApplication,
  useCreateFactoringApplication,
  useEditApplication,
  useEditFactoringApplication,
  useGetApplicationReview,
  useGetFactoringApplication,
  useGetPlaidToken,
  useSendBankInfoStripe,
  useVerifyStripeMicrodeposits,
} from "../../../data/enrollment";
import { useAddFactoringDocument } from "../../../data/enrollment/useAddFactoringDocument";
import { useDeleteFactoringDocument } from "../../../data/enrollment/useDeleteFactoringDocument";
import * as actions from "../../../data/payment-method-page/actions";
import { initialFactoringFormsState, reducer } from "../../../data/payment-method-page/reducer";
import { FactoringForms } from "../../../data/payment-method-page/types";
import paymentMethodTruckDesktop from "../../../images/payment-method-truck-desktop.png";
import paymentMethodTruck from "../../../images/payment-method-truck.png";
import { fullName } from "../../../utils/full-name";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  addNewBankDialog: {
    position: "absolute",
  },
  container: {
    padding: "1.5rem .875rem 1.5rem .875rem",
    margin: "0.5rem auto 0rem auto",
  },
  pageButtons: {
    marginTop: "3.25em",
    justifyContent: "center",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  quoteSection: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
  truckBackground: {
    backgroundImage: `url(${paymentMethodTruck})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    display: "flex",
    width: "22.5rem",
    height: "19rem",
    borderRadius: "4px",
    marginTop: "0.5rem",
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${paymentMethodTruckDesktop})`,
      width: "100%",
      backgroundPosition: "0% 50%",
      backgroundSize: "cover",
      height: "16.875rem",
      marginTop: "0rem",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundPosition: "40% 50%",
    },
  },
  truckTitle: {
    marginTop: "0.5rem",
    width: "18.75rem",
    paddingLeft: "1rem",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      marginLeft: "5%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90%",
      marginLeft: 0,
    },
  },
  truckSubtitle: {
    width: "8rem",
    marginLeft: "1.5rem",
    paddingBottom: "1.5rem",
  },
  factoringButton: {
    backgroundColor: theme.palette.success.main,
  },
  factoringButtonContainer: {
    alignSelf: "flex-end",
  },
  factoringCheckbox: {
    color: theme.palette.common.white,
    "&.Mui-checked": {
      color: theme.palette.common.white,
    },
  },
  orText: {
    textAlign: "center",
  },
  orContainer: {
    margin: "1.5rem 0rem",
  },
  factoringTitle: {
    marginTop: "1.5rem",
    marginBottom: "0.75rem",
  },
  checkIcon: {
    margin: "0.5rem",
  },
  mobileDialog: {
    "& .MuiDialog-container": {
      height: "inherit",
      position: "absolute",
      bottom: 0,
      width: "100%",
    },
    "& .MuiDialog-paperWidthSm": {
      minWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        minWidth: "50%",
      },
    },
    "& .MuiPaper-rounded": {
      [theme.breakpoints.up("sm")]: {
        borderRadius: "4px 4px 4px 4px",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "4px 4px 0px 0px",
      },
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "flex-end",
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
      },
    },
    "& .MuiDialog-paper": {
      margin: "0px",
      overflowX: "hidden",
      overflowY: "hidden",
    },
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        minWidth: "50%",
      },
    },
    "& .MuiPaper-rounded": {
      [theme.breakpoints.up("sm")]: {
        borderRadius: "4px 4px 4px 4px",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "4px 4px 0px 0px",
      },
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "flex-end",
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
      },
    },
    "& .MuiDialog-paper": {
      margin: "0px",
      overflowX: "hidden",
      overflowY: "hidden",
    },
  },
}));

const factoringCustomerMapper = (value: ShipperBroker): FactoringCustomer => {
  return {
    businessName: value[ShipperBrokerFields.name],
    monthlySales: parseInt(value[ShipperBrokerFields.sales]!),
    mcNumber: value[ShipperBrokerFields.mcNumber],
    customerAddress: {
      address1: value[ShipperBrokerFields.addressLineOne],
      address2: value[ShipperBrokerFields.addressLineTwo],
      state: value[ShipperBrokerFields.state] as State,
      city: value[ShipperBrokerFields.city],
      zip: value[ShipperBrokerFields.zip],
    },
  };
};

const yesOrNo = (b: boolean | null | undefined) => {
  if (b !== undefined && b !== null) {
    return b ? YesNoValues.yes : YesNoValues.no;
  } else {
    return undefined;
  }
};

const PaymentMethodPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const mobileWidth = useMediaQuery("(max-width: 600px)");
  const { data: applicationReview } = useGetApplicationReview(RouteNames.payment);
  const application = applicationReview?.application;
  const { mutate: editApplication, isLoading: isEditingApplication, isSuccess } = useEditApplication();
  const { data: factoringApplication } = useGetFactoringApplication();
  const {
    mutate: createFactoringApplication,
    isLoading: isCreatingFactoringApplication,
  } = useCreateFactoringApplication();
  const { mutate: editFactoringApplication, isLoading: isEditingFactoringApplication } = useEditFactoringApplication();
  const { mutate: addFactoringDocument, isLoading: isAddingFactoringDocument } = useAddFactoringDocument();
  const { mutate: deleteFactoringDocument, isLoading: isDeletingFactoringDocument } = useDeleteFactoringDocument();
  const {
    mutate: completeFactoringApplication,
    isLoading: isCompletingFactoringApplication,
  } = useCompleteFactoringApplication();
  const { mutate: addPlaidAccount, isLoading: isAddingPlaidAccount } = useAddPlaidAccount();
  const {
    mutate: sendBankInfoStripe,
    isLoading: isAddingStripeAccount,
    error: stripeAccountError,
  } = useSendBankInfoStripe();

  const {
    mutate: verifyStripeMicrodeposits,
    isLoading: isVerifyingMicrodeposits,
    error: microdepositsError,
  } = useVerifyStripeMicrodeposits();

  const { data: token } = useGetPlaidToken();
  const formikConfig = useFormikConfig({
    billingCycle: application?.billingCycle?.toLowerCase() || "",
  });

  const [addManualOpen, setAddManualOpen] = useState(false);
  const [verifyDepositsOpen, setVerifyDepositsOpen] = useState(false);
  const [stripeBankAccountNumber, setStripeBankAccountNumber] = useState<string>("");
  const [bankName, setBankName] = useState<string>("");
  const [stripeSuccess, setStripeSuccess] = useState<boolean>();

  const [factoringChecked, setFactoringChecked] = useState(false);
  const [stripeBankAccountAdded, setStripeBankAccountAdded] = useState(false);

  const [paymentChecked, setPaymentChecked] = useState(false);
  const [complete, setComplete] = useState(false);
  const [factoringFormsState, dispatch] = useReducer(reducer, initialFactoringFormsState);
  const mobileSpacing = useMediaQuery(theme.breakpoints.down("xs"));
  const [plaidSuccess, setPlaidSuccess] = useState<boolean | undefined>();

  const checkCompletedForms = () => {
    const formArray = Object.values(factoringFormsState);
    return formArray.every((form) => form.completed === true);
  };

  const hasFactoringApplication = Boolean(factoringApplication);
  const factoringBasicInfoCompleted = factoringFormsState[FactoringForms.basicInformation].completed;
  const factoringBusinessDetailsCompleted = factoringFormsState[FactoringForms.businessDetails].completed;
  const factoringOwnerInfoCompleted = factoringFormsState[FactoringForms.ownerInformation].completed;
  const factoringShipperBrokerCompleted = factoringFormsState[FactoringForms.shipperBrokerInfo].completed;
  const factoringDocumentsCompleted = factoringFormsState[FactoringForms.documents].completed;
  const factoringBankInfoCompleted = factoringFormsState[FactoringForms.bankInformation].completed;

  const paymentPlaidOnSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token, metadata) => {
      setPlaidSuccess(true);
      setComplete(true);
      addPlaidAccount({ publicToken: public_token, metadata });
    },
    [addPlaidAccount],
  );

  const factoringPlaidOnSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token, metadata) => {
      setPlaidSuccess(true);
      setComplete(true);
      dispatch(actions.completeBankInformationForm);
      addPlaidAccount({ publicToken: public_token, metadata });
    },
    [addPlaidAccount],
  );

  const plaidOnEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
      if (eventName === "ERROR") {
        setPlaidSuccess(false);
      }
    },
    [],
  );

  const plaidOnExit = useCallback<PlaidLinkOnExit>(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      setPlaidSuccess(false);
    },
    [],
  );

  const startStripeManualBank = () => {
    setAddManualOpen(true);
  };

  const startStripeVerifyDeposits = () => {
    setVerifyDepositsOpen(true);
  };

  const [newBankConfirmationDialog, setNewBankConfirmationDialog] = useState<boolean>(false);

  const onAddNewBankAccount = () => {
    setNewBankConfirmationDialog(true);
  };

  const startNewBankAccount = () => {
    setStripeBankAccountAdded(false);
    setPlaidSuccess(undefined);
    setComplete(false);
    setStripeSuccess(false);
    setNewBankConfirmationDialog(false);
  };

  useEffect(() => {
    const bankAccount = applicationReview?.bankAccount;
    const hasBankAccount = Boolean(bankAccount);
    const isFactoring = Boolean(factoringApplication);
    setFactoringChecked(isFactoring);
    setPaymentChecked(!isFactoring && hasBankAccount);
    setStripeBankAccountNumber(bankAccount?.accountNumberLast4 || "");
    setBankName(bankAccount?.bankName || "");
    setStripeBankAccountAdded(
      bankAccount?.accountStatus === StripeAccountStatus.New ||
        bankAccount?.accountStatus === StripeAccountStatus.Verified,
    );
    setPlaidSuccess(bankAccount?.accountStatus === StripeAccountStatus.Verified ? true : undefined);
    setStripeSuccess(bankAccount?.accountStatus === StripeAccountStatus.Verified);
    setComplete(hasBankAccount);
  }, [applicationReview, factoringApplication]);

  if (application?.insuranceProgram?.active === false) {
    return <Redirect to={routes.enrollment.quote.inactive.path} />;
  }

  if (isSuccess) {
    return <Redirect push to={routes.enrollment.review.path} />;
  }

  return (
    <Grid container item xs={12} lg={8} className={classes.container} justify="center" spacing={mobileSpacing ? 2 : 4}>
      {!application?.qualified && qualifyRedirect(application?.ineligibleReason || "")}
      <Grid item xs={12} sm={6}>
        <YourQuoteCard
          weeklyDownPayment={(application?.currentQuote?.rates?.weeklyDownPayment ?? 0).toString()}
          monthlyDownPayment={(application?.currentQuote?.rates?.monthlyDownPayment ?? 0).toString()}
          weeklyTotal={(application?.currentQuote?.rates?.weeklyRate ?? 0).toString()}
          monthlyTotal={(application?.currentQuote?.rates?.monthlyRate ?? 0).toString()}
          annualTotal={(application?.currentQuote?.rates?.annualizedRate ?? 0).toString()}
        />
      </Grid>
      <Grid container item xs={12} sm={6} justify="center">
        <Grid container item className={classes.truckBackground} justify="center">
          <Grid item>
            <Typography variant="h1" color="primary" className={classes.truckTitle}>
              {t("finish-application.payment-method-page.image-title")}
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography variant="h3" component="h2" className={classes.truckSubtitle}>
              {t("finish-application.payment-method-page.image-subtitle")}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.factoringButtonContainer}>
            <Button
              variant="contained"
              fullWidth
              className={classes.factoringButton}
              onClick={() => {
                setPaymentChecked((prevState) => {
                  return factoringChecked ? prevState : false;
                });
                setFactoringChecked((prevState) => {
                  return !prevState;
                });
              }}
            >
              {factoringChecked ? (
                <CheckBoxIcon className={classes.checkIcon} />
              ) : (
                <CheckBoxOutlineBlankIcon className={classes.checkIcon} />
              )}
              <Typography variant="h2">{t("finish-application.payment-method-page.factoring-button")}</Typography>
            </Button>
          </Grid>
        </Grid>
        {factoringChecked && token && (
          <Formik
            {...formikConfig}
            validationSchema={null}
            onSubmit={(values) => {
              editApplication({
                ...application,
                billingCycle: BillingCycle.Factoring,
              });
              completeFactoringApplication({
                ownerId: application?.ownerId,
              });
            }}
          >
            {(formik) => (
              <Grid container item direction="row">
                <Grid item className={classes.factoringTitle}>
                  <Typography variant="h2">{t("factoring.title")}</Typography>
                </Grid>
                <FactoringSection
                  title={t("factoring.contact-card.title")}
                  formStatus={factoringBasicInfoCompleted}
                  form={
                    <FactoringBasicInformationForm
                      firstName={factoringApplication?.contactFirstName || application?.business?.contactFirstName}
                      lastName={factoringApplication?.contactLastName || application?.business?.contactLastName}
                      contactTitle={factoringApplication?.contactTitle || ""}
                      contactEmail={factoringApplication?.contactEmail || application?.business?.contactEmail}
                      monthlyRevenue={factoringApplication?.monthlyRevenue}
                      truckCount={factoringApplication?.trucksInFleet}
                      currentFactoring={yesOrNo(factoringApplication?.currentlyFactoring)}
                      currentFactoringCompany={factoringApplication?.currentFactoringCompany || ""}
                      isLoading={isCreatingFactoringApplication || isEditingFactoringApplication}
                      onSubmit={(values) => {
                        if (hasFactoringApplication) {
                          editFactoringApplication(
                            {
                              ...factoringApplication,
                              contactFirstName: values[BasicInformationFields.firstName],
                              contactLastName: values[BasicInformationFields.lastName],
                              contactTitle: values[BasicInformationFields.contactTitle],
                              contactEmail: values[BasicInformationFields.contactEmail],
                              monthlyRevenue: values[BasicInformationFields.monthlyRevenue],
                              trucksInFleet: values[BasicInformationFields.truckCount],
                              currentlyFactoring:
                                values[BasicInformationFields.currentFactoring] === YesNoValues.yes ? true : false,
                              currentFactoringCompany:
                                values[BasicInformationFields.currentFactoring] === YesNoValues.no
                                  ? null
                                  : values[BasicInformationFields.currentFactoringCompany],
                            },
                            {
                              onSuccess: () => {
                                dispatch(actions.completeBasicInformationForm(values));
                                if (factoringBusinessDetailsCompleted === undefined) {
                                  dispatch(actions.startBusinessDetailsForm);
                                }
                              },
                            },
                          );
                        } else {
                          createFactoringApplication(
                            {
                              contactFirstName: values[BasicInformationFields.firstName],
                              contactLastName: values[BasicInformationFields.lastName],
                              contactTitle: values[BasicInformationFields.contactTitle],
                              contactEmail: values[BasicInformationFields.contactEmail],
                              monthlyRevenue: values[BasicInformationFields.monthlyRevenue],
                              trucksInFleet: values[BasicInformationFields.truckCount],
                              currentlyFactoring:
                                values[BasicInformationFields.currentFactoring] === YesNoValues.yes ? true : false,
                              currentFactoringCompany:
                                values[BasicInformationFields.currentFactoring] === YesNoValues.no
                                  ? null
                                  : values[BasicInformationFields.currentFactoringCompany],
                            },
                            {
                              onSuccess: () => {
                                dispatch(actions.completeBasicInformationForm(values));
                                if (factoringBusinessDetailsCompleted === undefined) {
                                  dispatch(actions.startBusinessDetailsForm);
                                }
                              },
                            },
                          );
                        }
                      }}
                    />
                  }
                  card={
                    <FactoringBasicInformationCard
                      name={fullName(
                        factoringApplication?.contactFirstName || "",
                        factoringApplication?.contactLastName || "",
                      )}
                      title={factoringApplication?.contactTitle || ""}
                      email={factoringApplication?.contactEmail || ""}
                      monthlyRevenue={factoringApplication?.monthlyRevenue?.toString() || ""}
                      truckCount={factoringApplication?.trucksInFleet || 0}
                      currentFactoring={yesOrNo(factoringApplication?.currentlyFactoring) || ""}
                      factoringCompany={factoringApplication?.currentFactoringCompany || ""}
                      onEdit={() => dispatch(actions.startBasicInformationForm)}
                    />
                  }
                />
                <FactoringSection
                  title={t("factoring.business-details.title")}
                  formStatus={factoringBusinessDetailsCompleted}
                  form={
                    <FactoringBusinessDetailsForm
                      businessName={factoringApplication?.businessName || application?.business?.businessName}
                      phoneNumber={factoringApplication?.contactPhone || ""}
                      ein={factoringApplication?.einNumber || application?.business?.einNumber}
                      dotNumber={factoringApplication?.dotNumber || application?.business?.dotNumber}
                      mcNumber={factoringApplication?.mcNumber || application?.business?.mcNumber}
                      addressLineOne={
                        factoringApplication?.businessAddress?.address1 || application?.business?.address?.address1
                      }
                      addressLineTwo={
                        factoringApplication?.businessAddress?.address2 || application?.business?.address?.address2
                      }
                      city={factoringApplication?.businessAddress?.city || application?.business?.address?.city}
                      state={factoringApplication?.businessAddress?.state || application?.business?.address?.state}
                      zip={factoringApplication?.businessAddress?.zip || application?.business?.address?.zip}
                      isLoading={isEditingFactoringApplication}
                      onSubmit={(values) => {
                        editFactoringApplication(
                          {
                            ...factoringApplication,
                            businessName: values[BusinessDetailsFields.businessName],
                            contactPhone: values[BusinessDetailsFields.phoneNumber],
                            einNumber: values[BusinessDetailsFields.ein],
                            dotNumber: values[BusinessDetailsFields.dotNumber],
                            mcNumber: values[BusinessDetailsFields.mcNumber],
                            businessAddress: {
                              ...factoringApplication?.businessAddress,
                              address1: values[BusinessDetailsFields.addressLineOne],
                              address2: values[BusinessDetailsFields.addressLineTwo],
                              city: values[BusinessDetailsFields.city],
                              state: values[BusinessDetailsFields.state] as State,
                              zip: values[BusinessDetailsFields.zip],
                            },
                          },
                          {
                            onSuccess: () => {
                              dispatch(actions.completeBusinessDetailsForm(values));
                              if (factoringOwnerInfoCompleted === undefined) {
                                dispatch(actions.startOwnerInformationForm);
                              }
                            },
                          },
                        );
                      }}
                    />
                  }
                  card={
                    <FactoringBusinessDetailsCard
                      name={factoringApplication?.businessName || ""}
                      phone={factoringApplication?.contactPhone || ""}
                      ein={factoringApplication?.einNumber || ""}
                      dotNumber={factoringApplication?.dotNumber || ""}
                      mcNumber={factoringApplication?.mcNumber || ""}
                      addressLineOne={factoringApplication?.businessAddress?.address1 || ""}
                      addressLineTwo={factoringApplication?.businessAddress?.address2 || ""}
                      city={factoringApplication?.businessAddress?.city || ""}
                      state={factoringApplication?.businessAddress?.state || ""}
                      zip={factoringApplication?.businessAddress?.zip || ""}
                      onEdit={() => dispatch(actions.startBusinessDetailsForm)}
                    />
                  }
                />
                <FactoringSection
                  title={t("factoring.owner-information-form.title")}
                  formStatus={factoringOwnerInfoCompleted}
                  form={
                    <FactoringOwnerInformationForm
                      firstName={
                        (factoringApplication?.factoringOwners || [])[0]?.firstName ||
                        application?.business?.contactFirstName
                      }
                      lastName={
                        (factoringApplication?.factoringOwners || [])[0]?.lastName ||
                        application?.business?.contactLastName
                      }
                      emailAddress={
                        (factoringApplication?.factoringOwners || [])[0]?.ownerEmail ||
                        application?.business?.contactEmail
                      }
                      signorParty={(factoringApplication?.factoringOwners || [])[0]?.signorParty}
                      secondOwner={Boolean((factoringApplication?.factoringOwners || []).length > 1)}
                      firstName2={(factoringApplication?.factoringOwners || [])[1]?.firstName || ""}
                      lastName2={(factoringApplication?.factoringOwners || [])[1]?.lastName || ""}
                      emailAddress2={(factoringApplication?.factoringOwners || [])[1]?.ownerEmail || ""}
                      signorParty2={(factoringApplication?.factoringOwners || [])[1]?.signorParty}
                      isLoading={isEditingFactoringApplication}
                      onSubmit={(values) => {
                        editFactoringApplication(
                          {
                            ...factoringApplication,
                            factoringOwners: [
                              {
                                firstName: values[OwnerInformationFields.firstName],
                                lastName: values[OwnerInformationFields.lastName],
                                ownerEmail: values[OwnerInformationFields.emailAddress],
                                signorParty: values[OwnerInformationFields.signorParty],
                              },
                              ...(Boolean(values[OwnerInformationFields.secondOwner])
                                ? [
                                    {
                                      firstName: values[OwnerInformationFields.firstName2],
                                      lastName: values[OwnerInformationFields.lastName2],
                                      ownerEmail: values[OwnerInformationFields.emailAddress2],
                                      signorParty: values[OwnerInformationFields.signorParty2],
                                    },
                                  ]
                                : []),
                            ],
                          },
                          {
                            onSuccess: () => {
                              dispatch(actions.completeOwnerInformationForm(values));
                              if (factoringShipperBrokerCompleted === undefined) {
                                dispatch(actions.startShipperBrokerInfo);
                              }
                            },
                          },
                        );
                      }}
                    />
                  }
                  card={
                    <FactoringOwnerInformationCard
                      owners={factoringApplication?.factoringOwners || []}
                      onEdit={() => dispatch(actions.startOwnerInformationForm)}
                    />
                  }
                />
                <FactoringSection
                  title={t("factoring.shipper-broker-form-wrapper.title")}
                  formStatus={factoringShipperBrokerCompleted}
                  form={
                    <FactoringShipperBrokerForm
                      hauling={
                        factoringApplication?.factoringCustomers === undefined
                          ? undefined
                          : yesOrNo(Boolean(factoringApplication?.factoringCustomers?.length))
                      }
                      shipperBrokers={factoringApplication?.factoringCustomers || []}
                      isLoading={isEditingFactoringApplication}
                      onSubmit={(values) => {
                        editFactoringApplication(
                          {
                            ...factoringApplication,
                            factoringCustomers: values.shipperBrokers.map((shipperBroker) =>
                              factoringCustomerMapper(shipperBroker),
                            ),
                          },
                          {
                            onSuccess: () => {
                              dispatch(actions.completeShipperBrokerInfo(values));
                              if (factoringDocumentsCompleted === undefined) {
                                dispatch(actions.startDocumentsForm);
                              }
                            },
                          },
                        );
                      }}
                      onNoShipperBrokersSubmit={() => {
                        editFactoringApplication(
                          {
                            ...factoringApplication,
                            factoringCustomers: [],
                          },
                          {
                            onSuccess: () => {
                              dispatch(actions.completeEmptyShipperBrokerInfo);
                              if (factoringDocumentsCompleted === undefined) {
                                dispatch(actions.startDocumentsForm);
                              }
                            },
                          },
                        );
                      }}
                    />
                  }
                  card={
                    <FactoringShipperBrokerCard
                      shipperBrokers={factoringApplication?.factoringCustomers || []}
                      onEdit={() => dispatch(actions.startShipperBrokerInfo)}
                    />
                  }
                />
                <FactoringSection
                  title={t("factoring.documents-form.title")}
                  formStatus={factoringDocumentsCompleted}
                  form={
                    <FactoringDocumentsForm
                      articlesOfIncorporation={factoringApplication?.articlesOfIncorporation}
                      w9={factoringApplication?.w9}
                      driversLicense={factoringApplication?.driversLicense}
                      additionalDocuments={factoringApplication?.otherDocuments || []}
                      onAddDocument={(type, file) => {
                        addFactoringDocument({ factoringDocumentType: type, file });
                      }}
                      onDeleteDocument={(type, document) => {
                        deleteFactoringDocument({ factoringDocumentType: type, documentId: document?.id });
                      }}
                      disabled={
                        isEditingFactoringApplication || isAddingFactoringDocument || isDeletingFactoringDocument
                      }
                      isLoading={
                        isEditingFactoringApplication || isAddingFactoringDocument || isDeletingFactoringDocument
                      }
                      onSubmit={() => {
                        dispatch(actions.completeDocumentsForm);
                        if (factoringBankInfoCompleted === undefined) {
                          dispatch(actions.startBankInformationForm);
                        }
                      }}
                    />
                  }
                  card={
                    <DocumentCard
                      title={t("factoring.documents-form.title")}
                      editHelperText={t("factoring.documents-form.documents-card.edit-icon")}
                      documents={[
                        factoringApplication?.articlesOfIncorporation,
                        factoringApplication?.w9,
                        factoringApplication?.driversLicense,
                        ...(factoringApplication?.otherDocuments ? factoringApplication.otherDocuments : []),
                      ]
                        .filter((document) => Boolean(document))
                        .map((document) => document?.name || "")}
                      onEdit={() => dispatch(actions.startDocumentsForm)}
                    />
                  }
                />

                <FactoringSection
                  title={t("factoring.bank-information.title")}
                  formStatus={factoringBankInfoCompleted}
                  form={
                    <FactoringBankInformationForm
                      accountNumber={stripeBankAccountNumber}
                      bankName={bankName}
                      plaidOnEvent={plaidOnEvent}
                      plaidOnExit={plaidOnExit}
                      plaidOnSuccess={factoringPlaidOnSuccess}
                      plaidSuccess={plaidSuccess}
                      token={token}
                      onAddAccountDialogOpen={() => startStripeManualBank()}
                      onVerifyAccountDialogOpen={() => startStripeVerifyDeposits()}
                      onAddNewBankAccount={() => onAddNewBankAccount()}
                      stripeSuccess={stripeSuccess}
                      onComplete={() => {
                        setComplete(true);
                      }}
                      onNotComplete={() => setComplete(false)}
                      stripeBankAccountAdded={stripeBankAccountAdded}
                    />
                  }
                />
                <Grid container item direction="row" className={classes.pageButtons} spacing={2}>
                  <Grid item xs={3}>
                    <PreviousButton
                      variant="outlined"
                      color="primary"
                      fullWidth
                      className={classes.previousButton}
                      onClick={() => history.push(routes.enrollment.finishApplication.path)}
                      aria-label={t("finish-application-page.previous-button-label")}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <NextButton
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={
                        paymentChecked
                          ? !checkCompletedForms()
                          : !complete ||
                            !factoringChecked ||
                            isEditingApplication ||
                            isCreatingFactoringApplication ||
                            isEditingFactoringApplication ||
                            isAddingFactoringDocument ||
                            isDeletingFactoringDocument ||
                            isAddingPlaidAccount ||
                            isAddingStripeAccount ||
                            isVerifyingMicrodeposits
                      }
                      onClick={() => {
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "continueToReviewFactoringEvent",
                            continueToReviewFactoringText: "Continue To Review",
                          },
                        });
                        formik.handleSubmit();
                      }}
                    >
                      {isEditingApplication ||
                      isCreatingFactoringApplication ||
                      isEditingFactoringApplication ||
                      isAddingFactoringDocument ||
                      isDeletingFactoringDocument ||
                      isAddingPlaidAccount ||
                      isAddingStripeAccount ||
                      isVerifyingMicrodeposits ? (
                        <CircularProgress
                          color="inherit"
                          size="2rem"
                          aria-label={t("common.circular-progress-aria-label")}
                        />
                      ) : (
                        t("finish-application.payment-method-page.next-button")
                      )}
                    </NextButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Formik>
        )}
        <Grid item xs={12} container direction="row" alignItems="center" className={classes.orContainer}>
          <Grid item xs={5}>
            <Divider />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h2" className={classes.orText}>
              {t("finish-application.payment-method-page.or")}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Divider />
          </Grid>
        </Grid>
        <Formik
          {...formikConfig}
          onSubmit={(values: PaymentMethodFormProps) => {
            TagManager.dataLayer({
              dataLayer: {
                event: "billingCycleEvent",
                billingCycle: values[Fields.billingCycle] as BillingCycle,
              },
            });
            editApplication({
              ...application,
              billingCycle: values[Fields.billingCycle] as BillingCycle,
            });
          }}
        >
          {(formik) => (
            <>
              <Grid item xs={12} className={classes.factoringButtonContainer}>
                {paymentChecked && token ? (
                  <>
                    <PaymentMethodForm
                      accountNumber={stripeBankAccountNumber}
                      bankName={bankName}
                      paymentChecked={paymentChecked}
                      onPaymentChecked={() => setPaymentChecked(true)}
                      onPaymentNotChecked={() => setPaymentChecked(false)}
                      onComplete={() => {
                        setComplete(true);
                      }}
                      onNotComplete={() => setComplete(false)}
                      plaidOnEvent={plaidOnEvent}
                      plaidOnExit={plaidOnExit}
                      plaidOnSuccess={paymentPlaidOnSuccess}
                      plaidSuccess={plaidSuccess}
                      onAddAccountDialogOpen={() => startStripeManualBank()}
                      onVerifyAccountDialogOpen={() => startStripeVerifyDeposits()}
                      onAddNewBankAccount={() => onAddNewBankAccount()}
                      stripeSuccess={stripeSuccess}
                      stripeBankAccountAdded={stripeBankAccountAdded}
                      token={token}
                      weeklyDownPayment={(application?.currentQuote?.rates?.weeklyDownPayment ?? 0).toString()}
                      monthlyDownPayment={(application?.currentQuote?.rates?.monthlyDownPayment ?? 0).toString()}
                      weeklyTotal={(application?.currentQuote?.rates?.weeklyRate ?? 0).toString()}
                      monthlyTotal={(application?.currentQuote?.rates?.monthlyRate ?? 0).toString()}
                      annualTotal={(application?.currentQuote?.rates?.annualizedRate ?? 0).toString()}
                    />
                    <Grid container item direction="row" className={classes.pageButtons} spacing={2}>
                      <Grid item xs={3}>
                        <PreviousButton
                          variant="outlined"
                          color="primary"
                          fullWidth
                          className={classes.previousButton}
                          onClick={() => history.push(routes.enrollment.finishApplication.path)}
                          aria-label={t("finish-application-page.previous-button-label")}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <NextButton
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={
                            factoringChecked
                              ? !checkCompletedForms()
                              : !complete ||
                                !paymentChecked ||
                                isAddingPlaidAccount ||
                                isAddingStripeAccount ||
                                isVerifyingMicrodeposits
                          }
                          onClick={() => {
                            TagManager.dataLayer({
                              dataLayer: {
                                event: "continueToReviewEvent",
                                continueToReviewText: "Continue To Review",
                              },
                            });
                            formik.handleSubmit();
                          }}
                        >
                          {isEditingApplication ||
                          isCompletingFactoringApplication ||
                          isAddingPlaidAccount ||
                          isAddingStripeAccount ||
                          isVerifyingMicrodeposits ? (
                            <CircularProgress
                              color="inherit"
                              size="2rem"
                              aria-label={t("common.circular-progress-aria-label")}
                            />
                          ) : (
                            t("finish-application.payment-method-page.next-button")
                          )}
                        </NextButton>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "payWithMyBankAccountEvent",
                          payWithMyBankAccountText: "Pay With My Bank Account",
                        },
                      });
                      setFactoringChecked((prevState) => {
                        return paymentChecked ? prevState : false;
                      });
                      setPaymentChecked((prevState) => {
                        return !prevState;
                      });
                    }}
                  >
                    {paymentChecked ? (
                      <CheckBoxIcon className={classes.checkIcon} />
                    ) : (
                      <CheckBoxOutlineBlankIcon className={classes.checkIcon} />
                    )}
                    <Typography variant="h2">{t("finish-application.payment-method-page.payment-button")}</Typography>
                  </Button>
                )}
              </Grid>
            </>
          )}
        </Formik>
      </Grid>

      <Dialog
        open={addManualOpen}
        onClose={() => setAddManualOpen(false)}
        className={mobileWidth ? classes.mobileDialog : classes.dialog}
      >
        <AddBankAccountManualModal
          onClose={() => setAddManualOpen(false)}
          error={stripeAccountError as Error}
          onSubmit={(values) => {
            sendBankInfoStripe(
              {
                stripeBankAccountCreateRequest: {
                  accountNumber: values["account-number"],
                  nameOnAccount: values["full-name"],
                  routingNumber: values["routing-number"],
                },
              },
              {
                onSuccess: () => {
                  setStripeBankAccountNumber(values["account-number"].slice(-4));
                  setStripeBankAccountAdded(true);
                  setAddManualOpen(false);
                  setComplete(true);
                },
              },
            );
          }}
        />
      </Dialog>
      <Dialog
        open={verifyDepositsOpen}
        onClose={() => dispatch(actions.closeVerifyAccountDialog())}
        className={classes.dialog}
      >
        <VerifyBankAccountModal
          onClose={() => setVerifyDepositsOpen(false)}
          error={microdepositsError as Error}
          onSubmit={(values) => {
            verifyStripeMicrodeposits(
              {
                deposit1: parseInt(values["first-deposit"]),
                deposit2: parseInt(values["second-deposit"]),
              },
              {
                onSuccess: () => {
                  setVerifyDepositsOpen(false);
                  setStripeSuccess(true);
                  setComplete(true);
                },
              },
            );
          }}
        />
      </Dialog>
      <Dialog
        open={newBankConfirmationDialog}
        onClose={() => setNewBankConfirmationDialog(false)}
        className={classes.addNewBankDialog}
      >
        <AddNewBankAccountConfirmationModal
          startNewBankAccount={() => startNewBankAccount()}
          onClose={() => setNewBankConfirmationDialog(false)}
        />
      </Dialog>
    </Grid>
  );
};

export default PaymentMethodPage;
