/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FactoringDocument
 */
export interface FactoringDocument {
  /**
   *
   * @type {number}
   * @memberof FactoringDocument
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FactoringDocument
   */
  storageUri?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringDocument
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof FactoringDocument
   */
  length?: number;
}

export function FactoringDocumentFromJSON(json: any): FactoringDocument {
  return FactoringDocumentFromJSONTyped(json, false);
}

export function FactoringDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FactoringDocument {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    storageUri: !exists(json, "storageUri") ? undefined : json["storageUri"],
    name: !exists(json, "name") ? undefined : json["name"],
    length: !exists(json, "length") ? undefined : json["length"],
  };
}

export function FactoringDocumentToJSON(value?: FactoringDocument | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    storageUri: value.storageUri,
    name: value.name,
    length: value.length,
  };
}
