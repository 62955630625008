import { EditableDeletableCard } from "@chq/components";
import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: "1rem",
  },
  button: {
    padding: "1rem 2rem",
  },
  buttonContainer: {
    paddingTop: "2rem",
  },
}));

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ResetPasswordConfirmation: React.FC<Props> = ({ onClick }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <EditableDeletableCard
      variant="default"
      title={t("reset-password-confirmation.title")}
      titleVariant="h3"
      className={classes.title}
    >
      <Grid container>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button variant="contained" onClick={onClick} className={classes.button}>
            {t("reset-password-confirmation.go-to-login-button")}
          </Button>
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default ResetPasswordConfirmation;
