import {
  EditableDeletableCard,
  SaveButton,
  Select,
  TextInput,
  TooltipHelp,
  YesNoInput,
  YesNoValues,
} from "@chq/components";
import { ELDProvider } from "@chq/enrollment-api";
import { Grid, Link, makeStyles, Theme } from "@material-ui/core";
import { useFormik } from "formik";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { getFirstEldProvider, getOtherEldProvider } from "../utils/eld-provider-mapper";
import EldDialog from "./eld-dialog";
import EldHelpDialog from "./eld-help-dialog";

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    padding: "1.125rem 0rem",
  },
  container: {
    paddingTop: ".75rem",
  },
  form: {
    paddingTop: "0.5rem",
  },
  linkStyle: {
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
  tooltipText: {
    color: theme.palette.common.white,
  },
  yesNoInput: {
    width: "100%",
    paddingLeft: "1rem",
    "& .MuiFormGroup-root": {
      width: "fit-content",
      "& .MuiFormControlLabel-root": {
        padding: "0.5rem 0.75rem",
      },
    },
  },
}));

export enum Fields {
  eldProvider = "eld-provider",
  otherEldProvider = "other-eld-provider",
  eldAdmin = "eld-admin",
  eldAccount = "eld-account",
  keepTruckingAppDevice = "keeptruckin-app-device",
}

export type EldFormProps = {
  [Fields.eldProvider]?: ELDProvider | undefined;
  [Fields.otherEldProvider]?: ELDProvider | undefined;
  [Fields.eldAdmin]: string;
  [Fields.keepTruckingAppDevice]?: YesNoValues;
  [Fields.eldAccount]: string;
};

export type Props = {
  eldProvider?: ELDProvider | undefined;
  otherEldProvider?: ELDProvider | undefined;
  eldAdmin?: string;
  eldAccount?: string;
  keepTruckingAppDevice?: YesNoValues;
  isSomethingSaving?: boolean;
  onSubmit?: (values: EldFormProps) => void;
  onClickEvent?: () => void;
  emailSent?: boolean;
  isGeotabIdle?: boolean;
  isGeotabError?: boolean;
  isGeotabLoading?: boolean;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.eldProvider]: yup
      .string()
      .required(t("errors.required", { field: t("finish-application-page.eld-form.eld-provider.label") })),
    [Fields.otherEldProvider]: yup.string().when(Fields.eldProvider, {
      is: ELDProvider.Other,
      then: yup
        .string()
        .required(t("errors.required", { field: t("finish-application-page.eld-form.other-eld-provider.label") })),
      otherwise: yup.string(),
    }),
    [Fields.eldAdmin]: yup.string(),
    [Fields.eldAccount]: yup
      .string()
      .when(Fields.eldProvider, {
        is: ELDProvider.MotiveKeepTruckin,
        then: yup
          .string()
          .required(t("errors.required", { field: t("finish-application-page.eld-form.eld-account.error-label") }))
          .matches(/^KT-[0-9]{7}$/, t("finish-application-page.eld-form.eld-account.error-keeptruckin-format")),
      })
      .when(Fields.eldProvider, {
        is: ELDProvider.Samsara,
        then: yup
          .string()
          .required(t("errors.required", { field: t("finish-application-page.eld-form.eld-account.error-label") }))
          // .matches(
          //   /^samsara_api_[a-zA-Z0-9]{18}$/,
          //   t("finish-application-page.eld-form.eld-account.error-samsara-format"),
          // )
          .min(42, t("finish-application-page.eld-form.eld-account.error-samsara-format"))
          .max(42, t("finish-application-page.eld-form.eld-account.error-samsara-format")),
      })
      .when(Fields.eldProvider, {
        is: ELDProvider.Geotab,
        then: yup
          .string()
          .required(t("errors.required", { field: t("finish-application-page.eld-form.eld-account.error-label") }))
          .matches(/^[a-zA-Z0-9_]*$/, t("finish-application-page.eld-form.eld-account.error-geotab-format")),
      }),
    [Fields.keepTruckingAppDevice]: yup
      .boolean()
      .oneOf([true], t("finish-application-page.eld-form.keeptruckin-app-device.error-label")),
  });
};

const EldForm: React.FC<Props> = ({
  eldProvider = undefined,
  otherEldProvider = undefined,
  eldAdmin = "",
  eldAccount = "",
  keepTruckingAppDevice = undefined,
  isSomethingSaving,
  onSubmit,
  onClickEvent,
  emailSent,
  isGeotabIdle,
  isGeotabError,
  isGeotabLoading,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const validationSchema = useValidationSchema();

  const firstEldProviders = getFirstEldProvider();
  const otherEldProviders = getOtherEldProvider();

  const formik = useFormik<EldFormProps>({
    initialValues: {
      [Fields.eldProvider]: eldProvider,
      [Fields.otherEldProvider]: otherEldProvider,
      [Fields.eldAdmin]: eldAdmin,
      [Fields.eldAccount]: eldAccount,
      [Fields.keepTruckingAppDevice]: keepTruckingAppDevice,
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit && onSubmit(values);
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  const [noELDModalOpen, setNoELDModalOpen] = useState<boolean>(false);
  const [otherELDModalOpen, setOtherELDModalOpen] = useState<boolean>(
    formik.values[Fields.otherEldProvider] === ELDProvider.Other,
  );
  const [keepTruckinErrorOpen, setKeepTruckinErrorOpen] = useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);

  const onRequestGeoTab = async () => {
    onClickEvent && onClickEvent();
    await formik.setFieldValue(Fields.eldProvider, ELDProvider.Other);
    await formik.setFieldValue(Fields.otherEldProvider, ELDProvider.Other);
    await formik.setFieldValue(Fields.eldAdmin, "");
    await formik.setFieldValue(Fields.eldAccount, "");
    await formik.setFieldValue(Fields.keepTruckingAppDevice, undefined);
    onSubmit &&
      onSubmit({
        ...formik.values,
        [Fields.eldProvider]: ELDProvider.Other,
        [Fields.otherEldProvider]: ELDProvider.Other,
        [Fields.eldAdmin]: "",
        [Fields.eldAccount]: "",
        [Fields.keepTruckingAppDevice]: undefined,
      });
  };

  const accountPrefix = (provider?: ELDProvider): string => {
    if (provider && provider === ELDProvider.MotiveKeepTruckin) return "KT-";
    if (provider && provider === ELDProvider.Samsara) return "samsara_api_";
    return "";
  };

  const accountLength = (provider?: ELDProvider) => {
    if (provider && provider === ELDProvider.Samsara) return 42;
    else if (provider && provider === ELDProvider.MotiveKeepTruckin) return 10;
    return 50;
  };

  const changeEldProvider = (provider: ELDProvider | undefined) => {
    formik.setFieldValue(Fields.eldAccount, accountPrefix(provider as ELDProvider));
    formik.setFieldTouched(Fields.eldAccount, false);
    formik.setFieldValue(Fields.eldAdmin, "");
    formik.setFieldValue(Fields.keepTruckingAppDevice, undefined);

    if (provider === ELDProvider.None) {
      setNoELDModalOpen(true);
    } else if (provider === ELDProvider.Other) {
      formik.values[Fields.otherEldProvider] = undefined;
    }
  };

  const changeEldAccount = (newValue: string, prevValue: string) => {
    const prefix = accountPrefix(formik.values[Fields.eldProvider] as ELDProvider);
    if (!newValue || newValue === "") {
      newValue = prefix;
    } else if (newValue.slice(0, prefix.length) !== prefix) {
      if (prevValue.slice(0, prefix.length) !== prefix) {
        newValue = prefix;
      } else {
        newValue = prevValue;
      }
    }
    return newValue;
  };

  const onKeepTruckinAppDeviceChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === YesNoValues.no) {
      setKeepTruckinErrorOpen(true);
      formik.setFieldValue(Fields.eldProvider, ELDProvider.Other);
      formik.setFieldValue(Fields.otherEldProvider, ELDProvider.Other);
      formik.setFieldValue(Fields.eldAdmin, undefined);
      formik.setFieldValue(Fields.eldAccount, undefined);
      formik.setFieldValue(Fields.keepTruckingAppDevice, undefined);
    }
  };

  return (
    <EditableDeletableCard
      title={t("finish-application-page.eld-form.title")}
      className={classes.container}
      titleVariant="h2"
      variant="default"
    >
      {/** If first choice is None **/}
      <EldDialog
        variant="none"
        open={noELDModalOpen}
        onClose={() => setNoELDModalOpen(false)}
        onClickEvent={onRequestGeoTab}
        emailSent={emailSent}
        isIdle={isGeotabIdle}
        isLoading={isGeotabLoading}
        isError={isGeotabError}
      />
      {/** If second choice is Other **/}
      <EldDialog
        variant="other"
        open={otherELDModalOpen}
        onClose={() => setOtherELDModalOpen(false)}
        onClickEvent={onRequestGeoTab}
        emailSent={emailSent}
        isIdle={isGeotabIdle}
        isLoading={isGeotabLoading}
        isError={isGeotabError}
      />
      {/** If second choice is KeepTruckin **/}
      <EldDialog
        variant="keepTruckin"
        open={keepTruckinErrorOpen}
        onClose={() => setKeepTruckinErrorOpen(false)}
        onClickEvent={onRequestGeoTab}
        emailSent={emailSent}
        isIdle={isGeotabIdle}
        isLoading={isGeotabLoading}
        isError={isGeotabError}
      />
      {/** "Help Me Connect" Dialog **/}
      <EldHelpDialog provider={formik.values[Fields.eldProvider]} open={helpOpen} onClose={() => setHelpOpen(false)} />

      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container>
          {/** ELD Provider Field **/}
          <Grid item xs={12}>
            <Select
              fullWidth
              required
              id={Fields.eldProvider}
              name={Fields.eldProvider}
              label={t("finish-application-page.eld-form.eld-provider.label")}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                changeEldProvider(event.target.value as ELDProvider);
                formik.handleChange(event);
              }}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.eldProvider]}
              error={formik.touched[Fields.eldProvider] && Boolean(formik.errors[Fields.eldProvider])}
              helperText={formik.touched[Fields.eldProvider] && formik.errors[Fields.eldProvider]}
              items={firstEldProviders.map((eldProvider: ELDProvider) => ({
                name: t(`eld-provider.${eldProvider}`),
                value: eldProvider,
              }))}
            />
          </Grid>
          {formik.values[Fields.eldProvider] && formik.values[Fields.eldProvider] !== ELDProvider.None && (
            <>
              {formik.values[Fields.eldProvider] !== ELDProvider.Other ? (
                <Grid item xs={12}>
                  {formik.values[Fields.eldProvider] === ELDProvider.MotiveKeepTruckin && (
                    // KeepTruckin Flow
                    <YesNoInput
                      className={classes.yesNoInput}
                      id={Fields.keepTruckingAppDevice}
                      label={t("finish-application-page.eld-form.keeptruckin-app-device.label")}
                      name={Fields.keepTruckingAppDevice}
                      yesText={t("finish-application-page.eld-form.keeptruckin-app-device.device-label")}
                      noText={t("finish-application-page.eld-form.keeptruckin-app-device.app-label")}
                      required
                      value={formik.values[Fields.keepTruckingAppDevice]}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        onKeepTruckinAppDeviceChange(event);
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched[Fields.keepTruckingAppDevice] &&
                        Boolean(formik.errors[Fields.keepTruckingAppDevice])
                      }
                      helperText={
                        formik.touched[Fields.keepTruckingAppDevice] && formik.errors[Fields.keepTruckingAppDevice]
                      }
                    />
                  )}
                  {(formik.values[Fields.eldProvider] !== ELDProvider.MotiveKeepTruckin ||
                    formik.values[Fields.keepTruckingAppDevice] === YesNoValues.yes) && (
                    <TextInput
                      fullWidth
                      required
                      id={Fields.eldAccount}
                      name={Fields.eldAccount}
                      label={t("finish-application-page.eld-form.eld-account.label", {
                        provider: formik.values[Fields.eldProvider],
                      })}
                      onChange={(event) => {
                        event.target.value = changeEldAccount(event.target.value, formik.values[Fields.eldAccount]);
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values[Fields.eldAccount]}
                      error={formik.touched[Fields.eldAccount] && Boolean(formik.errors[Fields.eldAccount])}
                      helperText={formik.touched[Fields.eldAccount] && formik.errors[Fields.eldAccount]}
                      labelAdornment={
                        <Link
                          className={classes.linkStyle}
                          onClick={() => {
                            setHelpOpen(true);
                          }}
                        >
                          {t("finish-application-page.eld-form.eld-account.info-text")}
                        </Link>
                      }
                      inputProps={{ maxLength: accountLength(formik.values[Fields.eldProvider]) }}
                    />
                  )}
                </Grid>
              ) : (
                <>
                  {/** Other Flow **/}
                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      required
                      id={Fields.otherEldProvider}
                      name={Fields.otherEldProvider}
                      label={t("finish-application-page.eld-form.other-eld-provider.label")}
                      onChange={(event) => {
                        event.target.value === ELDProvider.Other && setOtherELDModalOpen(true);
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values[Fields.otherEldProvider]}
                      error={formik.touched[Fields.otherEldProvider] && Boolean(formik.errors[Fields.otherEldProvider])}
                      helperText={formik.touched[Fields.otherEldProvider] && formik.errors[Fields.otherEldProvider]}
                      items={otherEldProviders.map((otherEldProvider: ELDProvider) => ({
                        name: t(`eld-provider.${otherEldProvider}`),
                        value: otherEldProvider,
                      }))}
                    />
                  </Grid>
                  {formik.values[Fields.otherEldProvider] !== ELDProvider.Other &&
                    formik.values[Fields.otherEldProvider] !== undefined && (
                      <>
                        <Grid item xs={12}>
                          <TextInput
                            fullWidth
                            id={Fields.eldAdmin}
                            name={Fields.eldAdmin}
                            label={t("finish-application-page.eld-form.eld-admin.label", {
                              provider: formik.values[Fields.eldProvider],
                            })}
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values[Fields.eldAdmin]}
                            error={formik.touched[Fields.eldAdmin] && Boolean(formik.errors[Fields.eldAdmin])}
                            helperText={formik.touched[Fields.eldAdmin] && formik.errors[Fields.eldAdmin]}
                            labelAdornment={
                              <TooltipHelp
                                title={t("finish-application-page.eld-form.eld-admin.info-text").toString()}
                                placement="left-end"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            fullWidth
                            id={Fields.eldAccount}
                            name={Fields.eldAccount}
                            label={t("finish-application-page.eld-form.eld-account.label")}
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values[Fields.eldAccount]}
                            error={formik.touched[Fields.eldAccount] && Boolean(formik.errors[Fields.eldAccount])}
                            helperText={formik.touched[Fields.eldAccount] && formik.errors[Fields.eldAccount]}
                          />
                        </Grid>
                      </>
                    )}
                </>
              )}
            </>
          )}
          <Grid item xs={12}>
            <SaveButton
              label={t("finish-application-page.eld-form.add-button")}
              variant="outlined"
              fullWidth
              type="submit"
              disabled={!formik.isValid || isSomethingSaving}
              formikValid={formik.isValid}
            />
          </Grid>
        </Grid>
      </form>
    </EditableDeletableCard>
  );
};

export default EldForm;
