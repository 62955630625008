import { DatePicker } from "@chq/components";
import { Card, CardContent, makeStyles, Theme, Typography } from "@material-ui/core";
import startOfToday from "date-fns/startOfToday";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { getFutureDate } from "../utils/get-future-date";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: "1rem",
  },
  select: {
    "& .MuiOutlinedInput-root": {
      [theme.breakpoints.down("xs")]: {
        marginTop: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "1.5rem",
      },
    },
  },
}));

export enum Fields {
  effectiveDate = "effective-date",
}

export type FormProps = {
  [Fields.effectiveDate]?: Date | null;
};

export type Props = {
  effectiveDate?: Date | null;
  quoteCreationDate?: Date | null;
};

export const useValidationSchema = (quoteCreationDate: Date) => {
  const [t] = useTranslation();
  const today = startOfToday();
  const maxDate = getFutureDate(quoteCreationDate, 89);

  return yup.object({
    [Fields.effectiveDate]: yup
      .date()
      .nullable()
      .required(
        t(`errors.required`, {
          field: t("review-application-page.review-effective-date-card.field"),
        }),
      )
      .typeError(t("errors.date", { field: t("review-application-page.review-effective-date-card.field") }))
      .min(today, t("review-application-page.review-effective-date-card.errors.min-effective-date"))
      .max(maxDate, t("review-application-page.review-effective-date-card.errors.max-effective-date")),
  });
};

export const useFormikConfig = ({ effectiveDate = startOfToday(), quoteCreationDate }: Props = {}): Omit<
  FormikConfig<FormProps>,
  "onSubmit"
> => {
  const validationSchema = useValidationSchema(quoteCreationDate || startOfToday());
  return {
    initialValues: {
      [Fields.effectiveDate]: effectiveDate,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const ReviewEffectiveDateCard: React.FC<Props> = ({ quoteCreationDate }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<FormProps>();
  const maxDate = getFutureDate(quoteCreationDate || startOfToday(), 89);

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2" className={classes.title}>
          {t("review-application-page.review-effective-date-card.title")}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <DatePicker
            fullWidth
            required
            id={Fields.effectiveDate}
            name={Fields.effectiveDate}
            disablePast
            maxDate={maxDate}
            KeyboardButtonProps={{
              "aria-label": t("review-application-page.review-effective-date-card.button"),
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(date: any) => {
              formik.setFieldValue(Fields.effectiveDate, date);
            }}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.effectiveDate] || null}
            error={formik.touched[Fields.effectiveDate] && Boolean(formik.errors[Fields.effectiveDate])}
            helperText={formik.touched[Fields.effectiveDate] && formik.errors[Fields.effectiveDate]}
          />
        </form>
      </CardContent>
    </Card>
  );
};

export default ReviewEffectiveDateCard;
