import { ReferralMethod } from "@chq/enrollment-api";

export const useReferralMethodTypes = (): ReferralMethod[] => {
  return [
    ReferralMethod.Email,
    ReferralMethod.Facebook,
    ReferralMethod.FreightWaves,
    ReferralMethod.FriendFamily,
    ReferralMethod.Mailer,
    ReferralMethod.Mats,
    ReferralMethod.OnlineSearch,
    ReferralMethod.Other,
    ReferralMethod.Overdrive,
    ReferralMethod.PhoneCall,
    ReferralMethod.Referral,
    ReferralMethod.SiriusXM,
    ReferralMethod.SocialMedia,
  ];
};
