import { AlertType, PageAlert } from "@chq/components";
import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import AccountInfoForm, {
  Fields as ChangePasswordFields,
} from "../../../components/policy-management-components/account-info-form";
import CancelPolicyButton, {
  Fields as CancelPolicyFormFields,
} from "../../../components/policy-management-components/cancel-policy-button";
import EldCard from "../../../components/policy-management-components/eld-card";
import PaymentForm from "../../../components/policy-management-components/payment-form";
import PmCompanyInfoCard from "../../../components/policy-management-components/pm-company-info-card";
import { useCancelPolicy } from "../../../data/policy-management/useCancelPolicy";
import { useChangePassword } from "../../../data/policy-management/useChangePassword";
import { useGetPolicy } from "../../../data/policy-management/useGetPolicy";
import { fullName } from "../../../utils/full-name";

const paymentFormProps = {
  paymentMethod: "TBD",
  billingCycle: "WIRE THIS UP",
  bankAccount: "BANK NAME - XXXX",
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.5rem 0.75rem 1.5rem 0.75rem",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignContent: "center",
    },
  },
  border: {
    borderBottom: `solid 1px ${theme.palette.secondary.light}`,
    paddingBottom: "0.75rem !important",
  },
  cancelButton: {
    paddingTop: "0.75rem !important",
  },
  columnGroup: {
    display: "flex",
    justifyContent: "flex-start",
  },
  firstColumnGroup: {
    [theme.breakpoints.up("md")]: {
      marginRight: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0.5rem",
    },
  },
}));

const PersonalInfoPage: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();

  const { data, isLoading, isError, error } = useGetPolicy();
  const { mutate: cancelPolicy, isSuccess: cancelled } = useCancelPolicy();
  const { mutate: changePassword } = useChangePassword();
  return (
    <>
      {isError && error && <PageAlert alertType={AlertType.error} message={error.message} />}

      <Grid container className={classes.container}>
        {isLoading ? (
          <Grid item>
            <CircularProgress color="primary" size="2.5rem" aria-label={t("common.circular-progress-aria-label")} />
          </Grid>
        ) : (
          <>
            <Grid
              container
              item
              direction="column"
              xs={12}
              sm={8}
              md={5}
              spacing={1}
              className={classNames(classes.columnGroup, classes.firstColumnGroup)}
            >
              <Grid item>
                <AccountInfoForm
                  name={fullName(data?.business?.contactFirstName || "", data?.business?.contactLastName || "")}
                  email={data?.business?.contactEmail || ""}
                  phoneNumber={data?.business?.contactPhone || ""}
                  onSubmit={({
                    [ChangePasswordFields.currentPassword]: currentPassword,
                    [ChangePasswordFields.password]: newPassword,
                  }) => changePassword({ changePasswordRequest: { currentPassword, newPassword } })}
                />
              </Grid>
              <Grid item>
                <PmCompanyInfoCard
                  name={data?.business?.businessName || ""}
                  dotNumber={data?.business?.dotNumber || ""}
                  ein={data?.business?.einNumber || ""}
                  mcNumber={data?.business?.mcNumber || ""}
                  vehicleLocation={data?.business?.address?.state}
                  radiusOfOperation={data?.business?.radiusOfOperation}
                  entityType={data?.business?.legalEntity}
                  operatingAuthStatus={data?.business?.operatingAuthorityStatus}
                  primaryOperationType={data?.business?.operationType}
                />
              </Grid>
            </Grid>
            <Grid container item direction="column" xs={12} sm={8} md={5} spacing={1} className={classes.columnGroup}>
              <Grid item>
                <EldCard
                  eldProvider={data?.business?.eldProvider}
                  eldAdmin={data?.business?.eldAdminName || ""}
                  fleetNumber={data?.business?.fleetAccountNumber || ""}
                />
              </Grid>

              <Grid item className={classes.border}>
                <PaymentForm {...paymentFormProps} />
              </Grid>
              <Grid item className={classes.cancelButton}>
                <CancelPolicyButton
                  cancelled={cancelled}
                  onSubmit={({ [CancelPolicyFormFields.comments]: reason }) =>
                    cancelPolicy({ policyId: data?.id, reason })
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default PersonalInfoPage;
