import { NumericInput, SaveButton } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import AddressForm, {
  Fields,
  FormProps as AddressFormProps,
  Props as AddressFormComponentProps,
  useFormikConfig as useAddressFormFormikConfig,
} from "./address-form";

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    padding: "0rem 0.125rem 1.125rem 0.25rem",
  },
  submitButton: {
    minHeight: "3.5rem",
  },
}));

export type FormProps = AddressFormProps & {
  [Fields.ein]?: string | null;
};

type Props = Omit<AddressFormComponentProps, "onSubmit"> & {
  ein?: string | null;
  onSubmit: FormikConfig<FormProps>["onSubmit"];
};

type AdditionalInsuredFormProps = {
  ein?: string | null;
};

export const useFormikConfig = ({ ein: initialEin }: AdditionalInsuredFormProps = {}): Omit<
  FormikConfig<FormProps>,
  "onSubmit"
> => {
  return {
    initialValues: {
      [Fields.ein]: initialEin,
    },
    validateOnMount: true,
    enableReinitialize: true,
  };
};

const AdditionalInsuredForm: React.FC<Props> = ({
  entityName = "",
  addressLine1 = "",
  addressLine2 = "",
  city = "",
  state = "",
  zipcode = "",
  ein = "",
  onSubmit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const addressFormConfig = useAddressFormFormikConfig({
    entityName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipcode,
  });
  const additionalInsuredFormConfig = useFormikConfig({ ein });
  return (
    <Formik
      initialValues={{
        ...addressFormConfig.initialValues,
        ...additionalInsuredFormConfig.initialValues,
      }}
      enableReinitialize={true}
      validationSchema={addressFormConfig.validationSchema.concat(additionalInsuredFormConfig.validationSchema)}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Grid container direction="column" className={classes.formContainer}>
            <AddressForm />
            <Grid item xs={12}>
              <NumericInput
                fullWidth
                label={t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.ein}.label`)}
                format="##-#######"
                id={Fields.ein}
                name={Fields.ein}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.ein] && Boolean(formik.errors[Fields.ein])}
                value={formik.values[Fields.ein]}
                helperText={formik.touched[Fields.ein] && formik.errors[Fields.ein]}
              />
            </Grid>
            <Grid item xs={12}>
              <SaveButton
                label={t(
                  "review-application-page.review-power-units-trailers-form.add-additional-insured.submit-button",
                )}
                variant="outlined"
                fullWidth
                onClick={() => formik.handleSubmit()}
                type="submit"
                disabled={!formik.isValid}
                formikValid={formik.isValid}
              />
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default AdditionalInsuredForm;
