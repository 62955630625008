import { CardField, EditableDeletableCard, format } from "@chq/components";
import { Driver } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { fullName } from "../utils/full-name";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: "1.125rem 0.5rem 0rem",
  },
  container: {
    paddingTop: "1rem",
  },
  driver: {
    paddingBottom: "1rem",
  },
  divider: {
    borderBottom: `solid .125rem ${theme.palette.grey[400]}`,
  },
}));
type Props = {
  drivers: Driver[];
};

const DriversCard: React.FC<Props> = ({ drivers }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const totalDrivers = drivers.length;
  return (
    <EditableDeletableCard
      variant="default"
      title={t("drivers-card.title", { numberOfDrivers: totalDrivers })}
      titleVariant="h2"
      titleComponent="h4"
      className={classes.title}
      elevation={1}
      id={"driverCard"}
      border={false}
    >
      {drivers.map((driver, index) => (
        <Grid
          key={`driverNumber${index}`}
          container
          className={classNames(classes.container, { [classes.divider]: totalDrivers - 1 !== index })}
        >
          <CardField
            id={`driverName${index}`}
            label={fullName(driver.firstName!, driver.lastName!)}
            labelColor="textPrimary"
            className={classes.driver}
          />
          <CardField
            id={`dateOfBirth${index}`}
            label={t("drivers-card.date-of-birth")}
            values={[{ value: format(driver.dateOfBirth!) }]}
          />
          <CardField
            id={`cdlNumber${index}`}
            label={t("drivers-card.cdl-number")}
            values={[{ value: driver.cdlNumber!.toString() }]}
          />
          <CardField
            id={`cdlState${index}`}
            label={t("drivers-card.cdl-state")}
            values={[{ value: driver.cdlState }]}
            className={classNames({ [classes.driver]: totalDrivers - 1 !== index })}
          />
        </Grid>
      ))}
    </EditableDeletableCard>
  );
};

export default DriversCard;
