import {
  ApiV10ApplicationSignEConsentPostRequest,
  ApiV10DocumentDeleteRequest,
  ApiV10DocumentUriGetRequest,
  ApplicationApi,
  DocumentApi,
} from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const useAddEDeliveryConsent = (): UseMutationResult<void, Error, ApiV10ApplicationSignEConsentPostRequest> => {
  const applicationApi = useApi(ApplicationApi);
  const { application, updateApplication } = useCachedApplication();
  const mutation = useMutation<void, Error, ApiV10ApplicationSignEConsentPostRequest>(async () => {
    await applicationApi.apiV10ApplicationSignEConsentPost({ applicationId: application!.id });

    const response = await applicationApi.apiV10ApplicationGet();
    if (response.error) {
      throw new Error(response.error.message || "Failed to create signed");
    }
    if (response.data) {
      updateApplication(response.data);
    }
    return;
  });
  return mutation;
};

export const useGetDocument = (): UseMutationResult<Blob, Error, ApiV10DocumentUriGetRequest> => {
  const documentApi = useApi(DocumentApi);
  const mutation = useMutation<Blob, Error, ApiV10DocumentUriGetRequest>(
    async (request: ApiV10DocumentUriGetRequest) => {
      const response = await documentApi.apiV10DocumentUriGet(request);
      if (response.error) {
        throw new Error(response.error.message || "failed to get document");
      }
      return response.data!;
    },
  );
  return mutation;
};
export const useDeleteEDeliveryConsentDocument = (): UseMutationResult<void, Error, ApiV10DocumentDeleteRequest> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(ApplicationApi);
  const { application, updateApplication } = useCachedApplication();
  const mutation = useMutation<void, Error, ApiV10DocumentDeleteRequest>(
    async (request: ApiV10DocumentDeleteRequest) => {
      await documentApi.apiV10DocumentDelete(request);
      const response = await applicationApi.apiV10ApplicationPut({
        application: {
          ...application,
          pdfDocuments: { ...application?.pdfDocuments, eDeliveryConsentForm: undefined },
        },
      });

      if (response.error) {
        throw new Error(response.error.message || "Failed to delete E-Delivery Consent Document");
      }
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};
