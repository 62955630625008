export const createClaimEmail = (
  name: string,
  phone: string,
  email: string,
  dotNumber: string,
  policyNumber: string,
  claim: string,
) => {
  const to = "smallfleetadvantageclaims@ngic.com";
  const subject = "File a Claim";
  const message = `Name: ${name}\nPhone: ${phone}\nDOT Number: ${dotNumber}\nPolicy number: ${policyNumber}\nClaim Type: ${claim}\n\nThis is an SFARRG policy with AON.`;

  return `mailto:${to}?subject=${encodeURI(subject)}&body=${encodeURI(message)}`;
};
