import { CardField, EditableDeletableCard, PasswordInput } from "@chq/components";
import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import { FormikConfig, useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { usePasswordRules } from "../../data/usePasswordRules";
import { formatPhoneNumber } from "../../utils/phone-number-format";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: "1rem",
  },
  container: {
    paddingTop: "0.75rem",
    borderBottom: `solid .0625rem ${theme.palette.secondary.light}`,
  },
  button: {
    padding: "1.125rem 0rem",
    fontWeight: 700,
    fontSize: "12px",
  },
  buttonContainer: {
    paddingTop: ".625rem",
  },
  phone: {
    paddingBottom: ".625rem",
  },
  cancelButton: {
    padding: "1.125rem 0rem",
    fontWeight: 700,
  },
  passwordContainer: {
    paddingTop: "1.5rem",
  },
  updatePasswordButtonContainer: {
    paddingBottom: "1rem",
    paddingTop: "0.75rem",
  },
}));

type Props = {
  name: string;
  email: string;
  phoneNumber: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export enum Fields {
  currentPassword = "current-password",
  password = "password",
  reEnterPassword = "re-enter-password",
}

export type FormProps = {
  [Fields.currentPassword]: string;
  [Fields.password]: string;
  [Fields.reEnterPassword]: string;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();
  const passwordRules = usePasswordRules();

  return Yup.object({
    [Fields.currentPassword]: Yup.string().required(
      t(`errors.required`, { field: t(`policy-management-page.account-info-form.${Fields.currentPassword}.label`) }),
    ),
    [Fields.password]: passwordRules,
    [Fields.reEnterPassword]: Yup.string()
      .oneOf([Yup.ref([Fields.password].toString())], "")
      .required(t(`policy-management-page.account-info-form.${Fields.reEnterPassword}.help-text`)),
  });
};

const AccountInfoForm: React.FC<Props> = ({ name, email, phoneNumber, onSubmit }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const validationSchema = useValidationSchema();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.currentPassword]: "",
      [Fields.password]: "",
      [Fields.reEnterPassword]: "",
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
    validateOnMount: true,
    enableReinitialize: true,
  });
  return (
    <EditableDeletableCard
      variant="default"
      title={t("policy-management-page.account-info-form.title")}
      titleVariant="h2"
      titleComponent="h3"
      border={false}
      elevation={1}
      className={classes.title}
    >
      <Grid container className={classes.container}>
        <CardField id="contact-card-name" label={t("factoring.contact-card.name")} values={[{ value: name }]} />
        <CardField id="email" label={t("factoring.contact-card.email")} values={[{ value: email }]} />
        <CardField
          id="phone"
          label={t("factoring.business-details-card.phone")}
          values={[{ value: formatPhoneNumber(phoneNumber)! }]}
          className={classes.phone}
        />
      </Grid>
      {!isOpen && (
        <Grid container className={classes.buttonContainer}>
          <Grid item xs={12}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => setIsOpen(true)}
              className={classes.button}
            >
              {t("policy-management-page.account-info-form.button")}
            </Button>
          </Grid>
        </Grid>
      )}
      {isOpen && (
        <form onSubmit={formik.handleSubmit}>
          <Grid container className={classes.passwordContainer}>
            <Grid item xs={12}>
              <PasswordInput
                fullWidth
                required
                label={t("policy-management-page.account-info-form.current-password.label")}
                id={Fields.currentPassword}
                name={Fields.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.currentPassword] && Boolean(formik.errors[Fields.currentPassword])}
                value={formik.values[Fields.currentPassword]}
                helperText={formik.touched[Fields.currentPassword] && formik.errors[Fields.currentPassword]}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                fullWidth
                required
                label={t("policy-management-page.account-info-form.password.label")}
                rules={[
                  {
                    humanReadableFormat: t("common.password-rules.min-length"),
                    regEx: /.{8,}/,
                  },
                  {
                    humanReadableFormat: t("common.password-rules.uppercase"),
                    regEx: /[A-Z]/,
                  },
                  { humanReadableFormat: t("common.password-rules.digit"), regEx: /[0-9]/ },
                ]}
                id={Fields.password}
                name={Fields.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.password] && Boolean(formik.errors[Fields.password])}
                value={formik.values[Fields.password]}
                helperText={formik.touched[Fields.password] && formik.errors[Fields.password]}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                fullWidth
                required
                label={t("policy-management-page.account-info-form.re-enter-password.label")}
                rules={[
                  {
                    humanReadableFormat: t("common.password-rules.match"),
                    regEx: formik.values[Fields.password]
                      ? formik.values[Fields.password] === formik.values[Fields.reEnterPassword]
                      : false,
                  },
                ]}
                id={Fields.reEnterPassword}
                name={Fields.reEnterPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.reEnterPassword] && Boolean(formik.errors[Fields.reEnterPassword])}
                value={formik.values[Fields.reEnterPassword]}
                helperText={formik.touched[Fields.reEnterPassword] && formik.errors[Fields.reEnterPassword]}
              />
            </Grid>
            <Grid item xs={12} className={classes.updatePasswordButtonContainer}>
              <Button
                fullWidth
                disabled={!formik.isValid}
                color="primary"
                variant="contained"
                className={classes.cancelButton}
                onClick={() => {
                  formik.handleSubmit();
                  setIsOpen(false);
                }}
              >
                {t("policy-management-page.account-info-form.update-password-button")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => setIsOpen(false)}
                className={classes.cancelButton}
              >
                {t("policy-management-page.account-info-form.cancel-button")}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </EditableDeletableCard>
  );
};

export default AccountInfoForm;
