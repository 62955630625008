import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const DownloadIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M7.8 17.4H16.2V16.2H7.8V17.4ZM16.2 10.8H13.8V7.2H10.2V10.8H7.8L12 15L16.2 10.8Z" />
    </SvgIcon>
  );
};

export default DownloadIcon;
