import { TextFieldProps } from "@material-ui/core";
import React from "react";
import TextInput from "./text-input";

export type EmailInputProps = Omit<TextFieldProps, "type" | "variant"> & {
  labelAdornment?: React.ReactNode;
  labelEmbellishment?: React.ReactNode;
};

const EmailInput: React.FC<EmailInputProps> = (props) => <TextInput type="email" {...props} />;

export default EmailInput;
