/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserRegisterRequest
 */
export interface UserRegisterRequest {
  /**
   *
   * @type {string}
   * @memberof UserRegisterRequest
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserRegisterRequest
   */
  password?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserRegisterRequest
   */
  phoneNumber?: string | null;
}

export function UserRegisterRequestFromJSON(json: any): UserRegisterRequest {
  return UserRegisterRequestFromJSONTyped(json, false);
}

export function UserRegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegisterRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, "email") ? undefined : json["email"],
    password: !exists(json, "password") ? undefined : json["password"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
  };
}

export function UserRegisterRequestToJSON(value?: UserRegisterRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
    phoneNumber: value.phoneNumber,
  };
}
