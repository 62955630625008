import { Grid, makeStyles, Theme, Typography, TypographyProps } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import classNames from "classnames";
import React from "react";

interface LabelProps {
  labelText: string | React.ReactNode;
  required?: boolean;
  className?: string;
  variant: TypographyProps["variant"];
  containerStyle?: string;
  variantMapping?: Partial<Record<Variant, string>> | undefined;
}
const FieldLabel: React.FC<LabelProps> = ({
  required = true,
  labelText,
  className,
  variant,
  containerStyle,
  variantMapping,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classNames(containerStyle, classes.labelContainer)}>
      {required && (
        <Typography variant={variant} variantMapping={variantMapping} className={classes.required}>
          {"*"}
        </Typography>
      )}
      <Typography
        variant={variant}
        variantMapping={variantMapping}
        className={classNames(classes.inputLabel, className)}
      >
        {labelText}
      </Typography>
    </Grid>
  );
};

export default FieldLabel;
const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    fontWeight: "bold",
  },
  labelContainer: {
    display: "flex",
  },
  required: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    paddingRight: "0.188rem",
    transform: "translate(0em, 0.15em)",
  },
}));
