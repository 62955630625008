import { InputAdornment } from "@material-ui/core";
import React from "react";
import NumericInput, { NumericInputProps } from "./numeric-input";

type CurrencyInputProps = Omit<NumericInputProps, "thousandSeparator" | "decimalScale" | "allowNegative"> & {
  currencyAdornment?: string;
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({ value, currencyAdornment = "$", ...NumericInputProps }) => {
  return (
    <NumericInput
      {...NumericInputProps}
      InputProps={{
        startAdornment: <InputAdornment position="start">{currencyAdornment}</InputAdornment>,
        type: "tel",
      }}
      thousandSeparator
      decimalScale={2}
      allowNegative={false}
      value={value}
    />
  );
};

export default CurrencyInput;
