import { PreviousButton } from "@chq/components";
import { Button, CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import SaveQuoteForm, {
  Fields,
  useFormikConfig as useSaveQuoteFormikConfig,
} from "../../../components/save-quote-form";
import { useAddPassword, VerifiedStatus } from "../../../data/authentication";
import { useGetApplication } from "../../../data/enrollment";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1rem 0.75rem 2rem 0.75rem",
    alignSelf: "center",
    alignItems: "center",
  },
  pageTitle: {
    padding: "0.5rem 0.75rem 1.5rem 0.75rem",
    [theme.breakpoints.down("xs")]: {
      alignContent: "flex-start",
      textAlign: "flex-start",
    },
    [theme.breakpoints.up("sm")]: {
      alignContent: "center",
      textAlign: "center",
    },
  },
  buttonGroup: {
    paddingTop: "2.25rem",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  createButton: {
    minHeight: "3.5rem",
  },
}));

const SaveQuotePage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  const { data: application } = useGetApplication(RouteNames.saveQuote);
  const saveQuoteFormConfig = useSaveQuoteFormikConfig({ email: application?.business?.contactEmail });
  const { mutate: addPassword, isLoading } = useAddPassword();

  if (application?.insuranceProgram?.active === false) {
    return <Redirect to={routes.enrollment.quote.inactive.path} />;
  }

  return (
    <Formik
      initialValues={{
        ...saveQuoteFormConfig.initialValues,
      }}
      enableReinitialize={true}
      validationSchema={saveQuoteFormConfig.validationSchema}
      validateOnMount={true}
      onSubmit={(values) => {
        TagManager.dataLayer({
          dataLayer: {
            event: "createAnAccountEvent",
            createAnAccountText: "Create An Account",
          },
        });
        addPassword(
          { userId: application?.owner?.identityId, password: values[Fields.password] },
          {
            onSuccess: (response) => {
              if (response === VerifiedStatus.accountIsVerifiedPasswordCreated) {
                history.push(routes.enrollment.successAccount.path);
              } else if (response === VerifiedStatus.verfiyEmailSentPasswordCreated) {
                history.push(routes.enrollment.verify.path);
              }
            },
          },
        );
      }}
    >
      {(formik) => {
        return (
          <Grid container direction="column" className={classes.container} item xs={12} sm={8} md={6}>
            <Grid container item direction="column">
              <Grid container item direction="column" className={classes.pageTitle}>
                <Grid item>
                  <Typography variant="h2">{t("enrollment.save-quote-page.title")}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" component="p">
                    {t("enrollment.save-quote-page.subtitle")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <SaveQuoteForm />
              </Grid>
            </Grid>
            <Grid container item direction="row" className={classes.buttonGroup} spacing={2} xs={12} sm={8}>
              <Grid item xs={3}>
                <PreviousButton
                  fullWidth
                  color="primary"
                  variant="outlined"
                  className={classes.previousButton}
                  onClick={() => history.push(routes.enrollment.quote.path)}
                  aria-label={t("enrollment.save-quote-page.previous-button")}
                />
              </Grid>
              <Grid item xs={9}>
                <Button
                  fullWidth
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  disabled={!formik.isValid || isLoading}
                  variant="contained"
                  className={classes.createButton}
                >
                  {isLoading ? (
                    <CircularProgress
                      color="inherit"
                      size="2rem"
                      aria-label={t("common.circular-progress-aria-label")}
                    />
                  ) : (
                    t("enrollment.save-quote-page.create-button")
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default SaveQuotePage;
