import { PrimaryOperationType } from "@chq/enrollment-api";

export const useOperationTypes = (): PrimaryOperationType[] => {
  return [
    PrimaryOperationType.Agriculture,
    PrimaryOperationType.AutoTransporter,
    PrimaryOperationType.BulkDryBulk,
    PrimaryOperationType.BulkTankChemicalFoodGrade,
    PrimaryOperationType.Contractor,
    PrimaryOperationType.Drayage,
    PrimaryOperationType.DryFreightLTL,
    PrimaryOperationType.DryFreightTL,
    PrimaryOperationType.Dumping,
    PrimaryOperationType.Flatbed,
    PrimaryOperationType.FoodGrainDelivery,
    PrimaryOperationType.Intermodal,
    PrimaryOperationType.LoggingOperations,
    PrimaryOperationType.MobileHomeToters,
    PrimaryOperationType.NotOtherwiseSpecifiedAllOther,
    PrimaryOperationType.OilGasFieldOperations,
    PrimaryOperationType.RefrigeratedReefer,
    PrimaryOperationType.SpecializedCarriers,
    PrimaryOperationType.TankFuelOilPetroleum,
    PrimaryOperationType.WasteOrDriveAway,
  ];
};
