import { Dialog, makeStyles, Theme } from "@material-ui/core";
import { Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { UserLoginType, useUserLoginType } from "../data/authentication/useUserLoginType";
import { useSendResumeApplicationEmail } from "../data/enrollment";
import { routes } from "../ui/routes";
import ContinueQuoteEmail, {
  Fields as ContinueEmailFields,
  useFormikConfig as useContinueEmailFormikConfig,
} from "./continue-quote-email";
import StartNewQuote from "./start-new-quote";
import WelcomeBackQuote from "./welcome-back-quote";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: theme.palette.common.white,
  },
  description: {
    color: theme.palette.common.white,
    padding: ".625rem 1.5rem 2rem 1.5rem",
  },
  buttonContainer: {
    background: theme.palette.secondary.dark,
    justifyContent: "center",
  },
  button: {
    padding: "0rem 1.5rem 0rem 1.5rem",
    marginTop: "-1.5rem",
  },
  startedQuoteSection: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignContent: "center",
    },
    marginTop: "2rem",
    justifyContent: "center",
    alignItems: "center",
  },
  alreadyStartedQuote: {
    color: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1rem",
    },
    [theme.breakpoints.up("xs")]: {
      paddingRight: "1rem",
    },
  },
  continueQuoteButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.dark,
    fontSize: "12px",
    padding: "0.5rem 2.25rem 0.5rem 2.25rem",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },
      [theme.breakpoints.up("xs")]: {
        width: "50%",
      },
    },
    "& .MuiPaper-rounded": {
      borderRadius: "4px 4px 0px 0px",
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
      overflowX: "hidden",
      overflowY: "hidden",
      [theme.breakpoints.up("sm")]: {
        alignSelf: "center",
      },
    },
  },
  continueQuoteClose: {
    width: "1rem",
    height: "1rem",
    margin: "1rem",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  modalPaper: {
    backgroundColor: "rgba(0,0,0,0.65)",
    padding: "2rem 1em 2rem 1em",
    [theme.breakpoints.up("xs")]: {
      width: "93%",
      height: "100%",
      margin: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "35rem",
      margin: "1rem",
    },
  },
  modal: {
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  bullets: {
    color: theme.palette.common.white,
    textAlign: "left",
    fontSize: 16,
    lineHeight: "1.5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

enum StartQuotePageUserStatus {
  emailLookUpPending = 0,
  noEmail,
  hasEmailNoPassword,
  expired,
}

type Props = {
  open: boolean;
  onClose: () => void;
};

const ContinueQuoteDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const history = useHistory();

  const continueEmailFormikProps = useContinueEmailFormikConfig();
  const [status, setStatus] = useState<StartQuotePageUserStatus>(StartQuotePageUserStatus.emailLookUpPending);
  const { mutateAsync: getLoginType, isLoading: isLoginTypeLoading } = useUserLoginType();
  const { mutate: sendResumeApplicationEmail } = useSendResumeApplicationEmail();

  return (
    <Dialog className={classes.dialog} open={open} onClose={onClose}>
      <Formik
        {...continueEmailFormikProps}
        onSubmit={async (values) => {
          const loginType = await getLoginType(values[ContinueEmailFields.email]);
          switch (loginType) {
            case UserLoginType.magiclink: {
              setStatus(StartQuotePageUserStatus.hasEmailNoPassword);
              sendResumeApplicationEmail({ email: values[ContinueEmailFields.email] });
              break;
            }
            case UserLoginType.password: {
              history.push(routes.login.path);
              break;
            }
            case UserLoginType.none: {
              setStatus(StartQuotePageUserStatus.noEmail);
              break;
            }
          }
        }}
      >
        {(formik) => (
          <>
            {status === StartQuotePageUserStatus.emailLookUpPending && (
              <ContinueQuoteEmail isLoading={isLoginTypeLoading} onClose={onClose} />
            )}
            {status === StartQuotePageUserStatus.noEmail && (
              <StartNewQuote
                email={formik.values[ContinueEmailFields.email]}
                onPreviousClick={() => {
                  setStatus(StartQuotePageUserStatus.emailLookUpPending);
                }}
                onNextClick={onClose} // TODO: Add message saying this email does not exist.
              />
            )}
          </>
        )}
      </Formik>
      {status === StartQuotePageUserStatus.hasEmailNoPassword && <WelcomeBackQuote accountExpired={false} />}
      {status === StartQuotePageUserStatus.expired && <WelcomeBackQuote accountExpired />}
    </Dialog>
  );
};

export default ContinueQuoteDialog;
