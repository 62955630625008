import { route } from "static-route-paths";

export const routes = route({
  root: route(),
  login: route("login"),
  emailLink: route("email-link"),
  resetPassword: route("reset-password"),
  enrollment: route("enrollment", {
    campaign: route("campaign", { campaign_id: route(":campaign_id") }),
    partner: route("partner", { partner_name: route(":partner_name") }),
    qualify: route("qualify"),
    notQualify: route("not-qualified"),
    pendingQualify: route("pending-qualify", {
      powerUnit: route("power-unit"),
      state: route("state"),
      // This is when both power units and state are not eligible
      overall: route("overall"),
    }),
    basicInfo: route("basic-info"),
    equipment: route("equipment"),
    drivers: route("drivers"),
    coverage: route("coverage"),
    saveQuote: route("save-quote"),
    verify: route("verify"),
    success: route("success"),
    successAccount: route("account-success"),
    finishApplication: route("finish-application"),
    payment: route("payment"),
    review: route("review-application"),
    quote: route("quote", {
      pending: route("pending"),
      bound: route("bound"),
      inactive: route("next-steps"),
    }),
  }),
  policyManagement: route("policy-management", {
    dashboard: route("dashboard"),
    coverages: route("coverages", {
      edit: route("edit"),
    }),
    equipment: route("equipment", {
      edit: route("edit"),
    }),
    reviewEquipmentAndCoverages: route("review-equipment-and-coverages", {
      success: route("success"),
    }),
    drivers: route("drivers"),
    information: route("information"),
    documents: route("documents"),
    logout: route("logout"),
  }),
  unauthorized: route("not-authorized"),
  notFound: route("not-found"),
  loading: route("loader"),
});
