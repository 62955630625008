import { AlertType, Checkbox, EditableDeletableCard, PageAlert, PasswordInput, TextInput } from "@chq/components";
import { Button, CircularProgress, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useAuth } from "./authentication-provider";

const useStyles = makeStyles(() => ({
  title: {
    paddingTop: "1rem",
  },
  email: {
    paddingTop: "1.375rem",
  },
  checkbox: {
    paddingLeft: "1.5rem",
  },
  loginButton: {
    padding: "1rem 0rem",
  },
  forgotPassword: {
    cursor: "pointer",
  },
  forgotPasswordContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttons: {
    paddingTop: "1rem",
  },
  form: {
    padding: "0rem 1rem 0rem 1rem",
    margin: "auto",
  },
  pageAlert: {
    background: "linear-gradient(to right, rgba(204,0,0,0.15), rgba(204,0,0,0.15))",
    backgroundColor: "rgba(255,255,255,0.8)",
  },
}));

export enum Fields {
  email = "email",
  password = "password",
  signedIn = "signed-in",
}

type FormProps = {
  [Fields.email]: string;
  [Fields.password]: string;
  [Fields.signedIn]?: boolean;
};

type Props = {
  title: string;
  email?: string;
  password?: string;
  signedIn?: boolean;
  returnTo?: string;
  forgotPassOnClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const useValidateSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.email]: Yup.string()
      .email(t("errors.invalid-email"))
      .required(t(`errors.required`, { field: t(`login-form.${Fields.email}.label`) })),
    [Fields.password]: Yup.string().required(t(`errors.required`, { field: t(`login-form.${Fields.password}.label`) })),
    [Fields.signedIn]: Yup.boolean(),
  });
};

export const LoginForm: React.FC<Props> = ({
  title,
  email: initialEmail = "",
  password: initialPassword = "",
  signedIn: initialSignedIn = false,
  returnTo,
  forgotPassOnClick,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const validationSchema = useValidateSchema();
  const {
    loginWithEmailAndPassword: [{ error, loading: isAuthenticating }, loginWithEmailAndPassword],
  } = useAuth();

  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.email]: initialEmail,
      [Fields.password]: initialPassword,
      [Fields.signedIn]: initialSignedIn,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, formik) => {
      try {
        formik.setSubmitting(true);
        await loginWithEmailAndPassword(
          values[Fields.email],
          values[Fields.password],
          Boolean(values[Fields.signedIn]),
          returnTo,
        );
        formik.setSubmitting(false);
      } catch (e) {
        formik.setSubmitting(false);
      }
    },
  });

  return (
    <Grid item className={classes.form} xs={12} sm={8} lg={5}>
      {!isAuthenticating && error && (
        <PageAlert
          alertType={AlertType.error}
          className={classes.pageAlert}
          message={
            <Typography variant="h4" component="p">
              {t("login-page.invalid-email-or-password")}
            </Typography>
          }
        />
      )}
      <EditableDeletableCard variant="default" className={classes.title} titleVariant="h3" title={title}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                required
                label={t("login-form.email.label")}
                id={`login-form-${Fields.email}`}
                name={Fields.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[Fields.email]}
                error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
                helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
                className={classes.email}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                fullWidth
                required
                label={t("login-form.password.label")}
                id={Fields.password}
                name={Fields.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[Fields.password]}
                error={formik.touched[Fields.password] && Boolean(formik.errors[Fields.password])}
                helperText={formik.touched[Fields.password] && formik.errors[Fields.password]}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                label={t("login-form.checkbox.label")}
                id={Fields.signedIn}
                name={Fields.signedIn}
                value={formik.values[Fields.signedIn]}
                onChange={formik.handleChange}
                className={classes.checkbox}
              />
            </Grid>
            <Grid item container spacing={3} className={classes.buttons}>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={formik.isSubmitting || !formik.isValid}
                  className={classes.loginButton}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress color="inherit" size="2rem" />
                  ) : (
                    t("login-form.login-button")
                  )}
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.forgotPasswordContainer}>
                <Typography variant="h3" className={classes.forgotPassword}>
                  <Link onClick={forgotPassOnClick}>{t("login-form.forgot-password")}</Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </EditableDeletableCard>
    </Grid>
  );
};
