const Log = console;

import { IFrameWindow } from "./iframeWindow.js";

export class IFrameNavigator {
  prepare(params) {
    let frame = new IFrameWindow(params);
    return Promise.resolve(frame);
  }

  callback(url) {
    Log.debug("IFrameNavigator.callback");

    try {
      IFrameWindow.notifyParent(url);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
