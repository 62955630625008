import { FormikConfig } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

enum Fields {
  name = "name",
  email = "email",
  phone = "phone",
  problem = "problem",
  description = "description",
}

type Props = {
  name?: string;
  email?: string;
  phone?: string;
  problem?: string;
  description?: string;
};

export type FormProps = {
  [Fields.name]: string;
  [Fields.email]: string;
  [Fields.phone]: string;
  [Fields.problem]: string;
  [Fields.description]: string;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();
  return yup.object({
    [Fields.name]: yup.string().required(t("errors.required", { field: t("footer.name") })),
    [Fields.email]: yup
      .string()
      .email(t("errors.invalid-email"))
      .required(t("errors.required", { field: t("footer.email") })),
    [Fields.phone]: yup
      .string()
      .required(t("errors.required", { field: t("footer.phone") }))
      .test("length", t(`errors.phone-number`), (val) => val !== undefined && val.toString().length === 10),
    [Fields.problem]: yup.string().required(t("errors.required", { field: t("footer.problem") })),
    [Fields.description]: yup.string().when(Fields.problem, {
      is: t("footer.problem-options.6"),
      then: yup.string().required(t("errors.required", { field: t("footer.description") })),
    }),
  });
};

export const useFormikConfig = ({
  email: initialEmail = "",
  name: initialName = "",
  phone: initialPhone = "",
  problem: initialProblem = "",
  description: initialDescription = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.email]: initialEmail,
      [Fields.name]: initialName,
      [Fields.phone]: initialPhone,
      [Fields.problem]: initialProblem,
      [Fields.description]: initialDescription,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};
