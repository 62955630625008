/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AccountInfo
 */
export interface AccountInfo {
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  accountId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  mask?: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  officialName?: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  subtype?: string | null;
}

export function AccountInfoFromJSON(json: any): AccountInfo {
  return AccountInfoFromJSONTyped(json, false);
}

export function AccountInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accountId: !exists(json, "account_id") ? undefined : json["account_id"],
    mask: !exists(json, "mask") ? undefined : json["mask"],
    name: !exists(json, "name") ? undefined : json["name"],
    officialName: !exists(json, "official_name") ? undefined : json["official_name"],
    type: !exists(json, "type") ? undefined : json["type"],
    subtype: !exists(json, "subtype") ? undefined : json["subtype"],
  };
}

export function AccountInfoToJSON(value?: AccountInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    account_id: value.accountId,
    mask: value.mask,
    name: value.name,
    official_name: value.officialName,
    type: value.type,
    subtype: value.subtype,
  };
}
