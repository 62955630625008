import { makeStyles, SvgIconProps, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import ClampLines from "react-clamp-lines";
import ChevronDownIcon from "./icons/chevron-down";

type ClampProps = {
  text: string;
  lines?: number;
  ellipsis?: string;
  buttons?: boolean;
  moreText?: string;
  lessText?: string;
  className?: string;
  delay?: number;
  stopPropagation?: boolean;
  innerElement?: string;
};
const useStyles = makeStyles((theme: Theme) => ({
  clamp: {
    display: "flex",
    backgroundColor: "transparent",
    alignItems: "flex-end",
    "& div#clamped-content-clamp-lines-id": {
      color: theme.palette.primary.main,
    },
    "& button.clamp-lines__button": {
      border: "none",
      backgroundColor: "transparent",
    },
    "& div": {
      color: theme.palette.secondary.main,
      fontSize: ".875rem",
      lineHeight: "1.25rem",
    },
  },
  transparent: {
    backgroundColor: "transparent",
  },
  fonts: theme.typography.subtitle1,
  upArrow: {
    transform: "rotate(180deg)",
  },
}));

type Props = Omit<ClampProps, "moreText" | "lessText"> & {
  id: string;
  lines?: number;
  LessIconProps: SvgIconProps;
  MoreIconProps: SvgIconProps;
};

const ExpandableTextLabel: React.FC<Props> = ({ text, id, lines = 3, LessIconProps, MoreIconProps, ...props }) => {
  const classes = useStyles();
  return (
    <ClampLines
      id={id}
      className={classNames(classes.clamp, classes.fonts)}
      text={text}
      lines={lines}
      moreText={
        ((<ChevronDownIcon color="primary" {...MoreIconProps} className={classes.transparent} />) as unknown) as string
      }
      lessText={
        ((
          <ChevronDownIcon
            color="primary"
            className={classNames(classes.upArrow, classes.transparent)}
            {...LessIconProps}
          />
        ) as unknown) as string
      }
      {...props}
    />
  );
};

export default ExpandableTextLabel;
