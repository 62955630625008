import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import React from "react";
import FormControl, { FormControlProps } from "./form-control";
import { DATE_FORMAT } from "./utils/date-format";

type Props = Omit<FormControlProps, "children" | "labelId" | "helperTextId"> &
  Omit<KeyboardDatePickerProps, "margin" | "format" | "inputVariant">;

const DatePicker: React.FC<Props> = ({
  classes,
  className,
  color = "primary",
  disabled = false,
  error = false,
  FormHelperTextProps,
  fullWidth = false,
  focused,
  helperText,
  hiddenLabel,
  id,
  InputLabelProps,
  label,
  labelAdornment,
  labelEmbellishment,
  margin = "normal",
  required = false,
  size,
  variant,
  onChange,
  value,
  ...KeyboardDatePickerProps
}) => {
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;
  return (
    <FormControl
      className={classNames(classes?.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      focused={focused}
      hiddenLabel={hiddenLabel}
      required={required}
      color={color}
      FormHelperTextProps={FormHelperTextProps}
      helperTextId={helperTextId}
      helperText={helperText}
      labelId={inputLabelId}
      label={label}
      labelAdornment={labelAdornment}
      labelEmbellishment={labelEmbellishment}
      margin={margin}
      id={id}
      InputLabelProps={InputLabelProps}
      size={size}
      variant={variant}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="none"
          id={id}
          disabled={disabled}
          error={error}
          fullWidth={fullWidth}
          focused={focused}
          hiddenLabel={true}
          required={required}
          color={color}
          size={size}
          variant={variant}
          onChange={onChange}
          value={value}
          format={DATE_FORMAT}
          inputVariant={variant}
          {...KeyboardDatePickerProps}
          helperText={null}
          placeholder="mm/dd/yyyy"
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

export default DatePicker;
