import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const DeleteIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M16.2 7.20001H14.1L13.5 6.60001H10.5L9.89999 7.20001H7.79999V8.40001H16.2V7.20001ZM8.39999 16.2C8.39999 16.5183 8.52642 16.8235 8.75146 17.0485C8.9765 17.2736 9.28173 17.4 9.59999 17.4H14.4C14.7182 17.4 15.0235 17.2736 15.2485 17.0485C15.4736 16.8235 15.6 16.5183 15.6 16.2V9.00001H8.39999V16.2Z" />
    </SvgIcon>
  );
};

export default DeleteIcon;
