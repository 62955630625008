import { DRAWER_WIDTH, GeneralModal, HamburgerMenu, Header, LogoType } from "@chq/components";
import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { PolicyCoveragesProvider } from "../../data/policy-management/PolicyManagementCoveragesContext";
import { PolicyEquipmentProvider } from "../../data/policy-management/PolicyManagementEquipmentContext";
import Footer from "../components/footer";
import { routes } from "../routes";
import HeaderText from "../shared/header-text";
import PolicyManagementMenuItems from "./components/policy-management-menu-items";
import { PolicyManagementStepper } from "./components/policy-management-stepper";
import EcertModalContent from "./modal-content/ecertModalContent";
import CoveragesPage from "./pages/coverages-page";
import DashboardPage from "./pages/dashboard-page";
import DocumentsPage from "./pages/documents-page";
import EditCoveragesPage from "./pages/edit-coverages-page";
import EditEquipmentPage from "./pages/edit-equipment-page";
import EquipmentPage from "./pages/equipment-page";
import PersonalInfoPage from "./pages/personal-info-page";
import ReviewCoveragesAndEquipmentPage from "./pages/review-coverages-and-equipment-page";
import SubmittedChangesPage from "./pages/submitted-changes-page";

const useStyles = makeStyles((theme: Theme) => ({
  enrollmentContainer: {
    minHeight: "100vh",
  },
  fullHeight: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  responsiveBody: {
    "& > .container": {
      [theme.breakpoints.up("lg")]: {
        marginLeft: DRAWER_WIDTH,
        alignSelf: "center",
        width: `calc(100vw - ${DRAWER_WIDTH})`,
      },
    },
  },
  headerContainer: {
    textAlign: "center",
  },
  stepper: {
    paddingRight: "1.75rem",
  },
}));

export const policyManagementRouteMap = {
  [routes.policyManagement.dashboard.path]: "policy-management-page.dashboard-page.header-text",
  [routes.policyManagement.coverages.path]: "policy-management-page.coverages-page.header-text",
  [routes.policyManagement.equipment.path]: "policy-management-page.equipment-page.header-text",
  [routes.policyManagement.information.path]: "policy-management-page.company-info-page.header-text",
  [routes.policyManagement.documents.path]: "policy-management-page.documents-page.header-text",
};

export const menuNotVisibleRouteMap = {
  [routes.policyManagement.coverages.edit.path]: true,
  [routes.policyManagement.equipment.edit.path]: true,
  [routes.policyManagement.reviewEquipmentAndCoverages.path]: true,
};

const PolicyManagementRoutes: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [ecertModalOpen, setEcertModalOpen] = useState(false);

  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid container direction="column" className={classes.enrollmentContainer}>
      <Grid item className={classes.headerContainer}>
        <Header
          logo={LogoType.AON}
          location={location.pathname.toLowerCase()}
          menuNotVisibleStatus={menuNotVisibleRouteMap}
          aonTitleAccess={t("common.aon-logo")}
          aonAriaLabel={t("common.aon-logo")}
          chqTitleAccess={t("common.chq-logo")}
          chqAriaLabel={t("common.chq-logo")}
          headerBarTitleAccess={t("common.middle-bar")}
          headerBarAriaLabel={t("common.middle-bar")}
          onDrawerToggle={() =>
            setDrawerOpen((prevState) => {
              return !prevState;
            })
          }
          drawerOpen={drawerOpen}
        >
          <Grid className={classes.stepper}>
            <PolicyManagementStepper />
          </Grid>
          <HeaderText routeMap={policyManagementRouteMap} />
        </Header>
      </Grid>

      <div className={classNames(classes.fullHeight, { [classes.responsiveBody]: drawerOpen })}>
        <HamburgerMenu open={drawerOpen}>
          <PolicyManagementMenuItems setEcertModalOpen={setEcertModalOpen} setDrawerOpen={setDrawerOpen} />
        </HamburgerMenu>
        <PolicyEquipmentProvider>
          <PolicyCoveragesProvider>
            <div className={"container"}>
              {ecertModalOpen && (
                <GeneralModal
                  buttonText={t("policy-management-page.ecert-modal.button")}
                  buttonLink="https://ecertsonline.com/Default.asp"
                  open={ecertModalOpen}
                  onClose={() => setEcertModalOpen(false)}
                >
                  <EcertModalContent />
                </GeneralModal>
              )}
              <Switch>
                <Route exact path={routes.policyManagement.dashboard.path} component={DashboardPage} />
                <Route exact path={routes.policyManagement.coverages.path} component={CoveragesPage} />
                <Route exact path={routes.policyManagement.coverages.edit.path} component={EditCoveragesPage} />
                <Route exact path={routes.policyManagement.equipment.path} component={EquipmentPage} />
                <Route exact path={routes.policyManagement.equipment.edit.path} component={EditEquipmentPage} />
                <Route
                  exact
                  path={routes.policyManagement.reviewEquipmentAndCoverages.path}
                  component={ReviewCoveragesAndEquipmentPage}
                />
                <Route
                  exact
                  path={routes.policyManagement.reviewEquipmentAndCoverages.success.path}
                  component={SubmittedChangesPage}
                />
                {/* This page isn't functional for release 1.0 */}
                {/* <Route exact path={routes.policyManagement.speedgauge.path} component={SpeedgaugePage} /> */}
                <Route exact path={routes.policyManagement.drivers.path} render={() => <div>Drivers Page</div>} />
                <Route exact path={routes.policyManagement.information.path} component={PersonalInfoPage} />
                <Route exact path={routes.policyManagement.documents.path} component={DocumentsPage} />
                <Redirect to={routes.policyManagement.dashboard.path} />
              </Switch>
            </div>
          </PolicyCoveragesProvider>
        </PolicyEquipmentProvider>
      </div>
      {!(mobileView && drawerOpen) && (
        <Grid item>
          <Footer />
        </Grid>
      )}
    </Grid>
  );
};

export default PolicyManagementRoutes;
