import { Application, ApplicationApi } from "@chq/enrollment-api";
import { useCallback } from "react";
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "react-query";
import { useApi } from "../useApi";
import { useUpdateCachedApplicationReview } from "./useGetApplicationReview";

const getCurrentApplicationCacheKey = () => "current-application";

export const useGetApplication = (
  applicationState: undefined | string,
  options?: UseQueryOptions<Application, Error>,
): UseQueryResult<Application, Error> => {
  const applicationApi = useApi(ApplicationApi);
  const updateApplication = useUpdateCachedApplicationReview();

  const query = useQuery<Application, Error>(
    getCurrentApplicationCacheKey(),
    async () => {
      if (applicationState !== undefined) {
        await applicationApi.apiV10ApplicationUpdateApplicationStatePut({ applicationState });
      }
      const response = await applicationApi.apiV10ApplicationGet();
      if (response.error) {
        throw new Error(response.error.message || "Unable to find application");
      }
      return response.data!;
    },
    {
      ...options,
      onSuccess: (application) => {
        updateApplication({ application, bankAccountInfo: null });
      },
    },
  );
  return query;
};

export const useGetCachedApplication = () => {
  const cache = useQueryClient();
  return cache.getQueryData<Application>(getCurrentApplicationCacheKey());
};

export const useUpdateCachedApplication = () => {
  const cache = useQueryClient();
  const updateApplication = useUpdateCachedApplicationReview();
  return useCallback(
    (application: Application) => {
      cache.setQueryData(getCurrentApplicationCacheKey(), application);
      updateApplication({ application, bankAccountInfo: null });
    },
    [cache, updateApplication],
  );
};

export const useCachedApplication = () => {
  const application = useGetCachedApplication();
  const updateApplication = useUpdateCachedApplication();
  return { application, updateApplication };
};
