import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

type Props = {
  confirmText: string;
  confirmAction: () => void;
  cancelText: string;
  cancelAction: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  deleteOverlay: {
    position: "absolute",
    borderRadius: ".2rem .2rem .2rem .2rem",
    top: "0",
    height: "100%",
    marginLeft: "auto",
    width: "100%",
    backgroundColor: `${theme.palette.secondary.main}60`,
  },
  confirmText: {
    color: theme.palette.common.white,
    fontWeight: 700,
    padding: "0.5rem 3.75rem 0.5rem 3.75rem",
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
  },
  cancelButton: {
    "&.MuiButtonBase-root": {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
    "&.MuiButton-contained .MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
  cancelText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    padding: "0.5rem 3.75rem 0.5rem 3.75rem",
  },
  topButton: {
    marginBottom: "1.5rem",
  },
}));

const ComponentConfirmOverlay: React.FC<Props> = ({ confirmText, confirmAction, cancelText, cancelAction }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.deleteOverlay} container alignContent="center" justify="center">
      <Grid item xs={12} md={8} className={classes.topButton}>
        <Button fullWidth variant="contained" className={classes.redButton} onClick={() => confirmAction()}>
          <Typography variant="h2" component="h2" className={classes.confirmText}>
            {confirmText}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} md={8}>
        <Button fullWidth variant="contained" className={classes.cancelButton} onClick={() => cancelAction()}>
          <Typography variant="h2" component="h2" className={classes.confirmText}>
            {cancelText}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ComponentConfirmOverlay;
