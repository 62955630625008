/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { FactoringAddress, FactoringAddressFromJSON, FactoringAddressFromJSONTyped, FactoringAddressToJSON } from "./";

/**
 *
 * @export
 * @interface FactoringCustomer
 */
export interface FactoringCustomer {
  /**
   *
   * @type {number}
   * @memberof FactoringCustomer
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FactoringCustomer
   */
  businessName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringCustomer
   */
  mcNumber?: string | null;
  /**
   *
   * @type {number}
   * @memberof FactoringCustomer
   */
  termDays?: number | null;
  /**
   *
   * @type {number}
   * @memberof FactoringCustomer
   */
  monthlySales?: number | null;
  /**
   *
   * @type {FactoringAddress}
   * @memberof FactoringCustomer
   */
  customerAddress?: FactoringAddress;
  /**
   *
   * @type {number}
   * @memberof FactoringCustomer
   */
  factoringApplicationId?: number;
}

export function FactoringCustomerFromJSON(json: any): FactoringCustomer {
  return FactoringCustomerFromJSONTyped(json, false);
}

export function FactoringCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FactoringCustomer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    businessName: !exists(json, "businessName") ? undefined : json["businessName"],
    mcNumber: !exists(json, "mcNumber") ? undefined : json["mcNumber"],
    termDays: !exists(json, "termDays") ? undefined : json["termDays"],
    monthlySales: !exists(json, "monthlySales") ? undefined : json["monthlySales"],
    customerAddress: !exists(json, "customerAddress") ? undefined : FactoringAddressFromJSON(json["customerAddress"]),
    factoringApplicationId: !exists(json, "factoringApplicationId") ? undefined : json["factoringApplicationId"],
  };
}

export function FactoringCustomerToJSON(value?: FactoringCustomer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    businessName: value.businessName,
    mcNumber: value.mcNumber,
    termDays: value.termDays,
    monthlySales: value.monthlySales,
    customerAddress: FactoringAddressToJSON(value.customerAddress),
    factoringApplicationId: value.factoringApplicationId,
  };
}
