import { EditableDeletableCard, Select } from "@chq/components";
import { Button, createStyles, Grid, Link, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormikConfig } from "formik";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createClaimEmail } from "../../utils/create-claim-email";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      "& > .MuiGrid-root": {
        paddingTop: "1rem",
        paddingBottom: "1rem",
      },
    },
    infoContainer: {
      backgroundColor: theme.palette.grey[200],
      textAlign: "center",
      padding: "0.625rem",
    },
    fileLink: {
      ...theme.typography.body1,
      color: theme.palette.primary.main,
    },
    uppercase: {
      textTransform: "uppercase",
    },
    button: {
      minHeight: "56px",
      marginBottom: "1rem",
    },
    buttonContainer: {
      margin: "1rem 0rem",
    },
    needToFileContainer: {
      display: "flex",
      justifyContent: "center",
    },
    callUsContainer: {
      textAlign: "center",
      marginBottom: "1.2rem",
    },
    bold: {
      fontWeight: 700,
    },
  }),
);

export enum Fields {
  claimType = "claim-type",
}

export enum Options {
  autoLiability = "auto-liability",
  cargo = "cargo",
}

export type Props = {
  name?: string;
  phone?: string;
  email?: string;
  dotNumber?: string;
  policyNumber?: string;
  claim?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export type FormProps = {
  [Fields.claimType]?: Options | string;
};

const claimTypes = [Options.autoLiability, Options.cargo];

const FileClaimCard: React.FC<Props> = ({ name = "", phone = "", email = "", dotNumber = "", policyNumber = "" }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [claim, setClaim] = useState<string | undefined>(undefined);

  return (
    <EditableDeletableCard variant="default" className={classes.container} border={false} elevation={1}>
      <form>
        <Grid container item direction="column">
          <Grid item className={classes.needToFileContainer}>
            <Typography variant="h4" className={classes.uppercase}>
              {t("policy-management-page.dashboard-page.file-claim-card.need-to-file")}
            </Typography>
          </Grid>
          <Grid container item xs={12} wrap="nowrap">
            <Select
              fullWidth
              id={Fields.claimType}
              name={Fields.claimType}
              label={t("policy-management-page.dashboard-page.file-claim-card.file-claim-form-label")}
              items={claimTypes.map((claimType) => ({
                name: t(`policy-management-page.dashboard-page.file-claim-card.claim-types.${claimType}`),
                value: claimType,
              }))}
              value={claim}
              required
              onChange={(e) => setClaim(e.target.value)}
            />
          </Grid>
          {(claim === Options.cargo || claim === Options.autoLiability) && (
            <Grid item xs={12} className={classes.buttonContainer}>
              {claim === Options.autoLiability && (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={claim === undefined ? true : false}
                  fullWidth
                  className={classes.button}
                  href={createClaimEmail(
                    name,
                    phone,
                    email,
                    dotNumber,
                    policyNumber,
                    t(`policy-management-page.dashboard-page.file-claim-card.claim-types.${claim}`),
                  )}
                >
                  {t("policy-management-page.dashboard-page.file-claim-card.file-claim-button")}
                </Button>
              )}
              <Grid item className={classes.callUsContainer}>
                <Trans i18nKey={`policy-management-page.dashboard-page.file-claim-card.phone-numbers.${claim}`}>
                  <Typography variant="body1" component="span">
                    policy-management-page.dashboard-page.file-claim-card.phone-numbers.auto-liability-number
                  </Typography>
                  <Link
                    href={`tel:${t(
                      `policy-management-page.dashboard-page.file-claim-card.phone-numbers.${claim}-number`,
                    )}`}
                    className={classes.fileLink}
                  >
                    policy-management-page.dashboard-page.file-claim-card.phone-numbers.auto-liability-number
                  </Link>
                </Trans>
              </Grid>
              <Grid item>
                <Paper elevation={0} className={classes.infoContainer}>
                  <Grid item>
                    <Trans
                      i18nKey={"policy-management-page.dashboard-page.file-claim-card.dot-number"}
                      values={{ dotNumber }}
                    >
                      <Typography variant="body1" component="span">
                        Dot Number:
                      </Typography>
                      <Typography variant="h3" component="span" className={classes.bold}></Typography>
                    </Trans>
                  </Grid>
                  <Grid item>
                    {claim === Options.cargo && (
                      <Trans i18nKey={"policy-management-page.dashboard-page.file-claim-card.policy-number-cargo"}>
                        <Typography variant="body1" component="span">
                          Policy Number:
                        </Typography>
                        <Typography variant="h3" component="span" className={classes.bold}>
                          values
                        </Typography>
                      </Trans>
                    )}
                    {claim === Options.autoLiability && (
                      <Trans
                        i18nKey={"policy-management-page.dashboard-page.file-claim-card.policy-number-auto"}
                        values={{ policyNumber }}
                      >
                        <Typography variant="body1" component="span">
                          Policy Number:
                        </Typography>
                        <Typography variant="h3" component="span" className={classes.bold}></Typography>
                      </Trans>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      {t("policy-management-page.dashboard-page.file-claim-card.tell-them")}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </EditableDeletableCard>
  );
};

export default FileClaimCard;
