/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  StripeBalance,
  StripeBalanceFromJSON,
  StripeBalanceFromJSONTyped,
  StripeBalanceToJSON,
} from "./";

/**
 *
 * @export
 * @interface StripeBalanceApiResponse
 */
export interface StripeBalanceApiResponse {
  /**
   *
   * @type {StripeBalance}
   * @memberof StripeBalanceApiResponse
   */
  data?: StripeBalance;
  /**
   *
   * @type {ErrorResponse}
   * @memberof StripeBalanceApiResponse
   */
  error?: ErrorResponse;
}

export function StripeBalanceApiResponseFromJSON(json: any): StripeBalanceApiResponse {
  return StripeBalanceApiResponseFromJSONTyped(json, false);
}

export function StripeBalanceApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StripeBalanceApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : StripeBalanceFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function StripeBalanceApiResponseToJSON(value?: StripeBalanceApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: StripeBalanceToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
