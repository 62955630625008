import { EditableDeletableCard } from "@chq/components";
import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: "1rem",
    paddingLeft: "0.5rem",
  },
  button: {
    padding: "1rem 3rem",
  },
  buttonContainer: {
    paddingTop: "1rem",
  },
}));

type Props = {
  email: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ResetPasswordEmailConfirmation: React.FC<Props> = ({ email, onClick }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard
      variant="default"
      titleVariant="h3"
      title={t("reset-password-email-confirmation.title", { email: email })}
      className={classes.title}
    >
      <Grid container>
        <Grid item className={classes.buttonContainer}>
          <Button variant="contained" onClick={onClick} className={classes.button}>
            {t("reset-password-email-confirmation.ok")}
          </Button>
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default ResetPasswordEmailConfirmation;
