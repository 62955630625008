import {
  ChevronDownIcon as Chevron,
  EditableDeletableCard,
  EligibilityStates,
  EligibilityStatus,
} from "@chq/components";
import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";
import FleetErrorEmailCard from "./fleet-error-email-card";

const useStyles = makeStyles((theme: Theme) => ({
  buttonIcon: {
    transform: "rotate(90deg)",
    width: "auto",
    height: "40px",
    padding: "0px",
  },
  paper: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: "1rem 0.625rem",
  },
  backButton: {
    ...theme.typography.h3,
    margin: "1rem 0rem",
    padding: "0rem 1rem 0rem 0rem",
    textTransform: "none",
    color: theme.palette.primary.main,
  },
  explanationContainer: {
    paddingBottom: "1.5rem",
  },
  errorCard: {
    paddingBottom: "1.125rem",
  },
  eligibilityStatus: {
    marginBottom: "1.5rem",
    "& div > div": {
      display: "contents",
      "& svg": {
        height: "40px",
        width: "auto",
      },
    },
  },
  message: {
    fontWeight: 700,
    paddingTop: "1.5rem",
  },
  helpText: {
    paddingTop: "1.5rem",
  },
  card: {
    backgroundColor: theme.palette.grey[200],
    paddingTop: "1rem",
  },
}));

export type Props = {
  message: string;
  helpText: string;
};

const DNQPageContent: React.FC<Props> = ({ message, helpText }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Grid container direction="column" alignContent="center">
      <Grid xs={12} item>
        <Button
          startIcon={<Chevron className={classes.buttonIcon} />}
          className={classes.backButton}
          color={"primary"}
          onClick={() => {
            history.push(routes.enrollment.path);
          }}
        >
          {t("enrollment.did-not-qualify-page.back-button-text")}
        </Button>
      </Grid>
      <Grid xs={12} sm={8} lg={4} item className={classes.explanationContainer}>
        <EditableDeletableCard
          titleElement={
            <EligibilityStatus
              status={EligibilityStates.INELIGIBLE}
              statusText={t("enrollment.pending-qualify-page.title")}
              className={classes.eligibilityStatus}
            />
          }
          className={classes.card}
          variant="default"
        >
          <Typography className={classes.message}>{message}</Typography>
          <Typography className={classes.helpText}>{helpText}</Typography>
        </EditableDeletableCard>
      </Grid>
      <Grid xs={12} sm={8} lg={4} item className={classes.errorCard}>
        <FleetErrorEmailCard />
      </Grid>
    </Grid>
  );
};

export default DNQPageContent;
