import { CardField, EditableDeletableCard, EditableDeletableCardProps, format, formatEinNumber } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  containerClass: {
    paddingBottom: "0.625rem",
  },
  sectionLine: {
    borderBottom: `solid .063rem ${theme.palette.secondary.light}`,
    width: "100%",
    paddingBottom: "0.5rem",
    marginBottom: ".25rem",
  },
  belowSectionLine: {
    paddingTop: "0.5rem",
  },
}));

type Props = {
  ein?: string;
  mcNumber?: string;
  operatingAuthStatus?: string;
  desiredEffectiveDate?: Date | null;
  onEdit: EditableDeletableCardProps["onEdit"];
};

const CompanyInfoCard: React.FC<Props> = ({ ein, mcNumber, operatingAuthStatus, desiredEffectiveDate, onEdit }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard
      title={t("company-info-card.title")}
      variant="edit"
      titleVariant="h2"
      titleComponent="h4"
      containerClass={classes.containerClass}
      onEdit={onEdit}
      IconButtonProps={{ "aria-label": t("company-info-card.edit-button") }}
    >
      <Grid container>
        <CardField id="ein" label={t("company-info-card.ein")} values={[{ value: formatEinNumber(ein!)! }]} />
        <CardField id="mcNumber" label={t("company-info-card.mc-number")} values={[{ value: mcNumber }]} />
        <CardField
          id="operatingAuthStatus"
          label={t("company-info-card.operating-auth-status")}
          values={[{ value: operatingAuthStatus }]}
          className={classes.sectionLine}
          valueClass={classes.sectionLine}
        />
        <CardField
          id="desiredEffectiveDate"
          label={t("company-info-card.desired-effective-date")}
          values={[{ value: format(desiredEffectiveDate!), valueColor: "primary" }]}
          className={classes.belowSectionLine}
          valueClass={classes.belowSectionLine}
          labelColor="textPrimary"
        />
      </Grid>
    </EditableDeletableCard>
  );
};

export default CompanyInfoCard;
