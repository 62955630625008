import { theme } from "@chq/styles";
import { Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import TextInput, { TextInputProps } from "./text-input";

const LightChip = withStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  icon: {
    color: theme.palette.text.primary,
  },
})(Chip);

export type MultiSelectItem = {
  id?: number;
  name: string;
  value: string;
};

type Props = Omit<TextInputProps, "select" | "SelectProps"> & {
  items: Array<MultiSelectItem>;
  values?: Array<MultiSelectItem>;
  limitTags?: number;
  label?: string;
  setValue?: (newValues: Array<MultiSelectItem>) => void;
};

const MultiSelect: React.FC<Props> = ({ items, limitTags, values, label, setValue }, { ...TextInputProps }) => {
  return (
    <>
      <Autocomplete
        multiple
        id="tags-outlined"
        disableCloseOnSelect
        disabled={TextInputProps.disabled}
        options={items}
        getOptionLabel={(option) => option?.name ?? ""}
        value={values}
        filterSelectedOptions
        limitTags={limitTags}
        getOptionSelected={(option, value) => {
          return option.value === value.value;
        }}
        renderInput={(params) => (
          <TextInput
            required
            label={label}
            {...params}
            {...TextInputProps}
            InputProps={{ ...params.InputProps, endAdornment: null }}
          />
        )}
        renderTags={(values, getTagProps) =>
          values && values.map((option, index) => <LightChip label={option.name} {...getTagProps({ index })} />)
        }
        onChange={(event, newValues) => {
          setValue && setValue(newValues);
        }}
      />
    </>
  );
};

export default MultiSelect;
