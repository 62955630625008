import { NextButton, PreviousButton } from "@chq/components";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    padding: "1rem",
  },
  confirmationButton: {
    marginBottom: "1rem",
  },
  buttonContainer: {
    marginTop: "1rem",
  },
});

type Props = {
  startNewBankAccount: () => void;
  onClose: () => void;
};

const AddNewBankAccountConfirmationModal: React.FC<Props> = ({ onClose, startNewBankAccount }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Grid className={classes.container}>
      <Grid>
        <Typography align="center">
          {t("finish-application.payment-method-page.add-new-bank-account-modal-text")}
        </Typography>
      </Grid>
      <Grid className={classes.buttonContainer} wrap="nowrap" spacing={2} container>
        <Grid item xs={3}>
          <PreviousButton
            className={classes.confirmationButton}
            fullWidth
            variant="outlined"
            onClick={() => onClose()}
          />
        </Grid>
        <Grid item xs={9}>
          <NextButton fullWidth variant="contained" onClick={startNewBankAccount}>
            {t("finish-application.payment-method-page.change-my-bank")}
          </NextButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddNewBankAccountConfirmationModal;
