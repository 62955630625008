/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  UserRegisterResponse,
  UserRegisterResponseFromJSON,
  UserRegisterResponseFromJSONTyped,
  UserRegisterResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface UserRegisterResponseApiResponse
 */
export interface UserRegisterResponseApiResponse {
  /**
   *
   * @type {UserRegisterResponse}
   * @memberof UserRegisterResponseApiResponse
   */
  data?: UserRegisterResponse;
  /**
   *
   * @type {ErrorResponse}
   * @memberof UserRegisterResponseApiResponse
   */
  error?: ErrorResponse;
}

export function UserRegisterResponseApiResponseFromJSON(json: any): UserRegisterResponseApiResponse {
  return UserRegisterResponseApiResponseFromJSONTyped(json, false);
}

export function UserRegisterResponseApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserRegisterResponseApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : UserRegisterResponseFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function UserRegisterResponseApiResponseToJSON(value?: UserRegisterResponseApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: UserRegisterResponseToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
