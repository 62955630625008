import { CompAndCollisionCoverage, Equipment, RegisteredTo, ZoneEnum } from "@chq/enrollment-api";
import { Fields as TrailerPowerUnitFormFields } from "../components/trailer-power-unit-form";
import { TrailerPowerUnit } from "../ui/enrollment/pages/equipment-page";

export const equipmentApiMapper = (equipment: TrailerPowerUnit, appId?: number): Equipment => {
  return {
    id: equipment.id,
    vehicleNumber: equipment[TrailerPowerUnitFormFields.vehicleNumber]!,
    equipmentType: equipment.equipmentType!,
    vin: equipment[TrailerPowerUnitFormFields.vin]!,
    make: equipment[TrailerPowerUnitFormFields.make]!,
    model: equipment[TrailerPowerUnitFormFields.model]!,
    year: equipment[TrailerPowerUnitFormFields.year] ? equipment[TrailerPowerUnitFormFields.year]!.toString() : "",
    statedValue: equipment[TrailerPowerUnitFormFields.statedValue]!,
    registrationZip: equipment[TrailerPowerUnitFormFields.registrationZip]
      ? equipment[TrailerPowerUnitFormFields.registrationZip]!.toString()
      : "",
    destinationZone: equipment[TrailerPowerUnitFormFields.destination]! as ZoneEnum,
    ownerOperator: Boolean(parseInt(equipment[TrailerPowerUnitFormFields.ooIC]!)),
    registeredTo: equipment[TrailerPowerUnitFormFields.whereIsUnitRegistered] as RegisteredTo,
    loanLeaseGapCoverage:
      equipment["gap-coverage"] === undefined ? undefined : Boolean(parseInt(equipment["gap-coverage"]!)),
    compAndCollisionCoverage: equipment["coverage-option"] as CompAndCollisionCoverage,
    applicationId: appId,
  };
};
