/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface StripeBankAccountCreateRequest
 */
export interface StripeBankAccountCreateRequest {
  /**
   *
   * @type {string}
   * @memberof StripeBankAccountCreateRequest
   */
  nameOnAccount?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeBankAccountCreateRequest
   */
  accountNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeBankAccountCreateRequest
   */
  routingNumber?: string | null;
}

export function StripeBankAccountCreateRequestFromJSON(json: any): StripeBankAccountCreateRequest {
  return StripeBankAccountCreateRequestFromJSONTyped(json, false);
}

export function StripeBankAccountCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StripeBankAccountCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    nameOnAccount: !exists(json, "nameOnAccount") ? undefined : json["nameOnAccount"],
    accountNumber: !exists(json, "accountNumber") ? undefined : json["accountNumber"],
    routingNumber: !exists(json, "routingNumber") ? undefined : json["routingNumber"],
  };
}

export function StripeBankAccountCreateRequestToJSON(value?: StripeBankAccountCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    nameOnAccount: value.nameOnAccount,
    accountNumber: value.accountNumber,
    routingNumber: value.routingNumber,
  };
}
