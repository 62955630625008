import { useContext } from "react";
import { FooterFormContext } from "./FooterFormStateProvider";

const useFooterFormState = () => {
  const context = useContext(FooterFormContext);
  if (context) {
    return [context.footerOpen, context.setFooterOpen] as const;
  } else {
    throw new Error("useFooterForm may only be used in a descendant of FooterFormContext");
  }
};

export default useFooterFormState;
