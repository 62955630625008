import { BillingCycle, Rates } from "@chq/enrollment-api";

export const getQuoteItems = (rates?: Rates, hasCargo?: boolean, titles?: string[]) => {
  if (rates) {
    return {
      weekly: hasCargo
        ? [
            { title: titles ? titles[0] : "", total: rates.weeklyAutoLiability ?? 0 },
            { title: titles ? titles[1] : "", total: rates.weeklyCargoPayment ?? 0 },
            { title: titles ? titles[2] : "", total: rates.weeklySaasAmount ?? 0 },
          ]
        : [
            { title: titles ? titles[0] : "", total: rates.weeklyAutoLiability ?? 0 },
            { title: titles ? titles[2] : "", total: rates.weeklySaasAmount ?? 0 },
          ],
      monthly: hasCargo
        ? [
            { title: titles ? titles[0] : "", total: rates.monthlyAutoLiability ?? 0 },
            { title: titles ? titles[1] : "", total: rates.monthlyCargoPayment ?? 0 },
            { title: titles ? titles[2] : "", total: rates.monthySaasAmount ?? 0 },
          ]
        : [
            { title: titles ? titles[0] : "", total: rates.monthlyAutoLiability ?? 0 },
            { title: titles ? titles[2] : "", total: rates.monthySaasAmount ?? 0 },
          ],

      annually: hasCargo
        ? [
            { title: titles ? titles[0] : "", total: rates.autoLiabilityAmount ?? 0 },
            { title: titles ? titles[1] : "", total: rates.cargoAmount ?? 0 },
          ]
        : [{ title: titles ? titles[0] : "", total: rates.autoLiabilityAmount ?? 0 }],

      downPayment: [
        { title: titles ? titles[3] : "", total: rates.weeklyDownPayment?.toFixed(2) ?? 0 },
        { title: titles ? titles[3] : "", total: rates.monthlyDownPayment?.toFixed(2) ?? 0 },
        { title: titles ? titles[4] : "", total: rates.annualizedRate?.toFixed(2) ?? 0 },
      ],
    };
  } else return { weekly: [], monthly: [], annually: [], downPayment: [] };
};

export const getQuoteByCycle = (rates?: Rates, billingCycle?: BillingCycle) => {
  if (billingCycle === BillingCycle.Weekly) {
    return {
      quotePayment: rates?.weeklyRate ?? 0,
      downPayment: rates?.weeklyDownPayment ?? 0,
    };
  } else if (billingCycle === BillingCycle.Monthly) {
    return {
      quotePayment: rates?.monthlyRate ?? 0,
      downPayment: rates?.monthlyDownPayment ?? 0,
    };
  } else if (billingCycle === BillingCycle.Annual) {
    return {
      quotePayment: rates?.annualizedRate ?? 0,
    };
  } else {
    return {
      quotePayment: rates?.weeklyRate ?? 0,
    };
  }
};
