import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export const useCurrentHeaderText = (routeMap: { [x: string]: string }) => {
  const location = useLocation();
  const [t] = useTranslation();
  return t(routeMap[location.pathname.toLowerCase()]);
};

type Props = {
  routeMap: { [x: string]: string };
  className?: string;
};

const HeaderText: React.FC<Props> = ({ routeMap, className }) => {
  const currentHeaderText = useCurrentHeaderText(routeMap);
  if (!currentHeaderText) {
    return null;
  } else {
    return (
      <Typography variant="h2" className={className}>
        {currentHeaderText}
      </Typography>
    );
  }
};

export default HeaderText;
