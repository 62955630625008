import { fade } from "@chq/styles";
import { Button, Grid, IconButton, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { ElementType, useState } from "react";
import { DeleteIcon, SuccessIcon } from "./icons";

const useStyles = makeStyles((theme: Theme) => ({
  uploadForm: {
    marginBottom: "15px",
  },
  subtitle: {
    fontSize: "smaller",
  },
  documentButton: {
    ...theme.typography.body1,
    minHeight: "2.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.common.white,
  },
  fileAddedButton: {
    ...theme.typography.body1,
    fontSize: "1rem",
    justifyContent: "space-between",
    paddingLeft: "0.5rem",
  },
  download: {
    fontWeight: "bold",
  },
  subtitlePadding: {
    paddingBottom: "5px",
  },
  successIcon: {
    color: theme.palette.success.main,
    width: "auto",
    height: "40px",
  },
  deleteIcon: {
    width: "auto",
    height: "40px",
  },
  deleteIconButton: {
    "&.MuiIconButton-root": {
      padding: 0,
    },
  },
  title: {
    paddingBottom: "1rem",
  },
  button: {
    zIndex: 100,
  },
}));

type Props = {
  title: string;
  subtitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  titleComponent?: ElementType<any>;
  fileTypes?: string;
  document?: File;
  isDocumentEntered: boolean;
  name?: string | null;
  filePrompt: string;
  downloadTemplate?: string;
  downloadTemplateText?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onDelete?: () => void;
};

const DocumentUpload: React.FC<Props> = ({
  title,
  subtitle,
  titleComponent = "h3",
  fileTypes = "*",
  isDocumentEntered,
  name,
  filePrompt,
  downloadTemplate,
  downloadTemplateText,
  onChange,
  onDelete,
}) => {
  const classes = useStyles();
  const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);

  return (
    <Grid container direction="column" className={classes.uploadForm}>
      <Grid container direction="row" justify="space-between">
        <Grid item>
          <Typography variant="h4" className={classes.title} component={titleComponent}>
            {title}
          </Typography>
        </Grid>
        {downloadTemplate && (
          <Grid item className={classes.download}>
            <Link
              className={classes.download}
              download={true}
              href={downloadTemplate}
              aria-label={downloadTemplateText}
            >
              {downloadTemplateText}
            </Link>
          </Grid>
        )}
      </Grid>
      {subtitle && (
        <Grid item xs={12} className={classes.subtitlePadding}>
          {subtitle}
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          fullWidth
          component="label"
          className={classNames(classes.documentButton, {
            [classes.fileAddedButton]: isDocumentEntered,
          })}
          onMouseEnter={() => setShowDeleteIcon(true)}
          onMouseLeave={() => setShowDeleteIcon(false)}
          endIcon={
            isDocumentEntered ? (
              showDeleteIcon ? (
                <IconButton onClick={onDelete} className={classes.deleteIconButton}>
                  <DeleteIcon className={classes.deleteIcon} color="disabled" />
                </IconButton>
              ) : (
                <SuccessIcon className={classes.successIcon} />
              )
            ) : null
          }
        >
          {isDocumentEntered ? name : filePrompt}
          <input required={true} type="file" accept={fileTypes} onChange={onChange} hidden />
        </Button>
      </Grid>
    </Grid>
  );
};

export default DocumentUpload;
