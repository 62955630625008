/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  StripeBankAccount,
  StripeBankAccountFromJSON,
  StripeBankAccountFromJSONTyped,
  StripeBankAccountToJSON,
} from "./";

/**
 *
 * @export
 * @interface StripeBankAccountListApiResponse
 */
export interface StripeBankAccountListApiResponse {
  /**
   *
   * @type {Array<StripeBankAccount>}
   * @memberof StripeBankAccountListApiResponse
   */
  data?: Array<StripeBankAccount> | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof StripeBankAccountListApiResponse
   */
  error?: ErrorResponse;
}

export function StripeBankAccountListApiResponseFromJSON(json: any): StripeBankAccountListApiResponse {
  return StripeBankAccountListApiResponseFromJSONTyped(json, false);
}

export function StripeBankAccountListApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StripeBankAccountListApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
      ? null
      : (json["data"] as Array<any>).map(StripeBankAccountFromJSON),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function StripeBankAccountListApiResponseToJSON(value?: StripeBankAccountListApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
        ? null
        : (value.data as Array<any>).map(StripeBankAccountToJSON),
    error: ErrorResponseToJSON(value.error),
  };
}
