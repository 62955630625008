import React from "react";
import Lottie from "react-lottie";
import ascendLogo from "../images/partner-images/ascend-logo.svg";
import atbsLogo from "../images/partner-images/atbs-logo.svg";
import convoyLogo from "../images/partner-images/convoy-logo.svg";
import coverWalletLogo from "../images/partner-images/coverwallet-logo.svg";
import movingTruckAnimation from "../lotties/text-lottie.json";

interface logoProps {
  partnerName: string;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: movingTruckAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const PartnerLogo: React.FC<logoProps> = ({ partnerName }) => {
  let Logo = "";
  let logoHeight = 0;

  switch (partnerName) {
    case "ascend":
      Logo = ascendLogo;
      logoHeight = 24;
      break;
    case "convoy":
      Logo = convoyLogo;
      logoHeight = 24;
      break;
    case "atbs":
      Logo = atbsLogo;
      logoHeight = 24;
      break;
    case "coverwallet":
      Logo = coverWalletLogo;
      logoHeight = 100;
      break;
  }

  return partnerName === "text" ? (
    <Lottie options={defaultOptions} width={200} />
  ) : (
    <img src={Logo} height={logoHeight} alt="partner-logo" />
  );
};

export default PartnerLogo;
