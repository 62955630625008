/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SpeedGuageScore
 */
export interface SpeedGuageScore {
  /**
   *
   * @type {number}
   * @memberof SpeedGuageScore
   */
  score?: number;
  /**
   *
   * @type {Date}
   * @memberof SpeedGuageScore
   */
  date?: Date;
}

export function SpeedGuageScoreFromJSON(json: any): SpeedGuageScore {
  return SpeedGuageScoreFromJSONTyped(json, false);
}

export function SpeedGuageScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpeedGuageScore {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    score: !exists(json, "score") ? undefined : json["score"],
    date: !exists(json, "date") ? undefined : new Date(json["date"]),
  };
}

export function SpeedGuageScoreToJSON(value?: SpeedGuageScore | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    score: value.score,
    date: value.date === undefined ? undefined : value.date.toISOString(),
  };
}
