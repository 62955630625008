/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ReferralMethod {
  Email = "Email",
  Facebook = "Facebook",
  FreightWaves = "FreightWaves",
  FriendFamily = "Friend Family",
  Mailer = "Mailer",
  OnlineSearch = "Online Search",
  Other = "Other",
  Overdrive = "Overdrive",
  PhoneCall = "Phone Call",
  Referral = "Referral",
  SiriusXM = "SiriusXM",
  SocialMedia = "Social Media",
  Mats = "Mats",
}

export function ReferralMethodFromJSON(json: any): ReferralMethod {
  return ReferralMethodFromJSONTyped(json, false);
}

export function ReferralMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralMethod {
  return json as ReferralMethod;
}

export function ReferralMethodToJSON(value?: ReferralMethod | null): any {
  return value as any;
}
