import { useAuth } from "@chq/authentication";
import { OwnerBankAccountInfo, PolicyApi } from "@chq/policy-management-api";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "./usePolicyManagementApi";

type UpdateStripeAccount = {
  publicToken: string;
  metadata: PlaidLinkOnSuccessMetadata;
};

export const useUpdateStripeAccount = (): UseMutationResult<OwnerBankAccountInfo, Error, UpdateStripeAccount> => {
  const policyApi = useApi(PolicyApi);
  const { user } = useAuth();
  const mutation = useMutation<OwnerBankAccountInfo, Error, UpdateStripeAccount>(
    async ({ publicToken, metadata }: UpdateStripeAccount) => {
      const response = await policyApi.portalApiV10PolicyUpdateStripeAccountGet({
        identityId: user?.sub,
        publicToken: publicToken,
        accountId: metadata.accounts[0].id,
      });
      return response.data!;
    },
  );
  return mutation;
};
