import React from "react";
import { useParams } from "react-router";
import PartnerLandingPageComponent from "../../../components/partner-landing-page-component";

type PartnerParams = {
  partner_name: string;
};

const PartnerLandingPage: React.FC = () => {
  const { partner_name } = useParams<PartnerParams>();

  return <PartnerLandingPageComponent partnerName={partner_name} />;
};

export default PartnerLandingPage;
