import { Button, Dialog, Grid, IconButton, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import React, { useState } from "react";
import TagManager from "react-gtm-module";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { UserLoginType, useUserLoginType } from "../data/authentication/useUserLoginType";
import { useSendResumeApplicationEmail } from "../data/enrollment";
import ascendBackgroundDesktop from "../images/partner-images/ascend-background-desktop.png";
import ascendBackground from "../images/partner-images/ascend-background.png";
import atbsBackgroundDesktop from "../images/partner-images/atbs-background-desktop.png";
import atbsBackground from "../images/partner-images/atbs-background.png";
import convoyBackgroundDesktop from "../images/partner-images/convoy-background-desktop.png";
import convoyBackground from "../images/partner-images/convoy-background.png";
import coverWalletBackgroundDesktop from "../images/partner-images/coverwallet-background-desktop.png";
import coverWalletBackground from "../images/partner-images/coverwallet-background.png";
import textBackgroundDesktop from "../images/partner-images/text-background-desktop.png";
import textBackground from "../images/partner-images/text-background.png";
import { routes } from "../ui/routes";
import ContinueQuoteEmail, {
  Fields as ContinueEmailFields,
  useFormikConfig as useContinueEmailFormikConfig,
} from "./continue-quote-email";
import PartnerLogo from "./partner-logo";
import StartNewQuote from "./start-new-quote";
import WelcomeBackQuote from "./welcome-back-quote";

type Props = {
  bgImage: string;
  bgImageDesktop: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: ({ bgImage, bgImageDesktop }: Props) => {
    return {
      [theme.breakpoints.up("xs")]: {
        backgroundSize: "cover",
        background: `url(${bgImage})`,
      },
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.up("sm")]: {
        backgroundSize: "cover",
        backgroundPosition: "right center",
        background: `url(${bgImageDesktop})`,
      },
      display: "flex",
      flexDirection: "column",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    };
  },
  partnerPaper: {
    padding: "1rem 0 0 0",
    [theme.breakpoints.up("xs")]: {
      width: "93%",
      height: "100%",
      margin: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40rem",
      margin: "2.5rem 1rem 1rem 1rem",
    },
  },
  partnerPaperForText: {
    padding: "0 0 0 0",
    [theme.breakpoints.up("xs")]: {
      width: "93%",
      height: "100%",
      margin: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40rem",
      margin: "2.5rem 1rem 1rem 1rem",
    },
  },
  partnerContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  topContainer: {
    padding: "1rem .5rem 1rem .5rem",
    display: "flex",
    justifyContent: "center",
    alignIterms: "center",
  },
  topContainerForText: {
    padding: "0rem .5rem 1rem .5rem",
    display: "flex",
    justifyContent: "center",
    alignIterms: "center",
  },
  centeredTextContainer: {
    textAlign: "center",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  leftJustifiedTextContainer: {
    textAlign: "left",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  headlineText: {
    fontSize: 20,
    fontWeight: 900,
  },
  marketingText: {
    fontWeight: 300,
  },
  bullets: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginTop: "10px",
    marginBottom: "10px",
  },
  divider: {
    marginLeft: "1rem",
    marginRight: "1rem",
    width: "90%",
  },
  description: {
    marginLeft: "1rem",
    marginRight: "1rem",
    fontSize: "14px",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    color: theme.palette.common.white,
  },
  boldText: {
    fontWeight: 600,
  },
  boldItalicText: {
    fontWeight: 600,
    fontStyle: "italic",
  },
  boldUnderlinedText: {
    fontWeight: 600,
    textDecoration: "underline",
  },
  orangeText: {
    color: "#F26101",
  },
  blueText: {
    color: "#2185C5",
  },
  boldOrangeText: {
    fontWeight: 600,
    color: "#F26101",
  },
  boldBlueText: {
    fontWeight: 600,
    color: "#2185C5",
  },
  regularText: {
    fontWeight: 300,
  },
  regularItalicText: {
    fontStyle: "italic",
    fontWeight: 300,
  },
  regularUnderlinedText: {
    textDecoration: "underline",
    fontWeight: 300,
  },
  carrierHQBlueText: {
    fontSize: 17,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  ascendLink: {
    color: "#2185C5",
    fontWeight: 600,
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },
      [theme.breakpoints.up("xs")]: {
        width: "50%",
      },
    },
    "& .MuiPaper-rounded": {
      borderRadius: "4px 4px 0px 0px",
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
      overflowX: "hidden",
      overflowY: "hidden",
      [theme.breakpoints.up("sm")]: {
        alignSelf: "center",
      },
    },
  },
  continueQuoteClose: {
    width: "1rem",
    height: "1rem",
    margin: "1rem",
  },
  modalFooter: {
    display: "flex",
    justifyContent: "center",
    alignIterms: "center",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0 0 .2rem .2rem",
    width: "100%",
    height: "7rem",
  },
  buttonContainerGetStarted: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  buttonContainerContinueQuote: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "17px",
    height: "3rem",
    [theme.breakpoints.down("sm")]: {
      width: `calc(${100}% - ${1.5}rem )`,
      margin: "1rem .5rem 1rem 1rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      margin: "1rem 1rem 1rem 0rem",
    },
  },
  continueQuoteButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.dark,
    fontSize: "15px",
    height: "3rem",
    [theme.breakpoints.down("sm")]: {
      width: `calc(${100}% - ${1.5}rem )`,
      margin: "1rem 1rem 1rem .5rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      margin: "1rem 0rem 1rem 1rem",
    },
  },
}));

type PartnerParams = {
  partnerName: string;
};

enum StartQuotePageUserStatus {
  emailLookUpPending = 0,
  noEmail,
  hasEmailNoPassword,
  expired,
}

const PartnerLandingPageComponent: React.FC<PartnerParams> = ({ partnerName }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<StartQuotePageUserStatus>(StartQuotePageUserStatus.emailLookUpPending);
  const translationBullets = t(`enrollment.partner-landing-page.${partnerName}.bullets`, {
    returnObjects: true,
  });
  const translationBulletArray = Object.keys(translationBullets);
  const continueEmailFormikProps = useContinueEmailFormikConfig();
  const { mutateAsync: getLoginType } = useUserLoginType();
  const { mutate: sendResumeApplicationEmail } = useSendResumeApplicationEmail();

  let bgImage = "";
  let bgImageDesktop = "";

  // Switch statement to assign the background Images to the page
  switch (partnerName) {
    case "ascend":
      bgImage = ascendBackground;
      bgImageDesktop = ascendBackgroundDesktop;
      break;
    case "convoy":
      bgImage = convoyBackground;
      bgImageDesktop = convoyBackgroundDesktop;
      break;
    case "atbs":
      bgImage = atbsBackground;
      bgImageDesktop = atbsBackgroundDesktop;
      break;
    case "coverwallet":
      bgImage = coverWalletBackground;
      bgImageDesktop = coverWalletBackgroundDesktop;
      break;
    case "text":
      bgImage = textBackground;
      bgImageDesktop = textBackgroundDesktop;
      break;
  }

  const classes = useStyles({ bgImage, bgImageDesktop });

  // Create the bullet list to display in the modal
  const bulletList = translationBulletArray.map((bullet, index) => (
    <li key={index}>
      {
        <Trans i18nKey={`enrollment.partner-landing-page.${partnerName}.bullets.${bullet}`}>
          <Typography variant="h4" component="span" className={classes.regularText}></Typography>
          <Typography variant="h4" component="span" className={classes.boldText}></Typography>
          <Typography variant="h4" component="span" className={classes.boldItalicText}></Typography>
          <Typography variant="h4" component="span" className={classes.boldUnderlinedText}></Typography>
          <Typography variant="h4" component="span" className={classes.orangeText}></Typography>
          <Typography variant="h4" component="span" className={classes.blueText}></Typography>
          <Typography variant="h4" component="span" className={classes.regularItalicText}></Typography>
          <Typography variant="h4" component="span" className={classes.regularUnderlinedText}></Typography>
          <Link
            variant="h4"
            target="_blank"
            href={"https://inmotionglobal.com/features/carrierhq"}
            className={classes.ascendLink}
          ></Link>
        </Trans>
      }
    </li>
  ));

  const pushDataAndRedirect = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "partnerGetQuoteEvent",
        partnerGetQuoteText: "Get Quote",
        partnerName: partnerName,
      },
    });
    history.push({ pathname: routes.enrollment.qualify.path, state: partnerName });
  };

  return (
    <div className={classes.root}>
      <Paper className={partnerName === "text" ? classes.partnerPaperForText : classes.partnerPaper} elevation={7}>
        <Grid container className={classes.partnerContainer} direction="column" justify="center">
          <Grid
            container
            item
            xs={12}
            className={partnerName === "text" ? classes.topContainerForText : classes.topContainer}
            spacing={2}
          >
            <Grid item>
              <PartnerLogo partnerName={partnerName} />
            </Grid>
            {t(`enrollment.partner-landing-page.${partnerName}.headline`) !== "" ? (
              <Grid item xs={12} className={classes.centeredTextContainer}>
                <Trans i18nKey={`enrollment.partner-landing-page.${partnerName}.headline`}>
                  <Typography component="span" className={classes.headlineText}></Typography>
                  <Typography component="span" className={`${classes.headlineText} ${classes.boldText}`}></Typography>
                  <Typography
                    component="span"
                    className={`${classes.headlineText} ${classes.boldItalicText}`}
                  ></Typography>
                  <Typography
                    component="span"
                    className={`${classes.headlineText} ${classes.boldUnderlinedText}`}
                  ></Typography>
                  <Typography component="span" className={`${classes.headlineText} ${classes.orangeText}`}></Typography>
                  <Typography component="span" className={`${classes.headlineText} ${classes.blueText}`}></Typography>
                  <Typography
                    component="span"
                    className={`${classes.headlineText} ${classes.regularItalicText}`}
                  ></Typography>
                  <Typography
                    component="span"
                    className={`${classes.headlineText} ${classes.regularUnderlinedText}`}
                  ></Typography>
                </Trans>
              </Grid>
            ) : null}
            {t(`enrollment.partner-landing-page.${partnerName}.marketing`) !== "" ? (
              <Grid
                item
                xs={12}
                className={partnerName === "text" ? classes.centeredTextContainer : classes.leftJustifiedTextContainer}
              >
                <Trans i18nKey={`enrollment.partner-landing-page.${partnerName}.marketing`}>
                  <Typography variant="h4" component="span" className={classes.marketingText}></Typography>
                  <Typography
                    variant="h4"
                    component="span"
                    className={`${classes.marketingText} ${classes.boldText}`}
                  ></Typography>
                  <Typography
                    variant="h4"
                    component="span"
                    className={`${classes.marketingText} ${classes.boldItalicText}`}
                  ></Typography>
                  <Typography
                    variant="h4"
                    component="span"
                    className={`${classes.marketingText} ${classes.boldUnderlinedText}`}
                  ></Typography>
                  <Typography
                    variant="h4"
                    component="span"
                    className={`${classes.marketingText} ${classes.boldOrangeText}`}
                  ></Typography>
                  <Typography
                    variant="h4"
                    component="span"
                    className={`${classes.marketingText} ${classes.boldBlueText}`}
                  ></Typography>
                  <Typography
                    variant="h4"
                    component="span"
                    className={`${classes.marketingText} ${classes.regularItalicText}`}
                  ></Typography>
                  <Typography
                    variant="h4"
                    component="span"
                    className={`${classes.marketingText} ${classes.regularUnderlinedText}`}
                  ></Typography>
                  <Typography
                    variant="h4"
                    component="span"
                    className={`${classes.marketingText} ${classes.carrierHQBlueText}`}
                  ></Typography>
                </Trans>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <ul className={classes.bullets}>{bulletList}</ul>
            </Grid>
            {t(`enrollment.partner-landing-page.${partnerName}.post-bullets-sentence`) !== "" ? (
              <Grid item xs={12} className={classes.centeredTextContainer}>
                <Trans i18nKey={`enrollment.partner-landing-page.${partnerName}.post-bullets-sentence`}>
                  <Typography variant="h4" component="span" className={classes.regularText}></Typography>
                  <Typography variant="h4" component="span" className={classes.boldText}></Typography>
                  <Typography variant="h4" component="span" className={classes.boldItalicText}></Typography>
                  <Typography variant="h4" component="span" className={classes.boldUnderlinedText}></Typography>
                  <Typography variant="h4" component="span" className={classes.orangeText}></Typography>
                  <Typography variant="h4" component="span" className={classes.blueText}></Typography>
                  <Typography variant="h4" component="span" className={classes.regularItalicText}></Typography>
                  <Typography variant="h4" component="span" className={classes.regularUnderlinedText}></Typography>
                </Trans>
              </Grid>
            ) : null}
          </Grid>
          <Grid container item xs={12} className={classes.modalFooter} direction="row">
            <Grid item xs={6} className={classes.buttonContainerGetStarted}>
              <Button
                variant="contained"
                fullWidth
                className={classes.nextButton}
                onClick={() => pushDataAndRedirect()}
              >
                {t("enrollment.partner-landing-page.get-started")}
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.buttonContainerContinueQuote}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setOpen(true)}
                className={classes.continueQuoteButton}
              >
                {t("enrollment.start-quote-page.continue-quote-button")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Dialog className={classes.dialog} open={open} onClose={() => setOpen(false)}>
        <Formik
          {...continueEmailFormikProps}
          onSubmit={async (values) => {
            const loginType = await getLoginType(values[ContinueEmailFields.email]);
            switch (loginType) {
              case UserLoginType.magiclink: {
                setStatus(StartQuotePageUserStatus.hasEmailNoPassword);
                sendResumeApplicationEmail({ email: values[ContinueEmailFields.email] });
                break;
              }
              case UserLoginType.password: {
                history.push(routes.login.path);
                break;
              }
              case UserLoginType.none: {
                setStatus(StartQuotePageUserStatus.noEmail);
                break;
              }
            }
          }}
        >
          {(formik) => (
            <>
              <IconButton
                className={classes.continueQuoteClose}
                onClick={() => setOpen(false)}
                aria-label={t("continue-quote-email.close-icon-label")}
              >
                <CloseIcon />
              </IconButton>
              {status === StartQuotePageUserStatus.emailLookUpPending && <ContinueQuoteEmail />}
              {status === StartQuotePageUserStatus.noEmail && (
                <StartNewQuote
                  email={formik.values[ContinueEmailFields.email]}
                  onPreviousClick={() => {
                    setStatus(StartQuotePageUserStatus.emailLookUpPending);
                  }}
                  onNextClick={() => history.push(routes.enrollment.qualify.path)}
                />
              )}
            </>
          )}
        </Formik>
        {status === StartQuotePageUserStatus.hasEmailNoPassword && <WelcomeBackQuote accountExpired={false} />}
        {status === StartQuotePageUserStatus.expired && <WelcomeBackQuote accountExpired />}
      </Dialog>
    </div>
  );
};

export default PartnerLandingPageComponent;
