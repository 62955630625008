import { AuthenticationProvider, withAuthenticationRequired } from "@chq/authentication";
import qs from "qs";
import React, { Suspense, useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { EnrollmentProvider } from "../data/enrollment";
import EnrollmentRoutes from "./enrollment";
import UnauthorizedPage from "./enrollment/pages/401-page";
import MissingPage from "./enrollment/pages/404-page";
import EmailLinkPage from "./enrollment/pages/email-link-page";
import LoginPage from "./enrollment/pages/login-page";
import ResetPasswordPage from "./enrollment/pages/reset-password-page";
import PolicyManagementRoutes from "./policy-management";
import { routes } from "./routes";
import { FullScreenLoader } from "./shared/fullScreenLoader";

const UnhandledError: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const ScrollToTop: React.FC = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
};

const errorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
};

const App: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={UnhandledError} onError={errorHandler}>
      <Suspense fallback={<></>}>
        <EnrollmentProvider>
          <AuthenticationProvider>
            <Router>
              <ScrollToTop>
                <Switch>
                  <Route exact path={routes.notFound.path} component={MissingPage} />
                  <Route path={routes.login.path} component={LoginPage} />
                  <Route path={routes.resetPassword.path} component={ResetPasswordPage} />
                  <Route path={routes.loading.path}>
                    <FullScreenLoader header={true} footer={true} setAsLoading={true} />
                  </Route>
                  <Route exact path={routes.emailLink.path} component={EmailLinkPage} />
                  <Route path={routes.enrollment.path} component={EnrollmentRoutes}></Route>
                  <Route
                    path={routes.policyManagement.path}
                    component={withAuthenticationRequired(PolicyManagementRoutes, {
                      onRedirecting: () => (
                        <Redirect
                          push
                          to={{
                            pathname: routes.login.path,
                            search: qs.stringify({ returnTo: routes.policyManagement.path }),
                          }}
                        />
                      ),
                    })}
                  />
                  <Route path={routes.unauthorized.path} component={UnauthorizedPage} />
                  <Redirect push to={routes.enrollment.path}>
                    <MissingPage />
                  </Redirect>
                </Switch>
              </ScrollToTop>
            </Router>
          </AuthenticationProvider>
        </EnrollmentProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
