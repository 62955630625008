/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Document, DocumentFromJSON, DocumentFromJSONTyped, DocumentToJSON } from "./";

/**
 *
 * @export
 * @interface PdfDocuments
 */
export interface PdfDocuments {
  /**
   *
   * @type {number}
   * @memberof PdfDocuments
   */
  id?: number;
  /**
   *
   * @type {Document}
   * @memberof PdfDocuments
   */
  eDeliveryConsentForm?: Document;
  /**
   *
   * @type {Document}
   * @memberof PdfDocuments
   */
  insuranceApplication?: Document;
  /**
   *
   * @type {Document}
   * @memberof PdfDocuments
   */
  umuimCoverage?: Document;
  /**
   *
   * @type {Document}
   * @memberof PdfDocuments
   */
  fraudStatement?: Document;
  /**
   *
   * @type {Document}
   * @memberof PdfDocuments
   */
  certificateOfInsurance?: Document;
  /**
   *
   * @type {Document}
   * @memberof PdfDocuments
   */
  speedgaugeConsentForm?: Document;
  /**
   *
   * @type {Document}
   * @memberof PdfDocuments
   */
  rrgMembershipForm?: Document;
  /**
   *
   * @type {Document}
   * @memberof PdfDocuments
   */
  cargoForm?: Document;
  /**
   *
   * @type {Document}
   * @memberof PdfDocuments
   */
  cargoFormUnsigned?: Document;
}

export function PdfDocumentsFromJSON(json: any): PdfDocuments {
  return PdfDocumentsFromJSONTyped(json, false);
}

export function PdfDocumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdfDocuments {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    eDeliveryConsentForm: !exists(json, "eDeliveryConsentForm")
      ? undefined
      : DocumentFromJSON(json["eDeliveryConsentForm"]),
    insuranceApplication: !exists(json, "insuranceApplication")
      ? undefined
      : DocumentFromJSON(json["insuranceApplication"]),
    umuimCoverage: !exists(json, "umuimCoverage") ? undefined : DocumentFromJSON(json["umuimCoverage"]),
    fraudStatement: !exists(json, "fraudStatement") ? undefined : DocumentFromJSON(json["fraudStatement"]),
    certificateOfInsurance: !exists(json, "certificateOfInsurance")
      ? undefined
      : DocumentFromJSON(json["certificateOfInsurance"]),
    speedgaugeConsentForm: !exists(json, "speedgaugeConsentForm")
      ? undefined
      : DocumentFromJSON(json["speedgaugeConsentForm"]),
    rrgMembershipForm: !exists(json, "rrgMembershipForm") ? undefined : DocumentFromJSON(json["rrgMembershipForm"]),
    cargoForm: !exists(json, "cargoForm") ? undefined : DocumentFromJSON(json["cargoForm"]),
    cargoFormUnsigned: !exists(json, "cargoFormUnsigned") ? undefined : DocumentFromJSON(json["cargoFormUnsigned"]),
  };
}

export function PdfDocumentsToJSON(value?: PdfDocuments | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    eDeliveryConsentForm: DocumentToJSON(value.eDeliveryConsentForm),
    insuranceApplication: DocumentToJSON(value.insuranceApplication),
    umuimCoverage: DocumentToJSON(value.umuimCoverage),
    fraudStatement: DocumentToJSON(value.fraudStatement),
    certificateOfInsurance: DocumentToJSON(value.certificateOfInsurance),
    speedgaugeConsentForm: DocumentToJSON(value.speedgaugeConsentForm),
    rrgMembershipForm: DocumentToJSON(value.rrgMembershipForm),
    cargoForm: DocumentToJSON(value.cargoForm),
    cargoFormUnsigned: DocumentToJSON(value.cargoFormUnsigned),
  };
}
