import { EditableDeletableCard, NextButton, PreviousButton } from "@chq/components";
import { CircularProgress, fade, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.2rem",
    borderRadius: "0.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
  },
  containerClass: {
    padding: 0,
  },
  mainButtonsContainer: {
    "& .MuiGrid-root": {
      width: "100%",
    },
    marginBottom: "1.5rem",
  },
  continueButtonContainer: {
    margin: "-1.75rem 0rem 0.25rem 0rem",
  },
  pageButtons: {
    paddingTop: "1rem",
    justifyContent: "center",
  },
  proceedButton: {
    minHeight: "3.5rem",
    width: "100%",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
}));

export type Props = {
  disabledProceed?: boolean;
  onProceedClick?: React.MouseEventHandler<HTMLButtonElement>;
  onPreviousClick?: () => void;
  isSavingQuote?: boolean;
};

export const DisclosuresSection: React.FC<Props> = ({
  onProceedClick,
  onPreviousClick,
  disabledProceed,
  isSavingQuote,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <EditableDeletableCard
      variant="default"
      border={false}
      className={classes.container}
      containerClass={classes.containerClass}
    >
      <Grid container direction="column" alignItems="center">
        <Grid container spacing={2} item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h3">
              {t("enrollment.quote-overview-page.disclosures-section.title")}
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="p">
                {t("enrollment.quote-overview-page.disclosures-section.rate")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="p">
                {t("enrollment.quote-overview-page.disclosures-section.auto-liability")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="p">
                {t("enrollment.quote-overview-page.disclosures-section.annual-fees")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="p">
                {t("enrollment.quote-overview-page.disclosures-section.expiration", {
                  daysToExpiration: "45",
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item direction="row" className={classes.pageButtons} spacing={2}>
            <Grid item xs={3}>
              <PreviousButton
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.previousButton}
                onClick={onPreviousClick}
                aria-label={t("enrollment.quote-overview-page.disclosures-section.buttons.previous-button")}
              ></PreviousButton>
            </Grid>
            <Grid item xs={9}>
              <NextButton
                variant="contained"
                color="primary"
                fullWidth
                disabled={disabledProceed}
                onClick={onProceedClick}
                aria-label={t("enrollment.quote-overview-page.disclosures-section.buttons.proceed-button")}
              >
                {isSavingQuote ? (
                  <CircularProgress color="inherit" size="2rem" aria-label={t("common.circular-progress-aria-label")} />
                ) : (
                  t("enrollment.quote-overview-page.disclosures-section.buttons.proceed-button")
                )}
              </NextButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default DisclosuresSection;
