import { ApplicationApi, ApplicationApiResponse, ApplicationCreateRequest } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export enum CreateApplicationErrorMessage {
  notQualified = "Not Qualified",
  state = "state",
  powerUnits = "power units",
  stateAndPowerUnits = "state and power units",
}

export const useCreateApplication = (): UseMutationResult<ApplicationApiResponse, Error, ApplicationCreateRequest> => {
  const applicationApi = useApi(ApplicationApi);
  const { updateApplication } = useCachedApplication();
  const mutation = useMutation<ApplicationApiResponse, Error, ApplicationCreateRequest>(
    async (request: ApplicationCreateRequest) => {
      try {
        const response = await applicationApi.apiV10ApplicationPost({ applicationCreateRequest: request });

        if (response.data) {
          updateApplication(response.data);
        }

        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.json) {
          const response = error as Response;
          const json: ApplicationApiResponse = await response.json();

          throw new Error(json.error?.message || "Failed to create Application");
        } else {
          throw error;
        }
      }
    },
  );
  return mutation;
};
