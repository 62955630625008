import { SuccessIcon } from "@chq/components";
import { Button, makeStyles, Theme } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";

const useStyles = makeStyles((theme: Theme) => ({
  setUpButton: {
    backgroundColor: theme.palette.common.white,
    marginTop: "0.75rem",
    minHeight: "3.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    fontSize: "1.25rem",
    lineHeight: "1rem",
    color: theme.palette.primary.main,
  },
  icon: {
    height: "2.5rem",
    width: "auto",
    color: theme.palette.success.main,
  },
}));

interface PlaidProps {
  token: string;
  onSuccess: PlaidLinkOnSuccess;
  onEvent: PlaidLinkOnEvent;
  onExit: PlaidLinkOnExit;
  plaidSuccess: boolean | undefined;
}

export const PlaidLink: FunctionComponent<PlaidProps> = ({ token, onSuccess, onEvent, onExit, plaidSuccess }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    env: window.env?.REACT_APP_PLAID_ENVIRONMENT || "sandbox",
    product: ["auth"],
    onEvent,
    onExit,
  };

  const { open } = usePlaidLink(config);

  return (
    <Button
      variant="contained"
      fullWidth
      className={classes.setUpButton}
      endIcon={plaidSuccess ? <SuccessIcon className={classes.icon} /> : undefined}
      onClick={() => {
        TagManager.dataLayer({
          dataLayer: {
            event: "connectYourBankAccountEvent",
            connectYourBankAccountText: "Connect Your Bank Account",
          },
        });
        open();
      }}
    >
      {t(`finish-application.payment-method-form.set-up-bank-account.button`)}
    </Button>
  );
};
