/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CompAndCollisionCoverage {
  NoCoverage = "No coverage",
  _1000Deductible = "$1000 Deductible",
  _2000Deductible = "$2000 Deductible",
  _5000Deductible = "$5000 Deductible",
}

export function CompAndCollisionCoverageFromJSON(json: any): CompAndCollisionCoverage {
  return CompAndCollisionCoverageFromJSONTyped(json, false);
}

export function CompAndCollisionCoverageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CompAndCollisionCoverage {
  return json as CompAndCollisionCoverage;
}

export function CompAndCollisionCoverageToJSON(value?: CompAndCollisionCoverage | null): any {
  return value as any;
}
