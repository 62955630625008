/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  StripeAccountStatus,
  StripeAccountStatusFromJSON,
  StripeAccountStatusFromJSONTyped,
  StripeAccountStatusToJSON,
} from "./";

/**
 *
 * @export
 * @interface StripeBankAccount
 */
export interface StripeBankAccount {
  /**
   *
   * @type {string}
   * @memberof StripeBankAccount
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeBankAccount
   */
  customerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeBankAccount
   */
  bankName?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeBankAccount
   */
  accountHolderType?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeBankAccount
   */
  routingNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeBankAccount
   */
  accountNumberLast4?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeBankAccount
   */
  status?: string | null;
  /**
   *
   * @type {StripeAccountStatus}
   * @memberof StripeBankAccount
   */
  accountStatus?: StripeAccountStatus;
}

export function StripeBankAccountFromJSON(json: any): StripeBankAccount {
  return StripeBankAccountFromJSONTyped(json, false);
}

export function StripeBankAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeBankAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    customerId: !exists(json, "customerId") ? undefined : json["customerId"],
    bankName: !exists(json, "bankName") ? undefined : json["bankName"],
    accountHolderType: !exists(json, "accountHolderType") ? undefined : json["accountHolderType"],
    routingNumber: !exists(json, "routingNumber") ? undefined : json["routingNumber"],
    accountNumberLast4: !exists(json, "accountNumberLast4") ? undefined : json["accountNumberLast4"],
    status: !exists(json, "status") ? undefined : json["status"],
    accountStatus: !exists(json, "accountStatus") ? undefined : StripeAccountStatusFromJSON(json["accountStatus"]),
  };
}

export function StripeBankAccountToJSON(value?: StripeBankAccount | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    customerId: value.customerId,
    bankName: value.bankName,
    accountHolderType: value.accountHolderType,
    routingNumber: value.routingNumber,
    accountNumberLast4: value.accountNumberLast4,
    status: value.status,
    accountStatus: StripeAccountStatusToJSON(value.accountStatus),
  };
}
