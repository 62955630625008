import { booleanToYesNoString, CardField, Checkbox, PencilIcon as EditIcon, TooltipHelp } from "@chq/components";
import { CompAndCollisionCoverage, Equipment } from "@chq/enrollment-api";
import { Grid, IconButton, Paper } from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import AdditionalInsuredForm, { FormProps as AdditionalInsuredFormProps } from "./additional-insured-form";
import {
  Fields as CheckboxFormFields,
  FormProps as AddressFormProps,
  useValidationSchema as useAddressFormValidationSchema,
} from "./address-form";
import LossPayeeForm from "./loss-payee-form";
import { Fields } from "./policy-management-components/equipment-form";
import { Fields as PowerUnitTrailerFields } from "./trailer-power-unit-form";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1.125rem 0.5rem 0rem",
    "& .MuiGrid-root > .MuiGrid-root:last-child > .MuiGrid-root > div": {
      paddingBottom: "0px",
      borderBottom: "none",
    },
  },
  powerUnitContainer: {
    padding: "0.75rem 0rem 0.625rem 0rem",
  },
  vehicleNumber: {
    paddingBottom: "0.25rem",
  },
  fullWidth: {
    width: "100%",
    paddingBottom: "0.625rem",
    borderBottom: `2px solid ${fade(theme.palette.grey[400], 0.4)}`,
  },
  checkboxContainer: {
    padding: "0rem 0.625rem 0rem 0.5rem",
    backgroundColor: theme.palette.grey[200],
    "& .MuiGrid-root > .MuiFormControlLabel-root": {
      marginLeft: "0px",
    },
  },
  checkbox: {
    color: theme.palette.grey[500],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    "&.Mui-checked:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  firstCheckbox: {
    marginBottom: "0.125rem",
  },
  tooltipContainer: {
    height: "1.5rem",
  },
  fullWidthCheckbox: {
    width: "100%",
    paddingBottom: "0.625rem",
  },
  secondaryContainer: {
    paddingTop: "1rem",
  },
  extraFieldContainer: {
    padding: "0.625rem 0rem",
  },
  entityLabel: {
    paddingLeft: "0.625rem",
  },
  editIcon: {
    width: "auto",
    height: "35px",
  },
  iconButton: {
    padding: "0px",
  },
}));

export enum CheckboxFields {
  additionalInsuredCheckbox = "additional-insured-checkbox",
  lossPayeeCheckbox = "loss-payee-checkbox",
  additionalInsuredComplete = "additional-insured-complete",
  lossPayeeComplete = "loss-payee-complete",
}

export { Fields };

export const useValidationSchema = () => {
  const addressFormValidationSchema = useAddressFormValidationSchema();

  return Yup.object({
    [CheckboxFields.additionalInsuredCheckbox]: Yup.boolean(),
    [CheckboxFields.lossPayeeCheckbox]: Yup.boolean(),
    [Fields.lossPayee]: Yup.object({}).when(CheckboxFields.lossPayeeCheckbox, {
      is: true,
      then: addressFormValidationSchema,
      otherwise: Yup.object({}),
    }),
    [CheckboxFields.additionalInsuredComplete]: Yup.boolean().when(CheckboxFields.additionalInsuredCheckbox, {
      is: true,
      then: Yup.boolean().oneOf([true]),
    }),
    [CheckboxFields.lossPayeeComplete]: Yup.boolean().when(CheckboxFields.lossPayeeCheckbox, {
      is: true,
      then: Yup.boolean().oneOf([true]),
    }),
  });
};

export type CheckboxFormsProps = {
  [Fields.additionalInsured]?: AdditionalInsuredFormProps;
  [Fields.lossPayee]?: AddressFormProps;
  [CheckboxFields.additionalInsuredCheckbox]?: boolean;
  [CheckboxFields.lossPayeeCheckbox]?: boolean;
  [CheckboxFields.additionalInsuredComplete]?: boolean;
  [CheckboxFields.lossPayeeComplete]?: boolean;
};

export type Props = {
  index: number;
  arrayType: keyof ReviewTrailersPowerUnitsFormProps;
};

export type ReviewTrailerPowerUnitFormProps = CheckboxFormsProps & {
  unitInfo: Equipment;
};

export type ReviewTrailersPowerUnitsFormProps = {
  powerUnits?: ReviewTrailerPowerUnitFormProps[];
  trailers?: ReviewTrailerPowerUnitFormProps[];
};

export const useArrayValidationSchema = () => {
  const reviewPowerUnitValidationSchema = useValidationSchema();
  return Yup.object({
    powerUnits: Yup.array().of(reviewPowerUnitValidationSchema),
    trailers: Yup.array().of(reviewPowerUnitValidationSchema),
  });
};

export const useFormikConfig = ({ powerUnits = [], trailers = [] }: ReviewTrailersPowerUnitsFormProps = {}): Omit<
  FormikConfig<ReviewTrailersPowerUnitsFormProps>,
  "onSubmit"
> => {
  const validationSchema = useArrayValidationSchema();
  return {
    initialValues: {
      powerUnits,
      trailers,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const ReviewPowerUnitTrailerForm: React.FC<Props> = ({ index, arrayType }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<ReviewTrailersPowerUnitsFormProps>();

  return (
    <form>
      <Grid>
        <Grid container className={classes.powerUnitContainer}>
          <CardField
            id={`${PowerUnitTrailerFields.vehicleNumber}-${arrayType}-${index}`}
            labelColor="textPrimary"
            label={formik.values[arrayType]![index].unitInfo.vehicleNumber || String(index + 1)}
            className={classes.vehicleNumber}
          />
          <CardField
            id={`${PowerUnitTrailerFields.vin}-${arrayType}-${index}`}
            label={t(`power-units-card.${PowerUnitTrailerFields.vin}`)}
            values={[{ value: formik.values[arrayType]![index].unitInfo.vin?.toUpperCase() }]}
          />
          <CardField
            id={`${PowerUnitTrailerFields.make}-${arrayType}-${index}`}
            label={t(`power-units-card.${PowerUnitTrailerFields.make}`)}
            values={[{ value: formik.values[arrayType]![index].unitInfo.make }]}
          />
          {formik.values[arrayType]![index].unitInfo.model && (
            <CardField
              id={`${PowerUnitTrailerFields.model}-${arrayType}-${index}`}
              label={t(`power-units-card.${PowerUnitTrailerFields.model}`)}
              values={[{ value: formik.values[arrayType]![index].unitInfo.model }]}
            />
          )}
          <CardField
            id={`${PowerUnitTrailerFields.year}-${arrayType}-${index}`}
            label={t(`power-units-card.${PowerUnitTrailerFields.year}`)}
            values={[{ value: formik.values[arrayType]![index].unitInfo.year }]}
          />
          <CardField
            id={`${PowerUnitTrailerFields.destination}-${arrayType}-${index}`}
            label={t(`power-units-card.${PowerUnitTrailerFields.destination}`)}
            values={[
              {
                value: t(
                  `destination-type.${formik.values[arrayType]![index].unitInfo.destinationZone?.replace(".", "")}`,
                ),
              },
            ]}
          />
          {formik.values[arrayType]![index].unitInfo.compAndCollisionCoverage && (
            <>
              <CardField
                id={`${PowerUnitTrailerFields.coverageOption}-${arrayType}-${index}`}
                label={t(`power-units-card.${PowerUnitTrailerFields.coverageOption}`)}
                values={[{ value: formik.values[arrayType]![index].unitInfo.compAndCollisionCoverage }]}
              />
              {formik.values[arrayType]![index].unitInfo.compAndCollisionCoverage !==
                CompAndCollisionCoverage.NoCoverage && (
                <CardField
                  id={`${PowerUnitTrailerFields.loanLeaseGap}-${arrayType}-${index}`}
                  label={t(`power-units-card.${PowerUnitTrailerFields.loanLeaseGap}`)}
                  values={[
                    {
                      value: t(booleanToYesNoString(formik.values[arrayType]![index].unitInfo.loanLeaseGapCoverage)),
                    },
                  ]}
                />
              )}
            </>
          )}
        </Grid>
        <Grid container>
          <Grid container className={classes.secondaryContainer}>
            <div className={classes.fullWidthCheckbox}>
              <Grid container direction="column">
                <Grid item>
                  <Paper square elevation={0} className={classNames(classes.checkboxContainer, classes.firstCheckbox)}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item>
                        <Checkbox
                          label={t(
                            `review-application-page.review-power-units-trailers-form.add-additional-insured.label`,
                          )}
                          id={`${CheckboxFields.additionalInsuredCheckbox}-${arrayType}-${index}`}
                          name={`${arrayType}.${[index]}.${CheckboxFields.additionalInsuredCheckbox}`}
                          className={classes.checkbox}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              const newPowerUnitValues = formik.values[arrayType]!.map((unit, i) => {
                                if (index === i) {
                                  return {
                                    ...unit,
                                    [CheckboxFields.additionalInsuredCheckbox]: false,
                                    [Fields.additionalInsured]: {},
                                    [CheckboxFields.additionalInsuredComplete]: false,
                                  };
                                } else return unit;
                              });
                              formik.setValues({
                                ...formik.values,
                                [arrayType]: newPowerUnitValues,
                              });
                            } else {
                              formik.setFieldValue(
                                `${arrayType}.${[index]}.${CheckboxFields.additionalInsuredCheckbox}`,
                                e.target.checked,
                              );
                            }
                          }}
                          value={formik.values[arrayType]![index][CheckboxFields.additionalInsuredCheckbox] || false}
                          checked={formik.values[arrayType]![index][CheckboxFields.additionalInsuredCheckbox] || false}
                          disableRipple
                        />
                      </Grid>
                      <Grid
                        item
                        className={classNames({
                          [classes.tooltipContainer]: !formik.values[arrayType]![index][
                            CheckboxFields.additionalInsuredComplete
                          ],
                        })}
                      >
                        {formik.values[arrayType]![index][CheckboxFields.additionalInsuredComplete] &&
                        formik.values[arrayType]![index][CheckboxFields.additionalInsuredCheckbox] ? (
                          <IconButton
                            className={classes.iconButton}
                            aria-label={t(
                              "policy-management-page.equipment-page.equipment-form.edit-icon-additional-insured",
                            )}
                            onClick={() => {
                              formik.setFieldValue(
                                `${arrayType}.${[index]}.${CheckboxFields.additionalInsuredComplete}`,
                                false,
                              );
                            }}
                          >
                            <EditIcon className={classes.editIcon} />
                          </IconButton>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item>
                      {formik.values[arrayType]![index][CheckboxFields.additionalInsuredCheckbox] &&
                        (formik.values[arrayType]![index][CheckboxFields.additionalInsuredComplete] &&
                        formik.values[arrayType]![index][Fields.additionalInsured] !== undefined ? (
                          <CardField
                            id={"additional-insured-form-info"}
                            label={t(
                              `review-application-page.review-power-units-trailers-form.checkbox-form.entity-name.label`,
                            )}
                            values={[
                              {
                                value: formik.values[arrayType]![index][Fields.additionalInsured]![
                                  CheckboxFormFields.entityName
                                ]!,
                              },
                            ]}
                            className={classes.extraFieldContainer}
                            labelClass={classes.entityLabel}
                          />
                        ) : (
                          <AdditionalInsuredForm
                            entityName={
                              formik.values[arrayType]![index][Fields.additionalInsured]?.[
                                CheckboxFormFields.entityName
                              ]
                            }
                            addressLine1={
                              formik.values[arrayType]![index][Fields.additionalInsured]?.[
                                CheckboxFormFields.addressLine1
                              ]
                            }
                            addressLine2={
                              formik.values[arrayType]![index][Fields.additionalInsured]?.[
                                CheckboxFormFields.addressLine2
                              ]
                            }
                            city={formik.values[arrayType]![index][Fields.additionalInsured]?.[CheckboxFormFields.city]}
                            state={
                              formik.values[arrayType]![index][Fields.additionalInsured]?.[CheckboxFormFields.state]
                            }
                            zipcode={
                              formik.values[arrayType]![index][Fields.additionalInsured]?.[CheckboxFormFields.zipcode]
                            }
                            ein={formik.values[arrayType]![index][Fields.additionalInsured]?.[CheckboxFormFields.ein]}
                            onSubmit={(values) => {
                              formik.setFieldValue(`${arrayType}.${[index]}.${Fields.additionalInsured}`, values);
                              formik.setFieldValue(
                                `${arrayType}.${[index]}.${CheckboxFields.additionalInsuredComplete}`,
                                true,
                              );
                            }}
                          />
                        ))}
                    </Grid>
                  </Paper>
                  <Paper square elevation={0} className={classNames(classes.checkboxContainer, classes.firstCheckbox)}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item>
                        <Checkbox
                          label={t(`review-application-page.review-power-units-trailers-form.add-loss-payee.label`)}
                          id={`${CheckboxFields.lossPayeeCheckbox}-${arrayType}-${index}`}
                          name={CheckboxFields.lossPayeeCheckbox}
                          className={classes.checkbox}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              const newPowerUnitValues = formik.values[arrayType]!.map((unit, i) => {
                                if (index === i) {
                                  return {
                                    ...unit,
                                    [CheckboxFields.lossPayeeCheckbox]: false,
                                    [Fields.lossPayee]: {},
                                    [CheckboxFields.lossPayeeComplete]: false,
                                  };
                                } else return unit;
                              });
                              formik.setValues({
                                ...formik.values,
                                [arrayType]: newPowerUnitValues,
                              });
                            } else {
                              formik.setFieldValue(
                                `${arrayType}.${[index]}.${CheckboxFields.lossPayeeCheckbox}`,
                                e.target.checked,
                              );
                            }
                          }}
                          value={formik.values[arrayType]![index][CheckboxFields.lossPayeeCheckbox] || false}
                          checked={formik.values[arrayType]![index][CheckboxFields.lossPayeeCheckbox] || false}
                          disableRipple
                        />
                      </Grid>
                      <Grid
                        item
                        className={classNames({
                          [classes.tooltipContainer]: !formik.values[arrayType]![index][
                            CheckboxFields.additionalInsuredComplete
                          ],
                        })}
                      >
                        {formik.values[arrayType]![index][CheckboxFields.lossPayeeComplete] &&
                        formik.values[arrayType]![index][CheckboxFields.lossPayeeCheckbox] ? (
                          <IconButton
                            className={classes.iconButton}
                            aria-label={t("policy-management-page.equipment-page.equipment-form.edit-icon-loss-payee")}
                            onClick={() => {
                              formik.setFieldValue(
                                `${arrayType}.${[index]}.${CheckboxFields.lossPayeeComplete}`,
                                false,
                              );
                            }}
                          >
                            <EditIcon className={classes.editIcon} />
                          </IconButton>
                        ) : (
                          <TooltipHelp
                            title={
                              t(
                                "review-application-page.review-power-units-trailers-form.add-loss-payee.tooltip",
                              ) as string
                            }
                            placement="left"
                            color="disabled"
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      {formik.values[arrayType]![index][CheckboxFields.lossPayeeCheckbox] &&
                        (formik.values[arrayType]![index][CheckboxFields.lossPayeeComplete] &&
                        formik.values[arrayType]![index][Fields.lossPayee] !== undefined ? (
                          <CardField
                            id={"loss-payee-form-info"}
                            label={t(
                              `review-application-page.review-power-units-trailers-form.checkbox-form.entity-name.label`,
                            )}
                            values={[
                              {
                                value: formik.values[arrayType]![index][Fields.lossPayee]![
                                  CheckboxFormFields.entityName
                                ]!,
                              },
                            ]}
                            className={classes.extraFieldContainer}
                            labelClass={classes.entityLabel}
                          />
                        ) : (
                          <LossPayeeForm
                            entityName={
                              formik.values[arrayType]![index][Fields.lossPayee]?.[CheckboxFormFields.entityName]
                            }
                            addressLine1={
                              formik.values[arrayType]![index][Fields.lossPayee]?.[CheckboxFormFields.addressLine1]
                            }
                            addressLine2={
                              formik.values[arrayType]![index][Fields.lossPayee]?.[CheckboxFormFields.addressLine2]
                            }
                            city={formik.values[arrayType]![index][Fields.lossPayee]?.[CheckboxFormFields.city]}
                            state={formik.values[arrayType]![index][Fields.lossPayee]?.[CheckboxFormFields.state]}
                            zipcode={formik.values[arrayType]![index][Fields.lossPayee]?.[CheckboxFormFields.zipcode]}
                            onSubmit={(values) => {
                              formik.setFieldValue(`${arrayType}.${[index]}.${Fields.lossPayee}`, values);
                              formik.setFieldValue(`${arrayType}.${[index]}.${CheckboxFields.lossPayeeComplete}`, true);
                            }}
                          />
                        ))}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReviewPowerUnitTrailerForm;
