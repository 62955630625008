/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Application,
  ApplicationFromJSON,
  ApplicationFromJSONTyped,
  ApplicationToJSON,
  Quote,
  QuoteFromJSON,
  QuoteFromJSONTyped,
  QuoteToJSON,
  StripeBankAccount,
  StripeBankAccountFromJSON,
  StripeBankAccountFromJSONTyped,
  StripeBankAccountToJSON,
} from "./";

/**
 *
 * @export
 * @interface ApplicationReview
 */
export interface ApplicationReview {
  /**
   *
   * @type {Application}
   * @memberof ApplicationReview
   */
  application?: Application;
  /**
   *
   * @type {Quote}
   * @memberof ApplicationReview
   */
  quote?: Quote;
  /**
   *
   * @type {StripeBankAccount}
   * @memberof ApplicationReview
   */
  bankAccount?: StripeBankAccount;
  /**
   *
   * @type {string}
   * @memberof ApplicationReview
   */
  bankAccountInfo?: string | null;
}

export function ApplicationReviewFromJSON(json: any): ApplicationReview {
  return ApplicationReviewFromJSONTyped(json, false);
}

export function ApplicationReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationReview {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    application: !exists(json, "application") ? undefined : ApplicationFromJSON(json["application"]),
    quote: !exists(json, "quote") ? undefined : QuoteFromJSON(json["quote"]),
    bankAccount: !exists(json, "bankAccount") ? undefined : StripeBankAccountFromJSON(json["bankAccount"]),
    bankAccountInfo: !exists(json, "bankAccountInfo") ? undefined : json["bankAccountInfo"],
  };
}

export function ApplicationReviewToJSON(value?: ApplicationReview | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    application: ApplicationToJSON(value.application),
    quote: QuoteToJSON(value.quote),
    bankAccount: StripeBankAccountToJSON(value.bankAccount),
    bankAccountInfo: value.bankAccountInfo,
  };
}
