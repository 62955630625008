import { EditableDeletableCard } from "@chq/components";
import { Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textTransform: "uppercase",
    paddingTop: "1.25rem",
  },
  titleContainer: {
    textAlign: "center",
  },
  contactContainer: {
    textAlign: "center",
    paddingTop: "0.625rem",
  },
}));

const QuestionCard: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard variant="default" elevation={1} border={false}>
      <Grid container>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h4" className={classes.title}>
            {t("question-card.title")}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.contactContainer}>
          <Typography component="p">
            <Trans i18nKey={"question-card.contact"}>
              <Typography variant="body1" component="span">
                Email
              </Typography>
              <Link href={`mailto:SFARRG@aon.com`} variant="body1">
                SFARRG@aon.com
              </Link>
              <Typography variant="body1" component="span">
                or call
              </Typography>
              <Link href={"tel:800-492-5756"} variant="body1">
                800-492-5756
              </Link>
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default QuestionCard;
