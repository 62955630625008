/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface StripeCustomer
 */
export interface StripeCustomer {
  /**
   *
   * @type {string}
   * @memberof StripeCustomer
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeCustomer
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeCustomer
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeCustomer
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeCustomer
   */
  defaultSource?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof StripeCustomer
   */
  deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof StripeCustomer
   */
  paymentMethodId?: string | null;
}

export function StripeCustomerFromJSON(json: any): StripeCustomer {
  return StripeCustomerFromJSONTyped(json, false);
}

export function StripeCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeCustomer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    description: !exists(json, "description") ? undefined : json["description"],
    email: !exists(json, "email") ? undefined : json["email"],
    name: !exists(json, "name") ? undefined : json["name"],
    defaultSource: !exists(json, "defaultSource") ? undefined : json["defaultSource"],
    deleted: !exists(json, "deleted") ? undefined : json["deleted"],
    paymentMethodId: !exists(json, "paymentMethodId") ? undefined : json["paymentMethodId"],
  };
}

export function StripeCustomerToJSON(value?: StripeCustomer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    description: value.description,
    email: value.email,
    name: value.name,
    defaultSource: value.defaultSource,
    deleted: value.deleted,
    paymentMethodId: value.paymentMethodId,
  };
}
