import { CardField, EditableDeletableCard, EditableDeletableCardProps, formatEinNumber } from "@chq/components";
import { State } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatPhoneNumber } from "../utils/phone-number-format";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.grey[600],
    padding: "1.125rem .625rem 1rem .625rem",
  },
}));

type Props = {
  name: string;
  phone: string;
  ein: string;
  dotNumber: string;
  mcNumber: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  state: State | "";
  zip: string;
  onEdit?: EditableDeletableCardProps["onEdit"];
};

const FactoringBusinessDetailsCard: React.FC<Props> = ({
  name,
  phone,
  ein,
  dotNumber,
  mcNumber,
  addressLineOne,
  addressLineTwo,
  city,
  state,
  zip,
  onEdit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard
      variant="edit"
      title={t("factoring.business-details-card.title")}
      titleVariant="h2"
      titleComponent="h3"
      onEdit={onEdit}
      IconButtonProps={{ "aria-label": t("factoring.business-details-card.edit-button") }}
      className={classes.container}
    >
      <Grid container>
        <CardField id="name" label={t("factoring.business-details-card.name")} values={[{ value: name }]} />
        <CardField
          id="phone"
          label={t("factoring.business-details-card.phone")}
          values={[{ value: formatPhoneNumber(phone)! }]}
        />
        <CardField
          id="ein"
          label={t("factoring.business-details-card.ein")}
          values={[{ value: formatEinNumber(ein)! }]}
        />
        <CardField
          id="dotNumber"
          label={t("factoring.business-details-card.dot-number")}
          values={[{ value: dotNumber }]}
        />
        <CardField
          id="mcNumber"
          label={t("factoring.business-details-card.mc-number")}
          values={[{ value: mcNumber }]}
        />
        <CardField
          id="addressLineOne"
          label={t("factoring.business-details-card.address-line-one")}
          values={[{ value: addressLineOne }]}
        />
        {addressLineTwo && (
          <CardField
            id="addressLineTwo"
            label={t("factoring.business-details-card.address-line-two")}
            values={[{ value: addressLineTwo }]}
          />
        )}
        <CardField id="city" label={t("factoring.business-details-card.city")} values={[{ value: city }]} />
        <CardField id="state" label={t("factoring.business-details-card.state")} values={[{ value: state }]} />
        <CardField id="zip" label={t("factoring.business-details-card.zip")} values={[{ value: zip }]} />
      </Grid>
    </EditableDeletableCard>
  );
};

export default FactoringBusinessDetailsCard;
