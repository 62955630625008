/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TokenPasswordResetRequest
 */
export interface TokenPasswordResetRequest {
  /**
   *
   * @type {string}
   * @memberof TokenPasswordResetRequest
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof TokenPasswordResetRequest
   */
  token?: string | null;
  /**
   *
   * @type {string}
   * @memberof TokenPasswordResetRequest
   */
  password?: string | null;
}

export function TokenPasswordResetRequestFromJSON(json: any): TokenPasswordResetRequest {
  return TokenPasswordResetRequestFromJSONTyped(json, false);
}

export function TokenPasswordResetRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TokenPasswordResetRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    token: !exists(json, "token") ? undefined : json["token"],
    password: !exists(json, "password") ? undefined : json["password"],
  };
}

export function TokenPasswordResetRequestToJSON(value?: TokenPasswordResetRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    token: value.token,
    password: value.password,
  };
}
