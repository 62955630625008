import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const PlusCircleIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15 12.6H12.6V15H11.4V12.6H9V11.4H11.4V9H12.6V11.4H15V12.6ZM12 6C11.2121 6 10.4319 6.15519 9.7039 6.45672C8.97595 6.75825 8.31451 7.20021 7.75736 7.75736C6.63214 8.88258 6 10.4087 6 12C6 13.5913 6.63214 15.1174 7.75736 16.2426C8.31451 16.7998 8.97595 17.2417 9.7039 17.5433C10.4319 17.8448 11.2121 18 12 18C13.5913 18 15.1174 17.3679 16.2426 16.2426C17.3679 15.1174 18 13.5913 18 12C18 11.2121 17.8448 10.4319 17.5433 9.7039C17.2417 8.97595 16.7998 8.31451 16.2426 7.75736C15.6855 7.20021 15.0241 6.75825 14.2961 6.45672C13.5681 6.15519 12.7879 6 12 6Z" />
    </SvgIcon>
  );
};

export default PlusCircleIcon;
