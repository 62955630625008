import { ButtonProps, SvgIconProps } from "@material-ui/core";
import React from "react";
import ArrowLeftIcon from "../icons/arrow-left";
import CommonButton from "./common-button";

type Props = ButtonProps & {
  iconProps?: SvgIconProps;
};

const PreviousButton: React.FC<Props> = ({ iconProps, ...buttonProp }) => {
  return <CommonButton {...buttonProp} startIcon={<ArrowLeftIcon viewBox="4 4 16 16" {...iconProps} />} />;
};

export default PreviousButton;
