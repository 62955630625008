import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const BlackCircle: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <circle cx="12" cy="12" r="6.6" />
    </SvgIcon>
  );
};

export default BlackCircle;
