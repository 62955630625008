import { ForgotPasswordForm, hasAdminRole, LoginForm, useAuth } from "@chq/authentication";
import { Header } from "@chq/components";
import { fade, Grid, makeStyles, Theme } from "@material-ui/core/";
import { startOfToday } from "date-fns";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import { useGetApplication } from "../../../data/enrollment";
import loginBackgroundDesktop from "../../../images/login-road-background-desktop.png";
import loginBackground from "../../../images/login-road-background.png";
import { RouteNames } from "../../../utils/route-names";
import Footer from "../../components/footer";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: "100vh",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: "cover",
    overflowX: "hidden",
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${loginBackgroundDesktop})`,
      backgroundSize: "cover",
    },
  },
  header: {
    backgroundColor: fade(theme.palette.common.white, 0.8),
  },
}));

enum LoginStatus {
  login = 0,
  forgotPassword,
}

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [status, setStatus] = useState<LoginStatus>(LoginStatus.login);
  const { search } = useLocation();
  const queryString = qs.parse(search.slice(1));
  const returnTo = queryString?.returnTo as string;
  const today = startOfToday();
  const { isAuthenticated } = useAuth();
  const isAdmin = hasAdminRole();
  const { isSuccess: foundApplication, isError: failedToGetApplication, data: application } = useGetApplication(
    undefined,
    {
      enabled: isAuthenticated && !isAdmin,
    },
  );

  useEffect(() => {
    if (isAdmin) {
      // Redirect to Command (another react app on the same domain)
      window.location.assign(returnTo || "/command");
    }
  }, [isAdmin, returnTo]);

  if (failedToGetApplication) {
    return <Redirect push to={routes.enrollment.path} />;
  }

  if (foundApplication && application) {
    if (
      application?.insuranceProgram?.active === false &&
      (application.applicationState === RouteNames.quoteOverview ||
        application.applicationState === RouteNames.saveQuote ||
        application.applicationState === RouteNames.verify ||
        application.applicationState === RouteNames.success ||
        application.applicationState === RouteNames.successAcount ||
        application.applicationState === RouteNames.finishApplication ||
        application.applicationState === RouteNames.payment ||
        application.applicationState === RouteNames.review ||
        application.applicationState === RouteNames.quotePending)
    ) {
      return <Redirect to={routes.enrollment.quote.inactive.path} />;
    } else if (application.currentQuote?.expirationDate && today > application.currentQuote.expirationDate) {
      return <Redirect to={{ pathname: routes.enrollment.path, search: qs.stringify({ expired: true }) }} />;
    } else {
      return <Redirect to={application.applicationState || routes.enrollment.basicInfo.path} />;
    }
  }
  return (
    <Grid container className={classes.container}>
      <Header className={classes.header} chqTitleAccess={t("common.chq-logo")} chqAriaLabel={t("common.chq-logo")} />
      {status === LoginStatus.login ? (
        <LoginForm
          title={t("login-form.title")}
          returnTo={returnTo}
          forgotPassOnClick={() => setStatus(LoginStatus.forgotPassword)}
        />
      ) : (
        <ForgotPasswordForm backToLoginOnClick={() => setStatus(LoginStatus.login)} />
      )}

      <Footer />
    </Grid>
  );
};

export default LoginPage;
