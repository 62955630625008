import { Grid, IconButton, makeStyles, Paper, Theme } from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import Checkbox from "./checkbox";
import { PencilIcon as EditIcon } from "./icons";
import TooltipHelp from "./tooltip-help";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    padding: "0px",
  },
  checkboxContainer: {
    padding: "0rem 0.625rem 0rem 0.5rem",
    backgroundColor: theme.palette.grey[200],
    "& .MuiGrid-root > .MuiFormControlLabel-root": {
      marginLeft: "0px",
    },
  },
  checkbox: {
    color: theme.palette.grey[500],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    "&.Mui-checked:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  firstCheckbox: {
    marginBottom: "0.125rem",
  },
  tooltipContainer: {
    height: "1.5rem",
  },
  editIcon: {
    width: "auto",
    height: "35px",
  },
}));

type Props = {
  complete: boolean | undefined;
  currentCheckState: boolean | undefined;
  checkboxLabel: string;
  editButtonAriaLabel: string;
  tooltipText: string;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onEdit: React.MouseEventHandler<HTMLButtonElement>;
};
const CheckboxForm: React.FC<Props> = ({
  complete = false,
  currentCheckState = false,
  onCheck,
  onEdit,
  checkboxLabel,
  editButtonAriaLabel,
  tooltipText,
  children,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(currentCheckState);

  return (
    <Paper square elevation={0} className={classNames(classes.checkboxContainer, classes.firstCheckbox)}>
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item>
          <Checkbox
            label={checkboxLabel}
            className={classes.checkbox}
            checked={checked}
            onChange={(event, checked) => {
              onCheck(event, checked);
              setChecked(event.target.checked);
            }}
            disableRipple
          />
        </Grid>
        <Grid item className={classNames({ [classes.tooltipContainer]: !complete })}>
          {complete && checked ? (
            <IconButton className={classes.iconButton} aria-label={editButtonAriaLabel} onClick={onEdit}>
              <EditIcon className={classes.editIcon} />
            </IconButton>
          ) : (
            <TooltipHelp title={tooltipText} placement="left" color="disabled" />
          )}
        </Grid>
      </Grid>
      <Grid item>{children}</Grid>
    </Paper>
  );
};

export default CheckboxForm;
