import { EditableDeletableCard } from "@chq/components";
import { StringApiResponse } from "@chq/enrollment-api";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import classNames from "classnames";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { PlaidLinkOnEvent, PlaidLinkOnExit, PlaidLinkOnSuccess } from "react-plaid-link";
import * as Yup from "yup";
import { PlaidLink } from "../ui/enrollment/components/plaid-link";
import AddBankAccountCompleteCard from "./add-bank-account-complete-card";
import ConnectBankManuallyCard from "./connect-bank-manually-card";
import VerifyAccountCompleteCard from "./verify-account-complete-card";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    "& > div": {
      paddingBottom: "1rem",
    },
    backgroundColor: "transparent",
  },
  setUpButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginTop: "0.75rem",
    minHeight: "3.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    fontSize: "0.75rem",
    lineHeight: "1rem",
    textTransform: "uppercase",
    "& .MuiButton-label": {
      justifyContent: "space-between",
    },
  },
  icon: {
    height: "2.5rem",
    width: "auto",
    color: theme.palette.success.main,
  },
  checkboxContainer: {
    marginBottom: ".75rem",
  },
  connectBankAccount: {
    marginTop: "1rem",
    fontSize: "1.25rem",
    fontWeight: "bold",
  },
  radioBilling: {
    border: `1px solid ${theme.palette.secondary.light}`,
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: ".25rem",
    display: "block",
    padding: ".5rem",
    margin: ".25rem 0",
    "& .MuiTypography-root": {
      fontWeight: 700,
      padding: 0,
    },
    "&:hover": {
      background: theme.palette.action.active,
    },
  },
  active: {
    background: theme.palette.action.active,
  },
  radioContain: {
    padding: ".25rem",
    width: "100%",
  },
  radioHide: {
    display: "none",
  },
  radioHeading: {
    fontWeight: 700,
  },
  weeklyTotal: {
    color: theme.palette.success.main,
    fontSize: "1.125rem",
  },
  greyText: {
    color: theme.palette.grey[400],
  },
  dueAtBind: {
    fontSize: ".75rem",
  },
  downPaymentAmount: {
    color: theme.palette.grey[800],
    fontsize: ".875rem",
    fontWeight: 700,
  },
}));

export enum Fields {
  billingCycle = "billing-cycle",
  setUpBank = "set-up-bank-account",
}

export type PaymentMethodFormProps = {
  [Fields.billingCycle]: string;
};

type FormProps = {
  billingCycle?: string;
  setUpBank?: boolean;
};

type Props = {
  paymentChecked: boolean;
  accountNumber: string;
  bankName: string;
  onComplete: () => void;
  onNotComplete: () => void;
  onPaymentChecked: () => void;
  onPaymentNotChecked: () => void;
  plaidOnSuccess: PlaidLinkOnSuccess;
  plaidOnEvent: PlaidLinkOnEvent;
  plaidOnExit: PlaidLinkOnExit;
  plaidSuccess: boolean | undefined;
  stripeSuccess: boolean | undefined;
  token?: StringApiResponse | undefined;
  onAddAccountDialogOpen: () => void;
  onVerifyAccountDialogOpen: () => void;
  onAddNewBankAccount: () => void;
  stripeBankAccountAdded: boolean;
  weeklyDownPayment?: string;
  monthlyDownPayment?: string;
  weeklyTotal: string;
  monthlyTotal: string;
  annualTotal: string;
  onSubmit?: FormikConfig<PaymentMethodFormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.billingCycle]: Yup.string().required(
      t(`errors.required`, { field: t(`finish-application.payment-method-form.${Fields.billingCycle}.label`) }),
    ),
  });
};

export const useFormikConfig = ({ billingCycle = "", setUpBank = undefined }: FormProps = {}): Omit<
  FormikConfig<PaymentMethodFormProps>,
  "onSubmit"
> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.billingCycle]: billingCycle,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const PaymentMethodForm: React.FC<Props> = ({
  paymentChecked = false,
  accountNumber,
  bankName,
  onComplete,
  onNotComplete,
  onPaymentChecked,
  onPaymentNotChecked,
  plaidOnSuccess,
  plaidOnEvent,
  plaidOnExit,
  plaidSuccess,
  stripeSuccess,
  stripeBankAccountAdded,
  onAddAccountDialogOpen,
  onVerifyAccountDialogOpen,
  onAddNewBankAccount,
  token,
  weeklyDownPayment,
  monthlyDownPayment,
  weeklyTotal,
  monthlyTotal,
  annualTotal,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<PaymentMethodFormProps>();

  return (
    <EditableDeletableCard variant="default" className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="row" justify="center" alignItems="center" className={classes.checkboxContainer}>
          <Checkbox
            checked={paymentChecked}
            onChange={(event) => {
              event.target.checked ? onPaymentChecked() : onPaymentNotChecked();
              onNotComplete();
            }}
            inputProps={{ "aria-label": t(`finish-application.payment-method-form.${Fields.setUpBank}.checkbox`) }}
          />
          <Typography variant="h2">{t("finish-application.payment-method-page.payment-button")}</Typography>
        </Grid>
        <FormControl component="fieldset" margin="none" className={classes.radioContain}>
          <FormLabel component="legend">
            <Typography variant="h2" className={classes.radioHeading}>
              {t("finish-application.payment-method-form.select-your-billing-frequency")}
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-label={t("finish-application.payment-method-form.select-your-billing-frequency")}
            id={Fields.billingCycle}
            name={Fields.billingCycle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.billingCycle]}
          >
            <FormControlLabel
              value={t(`finish-application.payment-method-form.${Fields.billingCycle}.weekly-lower`)}
              control={<Radio className={classes.radioHide} />}
              label={
                <Grid container direction="column">
                  <Grid item container justify="space-between" direction="row">
                    <Grid item>
                      <Typography component="h2" variant="h3">
                        {t("finish-application.payment-method-form.billing-cycle.weekly")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="h2" variant="h2" className={classes.weeklyTotal}>
                        {t("enrollment.quote-overview-page.quote-summary.wk", { money: weeklyTotal })}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="flex-end" justify="space-between" direction="row">
                    <Grid item>
                      <Typography
                        component="h2"
                        variant="h4"
                        className={classNames(classes.greyText, classes.dueAtBind)}
                      >
                        {t("enrollment.quote-overview-page.down-payment-due-at-bind")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classNames(classes.greyText, classes.downPaymentAmount)}
                      >
                        <strong>{t("common.currency", { money: weeklyDownPayment })}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              }
              className={classNames(classes.radioBilling, {
                [classes.active]:
                  formik.values[Fields.billingCycle] ===
                  t(`finish-application.payment-method-form.${Fields.billingCycle}.weekly-lower`),
              })}
            />
            <FormControlLabel
              value={t(`finish-application.payment-method-form.${Fields.billingCycle}.monthly-lower`)}
              control={<Radio className={classes.radioHide} />}
              label={
                <Grid container direction="column">
                  <Grid item container justify="space-between" direction="row">
                    <Grid item>
                      <Typography component="h2" variant="h3">
                        {t("finish-application.payment-method-form.billing-cycle.monthly")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        <strong>{t("enrollment.quote-overview-page.quote-summary.mo", { money: monthlyTotal })}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="flex-end" justify="space-between" direction="row">
                    <Grid item>
                      <Typography
                        component="h2"
                        variant="h4"
                        className={classNames(classes.greyText, classes.dueAtBind)}
                      >
                        {t("enrollment.quote-overview-page.down-payment-due-at-bind")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        component="h2"
                        variant="h3"
                        className={classNames(classes.greyText, classes.downPaymentAmount)}
                      >
                        {t("common.currency", { money: monthlyDownPayment })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              }
              className={classNames(classes.radioBilling, {
                [classes.active]:
                  formik.values[Fields.billingCycle] ===
                  t(`finish-application.payment-method-form.${Fields.billingCycle}.monthly-lower`),
              })}
            />
            <FormControlLabel
              value={t(`finish-application.payment-method-form.${Fields.billingCycle}.annual-lower`)}
              control={<Radio className={classes.radioHide} />}
              label={
                <Grid container direction="column">
                  <Grid item container justify="space-between" direction="row">
                    <Grid item>
                      <Typography component="h2" variant="h3">
                        {t("finish-application.payment-method-form.billing-cycle.annually")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        <strong>{t("enrollment.quote-overview-page.quote-summary.yr", { money: annualTotal })}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="flex-end" justify="space-between" direction="row">
                    <Grid item>
                      <Typography
                        component="h2"
                        variant="h4"
                        className={classNames(classes.greyText, classes.dueAtBind)}
                      >
                        {t("enrollment.quote-overview-page.full-payment-due-at-bind")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              }
              className={classNames(classes.radioBilling, {
                [classes.active]:
                  formik.values[Fields.billingCycle] ===
                  t(`finish-application.payment-method-form.${Fields.billingCycle}.annual-lower`),
              })}
            />
          </RadioGroup>
        </FormControl>

        {/* hide if billing frequency has not been selected */}
        {!stripeBankAccountAdded && token && formik.values[Fields.billingCycle] !== "" && (
          <Grid className={classes.connectBankAccount}>
            <Typography variant="h2">
              {t(`finish-application.payment-method-form.set-up-bank-account.button`)}
            </Typography>
            <PlaidLink
              onSuccess={plaidOnSuccess}
              onEvent={plaidOnEvent}
              onExit={plaidOnExit}
              plaidSuccess={plaidSuccess}
              token={token!.data!}
            />
          </Grid>
        )}
        {plaidSuccess === false && !stripeBankAccountAdded && (
          <ConnectBankManuallyCard
            onAddAccountDialogOpen={onAddAccountDialogOpen}
            onComplete={onComplete}
            onNotComplete={onNotComplete}
          />
        )}
        {stripeBankAccountAdded && !stripeSuccess && (
          <AddBankAccountCompleteCard
            accountNumber={accountNumber}
            onVerifyAccountDialogOpen={onVerifyAccountDialogOpen}
          />
        )}
        {stripeSuccess && (
          <VerifyAccountCompleteCard
            onAddNewBankAccount={onAddNewBankAccount}
            bankName={bankName}
            accountNumber={accountNumber}
          />
        )}
      </form>
    </EditableDeletableCard>
  );
};

export default PaymentMethodForm;
