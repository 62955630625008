import { EditableDeletableCard } from "@chq/components";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.action.active,
  },
  welcomeBack: {
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    paddingTop: "1rem",
  },
  startedQuote: {
    display: "flex",
    justifyContent: "center",
  },
}));

const QualifyWelcomeBackCard: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard variant="default" border={false} className={classes.container}>
      <Typography variant="h3" className={classes.welcomeBack}>
        {t("qualify-welcome-back-card.welcome-back")}
      </Typography>
      <Typography variant="h3" className={classes.startedQuote}>
        {t("qualify-welcome-back-card.started-quote")}
      </Typography>
    </EditableDeletableCard>
  );
};

export default QualifyWelcomeBackCard;
