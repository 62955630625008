import { useAuth } from "@chq/authentication";
import { NextButton } from "@chq/components";
import { State } from "@chq/enrollment-api";
import { Button, CircularProgress, Dialog, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import ContinueQuoteDialog from "../../../components/continue-quote-dialog";
import NoDotWarningModal from "../../../components/no-dot-warning-modal";
import QualificationForm, {
  Fields as QualificationFormFields,
  QualificationFormProps,
  useFormikConfig as useQualificationFormikConfig,
} from "../../../components/qualification-form";
import QualifyWelcomeBackCard from "../../../components/qualify-welcome-back-card";
import { UserLoginType, useUserLoginType } from "../../../data/authentication/useUserLoginType";
import {
  useCreateApplication,
  useEditApplication,
  useGetApplication,
  useSendResumeApplicationEmail,
} from "../../../data/enrollment";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 0rem 0.75rem 0rem",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "1.5rem 0rem 0.75rem 0rem",
    },
  },
  pageTitleContainer: {
    padding: "0rem 1rem 0rem 1rem",
    display: "flex",
    maxWidth: "500px",
  },
  formWidth: {
    maxWidth: "500px",
  },
  warning: {
    display: "flex",
    alignItems: "center",
    paddingBottom: ".25rem",
  },
  buttonContainer: {
    width: "100%",
  },
  button: {
    width: "100%",
    display: "flex",
    minHeight: "56px",
  },
  continueQuoteButton: {
    width: "100%",
    display: "flex",
    minHeight: "56px",
    backgroundColor: theme.palette.secondary.main,
  },
  noDotIcon: {
    height: "15px",
    width: "auto",
    marginRight: "0.25rem",
  },
  loading: {
    color: theme.palette.common.white,
  },
  requiredSubText: {
    paddingLeft: "0.2rem",
  },
  title: {
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
  },
  titleBlue: {
    fontSize: "1.5rem",
    color: theme.palette.primary.main,
    lineHeight: "1.75rem",
  },
  bulletList: {
    [theme.breakpoints.down("sm")]: {
      listStyle: "none",
      padding: "0px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      listStyle: "none",
      paddingLeft: "1rem",
    },
  },
  bulletRow: {
    padding: ".2rem 0rem",
  },
  regularText: {
    fontSize: ".9rem",
  },
  regularBlueText: {
    fontSize: ".9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  check: {
    color: theme.palette.primary.main,
    height: "1.2rem",
  },
}));

const QualifyPage: React.FC = () => {
  const location = useLocation();
  let partnerName: string | null = null;
  let campaignId: string | null = null;
  let paramHolder = "";

  if (location.state !== null || location.state !== "") {
    paramHolder = location.state as string;
    const temp = paramHolder || "";
    const dashes = temp.split("-").length - 1;
    if (dashes === 4) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      campaignId = paramHolder;
    } else {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      partnerName = paramHolder;
    }
  }

  const classes = useStyles();
  const [t] = useTranslation();
  const translationBullets = t(`enrollment.qualify-page.bullets`, {
    returnObjects: true,
  });
  const translationBulletArray = Object.keys(translationBullets);
  const [noDotModalOpen, setNoDotModalOpen] = useState<boolean>(false);
  const [continueQuoteOpen, setContinueQuoteOpen] = useState<boolean>(false);
  const { mutateAsync: getLoginType, data: loginType, isLoading: idCheckingUserStatus } = useUserLoginType();
  const {
    mutate: sendResumeApplicationEmail,
    isLoading: isSendingMagicLink,
    isSuccess: magicLinkSent,
  } = useSendResumeApplicationEmail();
  const {
    isAuthenticated,
    loginWithEmail: [{ loading: isAuthenticating }, loginWithEmail],
  } = useAuth();
  const {
    mutate: createApplication,
    isLoading: isCreatingApplication,
    isSuccess: isCreatingApplicationSuccess,
    isError: isCreatingApplicationError,
    error: createApplicationError,
  } = useCreateApplication();

  const { data: application } = useGetApplication(undefined, {
    enabled: loginType === UserLoginType.none ? isCreatingApplicationSuccess : isAuthenticated,
  });
  const {
    mutate: editApplication,
    isLoading: isEditingApplication,
    isSuccess: continueToBasicInfo,
    isError: isEditingApplicationError,
    error: editApplicationError,
  } = useEditApplication();

  const qualificationFormConfig = useQualificationFormikConfig({
    dotNum: application?.business?.dotNumber || "",
    vehicleLocation: application?.business?.garagingAddress?.state || application?.business?.address?.state || "",
    radiusOfOperation: application?.business?.radiusOfOperationInt || 0,
    powerUnitCount: application?.business?.powerUnitCount || 1,
    email: application?.business?.contactEmail || "",
  });

  // Create the bullet list to display in the modal
  const bulletList = translationBulletArray.map((bullet, index) => (
    <li key={index} className={classes.bulletRow}>
      {
        <Grid container item direction="row" xs={12}>
          <Grid container item xs={2} direction="row" justify="center">
            <Check className={classes.check} />
          </Grid>
          <Grid item xs={10}>
            <Trans i18nKey={`enrollment.qualify-page.bullets.${bullet}`}>
              <Typography variant="body1" component="span" className={classes.regularText}></Typography>
              <Typography variant="body1" component="span" className={classes.regularBlueText}></Typography>
            </Trans>
          </Grid>
        </Grid>
      }
    </li>
  ));

  useEffect(() => {
    if (isCreatingApplicationSuccess) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [isCreatingApplicationSuccess]);

  if (loginType === UserLoginType.password) {
    return <Redirect push to={routes.login.path} />;
  }

  if (continueToBasicInfo) {
    return <Redirect push to={routes.enrollment.basicInfo.path} />;
  }

  return (
    <Formik
      initialValues={{
        ...qualificationFormConfig.initialValues,
      }}
      validationSchema={qualificationFormConfig.validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={async (values: QualificationFormProps) => {
        if (isAuthenticated) {
          TagManager.dataLayer({
            dataLayer: {
              event: "step1BasicInfoEvent",
              step1BasicInfoText: "Step 1: Basic Info",
            },
          });
          editApplication(
            {
              ...application,
              business: application?.business?.garagingAddress
                ? {
                    ...application?.business,
                    garagingAddress: {
                      state: values[QualificationFormFields.vehicleLocation] as State,
                    },
                    dotNumber: values[QualificationFormFields.dotNum],
                    radiusOfOperationInt: values[QualificationFormFields.radiusOfOperation],
                    powerUnitCount: values[QualificationFormFields.powerUnitCount],
                  }
                : {
                    ...application?.business,
                    address: {
                      state: values[QualificationFormFields.vehicleLocation] as State,
                    },
                    dotNumber: values[QualificationFormFields.dotNum],
                    radiusOfOperationInt: values[QualificationFormFields.radiusOfOperation],
                    powerUnitCount: values[QualificationFormFields.powerUnitCount],
                  },
            },
            {
              onSuccess: (response) => {
                if (response.data?.qualified === false) qualifyRedirect(response.data?.ineligibleReason || "");
              },
            },
          );
          if (window.LO && window.LO.visitor) {
            window.LO?.visitor?.identify(values[QualificationFormFields.dotNum] || "", {
              email: values[QualificationFormFields.email],
              id: values[QualificationFormFields.dotNum],
            });
          }
        } else {
          const loginType = await getLoginType(values[QualificationFormFields.email]);
          switch (loginType) {
            case UserLoginType.magiclink:
              sendResumeApplicationEmail({ email: values[QualificationFormFields.email] });
              break;
            case UserLoginType.none: {
              await loginWithEmail(values[QualificationFormFields.email]);
              createApplication({
                dotNumber: values[QualificationFormFields.dotNum],
                userEmail: values[QualificationFormFields.email],
                stateOfOperation: values[QualificationFormFields.vehicleLocation] as State,
                radiusOfOperationInt: values[QualificationFormFields.radiusOfOperation],
                powerUnitCount: values[QualificationFormFields.powerUnitCount],
                applicationState: RouteNames.basicInfo,
                partnerName: partnerName as string,
                campaignId: campaignId as string,
              });
              TagManager.dataLayer({
                dataLayer: {
                  event: "doIQualifyEvent",
                  doIQualifyText: "Do I Qualify",
                  stateOfOperation: values[QualificationFormFields.vehicleLocation] as State,
                  radiusOfOperation: values[QualificationFormFields.radiusOfOperation],
                  powerUnitCount: values[QualificationFormFields.powerUnitCount],
                },
              });
              break;
            }
          }
        }
      }}
    >
      {(formik) => {
        const hasDotNumber = Boolean(formik.values[QualificationFormFields.dotNum]);
        return (
          <Grid container direction="column" alignItems="center">
            {isCreatingApplicationError && qualifyRedirect(createApplicationError?.message || "")}
            {isEditingApplicationError && qualifyRedirect(editApplicationError?.message || "")}
            <>
              <Grid container item direction="column" alignItems="center" className={classes.container}>
                <Grid
                  container
                  item
                  direction="row"
                  xs={12}
                  sm={5}
                  alignItems="center"
                  className={classes.pageTitleContainer}
                >
                  <Grid item xs={5}>
                    <Trans i18nKey={"enrollment.qualify-page.title"}>
                      <Typography variant="h2" component="span" className={classes.title}></Typography>
                      <Typography variant="h2" component="span" className={classes.titleBlue}></Typography>
                    </Trans>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid item>
                      <ul className={classes.bulletList}>{bulletList}</ul>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={8}
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  className={classes.formWidth}
                >
                  <Grid item xs={12}>
                    <QualificationForm />
                  </Grid>
                  <Grid item xs={12} className={classes.buttonContainer}>
                    {isCreatingApplicationSuccess && <Redirect push to={routes.enrollment.basicInfo.path} />}
                    {magicLinkSent ? (
                      <QualifyWelcomeBackCard />
                    ) : (
                      <Button
                        onClick={() => {
                          if (hasDotNumber) {
                            formik.handleSubmit();
                          } else {
                            setNoDotModalOpen(true);
                          }
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={
                          !formik.isValid ||
                          idCheckingUserStatus ||
                          isSendingMagicLink ||
                          isAuthenticating ||
                          isCreatingApplication
                        }
                      >
                        {idCheckingUserStatus ||
                        isSendingMagicLink ||
                        isAuthenticating ||
                        isCreatingApplication ||
                        isEditingApplication ? (
                          <CircularProgress
                            className={classes.loading}
                            aria-label={t("common.circular-progress-aria-label")}
                          />
                        ) : (
                          t("email-field.qualify-button")
                        )}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.buttonContainer}>
                    <NextButton
                      variant="contained"
                      onClick={() => setContinueQuoteOpen(true)}
                      className={classes.continueQuoteButton}
                    >
                      {t("enrollment.start-quote-page.continue-quote-button")}
                    </NextButton>
                  </Grid>
                </Grid>
              </Grid>
            </>
            <Dialog open={noDotModalOpen} onClose={() => setNoDotModalOpen(false)}>
              <NoDotWarningModal
                onClick={() => {
                  setNoDotModalOpen(false);
                  formik.handleSubmit();
                }}
              />
            </Dialog>
            <ContinueQuoteDialog
              open={continueQuoteOpen}
              onClose={() => {
                setContinueQuoteOpen(false);
              }}
            ></ContinueQuoteDialog>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default QualifyPage;
