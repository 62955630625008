/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  State,
  StateFromJSON,
  StateFromJSONTyped,
  StateToJSON,
  Violation,
  ViolationFromJSON,
  ViolationFromJSONTyped,
  ViolationToJSON,
} from "./";

/**
 *
 * @export
 * @interface Driver
 */
export interface Driver {
  /**
   *
   * @type {number}
   * @memberof Driver
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  lastName?: string | null;
  /**
   *
   * @type {Date}
   * @memberof Driver
   */
  dateOfBirth?: Date;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  cdlNumber?: string | null;
  /**
   *
   * @type {State}
   * @memberof Driver
   */
  cdlState?: State;
  /**
   *
   * @type {number}
   * @memberof Driver
   */
  applicationId?: number | null;
  /**
   *
   * @type {Array<Violation>}
   * @memberof Driver
   */
  violations?: Array<Violation> | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  violationDescription?: string | null;
}

export function DriverFromJSON(json: any): Driver {
  return DriverFromJSONTyped(json, false);
}

export function DriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): Driver {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    dateOfBirth: !exists(json, "dateOfBirth") ? undefined : new Date(json["dateOfBirth"]),
    cdlNumber: !exists(json, "cdlNumber") ? undefined : json["cdlNumber"],
    cdlState: !exists(json, "cdlState") ? undefined : StateFromJSON(json["cdlState"]),
    applicationId: !exists(json, "applicationId") ? undefined : json["applicationId"],
    violations: !exists(json, "violations")
      ? undefined
      : json["violations"] === null
      ? null
      : (json["violations"] as Array<any>).map(ViolationFromJSON),
    violationDescription: !exists(json, "violationDescription") ? undefined : json["violationDescription"],
  };
}

export function DriverToJSON(value?: Driver | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    dateOfBirth: value.dateOfBirth === undefined ? undefined : value.dateOfBirth.toISOString(),
    cdlNumber: value.cdlNumber,
    cdlState: StateToJSON(value.cdlState),
    applicationId: value.applicationId,
    violations:
      value.violations === undefined
        ? undefined
        : value.violations === null
        ? null
        : (value.violations as Array<any>).map(ViolationToJSON),
    violationDescription: value.violationDescription,
  };
}
