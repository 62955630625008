import { PlaidApi } from "@chq/enrollment-api";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

type AddPlaidAccount = {
  publicToken: string;
  metadata: PlaidLinkOnSuccessMetadata;
};

export const useAddPlaidAccount = (): UseMutationResult<void, Error, AddPlaidAccount> => {
  const plaidApi = useApi(PlaidApi);
  const mutation = useMutation<void, Error, AddPlaidAccount>(async ({ publicToken, metadata }: AddPlaidAccount) => {
    await plaidApi.apiV10PlaidExchangePublicTokenGet({ publicToken });
    await plaidApi.apiV10PlaidCreateStripeAccountGet({ accountId: metadata.accounts[0].id });
  });
  return mutation;
};
