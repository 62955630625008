import { NextButton, PreviousButton, radioValueToBoolean, YesNoValues } from "@chq/components";
import { Equipment } from "@chq/enrollment-api";
import { CircularProgress, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import TagManager from "react-gtm-module";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import AddCoverageForm, {
  AddCoverageFormProps as CoverageInfo,
  Fields,
  useFormikConfig,
} from "../../../components/add-coverage-form";
import GenericPageAlert from "../../../components/generic-page-alert";
import { useEditApplication, useGetApplication } from "../../../data/enrollment";
import { useTrailerCoverages } from "../../../data/useTrailerCoverages";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.5rem 1rem 2.25rem 1rem",
    alignItems: "center",
  },
  titleSection: {
    marginBottom: "1rem",
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  title: {
    fontSize: "1.5rem",
    marginBottom: "0.25rem",
  },
  subTitle: {
    fontSize: "1rem",
  },
  pageButtons: {
    paddingTop: "1rem",
    justifyContent: "center",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  bold: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
}));

const yesOrNo = (b: boolean | null | undefined) => {
  if (b !== undefined && b !== null) {
    return b ? YesNoValues.yes : YesNoValues.no;
  } else {
    return "";
  }
};

const CoveragePage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const trailerCoverages = useTrailerCoverages();
  const minTrailerCoverage = trailerCoverages[0];
  const { data: application } = useGetApplication(RouteNames.coverages);
  const { mutate: editApplication, isLoading: isEditingApplication, isError, isSuccess } = useEditApplication();
  const firstEquipment = (application?.coveredEquipment || [])[0];
  const formikProps = useFormikConfig({
    trailerInterchange: yesOrNo(application?.coverages?.trailerInterchangeCoverage),
    trailerCoverageLimit: application?.coverages?.trailerInterchangeCoverageLimit || undefined,
    propertyDamageBodilyInjuryLimit: application?.coverages?.propertyDamageBodilyInjuryLimit?.toString() || undefined,
    hiredAuto: yesOrNo(application?.coverages?.hiredAutoCovers),
    uninsuredMotorist: yesOrNo(application?.coverages?.umUimCoverage),
    cargoInsurance: firstEquipment ? yesOrNo(firstEquipment.cargoInsurance) : undefined,
    cargoLimit: firstEquipment ? firstEquipment.cargoInsuranceLimit?.toString() : undefined,
    cargoDeductible: firstEquipment ? firstEquipment.cargoInsuranceDeductible?.toString() : undefined,
    reeferBreakdown: firstEquipment ? yesOrNo(firstEquipment.cargoInsuranceReeferBreakdown) : undefined,
  });
  if (isSuccess) {
    return (
      <Redirect
        push
        to={
          application?.insuranceProgram?.active === false
            ? routes.enrollment.quote.inactive.path
            : routes.enrollment.quote.path
        }
      />
    );
  }

  return (
    <>
      {isError && <GenericPageAlert />}
      {!application?.qualified && qualifyRedirect(application?.ineligibleReason || "")}
      <Formik
        {...formikProps}
        enableReinitialize
        onSubmit={(values: CoverageInfo) => {
          const currentEquipment = application?.coveredEquipment as Equipment[];
          const updatedEquipment: Equipment[] = currentEquipment.map((equipment) => {
            return {
              ...equipment,
              cargoInsurance: radioValueToBoolean(values[Fields.cargoInsurance]),
              cargoInsuranceLimit: values[Fields.cargoLimit] ? parseInt(values[Fields.cargoLimit]!) : undefined,
              cargoInsuranceDeductible: values[Fields.cargoDeductible]
                ? parseInt(values[Fields.cargoDeductible]!)
                : undefined,
              cargoInsuranceReeferBreakdown: radioValueToBoolean(values[Fields.reeferBreakdown]),
            };
          });
          const trailerInterchangeCoverageLimit =
            values[Fields.trailerInterchange] === YesNoValues.yes
              ? values[Fields.trailerCoverageLimit] || minTrailerCoverage
              : undefined;
          editApplication({
            ...application,
            coveredEquipment: updatedEquipment,
            coverages: {
              ...application?.coverages,
              trailerInterchangeCoverage: values[Fields.trailerInterchange] === YesNoValues.yes ? true : false,
              propertyDamageBodilyInjuryLimit: values[Fields.propertyDamageBodilyInjuryLimit]
                ? parseInt(values[Fields.propertyDamageBodilyInjuryLimit]!)
                : undefined,
              trailerInterchangeCoverageLimit,
              hiredAutoCovers: values[Fields.hiredAuto] === YesNoValues.yes ? true : false,
              umUimCoverage: values[Fields.uninsuredMotorist] === YesNoValues.yes ? true : false,
            },
          });
          TagManager.dataLayer({
            dataLayer: {
              event: "getYourQuoteEvent",
              getYourQuoteText: "Get Your Quote",
            },
          });
        }}
      >
        {(formik) => (
          <Grid container direction="column" className={classes.container}>
            <Grid container item direction="column" className={classes.titleSection}>
              <Grid item>
                <Typography variant="h2" className={classes.title}>
                  {t("coverage-form.title")}
                </Typography>
              </Grid>
              <Grid item>
                <Trans i18nKey={"coverage-form.subtitle"} className={classes.subTitle}>
                  <Typography className={classes.subTitle}></Typography>
                  <Link href={"tel:866-971-5464"} className={classes.bold}>
                    {t("common.client-succes-phone-number")}
                  </Link>
                </Trans>
              </Grid>
            </Grid>
            <Grid container item direction="column" spacing={2} xs={12} sm={10}>
              <Grid item xs={12}>
                <AddCoverageForm />
              </Grid>
              <Grid container item direction="row" className={classes.pageButtons} spacing={2} xs={12}>
                <Grid item xs={3} sm={2}>
                  <PreviousButton
                    variant="outlined"
                    color="primary"
                    fullWidth
                    className={classes.previousButton}
                    onClick={() => history.push(routes.enrollment.drivers.path)}
                    aria-label={t("coverage-form.previous-button-label")}
                  ></PreviousButton>
                </Grid>
                <Grid item xs={9} sm={6}>
                  <NextButton
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.isValid || isEditingApplication}
                  >
                    {isEditingApplication ? (
                      <CircularProgress
                        color="inherit"
                        size="2rem"
                        aria-label={t("common.circular-progress-aria-label")}
                      />
                    ) : (
                      t("coverage-form.next-button")
                    )}
                  </NextButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default CoveragePage;
