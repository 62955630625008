/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TermsAndConditionsLinks
 */
export interface TermsAndConditionsLinks {
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  termsOfUseLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  privacyPolicyLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  umUimTermsLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  fraudStatementLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  rrgAgreementLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  speedGaugeTermsLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  cargoPolicyLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  cargoPolicyWatermarkedLink?: string | null;
}

export function TermsAndConditionsLinksFromJSON(json: any): TermsAndConditionsLinks {
  return TermsAndConditionsLinksFromJSONTyped(json, false);
}

export function TermsAndConditionsLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsAndConditionsLinks {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    termsOfUseLink: !exists(json, "termsOfUseLink") ? undefined : json["termsOfUseLink"],
    privacyPolicyLink: !exists(json, "privacyPolicyLink") ? undefined : json["privacyPolicyLink"],
    umUimTermsLink: !exists(json, "umUimTermsLink") ? undefined : json["umUimTermsLink"],
    fraudStatementLink: !exists(json, "fraudStatementLink") ? undefined : json["fraudStatementLink"],
    rrgAgreementLink: !exists(json, "rrgAgreementLink") ? undefined : json["rrgAgreementLink"],
    speedGaugeTermsLink: !exists(json, "speedGaugeTermsLink") ? undefined : json["speedGaugeTermsLink"],
    cargoPolicyLink: !exists(json, "cargoPolicyLink") ? undefined : json["cargoPolicyLink"],
    cargoPolicyWatermarkedLink: !exists(json, "cargoPolicyWatermarkedLink")
      ? undefined
      : json["cargoPolicyWatermarkedLink"],
  };
}

export function TermsAndConditionsLinksToJSON(value?: TermsAndConditionsLinks | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    termsOfUseLink: value.termsOfUseLink,
    privacyPolicyLink: value.privacyPolicyLink,
    umUimTermsLink: value.umUimTermsLink,
    fraudStatementLink: value.fraudStatementLink,
    rrgAgreementLink: value.rrgAgreementLink,
    speedGaugeTermsLink: value.speedGaugeTermsLink,
    cargoPolicyLink: value.cargoPolicyLink,
    cargoPolicyWatermarkedLink: value.cargoPolicyWatermarkedLink,
  };
}
