import { ApplicationReview, Equipment, EquipmentType } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router";
import CompanyDetailsCard from "../../../components/company-details-card";
import CoverageBeginsCard from "../../../components/coverage-begins-card";
import DriversCountCard from "../../../components/drivers-count-card";
import ReviewPaymentMethodCard from "../../../components/review-payment-method-card";
import ReviewYourQuoteCard from "../../../components/review-your-quote-card";
import TrailersPowerUnitsCard from "../../../components/trailers-power-units-card";
import { useGetFactoringApplication } from "../../../data/enrollment";
import { getQuoteByCycle } from "../../../utils/get-quote-items";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.25rem 0.625rem 0rem",
    alignItems: "flex-start",
    margin: "auto",
  },
  titleItem: {
    margin: "auto",
  },
  button: {
    margin: "auto",
  },
  submitButton: {
    minHeight: "56px",
  },
  submitButtonContainer: {
    padding: "1.125rem 0rem",
  },
  downloadButton: {
    ...theme.typography.h4,
    margin: "1rem 0 0",
    minHeight: "40px",
    backgroundColor: theme.palette.secondary.light,
  },
  icon: {
    width: "auto",
    height: "30px",
  },
  buttonContainer: {
    padding: "0.625rem 0rem 0.375rem",
    margin: ".25rem auto",
  },
  leftButton: {
    marginRight: "0.625rem",
  },
  textCenter: {
    textAlign: "center",
  },
  topText: {
    marginBottom: "1.125rem",
  },
  bottomSection: {
    margin: "auto",
  },
}));

export type Props = {
  bound: boolean;
  topComponent: React.ReactNode;
  bottomComponent?: React.ReactNode;
  containerClass?: string;
  applicationReview?: ApplicationReview;
  isLoadingApplication?: boolean;
};

const QuoteSavedPageContent: React.FC<Props> = ({
  bound,
  topComponent,
  bottomComponent,
  containerClass,
  applicationReview,
  isLoadingApplication,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { data: factoringApplication, isFetching: isLoadingFactoringApplication } = useGetFactoringApplication();

  const powerUnits = (applicationReview?.application?.coveredEquipment || []).filter(
    (equipment: Equipment) => equipment.equipmentType === EquipmentType.PowerUnit,
  );
  const trailers = (applicationReview?.application?.coveredEquipment || []).filter(
    (equipment: Equipment) => equipment.equipmentType === EquipmentType.Trailer,
  );

  const quote = getQuoteByCycle(
    applicationReview?.application?.currentQuote?.rates,
    applicationReview?.application?.billingCycle,
  );

  return (
    <Grid
      item
      container
      className={classNames(classes.container, containerClass)}
      justify="center"
      xs={12}
      sm={10}
      lg={8}
      spacing={1}
    >
      {!applicationReview?.application?.qualified &&
        qualifyRedirect(applicationReview?.application?.ineligibleReason || "")}
      <Grid item container xs={12} sm={8} justify="center" className={classes.topText}>
        {topComponent}
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1}>
        <Grid item xs={12}>
          <ReviewYourQuoteCard
            dotNumber={applicationReview?.application?.business?.dotNumber}
            quotePayment={quote.quotePayment}
            downPayment={quote.downPayment}
            billingCycle={applicationReview?.application?.billingCycle}
            onEdit={() => history.push(routes.enrollment.quote.path)}
            onAddDotNumber={() => history.push(routes.enrollment.qualify.path)}
          />
        </Grid>
        <Grid item xs={12}>
          <CoverageBeginsCard
            effectiveDate={applicationReview?.application?.desiredEffectiveDate}
            editable={!bound}
            onEdit={() => history.push(routes.enrollment.review.path)}
          />
        </Grid>
        <Grid item xs={12}>
          <ReviewPaymentMethodCard
            bankInfo={applicationReview?.bankAccountInfo}
            factoringStatus={applicationReview?.application?.factoringApprovalStatus}
            isLoading={isLoadingApplication || isLoadingFactoringApplication}
            isFactoring={Boolean(factoringApplication)}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1}>
        <Grid item xs={12}>
          <CompanyDetailsCard
            dotNumber={applicationReview?.application?.business?.dotNumber}
            legalEntity={applicationReview?.application?.business?.legalEntity}
            einNumber={applicationReview?.application?.business?.einNumber}
            radiusOfOperation={applicationReview?.application?.business?.radiusOfOperation}
          />
        </Grid>
        <Grid item xs={12}>
          <TrailersPowerUnitsCard equipment={powerUnits} equipmentType={EquipmentType.PowerUnit} />
        </Grid>
        <Grid item xs={12}>
          <TrailersPowerUnitsCard equipment={trailers} equipmentType={EquipmentType.Trailer} />
        </Grid>
        <Grid item xs={12}>
          <DriversCountCard drivers={applicationReview?.application?.coveredIndividuals || []} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} className={classes.bottomSection}>
        {bottomComponent}
      </Grid>
    </Grid>
  );
};

export default QuoteSavedPageContent;
