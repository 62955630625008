import { TypographyProps } from "@material-ui/core";

const variantObject = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "h6",
  subtitle2: "h6",
  body1: "p",
  body2: "p",
};

const variantToComponentTypes = new Map(Object.entries(variantObject));

export const variantToComponent = (variant: TypographyProps["variant"]) => {
  return variantToComponentTypes.get(variant!);
};
