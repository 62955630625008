import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useHistory } from "react-router";
import thumbsUpAnimation from "../../../lotties/thumbs-up.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: thumbsUpAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "auto",
  },
  thumbIcon: {
    marginBottom: "2rem",
  },
  thanksText: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  button: {
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
  },
}));

const SubmittedChangesPage: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid xs={10} sm={10} md={8} lg={6} item container justify="center" className={classes.root}>
      <Grid item xs={12} className={classes.thumbIcon}>
        <Lottie options={defaultOptions} height={125} width={125} />
      </Grid>
      <Grid item xs={12} sm={12} className={classes.thanksText}>
        <Typography variant="h3" component="h3">
          {t("policy-management-page.submitted-changes-page.thank-you")}
        </Typography>
        <Typography variant="h3" component="h3">
          {t("policy-management-page.submitted-changes-page.contact")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          className={classes.button}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => history.push("/policy-management/")}
        >
          <Typography variant="h2" component="h4">
            {t("policy-management-page.submitted-changes-page.ok")}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default SubmittedChangesPage;
