import { EditableDeletableCard } from "@chq/components";
import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import EquipmentInfo, { Props as EquipmentInfoProps } from "./equipment-info";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: "1rem 0.5rem 0rem 0.5rem",
    },
  }),
);

const EquipmentCard: React.FC<EquipmentInfoProps> = ({ equipment, ...Props }) => {
  const classes = useStyles();

  return (
    <EditableDeletableCard
      title={equipment.vehicleNumber!}
      variant="default"
      className={classes.container}
      border={false}
      elevation={1}
    >
      <EquipmentInfo {...Props} equipment={equipment} />
    </EditableDeletableCard>
  );
};

export default EquipmentCard;
