import { EditableDeletableCard } from "@chq/components";
import { Button, fade, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useHistory } from "react-router";
import { useGetApplication, useSendInactiveQuoteEmail } from "../../../data/enrollment";
import inactiveAnimation from "../../../lotties/truck-driving-lottie.json";
import { routes } from "../../../ui/routes";
import { RouteNames } from "../../../utils/route-names";

const useStyles = makeStyles((theme: Theme) => ({
  contentWidth: {
    justifyContent: "center",
  },
  cardContainer: {
    marginTop: "1.5rem",
    maxWidth: "40rem",
    justifyContent: "center",
    padding: "0 0.5rem 1rem 0.5rem",
  },
  mainContainer: {
    borderRadius: "0.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    width: "100%",
  },
  contactContainer: {
    padding: "1rem 1.5rem",
    borderRadius: "0.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    width: "100%",
  },
  containerClass: {
    padding: "0",
  },
  title: {
    paddingBottom: "1.5rem",
    color: theme.palette.primary.main,
  },
  lottieContainer: {
    padding: "1rem 0 1rem 0",
  },
  truckLottie: {
    width: "100%",
    paddingBottom: "1rem",
    alignContent: "center",
  },
  textContainer: {
    padding: "0rem 1.5rem 1.3rem 1.5rem",
  },
  text: {
    lineHeight: "1.8rem",
  },
  backButton: {
    backgroundColor: theme.palette.common.white,
    minWidth: "50%",
    minHeight: "56px",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  contactCard: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  phoneIcon: {
    fontWeight: "bold",
    transform: "rotate(15deg)",
  },
  contactText: {
    fontWeight: 700,
    fontSize: "0.9rem",
    "& .MuiTypography-body2": {
      fontWeight: 700,
      fontSize: "0.9rem",
    },
  },
}));

const truckOptions = {
  loop: true,
  autoplay: true,
  animationData: inactiveAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const InactiveQuotePage: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { data: application, isLoading: isLoadingApplication } = useGetApplication(RouteNames.quoteOverview);
  const { mutate: sendInactiveQuoteEmail } = useSendInactiveQuoteEmail();

  useEffect(() => {
    if (!isLoadingApplication && application?.id) {
      sendInactiveQuoteEmail({ businessId: application!.businessId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className={classes.contentWidth}>
      <Grid container item className={classes.cardContainer}>
        <Grid container item direction="column" xs={12} spacing={2}>
          <Grid container item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <EditableDeletableCard
                  variant="default"
                  border={false}
                  className={classes.mainContainer}
                  containerClass={classes.containerClass}
                >
                  <Grid container direction="column" className={classes.lottieContainer}>
                    <Grid item xs={12} className={classes.truckLottie}>
                      <Lottie options={truckOptions} width={"100%"} height={"100%"} />
                    </Grid>
                  </Grid>
                  <Grid container direction="column" className={classes.textContainer}>
                    <Grid item xs={12}>
                      <Typography variant="h1" className={classes.title}>
                        {t("enrollment.inactive-quote-page.title")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography variant="body1" component="p" className={classes.text}>
                        {t("enrollment.inactive-quote-page.body")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.backButton}
                        onClick={() => {
                          history.push(routes.enrollment.coverage.path);
                        }}
                      >
                        {t("enrollment.inactive-quote-page.back-button")}
                      </Button>
                    </Grid>
                  </Grid>
                </EditableDeletableCard>
              </Grid>
              <Grid item xs={12}>
                <EditableDeletableCard
                  variant="default"
                  border={false}
                  className={classes.contactContainer}
                  containerClass={classes.containerClass}
                >
                  <Grid container direction="row" spacing={2} alignItems="center" className={classes.contactCard}>
                    <Grid item>
                      <Link href={"tel:866-621-4145"} aria-label={t("common.phone-link-aria-label")}>
                        <Phone
                          color="primary"
                          className={classes.phoneIcon}
                          aria-label={t("common.phone-link-aria-label")}
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={10} sm={11} className={classes.contactText}>
                      <Trans i18nKey="enrollment.inactive-quote-page.contact">
                        <Typography variant="body2"></Typography>
                        <Link variant="body2" href={"tel:866-621-4145"}>
                          {t("common.phone-link-aria-label")}
                        </Link>
                        <Link variant="body2" href={"mailto:info@CarrierHQ.com"}>
                          {t("common.email-link-aria-label")}
                        </Link>
                      </Trans>
                    </Grid>
                  </Grid>
                </EditableDeletableCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InactiveQuotePage;
