/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FactoringDocumentType {
  ArticlesOfIncorporation = "ArticlesOfIncorporation",
  W9 = "W9",
  DriversLicense = "DriversLicense",
  Other = "Other",
}

export function FactoringDocumentTypeFromJSON(json: any): FactoringDocumentType {
  return FactoringDocumentTypeFromJSONTyped(json, false);
}

export function FactoringDocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FactoringDocumentType {
  return json as FactoringDocumentType;
}

export function FactoringDocumentTypeToJSON(value?: FactoringDocumentType | null): any {
  return value as any;
}
