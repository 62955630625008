/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum OperatingAuthorityStatus {
  AuthorizeForHire = "Authorize For Hire",
  ApplicationSubmitted = "Application Submitted",
  Pending = "Pending",
  NotAuthorized = "Not Authorized",
  Inactive = "Inactive",
  Unknown = "Unknown",
}

export function OperatingAuthorityStatusFromJSON(json: any): OperatingAuthorityStatus {
  return OperatingAuthorityStatusFromJSONTyped(json, false);
}

export function OperatingAuthorityStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OperatingAuthorityStatus {
  return json as OperatingAuthorityStatus;
}

export function OperatingAuthorityStatusToJSON(value?: OperatingAuthorityStatus | null): any {
  return value as any;
}
