import { useTheme } from "@chq/styles";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const HeaderBar: React.FC<SvgIconProps> = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props} width="4" height="36" viewBox="0 0 4 36">
      <rect x="0.994446" width="2.01112" height="36" fill={theme.palette.grey[400]} />
    </SvgIcon>
  );
};

export default HeaderBar;
