import { Checkbox, FailIcon, TextInput } from "@chq/components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { FormikConfig, useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    background: theme.palette.secondary.light,
    borderRadius: "0px",
    boxShadow: "0px 0px 0px 0px",
    color: theme.palette.secondary.main,
    textTransform: "uppercase",
    lineHeight: "16px",
    fontSize: "0.75rem",
    width: "100%",
  },
  icons: {
    height: "2rem",
    width: "2rem",
  },
  dialogTitle: {
    paddingBottom: "0rem",
    "& .MuiTypography-h6": {
      fontWeight: 700,
    },
  },
  label: {
    marginTop: "-0.5rem",
  },
  comments: {
    marginTop: "3rem",
    "& .MuiInputBase-root": {
      marginTop: "2rem",
    },
  },
  button: {
    minWidth: "6rem",
    minHeight: "3.5rem",
    width: "100%",
  },
  divider: {
    paddingTop: "1.25rem",
    borderBottom: `solid .125rem ${theme.palette.secondary.light}`,
  },
  container: {
    paddingBottom: "1rem",
  },
  title: {
    "& .MuiTypography-h6": {
      fontWeight: 700,
    },
  },
  content: {
    overflowY: "hidden",
  },
}));

export enum Fields {
  yesCancel = "yes-cancel",
  comments = "comments",
}

export type FormProps = {
  [Fields.yesCancel]?: boolean;
  [Fields.comments]: string;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();
  return yup.object({
    [Fields.yesCancel]: yup.boolean().required().oneOf([true]),
    [Fields.comments]: yup.string().required(t("policy-management-page.cancel-policy.comments.help-text")),
  });
};

type Props = {
  yesCancel?: boolean;
  comments?: string;
  cancelled?: boolean;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
  onClose?: () => void;
};

const CancelPolicyButton: React.FC<Props> = ({
  onSubmit,
  onClose,
  cancelled = false,
  yesCancel = false,
  comments = "",
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const validationSchema = useValidationSchema();

  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.yesCancel]: yesCancel,
      [Fields.comments]: comments,
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
    validateOnMount: true,
    enableReinitialize: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Button variant="contained" className={classes.cancelButton} onClick={() => setOpen(true)}>
        <FailIcon color="error" className={classes.icons} />
        {t("policy-management-page.cancel-policy.button")}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby={t("policy-management-page.cancel-policy.dialog-aria-label")}
      >
        {!cancelled && (
          <>
            <DialogTitle id="cancel-my-policy-title" className={classes.dialogTitle}>
              {t("policy-management-page.cancel-policy.title")}
            </DialogTitle>
            <DialogContent className={classes.content}>
              <Checkbox
                id={Fields.yesCancel}
                name={Fields.yesCancel}
                required
                value={formik.values[Fields.yesCancel]}
                onChange={formik.handleChange}
                label={t("policy-management-page.cancel-policy.yes-cancel.label")}
              />
              <TextInput
                id={Fields.comments}
                name={Fields.comments}
                value={formik.values[Fields.comments]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.comments] && Boolean(formik.errors[Fields.comments])}
                helperText={formik.touched[Fields.comments] && formik.errors[Fields.comments]}
                required
                multiline
                InputLabelProps={{ className: classes.label }}
                label={t("policy-management-page.cancel-policy.comments.label")}
                fullWidth
                className={classes.comments}
              />
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    onClick={() => formik.handleSubmit()}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={!formik.isValid}
                  >
                    {t("policy-management-page.cancel-policy.submit-cancellation")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.divider}></div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      setOpen(false);
                      onClose && onClose();
                    }}
                    color="primary"
                    className={classes.button}
                  >
                    {t("policy-management-page.cancel-policy.not-cancelling")}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
        {cancelled && (
          <>
            <DialogTitle id="cancel-my-policy-title" className={classes.dialogTitle}>
              {t("policy-management-page.cancel-policy.cancelled-title")}
            </DialogTitle>
            <DialogContent>
              <Typography>{t("policy-management-page.cancel-policy.sorry-to-see")}</Typography>
            </DialogContent>
            <DialogActions>
              <Grid container className={classes.container}>
                <Grid item xs={12}>
                  <Button onClick={() => setOpen(false)} variant="contained" color="primary" className={classes.button}>
                    {t("policy-management-page.cancel-policy.ok")}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </form>
  );
};

export default CancelPolicyButton;
