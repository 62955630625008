import { StyledComponentProps, Theme, Tooltip, TooltipProps, withStyles } from "@material-ui/core";

export type DarkTooltipProps = StyledComponentProps & TooltipProps;

const DarkTooltip: React.ComponentType<DarkTooltipProps> = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[1],
    maxWidth: "16rem",
  },
  tooltipPlacementBottom: {
    margin: 0,
  },
}))(Tooltip);

export default DarkTooltip;
