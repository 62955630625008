import { Button, Checkbox, Divider, Grid, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  plaidError: {
    backgroundColor: fade(theme.palette.error.main, 0.1),
  },
  unableToConnectText: {
    textAlign: "center",
    marginTop: "0.625rem",
  },
  connectManuallyButtonContainer: {
    alignSelf: "center",
    marginTop: "0.25rem",
    marginBottom: "0.625rem",
  },
  connectManuallyButton: {
    ...theme.typography.h3,
    color: theme.palette.primary.main,
    minHeight: "56px",
    backgroundColor: theme.palette.common.white,
  },
  connectManuallyContainer: {
    margin: "0.75rem 0rem",
  },
  errorContainer: {
    marginBottom: "1rem",
  },
  errorIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  errorText: {
    paddingLeft: "0.25rem",
  },
  alternateText: {
    marginTop: "0.625rem",
    textTransform: "uppercase",
    textAlign: "center",
  },
  alternateLink: {
    display: "flex",
    justifyContent: "center",
    whiteSpace: "pre-wrap",
  },
  connectManuallyTextContainer: {
    display: "flex",
    justifyContent: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  link: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

type Props = {
  onAddAccountDialogOpen: () => void;
  onComplete: () => void;
  onNotComplete: () => void;
};

const ConnectBankManuallyCard: React.FC<Props> = ({ onAddAccountDialogOpen, onComplete, onNotComplete }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [connectLater, setConnectLater] = useState(false);

  return (
    <Grid container direction="column">
      <Grid item className={classes.connectManuallyContainer}>
        <Paper elevation={0} className={classes.plaidError}>
          <Grid container justify="center" direction="column">
            <Grid item>
              <Typography variant="body1" className={classes.unableToConnectText}>
                {t("finish-application.payment-method-form.plaid-failure.unable-to-connect")}
              </Typography>
            </Grid>
            <Grid item className={classes.connectManuallyButtonContainer}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.connectManuallyButton}
                disabled={connectLater}
                onClick={() => onAddAccountDialogOpen()}
              >
                <strong>{t("finish-application.payment-method-form.plaid-failure.connect-manually")}</strong>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid container item direction="column">
        <Grid item container direction="row" alignItems="center">
          <Grid item xs={1}>
            <Checkbox
              inputProps={{
                "aria-label": t("finish-application.payment-method-form.plaid-failure.checkbox-text"),
              }}
              checked={connectLater}
              onChange={(event) => {
                setConnectLater(event.target.checked);
                event.target.checked ? onComplete() : onNotComplete();
              }}
            />
          </Grid>
          <Grid item xs={11} className={classes.connectManuallyTextContainer}>
            <Typography variant="h3">
              <strong>{t("finish-application.payment-method-form.plaid-failure.checkbox-text")}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" alignItems="center" className={classes.errorContainer}>
          <Grid item xs={2} className={classes.errorIcon}>
            <ErrorOutlineOutlinedIcon color="error" />
          </Grid>
          <Grid item xs={10} className={classes.errorText}>
            <Typography color="error">
              <strong>{t("finish-application.payment-method-form.plaid-failure.checkbox-disclaimer")}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <Typography variant="h4" className={classes.alternateText}>
          {t("finish-application.payment-method-form.plaid-failure.alternate-text")}
        </Typography>
      </Grid>
      <Grid item className={classes.alternateLink}>
        <Trans i18nKey={"finish-application.payment-method-form.plaid-failure.alternate-phone"}>
          <Typography variant="body1" className={classes.bold} component="span"></Typography>
          <Link href={"tel:866-621-4145"} className={classes.link}>
            {t("finish-application.payment-method-form.plaid-failure.alternate-phone")}
          </Link>
        </Trans>
      </Grid>
    </Grid>
  );
};

export default ConnectBankManuallyCard;
