/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import TextInput, { TextInputProps } from "./text-input";

export type NumericInputProps = NumberFormatProps &
  Omit<TextInputProps, "type" | "select" | "SelectProps" | "value" | "defaultValue"> & {
    value: number | string | undefined | null;
  };

function NumberPickerFormat(props: any) {
  const { inputRef, onChange, id, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      id={id}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}

const NumericInput: React.FC<NumericInputProps> = ({
  thousandSeparator,
  decimalSeparator,
  thousandsGroupStyle,
  decimalScale,
  fixedDecimalScale,
  displayType,
  prefix,
  suffix,
  format,
  removeFormatting,
  mask,
  isNumericString,
  customInput,
  allowNegative,
  allowEmptyFormatting,
  allowLeadingZeros,
  isAllowed,
  renderText,
  allowedDecimalSeparators,
  percentage,
  ...TextInputProps
}) => {
  return (
    <TextInput
      {...TextInputProps}
      InputProps={{
        ...TextInputProps.InputProps,
        inputComponent: NumberPickerFormat,
        inputProps: {
          ...TextInputProps.inputProps,
          thousandSeparator,
          decimalSeparator,
          thousandsGroupStyle,
          decimalScale,
          type: "tel",
          fixedDecimalScale,
          displayType,
          prefix,
          suffix,
          format,
          removeFormatting,
          mask,
          isNumericString,
          customInput,
          allowNegative,
          allowEmptyFormatting,
          allowLeadingZeros,
          isAllowed,
          renderText,
          allowedDecimalSeparators,
          percentage,
        },
      }}
    />
  );
};

export default NumericInput;
