import { Button, ButtonProps, CircularProgress, makeStyles, Theme } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "inline",
  },
  saveButtonActive: {
    padding: "1.125rem 0rem",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.grey[400],
    },
  },
  saveButtonDisabled: {
    padding: "1.125rem 0rem",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    border: "2px solid",
  },
  saveIconFormikInvalid: {
    height: "1.125rem",
    paddingRight: ".5rem",
    color: theme.palette.grey[400],
  },
  saveIconFormikValid: {
    height: "1.125rem",
    paddingRight: ".5rem",
    color: theme.palette.primary.light,
  },
  buttonTextFormikValid: {
    color: theme.palette.primary.light,
  },
  buttonTextFormikInvalid: {
    color: theme.palette.grey[400],
  },
}));

type Props = ButtonProps & {
  label?: string;
  formikValid?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
};

const SaveButton: React.FC<Props> = ({ label, formikValid, isLoading, onClick, ...buttonProp }) => {
  const classes = useStyles();
  return (
    <Button
      {...buttonProp}
      className={formikValid ? classes.saveButtonActive : classes.saveButtonDisabled}
      onClick={onClick}
    >
      {isLoading ? (
        <CircularProgress color="primary" size="2rem" />
      ) : (
        <SaveIcon className={formikValid ? classes.saveIconFormikValid : classes.saveIconFormikInvalid} />
      )}
      <div className={formikValid ? classes.buttonTextFormikValid : classes.buttonTextFormikInvalid}>{label}</div>
    </Button>
  );
};

export default SaveButton;
