import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  titleContain: {
    display: "flex",
    flexDirection: "column",
  },
}));

const EcertModalContent: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <>
      <div className={classes.titleContain}>
        <Typography variant="h2" align="left">
          {t("policy-management-page.ecert-modal.title")}
        </Typography>
        <br />
        <Typography variant="h4" align="left" component="h3">
          {t("policy-management-page.ecert-modal.certificate-of-insurance-issuance")}
        </Typography>
      </div>

      <ol>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-one")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-two")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-three")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-four")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-five")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-six")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-seven")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-eight")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-nine")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-ten")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-eleven")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.issuance-bullet-twelve")}</Typography>
        </li>
      </ol>
      <Typography variant="h4" align="left" component="h3">
        {t("policy-management-page.ecert-modal.view-list-certificate-of-insurance")}
      </Typography>
      <ol>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.list-bullet-one")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.list-bullet-two")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.list-bullet-three")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.list-bullet-four")}</Typography>
        </li>
        <li>
          <Typography variant="body1">{t("policy-management-page.ecert-modal.list-bullet-five")}</Typography>
        </li>
      </ol>
    </>
  );
};

export default EcertModalContent;
