import { Application, ApplicationApi } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const useSubmitApplication = (): UseMutationResult<Application, Error, Application> => {
  const applicationApi = useApi(ApplicationApi);
  const { updateApplication } = useCachedApplication();
  const mutation = useMutation<Application, Error, Application>(async (request: Application) => {
    const response = await applicationApi.apiV10ApplicationSubmitApplicationPut({
      application: request,
    });
    if (response.error) {
      throw new Error(response.error.message || "Failed to submit applicaiton");
    }
    if (response.data) {
      updateApplication(response.data);
    }
    return response.data!;
  });
  return mutation;
};
