/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  FactoringApplicationApiResponse,
  FactoringApplicationApiResponseFromJSON,
  FactoringApplicationApiResponseToJSON,
  FactoringDocumentType,
  FactoringDocumentTypeFromJSON,
  FactoringDocumentTypeToJSON,
} from "../models";

export interface FactoringApiV10FactoringDocumentAddDocumentPostRequest {
  applicationId?: number;
  file?: Blob;
  factoringDocumentType?: FactoringDocumentType;
}

export interface FactoringApiV10FactoringDocumentDeleteDocumentDeleteRequest {
  documentId?: number;
  factoringDocumentType?: FactoringDocumentType;
  applicationId?: number;
}

/**
 *
 */
export class FactoringDocumentApi extends runtime.BaseAPI {
  /**
   * Adds a document to the Factoring Application
   */
  async factoringApiV10FactoringDocumentAddDocumentPostRaw(
    requestParameters: FactoringApiV10FactoringDocumentAddDocumentPostRequest,
  ): Promise<runtime.ApiResponse<FactoringApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("File", requestParameters.file as any);
    }

    if (requestParameters.factoringDocumentType !== undefined) {
      formParams.append("FactoringDocumentType", requestParameters.factoringDocumentType as any);
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringDocument/AddDocument`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FactoringApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Adds a document to the Factoring Application
   */
  async factoringApiV10FactoringDocumentAddDocumentPost(
    requestParameters: FactoringApiV10FactoringDocumentAddDocumentPostRequest,
  ): Promise<FactoringApplicationApiResponse> {
    const response = await this.factoringApiV10FactoringDocumentAddDocumentPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a document and removes it from the Application
   */
  async factoringApiV10FactoringDocumentDeleteDocumentDeleteRaw(
    requestParameters: FactoringApiV10FactoringDocumentDeleteDocumentDeleteRequest,
  ): Promise<runtime.ApiResponse<FactoringApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.documentId !== undefined) {
      queryParameters["documentId"] = requestParameters.documentId;
    }

    if (requestParameters.factoringDocumentType !== undefined) {
      queryParameters["factoringDocumentType"] = requestParameters.factoringDocumentType;
    }

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringDocument/DeleteDocument`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FactoringApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Deletes a document and removes it from the Application
   */
  async factoringApiV10FactoringDocumentDeleteDocumentDelete(
    requestParameters: FactoringApiV10FactoringDocumentDeleteDocumentDeleteRequest,
  ): Promise<FactoringApplicationApiResponse> {
    const response = await this.factoringApiV10FactoringDocumentDeleteDocumentDeleteRaw(requestParameters);
    return await response.value();
  }
}
