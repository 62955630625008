import { Header } from "@chq/components";
import { theme } from "@chq/styles";
import { Grid, makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import qs from "qs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import ResetPasswordConfirmation from "../../../components/reset-password-confirmation";
import ResetPasswordForm, {
  Fields as ResetPasswordFields,
  useFormikConfig,
} from "../../../components/reset-password-form";
import { useTokenPasswordReset } from "../../../data/authentication";
import loginBackgroundDesktop from "../../../images/login-road-background-desktop.png";
import loginBackground from "../../../images/login-road-background.png";
import Footer from "../../components/footer";
import { routes } from "../../routes";

const useStyles = makeStyles(() => ({
  container: {
    minHeight: "100vh",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: "cover",
    overflowX: "hidden",
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${loginBackgroundDesktop})`,
      backgroundSize: "cover",
    },
  },
  form: {
    padding: "0rem 1rem 0rem 1rem",
  },
}));

const fixToken = (token: string) => {
  const fixedToken = token.split(" ").join("+");
  return fixedToken;
};

const ResetPasswordPage: React.FC = () => {
  const classes = useStyles();
  const formikProps = useFormikConfig();
  const history = useHistory();
  const { search } = useLocation();
  const [t] = useTranslation();
  const { mutate: tokenPasswordReset, isSuccess: resetSuccess } = useTokenPasswordReset();
  const params = qs.parse(search.slice(1));
  if (params.token) {
    params.token = fixToken(params.token as string);
  }
  //add the query string binding for if userid is added to the query string
  if (params.userId) {
    params.userId = fixToken(params.userId as string);
  }
  return (
    <Grid container className={classes.container}>
      <Header chqTitleAccess={t("common.chq-logo")} chqAriaLabel={t("common.chq-logo")} />{" "}
      {!resetSuccess && (
        <Grid item xs={12} className={classes.form}>
          <Formik
            {...formikProps}
            onSubmit={(values) =>
              tokenPasswordReset({
                userId: params.userId as string,
                token: params.token as string,
                password: values[ResetPasswordFields.reEnterPassword],
              })
            }
          >
            <ResetPasswordForm />
          </Formik>
        </Grid>
      )}
      {resetSuccess && (
        <Grid item xs={12} className={classes.form}>
          <ResetPasswordConfirmation onClick={() => history.push(routes.login.path)} />
        </Grid>
      )}
      <Footer />
    </Grid>
  );
};

export default ResetPasswordPage;
