import React from "react";
import { useTranslation } from "react-i18next";
import DNQPageContent from "../../../components/dnq-page-content";

const DNQOverallPage: React.FC = () => {
  const [t] = useTranslation();

  return (
    <DNQPageContent
      message={t("enrollment.pending-overall-qualify-page.message")}
      helpText={t("enrollment.pending-overall-qualify-page.helpText")}
    />
  );
};

export default DNQOverallPage;
