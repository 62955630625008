/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Business,
  BusinessFromJSON,
  BusinessFromJSONTyped,
  BusinessToJSON,
  Coverages,
  CoveragesFromJSON,
  CoveragesFromJSONTyped,
  CoveragesToJSON,
  Document,
  DocumentFromJSON,
  DocumentFromJSONTyped,
  DocumentToJSON,
  Driver,
  DriverFromJSON,
  DriverFromJSONTyped,
  DriverToJSON,
  Equipment,
  EquipmentFromJSON,
  EquipmentFromJSONTyped,
  EquipmentToJSON,
  PdfDocuments,
  PdfDocumentsFromJSON,
  PdfDocumentsFromJSONTyped,
  PdfDocumentsToJSON,
  SpeedGuageScore,
  SpeedGuageScoreFromJSON,
  SpeedGuageScoreFromJSONTyped,
  SpeedGuageScoreToJSON,
} from "./";

/**
 *
 * @export
 * @interface Policy
 */
export interface Policy {
  /**
   *
   * @type {number}
   * @memberof Policy
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  policyNumber?: string | null;
  /**
   *
   * @type {Date}
   * @memberof Policy
   */
  effectiveDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Policy
   */
  exiprationDate?: Date;
  /**
   *
   * @type {Array<SpeedGuageScore>}
   * @memberof Policy
   */
  speedGuageScores?: Array<SpeedGuageScore> | null;
  /**
   *
   * @type {Business}
   * @memberof Policy
   */
  business?: Business;
  /**
   *
   * @type {Coverages}
   * @memberof Policy
   */
  coverages?: Coverages;
  /**
   *
   * @type {PdfDocuments}
   * @memberof Policy
   */
  pdfDocuments?: PdfDocuments;
  /**
   *
   * @type {Document}
   * @memberof Policy
   */
  policyDocument?: Document;
  /**
   *
   * @type {Array<Driver>}
   * @memberof Policy
   */
  drivers?: Array<Driver> | null;
  /**
   *
   * @type {Array<Equipment>}
   * @memberof Policy
   */
  equipment?: Array<Equipment> | null;
}

export function PolicyFromJSON(json: any): Policy {
  return PolicyFromJSONTyped(json, false);
}

export function PolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Policy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    policyNumber: !exists(json, "policyNumber") ? undefined : json["policyNumber"],
    effectiveDate: !exists(json, "effectiveDate") ? undefined : new Date(json["effectiveDate"]),
    exiprationDate: !exists(json, "exiprationDate") ? undefined : new Date(json["exiprationDate"]),
    speedGuageScores: !exists(json, "speedGuageScores")
      ? undefined
      : json["speedGuageScores"] === null
      ? null
      : (json["speedGuageScores"] as Array<any>).map(SpeedGuageScoreFromJSON),
    business: !exists(json, "business") ? undefined : BusinessFromJSON(json["business"]),
    coverages: !exists(json, "coverages") ? undefined : CoveragesFromJSON(json["coverages"]),
    pdfDocuments: !exists(json, "pdfDocuments") ? undefined : PdfDocumentsFromJSON(json["pdfDocuments"]),
    policyDocument: !exists(json, "policyDocument") ? undefined : DocumentFromJSON(json["policyDocument"]),
    drivers: !exists(json, "drivers")
      ? undefined
      : json["drivers"] === null
      ? null
      : (json["drivers"] as Array<any>).map(DriverFromJSON),
    equipment: !exists(json, "equipment")
      ? undefined
      : json["equipment"] === null
      ? null
      : (json["equipment"] as Array<any>).map(EquipmentFromJSON),
  };
}

export function PolicyToJSON(value?: Policy | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    policyNumber: value.policyNumber,
    effectiveDate: value.effectiveDate === undefined ? undefined : value.effectiveDate.toISOString(),
    exiprationDate: value.exiprationDate === undefined ? undefined : value.exiprationDate.toISOString(),
    speedGuageScores:
      value.speedGuageScores === undefined
        ? undefined
        : value.speedGuageScores === null
        ? null
        : (value.speedGuageScores as Array<any>).map(SpeedGuageScoreToJSON),
    business: BusinessToJSON(value.business),
    coverages: CoveragesToJSON(value.coverages),
    pdfDocuments: PdfDocumentsToJSON(value.pdfDocuments),
    policyDocument: DocumentToJSON(value.policyDocument),
    drivers:
      value.drivers === undefined
        ? undefined
        : value.drivers === null
        ? null
        : (value.drivers as Array<any>).map(DriverToJSON),
    equipment:
      value.equipment === undefined
        ? undefined
        : value.equipment === null
        ? null
        : (value.equipment as Array<any>).map(EquipmentToJSON),
  };
}
