import { AlertType, PageAlert } from "@chq/components";
import { Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useGetApplication, useSendResumeApplicationEmail } from "../../../data/enrollment";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  helpContainer: {
    backgroundColor: theme.palette.secondary.light,
    padding: "2rem 0 2rem 0",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
    flexGrow: 1,
  },

  email: {
    alignContent: "center",
  },
  resend: {
    width: "100%",
  },
  resendEmailText: {
    textTransform: "uppercase",
  },
}));

const VerifyAccountPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const { data: application } = useGetApplication(undefined);
  const [resendLinkStatus, setResendLinkStatus] = useState<AlertType>();
  const { mutate: sendResumeApplicationEmail } = useSendResumeApplicationEmail();
  const email = application?.business?.contactEmail ? application.business.contactEmail : "test@test.com";

  if (application?.insuranceProgram?.active === false) {
    return <Redirect to={routes.enrollment.quote.inactive.path} />;
  }

  return (
    <Grid container direction="column" alignItems="center" className={classes.container}>
      {resendLinkStatus && (
        <PageAlert
          message={
            resendLinkStatus === AlertType.success
              ? t("enrollment.verify-page.resend-successful")
              : t("enrollment.verify-page.resend-unsuccessful")
          }
          alertType={resendLinkStatus}
        />
      )}
      <Grid item container spacing={5} direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h1" component="h2">
            {t("enrollment.verify-page.title")}
          </Typography>
        </Grid>
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="h4" component="h3">
              {t("enrollment.verify-page.sent-link")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" component="h4">
              {email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.resend}>
        <div className={classes.helpContainer}>
          <Typography variant="body1">{t("enrollment.verify-page.have-not-received")}</Typography>
          <Link
            component="button"
            variant="h4"
            className={classes.resendEmailText}
            onClick={() => {
              sendResumeApplicationEmail(
                { email },
                {
                  onSuccess: () => setResendLinkStatus(AlertType.success),
                  onError: () => setResendLinkStatus(AlertType.error),
                },
              );
            }}
          >
            {t("enrollment.verify-page.resend-email")}
          </Link>
        </div>
      </Grid>
    </Grid>
  );
};

export default VerifyAccountPage;
