import { NextButton, PreviousButton } from "@chq/components";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  container: {
    padding: "4.5rem 0.75rem 1rem 0.75rem",
    textAlign: "center",
    background: theme.palette.common.white,
    borderRadius: ".25rem .25rem 0rem 0rem",
  },
  textContainer: {
    paddingBottom: "4rem !important",
  },
  title: {
    textTransform: "capitalize",
  },
  loading: {
    color: theme.palette.common.white,
  },
}));

type Props = {
  email: string;
  onPreviousClick: React.MouseEventHandler<HTMLButtonElement>;
  onNextClick: React.MouseEventHandler<HTMLButtonElement>;
};

const StartNewQuote: React.FC<Props> = ({ email, onPreviousClick, onNextClick }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <Grid container spacing={1} justify="center" className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.title}>
          {t("start-new-quote.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.textContainer}>
        <Typography variant="h3">{t("start-new-quote.does-not-exist", { email: email })}</Typography>
      </Grid>
      <Grid item xs={3}>
        <PreviousButton
          variant="outlined"
          color="primary"
          fullWidth
          className={classes.previousButton}
          onClick={onPreviousClick}
          aria-label={t("enrollment.add-driver.previous-button-label")}
        ></PreviousButton>
      </Grid>
      <Grid item xs={9}>
        <NextButton variant="contained" color="primary" fullWidth onClick={onNextClick}>
          {t("start-new-quote.button")}
        </NextButton>
      </Grid>
    </Grid>
  );
};

export default StartNewQuote;
