import { makeStyles, Theme } from "@chq/styles";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import FormControl, { FormControlProps } from "./form-control";

interface RadioChoice {
  label: string;
  value: unknown;
}

export type RadioInputProps = Omit<FormControlProps, "children" | "variant"> & {
  choices: RadioChoice[];
  value?: unknown;
  name?: string;
  radioClass?: string;
  nativeRadio?: boolean;
  activeColor?: string;
  activeTextColor?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  hidden: {
    display: "none",
  },
  radio: {
    display: "flex",
  },
  buttonLabels: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRight: "none",
    padding: "0.375rem",
    minWidth: "100px",
    minHeight: "40px",
    flexGrow: 1,
    margin: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      padding: "0",
    },
    "&:first-child": {
      borderTopLeftRadius: "0.25rem",
      borderBottomLeftRadius: "0.25rem",
    },
    "&:last-child": {
      borderRight: `1px solid ${theme.palette.grey[500]}`,
      borderTopRightRadius: "0.25rem",
      borderBottomRightRadius: "0.25rem",
    },
  },
  active: (props: StyleProps) => ({
    backgroundColor: props.activeColor ? props.activeColor : theme.palette.secondary.contrastText,
    "& span": {
      color: props.activeTextColor ? props.activeTextColor : theme.palette.common.black,
    },
  }),
}));

type StyleProps = {
  activeColor?: string;
  activeTextColor?: string;
};

const RadioInput: React.FC<RadioInputProps> = ({
  activeColor,
  activeTextColor,
  classes,
  className,
  color = "primary",
  disabled = false,
  error = false,
  FormHelperTextProps,
  fullWidth = false,
  focused,
  helperText,
  hiddenLabel,
  id,
  InputLabelProps,
  label,
  labelAdornment,
  labelEmbellishment,
  required = false,
  margin = "normal",
  size,
  choices,
  onChange,
  value,
  name,
  radioClass,
  nativeRadio = false,
  ...FormControlProps
}) => {
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const props: StyleProps = {
    activeColor,
    activeTextColor,
  };
  const styles = useStyles(props);
  return (
    <FormControl
      className={classNames(classes?.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      focused={focused}
      hiddenLabel={hiddenLabel}
      required={required}
      color={color}
      FormHelperTextProps={FormHelperTextProps}
      helperTextId={helperTextId}
      helperText={helperText}
      labelId={inputLabelId}
      label={label}
      labelAdornment={labelAdornment}
      labelEmbellishment={labelEmbellishment}
      margin={margin}
      id={id}
      InputLabelProps={InputLabelProps}
      size={size}
      aria-labelledby={inputLabelId}
      aria-describedby={helperTextId}
      {...FormControlProps}
    >
      <RadioGroup row className={classNames(styles.radio, radioClass)} onChange={onChange} value={value} name={name}>
        {choices.map((choice, index) => (
          <FormControlLabel
            control={
              <Radio
                color="primary"
                className={classNames({
                  [styles.hidden]: !nativeRadio,
                })}
              />
            }
            label={choice.label}
            value={choice.value}
            className={classNames({
              [styles.buttonLabels]: !nativeRadio,
              [styles.active]: value === choice.value && !nativeRadio,
            })}
            key={`choice:${index}`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioInput;
