import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const HelpCircleOutlineIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M11.4 15.6H12.6V14.4H11.4V15.6ZM12 6C11.2121 6 10.4319 6.15519 9.7039 6.45672C8.97595 6.75825 8.31451 7.20021 7.75736 7.75736C6.63214 8.88258 6 10.4087 6 12C6 13.5913 6.63214 15.1174 7.75736 16.2426C8.31451 16.7998 8.97595 17.2417 9.7039 17.5433C10.4319 17.8448 11.2121 18 12 18C13.5913 18 15.1174 17.3679 16.2426 16.2426C17.3679 15.1174 18 13.5913 18 12C18 11.2121 17.8448 10.4319 17.5433 9.7039C17.2417 8.97595 16.7998 8.31451 16.2426 7.75736C15.6855 7.20021 15.0241 6.75825 14.2961 6.45672C13.5681 6.15519 12.7879 6 12 6ZM12 16.8C9.354 16.8 7.2 14.646 7.2 12C7.2 9.354 9.354 7.2 12 7.2C14.646 7.2 16.8 9.354 16.8 12C16.8 14.646 14.646 16.8 12 16.8ZM12 8.4C11.3635 8.4 10.753 8.65286 10.3029 9.10294C9.85286 9.55303 9.6 10.1635 9.6 10.8H10.8C10.8 10.4817 10.9264 10.1765 11.1515 9.95147C11.3765 9.72643 11.6817 9.6 12 9.6C12.3183 9.6 12.6235 9.72643 12.8485 9.95147C13.0736 10.1765 13.2 10.4817 13.2 10.8C13.2 12 11.4 11.85 11.4 13.8H12.6C12.6 12.45 14.4 12.3 14.4 10.8C14.4 10.1635 14.1471 9.55303 13.6971 9.10294C13.247 8.65286 12.6365 8.4 12 8.4Z" />
    </SvgIcon>
  );
};

export default HelpCircleOutlineIcon;
