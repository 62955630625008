import { CardField, EditableDeletableCard } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  containerClass: {
    paddingTop: "0.5rem",
    paddingBottom: "0.2rem",
  },
  spacing: {
    "&> .MuiGrid-root": {
      padding: "0.5rem 0rem",
    },
    "&> :first-child": {
      [theme.breakpoints.up("sm")]: {
        paddingTop: "1.25rem",
      },
    },
  },
  text: {
    alignSelf: "center",
    "& .MuiTypography-root": {
      fontSize: ".9rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "0.9rem",
    },
  },
  weeklyText: {
    "& .MuiTypography-root": {
      fontSize: "1rem",
    },
  },
  downPaymentText: {
    marginTop: ".5rem",
    "& .MuiTypography-root": {
      fontSize: ".8rem",
    },
  },
  paymentSection: {
    padding: ".625rem 0",
  },
  paymentSectionBorder: {
    borderTop: `1px ${theme.palette.grey[200]} solid`,
  },
}));

type Props = {
  weeklyDownPayment?: string;
  monthlyDownPayment?: string;
  weeklyTotal: string;
  monthlyTotal: string;
  annualTotal: string;
};

const YourQuoteCard: React.FC<Props> = ({
  weeklyTotal,
  weeklyDownPayment,
  monthlyTotal,
  monthlyDownPayment,
  annualTotal,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <EditableDeletableCard
      title={t("enrollment.quote-overview-page.your-quote")}
      variant="default"
      titleVariant="h2"
      titleComponent="h4"
      containerClass={classes.containerClass}
      IconButtonProps={{ "aria-label": t("enrollment.quote-overview-page.your-quote") }}
    >
      <Grid container className={classes.spacing}>
        <Grid className={classes.paymentSection} item xs={12}>
          <CardField
            id={t("enrollment.quote-overview-page.quote-summary.weekly")}
            label={t("enrollment.quote-overview-page.quote-summary.weekly")}
            labelColor="textPrimary"
            labelVariant="h4"
            values={[
              {
                value: t("enrollment.quote-overview-page.quote-summary.week", { money: weeklyTotal }),
                valueColor: "success",
                valueVariant: "h4",
                textAlignEnd: true,
              },
            ]}
          />
          <CardField
            id={t("enrollment.quote-overview-page.quote-summary.week")}
            label={t("enrollment.quote-overview-page.down-payment-due-at-bind")}
            labelVariant="h5"
            values={[
              {
                value: t("common.currency", { money: weeklyDownPayment }),
                valueVariant: "h5",
                textAlignEnd: true,
              },
            ]}
            className={classes.downPaymentText}
          />
        </Grid>
        <Grid className={classNames(classes.paymentSection, classes.paymentSectionBorder)} item xs={12}>
          <CardField
            id={t("enrollment.quote-overview-page.quote-summary.monthly")}
            label={t("enrollment.quote-overview-page.quote-summary.monthly")}
            labelColor="textPrimary"
            labelVariant="h4"
            values={[
              {
                value: t("enrollment.quote-overview-page.quote-summary.month", { money: monthlyTotal }),
                valueVariant: "h4",
                textAlignEnd: true,
              },
            ]}
          />
          <CardField
            id={t("enrollment.quote-overview-page.quote-summary.month")}
            label={t("enrollment.quote-overview-page.down-payment-due-at-bind")}
            labelVariant="h5"
            values={[
              {
                value: t("common.currency", { money: monthlyDownPayment }),
                valueVariant: "h5",
                textAlignEnd: true,
              },
            ]}
            className={classes.downPaymentText}
          />
        </Grid>
        <Grid className={classNames(classes.paymentSection, classes.paymentSectionBorder)} item xs={12}>
          <CardField
            id={t("enrollment.quote-overview-page.quote-summary.annually")}
            label={t("enrollment.quote-overview-page.quote-summary.annually")}
            labelColor="textPrimary"
            labelVariant="h4"
            values={[
              {
                value: t("enrollment.quote-overview-page.quote-summary.year", { money: annualTotal }),
                valueVariant: "h4",
                textAlignEnd: true,
              },
            ]}
          />
          <CardField
            id={t("enrollment.quote-overview-page.quote-summary.year")}
            label={t("enrollment.quote-overview-page.full-payment-due-at-bind")}
            labelVariant="h5"
            className={classes.downPaymentText}
          />
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default YourQuoteCard;
