import { booleanToRadioValue } from "@chq/components";
import { Equipment } from "@chq/enrollment-api";
import _ from "lodash";
import { Fields as LossPayeeAdditionalInsuredFields } from "../components/address-form";
import {
  EquipmentFormProps,
  Fields as EquipmentFormFields,
} from "../components/policy-management-components/equipment-form";
import { Fields as TrailerPowerUnitFormFields, TrailerPowerUnitFormProps } from "../components/trailer-power-unit-form";

export const equipmentFieldMapper = (equipment: Equipment): EquipmentFormProps & TrailerPowerUnitFormProps => {
  return {
    [TrailerPowerUnitFormFields.vehicleNumber]: equipment.vehicleNumber!,
    [TrailerPowerUnitFormFields.vin]: equipment.vin!,
    [TrailerPowerUnitFormFields.make]: equipment.make!,
    [TrailerPowerUnitFormFields.model]: equipment.model!,
    [TrailerPowerUnitFormFields.year]: equipment.year ? parseInt(equipment.year) : undefined,
    [TrailerPowerUnitFormFields.statedValue]: equipment.statedValue!,
    [TrailerPowerUnitFormFields.registrationZip]: equipment.registrationZip ? equipment.registrationZip : undefined,
    [TrailerPowerUnitFormFields.destination]: equipment.destinationZone!,
    [TrailerPowerUnitFormFields.ooIC]: booleanToRadioValue(equipment.ownerOperator),
    [TrailerPowerUnitFormFields.whereIsUnitRegistered]: equipment.registeredTo!,
    [TrailerPowerUnitFormFields.gapCoverage]: booleanToRadioValue(equipment.loanLeaseGapCoverage),
    [TrailerPowerUnitFormFields.coverageOption]: equipment.compAndCollisionCoverage,
    [EquipmentFormFields.lossPayee]: !_.isEmpty(equipment.lossPayees)
      ? {
          [LossPayeeAdditionalInsuredFields.entityName]: equipment.lossPayees![0].name,
          [LossPayeeAdditionalInsuredFields.addressLine1]: equipment.lossPayees![0].address?.address1,
          [LossPayeeAdditionalInsuredFields.addressLine2]: equipment.lossPayees![0].address?.address2,
          [LossPayeeAdditionalInsuredFields.city]: equipment.lossPayees![0].address?.city,
          [LossPayeeAdditionalInsuredFields.state]: equipment.lossPayees![0].address?.state,
          [LossPayeeAdditionalInsuredFields.zipcode]: equipment.lossPayees![0].address?.zip,
        }
      : undefined,
    [EquipmentFormFields.additionalInsured]: !_.isEmpty(equipment.additionalInsured)
      ? {
          [LossPayeeAdditionalInsuredFields.entityName]: equipment.additionalInsured![0].name,
          [LossPayeeAdditionalInsuredFields.addressLine1]: equipment.additionalInsured![0].address?.address1,
          [LossPayeeAdditionalInsuredFields.addressLine2]: equipment.additionalInsured![0].address?.address2,
          [LossPayeeAdditionalInsuredFields.city]: equipment.additionalInsured![0].address?.city,
          [LossPayeeAdditionalInsuredFields.state]: equipment.additionalInsured![0].address?.state,
          [LossPayeeAdditionalInsuredFields.zipcode]: equipment.additionalInsured![0].address?.zip,
          [LossPayeeAdditionalInsuredFields.ein]: equipment.additionalInsured![0].einNumber,
        }
      : undefined,
  };
};
