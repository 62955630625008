import { AlertType, PageAlert } from "@chq/components";
import { StripeAccountStatus } from "@chq/enrollment-api";
import { OwnerBankAccountInfo } from "@chq/policy-management-api";
import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PlaidLinkError,
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkStableEvent,
} from "react-plaid-link";
import DashboardMainCard from "../../../components/policy-management-components/dashboard-main-card";
import FileClaimCard from "../../../components/policy-management-components/file-claim-card";
import PaymentMethodCard from "../../../components/policy-management-components/payment-method-card";
import PmCompanyInfoCard from "../../../components/policy-management-components/pm-company-info-card";
import QuestionCard from "../../../components/policy-management-components/question-card";
import { useGetApplicationReview, useGetPlaidToken } from "../../../data/enrollment";
import { useGetOwnerBankAccountInfo } from "../../../data/policy-management/useGetOwnerBankAccountInfo";
import { useGetPolicy } from "../../../data/policy-management/useGetPolicy";
import { useUpdateStripeAccount } from "../../../data/policy-management/useUpdateStripeAccount";
import { fullName } from "../../../utils/full-name";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    padding: "1.5rem 0.75rem 1.5rem 0.75rem",
    flexDirection: "column",
    alignItems: "center",
    "& > .MuiGrid-item": {
      marginBottom: "1rem",
    },
  },
  unitCardContainer: {
    width: "100%",
  },
}));

const DashboardPage: React.FC = () => {
  const [t] = useTranslation();

  const [plaidSuccess, setPlaidSuccess] = useState<boolean | undefined>();
  const classes = useStyles();
  const { data: applicationReview, isLoading: isLoadingApplicationReview } = useGetApplicationReview(undefined);
  const { data, isLoading, error } = useGetPolicy();
  const {
    data: ownerBankAccountInfoData,
    isLoading: isLoadingOwnerBankAccountInfoData,
    error: ownerBankAccountInfoError,
  } = useGetOwnerBankAccountInfo();
  const { data: token, isLoading: isLoadingToken } = useGetPlaidToken();
  const [stripeBankAccountAdded, setStripeBankAccountAdded] = useState(false);
  const [complete, setComplete] = useState(false);
  const { mutate: updateStripeAccount, isLoading: isUpdatingStripeAccount } = useUpdateStripeAccount();
  const [updatedBankAccountInfo, setUpdatedBankAccountInfo] = useState<OwnerBankAccountInfo>();

  const paymentPlaidOnSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token, metadata) => {
      updateStripeAccount(
        { publicToken: public_token, metadata },
        {
          onSuccess: (data: OwnerBankAccountInfo) => {
            if (data !== null) {
              setComplete(true);
              setPlaidSuccess(true);
              setStripeBankAccountAdded(true);
            } else {
              setStripeBankAccountAdded(false);
              setComplete(true);
              setPlaidSuccess(false);
            }
          },
          onError: () => {
            setStripeBankAccountAdded(false);
            setComplete(true);
            setPlaidSuccess(false);
          },
        },
      );
    },
    [updateStripeAccount],
  );

  const plaidOnEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
      if (eventName === "ERROR") {
        setPlaidSuccess(false);
        setStripeBankAccountAdded(false);
      }
    },
    [],
  );
  const plaidOnExit = useCallback<PlaidLinkOnExit>(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      setPlaidSuccess(false);
      setComplete(false);
      setStripeBankAccountAdded(false);
    },
    [],
  );

  useEffect(() => {
    const bankAccount = applicationReview?.bankAccount;
    setStripeBankAccountAdded(
      bankAccount?.accountStatus === StripeAccountStatus.New ||
        bankAccount?.accountStatus === StripeAccountStatus.Verified,
    );
    setPlaidSuccess(bankAccount?.accountStatus === StripeAccountStatus.Verified ? true : undefined);
  }, [applicationReview]);

  useEffect(() => {
    setUpdatedBankAccountInfo(ownerBankAccountInfoData);
  }, [ownerBankAccountInfoData]);

  return (
    <>
      {error && <PageAlert alertType={AlertType.error} message={error.message} />}
      {ownerBankAccountInfoError && (
        <PageAlert alertType={AlertType.error} message={ownerBankAccountInfoError.message} />
      )}
      <Grid container className={classes.container}>
        {isLoading || isLoadingApplicationReview || isLoadingOwnerBankAccountInfoData || isLoadingToken ? (
          <Grid item>
            <CircularProgress color="primary" size="2.5rem" aria-label={t("common.circular-progress-aria-label")} />
          </Grid>
        ) : (
          <>
            <Grid item className={classes.unitCardContainer} xs={12} sm={6} lg={4}>
              <DashboardMainCard
                policyNumber={data?.policyNumber || ""}
                startCoverage={data?.effectiveDate}
                endCoverage={data?.exiprationDate}
                lastPayment={0}
                currentPayment={0}
                paymentHistoryEnabled={false}
              />
            </Grid>
            <Grid item className={classes.unitCardContainer} xs={12} sm={6} lg={4}>
              <PmCompanyInfoCard
                name={data?.business?.businessName}
                dotNumber={data?.business?.dotNumber}
                ein={data?.business?.einNumber}
                mcNumber={data?.business?.mcNumber}
                vehicleLocation={data?.business?.address?.state}
                radiusOfOperation={data?.business?.radiusOfOperation}
                entityType={data?.business?.legalEntity}
                operatingAuthStatus={data?.business?.operatingAuthorityStatus}
                primaryOperationType={data?.business?.operationType}
              />
            </Grid>
            <Grid item className={classes.unitCardContainer} xs={12} sm={6} lg={4}>
              <FileClaimCard
                policyNumber={data?.policyNumber || ""}
                dotNumber={data?.business?.dotNumber || ""}
                name={fullName(data?.business?.contactFirstName || "", data?.business?.contactLastName || "")}
                email={data?.business?.contactEmail || ""}
                phone={data?.business?.contactPhone || ""}
              />
            </Grid>
            <Grid item className={classes.unitCardContainer} xs={12} sm={6} lg={4}>
              <QuestionCard />
            </Grid>
            <Grid item className={classes.unitCardContainer} xs={12} sm={6} lg={4}>
              <PaymentMethodCard
                paymentMethod={updatedBankAccountInfo?.paymentMethod || undefined}
                paymentCycle={updatedBankAccountInfo?.paymentCycle}
                bankAccountInfo={updatedBankAccountInfo?.bankAccountInfo || undefined}
                token={token}
                plaidOnEvent={plaidOnEvent}
                plaidOnExit={plaidOnExit}
                plaidOnSuccess={paymentPlaidOnSuccess}
                plaidSuccess={plaidSuccess}
                complete={complete}
                stripeBankAccountAdded={stripeBankAccountAdded}
                isUpdatingStripeAccount={isUpdatingStripeAccount}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default DashboardPage;
