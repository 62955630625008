import { Select } from "@chq/components";
import { Card, CardContent, makeStyles, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: "1rem",
  },
  select: {
    "& .MuiOutlinedInput-root": {
      [theme.breakpoints.down("xs")]: {
        marginTop: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "1.5rem",
      },
    },
  },
}));

export enum Fields {
  intrastateFilingAuthority = "Intrastate Filing Authority",
}

export type FormProps = {
  [Fields.intrastateFilingAuthority]: string | undefined;
};

export type Props = {
  intrastateFilingAuthority?: string | undefined;
  state?: string;
};

export const useValidationSchema = () => {
  return Yup.object({
    [Fields.intrastateFilingAuthority]: Yup.string().required(),
  });
};

export const useFormikConfig = ({ intrastateFilingAuthority = undefined }: Props = {}): Omit<
  FormikConfig<FormProps>,
  "onSubmit"
> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.intrastateFilingAuthority]: intrastateFilingAuthority,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const IntrastateFilingForm: React.FC<Props> = ({ state }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<FormProps>();

  enum IntraStateOptions {
    Yes = "true",
    No = "false",
  }

  const intraStateOptions = [IntraStateOptions.Yes, IntraStateOptions.No];

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2" className={classes.title}>
          {t("intrastate-filing-form.intrastate-filing-authority")}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Select
            fullWidth
            label={t(`intrastate-filing-form.instrastate-helper-text`, { state })}
            required
            items={intraStateOptions.map((intraStateOption) => ({
              name: t(`intrastate-filing-form.intrastate-select-options.${intraStateOption}`),
              value: intraStateOption,
            }))}
            className={classes.select}
            id={Fields.intrastateFilingAuthority}
            name={Fields.intrastateFilingAuthority}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.intrastateFilingAuthority]}
            error={
              formik.touched[Fields.intrastateFilingAuthority] &&
              Boolean(formik.errors[Fields.intrastateFilingAuthority])
            }
            helperText={
              formik.touched[Fields.intrastateFilingAuthority] && formik.errors[Fields.intrastateFilingAuthority]
            }
          />
        </form>
      </CardContent>
    </Card>
  );
};

export default IntrastateFilingForm;
