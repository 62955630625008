import { ApplicationApi, TermsAndConditionsLinks } from "@chq/enrollment-api";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { useApi } from "../useApi";

export const useTermsAndConditionLinks = (
  options?: UseQueryOptions<TermsAndConditionsLinks, Error>,
): UseQueryResult<TermsAndConditionsLinks, Error> => {
  const applicationApi = useApi(ApplicationApi);

  const query = useQuery<TermsAndConditionsLinks, Error>(
    "terms",
    async () => {
      const response = await applicationApi.apiV10ApplicationGetTermsOfUseGet();
      if (response.error) {
        throw new Error(response.error.message || "Unable to find terms and conditions");
      }
      return response.data!;
    },
    options,
  );
  return query;
};
