/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FactoringApplicationCreateRequest
 */
export interface FactoringApplicationCreateRequest {
  /**
   *
   * @type {string}
   * @memberof FactoringApplicationCreateRequest
   */
  contactFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplicationCreateRequest
   */
  contactLastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplicationCreateRequest
   */
  contactTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringApplicationCreateRequest
   */
  contactEmail?: string | null;
  /**
   *
   * @type {number}
   * @memberof FactoringApplicationCreateRequest
   */
  monthlyRevenue?: number;
  /**
   *
   * @type {number}
   * @memberof FactoringApplicationCreateRequest
   */
  trucksInFleet?: number;
  /**
   *
   * @type {boolean}
   * @memberof FactoringApplicationCreateRequest
   */
  currentlyFactoring?: boolean;
  /**
   *
   * @type {string}
   * @memberof FactoringApplicationCreateRequest
   */
  currentFactoringCompany?: string | null;
}

export function FactoringApplicationCreateRequestFromJSON(json: any): FactoringApplicationCreateRequest {
  return FactoringApplicationCreateRequestFromJSONTyped(json, false);
}

export function FactoringApplicationCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoringApplicationCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    contactFirstName: !exists(json, "contactFirstName") ? undefined : json["contactFirstName"],
    contactLastName: !exists(json, "contactLastName") ? undefined : json["contactLastName"],
    contactTitle: !exists(json, "contactTitle") ? undefined : json["contactTitle"],
    contactEmail: !exists(json, "contactEmail") ? undefined : json["contactEmail"],
    monthlyRevenue: !exists(json, "monthlyRevenue") ? undefined : json["monthlyRevenue"],
    trucksInFleet: !exists(json, "trucksInFleet") ? undefined : json["trucksInFleet"],
    currentlyFactoring: !exists(json, "currentlyFactoring") ? undefined : json["currentlyFactoring"],
    currentFactoringCompany: !exists(json, "currentFactoringCompany") ? undefined : json["currentFactoringCompany"],
  };
}

export function FactoringApplicationCreateRequestToJSON(value?: FactoringApplicationCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    contactFirstName: value.contactFirstName,
    contactLastName: value.contactLastName,
    contactTitle: value.contactTitle,
    contactEmail: value.contactEmail,
    monthlyRevenue: value.monthlyRevenue,
    trucksInFleet: value.trucksInFleet,
    currentlyFactoring: value.currentlyFactoring,
    currentFactoringCompany: value.currentFactoringCompany,
  };
}
