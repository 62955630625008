import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const usePasswordRules = () => {
  const [t] = useTranslation();
  return Yup.string()
    .min(8, "")
    .matches(/[0-9]/, { message: " " })
    .matches(/[A-Z]/, { message: " " })
    .required(t(`errors.password`));
};
