/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { State, StateFromJSON, StateFromJSONTyped, StateToJSON } from "./";

/**
 *
 * @export
 * @interface FactoringAddress
 */
export interface FactoringAddress {
  /**
   *
   * @type {number}
   * @memberof FactoringAddress
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FactoringAddress
   */
  address1?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringAddress
   */
  address2?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringAddress
   */
  city?: string | null;
  /**
   *
   * @type {State}
   * @memberof FactoringAddress
   */
  state?: State;
  /**
   *
   * @type {string}
   * @memberof FactoringAddress
   */
  zip?: string | null;
}

export function FactoringAddressFromJSON(json: any): FactoringAddress {
  return FactoringAddressFromJSONTyped(json, false);
}

export function FactoringAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): FactoringAddress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    address1: !exists(json, "address1") ? undefined : json["address1"],
    address2: !exists(json, "address2") ? undefined : json["address2"],
    city: !exists(json, "city") ? undefined : json["city"],
    state: !exists(json, "state") ? undefined : StateFromJSON(json["state"]),
    zip: !exists(json, "zip") ? undefined : json["zip"],
  };
}

export function FactoringAddressToJSON(value?: FactoringAddress | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    address1: value.address1,
    address2: value.address2,
    city: value.city,
    state: StateToJSON(value.state),
    zip: value.zip,
  };
}
