import { ApplicationApi, AuthenticationApi, UserStatusResponseApiResponse } from "@chq/enrollment-api";
import { useAsyncFn } from "react-use";
import { AsyncFnReturn } from "react-use/lib/useAsync";
import { useApi } from "./useApi";

export enum ResetPasswordErrorMessage {
  noPasswordFound = "No Password Associated With Account",
  noAccountFound = "No Account Associated With Email",
}

export const useResetPassword = (): AsyncFnReturn => {
  const authenticationApi = useApi(AuthenticationApi);
  const applicationApi = useApi(ApplicationApi);

  return useAsyncFn(async (email: string) => {
    let userStatus: UserStatusResponseApiResponse;
    try {
      const { data } = await authenticationApi.apiV10AuthenticationUserIdPost({ userIdRequest: { email } });
      userStatus = await authenticationApi.apiV10AuthenticationUserStatusPost({
        userStatusRequest: { userId: data?.userId },
      });
    } catch (e) {
      throw new Error(ResetPasswordErrorMessage.noAccountFound);
    }
    if (userStatus?.data?.hasPassword) {
      return await applicationApi.apiV10ApplicationPasswordResetPost({ email: email });
    } else {
      throw new Error(ResetPasswordErrorMessage.noPasswordFound);
    }
  });
};
