import React from "react";
import TextInput, { TextInputProps } from "./text-input";

export type SelectItem = {
  name: string | React.ReactNode;
  value: string | number;
};

type Props = Omit<TextInputProps, "select" | "SelectProps"> & {
  items: Array<SelectItem>;
};

const Select: React.FC<Props> = ({ items, ["aria-label"]: ariaLabel, ...TextInputProps }) => {
  return (
    <TextInput {...TextInputProps} select SelectProps={{ inputProps: { "aria-label": ariaLabel }, native: true }}>
      <option></option>
      {items.map((item, index) => {
        return (
          <option value={item.value} key={`${item.value}-${index}-menu-item`}>
            {item.name}
          </option>
        );
      })}
    </TextInput>
  );
};

export default Select;
