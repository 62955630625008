import { DatePicker, EditableDeletableCard, NumericInput, SaveButton, Select } from "@chq/components";
import { OperatingAuthorityStatus } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { startOfToday } from "date-fns";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useOperatingAuthorityStatues } from "../data/useOperatingAuthorityStatuses";
import { getFutureDate } from "../utils/get-future-date";

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    padding: "1.125rem 0rem",
  },
  container: {
    paddingTop: ".75rem",
  },
  form: {
    paddingTop: "0.5rem",
  },
  inputTitle: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        marginTop: "1rem",
      },
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        marginTop: "1rem",
      },
    },
  },
}));

export enum Fields {
  ein = "ein",
  mcNumber = "mc-number",
  operatingAuthStatus = "operation-auth-status",
  desiredEffectiveDate = "desired-effective-date",
}

export const useValidationSchema = (quoteCreationDate: Date) => {
  const [t] = useTranslation();
  const today = startOfToday();
  const limit = getFutureDate(quoteCreationDate, 89);

  return yup.object({
    [Fields.ein]: yup
      .number()
      .test("length", t("company-info-form.ein.length"), (val) => val !== undefined && val.toString().length === 9)
      .required(t("errors.required", { field: t("company-info-form.ein.label") })),
    [Fields.mcNumber]: yup.string().test(
      "length",
      t("errors.between-digits", {
        field: t(`factoring.business-details.${Fields.mcNumber}.label`),
        min: "6",
        max: "8",
      }),
      (val) => (val ? val.toString().length >= 6 && val.toString().length <= 8 : true),
    ),
    [Fields.operatingAuthStatus]: yup
      .string()
      .required(t("errors.required", { field: t("company-info-form.operating-auth-status.label") })),
    [Fields.desiredEffectiveDate]: yup
      .date()
      .required(t("errors.required", { field: t("company-info-form.desired-effective-date.label") }))
      .min(today, t("company-info-form.errors.min-effective-date"))
      .max(limit, t("company-info-form.errors.max-effective-date")),
  });
};

export type FormProps = {
  [Fields.ein]: string;
  [Fields.mcNumber]: string;
  [Fields.operatingAuthStatus]?: OperatingAuthorityStatus | "";
  [Fields.desiredEffectiveDate]?: Date | null;
};

type Props = {
  ein?: string;
  mcNumber?: string;
  operatingAuthStatus?: OperatingAuthorityStatus | "";
  desiredEffectiveDate?: Date | null;
  quoteCreationDate?: Date | null;
  isSomethingSaving?: boolean;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const CompanyInfoForm: React.FC<Props> = ({
  ein = "",
  mcNumber = "",
  operatingAuthStatus = "",
  desiredEffectiveDate,
  quoteCreationDate,
  isSomethingSaving,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const today = startOfToday();
  const limit = getFutureDate(quoteCreationDate || today, 89);
  const validationSchema = useValidationSchema(quoteCreationDate || today);
  const classes = useStyles();
  const operatingAuthStatuses = useOperatingAuthorityStatues();

  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.ein]: ein,
      [Fields.mcNumber]: mcNumber,
      [Fields.operatingAuthStatus]: operatingAuthStatus,
      [Fields.desiredEffectiveDate]: desiredEffectiveDate,
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  return (
    <EditableDeletableCard
      title={t("company-info-form.title")}
      titleVariant="h2"
      variant="default"
      className={classes.container}
    >
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <NumericInput
              fullWidth
              required
              format="##-#######"
              label={t("company-info-form.ein.label")}
              id={Fields.ein}
              name={Fields.ein}
              onChange={formik.handleChange}
              isNumericString
              onBlur={formik.handleBlur}
              value={formik.values[Fields.ein]}
              error={formik.touched[Fields.ein] && Boolean(formik.errors[Fields.ein])}
              helperText={formik.touched[Fields.ein] && formik.errors[Fields.ein]}
            />
          </Grid>
          <Grid item xs={6}>
            <NumericInput
              fullWidth
              label={t("company-info-form.mc-number.label")}
              id={Fields.mcNumber}
              name={Fields.mcNumber}
              format="########"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.mcNumber]}
              error={formik.touched[Fields.mcNumber] && Boolean(formik.errors[Fields.mcNumber])}
              helperText={formik.touched[Fields.mcNumber] && formik.errors[Fields.mcNumber]}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              required
              label={t("company-info-form.operating-auth-status.label")}
              id={Fields.operatingAuthStatus}
              name={Fields.operatingAuthStatus}
              items={operatingAuthStatuses.map((status: OperatingAuthorityStatus) => ({
                name: t(`operating-authority-status.${status}`),
                value: status,
              }))}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.operatingAuthStatus]}
              error={formik.touched[Fields.operatingAuthStatus] && Boolean(formik.errors[Fields.operatingAuthStatus])}
              helperText={formik.touched[Fields.operatingAuthStatus] && formik.errors[Fields.operatingAuthStatus]}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              fullWidth
              required
              className={classes.inputTitle}
              label={t("company-info-form.desired-effective-date.label")}
              id={Fields.desiredEffectiveDate}
              name={Fields.desiredEffectiveDate}
              disablePast
              maxDate={limit}
              KeyboardButtonProps={{
                "aria-label": t("company-info-form.desired-effective-date.effective-date-button"),
              }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(date: any) => {
                formik.setFieldValue(Fields.desiredEffectiveDate, date);
              }}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.desiredEffectiveDate] || null}
              error={formik.touched[Fields.desiredEffectiveDate] && Boolean(formik.errors[Fields.desiredEffectiveDate])}
              helperText={formik.touched[Fields.desiredEffectiveDate] && formik.errors[Fields.desiredEffectiveDate]}
            />
          </Grid>
          <Grid item xs={12}>
            <SaveButton
              label={t("company-info-form.add-button")}
              variant="outlined"
              fullWidth
              type="submit"
              disabled={!formik.isValid || isSomethingSaving}
              formikValid={formik.isValid}
            />
          </Grid>
        </Grid>
      </form>
    </EditableDeletableCard>
  );
};

export default CompanyInfoForm;
