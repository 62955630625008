import { Grid, IconButton, makeStyles, OutlinedInput, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import NumberFormat from "react-number-format";
import FormControl from "./form-control";
import MinusIcon from "./icons/minus-circle";
import PlusIcon from "./icons/plus-circle";
import { TextInputProps } from "./text-input";

const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    height: "40px",
    width: "auto",
  },
  buttons: {
    padding: "0px",
  },
  buttonFlex: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
  },
  inputFlex: {
    flex: 2,
    backgroundColor: theme.palette.common.white,
    borderRadius: "0.25rem",
  },
  container: {
    width: "10rem",
  },
}));

type Props = Omit<TextInputProps, "type" | "select" | "SelectProps" | "value" | "defaultValue" | "onChange"> & {
  min?: number;
  max?: number;
  step?: number;
  value: number;
  iconClass?: string;
  onChange: (event: { target: { name?: string; value: number } }) => void;
};

export type NumberPickerChangeEvent = { target: { name?: string; value: number } };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function NumberPickerFormat(props: any) {
  const { inputRef, onChange, id, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      id={id}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

const NumberPicker: React.FC<Props> = ({
  autoComplete,
  autoFocus = false,
  classes,
  className,
  color = "primary",
  disabled = false,
  error = false,
  FormHelperTextProps,
  fullWidth = false,
  helperText,
  hiddenLabel,
  id,
  InputLabelProps,
  inputProps,
  InputProps,
  inputRef,
  label,
  labelAdornment,
  multiline = false,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required = false,
  rows,
  rowsMax,
  value,
  labelEmbellishment,
  min,
  max,
  step = 1,
  iconClass,
  ...other
}) => {
  const defaultClasses = useStyles();
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const InputComponent = OutlinedInput;
  const InputElement = (
    <InputComponent
      aria-describedby={helperTextId}
      autoComplete={autoComplete}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      multiline={multiline}
      name={name}
      rows={rows}
      rowsMax={rowsMax}
      type="text"
      value={value}
      id={id}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
          target: {
            name: name,
            value: parseInt(e.target.value),
          },
        });
      }}
      onFocus={onFocus}
      placeholder={placeholder}
      inputProps={inputProps}
      inputComponent={NumberPickerFormat}
      {...InputProps}
    />
  );
  return (
    <FormControl
      className={classNames(defaultClasses.container, classes?.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      required={required}
      color={color}
      FormHelperTextProps={FormHelperTextProps}
      helperTextId={helperTextId}
      helperText={helperText}
      labelId={inputLabelId}
      label={label}
      labelAdornment={labelAdornment}
      labelEmbellishment={labelEmbellishment}
      id={id}
      InputLabelProps={InputLabelProps}
      {...other}
    >
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item className={defaultClasses.buttonFlex}>
          <IconButton
            className={defaultClasses.buttons}
            aria-label="minus icon"
            color={color}
            onClick={() => {
              let newValue = value - step;
              if (min !== undefined && newValue < min) {
                newValue = min;
              } else if (max !== undefined && newValue > max) {
                newValue = max;
              }
              onChange({
                target: {
                  name: name,
                  value: newValue,
                },
              });
            }}
            disabled={disabled || (min !== undefined && value <= min)}
          >
            <MinusIcon className={classNames(defaultClasses.icons, iconClass)} />
          </IconButton>
        </Grid>
        <Grid item className={defaultClasses.inputFlex}>
          {InputElement}
        </Grid>
        <Grid item className={defaultClasses.buttonFlex}>
          <IconButton
            className={defaultClasses.buttons}
            aria-label="plus icon"
            color={color}
            onClick={() => {
              let newValue = value + step;
              if (max !== undefined && newValue > max) {
                newValue = max;
              } else if (min !== undefined && newValue < min) {
                newValue = min;
              }
              onChange({
                target: {
                  name: name,
                  value: newValue,
                },
              });
            }}
            disabled={disabled || (max !== undefined && value >= max)}
          >
            <PlusIcon className={classNames(defaultClasses.icons, iconClass)} />
          </IconButton>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default NumberPicker;
