import {
  DeleteIcon,
  DocumentUpload,
  EditableDeletableCard,
  PlusCircleIcon as PlusCircle,
  TooltipHelp,
} from "@chq/components";
import { FactoringDocument, FactoringDocumentType } from "@chq/factoring-api";
import { fade } from "@chq/styles";
import { Button, CircularProgress, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.grey[600],
    paddingTop: "0.5rem",
  },
  addIcon: {
    height: "40px",
    width: "auto",
  },
  deleteIcon: {
    height: "30px",
    width: "auto",
  },
  iconButton: {
    padding: "0px",
  },
  addButton: {
    textTransform: "uppercase",
    fontSize: "0.75rem",
  },
  documentsSection: {
    marginTop: "1rem",
    "& > .MuiGrid-container": {
      marginBottom: "1rem",
    },
  },
  documentButton: {
    ...theme.typography.body1,
    minHeight: "2.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.common.white,
  },
  fileAddedButton: {
    ...theme.typography.body1,
    fontSize: "1rem",
    justifyContent: "flex-start",
    paddingLeft: "0.5rem",
  },
  submitButton: {
    minHeight: "54px",
    ...theme.typography.h3,
    color: theme.palette.primary.main,
    marginTop: "2rem",
    fontSize: "1.25rem",
    fontWeight: 700,
    border: "2px solid",
    padding: "1rem",
  },
  examples: {
    color: theme.palette.common.white,
  },
  bullets: {
    "& > li": {
      ...theme.typography.body1,
      color: theme.palette.common.white,
    },
  },
}));

export type DocumentsList = {
  [FactoringDocumentType.ArticlesOfIncorporation]?: FactoringDocument | null;
  [FactoringDocumentType.W9]?: FactoringDocument | null;
  [FactoringDocumentType.DriversLicense]?: FactoringDocument | null;
  [FactoringDocumentType.Other]?: FactoringDocument[];
};

export type Props = {
  articlesOfIncorporation?: FactoringDocument | null;
  w9?: FactoringDocument | null;
  driversLicense?: FactoringDocument | null;
  additionalDocuments?: FactoringDocument[];
  onAddDocument: (type: FactoringDocumentType, file: File) => void;
  onDeleteDocument: (type: FactoringDocumentType, document: FactoringDocument) => void;
  disabled?: boolean;
  isLoading?: boolean;
  onSubmit?: () => void;
};

const FactoringDocumentsForm: React.FC<Props> = ({
  articlesOfIncorporation,
  w9,
  driversLicense,
  additionalDocuments = [],
  onAddDocument,
  onDeleteDocument,
  disabled = false,
  isLoading,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const documentTypeKeys = [
    FactoringDocumentType.ArticlesOfIncorporation,
    FactoringDocumentType.W9,
    FactoringDocumentType.DriversLicense,
  ];
  const [documents, setDocuments] = useState<DocumentsList>({
    [FactoringDocumentType.ArticlesOfIncorporation]: articlesOfIncorporation,
    [FactoringDocumentType.W9]: w9,
    [FactoringDocumentType.DriversLicense]: driversLicense,
    [FactoringDocumentType.Other]: additionalDocuments,
  });

  useEffect(() => {
    setDocuments({
      [FactoringDocumentType.ArticlesOfIncorporation]: articlesOfIncorporation,
      [FactoringDocumentType.W9]: w9,
      [FactoringDocumentType.DriversLicense]: driversLicense,
      [FactoringDocumentType.Other]: additionalDocuments,
    });
  }, [additionalDocuments, articlesOfIncorporation, driversLicense, w9]);

  return (
    <EditableDeletableCard variant="default" className={classes.container}>
      <Grid container direction="column">
        <Grid container item direction="column">
          <Grid container item direction="row" justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h4">
                {t("factoring.documents-form.title")}
              </Typography>
            </Grid>
            <Grid item>
              <TooltipHelp
                title={
                  <>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="h2" className={classes.examples}>
                          {t("factoring.documents-form.tooltip-text.title")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ul className={classes.bullets}>
                          <li>
                            {t(`factoring.documents-form.files.${FactoringDocumentType.ArticlesOfIncorporation}`)}
                          </li>
                          <li>{t(`factoring.documents-form.files.${FactoringDocumentType.W9}`)}</li>
                          <li>{t(`factoring.documents-form.tooltip-text.license`)}</li>
                          <li>{t(`factoring.documents-form.tooltip-text.factoring`)}</li>
                        </ul>
                      </Grid>
                    </Grid>
                  </>
                }
                placement="left-end"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h4">{t("factoring.documents-form.subtitle")}</Typography>
          </Grid>
        </Grid>
        <Grid container item direction="column" className={classes.documentsSection}>
          {documentTypeKeys.map((documentType, index) => {
            const document = documents[documentType] as FactoringDocument | null;
            return (
              <DocumentUpload
                key={index}
                title={t(`factoring.documents-form.files.${FactoringDocumentType[documentType]}`)}
                fileTypes="image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" // all images, .pdf, .doc, .docx, .xls, .xlsx
                isDocumentEntered={Boolean(document)}
                name={document ? document.name : documentType}
                filePrompt={t("factoring.documents-form.files.prompt")}
                onChange={(e) => {
                  const file = e.target.files?.item(0);
                  if (file) {
                    setDocuments((prevState) => {
                      return { ...prevState, [documentType]: { name: file.name } };
                    });
                    onAddDocument(documentType, file);
                  }
                }}
                onDelete={() => {
                  document && onDeleteDocument(documentType, document);
                }}
              />
            );
          })}
          {(documents[FactoringDocumentType.Other] || []).map((doc, index) => {
            return (
              <Grid key={index} container item direction="column" spacing={1}>
                <Grid container item direction="row" justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="h4">
                      {t(`factoring.documents-form.files.${FactoringDocumentType.Other}`, { number: index + 5 })}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => {
                        setDocuments((prevState) => {
                          prevState[FactoringDocumentType.Other]?.splice(index, 1);
                          return {
                            ...prevState,
                            [FactoringDocumentType.Other]: prevState[FactoringDocumentType.Other],
                          };
                        });
                        onDeleteDocument(FactoringDocumentType.Other, doc);
                      }}
                    >
                      <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    fullWidth
                    component="label"
                    className={classNames(classes.documentButton, {
                      [classes.fileAddedButton]: doc?.name !== "",
                    })}
                  >
                    {doc && doc.name ? doc.name : t("factoring.documents-form.files.prompt")}
                    <input
                      type="file"
                      accept={
                        "image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      } // all images, .pdf, .doc, .docx, .xls, .xlsx
                      onChange={(e) => {
                        const file = e.target.files?.item(0);
                        if (file) {
                          setDocuments((prevState) => {
                            return {
                              ...prevState,
                              [FactoringDocumentType.Other]: (prevState[FactoringDocumentType.Other] || []).map(
                                (item, additionalIndex) => {
                                  if (index === additionalIndex) {
                                    return file;
                                  } else {
                                    return item;
                                  }
                                },
                              ),
                            };
                          });

                          onAddDocument(FactoringDocumentType.Other, file);
                        }
                      }}
                      hidden
                    />
                  </Button>
                </Grid>
              </Grid>
            );
          })}
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setDocuments((prevState) => {
                  const existingDocuments = prevState[FactoringDocumentType.Other] || [];
                  return {
                    ...prevState,
                    [FactoringDocumentType.Other]: [...existingDocuments, { name: "" }],
                  };
                });
              }}
              disabled={Boolean(documents[FactoringDocumentType.Other]?.length === 6)}
              startIcon={<PlusCircle className={classes.addIcon} />}
              className={classes.addButton}
            >
              {t("factoring.documents-form.add-button")}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            fullWidth
            className={classes.submitButton}
            color="primary"
            disabled={disabled}
            onClick={onSubmit}
          >
            {isLoading ? (
              <CircularProgress color="primary" size="2rem" aria-label={t("common.circular-progress-aria-label")} />
            ) : (
              t("factoring.documents-form.continue-button")
            )}
          </Button>
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default FactoringDocumentsForm;
