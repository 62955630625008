/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AccountInfoIEnumerableApiResponse,
  AccountInfoIEnumerableApiResponseFromJSON,
  AccountInfoIEnumerableApiResponseToJSON,
  BooleanApiResponse,
  BooleanApiResponseFromJSON,
  BooleanApiResponseToJSON,
  StringApiResponse,
  StringApiResponseFromJSON,
  StringApiResponseToJSON,
} from "../models";

export interface ApiV10PlaidCreateStripeAccountGetRequest {
  accountId?: string;
}

export interface ApiV10PlaidExchangePublicTokenGetRequest {
  publicToken?: string;
}

/**
 *
 */
export class PlaidApi extends runtime.BaseAPI {
  /**
   * Through the Plaid Link flow the connected account id is returned and  when passed to this endpoint will create the Stripe Customer and Bank Account.
   */
  async apiV10PlaidCreateStripeAccountGetRaw(
    requestParameters: ApiV10PlaidCreateStripeAccountGetRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.accountId !== undefined) {
      queryParameters["accountId"] = requestParameters.accountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Plaid/createStripeAccount`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   * Through the Plaid Link flow the connected account id is returned and  when passed to this endpoint will create the Stripe Customer and Bank Account.
   */
  async apiV10PlaidCreateStripeAccountGet(
    requestParameters: ApiV10PlaidCreateStripeAccountGetRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10PlaidCreateStripeAccountGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Exchanges the public token for an access token
   */
  async apiV10PlaidExchangePublicTokenGetRaw(
    requestParameters: ApiV10PlaidExchangePublicTokenGetRequest,
  ): Promise<runtime.ApiResponse<StringApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.publicToken !== undefined) {
      queryParameters["publicToken"] = requestParameters.publicToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Plaid/exchangePublicToken`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
  }

  /**
   * Exchanges the public token for an access token
   */
  async apiV10PlaidExchangePublicTokenGet(
    requestParameters: ApiV10PlaidExchangePublicTokenGetRequest,
  ): Promise<StringApiResponse> {
    const response = await this.apiV10PlaidExchangePublicTokenGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Retrieves the access token for the current user
   */
  async apiV10PlaidGetAccessTokenGetRaw(): Promise<runtime.ApiResponse<StringApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Plaid/getAccessToken`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
  }

  /**
   * Retrieves the access token for the current user
   */
  async apiV10PlaidGetAccessTokenGet(): Promise<StringApiResponse> {
    const response = await this.apiV10PlaidGetAccessTokenGetRaw();
    return await response.value();
  }

  /**
   * Gets all the accounts associated with the current user
   */
  async apiV10PlaidGetAccountsGetRaw(): Promise<runtime.ApiResponse<AccountInfoIEnumerableApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Plaid/getAccounts`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => AccountInfoIEnumerableApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets all the accounts associated with the current user
   */
  async apiV10PlaidGetAccountsGet(): Promise<AccountInfoIEnumerableApiResponse> {
    const response = await this.apiV10PlaidGetAccountsGetRaw();
    return await response.value();
  }

  /**
   * Gets a link token (or sandbox public_token) for the given user
   */
  async apiV10PlaidLinkTokenGetRaw(): Promise<runtime.ApiResponse<StringApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Plaid/linkToken`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets a link token (or sandbox public_token) for the given user
   */
  async apiV10PlaidLinkTokenGet(): Promise<StringApiResponse> {
    const response = await this.apiV10PlaidLinkTokenGetRaw();
    return await response.value();
  }

  /**
   * Exchanges the public token for an access token
   */
  async apiV10PlaidResetAccessTokenGetRaw(): Promise<runtime.ApiResponse<StringApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Plaid/resetAccessToken`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
  }

  /**
   * Exchanges the public token for an access token
   */
  async apiV10PlaidResetAccessTokenGet(): Promise<StringApiResponse> {
    const response = await this.apiV10PlaidResetAccessTokenGetRaw();
    return await response.value();
  }

  /**
   * Gets a sandbox public_token
   */
  async apiV10PlaidSandboxPublicTokenGetRaw(): Promise<runtime.ApiResponse<StringApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Plaid/sandboxPublicToken`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets a sandbox public_token
   */
  async apiV10PlaidSandboxPublicTokenGet(): Promise<StringApiResponse> {
    const response = await this.apiV10PlaidSandboxPublicTokenGetRaw();
    return await response.value();
  }
}
