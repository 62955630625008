/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ZoneEnum {
  _01Atlanta = "Zone 01 - Atlanta",
  _02BaltimoreWashingtonEastern = "Zone 02 - Baltimore/Washington (Eastern)",
  _03Boston = "Zone 03 - Boston",
  _04Buffalo = "Zone 04 - Buffalo",
  _05Charlotte = "Zone 05 - Charlotte",
  _06Chicago = "Zone 06 - Chicago",
  _07CincinnatiMideast = "Zone 07 - Cincinnati (Mideast)",
  _08Cleveland = "Zone 08 - Cleveland",
  _09DallasFortWorth = "Zone 09 - Dallas Fort Worth",
  _10Denver = "Zone 10 - Denver",
  _11Detroit = "Zone 11 - Detroit",
  _12Hartford = "Zone 12 - Hartford",
  _13Houston = "Zone 13 - Houston",
  _14Indianapolis = "Zone 14 - Indianapolis",
  _15Jacksonville = "Zone 15 - Jacksonville",
  _16KansasCity = "Zone 16 - Kansas City",
  _17LittleRock = "Zone 17 - Little Rock",
  _18LosAngeles = "Zone 18 - Los Angeles",
  _19LouisvilleMideast = "Zone 19 - Louisville (Mideast)",
  _20Memphis = "Zone 20 - Memphis",
  _21Miami = "Zone 21 - Miami",
  _22Milwaukee = "Zone 22 - Milwaukee",
  _23MinneapolisStPaul = "Zone 23 - Minneapolis St. Paul",
  _24Nashville = "Zone 24 - Nashville",
  _25NewOrleans = "Zone 25 - New Orleans",
  _26NewYorkCityEastern = "Zone 26 - New York City (Eastern)",
  _27OklahomaCity = "Zone 27 - Oklahoma City",
  _28Omaha = "Zone 28 - Omaha",
  _29Phoenix = "Zone 29 - Phoenix",
  _30Philadelphia = "Zone 30 - Philadelphia",
  _31Pittsburgh = "Zone 31 - Pittsburgh",
  _32Portland = "Zone 32 - Portland",
  _33Richmond = "Zone 33 - Richmond",
  _34StLouisMidwest = "Zone 34 - St. Louis (Midwest)",
  _35SaltLakeCity = "Zone 35 - Salt Lake City",
  _36SanFrancisco = "Zone 36 - San Francisco",
  _37Tulsa = "Zone 37 - Tulsa",
  _40Pacific = "Zone 40 - Pacific",
  _41Mountain = "Zone 41 - Mountain",
  _42Midwest = "Zone 42 - Midwest",
  _43Southwest = "Zone 43 - Southwest",
  _44NorthCentral = "Zone 44 - North Central",
  _45Mideast = "Zone 45 - Mideast",
  _46Gulf = "Zone 46 - Gulf",
  _47Southeast = "Zone 47 - Southeast",
  _48Eastern = "Zone 48 - Eastern",
  _49NewEngland = "Zone 49 - New England",
  _50Alaska = "Zone 50 - Alaska",
}

export function ZoneEnumFromJSON(json: any): ZoneEnum {
  return ZoneEnumFromJSONTyped(json, false);
}

export function ZoneEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneEnum {
  return json as ZoneEnum;
}

export function ZoneEnumToJSON(value?: ZoneEnum | null): any {
  return value as any;
}
