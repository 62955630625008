import {
  EditableDeletableCard,
  ExpandableTextLabel,
  RadioInput,
  SliderInput,
  TooltipHelp,
  YesNoInput,
  YesNoValues,
} from "@chq/components";
import { Divider, Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useTrailerCoverages } from "../data/useTrailerCoverages";

const useStyles = makeStyles((theme: Theme) => ({
  secondaryQuestion: {
    [theme.breakpoints.up("xs")]: {
      marginLeft: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "1rem",
      width: "16rem",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "2.625rem",
      width: "17rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "2.625rem",
      width: "17rem",
    },
    "& .MuiInputLabel-outlined.Mui-required": {
      height: "1.25rem",
    },
  },
  formContainer: {
    paddingTop: "1.25rem",
  },
  yesNoInput: {
    "& .MuiFormGroup-root": {
      marginTop: "0.75rem",
      marginBottom: "0.75rem",
      "& .MuiFormGroup-row": {
        marginTop: ".5rem",
        marginBottom: ".5rem",
      },
    },
  },
  "& .MuiFormGroup-root": {
    marginTop: "0.75rem",
    "& .MuiFormGroup-row": {
      marginTop: ".5rem",
      marginBottom: ".5rem",
    },
  },
  "& .MuiFormGroup-row": {
    marginTop: ".5rem",
    marginBottom: ".5rem",
  },
  secondaryInput: {
    marginTop: "1.75rem",
    "& .MuiFormGroup-root": {
      marginTop: "0.75rem",
      marginBottom: ".75rem",
    },
  },
  formElements: {
    alignContent: "center",
    [theme.breakpoints.up("xs")]: {
      paddingTop: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "0rem",
    },
  },
  title: {
    marginBottom: "1rem",
  },
  titleContainer: {
    paddingTop: "1.25rem",
  },
  sectionTop: {
    marginTop: "1rem",
  },
  secondQuestion: {
    paddingBottom: "0rem",
  },
  slider: {
    padding: "1rem 0rem 2rem 0rem",
    "& .MuiSlider-root": {
      margin: "0rem 4rem 0rem 1rem",
    },
  },
  buttonSection: {
    marginTop: "2rem",
  },
  radioClass: {
    width: "fit-content",
  },
  spacing: {
    [theme.breakpoints.only("sm")]: {
      marginRight: "0.5rem",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "1rem",
    },
  },
  bigRadioLabel: {
    transform: "translate(0rem, -1.75rem)",
  },
  bodilyInjuryRadioLabel: {
    [theme.breakpoints.up("xs")]: {
      transform: "translate(0rem, -2.75rem)",
    },
    [theme.breakpoints.up("md")]: {
      transform: "translate(0rem, -1.75rem)",
    },
  },
}));

export enum Fields {
  propertyDamageBodilyInjuryLimit = "property-damage-bodily-injury",
  trailerInterchange = "trailer-interchange",
  trailerCoverageLimit = "trailer-coverage-limit",
  hiredAuto = "hired-auto",
  uninsuredMotorist = "uninsured-motorist",
  cargoInsurance = "cargo-insurance",
  cargoLimit = "cargo-limit",
  cargoDeductible = "cargo-deductible",
  reeferBreakdown = "reefer-breakdown",
}

export type AddCoverageFormProps = {
  [Fields.trailerInterchange]: string;
  [Fields.trailerCoverageLimit]: number | undefined;
  [Fields.propertyDamageBodilyInjuryLimit]: string | undefined;
  [Fields.hiredAuto]: string;
  [Fields.uninsuredMotorist]: string;
  [Fields.cargoInsurance]: string;
  [Fields.cargoLimit]: string | undefined;
  [Fields.cargoDeductible]: string | undefined;
  [Fields.reeferBreakdown]: string | undefined;
};

type Props = {
  trailerInterchange?: string;
  trailerCoverageLimit?: number;
  propertyDamageBodilyInjuryLimit?: string;
  hiredAuto?: string;
  uninsuredMotorist?: string;
  cargoInsurance?: string;
  cargoLimit?: string;
  cargoDeductible?: string;
  reeferBreakdown?: string;
  onSubmit?: FormikConfig<AddCoverageFormProps>["onSubmit"];
  title?: string;
  containerClass?: string;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.trailerInterchange]: Yup.string().required(
      t(`errors.required`, { field: t(`coverage-form.${Fields.trailerInterchange}.label`) }),
    ),
    [Fields.trailerCoverageLimit]: Yup.number().when(Fields.trailerInterchange, {
      is: YesNoValues.yes,
      then: Yup.number().required(
        t(`errors.required`, { field: t(`coverage-form.${Fields.trailerCoverageLimit}.label`) }),
      ),
      otherwise: Yup.number(),
    }),
    [Fields.propertyDamageBodilyInjuryLimit]: Yup.string().required(
      t(`errors.required`, { field: t(`coverage-form.${Fields.propertyDamageBodilyInjuryLimit}.label`) }),
    ),
    [Fields.hiredAuto]: Yup.string().required(
      t(`errors.required`, { field: t(`coverage-form.${Fields.hiredAuto}.label`) }),
    ),
    [Fields.uninsuredMotorist]: Yup.string().required(
      t(`errors.required`, { field: t(`coverage-form.${Fields.uninsuredMotorist}.label`) }),
    ),
    [Fields.cargoInsurance]: Yup.string().required(
      t(`errors.required`, { field: t(`coverage-form.${Fields.cargoInsurance}.label`) }),
    ),
    [Fields.cargoLimit]: Yup.string().when(Fields.cargoInsurance, {
      is: YesNoValues.yes,
      then: Yup.string().required(t(`errors.required`, { field: t(`coverage-form.${Fields.cargoLimit}.label`) })),
    }),
    [Fields.cargoDeductible]: Yup.string().when(Fields.cargoInsurance, {
      is: YesNoValues.yes,
      then: Yup.string().required(t(`errors.required`, { field: t(`coverage-form.${Fields.cargoDeductible}.label`) })),
    }),
    [Fields.reeferBreakdown]: Yup.string().when(Fields.cargoInsurance, {
      is: YesNoValues.yes,
      then: Yup.string().required(t(`errors.required`, { field: t(`coverage-form.${Fields.reeferBreakdown}.label`) })),
    }),
  });
};

export const useFormikConfig = ({
  trailerInterchange: initialTrailerInterchange = "",
  trailerCoverageLimit: initialTrailerCoverageLimit = 0,
  propertyDamageBodilyInjuryLimit: initialPropertyDamageBodilyInjuryLimit = "",
  hiredAuto: initialHiredAuto = "",
  uninsuredMotorist: initialUninsuredMotorist = "",
  cargoInsurance: initialCargoInsurance = "",
  cargoLimit: initialCargoLimit = "",
  cargoDeductible: initialCargoDeductible = "",
  reeferBreakdown: initialReeferBreakdown = "",
}: Props = {}): Omit<FormikConfig<AddCoverageFormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.trailerInterchange]: initialTrailerInterchange,
      [Fields.trailerCoverageLimit]: initialTrailerCoverageLimit,
      [Fields.propertyDamageBodilyInjuryLimit]: initialPropertyDamageBodilyInjuryLimit,
      [Fields.hiredAuto]: initialHiredAuto,
      [Fields.uninsuredMotorist]: initialUninsuredMotorist,
      [Fields.cargoInsurance]: initialCargoInsurance,
      [Fields.cargoLimit]: initialCargoLimit,
      [Fields.cargoDeductible]: initialCargoDeductible,
      [Fields.reeferBreakdown]: initialReeferBreakdown,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const AddCoverageForm: React.FC<Props> = ({ title, containerClass }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const trailerCoverages = useTrailerCoverages();
  const minTrailerCoverage = trailerCoverages[0];
  const maxTrailerCoverage = trailerCoverages[trailerCoverages.length - 1];
  const formik = useFormikContext<AddCoverageFormProps>();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <EditableDeletableCard
      variant="default"
      border={false}
      title={title}
      className={classNames(containerClass, { [classes.titleContainer]: title !== undefined })}
      titleVariant="h4"
      elevation={1}
    >
      <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
        <Grid container className={classes.formElements} justify="space-around" spacing={1}>
          <Grid container item xs={12} sm={6} alignContent="flex-start">
            <Grid container item>
              <Grid item>
                <RadioInput
                  id={Fields.propertyDamageBodilyInjuryLimit}
                  label={t("coverage-form.property-damage-bodily-injury.label")}
                  InputLabelProps={{
                    className: classes.bodilyInjuryRadioLabel,
                  }}
                  name={Fields.propertyDamageBodilyInjuryLimit}
                  labelAdornment={
                    <TooltipHelp
                      title={t("coverage-form.property-damage-bodily-injury.help-number").toString()}
                      placement="left-end"
                    />
                  }
                  labelEmbellishment={
                    <ExpandableTextLabel
                      id="property-damage-bodily-injury-label-text"
                      text={t("coverage-form.property-damage-bodily-injury.label-embellish")}
                      LessIconProps={{
                        titleAccess: t("coverage-form.property-damage-bodily-injury.title-access-less"),
                      }}
                      MoreIconProps={{
                        titleAccess: t("coverage-form.property-damage-bodily-injury.title-access-more"),
                      }}
                    />
                  }
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[Fields.propertyDamageBodilyInjuryLimit]}
                  error={
                    formik.touched[Fields.propertyDamageBodilyInjuryLimit] &&
                    Boolean(formik.errors[Fields.propertyDamageBodilyInjuryLimit])
                  }
                  choices={[
                    {
                      label: t("coverage-form.property-damage-bodily-injury.750,000", { money: 750000 }),
                      value: "750000",
                    },
                    {
                      label: t("coverage-form.property-damage-bodily-injury.1,000,000", { money: 1000000 }),
                      value: "1000000",
                    },
                  ]}
                  className={classes.yesNoInput}
                  radioClass={classes.radioClass}
                  helperText={
                    formik.touched[Fields.propertyDamageBodilyInjuryLimit] &&
                    formik.errors[Fields.propertyDamageBodilyInjuryLimit]
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item className={classNames(classes.sectionTop)}>
                <YesNoInput
                  className={classes.yesNoInput}
                  id={Fields.trailerInterchange}
                  label={t("coverage-form.trailer-interchange.label")}
                  labelEmbellishment={
                    <ExpandableTextLabel
                      id="trailer-interchage-label-text"
                      text={t("coverage-form.trailer-interchange.label-embellish")}
                      LessIconProps={{ titleAccess: t("coverage-form.trailer-interchange.title-access-less") }}
                      MoreIconProps={{ titleAccess: t("coverage-form.trailer-interchange.title-access-more") }}
                    />
                  }
                  name={Fields.trailerInterchange}
                  yesText={t("common.yes")}
                  noText={t("common.no")}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[Fields.trailerInterchange]}
                  error={formik.touched[Fields.trailerInterchange] && Boolean(formik.errors[Fields.trailerInterchange])}
                  radioClass={classes.radioClass}
                  helperText={formik.touched[Fields.trailerInterchange] && formik.errors[Fields.trailerInterchange]}
                />
              </Grid>
              {formik.values[Fields.trailerInterchange] === YesNoValues.yes && (
                <Grid item xs={10}>
                  <SliderInput
                    id={Fields.trailerCoverageLimit}
                    name={Fields.trailerCoverageLimit}
                    label={t("coverage-form.trailer-coverage-limit.label")}
                    required
                    fullWidth
                    min={minTrailerCoverage}
                    max={maxTrailerCoverage}
                    step={5000}
                    marks={trailerCoverages.map((value) => ({
                      label: t("coverage-form.trailer-coverage-limit.value-label", { money: value / 1000 }),
                      value,
                    }))}
                    onChangeCommitted={(_e, value) => formik.setFieldValue(Fields.trailerCoverageLimit, value)}
                    onBlur={() => formik.setFieldTouched(Fields.trailerCoverageLimit)}
                    value={formik.values[Fields.trailerCoverageLimit] || minTrailerCoverage}
                    error={
                      formik.touched[Fields.trailerCoverageLimit] && Boolean(formik.errors[Fields.trailerCoverageLimit])
                    }
                    className={classes.slider}
                    helperText={
                      formik.touched[Fields.trailerCoverageLimit] && formik.errors[Fields.trailerCoverageLimit]
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item className={classNames(classes.sectionTop)}>
              <YesNoInput
                className={classes.yesNoInput}
                id={Fields.hiredAuto}
                label={t("coverage-form.hired-auto.label")}
                name={Fields.hiredAuto}
                labelEmbellishment={
                  <ExpandableTextLabel
                    id="hired-auto-label-text"
                    text={t("coverage-form.hired-auto.label-embellish")}
                    LessIconProps={{ titleAccess: t("coverage-form.hired-auto.title-access-less") }}
                    MoreIconProps={{ titleAccess: t("coverage-form.hired-auto.title-access-more") }}
                  />
                }
                yesText={t("common.yes")}
                noText={t("common.no")}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[Fields.hiredAuto]}
                error={formik.touched[Fields.hiredAuto] && Boolean(formik.errors[Fields.hiredAuto])}
                radioClass={classes.radioClass}
                helperText={formik.touched[Fields.hiredAuto] && formik.errors[Fields.hiredAuto]}
              />
            </Grid>
            {isExtraSmall && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} sm={6} alignContent="flex-start">
            <Grid container item className={classNames({ [classes.sectionTop]: isExtraSmall })}>
              <Grid item>
                <YesNoInput
                  className={classes.yesNoInput}
                  id={Fields.uninsuredMotorist}
                  label={t("coverage-form.uninsured-motorist.label")}
                  name={Fields.uninsuredMotorist}
                  InputLabelProps={{
                    className: classes.bigRadioLabel,
                  }}
                  labelEmbellishment={
                    <ExpandableTextLabel
                      id="uninsured-motorist-label-text"
                      text={t("coverage-form.uninsured-motorist.label-embellish")}
                      LessIconProps={{ titleAccess: t("coverage-form.uninsured-motorist.title-access-less") }}
                      MoreIconProps={{ titleAccess: t("coverage-form.uninsured-motorist.title-access-more") }}
                    />
                  }
                  yesText={t("common.yes")}
                  noText={t("common.no")}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[Fields.uninsuredMotorist]}
                  error={formik.touched[Fields.uninsuredMotorist] && Boolean(formik.errors[Fields.uninsuredMotorist])}
                  helperText={formik.touched[Fields.uninsuredMotorist] && formik.errors[Fields.uninsuredMotorist]}
                  radioClass={classes.radioClass}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container className={classNames(classes.sectionTop)}>
              <Grid item>
                <YesNoInput
                  className={classes.yesNoInput}
                  id={Fields.cargoInsurance}
                  label={t("coverage-form.cargo-insurance.label")}
                  name={Fields.cargoInsurance}
                  InputLabelProps={{
                    className: classes.bigRadioLabel,
                  }}
                  labelEmbellishment={
                    <ExpandableTextLabel
                      id="cargo-insurance-label-text"
                      text={t("coverage-form.cargo-insurance.label-embellish")}
                      LessIconProps={{ titleAccess: t("coverage-form.cargo-insurance.title-access-less") }}
                      MoreIconProps={{ titleAccess: t("coverage-form.cargo-insurance.title-access-more") }}
                    />
                  }
                  yesText={t("common.yes")}
                  noText={t("common.no")}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[Fields.cargoInsurance]}
                  error={formik.touched[Fields.cargoInsurance] && Boolean(formik.errors[Fields.cargoInsurance])}
                  helperText={formik.touched[Fields.cargoInsurance] && formik.errors[Fields.cargoInsurance]}
                  radioClass={classes.radioClass}
                />
              </Grid>
              {formik.values[Fields.cargoInsurance] === YesNoValues.yes && (
                <>
                  <Grid item xs={12}>
                    <RadioInput
                      id={Fields.cargoLimit}
                      label={t("coverage-form.cargo-limit.label")}
                      InputLabelProps={{
                        className: classes.bigRadioLabel,
                      }}
                      name={Fields.cargoLimit}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values[Fields.cargoLimit]}
                      error={formik.touched[Fields.cargoLimit] && Boolean(formik.errors[Fields.cargoLimit])}
                      choices={[
                        { label: t("coverage-form.cargo-limit.choices.one", { money: 100000 }), value: "100000" },
                        { label: t("coverage-form.cargo-limit.choices.two", { money: 250000 }), value: "250000" },
                      ]}
                      className={classNames(classes.secondaryQuestion, classes.secondaryInput)}
                      helperText={formik.touched[Fields.cargoLimit] && formik.errors[Fields.cargoLimit]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioInput
                      id={Fields.cargoDeductible}
                      label={t("coverage-form.cargo-deductible.label")}
                      InputLabelProps={{
                        className: classes.bigRadioLabel,
                      }}
                      name={Fields.cargoDeductible}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values[Fields.cargoDeductible]}
                      error={formik.touched[Fields.cargoDeductible] && Boolean(formik.errors[Fields.cargoDeductible])}
                      choices={[
                        {
                          label: t("coverage-form.cargo-deductible.choices.one", { money: 2500 }),
                          value: "2500",
                        },
                        {
                          label: t("coverage-form.cargo-deductible.choices.two", { money: 5000 }),
                          value: "5000",
                        },
                      ]}
                      className={classNames(classes.secondaryQuestion, classes.secondaryInput)}
                      helperText={formik.touched[Fields.cargoDeductible] && formik.errors[Fields.cargoDeductible]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <YesNoInput
                      id={Fields.reeferBreakdown}
                      label={t("coverage-form.reefer-breakdown.label")}
                      name={Fields.reeferBreakdown}
                      yesText={t("common.yes")}
                      noText={t("common.no")}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        className: classes.bigRadioLabel,
                      }}
                      value={formik.values[Fields.reeferBreakdown]}
                      error={formik.touched[Fields.reeferBreakdown] && Boolean(formik.errors[Fields.reeferBreakdown])}
                      className={classNames(classes.secondaryQuestion, classes.secondaryInput)}
                      helperText={formik.touched[Fields.reeferBreakdown] && formik.errors[Fields.reeferBreakdown]}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </EditableDeletableCard>
  );
};

export default AddCoverageForm;
