import { EditableDeletableCard, NumericInput, TooltipHelp } from "@chq/components";
import { State } from "@chq/enrollment-api";
import { makeStyles, Theme } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.125rem 0.5rem 0.375rem",
    "&>.MuiGrid-root": {
      paddingBottom: "0rem",
    },
  },
}));

export enum Fields {
  garagingState = "garaging-state",
  stateSpecificField = "state-specific-field",
}

export type StateSpecificFieldFormProps = {
  [Fields.garagingState]?: State;
  [Fields.stateSpecificField]: string;
};

type Props = {
  garagingState?: State;
  stateSpecificField?: string;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.stateSpecificField]: Yup.string().when(Fields.garagingState, {
      is: (val: State) => specialGaragingStates.includes(val),
      then: Yup.string().required(t("errors.generic-required")),
      otherwise: Yup.string(),
    }),
  });
};

export const useFormikConfig = ({ stateSpecificField = "", garagingState = undefined }: Props = {}): Omit<
  FormikConfig<StateSpecificFieldFormProps>,
  "onSubmit"
> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.garagingState]: garagingState,
      [Fields.stateSpecificField]: stateSpecificField,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnMount: true,
  };
};

export const specialGaragingStates = [
  State.CA,
  State.NM,
  State.OH,
  State.OK,
  State.PA,
  State.TN,
  State.VA,
  State.TX,
  State.WI,
];

const GaragingForm: React.FC<Props> = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [stateTranslation] = useTranslation("regions");
  const formik = useFormikContext<StateSpecificFieldFormProps>();

  return (
    <form onSubmit={formik.handleSubmit}>
      <EditableDeletableCard
        title={stateTranslation(`regions.US.${formik.values[Fields.garagingState]}`)}
        variant="default"
        titleVariant="h2"
        className={classes.container}
      >
        <NumericInput
          fullWidth
          id="email"
          name={Fields.stateSpecificField}
          label={t(`review-application-page.garaging-card.labels.${formik.values[Fields.garagingState]}`)}
          labelAdornment={
            <TooltipHelp
              title={
                formik.values[Fields.garagingState] === State.CA
                  ? t("review-application-page.garaging-card.tooltip-text.CA").toString()
                  : t("review-application-page.garaging-card.tooltip-text.generic").toString()
              }
              placement="left-end"
            />
          }
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[Fields.stateSpecificField]}
          error={formik.touched[Fields.stateSpecificField] && Boolean(formik.errors[Fields.stateSpecificField])}
          helperText={formik.touched[Fields.stateSpecificField] && formik.errors[Fields.stateSpecificField]}
        />
      </EditableDeletableCard>
    </form>
  );
};

export default GaragingForm;
