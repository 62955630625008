import DateFnsUtils from "@date-io/date-fns";
import { OutlinedInput, OutlinedInputProps } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import { parseISO } from "date-fns";
import dateFnsFormat from "date-fns/format";
import React from "react";
import { isDesktop, isMobile } from "react-device-detect";
import FormControl, { FormControlProps } from "./form-control";
import { TextInputProps } from "./text-input";
import { DATE_FORMAT } from "./utils/date-format";
export type SelectItem = {
  name: string;
  value: string | number;
};

type Props = Omit<TextInputProps, "select" | "SelectProps" | "onChange"> &
  Omit<FormControlProps, "children" | "labelId" | "helperTextId" | "onChange"> &
  Omit<KeyboardDatePickerProps, "margin" | "format" | "inputVariant"> &
  Omit<OutlinedInputProps, "margin" | "onChange"> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (date: any) => void;
    otherEmployeeForm?: boolean;
  };

const BirthdatePicker: React.FC<Props> = ({
  classes,
  className,
  color = "primary",
  disabled = false,
  error = false,
  FormHelperTextProps,
  fullWidth = false,
  focused,
  helperText,
  hiddenLabel,
  id,
  InputLabelProps,
  label,
  labelAdornment,
  labelEmbellishment,
  margin = "normal",
  required = false,
  size,
  variant,
  onClick,
  onBlur,
  onChange,
  name,
  value,
  inputRef,
  ...KeyboardDatePickerProps
}) => {
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  return (
    <FormControl
      className={classNames(classes?.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      focused={focused}
      hiddenLabel={hiddenLabel}
      required={required}
      color={color}
      FormHelperTextProps={FormHelperTextProps}
      helperTextId={helperTextId}
      helperText={helperText}
      labelId={inputLabelId}
      label={label}
      labelAdornment={labelAdornment}
      labelEmbellishment={labelEmbellishment}
      margin={margin}
      id={id}
      InputLabelProps={InputLabelProps}
      size={size}
      variant={variant}
    >
      {isMobile && (
        <OutlinedInput
          aria-describedby={helperTextId}
          name={name}
          type="date"
          id={id}
          onClick={onClick}
          onBlur={onBlur}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (e.target.value) {
              onChange && onChange(parseISO(e.target.value));
            }
          }}
          value={value ? dateFnsFormat(value as Date, "yyyy-MM-dd") : null}
          inputRef={inputRef}
        />
      )}
      {isDesktop && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="none"
            id={id}
            disabled={disabled}
            error={error}
            fullWidth={fullWidth}
            focused={focused}
            hiddenLabel={true}
            required={required}
            color={color}
            size={size}
            variant={variant}
            onChange={onChange}
            value={value}
            format={DATE_FORMAT}
            inputVariant={variant}
            {...KeyboardDatePickerProps}
            helperText={null}
            placeholder="mm/dd/yyyy"
            disableFuture
          />
        </MuiPickersUtilsProvider>
      )}
    </FormControl>
  );
};

export default BirthdatePicker;
