import { useAuth } from "@chq/authentication";
import { OwnerBankAccountInfo, PolicyApi } from "@chq/policy-management-api";
import { useQuery, UseQueryResult } from "react-query";
import { useApi } from "./usePolicyManagementApi";

export const policyCacheKey = (identity?: string) => {
  return ["ownerBankAccountInfo", identity];
};

export const useGetOwnerBankAccountInfo = (): UseQueryResult<OwnerBankAccountInfo, Error> => {
  const policyApi = useApi(PolicyApi);
  const { user } = useAuth();
  const query = useQuery<OwnerBankAccountInfo, Error>(policyCacheKey(user?.sub), async () => {
    const response = await policyApi.portalApiV10PolicyGetOwnerBankAccountInfoGet({
      identityId: user?.sub,
    });
    if (response.error) {
      throw new Error("Owner bank account info not found: " + response.error?.message);
    }
    return response.data!;
  });
  return query;
};
