import { booleanToYesNoString, CardField } from "@chq/components";
import { Equipment } from "@chq/policy-management-api";
import { createStyles, Grid, lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Fields as EquipmentFields } from "./equipment-form";

const useStyles = makeStyles((theme) =>
  createStyles({
    info: {
      paddingBottom: "1rem",
      paddingTop: "1rem",
      borderBottom: `solid 1px ${lighten(theme.palette.secondary.main, 0.52)}`,
    },
    form: {
      paddingTop: "1rem",
      width: "100%",
    },
    iconButton: {
      padding: "0px",
    },
    compCollisionContainer: {
      paddingTop: "0.5rem",
    },
    compCollisionValue: {
      alignSelf: "flex-end",
    },
  }),
);

export type Props = {
  equipment: Equipment;
  equipmentIndex?: number;
  editedFields?: string[];
};

const EquipmentInfo: React.FC<Props> = ({ equipment, equipmentIndex = 0, editedFields = [] }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.info}>
        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-vin-${equipmentIndex}`}
          label={t("power-units-card.vin")}
          values={[
            { value: equipment.vin, valueColor: editedFields.includes(EquipmentFields.vin) ? "primary" : undefined },
          ]}
          labelColor={editedFields.includes(EquipmentFields.vin) ? "primary" : undefined}
        />
        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-make-${equipmentIndex}`}
          label={t("power-units-card.make")}
          values={[
            { value: equipment.make, valueColor: editedFields.includes(EquipmentFields.make) ? "primary" : undefined },
          ]}
          labelColor={editedFields.includes(EquipmentFields.make) ? "primary" : undefined}
        />
        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-model-${equipmentIndex}`}
          label={t("power-units-card.model")}
          values={[
            {
              value: equipment.model,
              valueColor: editedFields.includes(EquipmentFields.model) ? "primary" : undefined,
            },
          ]}
          labelColor={editedFields.includes(EquipmentFields.model) ? "primary" : undefined}
        />
        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-year-${equipmentIndex}`}
          label={t("power-units-card.year")}
          values={[
            { value: equipment.year, valueColor: editedFields.includes(EquipmentFields.year) ? "primary" : undefined },
          ]}
          labelColor={editedFields.includes(EquipmentFields.year) ? "primary" : undefined}
        />

        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-registrationZip-${equipmentIndex}`}
          label={t("power-units-card.registration-zip")}
          values={[
            {
              value: equipment.registrationZip,
              valueColor: editedFields.includes(EquipmentFields.registrationZip) ? "primary" : undefined,
            },
          ]}
          labelColor={editedFields.includes(EquipmentFields.registrationZip) ? "primary" : undefined}
        />
        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-destination-${equipmentIndex}`}
          label={t("power-units-card.destination")}
          values={[
            {
              value: t(`destination-type.${equipment.destinationZone?.replace(".", "")}`),
              valueColor: editedFields.includes(EquipmentFields.destination) ? "primary" : undefined,
            },
          ]}
          labelColor={editedFields.includes(EquipmentFields.destination) ? "primary" : undefined}
        />
        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-ooIC-${equipmentIndex}`}
          label={t("power-units-card.oo-or-ic")}
          values={[
            {
              value: t(booleanToYesNoString(equipment.ownerOperator)),
              valueColor: editedFields.includes(EquipmentFields.vin)
                ? equipment.ownerOperator
                  ? "primary"
                  : "error"
                : undefined,
            },
          ]}
          labelColor={editedFields.includes(EquipmentFields.ooIC) ? "primary" : undefined}
        />
      </Grid>
      <Grid>
        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-comp-collision-coverage-${equipmentIndex}`}
          label={t("policy-management-page.equipment-page.equipment-card.comp-collision-coverage")}
          values={[
            {
              value: equipment.compAndCollisionCoverage,
              valueColor: editedFields.includes(EquipmentFields.coverageOption) ? "primary" : undefined,
            },
          ]}
          className={classes.compCollisionContainer}
          labelColor={editedFields.includes(EquipmentFields.coverageOption) ? "primary" : undefined}
          valueClass={classes.compCollisionValue}
        />
        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-stated-value-${equipmentIndex}`}
          label={t("policy-management-page.equipment-page.equipment-card.stated-value")}
          values={[
            {
              value: equipment.statedValue,
              valueColor: editedFields.includes(EquipmentFields.statedValue) ? "primary" : undefined,
            },
          ]}
          labelColor={editedFields.includes(EquipmentFields.statedValue) ? "primary" : undefined}
        />
        <CardField
          id={`${_.kebabCase(equipment.equipmentType)}-loan-lease-gap-${equipmentIndex}`}
          label={t("policy-management-page.equipment-page.equipment-card.loan-lease-coverage")}
          values={[
            {
              value: t(booleanToYesNoString(equipment.loanLeaseGapCoverage)),
              valueColor: editedFields.includes(EquipmentFields.gapCoverage)
                ? equipment.loanLeaseGapCoverage
                  ? "primary"
                  : "error"
                : undefined,
            },
          ]}
          labelColor={editedFields.includes(EquipmentFields.gapCoverage) ? "primary" : undefined}
        />
      </Grid>
    </>
  );
};

export default EquipmentInfo;
