import { useAccessToken, useAuth } from "@chq/authentication";
import { BaseAPI, Configuration, HTTPHeaders, ResponseContext } from "@chq/factoring-api";
import { useMemo } from "react";

type ApiConstructor<T extends BaseAPI> = new (config: Configuration) => T;

export function useFactoringApi<T extends BaseAPI>(api: ApiConstructor<T>) {
  const { logout } = useAuth();
  const token = useAccessToken();

  const headers = useMemo((): HTTPHeaders => {
    return token?.accessToken !== undefined ? { Authorization: `Bearer ${token.accessToken}` } : {};
  }, [token?.accessToken]);

  return new api(
    new Configuration({
      basePath: window.origin,
      headers,
      middleware: [
        {
          async post(context: ResponseContext): Promise<Response | void> {
            if (context.response.status === 401) {
              logout();
            }
          },
        },
      ],
    }),
  );
}
