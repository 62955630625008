import { ApiV10StripeCreateBankAccountPostRequest, StripeApi, StripeBankAccountApiResponse } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useSendBankInfoStripe = (): UseMutationResult<
  StripeBankAccountApiResponse,
  Error | unknown,
  ApiV10StripeCreateBankAccountPostRequest
> => {
  const stripeApi = useApi(StripeApi);
  const mutation = useMutation(async (request: ApiV10StripeCreateBankAccountPostRequest) => {
    try {
      await stripeApi.apiV10StripeCreateCustomerPost();
      const response = await stripeApi.apiV10StripeCreateBankAccountPost(request);

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.json) {
        const response = error as Response;
        const json: StripeBankAccountApiResponse = await response.json();

        throw new Error(json.error?.message || "Error connecting your bank account");
      } else {
        throw error;
      }
    }
  });

  return mutation;
};
