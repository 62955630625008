import { ApiV10ApplicationSendHelpEmailPostRequest, ApplicationApi, BooleanApiResponse } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useSendHelpEmailRequest = (): UseMutationResult<
  BooleanApiResponse,
  Error | unknown,
  ApiV10ApplicationSendHelpEmailPostRequest
> => {
  const applicationApi = useApi(ApplicationApi);
  const mutation = useMutation(async (request: ApiV10ApplicationSendHelpEmailPostRequest) => {
    const response = await applicationApi.apiV10ApplicationSendHelpEmailPost(request);

    return response;
  });
  return mutation;
};
