import { AlertType, PageAlert, PencilIcon as EditIcon } from "@chq/components";
import { EquipmentType } from "@chq/policy-management-api";
import { Button, CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CoveragesCard from "../../../components/policy-management-components/coverages-card";
import { PolicyCoveragesContext } from "../../../data/policy-management/PolicyManagementCoveragesContext";
import { PolicyEquipmentContext } from "../../../data/policy-management/PolicyManagementEquipmentContext";
import { useGetPolicy } from "../../../data/policy-management/useGetPolicy";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "0rem 0.75rem",
  },
  manageButton: {
    marginTop: "2rem",
    marginBottom: "1.125rem",
    "& .MuiButton-label": {
      ...theme.typography.h4,
      color: theme.palette.common.white,
    },
  },
  editIcon: {
    height: "30px",
    width: "auto",
  },
}));

const CoveragesPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const { data, isLoading, isError, error } = useGetPolicy();
  const { updatePolicyCoverages } = useContext(PolicyCoveragesContext);
  const { updatePolicyEquipment } = useContext(PolicyEquipmentContext);

  const firstEquipment = (data?.equipment || [])[0];
  const cargoInsurance = firstEquipment?.cargoInsurance;
  const cargoInsuranceLimit = firstEquipment?.cargoInsuranceLimit;
  const cargoInsuranceDeductible = firstEquipment?.cargoInsuranceDeductible;
  const cargoInsuranceReeferBreakdown = firstEquipment?.cargoInsuranceReeferBreakdown;

  const powerUnitsList = useMemo(() => {
    return data
      ? data?.equipment
          ?.filter((item) => item.equipmentType === EquipmentType.PowerUnit)
          .map((powerUnit) => {
            return { equipment: powerUnit, isEditing: false, editedFields: [], isNew: false, isDeleted: false };
          })
      : [];
  }, [data]);

  const trailersList = useMemo(() => {
    return data
      ? data?.equipment
          ?.filter((item) => item.equipmentType === EquipmentType.Trailer)
          .map((trailer) => {
            return { equipment: trailer, isEditing: false, editedFields: [], isNew: false, isDeleted: false };
          })
      : [];
  }, [data]);

  return (
    <>
      {isError && error && <PageAlert alertType={AlertType.error} message={error.message} />}
      <Grid item container direction="column" justify="center" className={classes.container} alignItems="center">
        {isLoading ? (
          <Grid item>
            <CircularProgress color="primary" size="2.5rem" aria-label={t("common.circular-progress-aria-label")} />
          </Grid>
        ) : (
          <>
            <Button
              variant="contained"
              disableElevation
              startIcon={<EditIcon className={classes.editIcon} />}
              onClick={() => {
                updatePolicyCoverages({
                  coverages: data
                    ? {
                        ...data.coverages,
                        cargoInsurance,
                        cargoInsuranceLimit,
                        cargoInsuranceDeductible,
                        cargoInsuranceReeferBreakdown,
                      }
                    : {},
                  editedFields: [],
                });
                updatePolicyEquipment({ powerUnits: powerUnitsList!, trailers: trailersList! });
                history.push(routes.policyManagement.coverages.edit.path);
              }}
              className={classes.manageButton}
              disabled={isError}
            >
              {t("policy-management-page.coverages-page.update-coverages-and-equipment")}
            </Button>
            <CoveragesCard
              coverages={data?.coverages}
              cargoInsurance={cargoInsurance}
              cargoInsuranceLimit={cargoInsuranceLimit}
              cargoInsuranceDeductible={cargoInsuranceDeductible}
              cargoInsuranceReeferBreakdown={cargoInsuranceReeferBreakdown}
            />
          </>
        )}
      </Grid>
    </>
  );
};

export default CoveragesPage;
