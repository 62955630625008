import { AddressApi, ApiV10AddressCheckAddressAccuracyPostRequest } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useAddressValidation = (): UseMutationResult<
  boolean,
  Error | unknown,
  ApiV10AddressCheckAddressAccuracyPostRequest
> => {
  const addressApi = useApi(AddressApi);
  const mutation = useMutation(async (request: ApiV10AddressCheckAddressAccuracyPostRequest) => {
    const response = await addressApi.apiV10AddressCheckAddressAccuracyPost(request);

    return response.data || false;
  });
  return mutation;
};
