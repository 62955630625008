/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ChangePasswordResponse
 */
export interface ChangePasswordResponse {
  /**
   *
   * @type {boolean}
   * @memberof ChangePasswordResponse
   */
  succeeded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChangePasswordResponse
   */
  invalidCurrentPassword?: boolean;
}

export function ChangePasswordResponseFromJSON(json: any): ChangePasswordResponse {
  return ChangePasswordResponseFromJSONTyped(json, false);
}

export function ChangePasswordResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePasswordResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    succeeded: !exists(json, "succeeded") ? undefined : json["succeeded"],
    invalidCurrentPassword: !exists(json, "invalidCurrentPassword") ? undefined : json["invalidCurrentPassword"],
  };
}

export function ChangePasswordResponseToJSON(value?: ChangePasswordResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    succeeded: value.succeeded,
    invalidCurrentPassword: value.invalidCurrentPassword,
  };
}
