import { CardField, EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { createStyles, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Fields as EquipmentFields } from "./equipment-form";
import EquipmentInfo, { Props as EquipmentInfoProps } from "./equipment-info";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      "& > div": {
        paddingBottom: "0.5rem",
      },
      "& > .MuiGrid-root > :nth-child(2)": {
        paddingTop: "0rem",
      },
    },
    blueTitle: {
      color: theme.palette.primary.main,
    },
    containerBottom: {
      paddingBottom: "1rem",
    },
    extraFieldBackground: {
      backgroundColor: theme.palette.grey[200],
    },
    extraFieldContainer: {
      padding: "0.625rem 0rem",
    },
    entityLabel: {
      paddingLeft: "0.625rem",
    },
    firstExtraField: {
      marginBottom: "0.125rem",
    },
    blueValue: {
      "& .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
    },
  }),
);

export type Props = EquipmentInfoProps & {
  bannerText?: string;
  isNew?: boolean;
  isDeleted?: boolean;
  className?: string;
  onEdit?: EditableDeletableCardProps["onEdit"];
};

const AddEquipmentCard: React.FC<Props> = ({
  equipment,
  editedFields = [],
  bannerText = "",
  isNew = false,
  isDeleted = false,
  className,
  onEdit,
  ...Props
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const hasAdditionalInsured = !_.isEmpty(equipment.additionalInsured);
  const hasLossPayee = !_.isEmpty(equipment.lossPayees);

  return (
    <EditableDeletableCard
      title={equipment.vehicleNumber!}
      variant={isDeleted ? "default" : "edit"}
      onEdit={onEdit}
      IconButtonProps={
        isDeleted
          ? undefined
          : { "aria-label": t("policy-management-page.equipment-page.add-equipment-card.edit-icon") }
      }
      className={classNames(classes.container, className)}
      containerClass={hasAdditionalInsured || hasLossPayee ? classes.containerBottom : undefined}
      border={false}
      elevation={1}
      bannerText={bannerText}
      bannerVariant={isDeleted ? "error" : editedFields.length > 0 || isNew ? "primary" : undefined}
      titleClass={editedFields.includes(EquipmentFields.vehicleNumber) ? classes.blueTitle : undefined}
    >
      <EquipmentInfo {...Props} equipment={equipment} editedFields={editedFields} />
      {hasAdditionalInsured && (
        <Grid xs={12} className={classNames(classes.extraFieldBackground, classes.firstExtraField)} container item>
          <CardField
            id={`additional-insured-info-${equipment.id}`}
            label={t("policy-management-page.equipment-page.add-equipment-card.additional-insured")}
            values={[{ value: equipment.additionalInsured![0].name! }]}
            className={classes.extraFieldContainer}
            labelClass={classes.entityLabel}
            labelColor={editedFields.includes(EquipmentFields.additionalInsured) ? "primary" : undefined}
            valueClass={classNames({ [classes.blueValue]: editedFields.includes(EquipmentFields.additionalInsured) })}
          />
        </Grid>
      )}
      {hasLossPayee && (
        <Grid xs={12} className={classes.extraFieldBackground} container item>
          <CardField
            id={`loss-payee-info-${equipment.id}`}
            label={t("policy-management-page.equipment-page.add-equipment-card.loss-payee")}
            values={[{ value: equipment.lossPayees![0].name! }]}
            className={classes.extraFieldContainer}
            labelClass={classes.entityLabel}
            labelColor={editedFields.includes(EquipmentFields.lossPayee) ? "primary" : undefined}
            valueClass={classNames({ [classes.blueValue]: editedFields.includes(EquipmentFields.lossPayee) })}
          />
        </Grid>
      )}
    </EditableDeletableCard>
  );
};

export default AddEquipmentCard;
