import {
  Checkbox,
  DeleteIcon,
  EmailInput,
  PlusCircleIcon as PlusCircle,
  SaveButton,
  TextInput,
  TooltipHelp,
} from "@chq/components";
import { Button, Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.grey[600],
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: "1.125rem 0.625rem 1.25rem",
  },
  tooltip: {
    marginTop: "0.25rem",
  },
  checkboxFormLabel: {
    "& .MuiFormControlLabel-root": {
      marginLeft: "0px",
      marginRight: "0.5rem",
    },
  },
  checkbox: {
    marginTop: "0.25rem",
  },
  form: {
    marginTop: "1rem",
  },
  icon: {
    height: "40px",
    width: "auto",
  },
  iconButton: {
    padding: "0px",
  },
  addButton: {
    justifyContent: "flex-start",
    width: "15rem",
    marginTop: "1rem",
    padding: "0.5rem 0rem",
  },
  secondOwnerTitle: {
    marginTop: "2rem",
  },
  submitButton: {
    fontWeight: theme.typography.body1.fontWeight,
    padding: "1.125rem 0rem",
    background: theme.palette.common.white,
  },
  submitButtonContainer: {
    marginTop: "2.5rem",
  },
}));

export enum Fields {
  firstName = "first-name",
  lastName = "last-name",
  emailAddress = "email-address",
  signorParty = "signor-party",
  secondOwner = "secondOwner",
  firstName2 = "first-name-2",
  lastName2 = "last-name-2",
  emailAddress2 = "email-address-2",
  signorParty2 = "signor-party-2",
}

export const useValidationSchema = () => {
  const [t] = useTranslation();
  return Yup.object({
    [Fields.firstName]: Yup.string().required(
      t(`errors.required`, { field: t(`factoring.owner-information-form.${Fields.firstName}.label`) }),
    ),
    [Fields.lastName]: Yup.string().required(
      t(`errors.required`, { field: t(`factoring.owner-information-form.${Fields.lastName}.label`) }),
    ),
    [Fields.emailAddress]: Yup.string()
      .email(t("errors.invalid-email"))
      .required(t(`errors.required`, { field: t(`factoring.owner-information-form.${Fields.emailAddress}.label`) })),
    [Fields.signorParty]: Yup.boolean()
      .oneOf([true])
      .when(Fields.signorParty2, {
        is: true,
        then: Yup.boolean().oneOf([false]),
      }),
    [Fields.secondOwner]: Yup.boolean(),
    [Fields.firstName2]: Yup.string().when(Fields.secondOwner, {
      is: true,
      then: Yup.string().required(
        t(`errors.required`, { field: t(`factoring.owner-information-form.${Fields.firstName}.label`) }),
      ),
    }),
    [Fields.lastName2]: Yup.string().when(Fields.secondOwner, {
      is: true,
      then: Yup.string().required(
        t(`errors.required`, { field: t(`factoring.owner-information-form.${Fields.lastName}.label`) }),
      ),
    }),
    [Fields.emailAddress2]: Yup.string().when(Fields.secondOwner, {
      is: true,
      then: Yup.string()
        .email(t("errors.invalid-email"))
        .required(t(`errors.required`, { field: t(`factoring.owner-information-form.${Fields.emailAddress}.label`) })),
    }),
    [Fields.signorParty2]: Yup.boolean().when(Fields.secondOwner, {
      is: true,
      then: Yup.boolean()
        .oneOf([true])
        .when(Fields.signorParty, {
          is: true,
          then: Yup.boolean().oneOf([false]),
        }),
    }),
  });
};

export type FormProps = {
  [Fields.firstName]?: string | null;
  [Fields.lastName]?: string | null;
  [Fields.emailAddress]?: string | null;
  [Fields.signorParty]?: boolean;
  [Fields.secondOwner]?: boolean;
  [Fields.firstName2]?: string;
  [Fields.lastName2]?: string;
  [Fields.emailAddress2]?: string;
  [Fields.signorParty2]?: boolean;
};

export type Props = {
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  signorParty?: boolean;
  secondOwner?: boolean;
  firstName2?: string;
  lastName2?: string;
  emailAddress2?: string;
  signorParty2?: boolean;
  isLoading: boolean;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const OwnerInformationForm: React.FC<Props> = ({
  firstName = "",
  lastName = "",
  emailAddress = "",
  signorParty = true,
  secondOwner = false,
  firstName2 = "",
  lastName2 = "",
  emailAddress2 = "",
  signorParty2 = false,
  isLoading,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const validationSchema = useValidationSchema();
  const classes = useStyles();
  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.firstName]: firstName,
      [Fields.lastName]: lastName,
      [Fields.emailAddress]: emailAddress,
      [Fields.signorParty]: signorParty,
      [Fields.secondOwner]: secondOwner,
      [Fields.firstName2]: firstName2,
      [Fields.lastName2]: lastName2,
      [Fields.emailAddress2]: emailAddress2,
      [Fields.signorParty2]: signorParty2,
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  return (
    <Paper elevation={0} className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h2">{t("factoring.owner-information-form.title")}</Typography>
          </Grid>
          <Grid container item direction="row" spacing={1} className={classes.form}>
            <Grid item xs={6}>
              <TextInput
                fullWidth
                required
                label={t(`factoring.owner-information-form.${Fields.firstName}.label`)}
                type="text"
                id={Fields.firstName}
                name={Fields.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.firstName] && Boolean(formik.errors[Fields.firstName])}
                value={formik.values[Fields.firstName]}
                helperText={formik.touched[Fields.firstName] && formik.errors[Fields.firstName]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                fullWidth
                required
                label={t(`factoring.owner-information-form.${Fields.lastName}.label`)}
                type="text"
                id={Fields.lastName}
                name={Fields.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.lastName] && Boolean(formik.errors[Fields.lastName])}
                value={formik.values[Fields.lastName]}
                helperText={formik.touched[Fields.lastName] && formik.errors[Fields.lastName]}
              />
            </Grid>
          </Grid>
          <Grid item>
            <EmailInput
              fullWidth
              required
              label={t(`factoring.owner-information-form.${Fields.emailAddress}.label`)}
              id={Fields.emailAddress}
              name={Fields.emailAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.emailAddress] && Boolean(formik.errors[Fields.emailAddress])}
              value={formik.values[Fields.emailAddress]}
              helperText={formik.touched[Fields.emailAddress] && formik.errors[Fields.emailAddress]}
            />
          </Grid>
          <Grid item container direction="row" alignItems="center">
            <Grid item className={classes.checkboxFormLabel}>
              <Checkbox
                label={t(`factoring.owner-information-form.${Fields.signorParty}.label`)}
                id={Fields.signorParty}
                name={Fields.signorParty}
                onChange={formik.handleChange}
                value={formik.values[Fields.signorParty]}
                checked={formik.values[Fields.signorParty]}
                disabled={formik.values[Fields.signorParty2]}
              />
            </Grid>
            <Grid item>
              <TooltipHelp
                title={t(`factoring.owner-information-form.${Fields.signorParty}.tooltip-text`) as string}
                placement="bottom"
                color="disabled"
                className={classes.tooltip}
              />
            </Grid>
          </Grid>
          {formik.values[Fields.secondOwner] ? (
            <Grid container item direction="column">
              <Grid
                item
                container
                direction="row"
                className={classes.secondOwnerTitle}
                alignItems="center"
                justify="space-between"
              >
                <Grid item>
                  <Typography variant="h2">{t("factoring.owner-information-form.second-owner")}</Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label={t(`factoring.owner-information-form.delete-icon`)}
                    className={classes.iconButton}
                    onClick={async () => {
                      formik.setValues({
                        ...formik.values,
                        [Fields.secondOwner]: false,
                        [Fields.firstName2]: "",
                        [Fields.lastName2]: "",
                        [Fields.emailAddress2]: "",
                        [Fields.signorParty]: true,
                        [Fields.signorParty2]: false,
                      });
                    }}
                  >
                    <DeleteIcon color="disabled" className={classes.icon} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container item direction="row" spacing={1} className={classes.form}>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    required
                    label={t(`factoring.owner-information-form.${Fields.firstName}.label`)}
                    type="text"
                    id={Fields.firstName2}
                    name={Fields.firstName2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched[Fields.firstName2] && Boolean(formik.errors[Fields.firstName2])}
                    value={formik.values[Fields.firstName2]}
                    helperText={formik.touched[Fields.firstName2] && formik.errors[Fields.firstName2]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    required
                    label={t(`factoring.owner-information-form.${Fields.lastName}.label`)}
                    type="text"
                    id={Fields.lastName2}
                    name={Fields.lastName2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched[Fields.lastName2] && Boolean(formik.errors[Fields.lastName2])}
                    value={formik.values[Fields.lastName2]}
                    helperText={formik.touched[Fields.lastName2] && formik.errors[Fields.lastName2]}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <EmailInput
                  fullWidth
                  required
                  label={t(`factoring.owner-information-form.${Fields.emailAddress}.label`)}
                  id={Fields.emailAddress2}
                  name={Fields.emailAddress2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[Fields.emailAddress2] && Boolean(formik.errors[Fields.emailAddress2])}
                  value={formik.values[Fields.emailAddress2]}
                  helperText={formik.touched[Fields.emailAddress2] && formik.errors[Fields.emailAddress2]}
                />
              </Grid>
              <Grid item container direction="row" alignItems="center">
                <Grid item className={classes.checkboxFormLabel}>
                  <Checkbox
                    label={t(`factoring.owner-information-form.${Fields.signorParty}.label`)}
                    id={Fields.signorParty2}
                    name={Fields.signorParty2}
                    onChange={formik.handleChange}
                    value={formik.values[Fields.signorParty2]}
                    checked={formik.values[Fields.signorParty2]}
                    disabled={formik.values[Fields.signorParty]}
                  />
                </Grid>
                <Grid item>
                  <TooltipHelp
                    title={t(`factoring.owner-information-form.${Fields.signorParty}.tooltip-text`) as string}
                    placement="bottom"
                    color="disabled"
                    className={classes.tooltip}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <Button
                variant="text"
                color="primary"
                startIcon={<PlusCircle className={classes.icon} />}
                className={classes.addButton}
                onClick={() => {
                  formik.setFieldValue(Fields.secondOwner, true);
                  formik.setTouched({
                    ...formik.touched,
                    [Fields.firstName2]: false,
                    [Fields.lastName2]: false,
                    [Fields.emailAddress2]: false,
                    [Fields.signorParty2]: false,
                  });
                }}
              >
                {t(`factoring.owner-information-form.add-second-owner`)}
              </Button>
            </Grid>
          )}
          <Grid item className={classes.submitButtonContainer}>
            <SaveButton
              label={t(`factoring.owner-information-form.add-owner-information`)}
              variant="outlined"
              fullWidth
              isLoading={isLoading}
              type="submit"
              disabled={!formik.isValid}
              formikValid={formik.isValid}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default OwnerInformationForm;
