import { EmailInput, NextButton } from "@chq/components";
import { CircularProgress, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "0 1.5rem 1.25rem 1.5rem",
    background: theme.palette.common.white,
    borderRadius: ".25rem .25rem 0rem 0rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    paddingTop: "1rem",
    paddingBottom: "1.125rem",
    textTransform: "capitalize",
  },
  title: {
    textTransform: "capitalize",
  },
  continueQuoteClose: {
    width: "1rem",
    height: "1rem",
    margin: "1rem 0",
  },
  loading: {
    color: theme.palette.common.white,
  },
}));

export enum Fields {
  email = "email",
}

export type FormProps = {
  [Fields.email]: string;
};

type Props = {
  email?: string;
  isLoading?: boolean;
  onClose?: () => void;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.email]: Yup.string()
      .email(t("errors.invalid-email"))
      .required(t("errors.required", { field: t("continue-quote-email.email.label") })),
  });
};

export const useFormikConfig = ({ email: initialEmail = "" }: Props = {}): Omit<
  FormikConfig<FormProps>,
  "onSubmit"
> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.email]: initialEmail,
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  };
};

const ContinueQuoteEmail: React.FC<Props> = ({ isLoading = false, onClose }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<FormProps>();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container className={classes.container} spacing={2}>
        <Grid item>
          <Typography variant="h2" className={classes.title}>
            {t("continue-quote-email.title")}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.continueQuoteClose}
            onClick={onClose}
            aria-label={t("continue-quote-email.close-icon-label")}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <EmailInput
            fullWidth
            required
            id={Fields.email}
            name={Fields.email}
            label={t("continue-quote-email.email.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.email]}
            error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
            helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
          />
        </Grid>
        <Grid item xs={12}>
          <NextButton type="submit" fullWidth variant="contained" disabled={!formik.isValid} className={classes.button}>
            {isLoading ? (
              <CircularProgress
                color="primary"
                size="2rem"
                className={classes.loading}
                aria-label={t("common.circular-progress-aria-label")}
              />
            ) : (
              t("continue-quote-email.continue")
            )}
          </NextButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContinueQuoteEmail;
