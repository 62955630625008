import { Application, ApplicationApi, ApplicationApiResponse } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const useEditApplication = (): UseMutationResult<ApplicationApiResponse, Error, Application> => {
  const applicationApi = useApi(ApplicationApi);
  const { updateApplication } = useCachedApplication();
  const mutation = useMutation<ApplicationApiResponse, Error, Application>(async (request: Application) => {
    try {
      const response = await applicationApi.apiV10ApplicationPut({ application: request });

      if (response.data) {
        updateApplication(response.data);
      }

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.json) {
        const response = error as Response;
        const json: ApplicationApiResponse = await response.json();

        throw new Error(json.error?.message || "Failed to edit Application");
      } else {
        throw error;
      }
    }
  });
  return mutation;
};
