import {
  CurrencyInput,
  EditableDeletableCard,
  EmailInput,
  NumberPicker,
  SaveButton,
  TextInput,
  YesNoInput,
  YesNoValues,
} from "@chq/components";
import { useTheme } from "@chq/styles";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { default as classnames, default as classNames } from "classnames";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  truckCount: {
    whiteSpace: "nowrap",
  },
  form: {
    paddingTop: "1rem",
  },
  title: {
    paddingBottom: "1rem",
  },
  addButton: {
    padding: "1.125rem 0rem",
    fontWeight: 400,
    background: theme.palette.common.white,
  },
  container: {
    background: theme.palette.grey[600],
    padding: "1.125rem .625rem 1rem .625rem",
  },
  input: {
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  revenueInput: {
    "& .MuiInputBase-root": {
      [theme.breakpoints.down("xs")]: {
        marginTop: "1rem",
      },
    },
  },
  yesNoInput: {
    width: "15rem",
    background: theme.palette.common.white,
  },
}));

export enum Fields {
  firstName = "first-name",
  lastName = "last-name",
  contactTitle = "contact-title",
  contactEmail = "contact-email",
  monthlyRevenue = "monthly-revenue",
  truckCount = "truck-count",
  currentFactoring = "current-factoring",
  currentFactoringCompany = "current-factoring-company",
}

export type FactoringContactFormProps = {
  [Fields.firstName]: string | null;
  [Fields.lastName]: string | null;
  [Fields.contactTitle]: string;
  [Fields.contactEmail]: string | null;
  [Fields.monthlyRevenue]?: number;
  [Fields.truckCount]: number;
  [Fields.currentFactoring]: string;
  [Fields.currentFactoringCompany]?: string;
};

export type Props = {
  firstName?: string | null;
  lastName?: string | null;
  contactTitle?: string;
  contactEmail?: string | null;
  monthlyRevenue?: number;
  truckCount?: number;
  currentFactoring?: string;
  currentFactoringCompany?: string;
  isLoading: boolean;
  onSubmit?: FormikConfig<FactoringContactFormProps>["onSubmit"];
};

export const MIN_TRUCK_COUNT = 1;
export const MAX_TRUCK_COUNT = 20;

export const hasExceededMaximumTruckCount = (truckCount: number) => {
  return truckCount > MAX_TRUCK_COUNT;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.firstName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`factoring.contact-form.${Fields.firstName}.label`) })),
    [Fields.lastName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`factoring.contact-form.${Fields.lastName}.label`) })),
    [Fields.contactTitle]: yup
      .string()
      .required(t(`errors.required`, { field: t(`factoring.contact-form.${Fields.contactTitle}.label`) })),
    [Fields.contactEmail]: yup
      .string()
      .email()
      .required(t(`errors.required`, { field: t(`factoring.contact-form.${Fields.contactEmail}.label`) })),
    [Fields.monthlyRevenue]: yup
      .number()
      .typeError(t(`errors.required`, { field: t(`factoring.contact-form.${Fields.monthlyRevenue}.help-error-text`) }))
      .required(t(`errors.required`, { field: t(`factoring.contact-form.${Fields.monthlyRevenue}.help-error-text`) })),
    [Fields.truckCount]: yup
      .number()
      .required(t(`errors.required`, { field: t(`factoring.contact-form.${Fields.truckCount}.label`) }))
      .max(
        MAX_TRUCK_COUNT,
        t(`errors.max`, {
          field: t(`factoring.contact-form.${Fields.truckCount}.help-error-text`),
          max: MAX_TRUCK_COUNT,
        }),
      )
      .min(
        MIN_TRUCK_COUNT,
        t(`errors.min`, {
          field: t(`factoring.contact-form.${Fields.truckCount}.help-error-text`),
          min: MIN_TRUCK_COUNT,
        }),
      )
      .typeError(t(`errors.required`, { field: t(`factoring.contact-form.${Fields.truckCount}.help-error-text`) })),
    [Fields.currentFactoring]: yup
      .string()
      .required(
        t(`errors.required`, { field: t(`factoring.contact-form.${Fields.currentFactoring}.help-error-text`) }),
      ),
    [Fields.currentFactoringCompany]: yup.string().when(Fields.currentFactoring, {
      is: YesNoValues.yes,
      then: yup
        .string()
        .required(t(`errors.required`, { field: t(`factoring.contact-form.${Fields.currentFactoringCompany}.label`) })),
    }),
  });
};

const FactoringContactForm: React.FC<Props> = ({
  firstName = "",
  lastName = "",
  contactTitle = "",
  contactEmail = "",
  monthlyRevenue,
  truckCount = 1,
  currentFactoring = "",
  currentFactoringCompany = "",
  isLoading,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const validationSchema = useValidationSchema();
  const theme = useTheme();
  const formik = useFormik<FactoringContactFormProps>({
    initialValues: {
      [Fields.firstName]: firstName,
      [Fields.lastName]: lastName,
      [Fields.contactTitle]: contactTitle,
      [Fields.contactEmail]: contactEmail,
      [Fields.monthlyRevenue]: monthlyRevenue,
      [Fields.truckCount]: truckCount,
      [Fields.currentFactoring]: currentFactoring,
      [Fields.currentFactoringCompany]: currentFactoringCompany,
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  return (
    <EditableDeletableCard
      title={t("factoring.contact-form.title")}
      variant="default"
      titleVariant="h2"
      titleComponent="h3"
      className={classes.container}
    >
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextInput
              required
              fullWidth
              id={Fields.firstName}
              name={Fields.firstName}
              label={t("factoring.contact-form.first-name.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.firstName]}
              error={formik.touched[Fields.firstName] && Boolean(formik.errors[Fields.firstName])}
              helperText={formik.touched[Fields.firstName] && formik.errors[Fields.firstName]}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              required
              fullWidth
              id={Fields.lastName}
              name={Fields.lastName}
              label={t("factoring.contact-form.last-name.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.lastName]}
              error={formik.touched[Fields.lastName] && Boolean(formik.errors[Fields.lastName])}
              helperText={formik.touched[Fields.lastName] && formik.errors[Fields.lastName]}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              required
              fullWidth
              id={Fields.contactTitle}
              name={Fields.contactTitle}
              label={t("factoring.contact-form.contact-title.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.contactTitle]}
              error={formik.touched[Fields.contactTitle] && Boolean(formik.errors[Fields.contactTitle])}
              helperText={formik.touched[Fields.contactTitle] && formik.errors[Fields.contactTitle]}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12}>
            <EmailInput
              required
              fullWidth
              id={Fields.contactEmail}
              name={Fields.contactEmail}
              label={t("factoring.contact-form.contact-email.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.contactEmail]}
              error={formik.touched[Fields.contactEmail] && Boolean(formik.errors[Fields.contactEmail])}
              helperText={formik.touched[Fields.contactEmail] && formik.errors[Fields.contactEmail]}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyInput
              required
              fullWidth
              type="text"
              id={Fields.monthlyRevenue}
              name={Fields.monthlyRevenue}
              label={t("factoring.contact-form.monthly-revenue.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.monthlyRevenue]}
              error={formik.touched[Fields.monthlyRevenue] && Boolean(formik.errors[Fields.monthlyRevenue])}
              helperText={formik.touched[Fields.monthlyRevenue] && formik.errors[Fields.monthlyRevenue]}
              className={classnames(classes.input, classes.revenueInput)}
            />
          </Grid>
          <Grid item xs={12}>
            <NumberPicker
              required
              fullWidth
              id={Fields.truckCount}
              name={Fields.truckCount}
              label={t("factoring.contact-form.truck-count.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.truckCount]}
              error={formik.touched[Fields.truckCount] && Boolean(formik.errors[Fields.truckCount])}
              helperText={formik.touched[Fields.truckCount] && formik.errors[Fields.truckCount]}
              className={classNames(classes.truckCount)}
              min={1}
              max={20}
            />
          </Grid>
          <Grid item xs={12}>
            <YesNoInput
              className={classes.yesNoInput}
              required
              id={Fields.currentFactoring}
              name={Fields.currentFactoring}
              label={t("factoring.contact-form.current-factoring.label")}
              yesText={t("common.yes")}
              noText={t("common.no")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.currentFactoring]}
              error={formik.touched[Fields.currentFactoring] && Boolean(formik.errors[Fields.currentFactoring])}
              helperText={formik.touched[Fields.currentFactoring] && formik.errors[Fields.currentFactoring]}
              activeColor={theme.palette.primary.main}
              activeTextColor={theme.palette.common.white}
            />
          </Grid>
          {formik.values[Fields.currentFactoring] === YesNoValues.yes && (
            <Grid item xs={12}>
              <TextInput
                required
                fullWidth
                id={Fields.currentFactoringCompany}
                name={Fields.currentFactoringCompany}
                label={t("factoring.contact-form.current-factoring-company.label")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[Fields.currentFactoringCompany]}
                error={
                  formik.touched[Fields.currentFactoringCompany] &&
                  Boolean(formik.errors[Fields.currentFactoringCompany])
                }
                helperText={
                  formik.touched[Fields.currentFactoringCompany] && formik.errors[Fields.currentFactoringCompany]
                }
                className={classes.input}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {/* <Button
              type="submit"
              variant="outlined"
              color="primary"
              fullWidth
              disabled={!formik.isValid || isLoading}
              className={classes.addButton}
            >
              {isLoading ? <CircularProgress color="primary" size="2rem" aria-label={t("common.circular-progress-aria-label")} /> : t("factoring.contact-form.add-button")}
            </Button> */}
            <SaveButton
              label={t("factoring.contact-form.add-button")}
              variant="outlined"
              fullWidth
              type="submit"
              disabled={!formik.isValid}
              formikValid={formik.isValid}
            />
          </Grid>
        </Grid>
      </form>
    </EditableDeletableCard>
  );
};

export default FactoringContactForm;
