import {
  BirthdatePicker,
  EditableDeletableCard,
  EditableDeletableCardProps,
  SaveButton,
  Select,
  TextInput,
  YesNoInput,
  YesNoValues,
} from "@chq/components";
import { State } from "@chq/enrollment-api";
import { Grid, IconButtonProps, makeStyles, Theme, Typography } from "@material-ui/core";
import { subYears } from "date-fns";
import startOfToday from "date-fns/startOfToday";
import { FormikConfig, useFormik } from "formik";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useStates } from "../data/useStates";

const useStyles = makeStyles((theme: Theme) => ({
  yesNoInput: {
    "& .MuiFormGroup-root": {
      marginTop: "0.75rem",
      marginBottom: "0.75rem",
      "& .MuiFormGroup-row": {
        marginTop: ".5rem",
        marginBottom: ".5rem",
      },
    },
  },
  radioClass: {
    width: "fit-content",
  },
  textArea: {
    marginBottom: "1rem",
  },
  textAreaWithCounter: {
    marginBottom: ".2rem",
  },
  characterCount: {
    marginBottom: "1rem",
    fontWeight: 300,
    fontSize: ".9rem",
    color: theme.palette.common.black,
  },
  characterCountRed: {
    marginBottom: "1rem",
    fontWeight: 300,
    fontSize: ".9rem",
    color: theme.palette.error.main,
  },
  textAreaContainer: {
    "& .MuiOutlinedInput-multiline": {
      marginTop: "1rem",
    },
  },
}));

export enum Fields {
  firstName = "first-name",
  lastName = "last-name",
  dateOfBirth = "date-of-birth",
  cdlNumber = "cdl-number",
  cdlState = "cdl-state",
  pastViolations = "past-violations",
  violationDescription = "violation-description",
  violationDescriptionEmbelishment = "violation-description-embelishment",
  maxCharacters = "max-characters",
}

export const useValidationSchema = () => {
  const [t] = useTranslation();
  const today = startOfToday();
  const minBirthdate = subYears(today, 75);
  const maxBirthdate = subYears(today, 21);

  return yup.object({
    [Fields.firstName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.firstName}`) })),
    [Fields.lastName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.lastName}`) })),
    [Fields.dateOfBirth]: yup
      .date()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.dateOfBirth}`) }))
      .typeError(t("errors.date", { field: t(`enrollment.add-driver.fields.${Fields.dateOfBirth}`) }))
      .min(minBirthdate, t("enrollment.add-driver.errors.age-restriction"))
      .max(maxBirthdate, t("enrollment.add-driver.errors.age-restriction")),
    [Fields.cdlNumber]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.cdlNumber}`) })),
    [Fields.cdlState]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.cdlState}`) })),
    [Fields.pastViolations]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.pastViolations}`) })),
    [Fields.violationDescription]: yup.string().when(Fields.pastViolations, {
      is: YesNoValues.yes,
      then: yup
        .string()
        .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.violationDescription}`) })),
    }),
  });
};

export type FormProps = {
  [Fields.firstName]: string;
  [Fields.lastName]: string;
  [Fields.dateOfBirth]?: Date;
  [Fields.cdlNumber]: string;
  [Fields.cdlState]?: State | "";
  [Fields.pastViolations]?: string | "";
  [Fields.violationDescription]?: string;
};

type Props = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  cdlNumber?: string;
  cdlState?: State | "";
  pastViolations?: string | undefined;
  violationDescription?: string;
  IconButtonProps?: Omit<IconButtonProps, "onClick">;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
  onDelete?: EditableDeletableCardProps["onDelete"];
  driverNumber?: number;
};

export const AddDriverForm: React.FC<Props> = ({
  firstName: initialFirstName = "",
  lastName: initialLastName = "",
  dateOfBirth: initialDateOfBirth,
  cdlNumber: initialCdlNumber = "",
  cdlState: initialCdlState = "",
  pastViolations: initialPastViolations = "",
  violationDescription: initialViolationDescription = "",
  onSubmit,
  onDelete,
  driverNumber = 1,
  IconButtonProps,
}) => {
  const [t] = useTranslation();
  const validationSchema = useValidationSchema();
  const states = useStates();
  const classes = useStyles();
  const [counter, setCounter] = useState(280);
  const [displayCounter, setDisplayCounter] = useState(false);

  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.firstName]: initialFirstName,
      [Fields.lastName]: initialLastName,
      [Fields.dateOfBirth]: initialDateOfBirth,
      [Fields.cdlNumber]: initialCdlNumber,
      [Fields.cdlState]: initialCdlState,
      [Fields.pastViolations]: initialPastViolations,
      [Fields.violationDescription]: initialViolationDescription,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
  });

  const checkIfErrorIsShowing = () => {
    const errors = Object.keys(formik.errors);
    const isErrorShowing = Object.keys(formik.touched).map((touched) => {
      return errors.includes(touched);
    });
    return isErrorShowing.includes(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setCustomChange = async (date: any) => {
    await formik.setFieldValue(Fields.dateOfBirth, date); // The mui DatePicker does not always set a "touched" state, but manually
    formik.setFieldTouched(Fields.dateOfBirth); // setting it must happen AFTER the field value is set to make validation work correctly
  };

  return (
    <EditableDeletableCard
      title={t("enrollment.add-driver.title", { number: driverNumber })}
      error={checkIfErrorIsShowing()}
      variant="delete"
      onDelete={onDelete}
      IconButtonProps={{
        "aria-label": t("enrollment.add-driver.delete-button"),
        ...IconButtonProps,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  id={Fields.firstName}
                  name={Fields.firstName}
                  label={t(`enrollment.add-driver.fields.${Fields.firstName}`)}
                  value={formik.values[Fields.firstName]}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[Fields.firstName] && Boolean(formik.errors[Fields.firstName])}
                  helperText={formik.touched[Fields.firstName] && formik.errors[Fields.firstName]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  id={Fields.lastName}
                  name={Fields.lastName}
                  label={t(`enrollment.add-driver.fields.${Fields.lastName}`)}
                  value={formik.values[Fields.lastName]}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[Fields.lastName] && Boolean(formik.errors[Fields.lastName])}
                  helperText={formik.touched[Fields.lastName] && formik.errors[Fields.lastName]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BirthdatePicker
              id={Fields.dateOfBirth}
              name={Fields.dateOfBirth}
              inputProps={{ "aria-label": `${Fields.dateOfBirth}` }}
              label={t(`enrollment.add-driver.fields.${Fields.dateOfBirth}`)}
              value={formik.values[Fields.dateOfBirth] || null}
              required
              fullWidth
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(date: any) => {
                setCustomChange(date);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.dateOfBirth] && Boolean(formik.errors[Fields.dateOfBirth])}
              helperText={formik.touched[Fields.dateOfBirth] && formik.errors[Fields.dateOfBirth]}
              KeyboardButtonProps={{
                "aria-label": t(`enrollment.add-driver.fields.${Fields.dateOfBirth}`),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              id={Fields.cdlNumber}
              name={Fields.cdlNumber}
              label={t(`enrollment.add-driver.fields.${Fields.cdlNumber}`)}
              value={formik.values[Fields.cdlNumber]}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.cdlNumber] && Boolean(formik.errors[Fields.cdlNumber])}
              helperText={formik.touched[Fields.cdlNumber] && formik.errors[Fields.cdlNumber]}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              id={Fields.cdlState}
              name={Fields.cdlState}
              label={t(`enrollment.add-driver.fields.${Fields.cdlState}`)}
              items={states.map((state) => ({
                name: state.abv,
                value: state.abv,
              }))}
              value={formik.values[Fields.cdlState]}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.cdlState] && Boolean(formik.errors[Fields.cdlState])}
              helperText={formik.touched[Fields.cdlState] && formik.errors[Fields.cdlState]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <YesNoInput
            fullWidth
            label={t(`enrollment.add-driver.fields.${Fields.pastViolations}`)}
            id={Fields.pastViolations}
            className={classes.yesNoInput}
            required
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.value === YesNoValues.yes) {
                formik.setFieldValue(Fields.violationDescription, "");
                formik.setTouched({
                  [Fields.violationDescription]: false,
                });
              } else {
                formik.setFieldValue(Fields.violationDescription, "");
                formik.setTouched({
                  [Fields.violationDescription]: true,
                });
                setCounter(280);
                setDisplayCounter(false);
              }
              formik.handleChange(event);
            }}
            onBlur={formik.handleBlur}
            name={Fields.pastViolations}
            value={formik.values[Fields.pastViolations]}
            error={formik.touched[Fields.pastViolations] && Boolean(formik.errors[Fields.pastViolations])}
            radioClass={classes.radioClass}
            yesText={t("common.yes")}
            noText={t("common.no")}
          />
        </Grid>
        {formik.values[Fields.pastViolations] === YesNoValues.yes && (
          <>
            <Grid item xs={12} className={classes.textAreaContainer}>
              <TextInput
                fullWidth
                multiline={true}
                rows={3}
                rowsMax={30}
                type="text"
                id={Fields.violationDescription}
                name={Fields.violationDescription}
                label={t(`enrollment.add-driver.fields.${Fields.violationDescription}`)}
                value={formik.values[Fields.violationDescription]}
                required
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value.length < 281) {
                    setCounter(280 - event.target.value.length);
                    formik.handleChange(event);
                  }

                  if (displayCounter === false) {
                    setDisplayCounter(true);
                  }
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.violationDescription] && Boolean(formik.errors[Fields.violationDescription])
                }
                helperText={formik.touched[Fields.violationDescription] && formik.errors[Fields.violationDescription]}
                labelEmbellishment={t(`enrollment.add-driver.${Fields.violationDescriptionEmbelishment}`)}
                className={displayCounter ? classes.textAreaWithCounter : classes.textArea}
              />
              {displayCounter && (
                <Typography
                  variant="h4"
                  align="right"
                  className={counter === 0 ? classes.characterCountRed : classes.characterCount}
                >
                  {t(`enrollment.add-driver.${Fields.maxCharacters}`, { count: counter })}
                </Typography>
              )}
            </Grid>
          </>
        )}
        <SaveButton
          label={t("enrollment.add-driver.add-driver-button")}
          variant="outlined"
          fullWidth
          type="submit"
          disabled={!formik.isValid}
          formikValid={formik.isValid}
        />
      </form>
    </EditableDeletableCard>
  );
};

export default AddDriverForm;
