import { PdfDownloadList } from "@chq/components";
import { Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useTermsAndConditionLinks } from "../../../data/enrollment";
import { useGetPolicy } from "../../../data/policy-management/useGetPolicy";

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: "1.5rem 1rem 1.5rem 1rem",
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
  },
  documentsTitle: {
    textTransform: "uppercase",
  },
  contentContainer: {
    padding: "1.5rem 0.75rem 1.5rem 0.75rem",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
  policyNumber: {
    display: "flex",
    flexDirection: "row",
    textTransform: "uppercase",
    "& p": {
      margin: ".25rem",
    },
  },
  pdfDownload: {
    "& > *": { boxShadow: "none" },
    overflowWrap: "inherit",
  },
}));

const DocumentsPage: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { data } = useGetPolicy();
  const { data: terms } = useTermsAndConditionLinks();

  let files;
  const hasCargoInsurance = data?.equipment?.some((equipment) => equipment.cargoInsurance);
  if (hasCargoInsurance) {
    files = [
      {
        ...data?.pdfDocuments?.eDeliveryConsentForm,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.electronic-delivery")}`,
      },
      {
        ...data?.pdfDocuments?.insuranceApplication,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.insurance-application")}`,
      },
      {
        ...data?.pdfDocuments?.umuimCoverage,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.um-uim-form")}`,
      },
      {
        ...data?.pdfDocuments?.fraudStatement,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.fraud-statement")}`,
      },
      {
        ...data?.policyDocument,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.policy-documents")}`,
      },
      {
        ...data?.pdfDocuments?.rrgMembershipForm,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.rrg-membership")}`,
      },
      {
        publicUri: terms?.cargoPolicyLink,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.cargo-policy-forms")}`,
      },
      {
        ...data?.pdfDocuments?.cargoForm,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.cargo-policy-signed")}`,
      },
    ];
  } else {
    files = [
      {
        ...data?.pdfDocuments?.eDeliveryConsentForm,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.electronic-delivery")}`,
      },
      {
        ...data?.pdfDocuments?.insuranceApplication,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.insurance-application")}`,
      },
      {
        ...data?.pdfDocuments?.umuimCoverage,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.um-uim-form")}`,
      },
      {
        ...data?.pdfDocuments?.fraudStatement,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.fraud-statement")}`,
      },
      {
        ...data?.policyDocument,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.policy-documents")}`,
      },
      {
        ...data?.pdfDocuments?.rrgMembershipForm,
        name: `${t("policy-management-page.documents-page.pdf-download.pdf-name.rrg-membership")}`,
      },
    ];
  }

  return (
    <Grid className={classes.pageContainer}>
      <Paper className={classes.paper}>
        <Grid container className={classes.contentContainer}>
          <Grid container item direction="column" xs={12} sm={8} md={5} spacing={1}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Typography variant="h4" component="p" className={classes.documentsTitle}>
                {t("policy-management-page.documents-page.header-text")}
              </Typography>
              <Grid className={classes.policyNumber}>
                <Trans
                  i18nKey={"policy-management-page.documents-page.policy-number"}
                  values={{ policyNumber: data?.policyNumber }}
                >
                  <Typography variant="subtitle1" component="p">
                    Policy
                  </Typography>
                  <Typography variant="subtitle1" component="p"></Typography>
                </Trans>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.pdfDownload}>
              <PdfDownloadList
                downloadAriaLabel={t("policy-management-page.documents-page.pdf-download.download-button")}
                files={files}
                downloadEnabled={true}
                emailEnabled={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default DocumentsPage;
