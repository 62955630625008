import { ChevronDownIcon as Chevron } from "@chq/components";
import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import qs from "qs";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import FleetErrorEmailCard from "../../../components/fleet-error-email-card";
import FleetNotEligibleCard from "../../../components/fleet-not-eligible-card";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  buttonIcon: {
    transform: "rotate(90deg)",
    width: "auto",
    height: "40px",
    padding: "0px",
  },
  paper: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: "1rem 0.625rem",
  },
  backButton: {
    ...theme.typography.h3,
    margin: "1rem 0rem",
    padding: "0rem 1rem 0rem 0rem",
    textTransform: "none",
    color: theme.palette.primary.main,
  },
  explanationContainer: {
    paddingBottom: "1.5rem",
  },
  errorCard: {
    paddingBottom: "1.125rem",
  },
  eligibilityStatus: {
    marginBottom: "1.5rem",
    "& div > div": {
      display: "contents",
      "& svg": {
        height: "40px",
        width: "auto",
      },
    },
  },
  explanationText: {
    marginBottom: "1.25rem",
  },
  listStart: {
    marginBottom: "0.625rem",
  },
  list: {
    ...theme.typography.h4,
  },
}));

const DNQPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const didNotQualifyReason = useMemo(() => qs.parse(search.slice(1)), [search]);

  return (
    <Grid container direction="column" alignContent="center">
      <Grid xs={12} item>
        <Button
          startIcon={<Chevron className={classes.buttonIcon} />}
          className={classes.backButton}
          color={"primary"}
          onClick={() => {
            history.push(routes.enrollment.qualify.path);
          }}
        >
          {t("enrollment.did-not-qualify-page.back-button-text")}
        </Button>
      </Grid>
      <Grid xs={12} sm={8} lg={4} item className={classes.explanationContainer}>
        <FleetNotEligibleCard
          eligibilityStatusClass={classes.eligibilityStatus}
          didNotQualifyReason={
            parseInt(didNotQualifyReason.reason! as string)
              ? parseInt(didNotQualifyReason.reason! as string)
              : undefined
          }
        />
      </Grid>
      <Grid xs={12} sm={8} lg={4} item className={classes.errorCard}>
        <FleetErrorEmailCard />
      </Grid>
    </Grid>
  );
};

export default DNQPage;
