import { PolicyApi, PortalApiV10PolicySendCancelPolicyEmailPostRequest } from "@chq/policy-management-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi as usePolicyApi } from "./usePolicyManagementApi";

export const useCancelPolicy = (): UseMutationResult<
  void,
  Error,
  PortalApiV10PolicySendCancelPolicyEmailPostRequest
> => {
  const policyApi = usePolicyApi(PolicyApi);
  const mutation = useMutation<void, Error, PortalApiV10PolicySendCancelPolicyEmailPostRequest>(
    async (request: PortalApiV10PolicySendCancelPolicyEmailPostRequest) => {
      await policyApi.portalApiV10PolicySendCancelPolicyEmailPost(request);
    },
  );
  return mutation;
};
