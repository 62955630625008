export enum DestinationType {
  Atlanta = "Zone 01 - Atlanta",
  BaltimoreWashington = "Zone 02 - Baltimore/Washington (Eastern)",
  Boston = "Zone 03 - Boston",
  Buffalo = "Zone 04 - Buffalo",
  Charlotte = "Zone 05 - Charlotte",
  Chicago = "Zone 06 - Chicago",
  CincinnatiMideast = "Zone 07 - Cincinnati (Mideast)",
  Cleveland = "Zone 08 - Cleveland",
  DallasFortWorth = "Zone 09 - Dallas Fort Worth",
  Denver = "Zone 10 - Denver",
  Hartford = "Zone 12 - Hartford",
  Houston = "Zone 13 - Houston",
  Indianapolis = "Zone 14 - Indianapolis",
  Jacksonville = "Zone 15 - Jacksonville",
  KansasCity = "Zone 16 - Kansas City",
  LittleRock = "Zone 17 - Little Rock",
  LosAngeles = "Zone 18 - Los Angeles",
  Louisville = "Zone 19 - Louisville (Mideast)",
  Memphis = "Zone 20 - Memphis",
  Miami = "Zone 21 - Miami",
  Milwaukee = "Zone 22 - Milwaukee",
  MinneapolisStPaul = "Zone 23 - Minneapolis St. Paul",
  Nashville = "Zone 24 - Nashville",
  NewOrleans = "Zone 25 - New Orleans",
  NewYorkCity = "Zone 26 - New York City (Eastern)",
  OklahomaCity = "Zone 27 - Oklahoma City",
  Omaha = "Zone 28 - Omaha",
  Phoenix = "Zone 29 - Phoenix",
  Philadelphia = "Zone 30 - Philadelphia",
  Pittsburgh = "Zone 31 - Pittsburgh",
  Portland = "Zone 32 - Portland",
  Richmond = "Zone 33 - Richmond",
  StLouis = "Zone 34 - St. Louis (Midwest)",
  SaltLakeCity = "Zone 35 - Salt Lake City",
  SanFrancisco = "Zone 36 - San Francisco",
  Tulsa = "Zone 37 - Tulsa",
  Pacific = "Zone 40 - Pacific",
  Mountain = "Zone 41 - Mountain",
  Midwest = "Zone 42 - Midwest",
  Southwest = "Zone 43 - Southwest",
  NorthCentral = "Zone 44 - North Central",
  Mideast = "Zone 45 - Mideast",
  Gulf = "Zone 46 - Gulf",
  SouthEast = "Zone 47 - Southeast",
  Eastern = "Zone 48 - Eastern",
  NewEngland = "Zone 49 - New England",
  Alaska = "Zone 50 - Alaska",
  BaltimoreWashingtonSoutheast = "Zone 02 - Baltimore/Washington (Southeast)",
  CincinnatiNorthCentral = "Zone 07 - Cincinnati (North Central)",
  LouisvilleNorthCentral = "Zone 19 - Louisville (North Central)",
  NewYorkCityNewEngland = "Zone 26 - New York City (New England)",
  StLouisNorthCentral = "Zone 34 - St. Louis (North Central)",
  Detroit = "Zone 11 - Detroit",
  PacificCoast = "Zone 40 - Pacific Coast",
  MinneapolisStPaulWithSlash = "Zone 23 - Minneapolis/St.Paul",
  NewYorkCityNonEastern = "Zone 26 - New York City",
}

export const disabledDestinations = [
  DestinationType.Alaska,
  DestinationType.BaltimoreWashingtonSoutheast,
  DestinationType.CincinnatiNorthCentral,
  DestinationType.LouisvilleNorthCentral,
  DestinationType.NewYorkCityNewEngland,
  DestinationType.StLouisNorthCentral,
];

export const destinationCaveats = [
  { zone: DestinationType.MinneapolisStPaul, states: ["MN"], newZone: DestinationType.MinneapolisStPaulWithSlash },
  { zone: DestinationType.NewYorkCity, states: ["CT"], newZone: DestinationType.NewYorkCityNonEastern },
  { zone: DestinationType.Pacific, states: ["CA", "WA"], newZone: DestinationType.PacificCoast },
];

export const useDestinationTypes = (): DestinationType[] => {
  return [
    DestinationType.Atlanta,
    DestinationType.BaltimoreWashington,
    DestinationType.Boston,
    DestinationType.Buffalo,
    DestinationType.Charlotte,
    DestinationType.Chicago,
    DestinationType.CincinnatiMideast,
    DestinationType.Cleveland,
    DestinationType.DallasFortWorth,
    DestinationType.Denver,
    DestinationType.Detroit,
    DestinationType.Hartford,
    DestinationType.Houston,
    DestinationType.Indianapolis,
    DestinationType.Jacksonville,
    DestinationType.KansasCity,
    DestinationType.LittleRock,
    DestinationType.LosAngeles,
    DestinationType.Louisville,
    DestinationType.Memphis,
    DestinationType.Miami,
    DestinationType.Milwaukee,
    DestinationType.MinneapolisStPaul,
    DestinationType.Nashville,
    DestinationType.NewOrleans,
    DestinationType.NewYorkCity,
    DestinationType.OklahomaCity,
    DestinationType.Omaha,
    DestinationType.Phoenix,
    DestinationType.Philadelphia,
    DestinationType.Pittsburgh,
    DestinationType.Portland,
    DestinationType.Richmond,
    DestinationType.StLouis,
    DestinationType.SaltLakeCity,
    DestinationType.SanFrancisco,
    DestinationType.Tulsa,
    DestinationType.Pacific,
    DestinationType.Mountain,
    DestinationType.Midwest,
    DestinationType.Southwest,
    DestinationType.NorthCentral,
    DestinationType.Mideast,
    DestinationType.Gulf,
    DestinationType.SouthEast,
    DestinationType.Eastern,
    DestinationType.NewEngland,
    DestinationType.Alaska,
    DestinationType.BaltimoreWashingtonSoutheast,
    DestinationType.CincinnatiNorthCentral,
    DestinationType.LouisvilleNorthCentral,
    DestinationType.NewYorkCityNewEngland,
    DestinationType.StLouisNorthCentral,
  ];
};
