import { Grid, Typography, makeStyles, Theme, Paper } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  startingContainer: {
    backgroundColor: "transparent",
    border: `1px ${theme.palette.grey[500]} solid`,
    width: "100%",
    padding: "1rem 0rem 1rem 0.625rem",
  },
  fullWidth: {
    marginTop: "0.75rem",
    "& > .MuiPaper-root": {
      width: "100%",
    },
  },
}));

type Props = {
  title?: string;
  formStatus: boolean | undefined;
  form?: React.ReactNode;
  card?: React.ReactNode;
};

const FactoringSection: React.FC<Props> = ({ title, formStatus, form, card }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} className={classes.fullWidth}>
      {formStatus === undefined && (
        <Paper elevation={0} className={classes.startingContainer}>
          <Typography variant="h2">{title}</Typography>
        </Paper>
      )}
      {formStatus !== undefined && (formStatus ? (card === undefined ? form : card) : form)}
    </Grid>
  );
};

export default FactoringSection;
