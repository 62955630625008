/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ModificationType {
  Unchanged = "Unchanged",
  Added = "Added",
  Changed = "Changed",
  Removed = "Removed",
}

export function ModificationTypeFromJSON(json: any): ModificationType {
  return ModificationTypeFromJSONTyped(json, false);
}

export function ModificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModificationType {
  return json as ModificationType;
}

export function ModificationTypeToJSON(value?: ModificationType | null): any {
  return value as any;
}
