import { ELDProvider } from "@chq/enrollment-api";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import classnames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: "bold",
  },
  regular: {
    whiteSpace: "pre-line",
  },
  subListItem: {
    display: "inline-block",
    marginTop: "0.5rem",
    paddingLeft: "1rem",
  },
  closeButton: {
    padding: 0,
    color: theme.palette.grey[500],
  },
  orderedList: {
    marginTop: 0,
    padding: "0 0.5rem 0 1.5rem",
    "& p": {
      paddingBottom: "0.5rem",
      "&:last-child": {
        paddingBottom: 0,
      },
    },
  },
  contactFooter: {
    background: theme.palette.action.active,
    padding: "0.75rem",
    borderRadius: "4px",
  },
  userIcon: {
    marginBottom: "-3px",
    fontSize: "1.25rem",
  },
  urlDisplay: {
    margin: "0.25rem 1rem 0 1rem",
  },
}));

type Props = {
  provider?: ELDProvider;
  open: boolean;
  onClose?: () => void;
};

const EldDialog: React.FC<Props> = ({ provider, open, onClose }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const getProviderLink = () => {
    switch (provider) {
      case ELDProvider.Geotab:
        return "https://my.geotab.com/";
      case ELDProvider.MotiveKeepTruckin:
        return "https://web.keeptruckin.com/en-US/#/admin/company";
      case ELDProvider.Samsara:
        return "https://cloud.samsara.com/signin";
      default:
        return "#";
    }
  };

  const translationBulletArray = Object.keys(
    t(`finish-application-page.eld-help-dialog.${provider}.bullets`, {
      returnObjects: true,
    }),
  );
  // Create the bullet list to display in the modal
  const bulletList = translationBulletArray.map((bullet, index) => (
    <Typography variant="body1" key={index}>
      <li>
        <Trans i18nKey={`finish-application-page.eld-help-dialog.${provider}.bullets.${bullet}`}>
          <Link variant="body1" target="_blank" href={getProviderLink()} className={classes.bold}>
            {t(`finish-application-page.eld-help-dialog.link-aria-label`, {
              provider: t(`eld-provider.${provider}`),
            })}
          </Link>
          <Typography component="span" variant="body1" className={classes.regular}></Typography>
          <Typography component="span" variant="body1" className={classes.bold}></Typography>
          <Typography
            component="span"
            variant="body1"
            className={classnames(classes.bold, classes.subListItem)}
          ></Typography>
          <PersonIcon fontSize="small" className={classes.userIcon}></PersonIcon>
          <PersonOutlineIcon fontSize="small" className={classes.userIcon}></PersonOutlineIcon>
          <pre className={classes.urlDisplay}></pre>
        </Trans>
      </li>
    </Typography>
  ));

  return (
    <>
      {(provider === ELDProvider.Geotab ||
        provider === ELDProvider.MotiveKeepTruckin ||
        provider === ELDProvider.Samsara) && (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle className={classes.bold}>
            <Grid container alignItems="flex-start" justify="space-between">
              <Grid item xs={10}>
                <Typography variant="h2">
                  {t("finish-application-page.eld-help-dialog.title", {
                    provider: t(`eld-provider.${provider}`),
                  })}
                </Typography>
              </Grid>
              <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <ol className={classes.orderedList}>{bulletList}</ol>
              <Typography variant="body1" align="center" className={classes.contactFooter}>
                <Trans i18nKey={"finish-application-page.eld-help-dialog.contact-footer"}>
                  <Link href={"tel:866-971-5464"} className={classes.bold}></Link>
                  <Link href={"mailto:info@CarrierHQ.com"} className={classes.bold}></Link>
                </Trans>
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default EldDialog;
