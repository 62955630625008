import { FailIcon, SuccessIcon } from "@chq/components";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  fade,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      padding: "1rem",
    },
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },
  contentBackground: {
    background: theme.palette.action.active,
    padding: "1rem",
    borderRadius: "4px",
  },
  bold: {
    fontWeight: "bold",
  },
  textCenter: {
    textAlign: "center",
  },
  button: {
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "1.125rem",
  },
  geotabButton: {
    background: theme.palette.success.main,
  },
  backButton: {
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
  },
  warningIcon: {
    marginBottom: "0.5rem",
  },
  successFailIcon: {
    display: "flex",
    justifyContent: "center",
    "& svg": {
      fontSize: "3.5rem",
    },
  },
  actionsContainer: {
    display: "flex",
    padding: 0,
    paddingTop: "1rem",
  },
  emailSentContainer: {
    margin: "0 auto",
    background: fade(theme.palette.success.main, 0.1),
    padding: "1rem",
    paddingLeft: 0,
    borderRadius: "4px",
    alignItems: "center",
  },
  greenText: {
    color: theme.palette.success.main,
  },
  errorContainer: {
    margin: "0 auto",
    background: fade(theme.palette.error.main, 0.1),
    padding: "1rem",
    paddingLeft: 0,
    borderRadius: "4px",
    alignItems: "center",
  },
  redText: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  variant?: "none" | "other" | "keepTruckin";
  open: boolean;
  onClose?: () => void;
  onClickEvent?: React.MouseEventHandler<HTMLButtonElement>;
  emailSent?: boolean;
  isIdle?: boolean;
  isError?: boolean;
  isLoading?: boolean;
};

const EldDialog: React.FC<Props> = ({
  variant = "none",
  open,
  onClose,
  onClickEvent,
  emailSent,
  isIdle,
  isError,
  isLoading,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <DialogContent>
        <Grid container className={classes.contentBackground}>
          <Grid container direction="row" justify="center">
            <WarningIcon color="primary" fontSize="large" className={classes.warningIcon} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans i18nKey={`finish-application-page.eld-dialog.${variant}-eld-content`}>
                <strong></strong>
                <Link href={"tel:866-971-5464"} className={classes.bold}>
                  {t("common.phone-link-aria-label")}
                </Link>
                <Link href={"mailto:info@CarrierHQ.com"} className={classes.bold}>
                  {t("common.email-link-aria-label")}
                </Link>
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        {(isIdle || isLoading) && (
          <Grid container spacing={smallScreen ? 0 : 2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={onClickEvent}
                className={classNames(classes.button, classes.geotabButton)}
                disabled={isLoading || isError || emailSent}
              >
                {isLoading ? (
                  <Grid item xs={12}>
                    <CircularProgress
                      color="primary"
                      size="2.5rem"
                      aria-label={t("common.circular-progress-aria-label")}
                    />
                  </Grid>
                ) : (
                  t("finish-application-page.eld-dialog.get-geotab-button-text")
                )}
              </Button>
            </Grid>
          </Grid>
        )}
        {emailSent && (
          <Grid
            container
            item
            xs={12}
            direction="row"
            alignContent="space-around"
            justify="center"
            className={classes.emailSentContainer}
          >
            <Grid item xs={3} sm={2} className={classNames(classes.successFailIcon, classes.greenText)}>
              <SuccessIcon />
            </Grid>
            <Grid item xs={9} sm={10}>
              <Typography variant="body1">
                <strong className={classes.greenText}>{t("finish-application-page.eld-dialog.thank-you")}</strong>
              </Typography>
              <Typography variant="body1">
                {smallScreen
                  ? t("finish-application-page.eld-dialog.ordered-geotab-short")
                  : t("finish-application-page.eld-dialog.ordered-geotab")}
              </Typography>
            </Grid>
          </Grid>
        )}
        {isError && (
          <Grid
            container
            item
            xs={12}
            direction="row"
            alignContent="space-around"
            justify="center"
            className={classes.errorContainer}
          >
            <Grid item xs={3} sm={2} className={classNames(classes.successFailIcon, classes.redText)}>
              <FailIcon color="error" />
            </Grid>
            <Grid item xs={9} sm={10}>
              <Typography variant="body1">
                <strong className={classes.redText}>{t("finish-application-page.eld-dialog.email-error-title")}</strong>
              </Typography>
              <Typography variant="body1">{t("finish-application-page.eld-dialog.email-error-message")}</Typography>
            </Grid>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EldDialog;
