/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum RegisteredTo {
  Company = "Company",
  LeasingCompany = "Leasing Company",
  Other = "Other",
}

export function RegisteredToFromJSON(json: any): RegisteredTo {
  return RegisteredToFromJSONTyped(json, false);
}

export function RegisteredToFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisteredTo {
  return json as RegisteredTo;
}

export function RegisteredToToJSON(value?: RegisteredTo | null): any {
  return value as any;
}
