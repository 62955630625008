/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { State, StateFromJSON, StateFromJSONTyped, StateToJSON } from "./";

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  address1?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  address2?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city?: string | null;
  /**
   *
   * @type {State}
   * @memberof Address
   */
  state?: State;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  readonly stateId?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  county?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zip?: string | null;
}

export function AddressFromJSON(json: any): Address {
  return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    address1: !exists(json, "address1") ? undefined : json["address1"],
    address2: !exists(json, "address2") ? undefined : json["address2"],
    city: !exists(json, "city") ? undefined : json["city"],
    state: !exists(json, "state") ? undefined : StateFromJSON(json["state"]),
    stateId: !exists(json, "stateId") ? undefined : json["stateId"],
    county: !exists(json, "county") ? undefined : json["county"],
    zip: !exists(json, "zip") ? undefined : json["zip"],
  };
}

export function AddressToJSON(value?: Address | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    address1: value.address1,
    address2: value.address2,
    city: value.city,
    state: StateToJSON(value.state),
    county: value.county,
    zip: value.zip,
  };
}
