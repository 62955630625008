import { CardField, CheckboxForm, EditableDeletableCard, Select, TooltipHelp, YesNoInput } from "@chq/components";
import { CompAndCollisionCoverage } from "@chq/policy-management-api";
import { Button, Grid, Theme, Typography } from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Formik, FormikConfig } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCoverageOptionTypes } from "../../data/useCoverageOptions";
import AdditionalInsuredForm, { FormProps as AdditionalInsuredFormProps } from "../additional-insured-form";
import { Fields as CheckboxFormFields, FormProps as AddressFormProps } from "../address-form";
import LossPayeeForm from "../loss-payee-form";
import TrailerPowerUnitForm, {
  TrailerPowerUnitFormProps,
  useFormikConfig as useTrailerPowerUnitFormikConfig,
} from "../trailer-power-unit-form";
import { Props as TrailerPowerUnitProps } from "../trailer-power-unit-form-wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    padding: "1.125rem 0rem",
  },
  fullWidth: {
    width: "100%",
    paddingBottom: "0.625rem",
  },
  secondaryContainer: {
    paddingTop: "1rem",
  },
  topDivider: {
    borderTop: `2px solid ${fade(theme.palette.grey[400], 0.4)}`,
  },
  divider: {
    borderBottom: `2px solid ${fade(theme.palette.grey[400], 0.4)}`,
  },
  extraFieldContainer: {
    padding: "0.625rem 0rem",
  },
  entityLabel: {
    paddingLeft: "0.625rem",
  },
  tooltipText: {
    color: theme.palette.common.white,
  },
  collisionInput: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      marginTop: "1rem",
    },
  },
  gapTooltip: {
    position: "absolute",
  },
}));

export enum Fields {
  vehicleNumber = "vehicle-number",
  vin = "vin",
  make = "make",
  model = "model",
  year = "year",
  statedValue = "stated-value",
  registrationZip = "registration-zip",
  destination = "destination",
  ooIC = "operator-or-contractor",
  whereIsUnitRegistered = "where-is-unit-registered",
  coverageOption = "coverage-option",
  gapCoverage = "gap-coverage",
  additionalInsured = "additional-insured",
  lossPayee = "loss-payee",
}

export type EquipmentFormProps = TrailerPowerUnitFormProps & {
  [Fields.additionalInsured]?: AdditionalInsuredFormProps;
  [Fields.lossPayee]?: AddressFormProps;
};

export type Props = Omit<TrailerPowerUnitProps, "onSubmit"> & {
  onSubmit?: FormikConfig<EquipmentFormProps>["onSubmit"];
  coverageOption?: string;
  gapCoverage?: string;
  statedValue?: number;
  additionalInsured?: boolean;
  lossPayee?: boolean;
  registrationZip?: string;
  additionalInsuredFormComplete?: boolean;
  lossPayeeFormComplete?: boolean;
  additionalInsuredForm?: AdditionalInsuredFormProps;
  lossPayeeForm?: AddressFormProps;
};

type CoverageProps = {
  coverageOption?: string;
  gapCoverage?: string;
  statedValue?: number;
  additionalInsured?: AdditionalInsuredFormProps;
  lossPayee?: AddressFormProps;
};

export type CheckboxForms = {
  additionalInsured: {
    complete?: boolean;
    checked?: boolean;
    values?: AdditionalInsuredFormProps;
  };
  lossPayee: {
    complete?: boolean;
    checked?: boolean;
    values?: AddressFormProps;
  };
};

export const useFormikConfig = ({ additionalInsured = {}, lossPayee = {} }: CoverageProps = {}): Omit<
  FormikConfig<{
    [Fields.additionalInsured]?: AdditionalInsuredFormProps;
    [Fields.lossPayee]?: AddressFormProps;
  }>,
  "onSubmit"
> => {
  return {
    initialValues: {
      [Fields.additionalInsured]: additionalInsured,
      [Fields.lossPayee]: lossPayee,
    },
    enableReinitialize: true,
    validateOnMount: true,
  };
};

export const EquipmentForm: React.FC<Props> = ({
  formContainerClass,
  variant,
  policyState,
  onDelete,
  unitCount = 1,
  onSubmit,
  additionalInsured = false,
  lossPayee = false,
  additionalInsuredForm = {},
  lossPayeeForm = {},
  additionalInsuredFormComplete = false,
  lossPayeeFormComplete = false,
  IconButtonProps,
  vehicleNumber = "",
  vin = "",
  make = "",
  model = "",
  year = undefined,
  statedValue = undefined,
  registrationZip = undefined,
  destination = "",
  ooIC = "",
  whereIsUnitRegistered = "",
  coverageOption = "",
  gapCoverage = "",
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const kebabCaseVariant = _.kebabCase(variant);
  const [checkboxForms, setCheckboxForms] = useState<CheckboxForms>({
    lossPayee: { complete: lossPayeeFormComplete, checked: lossPayee, values: lossPayeeForm },
    additionalInsured: {
      complete: additionalInsuredFormComplete,
      checked: additionalInsured,
      values: additionalInsuredForm,
    },
  });
  const coverageTypes = useCoverageOptionTypes();
  const coverageAndCheckboxFormConfig = useFormikConfig({
    coverageOption,
    gapCoverage,
    statedValue,
    additionalInsured: additionalInsuredForm,
    lossPayee: lossPayeeForm,
  });
  const trailerPowerUnitFormConfig = useTrailerPowerUnitFormikConfig({
    vehicleNumber,
    vin,
    make,
    model,
    year,
    registrationZip,
    destination,
    ooIC,
    whereIsUnitRegistered,
  });

  return (
    <Formik
      initialValues={{
        ...trailerPowerUnitFormConfig.initialValues,
        ...coverageAndCheckboxFormConfig.initialValues,
      }}
      validationSchema={trailerPowerUnitFormConfig.validationSchema.concat(
        coverageAndCheckboxFormConfig.validationSchema,
      )}
      onSubmit={(values, formikHelpers) => {
        onSubmit && onSubmit(values, formikHelpers);
      }}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const checkIfErrorIsShowing = () => {
          const errors = Object.keys(formik.errors);
          const isErrorShowing = Object.keys(formik.touched).map((touched) => {
            return errors.includes(touched);
          });
          return isErrorShowing.includes(true);
        };

        const areCheckboxFormsCompleted = () => {
          const checkboxes = Object.values(checkboxForms);
          const completionSummary = checkboxes.map((checkbox) => {
            if (checkbox.checked) {
              return checkbox.complete;
            } else return true;
          });
          return !completionSummary.includes(false);
        };

        return (
          <EditableDeletableCard
            className={formContainerClass}
            title={t(`trailer-power-unit-form.${kebabCaseVariant}-title`, { number: unitCount })}
            error={checkIfErrorIsShowing()}
            variant="delete"
            onDelete={onDelete}
            IconButtonProps={{
              "aria-label": t("trailer-power-unit-form.trashIcon"),
              ...IconButtonProps,
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <TrailerPowerUnitForm policyState={policyState} />
              <Grid item xs={12} className={classNames(classes.secondaryContainer, classes.topDivider)}>
                <Select
                  className={classes.collisionInput}
                  id={Fields.coverageOption}
                  name={Fields.coverageOption}
                  fullWidth
                  label={t(`power-units-card.${Fields.coverageOption}`)}
                  required
                  error={formik.touched[Fields.coverageOption] && Boolean(formik.errors[Fields.coverageOption])}
                  labelAdornment={
                    <TooltipHelp
                      title={
                        <>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="h4" component="strong" className={classes.tooltipText}>
                                {t(`power-units-card.coverage-option-comprehensive-title`)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.tooltipText}>
                                {t(`power-units-card.coverage-option-comprehensive-info`)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="h4" component="strong" className={classes.tooltipText}>
                                {t(`power-units-card.coverage-option-collision-title`)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={classes.tooltipText}>
                                {t(`power-units-card.coverage-option-collision-info`)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      }
                      placement="left-end"
                    />
                  }
                  items={coverageTypes.map((coverageType: CompAndCollisionCoverage) => ({
                    name: coverageType,
                    value: coverageType,
                  }))}
                  value={formik.values[Fields.coverageOption]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched[Fields.coverageOption] && formik.errors[Fields.coverageOption]}
                />
              </Grid>
              <Grid item xs={12} className={classes.divider}>
                <YesNoInput
                  id={Fields.gapCoverage}
                  name={Fields.gapCoverage}
                  label={t(`power-units-card.${Fields.gapCoverage}`)}
                  yesText={t("common.yes")}
                  noText={t("common.no")}
                  required
                  value={formik.values[Fields.gapCoverage]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[Fields.gapCoverage] && Boolean(formik.errors[Fields.gapCoverage])}
                  helperText={formik.touched[Fields.gapCoverage] && formik.errors[Fields.gapCoverage]}
                  labelAdornment={
                    <TooltipHelp
                      className={classes.gapTooltip}
                      title={
                        <Typography variant="h4" component="strong" className={classes.tooltipText}>
                          {t("power-units-card.gap-coverage-info")}
                        </Typography>
                      }
                      placement="left-end"
                    />
                  }
                />
              </Grid>
              <Grid container className={classes.secondaryContainer}>
                <div className={classes.fullWidth}>
                  <Grid container direction="column">
                    <Grid item>
                      <CheckboxForm
                        complete={checkboxForms.additionalInsured.complete}
                        currentCheckState={checkboxForms.additionalInsured.checked}
                        checkboxLabel={t(
                          `review-application-page.review-power-units-trailers-form.add-additional-insured.label`,
                        )}
                        editButtonAriaLabel={t(
                          "policy-management-page.equipment-page.equipment-form.edit-icon-additional-insured",
                        )}
                        tooltipText={t(
                          "review-application-page.review-power-units-trailers-form.add-additional-insured.tooltip",
                        )}
                        onCheck={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          if (event.target.checked) {
                            formik.setFieldValue(Fields.additionalInsured, checkboxForms.additionalInsured.values);
                          } else {
                            formik.setFieldValue(Fields.additionalInsured, {});
                          }
                          setCheckboxForms({
                            ...checkboxForms,
                            additionalInsured: { ...checkboxForms.additionalInsured, checked: event.target.checked },
                          });
                        }}
                        onEdit={() =>
                          setCheckboxForms({
                            ...checkboxForms,
                            additionalInsured: { ...checkboxForms.additionalInsured, complete: false },
                          })
                        }
                      >
                        {checkboxForms.additionalInsured.checked &&
                          (checkboxForms.additionalInsured.complete &&
                          checkboxForms.additionalInsured.values !== undefined ? (
                            <CardField
                              id={`additional-insured-form-info-${vin}`}
                              label={t(
                                `review-application-page.review-power-units-trailers-form.checkbox-form.entity-name.label`,
                              )}
                              values={[
                                { value: formik.values[Fields.additionalInsured]![CheckboxFormFields.entityName]! },
                              ]}
                              className={classes.extraFieldContainer}
                              labelClass={classes.entityLabel}
                            />
                          ) : (
                            <AdditionalInsuredForm
                              entityName={formik.values[Fields.additionalInsured]?.[CheckboxFormFields.entityName]}
                              addressLine1={formik.values[Fields.additionalInsured]?.[CheckboxFormFields.addressLine1]}
                              addressLine2={formik.values[Fields.additionalInsured]?.[CheckboxFormFields.addressLine2]}
                              city={formik.values[Fields.additionalInsured]?.[CheckboxFormFields.city]}
                              state={formik.values[Fields.additionalInsured]?.[CheckboxFormFields.state]}
                              zipcode={formik.values[Fields.additionalInsured]?.[CheckboxFormFields.zipcode]}
                              ein={formik.values[Fields.additionalInsured]?.[CheckboxFormFields.ein]}
                              onSubmit={(values) => {
                                setCheckboxForms({
                                  ...checkboxForms,
                                  additionalInsured: {
                                    ...checkboxForms.additionalInsured,
                                    complete: true,
                                    values: values,
                                  },
                                });
                                formik.setFieldValue(Fields.additionalInsured, values);
                              }}
                            />
                          ))}
                      </CheckboxForm>
                      <CheckboxForm
                        complete={checkboxForms.lossPayee.complete}
                        currentCheckState={checkboxForms.lossPayee.checked}
                        checkboxLabel={t(
                          `review-application-page.review-power-units-trailers-form.add-loss-payee.label`,
                        )}
                        editButtonAriaLabel={t(
                          "policy-management-page.equipment-page.equipment-form.edit-icon-loss-payee",
                        )}
                        tooltipText={t(
                          "review-application-page.review-power-units-trailers-form.add-loss-payee.tooltip",
                        )}
                        onCheck={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          if (event.target.checked) {
                            formik.setFieldValue(Fields.lossPayee, checkboxForms.lossPayee.values);
                          } else {
                            formik.setFieldValue(Fields.lossPayee, {});
                          }
                          setCheckboxForms({
                            ...checkboxForms,
                            lossPayee: { ...checkboxForms.lossPayee, checked: event.target.checked },
                          });
                        }}
                        onEdit={() =>
                          setCheckboxForms({
                            ...checkboxForms,
                            lossPayee: { ...checkboxForms.lossPayee, complete: false },
                          })
                        }
                      >
                        {checkboxForms.lossPayee.checked &&
                          (checkboxForms.lossPayee.complete && checkboxForms.lossPayee.values !== undefined ? (
                            <CardField
                              id={`loss-payee-form-info-${vin}`}
                              label={t(
                                `review-application-page.review-power-units-trailers-form.checkbox-form.entity-name.label`,
                              )}
                              values={[{ value: formik.values[Fields.lossPayee]![CheckboxFormFields.entityName]! }]}
                              className={classes.extraFieldContainer}
                              labelClass={classes.entityLabel}
                            />
                          ) : (
                            <LossPayeeForm
                              entityName={formik.values[Fields.lossPayee]?.[CheckboxFormFields.entityName]}
                              addressLine1={formik.values[Fields.lossPayee]?.[CheckboxFormFields.addressLine1]}
                              addressLine2={formik.values[Fields.lossPayee]?.[CheckboxFormFields.addressLine2]}
                              city={formik.values[Fields.lossPayee]?.[CheckboxFormFields.city]}
                              state={formik.values[Fields.lossPayee]?.[CheckboxFormFields.state]}
                              zipcode={formik.values[Fields.lossPayee]?.[CheckboxFormFields.zipcode]}
                              onSubmit={(values) => {
                                setCheckboxForms({
                                  ...checkboxForms,
                                  lossPayee: { ...checkboxForms.lossPayee, complete: true, values: values },
                                });
                                formik.setFieldValue(Fields.lossPayee, values);
                              }}
                            />
                          ))}
                      </CheckboxForm>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.addButton}
                  disabled={!formik.isValid || !areCheckboxFormsCompleted()}
                >
                  <Typography variant="h3" color="primary">
                    {t(`trailer-power-unit-form.${kebabCaseVariant}-add-button`)}
                  </Typography>
                </Button>
              </Grid>
            </form>
          </EditableDeletableCard>
        );
      }}
    </Formik>
  );
};

export default EquipmentForm;
