import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const CheckIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M8.99997 16.17L4.82997 12L3.40997 13.41L8.99997 19L21 7L19.59 5.59L8.99997 16.17Z" />
    </SvgIcon>
  );
};

export default CheckIcon;
