import { CardField, EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { fullName } from "../utils/full-name";

type Props = {
  driverNumber: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  cdlNumber: string;
  cdlState: string;
  violationDescription: string;
  onEdit: EditableDeletableCardProps["onEdit"];
};

const DriverCard: React.FC<Props> = ({
  driverNumber,
  firstName,
  lastName,
  dateOfBirth,
  cdlNumber,
  cdlState,
  violationDescription,
  onEdit,
}) => {
  const [t] = useTranslation();

  return (
    <EditableDeletableCard
      title={t("driver-card.driver", { number: driverNumber })}
      onEdit={onEdit}
      variant="edit"
      IconButtonProps={{ "aria-label": t("driver-card.edit-button") }}
    >
      <Grid container>
        <CardField id={"name"} label={t("driver-card.name")} values={[{ value: fullName(firstName, lastName) }]} />
        <CardField id={"dateOfBirth"} label={t("driver-card.dateOfBirth")} values={[{ value: dateOfBirth }]} />
        <CardField id={"cdlNumber"} label={t("driver-card.cdlNumber")} values={[{ value: cdlNumber }]} />
        <CardField id={"cdlState"} label={t("driver-card.cdlState")} values={[{ value: cdlState }]} />
        <CardField
          id={"violationDescription"}
          label={t("driver-card.violations")}
          values={[{ value: violationDescription === "" || undefined ? t("common.no") : t("common.yes") }]}
        />
      </Grid>
    </EditableDeletableCard>
  );
};

export default DriverCard;
