import { Policy, PolicyApi } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { RouteNames } from "../../utils/route-names";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const useBindPolicy = (): UseMutationResult<Policy, Error, void> => {
  const policyApi = useApi(PolicyApi);
  const { updateApplication } = useCachedApplication();

  const mutation = useMutation<Policy, Error, void>(async () => {
    const { data: application } = await policyApi.apiV10PolicyPut({
      applicationState: RouteNames.policyManagementHome,
    });
    if (application) {
      updateApplication(application);
    }

    return application!;
  });
  return mutation;
};
