/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Rates
 */
export interface Rates {
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  annualizedRate?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  weeklyRate?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  monthlyRate?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  downPayment?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  weeklyDownPayment?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  monthlyDownPayment?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  weeklyCargoPayment?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  monthlyCargoPayment?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  monthlyAutoLiability?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  weeklyAutoLiability?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  autoLiabilityAmount?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  cargoAmount?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  weeklySaasAmount?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  monthySaasAmount?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  rrgTechFeeAmount?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  combinedTechRrrgAmount?: number;
  /**
   *
   * @type {number}
   * @memberof Rates
   */
  total?: number;
}

export function RatesFromJSON(json: any): Rates {
  return RatesFromJSONTyped(json, false);
}

export function RatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Rates {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    annualizedRate: !exists(json, "annualizedRate") ? undefined : json["annualizedRate"],
    weeklyRate: !exists(json, "weeklyRate") ? undefined : json["weeklyRate"],
    monthlyRate: !exists(json, "monthlyRate") ? undefined : json["monthlyRate"],
    downPayment: !exists(json, "downPayment") ? undefined : json["downPayment"],
    weeklyDownPayment: !exists(json, "weeklyDownPayment") ? undefined : json["weeklyDownPayment"],
    monthlyDownPayment: !exists(json, "monthlyDownPayment") ? undefined : json["monthlyDownPayment"],
    weeklyCargoPayment: !exists(json, "weeklyCargoPayment") ? undefined : json["weeklyCargoPayment"],
    monthlyCargoPayment: !exists(json, "monthlyCargoPayment") ? undefined : json["monthlyCargoPayment"],
    monthlyAutoLiability: !exists(json, "monthlyAutoLiability") ? undefined : json["monthlyAutoLiability"],
    weeklyAutoLiability: !exists(json, "weeklyAutoLiability") ? undefined : json["weeklyAutoLiability"],
    autoLiabilityAmount: !exists(json, "autoLiabilityAmount") ? undefined : json["autoLiabilityAmount"],
    cargoAmount: !exists(json, "cargoAmount") ? undefined : json["cargoAmount"],
    weeklySaasAmount: !exists(json, "weeklySaasAmount") ? undefined : json["weeklySaasAmount"],
    monthySaasAmount: !exists(json, "monthySaasAmount") ? undefined : json["monthySaasAmount"],
    rrgTechFeeAmount: !exists(json, "rrgTechFeeAmount") ? undefined : json["rrgTechFeeAmount"],
    combinedTechRrrgAmount: !exists(json, "combinedTechRrrgAmount") ? undefined : json["combinedTechRrrgAmount"],
    total: !exists(json, "total") ? undefined : json["total"],
  };
}

export function RatesToJSON(value?: Rates | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    annualizedRate: value.annualizedRate,
    weeklyRate: value.weeklyRate,
    monthlyRate: value.monthlyRate,
    downPayment: value.downPayment,
    weeklyDownPayment: value.weeklyDownPayment,
    monthlyDownPayment: value.monthlyDownPayment,
    weeklyCargoPayment: value.weeklyCargoPayment,
    monthlyCargoPayment: value.monthlyCargoPayment,
    monthlyAutoLiability: value.monthlyAutoLiability,
    weeklyAutoLiability: value.weeklyAutoLiability,
    autoLiabilityAmount: value.autoLiabilityAmount,
    cargoAmount: value.cargoAmount,
    weeklySaasAmount: value.weeklySaasAmount,
    monthySaasAmount: value.monthySaasAmount,
    rrgTechFeeAmount: value.rrgTechFeeAmount,
    combinedTechRrrgAmount: value.combinedTechRrrgAmount,
    total: value.total,
  };
}
