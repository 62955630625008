/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum BillingCycle {
  Weekly = "Weekly",
  Monthly = "Monthly",
  Annual = "Annual",
  Factoring = "Factoring",
}

export function BillingCycleFromJSON(json: any): BillingCycle {
  return BillingCycleFromJSONTyped(json, false);
}

export function BillingCycleFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingCycle {
  return json as BillingCycle;
}

export function BillingCycleToJSON(value?: BillingCycle | null): any {
  return value as any;
}
