import { Footer as FooterPresentation } from "@chq/components";
import { makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTermsAndConditionLinks } from "../../data/enrollment";
import { useSendHelpEmailRequest } from "../../data/enrollment/useSendHelpEmailRequest";
import { useFormikConfig } from "../shared/validation-schema/validationSchema";

enum Fields {
  name = "name",
  email = "email",
  phone = "phone",
  problem = "problem",
  description = "description",
}

type FormProps = {
  [Fields.name]: string;
  [Fields.email]: string;
  [Fields.phone]: string;
  [Fields.problem]: string;
  [Fields.description]: string;
};

type Props = {
  onlyCarrierHQ?: boolean;
};

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
  },
}));

const Footer: React.FC<Props> = ({ onlyCarrierHQ }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const formikProps = useFormikConfig();
  const { mutate: sendEmail } = useSendHelpEmailRequest();
  const { data: terms } = useTermsAndConditionLinks({ enabled: !onlyCarrierHQ });
  const termsLink = terms?.termsOfUseLink;
  const privacyLink = terms?.privacyPolicyLink;

  //Get Array of Problems for footer email
  const listOfProblems = t("footer.problem-options", {
    returnObjects: true,
  });
  const problemOptionsArray: string[] = Object.values(listOfProblems);

  return (
    <div className={classes.footer}>
      <Formik
        {...formikProps}
        onSubmit={(values: FormProps) => {
          sendEmail({
            emailAddress: values[Fields.email],
            phoneNumber: values[Fields.phone],
            name: values[Fields.name],
            problem: values[Fields.problem],
            description:
              values[Fields.description] && values[Fields.description].replace(/\s/g, "").length !== 0
                ? values[Fields.description]
                : "N/A", //If a user only fills the description field with a blank, put in "N/A" because you cannot send a blank string
          });
        }}
      >
        <FooterPresentation
          onlyCarrierHQ={onlyCarrierHQ}
          termsLink={termsLink}
          privacyLink={privacyLink}
          nameLabel={t("footer.name")}
          phoneLabel={t("footer.phone")}
          emailLabel={t("footer.email")}
          problemLabel={t("footer.problem")}
          problemList={problemOptionsArray}
          problemListCondition={problemOptionsArray[6]}
          descriptionLabel={t("footer.description")}
          needHelpText={t("footer.need-help")}
          reachOutText={t("footer.reach-out")}
          privacyText={t("footer.privacy")}
          termsText={t("footer.terms", { year: new Date().getFullYear(), company: "CarrierHQ" })}
          legalText={t("footer.legal")}
        />
      </Formik>
    </div>
  );
};

export default Footer;
