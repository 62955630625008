/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  StripeCharge,
  StripeChargeFromJSON,
  StripeChargeFromJSONTyped,
  StripeChargeToJSON,
} from "./";

/**
 *
 * @export
 * @interface StripeChargeListApiResponse
 */
export interface StripeChargeListApiResponse {
  /**
   *
   * @type {Array<StripeCharge>}
   * @memberof StripeChargeListApiResponse
   */
  data?: Array<StripeCharge> | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof StripeChargeListApiResponse
   */
  error?: ErrorResponse;
}

export function StripeChargeListApiResponseFromJSON(json: any): StripeChargeListApiResponse {
  return StripeChargeListApiResponseFromJSONTyped(json, false);
}

export function StripeChargeListApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StripeChargeListApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
      ? null
      : (json["data"] as Array<any>).map(StripeChargeFromJSON),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function StripeChargeListApiResponseToJSON(value?: StripeChargeListApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
        ? null
        : (value.data as Array<any>).map(StripeChargeToJSON),
    error: ErrorResponseToJSON(value.error),
  };
}
