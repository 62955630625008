import {
  CardField,
  EditableDeletableCard,
  EditableDeletableCardProps,
  FailIcon,
  LoadingWheel,
  SuccessIcon,
} from "@chq/components";
import { FactoringApprovalStatus } from "@chq/enrollment-api";
import { Button, Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "1rem",
    paddingLeft: "0.5rem",
  },
  successIcon: {
    color: theme.palette.success.main,
    width: "auto",
    height: "40px",
  },
  bankInfo: {
    alignSelf: "center",
    "& > .MuiGrid-root > p": {
      display: "flex",
      alignItems: "center",
    },
  },
  factoringContainer: {
    marginTop: "0.625rem",
  },
  icons: {
    height: "2rem",
    width: "2rem",
  },
  error: {
    color: theme.palette.error.main,
  },
  link: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": { background: "none" },
  },
  loading: {
    animation: `$rotation 2s infinite linear`,
    minHeight: "3.5rem",
    width: "auto",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(359deg)",
    },
  },
}));

type Props = {
  bankInfo?: string | null;
  isLoading: boolean;
  isFactoring: boolean;
  factoringStatus?: FactoringApprovalStatus;
  editable?: boolean;
  onEdit?: EditableDeletableCardProps["onEdit"];
};

const ReviewPaymentMethodCard: React.FC<Props> = ({
  bankInfo,
  isLoading,
  isFactoring,
  factoringStatus,
  editable,
  onEdit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <EditableDeletableCard
      title={t("review-application-page.payment-method-card.title")}
      titleVariant="h2"
      className={classes.container}
      border={false}
      variant={editable ? "edit" : "default"}
      onEdit={onEdit}
      editTextVariant="h4"
      blueEdit={false}
      elevation={0}
      IconButtonProps={{ "aria-label": t("review-application-page.your-quote-card.edit-button") }}
    >
      {!isLoading ? (
        <Grid container direction="column">
          <CardField
            id={"bank-account-info"}
            label={t("review-application-page.payment-method-card.bank-account-label")}
            values={[
              {
                value: bankInfo ? (
                  <>
                    <SuccessIcon className={classes.successIcon} />
                    {bankInfo}
                  </>
                ) : (
                  <>
                    <Button
                      aria-label={t("review-application-page.payment-method-card.not-setup-aria-label")}
                      className={classes.link}
                      onClick={() => history.push(routes.enrollment.payment.path)}
                    >
                      <FailIcon color="error" className={classes.icons} />
                      <Typography variant="h4" component="h3" className={classes.error}>
                        {t("review-application-page.payment-method-card.not-setup")}
                      </Typography>
                    </Button>
                  </>
                ),
              },
            ]}
            className={classes.bankInfo}
          />
          {isFactoring && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <CardField
                id="factoring-application-status"
                label={t("review-application-page.payment-method-card.factoring-label")}
                values={[
                  {
                    value: t(
                      `review-application-page.payment-method-card.${
                        factoringStatus?.toString().toLowerCase() ||
                        t("review-application-page.payment-method-card.no-application")
                      }`,
                    ),
                  },
                ]}
                className={classes.factoringContainer}
              />
            </>
          )}
        </Grid>
      ) : (
        <LoadingWheel className={classes.loading} />
      )}
    </EditableDeletableCard>
  );
};

export default ReviewPaymentMethodCard;
