import { Header, NotFoundPage } from "@chq/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import MobileForestImage from "../../../images/forest-image-mobile.png";
import ForestImage from "../../../images/forest-image.png";
import Footer from "../../components/footer";
import { routes } from "../../routes";

const MissingPage: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <>
      <Header
        chqTitleAccess={t("common.chq-logo")}
        chqAriaLabel={t("common.chq-logo")}
        headerBarTitleAccess={t("common.middle-bar")}
        headerBarAriaLabel={t("common.middle-bar")}
      />
      <NotFoundPage
        desktopBackgroundImage={ForestImage}
        mobileBackgroundImage={MobileForestImage}
        numbersText={t("missing-page.404")}
        headerText={t("missing-page.header-text")}
        mainInfoText={t("missing-page.main-info")}
        buttonText={t("missing-page.home-button")}
        nextButtonOnClick={() => history.push(routes.enrollment.path)}
      />
      <Footer />
    </>
  );
};

export default MissingPage;
