import { CardField, EditableDeletableCard } from "@chq/components";
import { Equipment, EquipmentType } from "@chq/enrollment-api";
import { Divider, Grid, makeStyles, Theme } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  units: {
    display: "flex",
    whiteSpace: "pre-wrap",
  },
  title: {
    paddingTop: "1rem",
  },
  vehicleNumber: {
    paddingBottom: "0.25rem",
  },
  powerUnitContainer: {
    padding: "0.75rem 0rem 0.625rem 0rem",
  },
  divider: {
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
    height: "2px",
    width: "100%",
  },
}));

type Props = {
  equipmentType: EquipmentType;
  equipment: Equipment[];
};

const TrailersPowerUnitsCard: React.FC<Props> = ({ equipmentType, equipment }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const kebabCaseEquipmentType = _.kebabCase(equipmentType);
  return (
    <EditableDeletableCard
      title={t(`trailers-power-units-card.title-${kebabCaseEquipmentType}`, { numberOfUnits: equipment.length })}
      titleVariant="h2"
      titleComponent="h4"
      variant="default"
      border={false}
      className={classes.title}
    >
      <div>
        {equipment.length > 0 && (
          <>
            <Grid container className={classes.powerUnitContainer}>
              {equipment.map((unit, index) => (
                <React.Fragment key={`${unit.id}-${index}`}>
                  <CardField
                    key={`vehicleNumber-${index}`}
                    id={`${unit.vehicleNumber!}-${index}`}
                    labelColor="textPrimary"
                    label={unit.vehicleNumber!}
                    className={classes.vehicleNumber}
                  />
                  <CardField
                    key={`vin-${index}`}
                    id={`${unit.vin!}-${index}`}
                    label={t("power-units-card.vin")}
                    values={[{ value: unit.vin }]}
                  />
                  <CardField
                    key={`make-${index}`}
                    id={`${unit.make!}-${index}`}
                    label={t("power-units-card.make")}
                    values={[{ value: unit.make }]}
                  />
                  {unit.model !== undefined && (
                    <CardField
                      key={`model-${index}`}
                      id={`${unit.model!}-${index}`}
                      label={t("power-units-card.model")}
                      values={[{ value: unit.model }]}
                    />
                  )}

                  <CardField
                    key={`year-${index}`}
                    id={`${unit.year!}-${index}`}
                    label={t("power-units-card.year")}
                    values={[{ value: unit.year }]}
                  />
                  {equipment.length - 1 !== index && <Divider key={`divider-${index}`} className={classes.divider} />}
                </React.Fragment>
              ))}
            </Grid>
          </>
        )}
      </div>
    </EditableDeletableCard>
  );
};

export default TrailersPowerUnitsCard;
