/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DocumentType {
  Unknown = "Unknown",
  SfaargProposal = "SfaargProposal",
  Declarations = "Declarations",
  PolicyDocument = "PolicyDocument",
  RrgMembershipForm = "RrgMembershipForm",
  CargoFormSigned = "CargoFormSigned",
  CargoFormUnsigned = "CargoFormUnsigned",
  SpeedgaugeConsentForm = "SpeedgaugeConsentForm",
  UmUim = "UmUim",
  InsuranceApplication = "InsuranceApplication",
  EDeliveryConsent = "EDeliveryConsent",
  FraudStatement = "FraudStatement",
}

export function DocumentTypeFromJSON(json: any): DocumentType {
  return DocumentTypeFromJSONTyped(json, false);
}

export function DocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentType {
  return json as DocumentType;
}

export function DocumentTypeToJSON(value?: DocumentType | null): any {
  return value as any;
}
