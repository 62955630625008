import { createContext, Dispatch, SetStateAction, useState } from "react";
import { EquipmentList } from "../../ui/policy-management/pages/edit-equipment-page";

export type GlobalPolicyEquipmentContent = {
  policyEquipment: EquipmentList | undefined;
  updatePolicyEquipment: Dispatch<SetStateAction<EquipmentList | undefined>>;
};

export const PolicyEquipmentContext = createContext<GlobalPolicyEquipmentContent>({
  policyEquipment: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updatePolicyEquipment: () => {},
});

export const PolicyEquipmentProvider: React.FC = ({ children }) => {
  const [policyEquipment, updatePolicyEquipment] = useState<EquipmentList>();

  return (
    <PolicyEquipmentContext.Provider value={{ policyEquipment, updatePolicyEquipment }}>
      {children}
    </PolicyEquipmentContext.Provider>
  );
};
