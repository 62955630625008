import { SuccessIcon } from "@chq/components";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    alignSelf: "center",
  },
  successIcon: {
    width: "auto",
    height: "5rem",
    color: theme.palette.success.main,
  },
}));

type Props = {
  verified: boolean;
};

const SuccessCard: React.FC<Props> = ({ verified }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <Grid container item direction="column" alignContent="center">
      <Grid item className={classes.iconContainer}>
        <SuccessIcon className={classes.successIcon} />
      </Grid>
      <Grid item>
        <Typography variant="h4" component="p">
          {verified ? t("enrollment.success-card.account") : t("enrollment.success-card.verified")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SuccessCard;
