import { ELDProvider } from "@chq/enrollment-api";

export const getFirstEldProvider = (): ELDProvider[] => {
  return [ELDProvider.Geotab, ELDProvider.MotiveKeepTruckin, ELDProvider.Samsara, ELDProvider.Other, ELDProvider.None];
};

export const getOtherEldProvider = (): ELDProvider[] => {
  return [
    ELDProvider.AdvantageAssetTracking,
    ELDProvider.ActSoft,
    ELDProvider.Agilis,
    ELDProvider.AssuredTelematics,
    ELDProvider.Azuga,
    ELDProvider.EagleWireless,
    ELDProvider.EROAD,
    ELDProvider.EZFleet,
    ELDProvider.GoFleet,
    ELDProvider.GPSInsight,
    ELDProvider.Gridline,
    ELDProvider.InTouchGPS,
    ELDProvider.OnTrackSolutions,
    ELDProvider.Omnitrac,
    ELDProvider.RushEnterprises,
    ELDProvider.SafetyComplianceSolutions,
    ELDProvider.TeletracNavman,
    ELDProvider.TraxxisGPS,
    ELDProvider.Trimble,
    ELDProvider.Zonar,
    ELDProvider.Other,
  ];
};

export const getAllEldProviders = (): ELDProvider[] => {
  return [
    ELDProvider.AdvantageAssetTracking,
    ELDProvider.ActSoft,
    ELDProvider.Agilis,
    ELDProvider.AssuredTelematics,
    ELDProvider.Azuga,
    ELDProvider.EagleWireless,
    ELDProvider.EROAD,
    ELDProvider.EZFleet,
    ELDProvider.Geotab,
    ELDProvider.GoFleet,
    ELDProvider.GPSInsight,
    ELDProvider.Gridline,
    ELDProvider.InTouchGPS,
    ELDProvider.MotiveKeepTruckin,
    ELDProvider.OnTrackSolutions,
    ELDProvider.Omnitrac,
    ELDProvider.RushEnterprises,
    ELDProvider.SafetyComplianceSolutions,
    ELDProvider.Samsara,
    ELDProvider.TeletracNavman,
    ELDProvider.TraxxisGPS,
    ELDProvider.Trimble,
    ELDProvider.Zonar,
    ELDProvider.Other,
    ELDProvider.None,
  ];
};
