import {
  AuthenticationApi,
  ChangePasswordResponse,
  PortalApiV10AuthenticationChangePasswordPostRequest,
} from "@chq/policy-management-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi as usePolicyApi } from "./usePolicyManagementApi";

export const useChangePassword = (): UseMutationResult<
  ChangePasswordResponse,
  Error,
  PortalApiV10AuthenticationChangePasswordPostRequest
> => {
  const authenticationApi = usePolicyApi(AuthenticationApi);
  const mutation = useMutation<ChangePasswordResponse, Error, PortalApiV10AuthenticationChangePasswordPostRequest>(
    async (request: PortalApiV10AuthenticationChangePasswordPostRequest) => {
      const response = await authenticationApi.portalApiV10AuthenticationChangePasswordPost(request);
      return response.data!;
    },
  );
  return mutation;
};
