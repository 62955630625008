import { booleanToRadioValue, NextButton, radioValueToBoolean } from "@chq/components";
import { Button, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Formik } from "formik";
import _ from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import AddCoverageForm, {
  AddCoverageFormProps as CoverageInfo,
  Fields,
  useFormikConfig,
} from "../../../components/add-coverage-form";
import { PolicyCoveragesContext } from "../../../data/policy-management/PolicyManagementCoveragesContext";
import { coveragesFieldMapper } from "../../../utils/coverages-field-mapper";
import { getEquipmentFieldDifferences } from "../../../utils/get-equipment-field-differences";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "2.5rem 0.75rem",
  },
  title: {
    marginBottom: "1.125rem",
    textAlign: "center",
  },
  subtitle: {
    marginBottom: "0.5rem",
    textAlign: "center",
  },
  coveragesForm: {
    marginBottom: "1.5rem",
  },
  cancelButton: {
    minHeight: "56px",
  },
  topNextButton: {
    marginBottom: "1.625rem",
  },
  bottomNextButton: {
    marginBottom: "2.25rem",
  },
}));

const EditCoveragesPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const { policyCoverages, updatePolicyCoverages } = useContext(PolicyCoveragesContext);
  const formikProps = useFormikConfig({
    trailerInterchange: booleanToRadioValue(policyCoverages?.coverages?.trailerInterchangeCoverage),
    trailerCoverageLimit: policyCoverages
      ? policyCoverages.coverages
        ? policyCoverages.coverages.trailerInterchangeCoverageLimit!
        : undefined
      : undefined,
    hiredAuto: booleanToRadioValue(policyCoverages?.coverages?.hiredAutoCovers),
    uninsuredMotorist: booleanToRadioValue(policyCoverages?.coverages?.umUimCoverage),
    cargoInsurance: booleanToRadioValue(policyCoverages?.coverages?.cargoInsurance),
    cargoLimit: policyCoverages
      ? policyCoverages.coverages
        ? policyCoverages.coverages.cargoInsuranceLimit!.toString()
        : undefined
      : undefined,
    cargoDeductible: policyCoverages
      ? policyCoverages.coverages
        ? policyCoverages.coverages.cargoInsuranceDeductible!.toString()
        : undefined
      : undefined,
    reeferBreakdown: booleanToRadioValue(policyCoverages?.coverages?.cargoInsuranceReeferBreakdown),
  });
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Formik
        {...formikProps}
        onSubmit={(values: CoverageInfo) => {
          const mappedCoverages = coveragesFieldMapper(policyCoverages ? policyCoverages.coverages! : {});
          const differentFields = getEquipmentFieldDifferences(mappedCoverages, values) as string[];
          updatePolicyCoverages({
            coverages: {
              id: policyCoverages?.coverages.id,
              trailerInterchangeCoverage: radioValueToBoolean(values[Fields.trailerInterchange]),
              trailerInterchangeCoverageLimit: values[Fields.trailerCoverageLimit],
              hiredAutoCovers: radioValueToBoolean(values[Fields.hiredAuto]),
              umUimCoverage: radioValueToBoolean(values[Fields.uninsuredMotorist]),
              cargoInsurance: radioValueToBoolean(values[Fields.cargoInsurance]),
              cargoInsuranceLimit: values[Fields.cargoLimit] ? parseInt(values[Fields.cargoLimit]!) : undefined,
              cargoInsuranceDeductible: values[Fields.cargoDeductible]
                ? parseInt(values[Fields.cargoDeductible]!)
                : undefined,
              cargoInsuranceReeferBreakdown: radioValueToBoolean(values[Fields.reeferBreakdown]),
            },
            editedFields: _.uniq(_.concat(policyCoverages?.editedFields, differentFields) as string[]),
          });
          history.push(routes.policyManagement.equipment.edit.path);
        }}
      >
        {(formik) => (
          <Grid container className={classes.container} justify="center">
            {isExtraSmall && (
              <Grid item xs={12} sm={8}>
                <NextButton
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  className={classes.topNextButton}
                >
                  {t("policy-management-page.coverages-page.edit-coverages-next-button")}
                </NextButton>
              </Grid>
            )}
            <Grid container item direction="column" xs={12}>
              <Grid item className={classes.title}>
                <Typography variant="h2">
                  {t("policy-management-page.coverages-page.update-coverages-and-equipment")}
                </Typography>
              </Grid>
              <Grid item className={classes.subtitle}>
                <Typography variant="h4" component="h3">
                  {t("policy-management-page.coverages-page.coverages-form-page-subtitle")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={11} md={8} className={classes.coveragesForm}>
              <AddCoverageForm />
            </Grid>
            <Grid container item xs={12} sm={8} justify="space-between">
              <Grid item xs={12} md={5}>
                <NextButton
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  className={classes.bottomNextButton}
                >
                  {t("policy-management-page.coverages-page.edit-coverages-next-button")}
                </NextButton>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.cancelButton}
                  onClick={() => history.push(routes.policyManagement.coverages.path)}
                >
                  {t("common.cancel")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default EditCoveragesPage;
