import {
  FormControl as MuiFormControl,
  FormControlProps as MuiFormControlProps,
  FormHelperText,
  FormHelperTextProps,
  InputLabelProps,
} from "@material-ui/core";
import React from "react";
import { Label } from "./label";

export type FormControlProps = MuiFormControlProps & {
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  helperTextId?: string;
  helperText?: React.ReactNode;
  id?: string;
  InputLabelProps?: Partial<InputLabelProps>;
  labelId?: string;
  label?: React.ReactNode;
  labelAdornment?: React.ReactNode;
  labelEmbellishment?: React.ReactNode;
};

const FormControl: React.FC<FormControlProps> = ({
  children,
  FormHelperTextProps,
  helperTextId,
  helperText,
  id,
  InputLabelProps,
  labelId,
  label,
  labelAdornment,
  labelEmbellishment,
  ...FormControlProps
}) => {
  return (
    <MuiFormControl {...FormControlProps}>
      {label && (
        <Label htmlFor={id} id={labelId} labelAdornment={labelAdornment} {...InputLabelProps}>
          {label}
        </Label>
      )}

      {labelEmbellishment}
      {children}

      {helperText && (
        <FormHelperText id={helperTextId} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </MuiFormControl>
  );
};

export default FormControl;
