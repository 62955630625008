/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ChangePasswordRequest,
  ChangePasswordRequestFromJSON,
  ChangePasswordRequestToJSON,
  ChangePasswordResponseApiResponse,
  ChangePasswordResponseApiResponseFromJSON,
  ChangePasswordResponseApiResponseToJSON,
} from "../models";

export interface PortalApiV10AuthenticationChangePasswordPostRequest {
  changePasswordRequest?: ChangePasswordRequest;
}

/**
 *
 */
export class AuthenticationApi extends runtime.BaseAPI {
  /**
   */
  async portalApiV10AuthenticationChangePasswordPostRaw(
    requestParameters: PortalApiV10AuthenticationChangePasswordPostRequest,
  ): Promise<runtime.ApiResponse<ChangePasswordResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/portal/api/v1.0/Authentication/ChangePassword`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ChangePasswordRequestToJSON(requestParameters.changePasswordRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ChangePasswordResponseApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async portalApiV10AuthenticationChangePasswordPost(
    requestParameters: PortalApiV10AuthenticationChangePasswordPostRequest,
  ): Promise<ChangePasswordResponseApiResponse> {
    const response = await this.portalApiV10AuthenticationChangePasswordPostRaw(requestParameters);
    return await response.value();
  }
}
