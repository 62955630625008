/* eslint-disable @typescript-eslint/no-explicit-any */
import { NumericInput, Select, TextInput, XIcon as CloseIcon } from "@chq/components";
import { Button, Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useBankAccountType } from "../data/useBankAccountTypes";
import checkAccountHint from "../images/check-account-hint.svg";
import checkNoHint from "../images/check-no-hint.svg";
import checkRoutingHint from "../images/check-routing-hint.svg";

const useStyles = makeStyles((theme: Theme) => ({
  checkPicture: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[200],
    padding: "0.5rem 0.25rem",
  },
  errorMessageGeneric: {
    color: theme.palette.error.main,
    fontSize: ".75rem",
    marginTop: "1rem",
  },
  formContainer: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  greyBackground: {
    backgroundColor: theme.palette.grey[200],
    padding: "0.5rem",
  },
  submitButton: {
    minHeight: "56px",
  },
  fullName: {
    marginBottom: "6rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },
  },
  bankNumbers: {
    marginTop: "1rem",
  },
  zoomOverflow: {
    overflowY: "auto",
  },
}));

export enum Fields {
  accountType = "account-type",
  bankType = "bank-type",
  routingNumber = "routing-number",
  accountNumber = "account-number",
  fullName = "full-name",
}

export type AddBankAccountFormProps = {
  [Fields.accountType]: string;
  [Fields.accountNumber]: string;
  [Fields.bankType]: string;
  [Fields.routingNumber]: string;
  [Fields.accountNumber]: string;
  [Fields.fullName]: string;
};

type Props = {
  accountType?: string;
  bankType?: string;
  routingNumber?: string;
  accountNumber?: string;
  fullName?: string;
  error?: Error;
  onClose?: () => void;
  onSubmit?: FormikConfig<AddBankAccountFormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.accountType]: Yup.string().required(
      t(`errors.required`, {
        field: t(`finish-application.payment-method-form.add-account-manual-form.${Fields.accountType}.label`),
      }),
    ),
    [Fields.routingNumber]: Yup.string()
      .test(
        "length",
        t("errors.exact-digits", {
          field: t(`finish-application.payment-method-form.add-account-manual-form.${Fields.routingNumber}.label`),
          exact: 9,
        }),
        (val) => val !== undefined && val.toString().length === 9,
      )
      .required(
        t(`errors.required`, {
          field: t(`finish-application.payment-method-form.add-account-manual-form.${Fields.routingNumber}.label`),
        }),
      ),
    [Fields.accountNumber]: Yup.string()
      .required(
        t(`errors.required`, {
          field: t(`finish-application.payment-method-form.add-account-manual-form.${Fields.accountNumber}.label`),
        }),
      )
      .min(
        7,
        t("errors.min", {
          field: t(`finish-application.payment-method-form.add-account-manual-form.${Fields.accountNumber}.label`),
          min: 7,
        }),
      )
      .max(
        17,
        t("errors.max", {
          field: t(`finish-application.payment-method-form.add-account-manual-form.${Fields.accountNumber}.label`),
          max: 17,
        }),
      ),
    [Fields.fullName]: Yup.string().required(
      t(`errors.required`, {
        field: t(`finish-application.payment-method-form.add-account-manual-form.${Fields.fullName}.help-text`),
      }),
    ),
  });
};

const AddBankAccountManualModal: React.FC<Props> = ({
  accountType = "",
  bankType = "",
  routingNumber = "",
  accountNumber = "",
  fullName = "",
  error,
  onClose,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const accountTypes = useBankAccountType();
  const validationSchema = useValidationSchema();
  const [focusedField, setFocusedField] = useState<Fields | undefined>(undefined);

  const referenceImage = () => {
    switch (focusedField) {
      case Fields.routingNumber:
        return checkRoutingHint;
      case Fields.accountNumber:
        return checkAccountHint;
      default:
        return checkNoHint;
    }
  };
  const formik = useFormik<AddBankAccountFormProps>({
    initialValues: {
      [Fields.accountType]: accountType,
      [Fields.bankType]: bankType,
      [Fields.routingNumber]: routingNumber,
      [Fields.accountNumber]: accountNumber,
      [Fields.fullName]: fullName,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
  });

  return (
    <div className={classes.zoomOverflow}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container justify="center" className={classes.formContainer}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2">
                {t("finish-application.payment-method-form.add-account-manual-form.title")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label={t("finish-application.payment-method-form.add-account-manual-form.close-icon-label")}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              fullWidth
              id={Fields.accountType}
              name={Fields.accountType}
              label={t(`finish-application.payment-method-form.add-account-manual-form.${Fields.accountType}.label`)}
              items={accountTypes.map((type) => ({
                name: t(`finish-application.payment-method-form.add-account-manual-form.${Fields.accountType}.${type}`),
                value: type,
              }))}
              value={formik.values[Fields.accountType]}
              required
              onChange={formik.handleChange}
              onFocus={() => setFocusedField(Fields.accountType)}
              onBlur={(e: any) => {
                formik.handleBlur(e);
                setFocusedField(undefined);
              }}
              error={formik.touched[Fields.accountType] && Boolean(formik.errors[Fields.accountType])}
              helperText={formik.touched[Fields.accountType] && formik.errors[Fields.accountType]}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Paper className={classes.checkPicture} square elevation={0}>
              <img
                src={referenceImage()}
                alt={t("finish-application.payment-method-form.add-account-manual-form.check-alt-text")}
              />
            </Paper>
          </Grid>

          {error && (
            <Grid item container>
              <Grid item xs={12}>
                <Typography className={classes.errorMessageGeneric} component="p" variant="h4">
                  {error.message}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container item direction="row" spacing={1} className={classes.bankNumbers}>
            <Grid item xs={12} sm={6}>
              <NumericInput
                id={Fields.routingNumber}
                name={Fields.routingNumber}
                fullWidth
                label={t(
                  `finish-application.payment-method-form.add-account-manual-form.${Fields.routingNumber}.label`,
                )}
                decimalScale={0}
                allowLeadingZeros
                format="#########"
                value={formik.values[Fields.routingNumber]}
                required
                onChange={formik.handleChange}
                onFocus={() => setFocusedField(Fields.routingNumber)}
                onBlur={(e: any) => {
                  formik.handleBlur(e);
                  setFocusedField(undefined);
                }}
                error={formik.touched[Fields.routingNumber] && Boolean(formik.errors[Fields.routingNumber])}
                helperText={formik.touched[Fields.routingNumber] && formik.errors[Fields.routingNumber]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumericInput
                id={Fields.accountNumber}
                name={Fields.accountNumber}
                fullWidth
                label={t(
                  `finish-application.payment-method-form.add-account-manual-form.${Fields.accountNumber}.label`,
                )}
                decimalScale={0}
                allowLeadingZeros
                value={formik.values[Fields.accountNumber]}
                required
                onChange={formik.handleChange}
                onFocus={() => setFocusedField(Fields.accountNumber)}
                onBlur={(e: any) => {
                  formik.handleBlur(e);
                  setFocusedField(undefined);
                }}
                error={formik.touched[Fields.accountNumber] && Boolean(formik.errors[Fields.accountNumber])}
                helperText={formik.touched[Fields.accountNumber] && formik.errors[Fields.accountNumber]}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.fullName}>
            <TextInput
              id={Fields.fullName}
              name={Fields.fullName}
              fullWidth
              label={t(`finish-application.payment-method-form.add-account-manual-form.${Fields.fullName}.label`)}
              value={formik.values[Fields.fullName]}
              required
              onChange={formik.handleChange}
              onFocus={() => setFocusedField(Fields.fullName)}
              onBlur={(e) => {
                formik.handleBlur(e);
                setFocusedField(undefined);
              }}
              error={formik.touched[Fields.fullName] && Boolean(formik.errors[Fields.fullName])}
              helperText={formik.touched[Fields.fullName] && formik.errors[Fields.fullName]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper square elevation={0} className={classes.greyBackground}>
            <Grid container item xs={12} justify="center">
              <Grid item xs={12} sm={8}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!formik.isValid || !formik.dirty}
                  type="submit"
                  className={classes.submitButton}
                  disableElevation
                >
                  {t("finish-application.payment-method-form.add-account-manual-form.submit-button")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>
    </div>
  );
};

export default AddBankAccountManualModal;
