/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ViolationTypes {
  AccidentAtFault = "Accident - At Fault",
  AccidentNotAtFault = "Accident - Not At Fault",
  Speeding = "Speeding",
}

export function ViolationTypesFromJSON(json: any): ViolationTypes {
  return ViolationTypesFromJSONTyped(json, false);
}

export function ViolationTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViolationTypes {
  return json as ViolationTypes;
}

export function ViolationTypesToJSON(value?: ViolationTypes | null): any {
  return value as any;
}
