import { CardField, EditableDeletableCard, EditableDeletableCardProps, YesNoValues } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.grey[600],
    padding: "1.125rem .625rem 1rem .625rem",
  },
}));

type Props = {
  name: string;
  title: string;
  email: string;
  monthlyRevenue: string;
  truckCount: number;
  currentFactoring: string;
  factoringCompany?: string;
  onEdit?: EditableDeletableCardProps["onEdit"];
};

const FactoringContactCard: React.FC<Props> = ({
  name,
  title,
  email,
  monthlyRevenue,
  truckCount,
  currentFactoring,
  factoringCompany,
  onEdit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard
      variant="edit"
      title={t("factoring.contact-card.title")}
      titleVariant="h2"
      titleComponent="h3"
      onEdit={onEdit}
      IconButtonProps={{ "aria-label": t("factoring.contact-card.edit-button") }}
      className={classes.container}
    >
      <Grid container></Grid>
      <CardField id="name" label={t("factoring.contact-card.name")} values={[{ value: name }]} />
      <CardField id="title" label={t("factoring.contact-card.owner-title")} values={[{ value: title }]} />
      <CardField id="email" label={t("factoring.contact-card.email")} values={[{ value: email }]} />
      <CardField
        id="monthly-revenue"
        label={t("factoring.contact-card.monthly-revenue")}
        values={[{ value: t("factoring.contact-card.monthly-revenue-value", { money: parseInt(monthlyRevenue) }) }]}
      />
      <CardField
        id="truck-count"
        label={t("factoring.contact-card.truck-count")}
        values={[{ value: truckCount.toString() }]}
      />
      <CardField
        id="current-factoring"
        label={t("factoring.contact-card.current-factoring")}
        values={[{ value: currentFactoring === YesNoValues.yes ? t("common.yes") : t("common.no") }]}
      />
      {factoringCompany && (
        <CardField
          id="factoring-company"
          label={t("factoring.contact-card.factoring-company")}
          values={[{ value: factoringCompany }]}
        />
      )}
    </EditableDeletableCard>
  );
};

export default FactoringContactCard;
