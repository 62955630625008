/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Address, AddressFromJSON, AddressFromJSONTyped, AddressToJSON } from "./";

/**
 *
 * @export
 * @interface LossPayee
 */
export interface LossPayee {
  /**
   *
   * @type {number}
   * @memberof LossPayee
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof LossPayee
   */
  name?: string | null;
  /**
   *
   * @type {Address}
   * @memberof LossPayee
   */
  address?: Address;
}

export function LossPayeeFromJSON(json: any): LossPayee {
  return LossPayeeFromJSONTyped(json, false);
}

export function LossPayeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LossPayee {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    address: !exists(json, "address") ? undefined : AddressFromJSON(json["address"]),
  };
}

export function LossPayeeToJSON(value?: LossPayee | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    address: AddressToJSON(value.address),
  };
}
