import React from "react";
import RadioInput, { RadioInputProps } from "./radio-input";

export enum YesNoValues {
  yes = "1",
  no = "0",
}

type YesNoInputProps = Omit<RadioInputProps, "choices"> & {
  yesText: string;
  noText: string;
};

const YesNoInput: React.FC<YesNoInputProps> = ({ yesText, noText, ...RadioInputProps }) => {
  return (
    <RadioInput
      {...RadioInputProps}
      choices={[
        { label: yesText, value: YesNoValues.yes },
        { label: noText, value: YesNoValues.no },
      ]}
    />
  );
};

export default YesNoInput;
