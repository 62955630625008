import { CardField, EditableDeletableCard, PencilIcon, SuccessIcon } from "@chq/components";
import { Button, fade, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: ".5rem",
  },
  bankAccount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bankAccountValue: {
    display: "flex",
    justifyContent: "center",
  },
  phoneNumber: {
    display: "flex",
    whiteSpace: "break-spaces",
  },
  setUpButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginTop: "0.75rem",
    minHeight: "3.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    fontSize: "0.75rem",
    lineHeight: "1rem",
    textTransform: "uppercase",
    "& .MuiButton-label": {
      justifyContent: "space-between",
    },
  },
  icon: {
    height: "2.5rem",
    width: "auto",
    color: theme.palette.success.main,
  },
  pencilIcon: {
    height: "2.5rem",
    width: "auto",
  },
  bankAccountButton: {
    display: "flex",
    alignItems: "center",
  },
  textColor: {
    color: `${theme.palette.secondary.main} !important`,
  },
  updatePayment: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  cancel: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    color: theme.palette.primary.main,
  },
  billingCycle: {
    color: theme.palette.grey[400],
  },
}));

type Props = {
  paymentMethod: string;
  billingCycle: string;
  bankAccount: string;
};

const PaymentForm: React.FC<Props> = ({ paymentMethod, billingCycle, bankAccount }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSuccessful] = useState<boolean>(false); // this will be used once Plaid is hooked up I am assuming
  return (
    <EditableDeletableCard
      variant={isEditing ? "default" : "edit"}
      title={t("policy-management-page.payment-form.title")}
      titleVariant="h2"
      titleComponent="h3"
      border={false}
      className={classNames({ [classes.container]: isEditing })}
      onEdit={() => setIsEditing(true)}
      IconButtonProps={{ "aria-label": t("policy-management-page.payment-form.edit-button") }}
      bannerText={isSuccessful ? t("policy-management-page.payment-form.updated-sucessful") : ""}
    >
      {!isEditing && (
        <Grid container className={classes.container}>
          <CardField
            id="payment-method"
            label={t("policy-management-page.payment-form.payment-method.label")}
            values={[{ value: paymentMethod }]}
          />
          <CardField
            id="billing-cycle"
            label={t("policy-management-page.payment-form.billing-cycle.label")}
            values={[{ value: billingCycle }]}
          />
          <CardField
            id="bank-account"
            label={t("policy-management-page.payment-form.bank-account.label")}
            values={[{ value: bankAccount }]}
            valueSuccessIcon
            labelClass={classes.bankAccount}
            valueClass={classes.bankAccountValue}
          />
        </Grid>
      )}
      {isEditing && (
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("policy-management-page.payment-form.billing-cycle.label")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.billingCycle}>
              {billingCycle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="span" className={classes.phoneNumber}>
              <Trans i18nKey={"policy-management-page.payment-form.billing-frequency"}>
                <Typography variant="body1">To change billing frequency call</Typography>
                <Link href="800-492-5756">800-492-5756</Link>
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              className={classes.setUpButton}
              endIcon={<PencilIcon className={classes.pencilIcon} color="disabled" />}
              onClick={() => alert("Clicked")}
            >
              <div className={classes.bankAccountButton}>
                <SuccessIcon className={classes.icon} />
                <Typography variant="h4" color="primary" className={classes.textColor}>
                  {bankAccount}
                </Typography>
              </div>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              disabled={!isSuccessful}
              fullWidth
              onClick={() => alert("Clicked")}
              className={classes.updatePayment}
            >
              <Typography variant="h3">{t("policy-management-page.payment-form.update-payment")}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" fullWidth onClick={() => setIsEditing(false)} className={classes.updatePayment}>
              <Typography variant="h3">{t("policy-management-page.payment-form.cancel")}</Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </EditableDeletableCard>
  );
};

export default PaymentForm;
