import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "56px",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    minWidth: "2.5rem",
    "&.end-icon": {
      justifyContent: "flex-end",
    },
    "&.start-icon": {
      justifyContent: "flex-start",
    },
  },
  content: ({ hasStartSpace, hasEndSpace }: StyleProps) => {
    return {
      display: hasStartSpace && hasEndSpace ? "block" : "none",
      flexGrow: hasStartSpace && hasEndSpace ? 2 : 1,
    };
  },
  buttonContainer: ({ hasStartSpace, hasEndSpace }: StyleProps) => {
    return {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: hasStartSpace && hasEndSpace ? "space-between" : "center",
    };
  },
}));

type StyleProps = {
  hasStartSpace: boolean;
  hasEndSpace: boolean;
};

const CommonButton: React.FC<ButtonProps> = ({ startIcon, endIcon, children, className, ...buttonProps }) => {
  const hasEndSpace = Boolean(endIcon || (children && startIcon));
  const hasStartSpace = Boolean(startIcon || (children && endIcon));
  const classes = useStyles({ hasStartSpace, hasEndSpace });

  return (
    <Button className={classNames(classes.root, className)} {...buttonProps}>
      <div className={classes.buttonContainer}>
        {hasStartSpace && <div className={classNames(classes.icon, { "start-icon": hasEndSpace })}>{startIcon}</div>}
        <div className={classes.content}>{children}</div>
        {hasEndSpace && <div className={classNames(classes.icon, { "end-icon": hasStartSpace })}>{endIcon}</div>}
      </div>
    </Button>
  );
};

export default CommonButton;
