/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  IndividualBalance,
  IndividualBalanceFromJSON,
  IndividualBalanceFromJSONTyped,
  IndividualBalanceToJSON,
} from "./";

/**
 *
 * @export
 * @interface StripeBalance
 */
export interface StripeBalance {
  /**
   *
   * @type {Array<IndividualBalance>}
   * @memberof StripeBalance
   */
  available?: Array<IndividualBalance> | null;
  /**
   *
   * @type {Array<IndividualBalance>}
   * @memberof StripeBalance
   */
  pending?: Array<IndividualBalance> | null;
}

export function StripeBalanceFromJSON(json: any): StripeBalance {
  return StripeBalanceFromJSONTyped(json, false);
}

export function StripeBalanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeBalance {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    available: !exists(json, "available")
      ? undefined
      : json["available"] === null
      ? null
      : (json["available"] as Array<any>).map(IndividualBalanceFromJSON),
    pending: !exists(json, "pending")
      ? undefined
      : json["pending"] === null
      ? null
      : (json["pending"] as Array<any>).map(IndividualBalanceFromJSON),
  };
}

export function StripeBalanceToJSON(value?: StripeBalance | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    available:
      value.available === undefined
        ? undefined
        : value.available === null
        ? null
        : (value.available as Array<any>).map(IndividualBalanceToJSON),
    pending:
      value.pending === undefined
        ? undefined
        : value.pending === null
        ? null
        : (value.pending as Array<any>).map(IndividualBalanceToJSON),
  };
}
