import actionCreatorFactory from "typescript-fsa";
import { FactoringBusinessDetailsProps as BusinessDetailsFormProps } from "../../components/factoring-business-details";
import { FactoringContactFormProps as BasicInformationFormProps } from "../../components/factoring-contact-form";
import { FormProps as OwnerInformationFormProps } from "../../components/factoring-owner-information-form";
import { FormProps as ShipperBrokerFormProps } from "../../components/factoring-shipper-broker-form";

const actionCreator = actionCreatorFactory();

export const completeBasicInformationForm = actionCreator<BasicInformationFormProps>("COMPLETE_BASIC_INFORMATION_FORM");

export const startBasicInformationForm = actionCreator("START_BASIC_INFORMATION_FORM");

export const startBusinessDetailsForm = actionCreator("START_BUSINESS_DETAILS_FORM");

export const completeBusinessDetailsForm = actionCreator<BusinessDetailsFormProps>("COMPLETE_BUSINESS_DETAILS_FORM");

export const startOwnerInformationForm = actionCreator("START_OWNER_INFORMATION_FORM");

export const completeOwnerInformationForm = actionCreator<OwnerInformationFormProps>("COMPLETE_OWNER_INFORMATION_FORM");

export const startShipperBrokerInfo = actionCreator("START_SHIPPER_BROKER_INFO");

export const completeEmptyShipperBrokerInfo = actionCreator("COMPLETE_EMPTY_SHIPPER_BROKER_INFO");

export const completeShipperBrokerInfo = actionCreator<ShipperBrokerFormProps>("COMPLETE_SHIPPER_BROKER_INFO");

export const startDocumentsForm = actionCreator("START_DOCUMENTS_FORM");

export const completeDocumentsForm = actionCreator("COMPLETE_DOCUMENTS_FORM");

export const startBankInformationForm = actionCreator("START_BANK_INFORMATION_FORM");

export const completeBankInformationForm = actionCreator("COMPLETE_BANK_INFORMATION_FORM");

export const startPlaidFailFlow = actionCreator("START_PLAID_FAIL_FLOW");

export const resetPlaidFailFlow = actionCreator("RESET_PLAID_FAIL_FLOW");

export const startPlaidSuccessFlow = actionCreator("START_PLAID_SUCCESS_FLOW");

export const openAddAccountDialog = actionCreator("OPEN_ADD_ACCOUNT_DIALOG");

export const closeAddAccountDialog = actionCreator("CLOSE_ADD_ACCOUNT_DIALOG");

export const submitAddAccountForm = actionCreator("SUBMIT_ADD_ACCOUNT_FORM");

export const openVerifyAccountDialog = actionCreator("OPEN_VERIFY_ACCOUNT_DIALOG");

export const closeVerifyAccountDialog = actionCreator("CLOSE_VERIFY_ACCOUNT_DIALOG");

export const submitVerifyAccountForm = actionCreator("SUBMIT_VERIFY_ACCOUNT_FORM");
