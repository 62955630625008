/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { BillingCycle, BillingCycleFromJSON, BillingCycleFromJSONTyped, BillingCycleToJSON } from "./";

/**
 *
 * @export
 * @interface OwnerBankAccountInfo
 */
export interface OwnerBankAccountInfo {
  /**
   *
   * @type {string}
   * @memberof OwnerBankAccountInfo
   */
  paymentMethod?: string | null;
  /**
   *
   * @type {BillingCycle}
   * @memberof OwnerBankAccountInfo
   */
  paymentCycle?: BillingCycle;
  /**
   *
   * @type {string}
   * @memberof OwnerBankAccountInfo
   */
  bankAccountInfo?: string | null;
}

export function OwnerBankAccountInfoFromJSON(json: any): OwnerBankAccountInfo {
  return OwnerBankAccountInfoFromJSONTyped(json, false);
}

export function OwnerBankAccountInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerBankAccountInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    paymentMethod: !exists(json, "paymentMethod") ? undefined : json["paymentMethod"],
    paymentCycle: !exists(json, "paymentCycle") ? undefined : BillingCycleFromJSON(json["paymentCycle"]),
    bankAccountInfo: !exists(json, "bankAccountInfo") ? undefined : json["bankAccountInfo"],
  };
}

export function OwnerBankAccountInfoToJSON(value?: OwnerBankAccountInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    paymentMethod: value.paymentMethod,
    paymentCycle: BillingCycleToJSON(value.paymentCycle),
    bankAccountInfo: value.bankAccountInfo,
  };
}
