/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  RadiusOfOperation,
  RadiusOfOperationFromJSON,
  RadiusOfOperationFromJSONTyped,
  RadiusOfOperationToJSON,
  State,
  StateFromJSON,
  StateFromJSONTyped,
  StateToJSON,
} from "./";

/**
 *
 * @export
 * @interface ApplicationCreateRequest
 */
export interface ApplicationCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ApplicationCreateRequest
   */
  userEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApplicationCreateRequest
   */
  dotNumber?: string | null;
  /**
   *
   * @type {State}
   * @memberof ApplicationCreateRequest
   */
  stateOfOperation?: State;
  /**
   *
   * @type {RadiusOfOperation}
   * @memberof ApplicationCreateRequest
   */
  radiusOfOperation?: RadiusOfOperation;
  /**
   *
   * @type {number}
   * @memberof ApplicationCreateRequest
   */
  radiusOfOperationInt?: number;
  /**
   *
   * @type {number}
   * @memberof ApplicationCreateRequest
   */
  powerUnitCount?: number;
  /**
   *
   * @type {string}
   * @memberof ApplicationCreateRequest
   */
  applicationState?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApplicationCreateRequest
   */
  partnerName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ApplicationCreateRequest
   */
  campaignId?: string | null;
}

export function ApplicationCreateRequestFromJSON(json: any): ApplicationCreateRequest {
  return ApplicationCreateRequestFromJSONTyped(json, false);
}

export function ApplicationCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ApplicationCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userEmail: !exists(json, "userEmail") ? undefined : json["userEmail"],
    dotNumber: !exists(json, "dotNumber") ? undefined : json["dotNumber"],
    stateOfOperation: !exists(json, "stateOfOperation") ? undefined : StateFromJSON(json["stateOfOperation"]),
    radiusOfOperation: !exists(json, "radiusOfOperation")
      ? undefined
      : RadiusOfOperationFromJSON(json["radiusOfOperation"]),
    radiusOfOperationInt: !exists(json, "radiusOfOperationInt") ? undefined : json["radiusOfOperationInt"],
    powerUnitCount: !exists(json, "powerUnitCount") ? undefined : json["powerUnitCount"],
    applicationState: !exists(json, "applicationState") ? undefined : json["applicationState"],
    partnerName: !exists(json, "partnerName") ? undefined : json["partnerName"],
    campaignId: !exists(json, "campaignId") ? undefined : json["campaignId"],
  };
}

export function ApplicationCreateRequestToJSON(value?: ApplicationCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userEmail: value.userEmail,
    dotNumber: value.dotNumber,
    stateOfOperation: StateToJSON(value.stateOfOperation),
    radiusOfOperation: RadiusOfOperationToJSON(value.radiusOfOperation),
    radiusOfOperationInt: value.radiusOfOperationInt,
    powerUnitCount: value.powerUnitCount,
    applicationState: value.applicationState,
    partnerName: value.partnerName,
    campaignId: value.campaignId,
  };
}
