/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AdditionalInsured,
  AdditionalInsuredFromJSON,
  AdditionalInsuredFromJSONTyped,
  AdditionalInsuredToJSON,
  CompAndCollisionCoverage,
  CompAndCollisionCoverageFromJSON,
  CompAndCollisionCoverageFromJSONTyped,
  CompAndCollisionCoverageToJSON,
  EquipmentType,
  EquipmentTypeFromJSON,
  EquipmentTypeFromJSONTyped,
  EquipmentTypeToJSON,
  LossPayee,
  LossPayeeFromJSON,
  LossPayeeFromJSONTyped,
  LossPayeeToJSON,
  RegisteredTo,
  RegisteredToFromJSON,
  RegisteredToFromJSONTyped,
  RegisteredToToJSON,
  ZoneEnum,
  ZoneEnumFromJSON,
  ZoneEnumFromJSONTyped,
  ZoneEnumToJSON,
} from "./";

/**
 *
 * @export
 * @interface Equipment
 */
export interface Equipment {
  /**
   *
   * @type {number}
   * @memberof Equipment
   */
  id?: number;
  /**
   *
   * @type {EquipmentType}
   * @memberof Equipment
   */
  equipmentType?: EquipmentType;
  /**
   *
   * @type {string}
   * @memberof Equipment
   */
  vehicleNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Equipment
   */
  vin?: string | null;
  /**
   *
   * @type {string}
   * @memberof Equipment
   */
  make?: string | null;
  /**
   *
   * @type {string}
   * @memberof Equipment
   */
  model?: string | null;
  /**
   *
   * @type {string}
   * @memberof Equipment
   */
  year?: string | null;
  /**
   *
   * @type {number}
   * @memberof Equipment
   */
  statedValue?: number;
  /**
   *
   * @type {string}
   * @memberof Equipment
   */
  registrationZip?: string | null;
  /**
   *
   * @type {ZoneEnum}
   * @memberof Equipment
   */
  destinationZone?: ZoneEnum;
  /**
   *
   * @type {boolean}
   * @memberof Equipment
   */
  ownerOperator?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Equipment
   */
  registeredToCompany?: boolean;
  /**
   *
   * @type {RegisteredTo}
   * @memberof Equipment
   */
  registeredTo?: RegisteredTo;
  /**
   *
   * @type {boolean}
   * @memberof Equipment
   */
  loanLeaseGapCoverage?: boolean | null;
  /**
   *
   * @type {CompAndCollisionCoverage}
   * @memberof Equipment
   */
  compAndCollisionCoverage?: CompAndCollisionCoverage;
  /**
   *
   * @type {string}
   * @memberof Equipment
   */
  gvwr?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Equipment
   */
  readonly gvwrEligible?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Equipment
   */
  cargoInsurance?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof Equipment
   */
  cargoInsuranceLimit?: number | null;
  /**
   *
   * @type {number}
   * @memberof Equipment
   */
  cargoInsuranceDeductible?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Equipment
   */
  cargoInsuranceReeferBreakdown?: boolean | null;
  /**
   *
   * @type {Array<LossPayee>}
   * @memberof Equipment
   */
  lossPayees?: Array<LossPayee> | null;
  /**
   *
   * @type {Array<AdditionalInsured>}
   * @memberof Equipment
   */
  additionalInsured?: Array<AdditionalInsured> | null;
  /**
   *
   * @type {number}
   * @memberof Equipment
   */
  applicationId?: number | null;
}

export function EquipmentFromJSON(json: any): Equipment {
  return EquipmentFromJSONTyped(json, false);
}

export function EquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Equipment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    equipmentType: !exists(json, "equipmentType") ? undefined : EquipmentTypeFromJSON(json["equipmentType"]),
    vehicleNumber: !exists(json, "vehicleNumber") ? undefined : json["vehicleNumber"],
    vin: !exists(json, "vin") ? undefined : json["vin"],
    make: !exists(json, "make") ? undefined : json["make"],
    model: !exists(json, "model") ? undefined : json["model"],
    year: !exists(json, "year") ? undefined : json["year"],
    statedValue: !exists(json, "statedValue") ? undefined : json["statedValue"],
    registrationZip: !exists(json, "registrationZip") ? undefined : json["registrationZip"],
    destinationZone: !exists(json, "destinationZone") ? undefined : ZoneEnumFromJSON(json["destinationZone"]),
    ownerOperator: !exists(json, "ownerOperator") ? undefined : json["ownerOperator"],
    registeredToCompany: !exists(json, "registeredToCompany") ? undefined : json["registeredToCompany"],
    registeredTo: !exists(json, "registeredTo") ? undefined : RegisteredToFromJSON(json["registeredTo"]),
    loanLeaseGapCoverage: !exists(json, "loanLeaseGapCoverage") ? undefined : json["loanLeaseGapCoverage"],
    compAndCollisionCoverage: !exists(json, "compAndCollisionCoverage")
      ? undefined
      : CompAndCollisionCoverageFromJSON(json["compAndCollisionCoverage"]),
    gvwr: !exists(json, "gvwr") ? undefined : json["gvwr"],
    gvwrEligible: !exists(json, "gvwrEligible") ? undefined : json["gvwrEligible"],
    cargoInsurance: !exists(json, "cargoInsurance") ? undefined : json["cargoInsurance"],
    cargoInsuranceLimit: !exists(json, "cargoInsuranceLimit") ? undefined : json["cargoInsuranceLimit"],
    cargoInsuranceDeductible: !exists(json, "cargoInsuranceDeductible") ? undefined : json["cargoInsuranceDeductible"],
    cargoInsuranceReeferBreakdown: !exists(json, "cargoInsuranceReeferBreakdown")
      ? undefined
      : json["cargoInsuranceReeferBreakdown"],
    lossPayees: !exists(json, "lossPayees")
      ? undefined
      : json["lossPayees"] === null
      ? null
      : (json["lossPayees"] as Array<any>).map(LossPayeeFromJSON),
    additionalInsured: !exists(json, "additionalInsured")
      ? undefined
      : json["additionalInsured"] === null
      ? null
      : (json["additionalInsured"] as Array<any>).map(AdditionalInsuredFromJSON),
    applicationId: !exists(json, "applicationId") ? undefined : json["applicationId"],
  };
}

export function EquipmentToJSON(value?: Equipment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    equipmentType: EquipmentTypeToJSON(value.equipmentType),
    vehicleNumber: value.vehicleNumber,
    vin: value.vin,
    make: value.make,
    model: value.model,
    year: value.year,
    statedValue: value.statedValue,
    registrationZip: value.registrationZip,
    destinationZone: ZoneEnumToJSON(value.destinationZone),
    ownerOperator: value.ownerOperator,
    registeredToCompany: value.registeredToCompany,
    registeredTo: RegisteredToToJSON(value.registeredTo),
    loanLeaseGapCoverage: value.loanLeaseGapCoverage,
    compAndCollisionCoverage: CompAndCollisionCoverageToJSON(value.compAndCollisionCoverage),
    gvwr: value.gvwr,
    cargoInsurance: value.cargoInsurance,
    cargoInsuranceLimit: value.cargoInsuranceLimit,
    cargoInsuranceDeductible: value.cargoInsuranceDeductible,
    cargoInsuranceReeferBreakdown: value.cargoInsuranceReeferBreakdown,
    lossPayees:
      value.lossPayees === undefined
        ? undefined
        : value.lossPayees === null
        ? null
        : (value.lossPayees as Array<any>).map(LossPayeeToJSON),
    additionalInsured:
      value.additionalInsured === undefined
        ? undefined
        : value.additionalInsured === null
        ? null
        : (value.additionalInsured as Array<any>).map(AdditionalInsuredToJSON),
    applicationId: value.applicationId,
  };
}
