import { TruckIcon } from "@chq/components";
import { Slide } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useState } from "react";
import { useInterval } from "react-use";

const iconHeight = 34;

const useStyles = makeStyles((theme: Theme) => ({
  truckAnimationWrapper: {
    height: iconHeight,
    display: "flex",
    justifyItems: "center",
    overflow: "hidden",
  },
  truck: {
    width: 75,
    height: iconHeight,
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.primary.main,
  },
}));

export interface TruckLoadingAnimationProps {
  timeout?: number;
}

export const TruckLoadingAnimation: React.FC<TruckLoadingAnimationProps> = ({ timeout = 500 }) => {
  const classes = useStyles();

  const [animateIn, setAnimateIn] = useState<boolean>(false);
  const [direction, setDirection] = useState<"left" | "right">("left");

  useInterval(() => {
    setDirection(direction === "left" ? "right" : "left");
    setAnimateIn(!animateIn);
  }, timeout * 2);

  return (
    <div className={classes.truckAnimationWrapper}>
      <Slide
        direction={direction}
        in={animateIn}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: timeout,
          appear: 0,
          exit: timeout,
        }}
      >
        <div className={classes.truck}>
          <TruckIcon className={classes.truck} />
        </div>
      </Slide>
    </div>
  );
};
