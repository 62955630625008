/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Document,
  DocumentFromJSON,
  DocumentFromJSONTyped,
  DocumentToJSON,
  Policy,
  PolicyFromJSON,
  PolicyFromJSONTyped,
  PolicyToJSON,
  Rates,
  RatesFromJSON,
  RatesFromJSONTyped,
  RatesToJSON,
} from "./";

/**
 *
 * @export
 * @interface Quote
 */
export interface Quote {
  /**
   *
   * @type {Date}
   * @memberof Quote
   */
  readonly expirationDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Quote
   */
  quoteCreationDate?: Date | null;
  /**
   *
   * @type {Policy}
   * @memberof Quote
   */
  policy?: Policy;
  /**
   *
   * @type {Document}
   * @memberof Quote
   */
  sfaargProposalDoc?: Document;
  /**
   *
   * @type {Document}
   * @memberof Quote
   */
  declarationsDoc?: Document;
  /**
   *
   * @type {Rates}
   * @memberof Quote
   */
  rates?: Rates;
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  policyId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  sfarrgProposalDocId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  declarationsDocId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  ratesId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  ownerId?: number;
  /**
   *
   * @type {string}
   * @memberof Quote
   */
  policyNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Quote
   */
  qsStorageId?: string | null;
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  annualizedRate?: number;
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  weeklyRate?: number;
  /**
   *
   * @type {number}
   * @memberof Quote
   */
  monthlyRate?: number;
  /**
   *
   * @type {Date}
   * @memberof Quote
   */
  effectiveDate?: Date;
}

export function QuoteFromJSON(json: any): Quote {
  return QuoteFromJSONTyped(json, false);
}

export function QuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Quote {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    expirationDate: !exists(json, "expirationDate") ? undefined : new Date(json["expirationDate"]),
    quoteCreationDate: !exists(json, "quoteCreationDate")
      ? undefined
      : json["quoteCreationDate"] === null
      ? null
      : new Date(json["quoteCreationDate"]),
    policy: !exists(json, "policy") ? undefined : PolicyFromJSON(json["policy"]),
    sfaargProposalDoc: !exists(json, "sfaargProposalDoc") ? undefined : DocumentFromJSON(json["sfaargProposalDoc"]),
    declarationsDoc: !exists(json, "declarationsDoc") ? undefined : DocumentFromJSON(json["declarationsDoc"]),
    rates: !exists(json, "rates") ? undefined : RatesFromJSON(json["rates"]),
    policyId: !exists(json, "policyId") ? undefined : json["policyId"],
    sfarrgProposalDocId: !exists(json, "sfarrgProposalDocId") ? undefined : json["sfarrgProposalDocId"],
    declarationsDocId: !exists(json, "declarationsDocId") ? undefined : json["declarationsDocId"],
    ratesId: !exists(json, "ratesId") ? undefined : json["ratesId"],
    id: !exists(json, "id") ? undefined : json["id"],
    ownerId: !exists(json, "ownerId") ? undefined : json["ownerId"],
    policyNumber: !exists(json, "policyNumber") ? undefined : json["policyNumber"],
    qsStorageId: !exists(json, "qsStorageId") ? undefined : json["qsStorageId"],
    annualizedRate: !exists(json, "annualizedRate") ? undefined : json["annualizedRate"],
    weeklyRate: !exists(json, "weeklyRate") ? undefined : json["weeklyRate"],
    monthlyRate: !exists(json, "monthlyRate") ? undefined : json["monthlyRate"],
    effectiveDate: !exists(json, "effectiveDate") ? undefined : new Date(json["effectiveDate"]),
  };
}

export function QuoteToJSON(value?: Quote | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    quoteCreationDate:
      value.quoteCreationDate === undefined
        ? undefined
        : value.quoteCreationDate === null
        ? null
        : value.quoteCreationDate.toISOString(),
    policy: PolicyToJSON(value.policy),
    sfaargProposalDoc: DocumentToJSON(value.sfaargProposalDoc),
    declarationsDoc: DocumentToJSON(value.declarationsDoc),
    rates: RatesToJSON(value.rates),
    policyId: value.policyId,
    sfarrgProposalDocId: value.sfarrgProposalDocId,
    declarationsDocId: value.declarationsDocId,
    ratesId: value.ratesId,
    id: value.id,
    ownerId: value.ownerId,
    policyNumber: value.policyNumber,
    qsStorageId: value.qsStorageId,
    annualizedRate: value.annualizedRate,
    weeklyRate: value.weeklyRate,
    monthlyRate: value.monthlyRate,
    effectiveDate: value.effectiveDate === undefined ? undefined : value.effectiveDate.toISOString(),
  };
}
