import { AlertType, PageAlert } from "@chq/components";
import { Link, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  errorLink: {
    color: theme.palette.error.main,
    textDecoration: "underline",
  },
}));

export type Props = {
  errorTranslationKey?: string;
  phoneNumber?: string;
};

const GenericPageAlert: React.FC<Props> = ({
  errorTranslationKey = "common.page-level-error",
  phoneNumber = "866-621-4145",
}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <PageAlert
      alertType={AlertType.error}
      message={
        <Trans i18nKey={errorTranslationKey}>
          <Link href={`tel:${phoneNumber}`} className={classes.errorLink}>
            {t("common.phone-link-aria-label")}
          </Link>
        </Trans>
      }
    />
  );
};

export default GenericPageAlert;
