/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Equipment,
  EquipmentFromJSON,
  EquipmentFromJSONTyped,
  EquipmentToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface EquipmentApiResponse
 */
export interface EquipmentApiResponse {
  /**
   *
   * @type {Equipment}
   * @memberof EquipmentApiResponse
   */
  data?: Equipment;
  /**
   *
   * @type {ErrorResponse}
   * @memberof EquipmentApiResponse
   */
  error?: ErrorResponse;
}

export function EquipmentApiResponseFromJSON(json: any): EquipmentApiResponse {
  return EquipmentApiResponseFromJSONTyped(json, false);
}

export function EquipmentApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : EquipmentFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function EquipmentApiResponseToJSON(value?: EquipmentApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: EquipmentToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
