import { ApiV10QuoteSendEmailGetRequest, Application, Quote, QuoteApi } from "@chq/enrollment-api";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const quoteCacheKey = "quote";

export const useGetQuote = (): UseQueryResult<Application, Error> => {
  const quoteApi = useApi(QuoteApi);
  const { updateApplication } = useCachedApplication();
  const query = useQuery<Quote, Error>(quoteCacheKey, async () => {
    const response = await quoteApi.apiV10QuoteCreateGet();
    if (response.error) {
      throw new Error(response.error.message || "Unable to generate quote");
    } else if (response && response.data) {
      updateApplication({ ...response.data });
    }
    return response.data!;
  });
  return query;
};

export const useSendInactiveQuoteEmail = (): UseMutationResult<boolean, Error, ApiV10QuoteSendEmailGetRequest> => {
  const quoteApi = useApi(QuoteApi);
  const mutation = useMutation<boolean, Error, ApiV10QuoteSendEmailGetRequest>(
    async (request: ApiV10QuoteSendEmailGetRequest) => {
      const response = await quoteApi.apiV10QuoteSendEmailGet(request);
      if (response.error) {
        throw new Error(response.error.message || "Unable to send inactive quote page email.");
      } else {
        return response.data!;
      }
    },
  );
  return mutation;
};
