/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, DatePicker, NextButton, PreviousButton, TextInput } from "@chq/components";
import { Button, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import startOfToday from "date-fns/startOfToday";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Yup from "yup";
import AddEquipmentCard from "../../../components/policy-management-components/add-equipment-card";
import CoveragesCard from "../../../components/policy-management-components/coverages-card";
import { PolicyCoveragesContext } from "../../../data/policy-management/PolicyManagementCoveragesContext";
import { PolicyEquipmentContext } from "../../../data/policy-management/PolicyManagementEquipmentContext";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "2.5rem 0.75rem 0.625rem",
    margin: "auto",
  },
  text: {
    marginBottom: "1.125rem",
  },
  alignedText: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
    textAlign: "center",
  },
  reviewSection: {
    marginBottom: "0.75rem",
  },
  cardMargin: {
    marginBottom: "1rem",
  },
  editIcon: {
    height: "30px",
    width: "auto",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  cancelButtonContainer: {
    alignSelf: "flex-end",
  },
  cancelButton: {
    minHeight: "56px",
    marginBottom: "1.875rem",
  },
  formContainer: {
    marginTop: "1.125rem",
    marginBottom: "2.5rem",
  },
  disclaimerText: {
    marginBottom: "1.5rem",
  },
  datePicker: {
    "& :last-child > div": {
      paddingRight: "0px",
    },
  },
}));

enum Fields {
  signature = "signature",
  date = "date",
}

type FormProps = {
  [Fields.signature]: string;
  [Fields.date]?: Date;
};

const useValidationSchema = () => {
  const [t] = useTranslation();
  const today = startOfToday();

  return Yup.object({
    [Fields.signature]: Yup.string().required(
      t("errors.required", {
        field: t(`policy-management-page.review-coverages-and-equipment-page.signature-form.${Fields.signature}`),
      }),
    ),
    [Fields.date]: Yup.date()
      .nullable()
      .required(
        t(`errors.required`, {
          field: t(`policy-management-page.review-coverages-and-equipment-page.signature-form.${Fields.date}`),
        }),
      )
      .typeError(
        t("errors.date", {
          field: t(`policy-management-page.review-coverages-and-equipment-page.signature-form.${Fields.date}`),
        }),
      )
      .min(today, t(`policy-management-page.review-coverages-and-equipment-page.signature-form.${Fields.date}-min`)),
  });
};

const ReviewCoveragesAndEquipmentPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const validationSchema = useValidationSchema();
  const { policyEquipment, updatePolicyEquipment } = useContext(PolicyEquipmentContext);
  const { policyCoverages } = useContext(PolicyCoveragesContext);
  const editedCoveragesFields = policyCoverages?.editedFields ?? [];

  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.signature]: "",
      [Fields.date]: undefined,
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      history.push(routes.policyManagement.reviewEquipmentAndCoverages.success.path);
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  return (
    <Grid item container xs={12} sm={10} justify="center" className={classes.container} alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h2" className={classNames(classes.alignedText, classes.text)}>
          {t("policy-management-page.review-coverages-and-equipment-page.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" component="h3" className={classNames(classes.alignedText, classes.text)}>
          {t("policy-management-page.review-coverages-and-equipment-page.subtitle")}
        </Typography>
      </Grid>
      {editedCoveragesFields.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.text}>
              <strong>{t("policy-management-page.coverages-page.header-text")}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.reviewSection}>
            <CoveragesCard
              coverages={policyCoverages?.coverages ? policyCoverages.coverages : {}}
              cargoInsurance={policyCoverages?.coverages?.cargoInsurance}
              cargoInsuranceLimit={policyCoverages?.coverages?.cargoInsuranceLimit}
              cargoInsuranceDeductible={policyCoverages?.coverages?.cargoInsuranceDeductible}
              cargoInsuranceReeferBreakdown={policyCoverages?.coverages?.cargoInsuranceReeferBreakdown}
              editedFields={policyCoverages?.editedFields}
              isEditable={false}
              className={classes.cardMargin}
              onEdit={() => history.push(routes.policyManagement.coverages.edit.path)}
            />
          </Grid>
        </>
      )}
      {policyEquipment?.powerUnits.some((powerUnit) => {
        return powerUnit.editedFields.length > 0 || powerUnit.isNew || powerUnit.isDeleted;
      }) && (
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.text}>
            <strong>{t("enrollment.equipment-page.power-units-title")}</strong>
          </Typography>
        </Grid>
      )}
      <Grid container item xs={12} spacing={2} className={classes.reviewSection}>
        {policyEquipment?.powerUnits.map((powerUnit, index) => {
          if (powerUnit.editedFields.length > 0 || powerUnit.isNew || powerUnit.isDeleted) {
            return (
              <Grid item xs={12} md={6} lg={4}>
                <AddEquipmentCard
                  key={`powerUnit-${index}-${powerUnit.equipment.id}`}
                  className={classes.cardMargin}
                  bannerText={
                    powerUnit.isDeleted
                      ? t("policy-management-page.equipment-page.deleted-power-unit-card")
                      : powerUnit.isNew
                      ? t("policy-management-page.equipment-page.new-power-unit-card")
                      : powerUnit.editedFields.length > 0
                      ? t("policy-management-page.equipment-page.updated-power-unit-card")
                      : undefined
                  }
                  equipment={powerUnit.equipment}
                  isNew={powerUnit.isNew}
                  isDeleted={powerUnit.isDeleted}
                  editedFields={powerUnit.isNew ? [] : powerUnit.editedFields}
                  equipmentIndex={index}
                  onEdit={() => {
                    updatePolicyEquipment((prevState) => {
                      const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                      const trailers = prevState?.trailers ? prevState.trailers : [];
                      return {
                        trailers: trailers,
                        powerUnits: powerUnits.map((item, i) => (index === i ? { ...item, isEditing: true } : item)),
                      };
                    });
                    history.push(routes.policyManagement.equipment.edit.path);
                  }}
                />
              </Grid>
            );
          } else return undefined;
        })}
      </Grid>
      {policyEquipment?.trailers.some((trailer) => {
        return trailer.editedFields.length > 0;
      }) && (
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.text}>
            <strong>{t("enrollment.equipment-page.trailers-title")}</strong>
          </Typography>
        </Grid>
      )}
      <Grid container item xs={12} spacing={2} className={classes.reviewSection}>
        {policyEquipment?.trailers.map((trailer, index) => {
          if (trailer.editedFields.length > 0 || trailer.isNew || trailer.isDeleted) {
            return (
              <Grid item xs={12} md={6} lg={4}>
                <AddEquipmentCard
                  key={`trailer-${index}-${trailer.equipment.id}`}
                  className={classes.cardMargin}
                  bannerText={
                    trailer.isDeleted
                      ? t("policy-management-page.equipment-page.deleted-trailer-card")
                      : trailer.isNew
                      ? t("policy-management-page.equipment-page.new-trailer-card")
                      : trailer.editedFields.length > 0
                      ? t("policy-management-page.equipment-page.updated-trailer-card")
                      : undefined
                  }
                  {...trailer}
                  isNew={trailer.isNew}
                  isDeleted={trailer.isDeleted}
                  editedFields={trailer.isNew ? [] : trailer.editedFields}
                  equipment={trailer.equipment}
                  equipmentIndex={index}
                  onEdit={() => {
                    updatePolicyEquipment((prevState) => {
                      const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                      const trailers = prevState?.trailers ? prevState.trailers : [];
                      return {
                        powerUnits: powerUnits,
                        trailers: trailers.map((item, i) => (index === i ? { ...item, isEditing: true } : item)),
                      };
                    });
                    history.push(routes.policyManagement.equipment.edit.path);
                  }}
                />
              </Grid>
            );
          } else return undefined;
        })}
      </Grid>
      <Grid container item xs={12} sm={8}>
        <Grid item>
          <Typography variant="h3" className={classes.disclaimerText}>
            <strong>{t("policy-management-page.review-coverages-and-equipment-page.disclaimer")}</strong>
          </Typography>
        </Grid>
        <Checkbox
          label={
            <Typography variant="h3">
              <strong>{t("policy-management-page.review-coverages-and-equipment-page.checkbox-text")}</strong>
            </Typography>
          }
          caption={t("policy-management-page.review-coverages-and-equipment-page.checkbox-subtitle")}
        />
        <Grid container item spacing={1} className={classes.formContainer}>
          <Grid item xs={6}>
            <TextInput
              fullWidth
              required
              label={t(`policy-management-page.review-coverages-and-equipment-page.signature-form.${Fields.signature}`)}
              id={Fields.signature}
              name={Fields.signature}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.signature]}
              error={formik.touched[Fields.signature] && Boolean(formik.errors[Fields.signature])}
              helperText={formik.touched[Fields.signature] && formik.errors[Fields.signature]}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              id={Fields.date}
              name={Fields.date}
              label={t(`policy-management-page.review-coverages-and-equipment-page.signature-form.${Fields.date}`)}
              KeyboardButtonProps={{
                "aria-label": t("enrollment.basic-info.effective-date-button"),
              }}
              className={classes.datePicker}
              value={formik.values[Fields.date] ? formik.values[Fields.date]! : null}
              required
              disablePast
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(date: any) => {
                formik.setFieldValue(Fields.date, date);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.date] && Boolean(formik.errors[Fields.date])}
              helperText={formik.touched[Fields.date] && formik.errors[Fields.date]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} justify="space-between">
        <Grid item container xs={12} sm={8} lg={9} spacing={mobileView ? 1 : 2}>
          <Grid item xs={3} md={2} lg={1}>
            <PreviousButton
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.previousButton}
              onClick={() => history.push(routes.policyManagement.coverages.edit.path)}
              aria-label={t(
                "policy-management-page.review-coverages-and-equipment-page.signature-form.keyboard-button",
              )}
            ></PreviousButton>
          </Grid>
          <Grid item xs={9} md={6} lg={5}>
            <NextButton
              variant="contained"
              color="primary"
              fullWidth
              disabled={!formik.isValid}
              onClick={() => formik.handleSubmit()}
            >
              {t("policy-management-page.review-coverages-and-equipment-page.signature-form.submit-button")}
            </NextButton>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} md={4} lg={2} className={classes.cancelButtonContainer}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.cancelButton}
            onClick={() => history.push(routes.policyManagement.equipment.path)}
          >
            {t("common.cancel")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewCoveragesAndEquipmentPage;
