/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Document,
  DocumentFromJSON,
  DocumentFromJSONTyped,
  DocumentToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface DocumentApiResponse
 */
export interface DocumentApiResponse {
  /**
   *
   * @type {Document}
   * @memberof DocumentApiResponse
   */
  data?: Document;
  /**
   *
   * @type {ErrorResponse}
   * @memberof DocumentApiResponse
   */
  error?: ErrorResponse;
}

export function DocumentApiResponseFromJSON(json: any): DocumentApiResponse {
  return DocumentApiResponseFromJSONTyped(json, false);
}

export function DocumentApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : DocumentFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function DocumentApiResponseToJSON(value?: DocumentApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: DocumentToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
