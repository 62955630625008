/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum EquipmentType {
  Trailer = "Trailer",
  PowerUnit = "PowerUnit",
}

export function EquipmentTypeFromJSON(json: any): EquipmentType {
  return EquipmentTypeFromJSONTyped(json, false);
}

export function EquipmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentType {
  return json as EquipmentType;
}

export function EquipmentTypeToJSON(value?: EquipmentType | null): any {
  return value as any;
}
