import _ from "lodash";
import { EquipmentFormProps } from "../components/policy-management-components/equipment-form";

export const getEquipmentFieldDifferences = (
  oldObject: Record<string, unknown>,
  newObject: Record<string, unknown>,
) => {
  const keys = Object.keys(newObject);
  const extraFormDifferentKeys: (string | undefined)[] = [];
  const differentKeys = keys.map((key, index) => {
    if (_.isObject(oldObject[key as keyof EquipmentFormProps]) && _.isObject(newObject[key])) {
      if (_.isEmpty(oldObject[key as keyof EquipmentFormProps]) && _.isEmpty(newObject[key])) {
        return undefined;
      } else {
        if (
          _.isEmpty(oldObject[key as keyof EquipmentFormProps]) ||
          _.isEmpty(newObject[key as keyof EquipmentFormProps])
        ) {
          return key;
        } else {
          const extraFormKeys = Object.keys(newObject[key as keyof EquipmentFormProps] as Record<string, unknown>);
          const oldObjectWithKey = oldObject[key] as Record<string, unknown>;
          const newObjectWithKey = newObject[key] as Record<string, unknown>;
          extraFormKeys.map((extraFormKey, index) => {
            if (oldObjectWithKey[extraFormKey] !== newObjectWithKey[extraFormKey]) {
              extraFormDifferentKeys.push(key);
              return key;
            } else return undefined;
          });
          return undefined;
        }
      }
    } else if (oldObject[key] !== newObject[key]) {
      return key;
    } else return undefined;
  });
  const combinedKeys = [...differentKeys, ...extraFormDifferentKeys];
  const filteredKeys = combinedKeys.filter(function (key) {
    return key !== undefined;
  });
  return filteredKeys;
};
