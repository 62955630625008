/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { State, StateFromJSON, StateFromJSONTyped, StateToJSON } from "./";

/**
 *
 * @export
 * @interface BusinessDetailsRequest
 */
export interface BusinessDetailsRequest {
  /**
   *
   * @type {string}
   * @memberof BusinessDetailsRequest
   */
  businessName?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessDetailsRequest
   */
  contactPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessDetailsRequest
   */
  einNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessDetailsRequest
   */
  dotNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessDetailsRequest
   */
  mcNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessDetailsRequest
   */
  address1?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessDetailsRequest
   */
  address2?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessDetailsRequest
   */
  city?: string | null;
  /**
   *
   * @type {State}
   * @memberof BusinessDetailsRequest
   */
  state?: State;
  /**
   *
   * @type {string}
   * @memberof BusinessDetailsRequest
   */
  zip?: string | null;
}

export function BusinessDetailsRequestFromJSON(json: any): BusinessDetailsRequest {
  return BusinessDetailsRequestFromJSONTyped(json, false);
}

export function BusinessDetailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessDetailsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    businessName: !exists(json, "businessName") ? undefined : json["businessName"],
    contactPhone: !exists(json, "contactPhone") ? undefined : json["contactPhone"],
    einNumber: !exists(json, "einNumber") ? undefined : json["einNumber"],
    dotNumber: !exists(json, "dotNumber") ? undefined : json["dotNumber"],
    mcNumber: !exists(json, "mcNumber") ? undefined : json["mcNumber"],
    address1: !exists(json, "address1") ? undefined : json["address1"],
    address2: !exists(json, "address2") ? undefined : json["address2"],
    city: !exists(json, "city") ? undefined : json["city"],
    state: !exists(json, "state") ? undefined : StateFromJSON(json["state"]),
    zip: !exists(json, "zip") ? undefined : json["zip"],
  };
}

export function BusinessDetailsRequestToJSON(value?: BusinessDetailsRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    businessName: value.businessName,
    contactPhone: value.contactPhone,
    einNumber: value.einNumber,
    dotNumber: value.dotNumber,
    mcNumber: value.mcNumber,
    address1: value.address1,
    address2: value.address2,
    city: value.city,
    state: StateToJSON(value.state),
    zip: value.zip,
  };
}
