import { EditableDeletableCard } from "@chq/components";
import { fade, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1rem 1.5rem 1rem 1.5rem",
    borderRadius: "0.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    width: "100%",
  },
  containerClass: {
    padding: "0",
  },
  title: {
    paddingBottom: "0.5rem",
  },
  total: {
    color: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem",
      paddingBottom: ".4rem",
      textAlign: "center",
    },
  },
  textSmaller: {
    fontSize: ".9rem",
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
    },
  },
}));

const TrucktasticQuoteCard: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <EditableDeletableCard
      variant="default"
      border={false}
      className={classes.container}
      containerClass={classes.containerClass}
    >
      <Grid container direction="column">
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h4" className={classNames(classes.total)}>
            {t("enrollment.quote-overview-page.trucktastic-card.title")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item xs={10} sm={12} lg={12}>
          <Typography variant="h4" component="p" className={classes.textSmaller}>
            {t("enrollment.quote-overview-page.trucktastic-card.subtitle")}
          </Typography>
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default TrucktasticQuoteCard;
