import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { NextButton, PreviousButton } from "@chq/components";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "4rem 1rem 1rem 1rem",
    background: theme.palette.common.white,
    borderRadius: "4px 4px 0px 0px",
    textAlign: "center",
  },
  checkEmail: {
    fontWeight: 700,
    paddingTop: "1rem",
    paddingBottom: "3rem",
  },
  text: {
    padding: "0rem 2.75rem 0rem 2.75rem",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  buttonContainer: {
    paddingTop: "3rem",
  },
}));

type Props = {
  accountExpired: boolean;
  onPreviousClick?: React.MouseEventHandler<HTMLButtonElement>;
  onNextClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const WelcomeBackQuote: React.FC<Props> = ({ accountExpired, onPreviousClick, onNextClick }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h2">{t("welcome-back-quote.title")}</Typography>
      </Grid>
      {!accountExpired && (
        <>
          <Grid item xs={12} className={classes.text}>
            <Typography variant="h3">{t("welcome-back-quote.started-quote")}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.text}>
            <Typography variant="h3" className={classes.checkEmail}>
              {t("welcome-back-quote.check-email")}
            </Typography>
          </Grid>
        </>
      )}
      {accountExpired && (
        <>
          <Grid item xs={12} className={classes.text}>
            <Typography variant="h3">{t("welcome-back-quote.new-quote")}</Typography>
          </Grid>
          <Grid container item spacing={1} className={classes.buttonContainer}>
            <Grid item xs={3}>
              <PreviousButton
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.previousButton}
                onClick={onPreviousClick ? onPreviousClick : undefined}
                aria-label={t("enrollment.add-driver.previous-button-label")}
              ></PreviousButton>
            </Grid>
            <Grid item xs={9}>
              <NextButton variant="contained" color="primary" fullWidth onClick={onNextClick}>
                {t("welcome-back-quote.button")}
              </NextButton>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default WelcomeBackQuote;
