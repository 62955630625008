import {
  EditableDeletableCard,
  NumericInput,
  Select,
  TextInput,
  TooltipHelp,
  YesNoInput,
  YesNoValues,
} from "@chq/components";
import { CompAndCollisionCoverage, RegisteredTo } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { InfoRounded } from "@material-ui/icons";
import { FormikConfig, useFormikContext } from "formik";
import React, { ChangeEvent } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useGetYearMakeModel } from "../data/enrollment";
import { useCoverageOptionTypes } from "../data/useCoverageOptions";
import { destinationCaveats, disabledDestinations, useDestinationTypes } from "../data/useDestinationTypes";
import { useRegisteredToTypes } from "../data/useRegisteredToTypes";

const useStyles = makeStyles((theme: Theme) => ({
  infoCardContainer: {
    backgroundColor: theme.palette.action.active,
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    marginBottom: "10px",
  },
  ownerInput: {
    width: "12.5rem",
    marginTop: "0.75rem",
  },
  radioInput: {
    width: "12.5rem",
  },
  inputTitle: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        marginTop: "1rem",
      },
    },
  },
  gapTooltip: {
    position: "absolute",
  },
  informationIcon: {
    color: theme.palette.primary.main,
  },
  callForInfo: {
    height: "100%",
  },
}));

export enum Fields {
  vehicleNumber = "vehicle-number",
  vin = "vin",
  make = "make",
  model = "model",
  year = "year",
  registrationZip = "registration-zip",
  destination = "destination",
  ooIC = "operator-or-contractor",
  loanLeaseGap = "loan-lease-gap-coverage",
  physicalDamage = "physical-damage",
  statedValue = "stated-value",
  whereIsUnitRegistered = "where-is-unit-registered",
  coverageOption = "coverage-option",
  gapCoverage = "gap-coverage",
}

export const useValidationSchema = () => {
  const [t] = useTranslation();
  let sharedCoverageOption: string;

  return Yup.object({
    [Fields.vehicleNumber]: Yup.string(),
    [Fields.vin]: Yup.string()
      .length(17, t("trailer-power-unit-form.vin.length"))
      .matches(/^[a-zA-Z0-9]*$/, {
        excludeEmptyString: false,
        message: t("trailer-power-unit-form.vin.valid"),
      })
      .required(t("errors.required", { field: t("trailer-power-unit-form.vin.label") })),
    [Fields.make]: Yup.string().required(t("errors.required", { field: t("trailer-power-unit-form.make.label") })),
    [Fields.model]: Yup.string(),
    [Fields.year]: Yup.number()
      .typeError(t("errors.required", { field: t("trailer-power-unit-form.year.label") }))
      .required(t("errors.required", { field: t("trailer-power-unit-form.year.label") }))
      .min(1996, t("errors.min", { field: t("trailer-power-unit-form.year.label"), min: 1996 }))
      .test("before1998", t("trailer-power-unit-form.year.before1998"), (year) => year === undefined || year > 1998)
      .max(
        new Date().getFullYear() + 1,
        t("errors.max", { field: t("trailer-power-unit-form.year.label"), max: new Date().getFullYear() + 1 }),
      ),
    [Fields.registrationZip]: Yup.string()
      .length(5, t("trailer-power-unit-form.registration-zip.length"))
      .typeError(t("errors.required", { field: t("trailer-power-unit-form.registration-zip.label") }))
      .test(
        "length",
        t("trailer-power-unit-form.registration-zip.length"),
        (val) => val !== undefined && val.toString().length === 5,
      )
      .required(t("errors.required", { field: t("trailer-power-unit-form.registration-zip.label") })),
    [Fields.destination]: Yup.string()
      .typeError(t("errors.required", { field: t("trailer-power-unit-form.destination.label") }))
      .required(t("errors.required", { field: t("trailer-power-unit-form.destination.label") })),
    [Fields.ooIC]: Yup.string().required(
      t("errors.required", { field: t("trailer-power-unit-form.operator-or-contractor.label") }),
    ),
    [Fields.whereIsUnitRegistered]: Yup.string().when(Fields.ooIC, {
      is: YesNoValues.no,
      then: Yup.string()
        .required(t("errors.required", { field: t("trailer-power-unit-form.where-is-unit-registered.label") }))
        .test("is-not-other", " ", (value) => value !== ("Other" || undefined)),
    }),
    [Fields.coverageOption]: Yup.string().when([Fields.ooIC, Fields.whereIsUnitRegistered], {
      is: (ooic: string, whereRegistered: string) => {
        return ooic === YesNoValues.no && whereRegistered !== "Other";
      },
      then: Yup.string().required(
        t("errors.required", { field: t("trailer-power-unit-form.comp-collision-coverage.label") }),
      ),
    }),
    [Fields.statedValue]: Yup.number().when([Fields.ooIC, Fields.whereIsUnitRegistered, Fields.coverageOption], {
      is: (ooic: string, whereRegistered: string, coverageOption: string) => {
        sharedCoverageOption = coverageOption;
        return (
          ooic === YesNoValues.no &&
          whereRegistered !== "Other" &&
          coverageOption !== CompAndCollisionCoverage.NoCoverage
        );
      },
      then: Yup.number()
        .max(150000, t("power-units-card.stated-value-error-2"))
        .required(t("errors.required", { field: t("trailer-power-unit-form.stated-value.label") }))
        .test(
          "is-large-enough",
          t("trailer-power-unit-form.stated-value-error"),
          (value) => !checkStatedValue(Number(value), sharedCoverageOption),
        ),
    }),
    [Fields.gapCoverage]: Yup.string().when(
      [Fields.ooIC, Fields.whereIsUnitRegistered, Fields.coverageOption, Fields.statedValue],
      {
        is: (ooic: string, whereRegistered: string, coverageOption: string) => {
          return (
            ooic === YesNoValues.no &&
            whereRegistered !== "Other" &&
            coverageOption !== CompAndCollisionCoverage.NoCoverage
          );
        },
        then: Yup.string()
          .required(t("errors.required", { field: t("trailer-power-unit-form.gap-coverage.label") }))
          .test(
            "is-yes-no",
            t("errors.required", { field: t("trailer-power-unit-form.gap-coverage.label") }),
            (value) => Boolean(value && [YesNoValues.yes, YesNoValues.no].includes(value as YesNoValues)),
          ),
      },
    ),
  });
};

const checkStatedValue = (statedValue: number | undefined, compCollisionValue: string | undefined): boolean => {
  const statedValueValidationHelper: { [key: string]: number } = {
    [CompAndCollisionCoverage._1000Deductible]: 1000,
    [CompAndCollisionCoverage._2000Deductible]: 2000,
    [CompAndCollisionCoverage._5000Deductible]: 5000,
  };
  return statedValue! < statedValueValidationHelper[compCollisionValue || ""];
};

export type TrailerPowerUnitFormProps = {
  [Fields.vehicleNumber]: string;
  [Fields.vin]: string;
  [Fields.make]: string;
  [Fields.model]: string;
  [Fields.year]: number | undefined;
  [Fields.registrationZip]: string | undefined;
  [Fields.destination]: string;
  [Fields.ooIC]: string;
  [Fields.whereIsUnitRegistered]?: string;
  [Fields.coverageOption]?: string;
  [Fields.statedValue]?: number | undefined;
  [Fields.gapCoverage]?: string;
};

export type Props = {
  policyState?: string;
  vehicleNumber?: string;
  vin?: string;
  make?: string;
  model?: string;
  year?: number;
  registrationZip?: string;
  destination?: string;
  ooIC?: string;
  whereIsUnitRegistered?: string;
  coverageOption?: string;
  statedValue?: number;
  gapCoverage?: string;
};

export const useFormikConfig = ({
  vehicleNumber = "",
  vin = "",
  make = "",
  model = "",
  year = undefined,
  registrationZip = undefined,
  destination = "",
  ooIC = "",
  whereIsUnitRegistered = "",
  coverageOption = "",
  statedValue: initialStatedValue = undefined,
  gapCoverage: initialGapCoverage = "",
}: Props = {}): Omit<FormikConfig<TrailerPowerUnitFormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.vehicleNumber]: vehicleNumber,
      [Fields.vin]: vin,
      [Fields.make]: make,
      [Fields.model]: model,
      [Fields.year]: year,
      [Fields.registrationZip]: registrationZip,
      [Fields.destination]: destination,
      [Fields.ooIC]: ooIC,
      [Fields.whereIsUnitRegistered]: whereIsUnitRegistered,
      [Fields.coverageOption]: coverageOption,
      [Fields.statedValue]: initialStatedValue,
      [Fields.gapCoverage]: initialGapCoverage,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

export const TrailerPowerUnitForm: React.FC<Props> = ({ policyState }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { mutateAsync: getYearMakeModel, isError } = useGetYearMakeModel();
  const registeredTo = useRegisteredToTypes();
  const destinationTypes = useDestinationTypes().filter(
    (destinationType) => !disabledDestinations.includes(destinationType),
  );
  const coverageTypes = useCoverageOptionTypes();
  const formik = useFormikContext<TrailerPowerUnitFormProps>();

  return (
    <>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={5} md={6}>
          <TextInput
            className={classes.inputTitle}
            label={t("trailer-power-unit-form.vehicle-number.label")}
            type="text"
            fullWidth
            id={Fields.vehicleNumber}
            name={Fields.vehicleNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.vehicleNumber]}
            error={formik.touched[Fields.vehicleNumber] && Boolean(formik.errors[Fields.vehicleNumber])}
            helperText={formik.touched[Fields.vehicleNumber] && formik.errors[Fields.vehicleNumber]}
          />
        </Grid>
        <Grid item xs={7} md={6}>
          <TextInput
            className={classes.inputTitle}
            label={t("trailer-power-unit-form.vin.label")}
            required
            type="text"
            fullWidth
            id={Fields.vin}
            name={Fields.vin}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              formik.handleChange(event);
              if (event.target.value.length === 17) {
                getYearMakeModel({ vin: event.target.value }).then((response) => {
                  if (!isError) {
                    formik.setFieldValue(Fields.year, response.year);
                    formik.setFieldValue(Fields.make, response.make);
                    formik.setFieldValue(Fields.model, response.model);
                    response.year?.length !== 0
                      ? formik.setFieldTouched(Fields.year, true)
                      : formik.setFieldTouched(Fields.year, false);
                  }
                });
              }
            }}
            onBlur={formik.handleBlur}
            inputProps={{ maxLength: 17 }}
            value={formik.values[Fields.vin].toUpperCase()}
            error={formik.touched[Fields.vin] && Boolean(formik.errors[Fields.vin])}
            helperText={formik.touched[Fields.vin] && formik.errors[Fields.vin]}
          />
        </Grid>
      </Grid>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={6}>
          <TextInput
            label={t("trailer-power-unit-form.make.label")}
            required
            type="text"
            fullWidth
            id={Fields.make}
            name={Fields.make}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.make]}
            error={formik.touched[Fields.make] && Boolean(formik.errors[Fields.make])}
            helperText={formik.touched[Fields.make] && formik.errors[Fields.make]}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            label={t("trailer-power-unit-form.model.label")}
            type="text"
            fullWidth
            id={Fields.model}
            name={Fields.model}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.model]}
            error={formik.touched[Fields.model] && Boolean(formik.errors[Fields.model])}
            helperText={formik.touched[Fields.model] && formik.errors[Fields.model]}
          />
        </Grid>
      </Grid>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={4}>
          <NumericInput
            label={t("trailer-power-unit-form.year.label")}
            required
            fullWidth
            decimalScale={0}
            format="####"
            id={Fields.year}
            name={Fields.year}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.year]}
            error={formik.touched[Fields.year] && Boolean(formik.errors[Fields.year])}
            helperText={
              formik.touched[Fields.year] &&
              (formik.errors[Fields.year] === t("trailer-power-unit-form.year.before1998") ? (
                <Trans i18nKey={"trailer-power-unit-form.year.before1998"}>
                  <strong></strong>year.before1998
                </Trans>
              ) : (
                formik.errors[Fields.year]
              ))
            }
          />
        </Grid>
        <Grid item xs={8}>
          <NumericInput
            label={t("trailer-power-unit-form.registration-zip.label")}
            required
            format="#####"
            allowLeadingZeros
            fullWidth
            decimalScale={0}
            id={Fields.registrationZip}
            name={Fields.registrationZip}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.registrationZip]}
            error={formik.touched[Fields.registrationZip] && Boolean(formik.errors[Fields.registrationZip])}
            helperText={formik.touched[Fields.registrationZip] && formik.errors[Fields.registrationZip]}
          />
        </Grid>
      </Grid>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={12}>
          <Select
            label={t("trailer-power-unit-form.destination.label")}
            required
            fullWidth
            labelAdornment={
              <TooltipHelp title={t("trailer-power-unit-form.destination.help-text").toString()} placement="left-end" />
            }
            items={destinationTypes.map((destinationType) => {
              const caveats = destinationCaveats
                .map((caveat) => {
                  if (policyState && destinationType === caveat.zone && caveat.states.includes(policyState)) {
                    return {
                      name: t(`destination-type.${destinationType.toString().replace(".", "")}`),
                      value: caveat.newZone,
                    };
                  } else return {};
                })
                .filter((item) => item.name !== undefined);
              if (caveats.length !== 0 && caveats[0].name !== undefined && caveats[0].value !== undefined) {
                return caveats[0];
              } else {
                return {
                  name: t(`destination-type.${destinationType.toString().replace(".", "")}`),
                  value: destinationType,
                };
              }
            })}
            id={Fields.destination}
            name={Fields.destination}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.destination]}
            error={formik.touched[Fields.destination] && Boolean(formik.errors[Fields.destination])}
            helperText={formik.touched[Fields.destination] && formik.errors[Fields.destination]}
          />
        </Grid>
      </Grid>
      <Grid item>
        <YesNoInput
          label={t("trailer-power-unit-form.operator-or-contractor.label")}
          required
          id={Fields.ooIC}
          name={Fields.ooIC}
          yesText={t("common.yes")}
          noText={t("common.no")}
          radioClass={classes.ownerInput}
          fullWidth
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === YesNoValues.yes) {
              formik.setFieldValue(Fields.whereIsUnitRegistered, "");
              formik.setFieldValue(Fields.coverageOption, "");
              formik.setFieldValue(Fields.statedValue, 0);
              formik.setFieldValue(Fields.gapCoverage, "");
              formik.setTouched({
                [(Fields.whereIsUnitRegistered, Fields.coverageOption, Fields.statedValue, Fields.gapCoverage)]: false,
              });
            }
            formik.handleChange(event);
          }}
          onBlur={formik.handleBlur}
          value={formik.values[Fields.ooIC]}
          error={formik.touched[Fields.ooIC] && Boolean(formik.errors[Fields.ooIC])}
          helperText={formik.touched[Fields.ooIC] && formik.errors[Fields.ooIC]}
        />
      </Grid>
      {formik.values[Fields.ooIC] === YesNoValues.no && (
        <>
          <Grid item xs={12}>
            <Select
              label={t("trailer-power-unit-form.where-is-unit-registered.label")}
              required
              fullWidth
              items={registeredTo.map((companyType: RegisteredTo) => ({
                name: t(`trailer-power-unit-form.unit-registed-to.${companyType}`),
                value: companyType,
              }))}
              id={Fields.whereIsUnitRegistered}
              name={Fields.whereIsUnitRegistered}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value === RegisteredTo.Other) {
                  formik.setFieldValue(Fields.coverageOption, "");
                  formik.setFieldValue(Fields.statedValue, 0);
                  formik.setFieldValue(Fields.gapCoverage, "");
                  formik.setTouched({
                    [(Fields.coverageOption, Fields.statedValue, Fields.gapCoverage)]: false,
                  });
                }
                formik.handleChange(event);
              }}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.whereIsUnitRegistered]}
              error={
                formik.touched[Fields.whereIsUnitRegistered] && Boolean(formik.errors[Fields.whereIsUnitRegistered])
              }
              helperText={formik.touched[Fields.whereIsUnitRegistered] && formik.errors[Fields.whereIsUnitRegistered]}
            />
          </Grid>
          {(formik.values[Fields.whereIsUnitRegistered] === RegisteredTo.Company ||
            formik.values[Fields.whereIsUnitRegistered] === RegisteredTo.LeasingCompany) && (
            <>
              <Grid item xs={12}>
                <Select
                  id={Fields.coverageOption}
                  name={Fields.coverageOption}
                  fullWidth
                  label={t(`trailer-power-unit-form.comp-collision-coverage.label`)}
                  required
                  error={formik.touched[Fields.coverageOption] && Boolean(formik.errors[Fields.coverageOption])}
                  items={coverageTypes.map((coverageType: CompAndCollisionCoverage) => ({
                    name: coverageType,
                    value: coverageType,
                  }))}
                  value={formik.values[Fields.coverageOption]}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value === CompAndCollisionCoverage.NoCoverage) {
                      formik.setFieldValue(Fields.statedValue, 0);
                      formik.setFieldValue(Fields.gapCoverage, "");
                      formik.setTouched({
                        [(Fields.statedValue, Fields.gapCoverage)]: false,
                      });
                    }
                    formik.handleChange(event);
                  }}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched[Fields.coverageOption] && formik.errors[Fields.coverageOption]}
                />
              </Grid>
              {formik.values[Fields.coverageOption] &&
                formik.values[Fields.coverageOption] !== CompAndCollisionCoverage.NoCoverage && (
                  <>
                    <Grid item xs={6}>
                      <NumericInput
                        label={t("trailer-power-unit-form.stated-value.label")}
                        required
                        type="text"
                        fullWidth
                        decimalScale={0}
                        thousandSeparator
                        id={Fields.statedValue}
                        name={Fields.statedValue}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values[Fields.statedValue] === 0 ? "" : formik.values[Fields.statedValue]}
                        error={formik.touched[Fields.statedValue] && Boolean(formik.errors[Fields.statedValue])}
                        helperText={formik.touched[Fields.statedValue] && formik.errors[Fields.statedValue]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <YesNoInput
                        id={Fields.gapCoverage}
                        name={Fields.gapCoverage}
                        label={t(`trailer-power-unit-form.gap-coverage.label`)}
                        yesText={t("common.yes")}
                        noText={t("common.no")}
                        required
                        value={formik.values[Fields.gapCoverage]}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          formik.isValid && formik.submitForm();
                        }}
                        error={formik.touched[Fields.gapCoverage] && Boolean(formik.errors[Fields.gapCoverage])}
                        helperText={formik.touched[Fields.gapCoverage] && formik.errors[Fields.gapCoverage]}
                        labelAdornment={
                          <TooltipHelp
                            className={classes.gapTooltip}
                            title={t("trailer-power-unit-form.gap-coverage-info") as string}
                            placement="left-end"
                          />
                        }
                      />
                    </Grid>
                  </>
                )}
            </>
          )}
        </>
      )}
      {formik.values[Fields.whereIsUnitRegistered] === "Other" && formik.values[Fields.ooIC] === YesNoValues.no && (
        <EditableDeletableCard variant="default" border={false} className={classes.infoCardContainer}>
          <Grid container item direction="row" alignItems="center" className={classes.callForInfo}>
            <Grid item xs={2}>
              <InfoRounded className={classes.informationIcon} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h2">{t("trailer-power-unit-form.call-for-more-info")}</Typography>
            </Grid>
          </Grid>
        </EditableDeletableCard>
      )}
    </>
  );
};

export default TrailerPowerUnitForm;
