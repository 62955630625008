import {
  ApiV10EquipmentDeleteRequest,
  ApiV10EquipmentPostRequest,
  ApiV10EquipmentPutRequest,
  EquipmentApi,
  Equipment,
} from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useAddEquipment = (): UseMutationResult<Equipment | undefined, Error, ApiV10EquipmentPostRequest> => {
  const equipmentApi = useApi(EquipmentApi);

  const mutation = useMutation<Equipment | undefined, Error, ApiV10EquipmentPostRequest>(
    async (request: ApiV10EquipmentPostRequest) => {
      const response = await equipmentApi.apiV10EquipmentPost(request);

      if (response.error) {
        throw new Error(response.error.message!);
      }

      return response.data;
    },
  );
  return mutation;
};

export const useEditEquipment = (): UseMutationResult<Equipment | undefined, Error, ApiV10EquipmentPutRequest> => {
  const equipmentApi = useApi(EquipmentApi);

  const mutation = useMutation<Equipment | undefined, Error, ApiV10EquipmentPutRequest>(
    async (request: ApiV10EquipmentPutRequest) => {
      const response = await equipmentApi.apiV10EquipmentPut(request);

      if (response.error) {
        throw new Error(response.error.message!);
      }

      return response.data;
    },
  );
  return mutation;
};

export const useDeleteEquipment = (): UseMutationResult<void, Error, ApiV10EquipmentDeleteRequest> => {
  const equipmentApi = useApi(EquipmentApi);

  const mutation = useMutation<void, Error, ApiV10EquipmentDeleteRequest>(
    async (request: ApiV10EquipmentDeleteRequest) => {
      const response = await equipmentApi.apiV10EquipmentDelete(request);

      if (response.error) {
        throw new Error(response.error.message!);
      }

      return;
    },
  );
  return mutation;
};
