import { ApiV10EquipmentGetMakeModelGetRequest, Equipment, EquipmentApi } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export type YearMakeModel = {
  year: string | undefined | null;
  make: string | undefined | null;
  model: string | undefined | null;
};

export const useGetYearMakeModel = (): UseMutationResult<
  Equipment,
  Error | unknown,
  ApiV10EquipmentGetMakeModelGetRequest
> => {
  const equipmentApi = useApi(EquipmentApi);
  const mutation = useMutation<Equipment, Error | unknown, ApiV10EquipmentGetMakeModelGetRequest>(
    async (request: ApiV10EquipmentGetMakeModelGetRequest) => {
      const response = await equipmentApi.apiV10EquipmentGetMakeModelGet(request);
      const yearMakeModel: YearMakeModel = {
        year: response.data!.year,
        make: response.data!.make,
        model: response.data!.model,
      };
      return yearMakeModel;
    },
  );
  return mutation;
};
