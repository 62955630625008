/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentConfirmOverlay,
  NextButton,
  PlusCircleIcon as PlusCircle,
  PreviousButton,
  YesNoValues,
} from "@chq/components";
import { RegisteredTo } from "@chq/enrollment-api";
import { CompAndCollisionCoverage, Equipment, EquipmentType, State, ZoneEnum } from "@chq/policy-management-api";
import { Button, Grid, makeStyles, Tab, Tabs, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Fields as CheckboxFormFields } from "../../../components/address-form";
import AddEquipmentCard from "../../../components/policy-management-components/add-equipment-card";
import EquipmentForm, {
  Fields as EquipmentFields,
} from "../../../components/policy-management-components/equipment-form";
import { PolicyEquipmentContext } from "../../../data/policy-management/PolicyManagementEquipmentContext";
import { equipmentFieldMapper } from "../../../utils/equipment-field-mapper";
import { getEquipmentFieldDifferences } from "../../../utils/get-equipment-field-differences";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "2.5rem 0.75rem 0.625rem",
    margin: "auto",
  },
  title: {
    marginBottom: "1.5rem",
    marginLeft: "0.75rem",
  },
  editIcon: {
    height: "30px",
    width: "auto",
  },
  addIcon: {
    height: "40px",
    width: "auto",
  },
  addButton: {
    alignSelf: "flex-start",
    margin: "1rem 0rem",
    ...theme.typography.h4,
    color: theme.palette.primary.main,
  },
  responsiveAddButton: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      alignSelf: "center",
    },
  },
  tabs: {
    width: "100%",
    "& .MuiTabs-indicator": {
      height: "4px",
    },
    "& .MuiTabs-scroller": {
      display: "flex",
      justifyContent: "center",
    },
    marginBottom: "1.125rem",
  },
  tab: {
    ...theme.typography.h2,
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
    color: theme.palette.grey[400],
  },
  tabPanel: {
    margin: "2rem 0rem",
    display: "flex",
    flex: 1,
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  cancelButton: {
    minHeight: "56px",
    marginBottom: "1.875rem",
  },
  card: {
    position: "relative",
  },
  cancelButtonContainer: {
    alignSelf: "flex-end",
  },
}));

function TabPanel(props: { value: number; index: number; className: string; children: any }) {
  const { children, value, index, className } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`equipment-page-tab-value-${index}`} className={className}>
      {value === index && children}
    </div>
  );
}

export type EquipmentItem = {
  equipment: Equipment;
  isEditing: boolean;
  editedFields: string[];
  isNew?: boolean;
  isDeleted?: boolean;
};

export type EquipmentList = {
  trailers: EquipmentItem[];
  powerUnits: EquipmentItem[];
};

const EditEquipmentPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const { policyEquipment, updatePolicyEquipment } = useContext(PolicyEquipmentContext);
  const [showConfirmationOverlay, setShowConfirmationOverlay] = useState<number | null>(null);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [equipmentList, setEquipmentList] = useState<EquipmentList>();

  const isValid = useMemo(() => {
    if (equipmentList) {
      return equipmentList.powerUnits && equipmentList.trailers
        ? equipmentList.powerUnits.every((powerUnit) => {
            return !powerUnit.isEditing;
          }) &&
            equipmentList.trailers.every((trailer) => {
              return !trailer.isEditing;
            })
        : false;
    }
  }, [equipmentList]);

  useEffect(() => {
    setEquipmentList(policyEquipment);
  }, [policyEquipment]);

  const deleteConfirmation = (onDelete: () => void) => {
    onDelete();
    setShowConfirmationOverlay(null);
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        sm={10}
        direction="column"
        justify="center"
        className={classes.container}
        alignItems="center"
      >
        {isSmall && (
          <Grid container item xs={12} spacing={2} justify="space-between">
            <Grid item container xs={12} sm={8} spacing={isSmall ? 1 : 2}>
              <Grid item xs={3}>
                <PreviousButton
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.previousButton}
                  onClick={() => history.push(routes.policyManagement.coverages.edit.path)}
                  aria-label={t("policy-management-page.equipment-page.previous-button")}
                ></PreviousButton>
              </Grid>
              <Grid item xs={9}>
                <NextButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!isValid}
                  onClick={() => {
                    updatePolicyEquipment(equipmentList);
                    history.push(routes.policyManagement.reviewEquipmentAndCoverages.path);
                  }}
                >
                  {t("policy-management-page.equipment-page.review-changes-button")}
                </NextButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.cancelButton}
                onClick={() => history.push(routes.policyManagement.equipment.path)}
              >
                {t("common.cancel")}
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Typography variant="h2" className={classes.title}>
            {t("policy-management-page.coverages-page.update-coverages-and-equipment")}
          </Typography>
        </Grid>
        <Tabs
          value={value}
          onChange={(_event, value) => setValue(value)}
          aria-label={t("policy-management-page.equipment-page.tabs-label")}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
        >
          <Tab label={t("enrollment.equipment-page.power-units-title")} className={classes.tab} />
          <Tab label={t("enrollment.equipment-page.trailers-title")} className={classes.tab} />
        </Tabs>
        <Typography variant="h4" component="h3">
          {t("policy-management-page.equipment-page.edit-equipment-info")}
        </Typography>
        <Grid container item xs={12} md={10} lg={10} justify="center">
          <TabPanel value={value} index={0} className={classes.tabPanel}>
            <Grid container direction="row" justify="center" spacing={isSmall ? 2 : 5}>
              <>
                {isSmall && (
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Button
                      variant="text"
                      color="primary"
                      fullWidth
                      startIcon={<PlusCircle className={classes.addIcon} />}
                      className={classes.addButton}
                      onClick={() =>
                        setEquipmentList((prevState) => {
                          const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                          const trailers = prevState?.trailers ? prevState.trailers : [];
                          return {
                            trailers: trailers,
                            powerUnits: [
                              {
                                equipment: {
                                  equipmentType: EquipmentType.PowerUnit,
                                  vehicleNumber: "",
                                  vin: "",
                                  make: "",
                                  model: "",
                                  year: undefined,
                                  statedValue: undefined,
                                  registrationZip: undefined,
                                  destinationZone: undefined,
                                  ownerOperator: undefined,
                                  registeredToCompany: undefined,
                                  loanLeaseGapCoverage: undefined,
                                  compAndCollisionCoverage: undefined,
                                  lossPayees: undefined,
                                  additionalInsured: undefined,
                                  applicationId: undefined,
                                },
                                editedFields: [],
                                isEditing: true,
                                isNew: true,
                              },
                              ...powerUnits,
                            ],
                          };
                        })
                      }
                    >
                      {t("enrollment.equipment-page.add-power-units-button")}
                    </Button>
                  </Grid>
                )}
                {equipmentList?.powerUnits.map((powerUnit, index) => {
                  return (
                    <Grid item xs={12} sm={8} md={6} lg={4} key={powerUnit.equipment.id || index}>
                      {powerUnit.isEditing ? (
                        <div className={classes.card}>
                          <EquipmentForm
                            key={`powerUnit-form-${index}-${powerUnit.equipment.vin}`}
                            unitCount={index + 1}
                            variant={EquipmentType.PowerUnit}
                            vehicleNumber={powerUnit.equipment.vehicleNumber!}
                            vin={powerUnit.equipment.vin!}
                            make={powerUnit.equipment.make!}
                            model={powerUnit.equipment.model!}
                            year={powerUnit.equipment.year ? parseInt(powerUnit.equipment.year) : undefined}
                            statedValue={powerUnit.equipment.statedValue}
                            registrationZip={
                              powerUnit.equipment.registrationZip ? powerUnit.equipment.registrationZip : undefined
                            }
                            destination={powerUnit.equipment.destinationZone!}
                            ooIC={powerUnit.equipment.ownerOperator ? "1" : "0"}
                            registered={powerUnit.equipment.registeredToCompany ? "1" : "0"}
                            coverageOption={powerUnit.equipment.compAndCollisionCoverage}
                            gapCoverage={powerUnit.equipment.loanLeaseGapCoverage ? "1" : "0"}
                            additionalInsuredForm={
                              !_.isEmpty(powerUnit.equipment.additionalInsured)
                                ? {
                                    [CheckboxFormFields.entityName]: powerUnit.equipment.additionalInsured![0].name!,
                                    [CheckboxFormFields.addressLine1]: powerUnit.equipment.additionalInsured![0].address
                                      ?.address1,
                                    [CheckboxFormFields.addressLine2]: powerUnit.equipment.additionalInsured![0].address
                                      ?.address2,
                                    [CheckboxFormFields.city]: powerUnit.equipment.additionalInsured![0].address?.city,
                                    [CheckboxFormFields.state]: powerUnit.equipment.additionalInsured![0].address
                                      ?.state,
                                    [CheckboxFormFields.zipcode]: powerUnit.equipment.additionalInsured![0].address
                                      ?.zip,
                                    [CheckboxFormFields.ein]: powerUnit.equipment.additionalInsured![0].einNumber,
                                  }
                                : undefined
                            }
                            additionalInsured={!_.isEmpty(powerUnit.equipment.additionalInsured)}
                            additionalInsuredFormComplete={!_.isEmpty(powerUnit.equipment.additionalInsured)}
                            lossPayeeForm={
                              !_.isEmpty(powerUnit.equipment.lossPayees)
                                ? {
                                    [CheckboxFormFields.entityName]: powerUnit.equipment.lossPayees![0].name!,
                                    [CheckboxFormFields.addressLine1]: powerUnit.equipment.lossPayees![0].address
                                      ?.address1,
                                    [CheckboxFormFields.addressLine2]: powerUnit.equipment.lossPayees![0].address
                                      ?.address2,
                                    [CheckboxFormFields.city]: powerUnit.equipment.lossPayees![0].address?.city,
                                    [CheckboxFormFields.state]: powerUnit.equipment.lossPayees![0].address?.state,
                                    [CheckboxFormFields.zipcode]: powerUnit.equipment.lossPayees![0].address?.zip,
                                  }
                                : undefined
                            }
                            lossPayee={!_.isEmpty(powerUnit.equipment.lossPayees)}
                            lossPayeeFormComplete={!_.isEmpty(powerUnit.equipment.lossPayees)}
                            IconButtonProps={{ disabled: equipmentList?.powerUnits.length === 1 }}
                            onDelete={() => setShowConfirmationOverlay(index)}
                            onSubmit={(values) => {
                              const mappedEquipment = equipmentFieldMapper(powerUnit.equipment);
                              const differentFields = getEquipmentFieldDifferences(mappedEquipment, values) as string[];
                              setEquipmentList((prevState) => {
                                const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                                const trailers = prevState?.trailers ? prevState.trailers : [];
                                return {
                                  trailers: trailers,
                                  powerUnits: powerUnits.map((item, i) =>
                                    index === i
                                      ? {
                                          equipment: {
                                            ...item.equipment,
                                            equipmentType: EquipmentType.PowerUnit,
                                            vehicleNumber: values[EquipmentFields.vehicleNumber],
                                            vin: values[EquipmentFields.vin],
                                            make: values[EquipmentFields.make],
                                            model: values[EquipmentFields.model],
                                            year: values[EquipmentFields.year]
                                              ? values[EquipmentFields.year]!.toString()
                                              : undefined,
                                            statedValue: values[EquipmentFields.statedValue]!,
                                            registrationZip: values[EquipmentFields.registrationZip]
                                              ? values[EquipmentFields.registrationZip]!.toString()
                                              : undefined,
                                            destinationZone: values[EquipmentFields.destination] as ZoneEnum,
                                            ownerOperator: values[EquipmentFields.ooIC] === YesNoValues.yes,
                                            registeredTo: values[EquipmentFields.whereIsUnitRegistered] as RegisteredTo,
                                            deductible: undefined,
                                            loanLeaseGapCoverage:
                                              values[EquipmentFields.gapCoverage] === YesNoValues.yes,
                                            compAndCollisionCoverage: values[
                                              EquipmentFields.coverageOption
                                            ] as CompAndCollisionCoverage,
                                            lossPayees: !_.isEmpty(values[EquipmentFields.lossPayee])
                                              ? [
                                                  {
                                                    id: !_.isEmpty(item.equipment.lossPayees)
                                                      ? item.equipment.lossPayees![0].id
                                                      : undefined,
                                                    name: values[EquipmentFields.lossPayee]![
                                                      CheckboxFormFields.entityName
                                                    ],
                                                    address: {
                                                      address1: values[EquipmentFields.lossPayee]![
                                                        CheckboxFormFields.addressLine1
                                                      ],
                                                      address2: values[EquipmentFields.lossPayee]![
                                                        CheckboxFormFields.addressLine2
                                                      ],
                                                      city: values[EquipmentFields.lossPayee]![CheckboxFormFields.city],
                                                      state: values[EquipmentFields.lossPayee]![
                                                        CheckboxFormFields.state
                                                      ] as State,
                                                      zip: values[EquipmentFields.lossPayee]![
                                                        CheckboxFormFields.zipcode
                                                      ],
                                                      county: !_.isEmpty(item.equipment.lossPayees)
                                                        ? item.equipment.lossPayees![0].address?.county
                                                        : undefined,
                                                    },
                                                  },
                                                ]
                                              : undefined,
                                            additionalInsured: !_.isEmpty(values[EquipmentFields.additionalInsured])
                                              ? [
                                                  {
                                                    id: !_.isEmpty(item.equipment.additionalInsured)
                                                      ? item.equipment.additionalInsured![0].id
                                                      : undefined,
                                                    name: values[EquipmentFields.additionalInsured]![
                                                      CheckboxFormFields.entityName
                                                    ],
                                                    einNumber: values[EquipmentFields.additionalInsured]![
                                                      CheckboxFormFields.ein
                                                    ],
                                                    address: {
                                                      address1: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.addressLine1
                                                      ],
                                                      address2: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.addressLine2
                                                      ],
                                                      city: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.city
                                                      ],
                                                      state: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.state
                                                      ] as State,
                                                      zip: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.zipcode
                                                      ],
                                                      county: !_.isEmpty(item.equipment.additionalInsured)
                                                        ? item.equipment.additionalInsured![0].address?.county
                                                        : undefined,
                                                    },
                                                  },
                                                ]
                                              : undefined,
                                          },
                                          isNew: item.isNew,
                                          isEditing: false,
                                          editedFields: _.uniq(
                                            _.concat(powerUnit.editedFields, differentFields) as string[],
                                          ),
                                        }
                                      : item,
                                  ),
                                };
                              });
                            }}
                          />
                          {showConfirmationOverlay === index && (
                            <ComponentConfirmOverlay
                              confirmText={t("common.delete")}
                              confirmAction={() =>
                                deleteConfirmation(() => {
                                  setEquipmentList((prevState) => {
                                    const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                                    const trailers = prevState?.trailers ? prevState.trailers : [];
                                    if (powerUnit.isNew) {
                                      return {
                                        powerUnits: powerUnits.filter((item, i) => index !== i),
                                        trailers,
                                      };
                                    } else {
                                      return {
                                        powerUnits: powerUnits.map((item, i) =>
                                          index === i
                                            ? {
                                                ...item,
                                                isEditing: false,
                                                isDeleted: true,
                                              }
                                            : item,
                                        ),
                                        trailers: trailers,
                                      };
                                    }
                                  });
                                })
                              }
                              cancelText={t("common.cancel-delete")}
                              cancelAction={() => setShowConfirmationOverlay(null)}
                            />
                          )}
                        </div>
                      ) : (
                        <AddEquipmentCard
                          key={`powerUnit-${index}-${powerUnit.equipment.id}`}
                          bannerText={
                            powerUnit.isDeleted
                              ? t("policy-management-page.equipment-page.deleted-power-unit-card")
                              : powerUnit.isNew
                              ? t("policy-management-page.equipment-page.new-power-unit-card")
                              : powerUnit.editedFields.length > 0
                              ? t("policy-management-page.equipment-page.updated-power-unit-card")
                              : undefined
                          }
                          isNew={powerUnit.isNew}
                          isDeleted={powerUnit.isDeleted}
                          editedFields={powerUnit.isNew ? [] : powerUnit.editedFields}
                          equipment={powerUnit.equipment}
                          equipmentIndex={index}
                          onEdit={() =>
                            setEquipmentList((prevState) => {
                              const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                              const trailers = prevState?.trailers ? prevState.trailers : [];
                              return {
                                trailers: trailers,
                                powerUnits: powerUnits.map((item, i) =>
                                  index === i ? { ...item, isEditing: true } : item,
                                ),
                              };
                            })
                          }
                        />
                      )}
                    </Grid>
                  );
                })}
                <Grid item xs={12} sm={8} md={6} lg={4}>
                  <Button
                    variant="text"
                    color="primary"
                    fullWidth
                    startIcon={<PlusCircle className={classes.addIcon} />}
                    className={classes.addButton}
                    onClick={() =>
                      setEquipmentList((prevState) => {
                        const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                        const trailers = prevState?.trailers ? prevState.trailers : [];
                        return {
                          trailers: trailers,
                          powerUnits: [
                            ...powerUnits,
                            {
                              equipment: {
                                equipmentType: EquipmentType.PowerUnit,
                                vehicleNumber: "",
                                vin: "",
                                make: "",
                                model: "",
                                year: undefined,
                                statedValue: undefined,
                                registrationZip: undefined,
                                destinationZone: undefined,
                                ownerOperator: undefined,
                                registeredToCompany: undefined,
                                deductible: undefined,
                                loanLeaseGapCoverage: undefined,
                                compAndCollisionCoverage: undefined,
                                lossPayees: undefined,
                                additionalInsured: undefined,
                                applicationId: undefined,
                              },
                              editedFields: [],
                              isEditing: true,
                              isNew: true,
                            },
                          ],
                        };
                      })
                    }
                  >
                    {t("enrollment.equipment-page.add-power-units-button")}
                  </Button>
                </Grid>
              </>
            </Grid>
          </TabPanel>
        </Grid>
        <Grid container item xs={12} md={10} lg={10} justify="center">
          <TabPanel value={value} index={1} className={classes.tabPanel}>
            <Grid container direction="row" justify="center" spacing={isSmall ? 2 : 5}>
              <>
                {isSmall && (
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Button
                      variant="text"
                      color="primary"
                      fullWidth
                      startIcon={<PlusCircle className={classes.addIcon} />}
                      className={classes.addButton}
                      onClick={() =>
                        setEquipmentList((prevState) => {
                          const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                          const trailers = prevState?.trailers ? prevState.trailers : [];
                          return {
                            trailers: [
                              {
                                equipment: {
                                  equipmentType: EquipmentType.Trailer,
                                  vehicleNumber: "",
                                  vin: "",
                                  make: "",
                                  model: "",
                                  year: undefined,
                                  statedValue: undefined,
                                  registrationZip: undefined,
                                  destinationZone: undefined,
                                  ownerOperator: undefined,
                                  registeredToCompany: undefined,
                                  deductible: undefined,
                                  loanLeaseGapCoverage: undefined,
                                  compAndCollisionCoverage: undefined,
                                  lossPayees: undefined,
                                  additionalInsured: undefined,
                                  applicationId: undefined,
                                },
                                editedFields: [],
                                isEditing: true,
                                isNew: true,
                              },
                              ...trailers,
                            ],
                            powerUnits: powerUnits,
                          };
                        })
                      }
                    >
                      {t("enrollment.equipment-page.add-trailers-button")}
                    </Button>
                  </Grid>
                )}
                {equipmentList?.trailers.map((trailer, index) => {
                  return (
                    <Grid item xs={12} sm={8} md={6} lg={4} key={trailer.equipment.id || index}>
                      {trailer.isEditing ? (
                        <div className={classes.card}>
                          <EquipmentForm
                            key={`trailer-form-${index}-${trailer.equipment.vin}`}
                            variant={EquipmentType.Trailer}
                            unitCount={index + 1}
                            vehicleNumber={trailer.equipment.vehicleNumber!}
                            vin={trailer.equipment.vin!}
                            make={trailer.equipment.make!}
                            model={trailer.equipment.model!}
                            year={trailer.equipment.year ? parseInt(trailer.equipment.year) : undefined}
                            statedValue={trailer.equipment.statedValue}
                            registrationZip={
                              trailer.equipment.registrationZip ? trailer.equipment.registrationZip : undefined
                            }
                            destination={trailer.equipment.destinationZone!}
                            ooIC={trailer.equipment.ownerOperator ? "1" : "0"}
                            registered={trailer.equipment.registeredToCompany ? "1" : "0"}
                            coverageOption={trailer.equipment.compAndCollisionCoverage}
                            gapCoverage={trailer.equipment.loanLeaseGapCoverage ? "1" : "0"}
                            additionalInsuredForm={
                              !_.isEmpty(trailer.equipment.additionalInsured)
                                ? {
                                    [CheckboxFormFields.entityName]: trailer.equipment.additionalInsured![0].name!,
                                    [CheckboxFormFields.addressLine1]: trailer.equipment.additionalInsured![0].address
                                      ?.address1,
                                    [CheckboxFormFields.addressLine2]: trailer.equipment.additionalInsured![0].address
                                      ?.address2,
                                    [CheckboxFormFields.city]: trailer.equipment.additionalInsured![0].address?.city,
                                    [CheckboxFormFields.state]: trailer.equipment.additionalInsured![0].address?.state,
                                    [CheckboxFormFields.zipcode]: trailer.equipment.additionalInsured![0].address?.zip,
                                    [CheckboxFormFields.ein]: trailer.equipment.additionalInsured![0].einNumber,
                                  }
                                : undefined
                            }
                            additionalInsured={!_.isEmpty(trailer.equipment.additionalInsured)}
                            additionalInsuredFormComplete={!_.isEmpty(trailer.equipment.additionalInsured)}
                            lossPayeeForm={
                              !_.isEmpty(trailer.equipment.lossPayees)
                                ? {
                                    [CheckboxFormFields.entityName]: trailer.equipment.lossPayees![0].name!,
                                    [CheckboxFormFields.addressLine1]: trailer.equipment.lossPayees![0].address
                                      ?.address1,
                                    [CheckboxFormFields.addressLine2]: trailer.equipment.lossPayees![0].address
                                      ?.address2,
                                    [CheckboxFormFields.city]: trailer.equipment.lossPayees![0].address?.city,
                                    [CheckboxFormFields.state]: trailer.equipment.lossPayees![0].address?.state,
                                    [CheckboxFormFields.zipcode]: trailer.equipment.lossPayees![0].address?.zip,
                                  }
                                : undefined
                            }
                            lossPayee={!_.isEmpty(trailer.equipment.lossPayees)}
                            lossPayeeFormComplete={!_.isEmpty(trailer.equipment.lossPayees)}
                            IconButtonProps={{ disabled: equipmentList?.trailers.length === 1 }}
                            onDelete={() => setShowConfirmationOverlay(index)}
                            onSubmit={(values) => {
                              const mappedEquipment = equipmentFieldMapper(trailer.equipment);
                              const differentFields = getEquipmentFieldDifferences(mappedEquipment, values) as string[];
                              setEquipmentList((prevState) => {
                                const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                                const trailers = prevState?.trailers ? prevState.trailers : [];
                                return {
                                  trailers: trailers.map((item, i) =>
                                    index === i
                                      ? {
                                          equipment: {
                                            ...item.equipment,
                                            equipmentType: EquipmentType.Trailer,
                                            vehicleNumber: values[EquipmentFields.vehicleNumber],
                                            vin: values[EquipmentFields.vin],
                                            make: values[EquipmentFields.make],
                                            model: values[EquipmentFields.model],
                                            year: values[EquipmentFields.year]
                                              ? values[EquipmentFields.year]!.toString()
                                              : undefined,
                                            statedValue: values[EquipmentFields.statedValue]!,
                                            registrationZip: values[EquipmentFields.registrationZip]
                                              ? values[EquipmentFields.registrationZip]!.toString()
                                              : undefined,
                                            destinationZone: values[EquipmentFields.destination] as ZoneEnum,
                                            ownerOperator: values[EquipmentFields.ooIC] === YesNoValues.yes,
                                            registeredTo: values[EquipmentFields.whereIsUnitRegistered] as RegisteredTo,
                                            deductible: undefined,
                                            loanLeaseGapCoverage:
                                              values[EquipmentFields.gapCoverage] === YesNoValues.yes,
                                            compAndCollisionCoverage: values[
                                              EquipmentFields.coverageOption
                                            ] as CompAndCollisionCoverage,
                                            lossPayees: !_.isEmpty(values[EquipmentFields.lossPayee])
                                              ? [
                                                  {
                                                    id: !_.isEmpty(item.equipment.lossPayees)
                                                      ? item.equipment.lossPayees![0].id
                                                      : undefined,
                                                    name: values[EquipmentFields.lossPayee]![
                                                      CheckboxFormFields.entityName
                                                    ],
                                                    address: {
                                                      address1: values[EquipmentFields.lossPayee]![
                                                        CheckboxFormFields.addressLine1
                                                      ],
                                                      address2: values[EquipmentFields.lossPayee]![
                                                        CheckboxFormFields.addressLine2
                                                      ],
                                                      city: values[EquipmentFields.lossPayee]![CheckboxFormFields.city],
                                                      state: values[EquipmentFields.lossPayee]![
                                                        CheckboxFormFields.state
                                                      ] as State,
                                                      zip: values[EquipmentFields.lossPayee]![
                                                        CheckboxFormFields.zipcode
                                                      ],
                                                      county: !_.isEmpty(item.equipment.lossPayees)
                                                        ? item.equipment.lossPayees![0].address?.county
                                                        : undefined,
                                                    },
                                                  },
                                                ]
                                              : undefined,
                                            additionalInsured: !_.isEmpty(values[EquipmentFields.additionalInsured])
                                              ? [
                                                  {
                                                    id: !_.isEmpty(item.equipment.additionalInsured)
                                                      ? item.equipment.additionalInsured![0].id
                                                      : undefined,
                                                    name: values[EquipmentFields.additionalInsured]![
                                                      CheckboxFormFields.entityName
                                                    ],
                                                    einNumber: values[EquipmentFields.additionalInsured]![
                                                      CheckboxFormFields.ein
                                                    ],
                                                    address: {
                                                      address1: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.addressLine1
                                                      ],
                                                      address2: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.addressLine2
                                                      ],
                                                      city: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.city
                                                      ],
                                                      state: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.state
                                                      ] as State,
                                                      zip: values[EquipmentFields.additionalInsured]![
                                                        CheckboxFormFields.zipcode
                                                      ],
                                                      county: !_.isEmpty(item.equipment.additionalInsured)
                                                        ? item.equipment.additionalInsured![0].address?.county
                                                        : undefined,
                                                    },
                                                  },
                                                ]
                                              : undefined,
                                          },
                                          isNew: item.isNew,
                                          isEditing: false,
                                          editedFields: _.uniq(
                                            _.concat(trailer.editedFields, differentFields) as string[],
                                          ),
                                        }
                                      : item,
                                  ),
                                  powerUnits: powerUnits,
                                };
                              });
                            }}
                          />
                          {showConfirmationOverlay === index && (
                            <ComponentConfirmOverlay
                              confirmText={t("common.delete")}
                              confirmAction={() =>
                                deleteConfirmation(() => {
                                  setEquipmentList((prevState) => {
                                    const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                                    const trailers = prevState?.trailers ? prevState.trailers : [];
                                    if (trailer.isNew) {
                                      return {
                                        trailers: trailers.filter((item, i) => index !== i),
                                        powerUnits,
                                      };
                                    } else {
                                      return {
                                        trailers: trailers.map((item, i) =>
                                          index === i
                                            ? {
                                                ...item,
                                                isEditing: false,
                                                isDeleted: true,
                                              }
                                            : item,
                                        ),
                                        powerUnits: powerUnits,
                                      };
                                    }
                                  });
                                })
                              }
                              cancelText={t("common.cancel-delete")}
                              cancelAction={() => setShowConfirmationOverlay(null)}
                            />
                          )}
                        </div>
                      ) : (
                        <AddEquipmentCard
                          key={`trailer-${index}-${trailer.equipment.vin}`}
                          bannerText={
                            trailer.isDeleted
                              ? t("policy-management-page.equipment-page.deleted-trailer-card")
                              : trailer.isNew
                              ? t("policy-management-page.equipment-page.new-trailer-card")
                              : trailer.editedFields.length > 0
                              ? t("policy-management-page.equipment-page.updated-trailer-card")
                              : undefined
                          }
                          isNew={trailer.isNew}
                          isDeleted={trailer.isDeleted}
                          editedFields={trailer.isNew ? [] : trailer.editedFields}
                          equipment={trailer.equipment}
                          equipmentIndex={index}
                          onEdit={() =>
                            setEquipmentList((prevState) => {
                              const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                              const trailers = prevState?.trailers ? prevState.trailers : [];
                              return {
                                trailers: trailers.map((item, i) =>
                                  index === i ? { ...item, isEditing: true } : item,
                                ),
                                powerUnits: powerUnits,
                              };
                            })
                          }
                        />
                      )}
                    </Grid>
                  );
                })}
                <Grid item xs={12} sm={8} md={6} lg={4}>
                  <Button
                    variant="text"
                    color="primary"
                    fullWidth
                    startIcon={<PlusCircle className={classes.addIcon} />}
                    className={classes.addButton}
                    onClick={() =>
                      setEquipmentList((prevState) => {
                        const powerUnits = prevState?.powerUnits ? prevState.powerUnits : [];
                        const trailers = prevState?.trailers ? prevState.trailers : [];
                        return {
                          trailers: [
                            ...trailers,
                            {
                              equipment: {
                                equipmentType: EquipmentType.Trailer,
                                vehicleNumber: "",
                                vin: "",
                                make: "",
                                model: "",
                                year: undefined,
                                statedValue: undefined,
                                registrationZip: undefined,
                                destinationZone: undefined,
                                ownerOperator: undefined,
                                registeredToCompany: undefined,
                                deductible: undefined,
                                loanLeaseGapCoverage: undefined,
                                compAndCollisionCoverage: undefined,
                                lossPayees: undefined,
                                additionalInsured: undefined,
                                applicationId: undefined,
                              },
                              editedFields: [],
                              isEditing: true,
                              isNew: true,
                            },
                          ],
                          powerUnits: powerUnits,
                        };
                      })
                    }
                  >
                    {t("enrollment.equipment-page.add-trailers-button")}
                  </Button>
                </Grid>
              </>
            </Grid>
          </TabPanel>
        </Grid>
        <Grid container item xs={12} spacing={2} justify="space-between">
          <Grid container item direction="row" xs={12} sm={8} lg={9} spacing={isSmall ? 1 : 2}>
            <Grid item xs={3} md={2} lg={1}>
              <PreviousButton
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.previousButton}
                onClick={() => history.push(routes.policyManagement.coverages.edit.path)}
                aria-label={t("policy-management-page.equipment-page.previous-button")}
              ></PreviousButton>
            </Grid>
            <Grid item xs={9} md={6} lg={5}>
              <NextButton
                variant="contained"
                color="primary"
                fullWidth
                disabled={!isValid}
                onClick={() => {
                  updatePolicyEquipment(equipmentList);
                  history.push(routes.policyManagement.reviewEquipmentAndCoverages.path);
                }}
              >
                {t("policy-management-page.equipment-page.review-changes-button")}
              </NextButton>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} md={4} lg={2} className={classes.cancelButtonContainer}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.cancelButton}
              onClick={() => history.push(routes.policyManagement.equipment.path)}
            >
              {t("common.cancel")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditEquipmentPage;
