/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  DocumentApiResponse,
  DocumentApiResponseFromJSON,
  DocumentApiResponseToJSON,
  DocumentType,
  DocumentTypeFromJSON,
  DocumentTypeToJSON,
  FileContentResultApiResponse,
  FileContentResultApiResponseFromJSON,
  FileContentResultApiResponseToJSON,
} from "../models";

export interface PortalApiV10DocumentGetDocumentByIdGetRequest {
  documentId?: number;
}

export interface PortalApiV10DocumentPostRequest {
  file?: Blob;
  documentType?: DocumentType;
}

export interface PortalApiV10DocumentUriGetRequest {
  uri: string;
}

/**
 *
 */
export class DocumentApi extends runtime.BaseAPI {
  /**
   */
  async portalApiV10DocumentGetDocumentByIdGetRaw(
    requestParameters: PortalApiV10DocumentGetDocumentByIdGetRequest,
  ): Promise<runtime.ApiResponse<FileContentResultApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.documentId !== undefined) {
      queryParameters["documentId"] = requestParameters.documentId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/portal/api/v1.0/Document/getDocumentById`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FileContentResultApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async portalApiV10DocumentGetDocumentByIdGet(
    requestParameters: PortalApiV10DocumentGetDocumentByIdGetRequest,
  ): Promise<FileContentResultApiResponse> {
    const response = await this.portalApiV10DocumentGetDocumentByIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Uploads a document
   */
  async portalApiV10DocumentPostRaw(
    requestParameters: PortalApiV10DocumentPostRequest,
  ): Promise<runtime.ApiResponse<DocumentApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("File", requestParameters.file as any);
    }

    if (requestParameters.documentType !== undefined) {
      formParams.append("DocumentType", requestParameters.documentType as any);
    }

    const response = await this.request({
      path: `/portal/api/v1.0/Document`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => DocumentApiResponseFromJSON(jsonValue));
  }

  /**
   * Uploads a document
   */
  async portalApiV10DocumentPost(requestParameters: PortalApiV10DocumentPostRequest): Promise<DocumentApiResponse> {
    const response = await this.portalApiV10DocumentPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async portalApiV10DocumentUriGetRaw(
    requestParameters: PortalApiV10DocumentUriGetRequest,
  ): Promise<runtime.ApiResponse<FileContentResultApiResponse>> {
    if (requestParameters.uri === null || requestParameters.uri === undefined) {
      throw new runtime.RequiredError(
        "uri",
        "Required parameter requestParameters.uri was null or undefined when calling portalApiV10DocumentUriGet.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/portal/api/v1.0/Document/{uri}`.replace(`{${"uri"}}`, encodeURIComponent(String(requestParameters.uri))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FileContentResultApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async portalApiV10DocumentUriGet(
    requestParameters: PortalApiV10DocumentUriGetRequest,
  ): Promise<FileContentResultApiResponse> {
    const response = await this.portalApiV10DocumentUriGetRaw(requestParameters);
    return await response.value();
  }
}
