import { NextButton, PreviousButton, YesNoValues } from "@chq/components";
import { ELDProvider, OperatingAuthorityStatus } from "@chq/enrollment-api";
import { CircularProgress, Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { startOfToday } from "date-fns";
import { Formik } from "formik";
import React, { useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import CompanyInfoCard from "../../../components/company-info-card";
import CompanyInfoForm, {
  Fields as CompanyInfoFields,
  FormProps as CompanyInfoFormProps,
  useValidationSchema as useCompanyInfoFormValidation,
} from "../../../components/company-info-form";
import EldCard from "../../../components/eld-card";
import EldForm, {
  EldFormProps,
  Fields as EldFields,
  useValidationSchema as useEldFormValidation,
} from "../../../components/eld-form";
import GenericPageAlert from "../../../components/generic-page-alert";
import YourQuoteCard from "../../../components/your-quote-card";
import { useEditApplication, useGetApplication, useSendGeotabRequest } from "../../../data/enrollment";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.5rem .875rem 1.5rem .875rem",
    justifyContent: "center",
  },
  quote: {
    marginBottom: "1rem",
  },
  pageButtons: {
    justifyContent: "center",
    marginTop: "1rem",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  card: {
    paddingTop: "1.25rem",
  },
}));

type CompanyInfo = Partial<CompanyInfoFormProps> & {
  isEditing?: boolean;
};

type EldInfo = Partial<EldFormProps> & {
  isEditing?: boolean;
};

const FinishApplicationPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [t] = useTranslation();

  const isDesktopView = useMediaQuery(theme.breakpoints.up("sm"));
  const { data: application } = useGetApplication(RouteNames.finishApplication);
  const { mutate: editApplication, isLoading: isEditingApplication, isError } = useEditApplication();
  const [isCompanyInfoOpen, setIsCompanyInfoOpen] = useState<boolean>(!Boolean(application?.business?.einNumber));
  const [isEldInfoOpen, setIsEldInfoOpen] = useState<boolean>(!Boolean(application?.business?.eldProvider));

  const companyInfoValidation = useCompanyInfoFormValidation(
    application?.currentQuote?.quoteCreationDate || startOfToday(),
  );
  const eldInfoValidation = useEldFormValidation();

  const {
    mutate: sendEmail,
    isSuccess: emailSent,
    isError: emailFailedToSend,
    isLoading: sendingEmail,
    isIdle,
  } = useSendGeotabRequest();

  if (application?.insuranceProgram?.active === false) {
    return <Redirect to={routes.enrollment.quote.inactive.path} />;
  }

  return (
    <>
      {isError && <GenericPageAlert />}
      {!application?.qualified && qualifyRedirect(application?.ineligibleReason || "")}
      <Formik
        initialValues={{
          companyInfo: {
            isEditing: !Boolean(application?.business?.einNumber),
            [CompanyInfoFields.desiredEffectiveDate]: application?.desiredEffectiveDate,
            [CompanyInfoFields.ein]: application?.business?.einNumber || "",
            [CompanyInfoFields.mcNumber]: application?.business?.mcNumber || "",
            [CompanyInfoFields.operatingAuthStatus]: application?.business?.operatingAuthorityStatus,
          },
          eldInfo: {
            isEditing: !Boolean(application?.business?.eldProvider),
            [EldFields.eldProvider]: application?.business?.eldProvider
              ? application?.business?.eldProvider === ELDProvider.Geotab ||
                application?.business?.eldProvider === ELDProvider.MotiveKeepTruckin ||
                application?.business?.eldProvider === ELDProvider.Samsara ||
                application?.business?.eldProvider === ELDProvider.None
                ? application?.business?.eldProvider
                : ELDProvider.Other
              : undefined,
            [EldFields.otherEldProvider]:
              application?.business?.eldProvider &&
              application?.business?.eldProvider !== ELDProvider.Geotab &&
              application?.business?.eldProvider !== ELDProvider.MotiveKeepTruckin &&
              application?.business?.eldProvider !== ELDProvider.Samsara &&
              application?.business?.eldProvider !== ELDProvider.None
                ? application?.business?.eldProvider
                : undefined,
            [EldFields.eldAdmin]: application?.business?.eldAdminName || "",
            [EldFields.eldAccount]: application?.business?.fleetAccountNumber || "",
            [EldFields.keepTruckingAppDevice]:
              application?.business?.eldProvider === ELDProvider.MotiveKeepTruckin ? YesNoValues.yes : undefined,
          },
        }}
        enableReinitialize
        validateOnMount
        validate={({ companyInfo, eldInfo }: { companyInfo: CompanyInfo; eldInfo: EldInfo }) => {
          const errors: Record<string, string> = {};
          const isCompanyValid =
            !companyInfo.isEditing && !isCompanyInfoOpen && companyInfoValidation.isValidSync(companyInfo);
          const isEldValid = !eldInfo.isEditing && !isEldInfoOpen && eldInfoValidation.isValidSync(eldInfo);
          if (!isCompanyValid) {
            errors.companyInfo = t(`errors.required`, { field: "Company Information" });
          }
          if (!isEldValid) {
            errors.eldInfo = t(`errors.required`, { field: "ELD Information" });
          }
          return errors;
        }}
        onSubmit={(values) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "continueToPaymentEvent",
              continueToPaymentText: "Continue To Payment",
            },
          });
          editApplication(
            {
              ...application,
              business: {
                ...application?.business,
                einNumber: values.companyInfo[CompanyInfoFields.ein],
                mcNumber: values.companyInfo[CompanyInfoFields.mcNumber],
                operatingAuthorityStatus: values.companyInfo[
                  CompanyInfoFields.operatingAuthStatus
                ] as OperatingAuthorityStatus,
                eldProvider:
                  values.eldInfo[EldFields.eldProvider] === ELDProvider.Other
                    ? (values.eldInfo[EldFields.otherEldProvider] as ELDProvider)
                    : (values.eldInfo[EldFields.eldProvider] as ELDProvider),
                eldAdminName: values.eldInfo[EldFields.otherEldProvider] ? values.eldInfo[EldFields.eldAdmin] : "",
                fleetAccountNumber: values.eldInfo[EldFields.eldAccount],
              },
              desiredEffectiveDate: values.companyInfo[CompanyInfoFields.desiredEffectiveDate],
            },
            { onSuccess: () => history.push(routes.enrollment.payment.path) },
          );
        }}
      >
        {(formik) => (
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} sm={6} md={4}>
              {!isDesktopView && (
                <Grid item className={classes.quote}>
                  <YourQuoteCard
                    weeklyDownPayment={(application?.currentQuote?.rates?.weeklyDownPayment ?? 0).toString()}
                    monthlyDownPayment={(application?.currentQuote?.rates?.monthlyDownPayment ?? 0).toString()}
                    weeklyTotal={(application?.currentQuote?.rates?.weeklyRate ?? 0).toString()}
                    monthlyTotal={(application?.currentQuote?.rates?.monthlyRate ?? 0).toString()}
                    annualTotal={(application?.currentQuote?.rates?.annualizedRate ?? 0).toString()}
                  />
                </Grid>
              )}
              {!formik.values.companyInfo.isEditing && !isCompanyInfoOpen ? (
                <CompanyInfoCard
                  ein={formik.values.companyInfo[CompanyInfoFields.ein]}
                  mcNumber={formik.values.companyInfo[CompanyInfoFields.mcNumber]}
                  operatingAuthStatus={formik.values.companyInfo[CompanyInfoFields.operatingAuthStatus]}
                  desiredEffectiveDate={formik.values.companyInfo[CompanyInfoFields.desiredEffectiveDate]}
                  onEdit={() => {
                    setIsCompanyInfoOpen(true);
                    formik.setFieldValue("companyInfo", { ...formik.values.companyInfo, isEditing: true });
                  }}
                />
              ) : (
                <CompanyInfoForm
                  ein={formik.values.companyInfo[CompanyInfoFields.ein]}
                  mcNumber={formik.values.companyInfo[CompanyInfoFields.mcNumber]}
                  operatingAuthStatus={formik.values.companyInfo[CompanyInfoFields.operatingAuthStatus]}
                  desiredEffectiveDate={formik.values.companyInfo[CompanyInfoFields.desiredEffectiveDate]}
                  quoteCreationDate={application?.currentQuote?.quoteCreationDate}
                  isSomethingSaving={isEditingApplication}
                  onSubmit={(values) => {
                    setIsCompanyInfoOpen(false);
                    formik.setFieldValue("companyInfo", { ...values, isEditing: false });
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "saveCompanyInformationEvent",
                        saveCompanyInformationText: "Save Company Information",
                      },
                    });
                    editApplication({
                      ...application,
                      business: {
                        ...application?.business,
                        einNumber: values[CompanyInfoFields.ein],
                        mcNumber: values[CompanyInfoFields.mcNumber],
                        operatingAuthorityStatus: values[
                          CompanyInfoFields.operatingAuthStatus
                        ] as OperatingAuthorityStatus,
                      },
                      desiredEffectiveDate: values[CompanyInfoFields.desiredEffectiveDate],
                    });
                  }}
                />
              )}
            </Grid>
            <Grid container direction="column" item xs={12} sm={6} md={4}>
              {isDesktopView && (
                <Grid item className={classes.quote}>
                  <YourQuoteCard
                    weeklyDownPayment={(application?.currentQuote?.rates?.weeklyDownPayment ?? 0).toString()}
                    monthlyDownPayment={(application?.currentQuote?.rates?.monthlyDownPayment ?? 0).toString()}
                    weeklyTotal={(application?.currentQuote?.rates?.weeklyRate ?? 0).toString()}
                    monthlyTotal={(application?.currentQuote?.rates?.monthlyRate ?? 0).toString()}
                    annualTotal={(application?.currentQuote?.rates?.annualizedRate ?? 0).toString()}
                  />
                </Grid>
              )}
              <Grid item>
                {!formik.values.eldInfo.isEditing && !isEldInfoOpen ? (
                  <EldCard
                    eldProvider={formik.values.eldInfo[EldFields.eldProvider]!}
                    otherEldProvider={formik.values.eldInfo[EldFields.otherEldProvider]}
                    eldAdmin={formik.values.eldInfo[EldFields.eldAdmin]}
                    eldAccount={formik.values.eldInfo[EldFields.eldAccount]}
                    onEdit={() => {
                      setIsEldInfoOpen(true);
                      formik.setFieldValue("eldInfo", { ...formik.values.eldInfo, isEditing: true });
                    }}
                  />
                ) : (
                  <EldForm
                    eldProvider={formik.values.eldInfo[EldFields.eldProvider] as ELDProvider | undefined}
                    otherEldProvider={formik.values.eldInfo[EldFields.otherEldProvider] as ELDProvider | undefined}
                    eldAdmin={formik.values.eldInfo[EldFields.eldAdmin]}
                    eldAccount={formik.values.eldInfo[EldFields.eldAccount]}
                    keepTruckingAppDevice={formik.values.eldInfo[EldFields.keepTruckingAppDevice]}
                    isSomethingSaving={isEditingApplication}
                    onSubmit={(values) => {
                      setIsEldInfoOpen(false);
                      formik.setFieldValue("eldInfo", { ...values, isEditing: false });
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "saveELDInformationEvent",
                          saveELDInformationText: "Save ELD Information",
                        },
                      });
                      editApplication({
                        ...application,
                        business: {
                          ...application?.business,
                          eldProvider:
                            values[EldFields.eldProvider] === ELDProvider.Other
                              ? (values[EldFields.otherEldProvider] as ELDProvider)
                              : (values[EldFields.eldProvider] as ELDProvider),
                          eldAdminName: values[EldFields.otherEldProvider] ? values[EldFields.eldAdmin] : "",
                          fleetAccountNumber: values[EldFields.eldAccount],
                        },
                      });
                    }}
                    onClickEvent={() => sendEmail(application?.id)}
                    emailSent={emailSent}
                    isGeotabIdle={isIdle}
                    isGeotabLoading={sendingEmail}
                    isGeotabError={emailFailedToSend}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item direction="row" className={classes.pageButtons} spacing={2}>
              <Grid item xs={3} lg={1}>
                <PreviousButton
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.previousButton}
                  onClick={() => history.push(routes.enrollment.quote.path)}
                  aria-label={t("finish-application-page.previous-button-label")}
                ></PreviousButton>
              </Grid>
              <Grid item xs={9} sm={5} lg={3}>
                <NextButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!formik.isValid || isEditingApplication}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {isEditingApplication ? (
                    <CircularProgress
                      color="inherit"
                      size="2rem"
                      aria-label={t("common.circular-progress-aria-label")}
                    />
                  ) : (
                    t("finish-application-page.next-button")
                  )}
                </NextButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default FinishApplicationPage;
