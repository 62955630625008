// TODO: this should change to something generic
import { AuthenticationApi } from "@chq/enrollment-api";
import {
  AuthProviderState,
  OIDCProvider as OpenIDAuthenticationProvider,
  Token,
  useAuth as useOpenIDAuth,
} from "@developertown/oidc-provider";
import React, { ComponentType, createContext, FC, useCallback, useContext, useState } from "react";
import { useHistory } from "react-router";
import { useAsyncFn } from "react-use";
import { IFrameNavigator } from "./iframeNavigator.js";
import { useApi } from "./useApi";
import { useOIDCConfiguration } from "./useOIDCConfiguration";
const AccessTokenContext = createContext<Token | undefined>(undefined);
const BasenameContext = createContext<string | undefined>(undefined);

const DefaultLoading = () => <></>;

const AccessTokenProvider: React.FC<{ accessToken?: Token; LoadingComponent?: React.ComponentType }> = ({
  accessToken,
  LoadingComponent = DefaultLoading,
  children,
}) => {
  const { isAuthenticated, isLoading } = useOpenIDAuth();

  if (!isAuthenticated && isLoading) {
    return <LoadingComponent />;
  }

  if (isAuthenticated && !accessToken) {
    return <LoadingComponent />;
  }

  return <AccessTokenContext.Provider value={accessToken}>{children}</AccessTokenContext.Provider>;
};

export type AuthenticationProviderProps = {
  basename?: string;
  LoadingComponent?: React.ComponentType;
};

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  basename = window.location.origin,
  children,
  LoadingComponent,
}) => {
  const oidc = useOIDCConfiguration();
  const [accessToken, setAccessToken] = useState<Token | undefined>(undefined);

  const handleAccessTokenChange = useCallback(
    (token: Token) => {
      setAccessToken(token);
    },
    [setAccessToken],
  );

  return (
    <BasenameContext.Provider value={basename}>
      <OpenIDAuthenticationProvider
        authority={oidc.authority}
        client_id={oidc.clientId}
        scope={"openid profile api1"}
        filterProtocolClaims={false}
        response_type="code"
        loadUserInfo={true}
        redirect_uri={window.location.origin}
        silent_redirect_uri={`${basename}/silent.html`}
        post_logout_redirect_uri={window.location.origin}
        automaticSilentRenew
        monitorSession
        revokeAccessTokenOnSignout
        onAccessTokenChanged={handleAccessTokenChange}
      >
        <AccessTokenProvider accessToken={accessToken} LoadingComponent={LoadingComponent}>
          {children}
        </AccessTokenProvider>
      </OpenIDAuthenticationProvider>
    </BasenameContext.Provider>
  );
};

const useBasename = (): string => {
  const basename = useContext(BasenameContext);
  if (basename === undefined) {
    throw new Error("useBasename must be a child of BasenameContext.Provider");
  }
  return basename;
};

export const useAccessToken = (): Token | undefined => {
  const token = useContext(AccessTokenContext);
  return token;
};

export type AsyncState<T> =
  | {
      loading: boolean;
      error?: undefined;
      value?: undefined;
    }
  | {
      loading: true;
      error?: Error | undefined;
      value?: T;
    }
  | {
      loading: false;
      error: Error;
      value?: undefined;
    }
  | {
      loading: false;
      error?: undefined;
      value: T;
    };
type Auth = AuthProviderState & {
  loginWithEmail: [AsyncState<void>, (email: string, returnUrl?: string) => Promise<void>];
  loginWithEmailAndPassword: [
    AsyncState<void>,
    (email: string, password: string, rememberLogin: boolean, returnUrl?: string) => Promise<void>,
  ];
  loginWithMagicLink: [AsyncState<void>, (id: string, loginToken: string, returnUrl?: string) => Promise<void>];
};
const useAuth = (): Auth => {
  const api = useApi(AuthenticationApi);
  const auth = useOpenIDAuth();
  const history = useHistory();
  const basename = useBasename();

  const loginWithEmail = useAsyncFn(
    async (email: string, returnUrl?: string) => {
      const { data } = await api.apiV10AuthenticationUserCreateEmailPost({ userCreateRequest: { email: email } });
      const id = data?.id;
      const { data: tokenData } = await api.apiV10AuthenticationGenerateTokenSignInPost({
        generateTokenSignInRequest: { id },
      });
      const token = tokenData?.token;
      const { data: redirectUrl } = await api.apiV10AuthenticationTokenSignInPost({
        tokenSignInRequest: {
          id,
          token,
          returnUrl: `${basename}/tokensignin.html`,
        },
      });

      const navigator = new IFrameNavigator();
      const iframe = await navigator.prepare({});
      // Set Cookie on Identity Service to perform login
      // If the user if this redirect isn't successful we can assume a bad username/password
      await iframe.navigate({ url: redirectUrl });

      // Perform Authorization Code flow to obtain access token
      await auth.loginSilent();

      if (returnUrl) {
        history.push(returnUrl);
      }
    },
    [api, auth.loginSilent, history],
  );

  const loginWithEmailAndPassword = useAsyncFn(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (email: string, password: string, rememberLogin: boolean, returnUrl?: string, opts = {}) => {
      const { data: redirectUrl } = await api.apiV10AuthenticationPasswordSignInPost({
        passwordSignInRequest: {
          email,
          password,
          rememberLogin,
          returnUrl: `${basename}/tokensignin.html`,
        },
      });
      const navigator = new IFrameNavigator();
      const iframe = await navigator.prepare({});
      // Set Cookie on Identity Service to perform login
      // If the user if this redirect isn't successful we can assume a bad username/password
      await iframe.navigate({ url: redirectUrl, silentRequestTimeout: 1000 });

      // Perform Authorization Code flow to obtain access token
      await auth.loginSilent();

      if (returnUrl) {
        history.push(returnUrl);
      }
    },
    [api, auth.loginSilent, history],
  );

  const loginWithMagicLink = useAsyncFn(
    async (id: string, loginToken: string, returnUrl?: string) => {
      const { data: userStatus } = await api.apiV10AuthenticationUserStatusPost({ userStatusRequest: { userId: id } });
      if (!userStatus?.emailConfirmed) {
        const { data } = await api.apiV10AuthenticationGenerateTokenConfirmEmailPost({
          generateTokenConfirmEmailRequest: { userId: id },
        });
        const token = data?.token;
        await api.apiV10AuthenticationTokenConfirmEmailPost({
          tokenConfirmEmailRequest: { userId: id, token: token },
        });
        const { data: newToken } = await api.apiV10AuthenticationGenerateTokenSignInPost({
          generateTokenSignInRequest: { id: id },
        });
        const { data: redirectUrl } = await api.apiV10AuthenticationTokenSignInPost({
          tokenSignInRequest: {
            id,
            token: newToken?.token,
            returnUrl: `${basename}/tokensignin.html`,
          },
        });

        const navigator = new IFrameNavigator();
        const iframe = await navigator.prepare({});
        // Set Cookie on Identity Service to perform login
        // If the user if this redirect isn't successful we can assume a bad username/password
        await iframe.navigate({ url: redirectUrl });

        // Perform Authorization Code flow to obtain access token
        await auth.loginSilent();

        if (returnUrl) {
          history.push(returnUrl);
        }
      } else {
        const { data: redirectUrl } = await api.apiV10AuthenticationTokenSignInPost({
          tokenSignInRequest: {
            id,
            token: loginToken,
            returnUrl: `${basename}/tokensignin.html`,
          },
        });

        const navigator = new IFrameNavigator();
        const iframe = await navigator.prepare({});
        // Set Cookie on Identity Service to perform login
        // If the user if this redirect isn't successful we can assume a bad username/password
        await iframe.navigate({ url: redirectUrl });

        // Perform Authorization Code flow to obtain access token
        await auth.loginSilent();

        if (returnUrl) {
          // using location assign here because the login page redirects might cross react apps
          // eg. command => sfa enrollment nad sfa enrollment => command
          window.location.assign(returnUrl);
        }
      }
    },
    [api, auth.loginSilent, history],
  );

  return { ...auth, loginWithEmail, loginWithEmailAndPassword, loginWithMagicLink };
};

type WithAuthenticationRequiredOptions<P> = {
  onRedirecting?: (props?: P) => JSX.Element;
};
const defaultOnRedirecting = (): JSX.Element => <></>;
const withAuthenticationRequired = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions<P> = {},
): FC<P> => (props: P): JSX.Element => {
  const { isAuthenticated } = useAuth();
  const { onRedirecting = defaultOnRedirecting } = options;

  return isAuthenticated ? <Component {...props} /> : onRedirecting(props);
};

export { useAuth, withAuthenticationRequired };
