import React from "react";
import { useParams } from "react-router";
import MarketingLandingPageComponent from "../../../components/marketing-landing-page-component";

type CampaignParams = {
  campaign_id: string;
};

const MarketingLandingPage: React.FC = () => {
  const { campaign_id } = useParams<CampaignParams>();

  return <MarketingLandingPageComponent campaignId={campaign_id} />;
};

export default MarketingLandingPage;
