/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { DocumentType, DocumentTypeFromJSON, DocumentTypeFromJSONTyped, DocumentTypeToJSON } from "./";

/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   *
   * @type {number}
   * @memberof Document
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  storageUri?: string | null;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  publicUri?: string | null;
  /**
   *
   * @type {string}
   * @memberof Document
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof Document
   */
  length?: number;
  /**
   *
   * @type {DocumentType}
   * @memberof Document
   */
  documentType?: DocumentType;
}

export function DocumentFromJSON(json: any): Document {
  return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    storageUri: !exists(json, "storageUri") ? undefined : json["storageUri"],
    publicUri: !exists(json, "publicUri") ? undefined : json["publicUri"],
    name: !exists(json, "name") ? undefined : json["name"],
    length: !exists(json, "length") ? undefined : json["length"],
    documentType: !exists(json, "documentType") ? undefined : DocumentTypeFromJSON(json["documentType"]),
  };
}

export function DocumentToJSON(value?: Document | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    storageUri: value.storageUri,
    publicUri: value.publicUri,
    name: value.name,
    length: value.length,
    documentType: DocumentTypeToJSON(value.documentType),
  };
}
