/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Helmet, HelmetTags } from "react-helmet";

const LuckyOrange: React.FC = ({ children }) => {
  const luckyOrangeEnabled = Boolean(window.env.REACT_APP_LUCKY_ORANGE_SFA_SITE_ID);
  const scriptUrl = `https://tools.luckyorange.com/core/lo.js?site-id=${window.env.REACT_APP_LUCKY_ORANGE_SFA_SITE_ID}`;
  const [, setScriptLoaded] = useState(() => (luckyOrangeEnabled ? Boolean(window.LO) : true));
  const handleChangeClientState = (newState: any, addedTags: HelmetTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(({ src }: any) => src === scriptUrl);
      if (foundScript) {
        foundScript.addEventListener(
          "load",
          () => {
            window.LOQ = window.LOQ || [];
            window?.LOQ.push(["ready", () => setScriptLoaded(true)]);
          },
          { once: true },
        );
      }
    }
  };
  return (
    <>
      <Helmet onChangeClientState={handleChangeClientState}>
        {luckyOrangeEnabled ? (
          <script
            src={scriptUrl}
            type="text/javascript"
            onError={() => {
              // eslint-disable-next-line no-console
              console.error("failed to load lucky orange");
              setScriptLoaded(true);
            }}
          />
        ) : null}
      </Helmet>
      {children}
    </>
  );
};

export default LuckyOrange;
