import { EditableDeletableCard, NumericInput, SaveButton, Select, TextInput } from "@chq/components";
import { State } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useStates } from "../data/useStates";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: "1rem",
  },
  title: {
    paddingBottom: "1rem",
  },
  addButton: {
    padding: "1.125rem 0rem",
    fontWeight: 400,
    background: theme.palette.common.white,
  },
  container: {
    background: theme.palette.grey[600],
    padding: "1.125rem .625rem 1rem .625rem",
  },
}));

export enum Fields {
  businessName = "business-name",
  phoneNumber = "phone-number",
  ein = "ein",
  dotNumber = "dot-number",
  mcNumber = "mc-number",
  addressLineOne = "address-line-one",
  addressLineTwo = "address-line-two",
  city = "city",
  state = "state",
  zip = "zip",
}

export type FactoringBusinessDetailsProps = {
  [Fields.businessName]: string | null;
  [Fields.phoneNumber]: string;
  [Fields.ein]: string | null;
  [Fields.dotNumber]: string | null;
  [Fields.mcNumber]?: string | null;
  [Fields.addressLineOne]: string | null;
  [Fields.addressLineTwo]?: string | null;
  [Fields.city]: string | null;
  [Fields.state]?: State | "";
  [Fields.zip]: string | null;
};

export type Props = {
  businessName?: string | null;
  phoneNumber?: string;
  ein?: string | null;
  dotNumber?: string | null;
  mcNumber?: string | null;
  addressLineOne?: string | null;
  addressLineTwo?: string | null;
  city?: string | null;
  state?: State | "";
  zip?: string | null;
  isLoading?: boolean;
  onSubmit?: FormikConfig<FactoringBusinessDetailsProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();
  return yup.object({
    [Fields.businessName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`factoring.business-details.${Fields.businessName}.label`) })),
    [Fields.phoneNumber]: yup
      .string()
      .test(
        "length",
        t(`factoring.business-details.${Fields.phoneNumber}.phone-length`),
        (val) => val !== undefined && val.toString().length === 10,
      )
      .required(t(`errors.required`, { field: t(`factoring.business-details.${Fields.phoneNumber}.label`) })),
    [Fields.ein]: yup
      .string()
      .required(t(`errors.required`, { field: t(`factoring.business-details.${Fields.ein}.label`) }))
      .test("length", t("company-info-form.ein.length"), (val) => val !== undefined && val.toString().length === 9),
    [Fields.dotNumber]: yup.string().test(
      "length",
      t("errors.between-digits", {
        field: t(`factoring.business-details.${Fields.dotNumber}.label`),
        min: "5",
        max: "8",
      }),
      (val) => (val ? val.toString().length >= 5 && val.toString().length <= 8 : true),
    ),
    [Fields.mcNumber]: yup.string().test(
      "length",
      t("errors.between-digits", {
        field: t(`factoring.business-details.${Fields.mcNumber}.label`),
        min: "6",
        max: "8",
      }),
      (val) => (val ? val.toString().length >= 6 && val.toString().length <= 8 : true),
    ),
    [Fields.addressLineOne]: yup
      .string()
      .required(t(`errors.required`, { field: t(`factoring.business-details.${Fields.addressLineOne}.label`) })),
    [Fields.addressLineTwo]: yup.string(),
    [Fields.city]: yup
      .string()
      .required(t(`errors.required`, { field: t(`factoring.business-details.${Fields.city}.label`) })),
    [Fields.state]: yup
      .string()
      .required(t(`errors.required`, { field: t(`factoring.business-details.${Fields.state}.label`) })),
    [Fields.zip]: yup
      .string()
      .required(t(`errors.required`, { field: t(`factoring.business-details.${Fields.zip}.label`) })),
  });
};

const FactoringBusinessDetails: React.FC<Props> = ({
  businessName = "",
  phoneNumber = "",
  ein = "",
  dotNumber = "",
  mcNumber = "",
  addressLineOne = "",
  addressLineTwo = "",
  city = "",
  state = "",
  zip = "",
  isLoading,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const validationSchema = useValidationSchema();
  const classes = useStyles();
  const states = useStates();

  const formik = useFormik<FactoringBusinessDetailsProps>({
    initialValues: {
      [Fields.businessName]: businessName,
      [Fields.phoneNumber]: phoneNumber,
      [Fields.ein]: ein,
      [Fields.dotNumber]: dotNumber,
      [Fields.mcNumber]: mcNumber,
      [Fields.addressLineOne]: addressLineOne,
      [Fields.addressLineTwo]: addressLineTwo,
      [Fields.city]: city,
      [Fields.state]: state,
      [Fields.zip]: zip,
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
    validateOnMount: true,
    enableReinitialize: true,
  });
  return (
    <EditableDeletableCard
      variant="default"
      title={t("factoring.business-details.title")}
      titleVariant="h2"
      titleComponent="h3"
      className={classes.container}
    >
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextInput
              required
              fullWidth
              id={Fields.businessName}
              name={Fields.businessName}
              label={t("factoring.business-details.business-name.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.businessName]}
              error={formik.touched[Fields.businessName] && Boolean(formik.errors[Fields.businessName])}
              helperText={formik.touched[Fields.businessName] && formik.errors[Fields.businessName]}
            />
          </Grid>
          <Grid item xs={12}>
            <NumericInput
              required
              fullWidth
              isNumericString
              format="(###) ###-####"
              id={Fields.phoneNumber}
              name={Fields.phoneNumber}
              label={t("factoring.business-details.phone-number.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.phoneNumber]}
              error={formik.touched[Fields.phoneNumber] && Boolean(formik.errors[Fields.phoneNumber])}
              helperText={formik.touched[Fields.phoneNumber] && formik.errors[Fields.phoneNumber]}
            />
          </Grid>
          <Grid item xs={12}>
            <NumericInput
              required
              fullWidth
              isNumericString
              format="##-#######"
              id={Fields.ein}
              name={Fields.ein}
              label={t("factoring.business-details.ein.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.ein]}
              error={formik.touched[Fields.ein] && Boolean(formik.errors[Fields.ein])}
              helperText={formik.touched[Fields.ein] && formik.errors[Fields.ein]}
            />
          </Grid>
          <Grid item xs={6}>
            <NumericInput
              fullWidth
              isNumericString
              id={Fields.dotNumber}
              name={Fields.dotNumber}
              format="########"
              label={t("factoring.business-details.dot-number.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.dotNumber]}
              error={formik.touched[Fields.dotNumber] && Boolean(formik.errors[Fields.dotNumber])}
              helperText={formik.touched[Fields.dotNumber] && formik.errors[Fields.dotNumber]}
            />
          </Grid>
          <Grid item xs={6}>
            <NumericInput
              fullWidth
              isNumericString
              id={Fields.mcNumber}
              name={Fields.mcNumber}
              format="########"
              label={t("factoring.business-details.mc-number.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.mcNumber]}
              error={formik.touched[Fields.mcNumber] && Boolean(formik.errors[Fields.mcNumber])}
              helperText={formik.touched[Fields.mcNumber] && formik.errors[Fields.mcNumber]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              required
              fullWidth
              id={Fields.addressLineOne}
              name={Fields.addressLineOne}
              label={t("factoring.business-details.address-line-one.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.addressLineOne]}
              error={formik.touched[Fields.addressLineOne] && Boolean(formik.errors[Fields.addressLineOne])}
              helperText={formik.touched[Fields.addressLineOne] && formik.errors[Fields.addressLineOne]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              id={Fields.addressLineTwo}
              name={Fields.addressLineTwo}
              label={t("factoring.business-details.address-line-two.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.addressLineTwo]}
              error={formik.touched[Fields.addressLineTwo] && Boolean(formik.errors[Fields.addressLineTwo])}
              helperText={formik.touched[Fields.addressLineTwo] && formik.errors[Fields.addressLineTwo]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              required
              fullWidth
              id={Fields.city}
              name={Fields.city}
              label={t("factoring.business-details.city.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.city]}
              error={formik.touched[Fields.city] && Boolean(formik.errors[Fields.city])}
              helperText={formik.touched[Fields.city] && formik.errors[Fields.city]}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              id={Fields.state}
              name={Fields.state}
              label={t(`factoring.business-details.state.label`)}
              items={states.map((state) => ({
                name: state.abv,
                value: state.abv,
              }))}
              value={formik.values[Fields.state]}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.state] && Boolean(formik.errors[Fields.state])}
              helperText={formik.touched[Fields.state] && formik.errors[Fields.state]}
            />
          </Grid>
          <Grid item xs={6}>
            <NumericInput
              required
              fullWidth
              isNumericString
              format="#####"
              id={Fields.zip}
              name={Fields.zip}
              label={t("factoring.business-details.zip.label")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.zip]}
              error={formik.touched[Fields.zip] && Boolean(formik.errors[Fields.zip])}
              helperText={formik.touched[Fields.zip] && formik.errors[Fields.zip]}
            />
          </Grid>
          <Grid item xs={12}>
            <SaveButton
              label={t("factoring.business-details.add-button")}
              variant="outlined"
              fullWidth
              isLoading={isLoading}
              type="submit"
              disabled={!formik.isValid}
              formikValid={formik.isValid}
            />
          </Grid>
        </Grid>
      </form>
    </EditableDeletableCard>
  );
};

export default FactoringBusinessDetails;
