import { EditableDeletableCard } from "@chq/components";
import { EquipmentType } from "@chq/enrollment-api";
import { Divider } from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import { FieldArray, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import ReviewPowerUnitForm, { Fields, ReviewTrailersPowerUnitsFormProps } from "./review-power-unit-trailer-form";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1.125rem 0.5rem 0rem",
    "& .MuiGrid-root > .MuiGrid-root:last-child > .MuiGrid-root > div": {
      paddingBottom: "0px",
      borderBottom: "none",
    },
  },
  powerUnitContainer: {
    padding: "0.75rem 0rem 0.625rem 0rem",
  },
  vehicleNumber: {
    paddingBottom: "0.25rem",
  },
  fullWidth: {
    width: "100%",
    paddingBottom: "0.625rem",
    borderBottom: `2px solid ${fade(theme.palette.grey[400], 0.4)}`,
  },
  checkboxContainer: {
    padding: "0rem 0.625rem 0rem 0.5rem",
    backgroundColor: theme.palette.grey[200],
    "& .MuiGrid-root > .MuiFormControlLabel-root": {
      marginLeft: "0px",
    },
  },
  checkbox: {
    color: theme.palette.grey[500],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    "&.Mui-checked:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  firstCheckbox: {
    marginBottom: "0.125rem",
  },
  tooltipContainer: {
    height: "1.5rem",
  },
  fullWidthCheckbox: {
    width: "100%",
    paddingBottom: "0.625rem",
  },
  secondaryContainer: {
    paddingTop: "1rem",
  },
  extraFieldContainer: {
    padding: "0.625rem 0rem",
  },
  entityLabel: {
    paddingLeft: "0.625rem",
  },
  divider: {
    marginTop: "0.75rem",
    height: "2px",
  },
}));

const equipmentTypeArrayTypeMap = {
  [EquipmentType.PowerUnit]: "powerUnits",
  [EquipmentType.Trailer]: "trailers",
};

export type Props = {
  equipmentType: EquipmentType;
};

export { Fields };

const ReviewPowerUnitsTrailersForm: React.FC<Props> = ({ equipmentType }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<ReviewTrailersPowerUnitsFormProps>();
  const arrayType = equipmentTypeArrayTypeMap[equipmentType] as keyof ReviewTrailersPowerUnitsFormProps;

  return (
    <EditableDeletableCard
      title={t(`review-application-page.review-power-units-trailers-form.${arrayType}`, {
        number: formik.values[arrayType]!.length,
      })}
      titleVariant="h2"
      titleComponent="h4"
      variant="default"
      border={false}
      className={classes.container}
    >
      <FieldArray name={arrayType}>
        {(arrayHelpers) => {
          return (
            <>
              {(formik.values[arrayType] || []).map((unit, index) => {
                return (
                  <React.Fragment key={index}>
                    <ReviewPowerUnitForm key={index} index={index} arrayType={arrayType} />
                    {formik.values[arrayType] !== undefined && formik.values[arrayType]!.length - 1 !== index && (
                      <Divider key={`divider-${index}`} className={classes.divider} />
                    )}
                  </React.Fragment>
                );
              })}
            </>
          );
        }}
      </FieldArray>
    </EditableDeletableCard>
  );
};

export default ReviewPowerUnitsTrailersForm;
