import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const HamburgerIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z" fill="#31353A" />
    </SvgIcon>
  );
};

export default HamburgerIcon;
