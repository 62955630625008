import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const PencilIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M17.226 9.02551C17.46 8.79151 17.46 8.40151 17.226 8.17951L15.822 6.77551C15.6 6.54151 15.21 6.54151 14.976 6.77551L13.872 7.87351L16.122 10.1235L17.226 9.02551ZM6.60001 15.1515V17.4015H8.85001L15.486 10.7595L13.236 8.50951L6.60001 15.1515Z" />
    </SvgIcon>
  );
};

export default PencilIcon;
