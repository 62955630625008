/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BooleanApiResponse,
  BooleanApiResponseFromJSON,
  BooleanApiResponseToJSON,
  OwnerBankAccountInfoApiResponse,
  OwnerBankAccountInfoApiResponseFromJSON,
  OwnerBankAccountInfoApiResponseToJSON,
  PolicyApiResponse,
  PolicyApiResponseFromJSON,
  PolicyApiResponseToJSON,
} from "../models";

export interface PortalApiV10PolicyGetRequest {
  identityId?: string;
}

export interface PortalApiV10PolicyGetOwnerBankAccountInfoGetRequest {
  identityId?: string;
}

export interface PortalApiV10PolicySendCancelPolicyEmailPostRequest {
  policyId?: number;
  reason?: string;
}

export interface PortalApiV10PolicySendGeotabRequestEmailPostRequest {
  businessId?: number;
}

export interface PortalApiV10PolicyUpdateStripeAccountGetRequest {
  identityId?: string;
  publicToken?: string;
  accountId?: string;
}

/**
 *
 */
export class PolicyApi extends runtime.BaseAPI {
  /**
   * Creates a new FactoringApplication
   */
  async portalApiV10PolicyGetRaw(
    requestParameters: PortalApiV10PolicyGetRequest,
  ): Promise<runtime.ApiResponse<PolicyApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.identityId !== undefined) {
      queryParameters["identityId"] = requestParameters.identityId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/portal/api/v1.0/Policy`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => PolicyApiResponseFromJSON(jsonValue));
  }

  /**
   * Creates a new FactoringApplication
   */
  async portalApiV10PolicyGet(requestParameters: PortalApiV10PolicyGetRequest): Promise<PolicyApiResponse> {
    const response = await this.portalApiV10PolicyGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Returns bank account information for user
   */
  async portalApiV10PolicyGetOwnerBankAccountInfoGetRaw(
    requestParameters: PortalApiV10PolicyGetOwnerBankAccountInfoGetRequest,
  ): Promise<runtime.ApiResponse<OwnerBankAccountInfoApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.identityId !== undefined) {
      queryParameters["identityId"] = requestParameters.identityId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/portal/api/v1.0/Policy/getOwnerBankAccountInfo`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OwnerBankAccountInfoApiResponseFromJSON(jsonValue));
  }

  /**
   * Returns bank account information for user
   */
  async portalApiV10PolicyGetOwnerBankAccountInfoGet(
    requestParameters: PortalApiV10PolicyGetOwnerBankAccountInfoGetRequest,
  ): Promise<OwnerBankAccountInfoApiResponse> {
    const response = await this.portalApiV10PolicyGetOwnerBankAccountInfoGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async portalApiV10PolicySendCancelPolicyEmailPostRaw(
    requestParameters: PortalApiV10PolicySendCancelPolicyEmailPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.policyId !== undefined) {
      queryParameters["policyId"] = requestParameters.policyId;
    }

    if (requestParameters.reason !== undefined) {
      queryParameters["reason"] = requestParameters.reason;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/portal/api/v1.0/Policy/SendCancelPolicyEmail`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async portalApiV10PolicySendCancelPolicyEmailPost(
    requestParameters: PortalApiV10PolicySendCancelPolicyEmailPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.portalApiV10PolicySendCancelPolicyEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Sends email to Client Success to inform them a customer is interested in GeoTab
   */
  async portalApiV10PolicySendGeotabRequestEmailPostRaw(
    requestParameters: PortalApiV10PolicySendGeotabRequestEmailPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.businessId !== undefined) {
      queryParameters["businessId"] = requestParameters.businessId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/portal/api/v1.0/Policy/SendGeotabRequestEmail`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   * Sends email to Client Success to inform them a customer is interested in GeoTab
   */
  async portalApiV10PolicySendGeotabRequestEmailPost(
    requestParameters: PortalApiV10PolicySendGeotabRequestEmailPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.portalApiV10PolicySendGeotabRequestEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Through the Plaid Link flow the connected account id is returned and when passed to this endpoint will update the Stripe Customer and Bank Account.
   */
  async portalApiV10PolicyUpdateStripeAccountGetRaw(
    requestParameters: PortalApiV10PolicyUpdateStripeAccountGetRequest,
  ): Promise<runtime.ApiResponse<OwnerBankAccountInfoApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.identityId !== undefined) {
      queryParameters["identityId"] = requestParameters.identityId;
    }

    if (requestParameters.publicToken !== undefined) {
      queryParameters["publicToken"] = requestParameters.publicToken;
    }

    if (requestParameters.accountId !== undefined) {
      queryParameters["accountId"] = requestParameters.accountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/portal/api/v1.0/Policy/updateStripeAccount`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OwnerBankAccountInfoApiResponseFromJSON(jsonValue));
  }

  /**
   * Through the Plaid Link flow the connected account id is returned and when passed to this endpoint will update the Stripe Customer and Bank Account.
   */
  async portalApiV10PolicyUpdateStripeAccountGet(
    requestParameters: PortalApiV10PolicyUpdateStripeAccountGetRequest,
  ): Promise<OwnerBankAccountInfoApiResponse> {
    const response = await this.portalApiV10PolicyUpdateStripeAccountGetRaw(requestParameters);
    return await response.value();
  }
}
