import {
  FactoringApiV10FactoringDocumentAddDocumentPostRequest,
  FactoringApplicationApi,
  FactoringDocumentApi,
} from "@chq/factoring-api";
import { useMutation, UseMutationResult } from "react-query";
import { useCachedFactoringApplication } from ".";
import { useFactoringApi } from "../useFactoringApi";

export const useAddFactoringDocument = (): UseMutationResult<
  void,
  Error,
  Omit<FactoringApiV10FactoringDocumentAddDocumentPostRequest, "applicationId">
> => {
  const documentApi = useFactoringApi(FactoringDocumentApi);
  const applicationApi = useFactoringApi(FactoringApplicationApi);
  const { application, updateApplication } = useCachedFactoringApplication();
  const mutation = useMutation<void, Error, FactoringApiV10FactoringDocumentAddDocumentPostRequest>(
    async (request: Omit<FactoringApiV10FactoringDocumentAddDocumentPostRequest, "applicationId">) => {
      await documentApi.factoringApiV10FactoringDocumentAddDocumentPost({ applicationId: application?.id, ...request });
      const response = await applicationApi.factoringApiV10FactoringApplicationGetApplicationForCurrentUserGet();
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};
