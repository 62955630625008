import { CardField, EditableDeletableCard, EditableDeletableCardProps, YesNoValues } from "@chq/components";
import { CompAndCollisionCoverage, RegisteredTo } from "@chq/enrollment-api";
import { Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export type Props = {
  vehicleNumber: string;
  vin: string;
  make: string;
  model: string;
  year: string;
  registrationZip: string;
  destination: string;
  ooIC: string;
  whereIsUnitRegistered: string;
  coverageOption: string;
  statedValue: number;
  gapCoverage: string;
  onEdit?: EditableDeletableCardProps["onEdit"];
};

const PowerUnitsCard: React.FC<Props> = ({
  vehicleNumber,
  vin,
  make,
  model,
  year,
  registrationZip,
  destination,
  ooIC,
  whereIsUnitRegistered,
  coverageOption,
  statedValue,
  gapCoverage,
  onEdit,
}) => {
  const [t] = useTranslation();

  return (
    <EditableDeletableCard
      title={vehicleNumber}
      onEdit={onEdit}
      variant="edit"
      IconButtonProps={{ "aria-label": t("power-units-card.edit-button") }}
    >
      <Grid container>
        <CardField id={"vin"} label={t("power-units-card.vin")} values={[{ value: vin.toUpperCase() }]} />
        <CardField id={"make"} label={t("power-units-card.make")} values={[{ value: make }]} />
        {model && <CardField id={"model"} label={t("power-units-card.model")} values={[{ value: model }]} />}
        <CardField id={"year"} label={t("power-units-card.year")} values={[{ value: year }]} />
        <CardField
          id={"registrationZip"}
          label={t("power-units-card.registration-zip")}
          values={[{ value: registrationZip }]}
        />
        <CardField
          id={"destination"}
          label={t("power-units-card.destination")}
          values={[{ value: t(`destination-type.${destination.replace(".", "")}`) }]}
        />
        <CardField
          id={"ooIC"}
          label={t("power-units-card.oo-or-ic")}
          values={[{ value: ooIC === YesNoValues.yes ? t("common.yes") : t("common.no") }]}
        />
        {ooIC === YesNoValues.no && (
          <>
            <CardField
              id={"whereIsUnitRegistered"}
              label={t("power-units-card.where-unit-registered")}
              values={[{ value: t(`registered-to-type.${whereIsUnitRegistered}`) }]}
            />
            {whereIsUnitRegistered !== RegisteredTo.Other && (
              <>
                <CardField
                  id={"coverageOption"}
                  label={t("power-units-card.coverage-option")}
                  values={[{ value: t(`power-units-card.coverage-option-selection.${coverageOption}`) }]}
                />
                {coverageOption && coverageOption !== CompAndCollisionCoverage.NoCoverage && (
                  <>
                    <CardField
                      id={"coverageOption"}
                      label={t("power-units-card.stated-value")}
                      values={[{ value: t("common.currencyWithout", { money: statedValue }) }]}
                    />
                    <CardField
                      id={"gapCoverage"}
                      label={t("power-units-card.loan-lease-gap-coverage")}
                      values={[{ value: gapCoverage === YesNoValues.yes ? t("common.yes") : t("common.no") }]}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </EditableDeletableCard>
  );
};

export default PowerUnitsCard;
