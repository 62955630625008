import { CardField, EditableDeletableCard, FailIcon, SuccessIcon } from "@chq/components";
import { ELDProvider } from "@chq/policy-management-api";
import { Button, CircularProgress, Grid, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useGetPolicy } from "../../data/policy-management/useGetPolicy";
import { useSendGeotabRequest } from "../../data/policy-management/useSendGeotabRequest";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingTop: "1rem",
  },
  icons: {
    height: "2rem",
    width: "2rem",
  },
  container: {
    paddingTop: "0.875rem",
    borderBottom: `solid 1px ${theme.palette.secondary.light}`,
  },
  fleetNumber: {
    paddingBottom: ".625rem",
  },
  needHelp: {
    paddingTop: "0.625rem",
    textAlign: "center",
    textTransform: "uppercase",
  },
  contact: {
    textAlign: "center",
    width: "100%",
  },
  geoTabCard: {
    background: theme.palette.secondary.light,
    paddingTop: ".625rem",
  },
  eldContainer: {
    paddingTop: ".5rem",
    paddingBottom: "1rem",
  },
  addEld: {
    fontWeight: 700,
    textAlign: "center",
  },
  geoTabReseller: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  geoTabOrdered: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: "0.25rem",
    margin: "0.5rem auto",
    height: "6rem",
  },
  geoTabError: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: "0.25rem",
    margin: "0.5rem auto",
    height: "6rem",
  },
  failureText: {
    color: theme.palette.error.main,
    fontWeight: 700,
    marginBottom: "0.25rem",
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: "2.5rem",
  },
  successText: {
    color: theme.palette.success.dark,
    fontWeight: 700,
    marginBottom: "0.25rem",
  },
  buttonItem: {
    textAlign: "center",
  },
  button: {
    paddingTop: "1rem",
    paddingBottom: "1.125rem",
    background: theme.palette.success.main,
    width: "95%",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

type Props = {
  eldProvider?: ELDProvider;
  eldAdmin?: string;
  fleetNumber?: string;
};

const EldCard: React.FC<Props> = ({ eldProvider, eldAdmin, fleetNumber }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { data: policyData } = useGetPolicy();
  const { mutate: sendEmail, isSuccess: emailSent, isError, isLoading, isIdle } = useSendGeotabRequest();

  return (
    <EditableDeletableCard
      variant="default"
      title={t("eld-card.title")}
      titleVariant={"h2"}
      titleComponent={"h2"}
      border={false}
      elevation={1}
      className={classes.card}
    >
      <Grid container className={classes.container}>
        <CardField id="eldProvider" label={t("eld-card.eld-provider")} values={[{ value: eldProvider }]} />
        <CardField id="eldAdmin" label={t("eld-card.eld-admin")} values={[{ value: eldAdmin ? eldAdmin : "" }]} />
        <CardField
          id="fleetNumber"
          label={t("eld-card.fleet-number")}
          values={[{ value: fleetNumber ? fleetNumber : "" }]}
          className={classes.fleetNumber}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h3" className={classes.needHelp}>
            {t("policy-management-page.eld-card.need-help")}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.contact}>
          <Typography component="p">
            <Trans i18nKey={"policy-management-page.eld-card.contact"}>
              <Typography variant="body1" component="span">
                Call
              </Typography>
              <Link href={`mailto:support@speedgauge.net`} variant="body1">
                614-245-0773
              </Link>
              <Typography variant="body1" component="span">
                or email
              </Typography>
              <Link href={"tel:614-245-0773"} variant="body1">
                support@speedgauge.net
              </Link>
            </Trans>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.geoTabCard}>
            <Grid container spacing={2} className={classes.eldContainer}>
              <Grid item xs={12}>
                <Typography variant="h3" component="h3" className={classes.addEld}>
                  {t("policy-management-page.eld-card.add-eld")}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.geoTabReseller}>
                <Typography variant="h4" component="h4">
                  {t("policy-management-page.eld-card.geotab-reseller")}
                </Typography>
              </Grid>
              {emailSent && (
                <Grid
                  container
                  item
                  xs={10}
                  className={classes.geoTabOrdered}
                  direction="column"
                  alignContent="space-around"
                  justify="center"
                >
                  <Grid item xs={2}>
                    <SuccessIcon className={classes.successIcon} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h3" component="h3" className={classes.successText}>
                      {t("policy-management-page.eld-card.thank-you")}
                    </Typography>
                    <Typography variant="h3" component="h3">
                      {t("policy-management-page.eld-card.ordered-geotab")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {isIdle && (
                <Grid item xs={12} className={classes.buttonItem}>
                  <Button
                    variant="contained"
                    onClick={() => sendEmail({ businessId: policyData?.business?.id })}
                    className={classes.button}
                  >
                    {t("policy-management-page.eld-card.order-geotab-button")}
                  </Button>
                </Grid>
              )}
              {isLoading && (
                <Grid item xs={12} className={classes.buttonItem}>
                  <CircularProgress
                    color="primary"
                    size="2.5rem"
                    aria-label={t("common.circular-progress-aria-label")}
                  />
                </Grid>
              )}
              {isError && (
                <Grid
                  container
                  item
                  xs={10}
                  className={classes.geoTabError}
                  direction="column"
                  alignContent="space-around"
                  justify="center"
                >
                  <Grid item xs={2}>
                    <FailIcon color="error" className={classes.icons} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h3" component="h3" className={classes.failureText}>
                      {t("policy-management-page.eld-card.email-error-title")}
                    </Typography>
                    <Typography component="p">
                      <Trans i18nKey={"policy-management-page.eld-card.email-error-message"}>
                        <Typography component="span" variant="body1"></Typography>
                        <Link href={"tel:866-621-4145"}></Link>
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default EldCard;
