import { useAuth } from "@chq/authentication";
import { Header, LoadingOverlay } from "@chq/components";
import qs from "qs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import { useGetApplication } from "../../../data/enrollment";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const fixToken = (token: string) => {
  const fixedToken = token.split(" ").join("+");
  return fixedToken;
};

const EmailLinkPage: React.FC = () => {
  const [t] = useTranslation();
  const { search } = useLocation();
  const params = qs.parse(search.slice(1));
  if (params.token) {
    params.token = fixToken(params.token as string);
  }
  const {
    isAuthenticated,
    loginWithMagicLink: [{ loading: isAuthenticating }, loginWithMagicLink],
  } = useAuth();

  const { data: application } = useGetApplication(undefined, { enabled: isAuthenticated });

  useEffect(() => {
    if (
      (!isAuthenticating && search.includes("?action=resumeApp")) ||
      (!isAuthenticating && search.includes("?action=verifyEmail"))
    ) {
      loginWithMagicLink(params.user as string, params.token as string, routes.emailLink.path);
    }
  }, [isAuthenticating, loginWithMagicLink, params.token, params.user, search]);

  if (params.action === "resetPassword") {
    return (
      <Redirect
        push
        to={{
          pathname: routes.resetPassword.path,
          search: qs.stringify({ token: params.token, userId: params.user }),
        }}
      />
    );
  }

  if (application) {
    if (
      application?.insuranceProgram?.active === false &&
      (application?.applicationState === RouteNames.quoteOverview ||
        application?.applicationState === RouteNames.saveQuote ||
        application?.applicationState === RouteNames.verify ||
        application?.applicationState === RouteNames.success ||
        application?.applicationState === RouteNames.successAcount ||
        application?.applicationState === RouteNames.finishApplication ||
        application?.applicationState === RouteNames.payment ||
        application?.applicationState === RouteNames.review ||
        application?.applicationState === RouteNames.quotePending)
    ) {
      return <Redirect to={routes.enrollment.quote.inactive.path} />;
    }
    return <Redirect push to={application?.applicationState || routes.enrollment.basicInfo.path} />;
  }

  return (
    <>
      <Header
        chqTitleAccess={t("common.chq-logo")}
        chqAriaLabel={t("common.chq-logo")}
        headerBarTitleAccess={t("common.middle-bar")}
        headerBarAriaLabel={t("common.middle-bar")}
        elevation={1}
      />
      <LoadingOverlay title={t("common.loading")} />
    </>
  );
};

export default EmailLinkPage;
