import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const ChevronDownIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.24602 9.60001L12 12.354L14.754 9.60001L15.6 10.452L12 14.052L8.40002 10.452L9.24602 9.60001Z" />
    </SvgIcon>
  );
};

export default ChevronDownIcon;
