import { StepIndicator } from "@chq/components";
import { makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    padding: "0rem",
  },
}));

export enum PolicyManagementSteps {
  unknown = -1,
  coverages = 0,
  equipment = 1,
  review = 2,
}

export const usePolicyManagementSteps = () => {
  const [t] = useTranslation();
  return [PolicyManagementSteps.coverages, PolicyManagementSteps.equipment, PolicyManagementSteps.review].map((step) =>
    t(`policy-management-page.steps.${step}`),
  );
};

export const useCurrentPolicyManagementStep = () => {
  const location = useLocation();
  const routeMap = {
    [routes.policyManagement.coverages.edit.path]: PolicyManagementSteps.coverages,
    [routes.policyManagement.equipment.edit.path]: PolicyManagementSteps.equipment,
    [routes.policyManagement.reviewEquipmentAndCoverages.path]: PolicyManagementSteps.review,
  };
  return routeMap[location.pathname.toLowerCase()] >= 0
    ? routeMap[location.pathname.toLowerCase()]
    : PolicyManagementSteps.unknown;
};

export const PolicyManagementStepper = () => {
  const classes = useStyles();
  const steps = usePolicyManagementSteps();
  const currentStep = useCurrentPolicyManagementStep();
  if (currentStep === PolicyManagementSteps.unknown) {
    return null;
  } else return <StepIndicator steps={steps} activeStep={currentStep} className={classes.stepper} />;
};
