import { ApiV10ApplicationResumeApplicationPostRequest, ApplicationApi, BooleanApiResponse } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useSendResumeApplicationEmail = (): UseMutationResult<
  BooleanApiResponse,
  Error | unknown,
  ApiV10ApplicationResumeApplicationPostRequest
> => {
  const applicationApi = useApi(ApplicationApi);
  const mutation = useMutation(async (request: ApiV10ApplicationResumeApplicationPostRequest) => {
    const response = await applicationApi.apiV10ApplicationResumeApplicationPost(request);

    return response;
  });
  return mutation;
};
