import { NextButton } from "@chq/components";
import { Button, Dialog, Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import ContinueQuoteEmail, {
  Fields as ContinueEmailFields,
  useFormikConfig as useContinueEmailFormikConfig,
} from "../../../components/continue-quote-email";
import StartNewQuote from "../../../components/start-new-quote";
import WelcomeBackQuote from "../../../components/welcome-back-quote";
import { UserLoginType, useUserLoginType } from "../../../data/authentication/useUserLoginType";
import { useSendResumeApplicationEmail } from "../../../data/enrollment";
import truckBackgroundDesktop from "../../../images/truck-background-desktop.png";
import truckBackground from "../../../images/truck-background.png";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover",
      backgroundImage: `url(${truckBackground})`,
    },
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      backgroundPosition: "right center",
      backgroundImage: `url(${truckBackgroundDesktop})`,
    },
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  textContainer: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "3rem",
      marginTop: "1rem",
      paddingBottom: "4rem",
      width: "30rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "3rem",
      marginTop: "1rem",
      paddingBottom: "4rem",
    },
  },
  text: {
    color: theme.palette.common.white,
  },
  description: {
    color: theme.palette.common.white,
    padding: ".625rem 1.5rem 2rem 1.5rem",
  },
  buttonContainer: {
    background: theme.palette.secondary.dark,
    justifyContent: "center",
    paddingBottom: "2rem",
  },
  button: {
    padding: "0rem 1.5rem 0rem 1.5rem",
    marginTop: "-1.5rem",
  },
  startedQuoteSection: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignContent: "center",
    },
    marginTop: "2rem",
    justifyContent: "center",
    alignItems: "center",
  },
  alreadyStartedQuote: {
    color: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1rem",
    },
    [theme.breakpoints.up("xs")]: {
      paddingRight: "1rem",
    },
  },
  continueQuoteButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.dark,
    fontSize: "12px",
    padding: "0.5rem 2.25rem 0.5rem 2.25rem",
  },
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },
      [theme.breakpoints.up("xs")]: {
        width: "50%",
      },
    },
    "& .MuiPaper-rounded": {
      borderRadius: "4px 4px 0px 0px",
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "flex-end",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
      overflowX: "hidden",
      overflowY: "hidden",
      [theme.breakpoints.up("sm")]: {
        alignSelf: "center",
      },
    },
  },
  continueQuoteClose: {
    width: "1rem",
    height: "1rem",
    margin: "1rem",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  modalPaper: {
    backgroundColor: "rgba(0,0,0,0.65)",
    padding: "2rem 1em 2rem 1em",
    [theme.breakpoints.up("xs")]: {
      width: "93%",
      height: "100%",
      margin: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "35rem",
      margin: "1rem",
    },
  },
  modal: {
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  bullets: {
    color: theme.palette.common.white,
    textAlign: "left",
    fontSize: 16,
    lineHeight: "1.5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

enum StartQuotePageUserStatus {
  emailLookUpPending = 0,
  noEmail,
  hasEmailNoPassword,
  expired,
}

const StartQuotePage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  const continueEmailFormikProps = useContinueEmailFormikConfig();
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<StartQuotePageUserStatus>(StartQuotePageUserStatus.emailLookUpPending);
  const { mutateAsync: getLoginType } = useUserLoginType();
  const { mutate: sendResumeApplicationEmail } = useSendResumeApplicationEmail();

  const translationBullets = t("enrollment.start-quote-page.bullets", {
    returnObjects: true,
  });
  const translationBulletArray = Object.values(translationBullets);

  // Create the bullet list to display in the modal
  const bulletList = translationBulletArray.map((bullet, index) => <li key={index}>{bullet}</li>);

  useEffect(() => {
    if (search.includes("expired=true")) {
      setOpen(true);
      setStatus(StartQuotePageUserStatus.expired);
    }
  }, [search]);

  const pushDataAndRedirect = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "getQuoteEvent",
        getQuoteText: "Get Quote",
      },
    });
    history.push(routes.enrollment.qualify.path);
  };

  return (
    <div className={classes.root}>
      <Grid container item className={classes.modalContainer}>
        <Paper className={classes.modalPaper} elevation={7}>
          <Grid container item className={classes.modal} spacing={2}>
            <Grid container item className={classes.textContainer}>
              <Grid item xs={12}>
                <Typography variant="h1" component="h2" className={classes.text}>
                  {t("enrollment.start-quote-page.title")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ul className={classes.bullets}>{bulletList}</ul>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container item className={classes.buttonContainer}>
        <Grid item xs={12} sm={6} md={4} className={classes.button}>
          <NextButton fullWidth variant="contained" onClick={() => pushDataAndRedirect()}>
            {t("enrollment.start-quote-page.get-quote-button")}
          </NextButton>
        </Grid>
        <Grid item container xs={12} className={classes.startedQuoteSection}>
          <Typography variant="h4" component="h3" className={classes.alreadyStartedQuote}>
            {t("enrollment.start-quote-page.already-started-quote")}
          </Typography>
          <Button variant="contained" onClick={() => setOpen(true)} className={classes.continueQuoteButton}>
            {t("enrollment.start-quote-page.continue-quote-button")}
          </Button>
        </Grid>
      </Grid>
      <Dialog className={classes.dialog} open={open} onClose={() => setOpen(false)}>
        <Formik
          {...continueEmailFormikProps}
          onSubmit={async (values) => {
            const loginType = await getLoginType(values[ContinueEmailFields.email]);
            switch (loginType) {
              case UserLoginType.magiclink: {
                setStatus(StartQuotePageUserStatus.hasEmailNoPassword);
                sendResumeApplicationEmail({ email: values[ContinueEmailFields.email] });
                break;
              }
              case UserLoginType.password: {
                history.push(routes.login.path);
                break;
              }
              case UserLoginType.none: {
                setStatus(StartQuotePageUserStatus.noEmail);
                break;
              }
            }
          }}
        >
          {(formik) => (
            <>
              <IconButton
                className={classes.continueQuoteClose}
                onClick={() => setOpen(false)}
                aria-label={t("continue-quote-email.close-icon-label")}
              >
                <CloseIcon />
              </IconButton>
              {status === StartQuotePageUserStatus.emailLookUpPending && <ContinueQuoteEmail />}
              {status === StartQuotePageUserStatus.noEmail && (
                <StartNewQuote
                  email={formik.values[ContinueEmailFields.email]}
                  onPreviousClick={() => {
                    setStatus(StartQuotePageUserStatus.emailLookUpPending);
                  }}
                  onNextClick={() => history.push(routes.enrollment.qualify.path)}
                />
              )}
            </>
          )}
        </Formik>
        {status === StartQuotePageUserStatus.hasEmailNoPassword && <WelcomeBackQuote accountExpired={false} />}
        {status === StartQuotePageUserStatus.expired && <WelcomeBackQuote accountExpired />}
      </Dialog>
    </div>
  );
};

export default StartQuotePage;
