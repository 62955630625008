import { useAuth } from "@chq/authentication";
import { AuthenticationApi, UserStatusResponse } from "@chq/enrollment-api";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { useApi } from "../useApi";

export const useUserStatus = (
  options?: UseQueryOptions<UserStatusResponse, Error>,
): UseQueryResult<UserStatusResponse, Error> => {
  const { user } = useAuth();
  const authenticationApi = useApi(AuthenticationApi);
  const query = useQuery<UserStatusResponse, Error>(
    "user-status",
    async () => {
      const response = await authenticationApi.apiV10AuthenticationUserStatusPost({
        userStatusRequest: { userId: user?.sub },
      });
      return response.data!;
    },
    options,
  );
  return query;
};
