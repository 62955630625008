import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const PdfIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9 10.2H12.6V6.9L15.9 10.2ZM13.2 6H8.40001C7.73401 6 7.20001 6.534 7.20001 7.2V16.8C7.20001 17.46 7.73401 18 8.40001 18H15.6C15.9183 18 16.2235 17.8736 16.4485 17.6485C16.6736 17.4235 16.8 17.1183 16.8 16.8V9.6L13.2 6ZM9.21007 13.9295V15H8.6256V12.0141H9.66329C9.96134 12.0141 10.1965 12.1022 10.3688 12.2786C10.541 12.455 10.6272 12.686 10.6272 12.9718C10.6272 13.2589 10.5403 13.4906 10.3667 13.667C10.1944 13.842 9.95997 13.9295 9.66329 13.9295H9.21007ZM9.21007 13.4619H9.66329C9.78771 13.4619 9.88273 13.4161 9.94835 13.3245C10.014 13.2329 10.0468 13.1167 10.0468 12.9759C10.0468 12.8337 10.014 12.7161 9.94835 12.6231C9.88409 12.5288 9.78907 12.4816 9.66329 12.4816H9.21007V13.4619ZM11.2404 15V12.0141H12.1427C12.449 12.0141 12.7019 12.1303 12.9015 12.3627C13.1011 12.5937 13.2009 12.8918 13.2009 13.2568V13.7593C13.2009 14.1243 13.1011 14.4224 12.9015 14.6534C12.7019 14.8845 12.449 15 12.1427 15H11.2404ZM11.8249 12.4816V14.5345H12.1099C12.2726 14.5345 12.3977 14.4641 12.4852 14.3232C12.5741 14.1811 12.6185 13.9931 12.6185 13.7593V13.2527C12.6185 13.0203 12.5741 12.8337 12.4852 12.6929C12.3977 12.5521 12.2726 12.4816 12.1099 12.4816H11.8249ZM14.4274 13.7593H15.4405V13.2937H14.4274V12.4816H15.6189V12.0141H13.8429V15H14.4274V13.7593Z"
      />
    </SvgIcon>
  );
};

export default PdfIcon;
