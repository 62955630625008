/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ViolationTypes, ViolationTypesFromJSON, ViolationTypesFromJSONTyped, ViolationTypesToJSON } from "./";

/**
 *
 * @export
 * @interface Violation
 */
export interface Violation {
  /**
   *
   * @type {number}
   * @memberof Violation
   */
  id?: number;
  /**
   *
   * @type {Date}
   * @memberof Violation
   */
  dateOfViolation?: Date | null;
  /**
   *
   * @type {ViolationTypes}
   * @memberof Violation
   */
  violationType?: ViolationTypes;
}

export function ViolationFromJSON(json: any): Violation {
  return ViolationFromJSONTyped(json, false);
}

export function ViolationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Violation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    dateOfViolation: !exists(json, "dateOfViolation")
      ? undefined
      : json["dateOfViolation"] === null
      ? null
      : new Date(json["dateOfViolation"]),
    violationType: !exists(json, "violationType") ? undefined : ViolationTypesFromJSON(json["violationType"]),
  };
}

export function ViolationToJSON(value?: Violation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    dateOfViolation:
      value.dateOfViolation === undefined
        ? undefined
        : value.dateOfViolation === null
        ? null
        : value.dateOfViolation.toISOString(),
    violationType: ViolationTypesToJSON(value.violationType),
  };
}
