import { NotificationPreference } from "@chq/enrollment-api";

export const usePreferNotifiedTypes = (): NotificationPreference[] => {
  return [
    NotificationPreference.Phone,
    NotificationPreference.Text,
    NotificationPreference.Email,
    NotificationPreference.Any,
  ];
};
