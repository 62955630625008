import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const EyeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 9.9C11.5226 9.9 11.0648 10.0896 10.7272 10.4272C10.3896 10.7648 10.2 11.2226 10.2 11.7C10.2 12.1774 10.3896 12.6352 10.7272 12.9728C11.0648 13.3104 11.5226 13.5 12 13.5C12.4774 13.5 12.9352 13.3104 13.2728 12.9728C13.6104 12.6352 13.8 12.1774 13.8 11.7C13.8 11.2226 13.6104 10.7648 13.2728 10.4272C12.9352 10.0896 12.4774 9.9 12 9.9ZM12 14.7C11.2044 14.7 10.4413 14.3839 9.87868 13.8213C9.31607 13.2587 9 12.4956 9 11.7C9 10.9043 9.31607 10.1413 9.87868 9.57868C10.4413 9.01607 11.2044 8.7 12 8.7C12.7957 8.7 13.5587 9.01607 14.1213 9.57868C14.6839 10.1413 15 10.9043 15 11.7C15 12.4956 14.6839 13.2587 14.1213 13.8213C13.5587 14.3839 12.7957 14.7 12 14.7ZM12 7.2C9 7.2 6.438 9.066 5.4 11.7C6.438 14.334 9 16.2 12 16.2C15 16.2 17.562 14.334 18.6 11.7C17.562 9.066 15 7.2 12 7.2Z" />
    </SvgIcon>
  );
};

export default EyeIcon;
