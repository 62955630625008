import { useAuth } from "@chq/authentication";
import { HamburgerItem, HamburgerMenuItems, ShareIcon } from "@chq/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useGetPolicy } from "../../../data/policy-management/useGetPolicy";
import { routes } from "../../routes";

type Props = {
  setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setEcertModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const PolicyManagementMenuItems: React.FC<Props> = ({ setDrawerOpen, setEcertModalOpen }) => {
  const [t] = useTranslation();

  useGetPolicy();
  const { logout } = useAuth();
  const isFactoring = false; //TODO: update this when the backend can provide the info
  const isGeoTab = false; //TODO: update this when the backend can provide the info
  const items: HamburgerItem[] = [
    {
      label: t("policy-management-page.hamburger-menu.dashboard"),
      route: routes.policyManagement.dashboard.path,
      component: NavLink,
      onClick: () => {
        setDrawerOpen && setDrawerOpen(false);
      },
    },
    // {
    //   label: t("policy-management-page.hamburger-menu.coverages"),
    //   route: routes.policyManagement.coverages.path,
    //   component: NavLink,
    //   onClick: () => {
    //     setDrawerOpen && setDrawerOpen(false);
    //   },
    // },
    // {
    //   label: t("policy-management-page.hamburger-menu.equipment"),
    //   route: routes.policyManagement.equipment.path,
    //   component: NavLink,
    //   onClick: () => {
    //     setDrawerOpen && setDrawerOpen(false);
    //   },
    // },
    // {
    //   label: t("policy-management-page.hamburger-menu.drivers"),
    //   route: routes.policyManagement.drivers.path,
    //   component: NavLink,
    //   onClick: () => {
    //     setDrawerOpen && setDrawerOpen(false);
    //   },
    // },
    // {
    //   label: t("policy-management-page.hamburger-menu.company-personal-info"),
    //   route: routes.policyManagement.information.path,
    //   component: NavLink,
    //   onClick: () => {
    //     setDrawerOpen && setDrawerOpen(false);
    //   },
    // },
    {
      label: t("policy-management-page.hamburger-menu.documents"),
      route: routes.policyManagement.documents.path,
      component: NavLink,
      onClick: () => {
        setDrawerOpen && setDrawerOpen(false);
      },
    },
    {
      label: t("policy-management-page.hamburger-menu.ecert"),
      onClick: () => {
        setDrawerOpen && setDrawerOpen(false);
        setEcertModalOpen && setEcertModalOpen(true);
      },
    },
    ...(isFactoring
      ? [
          {
            label: t("policy-management-page.hamburger-menu.factorsoft"),
            route: "https://carrierhq.profitstars.com/Login.aspx ",
            externalRoute: true,
            icon: <ShareIcon />,
            onClick: () => {
              setDrawerOpen && setDrawerOpen(false);
            },
          },
        ]
      : []),
    ...(isGeoTab
      ? [
          {
            label: t("policy-management-page.hamburger-menu.geotab"),
            route: "https://www.geotab.com/mygeotab-login/",
            externalRoute: true,
            icon: <ShareIcon />,
            onClick: () => {
              setDrawerOpen && setDrawerOpen(false);
            },
          },
        ]
      : []),
    {
      label: t("policy-management-page.hamburger-menu.log-out"),
      dividerAbove: true,
      onClick: () => {
        setDrawerOpen && setDrawerOpen(false);
        logout();
      },
    },
  ];

  return <HamburgerMenuItems hamburgerItems={items} />;
};

export default PolicyManagementMenuItems;
