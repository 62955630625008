import React, { ComponentType, FC } from "react";
import { useAuth } from "./authentication-provider";

export enum AuthorizedRole {
  Admin = "Admin",
}

type WithAuthorizedRoleOptions<P> = {
  onUnauthorized?: (props?: P) => JSX.Element;
};
const defaultOnUnauthorized = (): JSX.Element => <></>;

export const withAuthorizedRole = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  requiredRole: AuthorizedRole,
  options: WithAuthorizedRoleOptions<P> = {},
): FC<P> => (props: P): JSX.Element => {
  const { onUnauthorized = defaultOnUnauthorized } = options;
  const isAuthorized = hasAuthorizedRole(requiredRole);
  return isAuthorized ? <Component {...props} /> : onUnauthorized(props);
};

export const hasAuthorizedRole = (requiredRole: AuthorizedRole): boolean => {
  const { user } = useAuth();
  const role = user?.role;
  return role === requiredRole;
};

export const withAdminRole = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  options: WithAuthorizedRoleOptions<P> = {},
): FC<P> => withAuthorizedRole(Component, AuthorizedRole.Admin, options);

export const hasAdminRole = (): boolean => hasAuthorizedRole(AuthorizedRole.Admin);
