import { withAuthenticationRequired } from "@chq/authentication";
import { Footer, Header, LogoType } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Formik } from "formik";
import React, { ComponentType, FC } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useGetApplication, useGetApplicationReview, useTermsAndConditionLinks } from "../../data/enrollment";
import { useSendHelpEmailRequest } from "../../data/enrollment/useSendHelpEmailRequest";
import { routes } from "../routes";
import HeaderText from "../shared/header-text";
import { useFormikConfig } from "../shared/validation-schema/validationSchema";
import { EnrollmentStepper } from "./components/enrollment-stepper";
import AccountSuccessPage from "./pages/account-success-page";
import BasicInfoPage from "./pages/basic-info-page";
import CoveragePage from "./pages/coverage-page";
import DNQOverallPage from "./pages/dnq-overall-page";
import DNQPage from "./pages/dnq-page";
import DNQPowerUnitsPage from "./pages/dnq-power-units-page";
import DNQStatePage from "./pages/dnq-state-page";
import DriversPage from "./pages/drivers-page";
import EquipmentPage from "./pages/equipment-page";
import FinishApplicationPage from "./pages/finish-application-page";
import InactiveQuotePage from "./pages/inactive-quote-page";
import MarketingLandingPage from "./pages/marketing-landing-page";
import PartnerLandingPage from "./pages/partner-landing-page";
import PaymentMethodPage from "./pages/payment-method-page";
import QualifyPage from "./pages/qualify-page";
import QuoteBoundPage from "./pages/quote-bound-page";
import QuoteOverviewPage from "./pages/quote-overview-page";
import QuotePendingPage from "./pages/quote-pending-page";
import ReviewApplicationPage from "./pages/review-application-page";
import SaveQuotePage from "./pages/save-quote-page";
import StartQuotePage from "./pages/start-quote-page";
import VerifyAccountPage from "./pages/verify-account-page";
import VerifySuccessPage from "./pages/verify-success-page";

const withAuth = <P extends Record<string, unknown>>(Component: ComponentType<P>): FC<P> =>
  withAuthenticationRequired(Component, {
    onRedirecting: () => <Redirect push to={routes.enrollment.path} />,
  });

const withApplicationData = <P extends Record<string, unknown>>(Component: React.ComponentType): React.FC<P> => (
  props: P,
): JSX.Element => {
  const { isLoading } = useGetApplication(undefined);
  const classes = useStyles();

  if (isLoading) {
    return <Grid container className={classes.fullHeight}></Grid>;
  }

  return <Component {...props} />;
};

const withReviewApplicationData = <P extends Record<string, unknown>>(Component: React.ComponentType): React.FC<P> => (
  props: P,
): JSX.Element => {
  const { isLoading } = useGetApplicationReview();
  const classes = useStyles();

  if (isLoading) {
    return <Grid container className={classes.fullHeight}></Grid>;
  }

  return <Component {...props} />;
};

const useStyles = makeStyles((theme: Theme) => ({
  enrollmentContainer: {
    minHeight: "100vh",
  },
  fullHeight: {
    overflow: "hidden",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  headerContainer: {
    textAlign: "center",
  },
  header: {
    padding: "0rem 3rem 0rem 3rem",
  },
}));

export const headerTextRouteMap = {
  [routes.enrollment.path]: "enrollment.start-quote-page.header-text",
  [routes.enrollment.finishApplication.path]: "finish-application-page.header-text",
  [routes.enrollment.payment.path]: "finish-application-page.header-text",
  [routes.enrollment.review.path]: "enrollment.review-page.header-text",
  [routes.enrollment.quote.pending.path]: "quote-saved-page.header-text",
};

export const headerLogoRouteMap = {
  [routes.enrollment.pendingQualify.path]: LogoType.AON,
  [routes.enrollment.notQualify.path]: LogoType.AON,
  [routes.enrollment.saveQuote.path]: LogoType.AON,
  [routes.enrollment.verify.path]: LogoType.AON,
  [routes.enrollment.success.path]: LogoType.AON,
  [routes.enrollment.successAccount.path]: LogoType.AON,
  [routes.enrollment.finishApplication.path]: LogoType.AON,
  [routes.enrollment.payment.path]: LogoType.AON,
  [routes.enrollment.review.path]: LogoType.AON,
  [routes.enrollment.quote.pending.path]: LogoType.AON,
  [routes.enrollment.quote.bound.path]: LogoType.AON,
};

export const enrollmentFooterMap = {
  [routes.enrollment.finishApplication.path]: "footer.legal",
  [routes.enrollment.successAccount.path]: "footer.legal",
  [routes.enrollment.success.path]: "footer.legal",
  [routes.enrollment.verify.path]: "footer.legal",
  [routes.enrollment.saveQuote.path]: "footer.legal",
  [routes.enrollment.payment.path]: "footer.legal",
  [routes.enrollment.review.path]: "footer.legal",
  [routes.enrollment.pendingQualify.path]: "footer.legal",
  [routes.enrollment.notQualify.path]: "footer.legal",
  [routes.enrollment.quote.pending.path]: "footer.legal",
  [routes.enrollment.quote.bound.path]: "footer.legal",
};

enum Fields {
  name = "name",
  email = "email",
  phone = "phone",
  problem = "problem",
  description = "description",
}

type FormProps = {
  [Fields.name]: string;
  [Fields.email]: string;
  [Fields.phone]: string;
  [Fields.problem]: string;
  [Fields.description]: string;
};

const EnrollmentFooter: React.FC = () => {
  const [t] = useTranslation();
  const formikProps = useFormikConfig();

  const { mutate: sendEmail } = useSendHelpEmailRequest();
  const { data: terms } = useTermsAndConditionLinks();
  const termsLink = terms?.termsOfUseLink;
  const privacyLink = terms?.privacyPolicyLink;

  //Get Array of Problems for footer email
  const listOfProblems = t("footer.problem-options", {
    returnObjects: true,
  });
  const problemOptionsArray: string[] = Object.values(listOfProblems);

  const useFooterText = (routeMap: { [x: string]: string }) => {
    const location = useLocation();

    if (!routeMap[location.pathname.toLowerCase()]) {
      return t("footer.legal-carrierHQ");
    } else {
      return t("footer.legal");
    }
  };

  return (
    <Formik
      {...formikProps}
      initialValues={{
        [Fields.name]: "",
        [Fields.email]: "",
        [Fields.phone]: "",
        [Fields.problem]: "",
        [Fields.description]: "",
      }}
      onSubmit={(values: FormProps) => {
        sendEmail({
          emailAddress: values[Fields.email],
          phoneNumber: values[Fields.phone],
          name: values[Fields.name],
          problem: values[Fields.problem],
          description:
            values[Fields.description] && values[Fields.description].replace(/\s/g, "").length !== 0
              ? values[Fields.description]
              : "N/A", //If a user only fills the description field with a blank, put in "N/A" because you cannot send a blank string
        });
      }}
    >
      <Footer
        termsLink={termsLink}
        privacyLink={privacyLink}
        nameLabel={t("footer.name")}
        phoneLabel={t("footer.phone")}
        emailLabel={t("footer.email")}
        problemLabel={t("footer.problem")}
        problemList={problemOptionsArray}
        problemListCondition={problemOptionsArray[6]}
        descriptionLabel={t("footer.description")}
        needHelpText={t("footer.need-help")}
        reachOutText={t("footer.reach-out")}
        privacyText={t("footer.privacy")}
        termsText={t("footer.terms", { year: new Date().getFullYear(), company: "CarrierHQ" })}
        legalText={useFooterText(enrollmentFooterMap)}
      />
    </Formik>
  );
};

const EnrollmentRoutes: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const location = useLocation();

  const useHeaderLogo = (): LogoType => {
    return headerLogoRouteMap[location.pathname.toLowerCase()] || LogoType.CHQ;
  };

  return (
    <Grid container direction="column" justify="center" className={classes.enrollmentContainer}>
      <Grid item className={classes.headerContainer}>
        <Header
          aonTitleAccess={t("common.aon-logo")}
          aonAriaLabel={t("common.aon-logo")}
          chqTitleAccess={t("common.chq-logo")}
          chqAriaLabel={t("common.chq-logo")}
          headerBarTitleAccess={t("common.middle-bar")}
          headerBarAriaLabel={t("common.middle-bar")}
          logo={useHeaderLogo()}
          location={location.pathname.toLowerCase()}
        >
          <Grid item xs={12} sm={10} md={6} lg={4}>
            <EnrollmentStepper />
          </Grid>
          <HeaderText routeMap={headerTextRouteMap} className={classes.header} />
        </Header>
      </Grid>

      <div className={classes.fullHeight}>
        <Switch>
          <Route exact path={routes.enrollment.campaign.campaign_id.path} component={MarketingLandingPage} />
          <Route exact path={routes.enrollment.partner.partner_name.path} component={PartnerLandingPage} />
          <Route exact path={routes.enrollment.path} component={StartQuotePage} />
          <Route exact path={routes.enrollment.qualify.path} component={QualifyPage} />
          <Route exact path={routes.enrollment.notQualify.path} component={DNQPage} />
          <Route exact path={routes.enrollment.pendingQualify.powerUnit.path} component={DNQPowerUnitsPage} />
          <Route exact path={routes.enrollment.pendingQualify.state.path} component={DNQStatePage} />
          <Route exact path={routes.enrollment.pendingQualify.overall.path} component={DNQOverallPage} />
          <Route
            exact
            path={routes.enrollment.basicInfo.path}
            component={withAuth(withApplicationData(BasicInfoPage))}
          />
          <Route
            exact
            path={routes.enrollment.equipment.path}
            component={withAuth(withApplicationData(EquipmentPage))}
          />
          <Route exact path={routes.enrollment.drivers.path} component={withAuth(withApplicationData(DriversPage))} />
          <Route exact path={routes.enrollment.coverage.path} component={withAuth(withApplicationData(CoveragePage))} />
          <Route
            exact
            path={routes.enrollment.quote.path}
            component={withAuth(withApplicationData(QuoteOverviewPage))}
          />
          <Route
            exact
            path={routes.enrollment.quote.inactive.path}
            component={withAuth(withApplicationData(InactiveQuotePage))}
          />
          <Route
            exact
            path={routes.enrollment.saveQuote.path}
            component={withAuth(withApplicationData(SaveQuotePage))}
          />
          <Route
            exact
            path={routes.enrollment.verify.path}
            component={withAuth(withApplicationData(VerifyAccountPage))}
          />
          <Route
            exact
            path={routes.enrollment.success.path}
            component={withAuth(withApplicationData(VerifySuccessPage))}
          />
          <Route
            exact
            path={routes.enrollment.successAccount.path}
            component={withAuth(withApplicationData(AccountSuccessPage))}
          />
          <Route
            exact
            path={routes.enrollment.finishApplication.path}
            component={withAuth(withApplicationData(FinishApplicationPage))}
          />
          <Route
            exact
            path={routes.enrollment.payment.path}
            component={withAuth(withReviewApplicationData(PaymentMethodPage))}
          />
          <Route
            exact
            path={routes.enrollment.review.path}
            component={withAuth(withReviewApplicationData(ReviewApplicationPage))}
          />
          <Route
            exact
            path={routes.enrollment.quote.pending.path}
            component={withAuth(withReviewApplicationData(QuotePendingPage))}
          />
          <Route
            exact
            path={routes.enrollment.quote.bound.path}
            component={withAuth(withReviewApplicationData(QuoteBoundPage))}
          />
        </Switch>
      </div>

      <Grid item>
        <EnrollmentFooter />
      </Grid>
    </Grid>
  );
};

export default EnrollmentRoutes;
