import { ChevronDownIcon, EditableDeletableCard, TextInput } from "@chq/components";
import { Button, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: ".5rem",
  },
  link: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    paddingTop: "1rem",
  },
  email: {
    paddingTop: "1.375rem",
  },
  resetButton: {
    padding: ".5em 0rem",
  },
  forgotPassword: {
    cursor: "pointer",
  },
  button: {
    paddingTop: ".5rem",
  },
  leftArrow: {
    transform: "rotate(90deg)",
    fontSize: "40px",
  },
}));

export enum Fields {
  email = "email",
}

type FormProps = {
  [Fields.email]: string;
};

type Props = {
  email?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
  backToLoginOnClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const useValidateSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.email]: Yup.string()
      .email(t("errors.invalid-email"))
      .required(t(`errors.required`, { field: t(`reset-password-email.${Fields.email}.label`) })),
  });
};

export const useFormikConfig = ({ email: initialEmail = "" }: Props = {}): Omit<
  FormikConfig<FormProps>,
  "onSubmit"
> => {
  const validationSchema = useValidateSchema();
  return {
    initialValues: {
      [Fields.email]: initialEmail,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    enableReinitialize: true,
  };
};

const ResetPasswordEmail: React.FC<Props> = ({ backToLoginOnClick }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<FormProps>();

  return (
    <EditableDeletableCard
      variant="default"
      className={classes.container}
      titleElement={
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.forgotPassword}>
              <Link onClick={backToLoginOnClick} className={classes.link}>
                <ChevronDownIcon className={classes.leftArrow} />
                {t("reset-password-email.back-to-login")}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.title}>
            <Typography variant="h3">{t("reset-password-email.title")}</Typography>
          </Grid>
        </Grid>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              required
              label={t("reset-password-email.email.label")}
              id={Fields.email}
              name={Fields.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.email]}
              error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
              helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
              className={classes.email}
            />
          </Grid>
          <Grid item xs={8} className={classes.button}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!formik.isValid}
              className={classes.resetButton}
            >
              {t("reset-password-email.password-reset-button")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </EditableDeletableCard>
  );
};

export default ResetPasswordEmail;
