import { StringApiResponse } from "@chq/enrollment-api";
import { fade } from "@chq/styles";
import { makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { PlaidLinkOnEvent, PlaidLinkOnExit, PlaidLinkOnSuccess } from "react-plaid-link";
import { PlaidLink } from "../ui/enrollment/components/plaid-link";
import AddBankAccountCompleteCard from "./add-bank-account-complete-card";
import ConnectBankManuallyCard from "./connect-bank-manually-card";
import VerifyAccountCompleteCard from "./verify-account-complete-card";

const useStyles = makeStyles((theme: Theme) => ({
  startingContainer: {
    background: theme.palette.grey[600],
    border: `1px ${theme.palette.grey[500]} solid`,
    width: "100%",
    padding: "1.125rem 0.625rem 2rem",
  },
  setUpButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginTop: "0.75rem",
    minHeight: "3.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  icon: {
    height: "2.5rem",
    width: "auto",
    color: theme.palette.success.main,
  },
  bankInfoTitle: {
    marginBottom: "0.75rem",
  },
}));

type Props = {
  accountNumber: string;
  bankName: string;
  plaidOnSuccess: PlaidLinkOnSuccess;
  plaidOnEvent: PlaidLinkOnEvent;
  plaidOnExit: PlaidLinkOnExit;
  plaidSuccess: boolean | undefined;
  stripeSuccess: boolean | undefined;
  token?: StringApiResponse | undefined;
  onAddAccountDialogOpen: () => void;
  onVerifyAccountDialogOpen: () => void;
  onAddNewBankAccount: () => void;
  stripeBankAccountAdded: boolean;
  onComplete: () => void;
  onNotComplete: () => void;
};

const FactoringBankInformationForm: React.FC<Props> = ({
  accountNumber,
  bankName,
  plaidOnSuccess,
  plaidOnEvent,
  plaidOnExit,
  plaidSuccess,
  stripeSuccess,
  token,
  onAddAccountDialogOpen,
  onVerifyAccountDialogOpen,
  onAddNewBankAccount,
  stripeBankAccountAdded,
  onComplete,
  onNotComplete,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.startingContainer}>
      <Typography variant="h2" className={classes.bankInfoTitle}>
        {t("factoring.bank-information.title")}
      </Typography>
      <Typography variant="body1">{t("factoring.bank-information.bank-info-however-text")}</Typography>
      {!stripeBankAccountAdded && token && (
        <PlaidLink
          onSuccess={plaidOnSuccess}
          onEvent={plaidOnEvent}
          onExit={plaidOnExit}
          plaidSuccess={plaidSuccess}
          token={token!.data!}
        />
      )}
      {plaidSuccess === false && !stripeBankAccountAdded && (
        <ConnectBankManuallyCard
          onAddAccountDialogOpen={onAddAccountDialogOpen}
          onComplete={onComplete}
          onNotComplete={onNotComplete}
        />
      )}
      {stripeBankAccountAdded && !stripeSuccess && (
        <AddBankAccountCompleteCard
          accountNumber={accountNumber}
          onVerifyAccountDialogOpen={onVerifyAccountDialogOpen}
        />
      )}
      {stripeSuccess && (
        <VerifyAccountCompleteCard
          onAddNewBankAccount={onAddNewBankAccount}
          bankName={bankName}
          accountNumber={accountNumber}
        />
      )}
    </Paper>
  );
};

export default FactoringBankInformationForm;
