import { Checkbox as MaterialCheckbox, CheckboxProps, FormControlLabel, Grid, Typography } from "@material-ui/core";
import React from "react";

type Props = CheckboxProps & {
  caption?: string;
  label: React.ReactNode;
};
const Checkbox: React.FC<Props> = ({ caption, label, ...CheckboxProps }) => {
  return (
    <FormControlLabel
      control={<MaterialCheckbox {...CheckboxProps} />}
      label={
        <Grid container direction="column">
          <Grid container direction="row">
            {label}
          </Grid>
          <Typography variant="body1">{caption}</Typography>
        </Grid>
      }
    />
  );
};

export default Checkbox;
