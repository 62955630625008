import { CircularProgress, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import classNames from "classnames";
import React from "react";
import EditableDeletableCard from "./editable-deletable-card";
import { DownloadIcon, PdfIcon, SendIcon as EmailIcon } from "./icons";

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    paddingTop: "1rem",
    paddingLeft: "0.5rem",
  },
  pdfListContainer: {
    "&:first-child": {
      paddingTop: "0.5rem",
      paddingBottom: "0.25rem",
    },
  },
  pdfItemContainer: {
    padding: "1rem 0.75rem 0.25rem 0.75rem",
  },
  buttonContainer: {
    width: "40px",
    textAlign: "center",
  },
  button: {
    height: "40px",
  },
  icon: {
    width: "auto",
    height: "40px",
    color: theme.palette.grey[300],
  },
  iconDisabled: {
    width: "auto",
    height: "40px",
    color: theme.palette.secondary.light,
  },
  regenerateIcon: {
    width: "auto",
    height: "24px",
    color: theme.palette.grey[300],
  },
  fileName: {
    fontWeight: 400,
    width: "7rem",
    overflowWrap: "break-word",
  },
  iconButton: {
    padding: "0rem",
  },
  pdfIcon: {
    display: "flex",
  },
  emailIcon: {
    transform: "translate(2px, 0px)",
  },
  centerIcon: {
    alignSelf: "center",
  },
  loadingText: {
    textAlign: "center",
  },
  activeLink: {},
  inactiveLink: {
    pointerEvents: "none",
    cursor: "default",
  },
  circularProgress: {
    color: theme.palette.grey[300],
  },
}));

export type File = {
  id?: number;
  name?: string;
  publicUri?: string | null;
  storageUri?: string | null;
  documentType?: string;
  regenerateThisFile?: boolean;
};

export type PDFDownloadListProps = {
  title?: string;
  files: File[];
  downloadAriaLabel?: string;
  isLoading?: boolean;
  loadingText?: string;
  emailAriaLabel?: string;
  downloadEnabled?: boolean;
  emailEnabled?: boolean;
  regenerateEnabled?: boolean;
  onEmail?: (file: File) => void;
  onRegenerateDocument?: (file: File, docIndex: number) => void;
  isRegenerating?: boolean;
  pdfIndex?: number;
};

const PdfDownloadList: React.FC<PDFDownloadListProps> = ({
  title,
  files,
  downloadAriaLabel,
  isLoading = false,
  loadingText = "",
  emailAriaLabel,
  downloadEnabled = true,
  emailEnabled = false,
  regenerateEnabled = false,
  onEmail,
  onRegenerateDocument,
  isRegenerating = false,
  pdfIndex,
}) => {
  const classes = useStyles();

  return (
    <EditableDeletableCard
      variant="default"
      border={false}
      title={title}
      titleVariant="h2"
      className={classes.cardContainer}
    >
      {isLoading ? (
        <Grid container className={classes.pdfListContainer}>
          <Grid container direction="column" spacing={2} alignItems={"center"}>
            <Grid item>
              <CircularProgress color="primary" size="2rem" aria-label={loadingText} />
            </Grid>
            <Grid item>
              <Typography variant="body1" className={classes.loadingText}>
                {loadingText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.pdfListContainer}>
          {files.map((file, index) => {
            return (
              <Grid
                container
                item
                direction="row"
                justify="space-between"
                className={classes.pdfItemContainer}
                key={`pdf-item-${file.name}-${index}`}
              >
                <Grid item container direction="row" xs={8} alignItems="center">
                  <Grid item className={classNames(classes.pdfIcon)}>
                    <PdfIcon className={classNames(classes.icon)} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" component="p" className={classes.fileName}>
                      {file.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="row" xs={4} justify="flex-end">
                  {regenerateEnabled && file.regenerateThisFile && (
                    <Grid item className={classes.buttonContainer}>
                      <IconButton
                        className={classNames(classes.iconButton, classes.button)}
                        aria-label={downloadAriaLabel}
                        onClick={() => !isRegenerating && onRegenerateDocument && onRegenerateDocument(file, index)}
                      >
                        {isRegenerating && pdfIndex === index ? (
                          <CircularProgress className={classes.circularProgress} size="1.2rem" />
                        ) : (
                          <CachedIcon className={classNames(classes.regenerateIcon)} />
                        )}
                      </IconButton>
                    </Grid>
                  )}
                  {downloadEnabled && (
                    <Grid item className={classes.buttonContainer}>
                      <a
                        href={String(file.publicUri)}
                        target="_blank"
                        className={file.publicUri ? classes.activeLink : classes.inactiveLink}
                      >
                        <IconButton className={(classes.iconButton, classes.button)} aria-label={downloadAriaLabel}>
                          <DownloadIcon
                            className={file.publicUri ? classNames(classes.icon) : classNames(classes.iconDisabled)}
                          />
                        </IconButton>
                      </a>
                    </Grid>
                  )}
                  {emailEnabled && (
                    <Grid item>
                      <IconButton
                        className={classes.iconButton}
                        aria-label={emailAriaLabel}
                        onClick={() => onEmail && onEmail(file)}
                      >
                        <EmailIcon className={classNames(classes.icon, classes.emailIcon)} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </EditableDeletableCard>
  );
};

export default PdfDownloadList;
