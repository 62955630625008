/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  OwnerBankAccountInfo,
  OwnerBankAccountInfoFromJSON,
  OwnerBankAccountInfoFromJSONTyped,
  OwnerBankAccountInfoToJSON,
} from "./";

/**
 *
 * @export
 * @interface OwnerBankAccountInfoApiResponse
 */
export interface OwnerBankAccountInfoApiResponse {
  /**
   *
   * @type {OwnerBankAccountInfo}
   * @memberof OwnerBankAccountInfoApiResponse
   */
  data?: OwnerBankAccountInfo;
  /**
   *
   * @type {ErrorResponse}
   * @memberof OwnerBankAccountInfoApiResponse
   */
  error?: ErrorResponse;
}

export function OwnerBankAccountInfoApiResponseFromJSON(json: any): OwnerBankAccountInfoApiResponse {
  return OwnerBankAccountInfoApiResponseFromJSONTyped(json, false);
}

export function OwnerBankAccountInfoApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OwnerBankAccountInfoApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : OwnerBankAccountInfoFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function OwnerBankAccountInfoApiResponseToJSON(value?: OwnerBankAccountInfoApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: OwnerBankAccountInfoToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
