// TODO: this should change to something generic
import { BaseAPI, Configuration } from "@chq/enrollment-api";

type ApiConstructor<T extends BaseAPI> = new (config: Configuration) => T;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useApi<T extends BaseAPI>(api: ApiConstructor<T>) {
  return new api(
    new Configuration({
      basePath: window.origin,
      middleware: [],
    }),
  );
}
