import { CardField, EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { FactoringCustomer } from "@chq/factoring-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  shipperTitle: {
    fontWeight: "bold",
    paddingBottom: ".625rem",
  },
  divider: {
    borderBottom: `solid .0625rem ${theme.palette.secondary.light}`,
  },
  mainContainer: { background: theme.palette.grey[600] },
  container: {
    paddingTop: ".625rem",
  },
  shipper: {
    paddingBottom: "1rem",
  },
}));

type Props = {
  shipperBrokers?: FactoringCustomer[];
  onEdit?: EditableDeletableCardProps["onEdit"];
};

const FactoringShipperBrokerCard: React.FC<Props> = ({ shipperBrokers = [], onEdit }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const totalShipperBrokers = shipperBrokers.length;

  return (
    <EditableDeletableCard
      variant="edit"
      title={t("factoring.shipper-broker-card.title")}
      titleVariant="h2"
      titleComponent="h3"
      onEdit={onEdit}
      IconButtonProps={{ "aria-label": t("factoring.shipper-broker-card.edit-button") }}
      className={classes.mainContainer}
    >
      {shipperBrokers.length > 0 ? (
        shipperBrokers.map((shipperBroker, index) => (
          <Grid
            container
            className={classNames(classes.container, { [classes.divider]: totalShipperBrokers - 1 !== index })}
            key={shipperBroker.businessName}
          >
            <Typography variant="h3" className={classes.shipperTitle}>
              {t("factoring.shipper-broker-card.shipper-broker-title", { number: index + 1 })}
            </Typography>
            <CardField
              id={`name${index}`}
              label={t("factoring.shipper-broker-card.name")}
              values={[{ value: shipperBroker.businessName }]}
            />
            <CardField
              id={`mcNumber${index}`}
              label={t("factoring.shipper-broker-card.mc-number")}
              values={[{ value: shipperBroker.mcNumber }]}
            />
            <CardField
              id={`addressLineOne${index}`}
              label={t("factoring.shipper-broker-card.address-line-one")}
              values={[{ value: shipperBroker.customerAddress?.address1 }]}
            />
            {shipperBroker.customerAddress?.address2 && (
              <CardField
                id={`addressLineTwo${index}`}
                label={t("factoring.shipper-broker-card.address-line-two")}
                values={[{ value: shipperBroker.customerAddress?.address2 }]}
              />
            )}
            <CardField
              id={`city${index}`}
              label={t("factoring.shipper-broker-card.city")}
              values={[{ value: shipperBroker.customerAddress?.city }]}
            />
            <CardField
              id={`state${index}`}
              label={t("factoring.shipper-broker-card.state")}
              values={[{ value: shipperBroker.customerAddress?.state }]}
            />
            <CardField
              id={`zip${index}`}
              label={t("factoring.shipper-broker-card.zip")}
              values={[{ value: shipperBroker.customerAddress?.zip }]}
            />
            <CardField
              id={`sales${index}`}
              label={t("factoring.shipper-broker-card.sales")}
              values={[
                shipperBroker.monthlySales !== undefined
                  ? {
                      value: t("factoring.shipper-broker-card.sales-value", {
                        money: shipperBroker?.monthlySales,
                      }),
                    }
                  : {
                      value: t("common.not-applicable"),
                    },
              ]}
              className={classNames({ [classes.shipper]: totalShipperBrokers - 1 !== index })}
            />
          </Grid>
        ))
      ) : (
        <Typography variant="h3" className={classes.shipperTitle}>
          {t("factoring.shipper-broker-card.no-shipper-broker-title")}
        </Typography>
      )}
    </EditableDeletableCard>
  );
};

export default FactoringShipperBrokerCard;
