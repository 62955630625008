/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Factors
 */
export interface Factors {
  /**
   *
   * @type {number}
   * @memberof Factors
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Factors
   */
  attractFactor?: number | null;
  /**
   *
   * @type {Date}
   * @memberof Factors
   */
  attractFactorSaveDate?: Date | null;
  /**
   *
   * @type {number}
   * @memberof Factors
   */
  averageAgeFactor?: number | null;
  /**
   *
   * @type {Date}
   * @memberof Factors
   */
  ageFactorSaveDate?: Date | null;
  /**
   *
   * @type {number}
   * @memberof Factors
   */
  cabFactor?: number | null;
  /**
   *
   * @type {Date}
   * @memberof Factors
   */
  cabFactorSaveDate?: Date | null;
  /**
   *
   * @type {number}
   * @memberof Factors
   */
  telematicFactor?: number | null;
  /**
   *
   * @type {number}
   * @memberof Factors
   */
  newVentureFactor?: number | null;
  /**
   *
   * @type {number}
   * @memberof Factors
   */
  baseScalar?: number | null;
}

export function FactorsFromJSON(json: any): Factors {
  return FactorsFromJSONTyped(json, false);
}

export function FactorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Factors {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    attractFactor: !exists(json, "attractFactor") ? undefined : json["attractFactor"],
    attractFactorSaveDate: !exists(json, "attractFactorSaveDate")
      ? undefined
      : json["attractFactorSaveDate"] === null
      ? null
      : new Date(json["attractFactorSaveDate"]),
    averageAgeFactor: !exists(json, "averageAgeFactor") ? undefined : json["averageAgeFactor"],
    ageFactorSaveDate: !exists(json, "ageFactorSaveDate")
      ? undefined
      : json["ageFactorSaveDate"] === null
      ? null
      : new Date(json["ageFactorSaveDate"]),
    cabFactor: !exists(json, "cabFactor") ? undefined : json["cabFactor"],
    cabFactorSaveDate: !exists(json, "cabFactorSaveDate")
      ? undefined
      : json["cabFactorSaveDate"] === null
      ? null
      : new Date(json["cabFactorSaveDate"]),
    telematicFactor: !exists(json, "telematicFactor") ? undefined : json["telematicFactor"],
    newVentureFactor: !exists(json, "newVentureFactor") ? undefined : json["newVentureFactor"],
    baseScalar: !exists(json, "baseScalar") ? undefined : json["baseScalar"],
  };
}

export function FactorsToJSON(value?: Factors | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    attractFactor: value.attractFactor,
    attractFactorSaveDate:
      value.attractFactorSaveDate === undefined
        ? undefined
        : value.attractFactorSaveDate === null
        ? null
        : value.attractFactorSaveDate.toISOString(),
    averageAgeFactor: value.averageAgeFactor,
    ageFactorSaveDate:
      value.ageFactorSaveDate === undefined
        ? undefined
        : value.ageFactorSaveDate === null
        ? null
        : value.ageFactorSaveDate.toISOString(),
    cabFactor: value.cabFactor,
    cabFactorSaveDate:
      value.cabFactorSaveDate === undefined
        ? undefined
        : value.cabFactorSaveDate === null
        ? null
        : value.cabFactorSaveDate.toISOString(),
    telematicFactor: value.telematicFactor,
    newVentureFactor: value.newVentureFactor,
    baseScalar: value.baseScalar,
  };
}
