/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Acknowledgements,
  AcknowledgementsFromJSON,
  AcknowledgementsFromJSONTyped,
  AcknowledgementsToJSON,
  ApplicationStatus,
  ApplicationStatusFromJSON,
  ApplicationStatusFromJSONTyped,
  ApplicationStatusToJSON,
  BillingCycle,
  BillingCycleFromJSON,
  BillingCycleFromJSONTyped,
  BillingCycleToJSON,
  Business,
  BusinessFromJSON,
  BusinessFromJSONTyped,
  BusinessToJSON,
  Coverages,
  CoveragesFromJSON,
  CoveragesFromJSONTyped,
  CoveragesToJSON,
  Driver,
  DriverFromJSON,
  DriverFromJSONTyped,
  DriverToJSON,
  Equipment,
  EquipmentFromJSON,
  EquipmentFromJSONTyped,
  EquipmentToJSON,
  FactoringApprovalStatus,
  FactoringApprovalStatusFromJSON,
  FactoringApprovalStatusFromJSONTyped,
  FactoringApprovalStatusToJSON,
  Factors,
  FactorsFromJSON,
  FactorsFromJSONTyped,
  FactorsToJSON,
  InsuranceProgram,
  InsuranceProgramFromJSON,
  InsuranceProgramFromJSONTyped,
  InsuranceProgramToJSON,
  NotificationPreference,
  NotificationPreferenceFromJSON,
  NotificationPreferenceFromJSONTyped,
  NotificationPreferenceToJSON,
  Owner,
  OwnerFromJSON,
  OwnerFromJSONTyped,
  OwnerToJSON,
  PdfDocuments,
  PdfDocumentsFromJSON,
  PdfDocumentsFromJSONTyped,
  PdfDocumentsToJSON,
  Quote,
  QuoteFromJSON,
  QuoteFromJSONTyped,
  QuoteToJSON,
  ReferralMethod,
  ReferralMethodFromJSON,
  ReferralMethodFromJSONTyped,
  ReferralMethodToJSON,
  StateSpecificValues,
  StateSpecificValuesFromJSON,
  StateSpecificValuesFromJSONTyped,
  StateSpecificValuesToJSON,
} from "./";

/**
 *
 * @export
 * @interface Application
 */
export interface Application {
  /**
   *
   * @type {number}
   * @memberof Application
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  qualified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  active?: boolean;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  partnerName?: string | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  speedGaugeScore?: number | null;
  /**
   *
   * @type {Date}
   * @memberof Application
   */
  desiredEffectiveDate?: Date | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  applicationState?: string | null;
  /**
   *
   * @type {ApplicationStatus}
   * @memberof Application
   */
  applicationStatus?: ApplicationStatus;
  /**
   *
   * @type {FactoringApprovalStatus}
   * @memberof Application
   */
  factoringApprovalStatus?: FactoringApprovalStatus;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  previousInsurer?: string | null;
  /**
   *
   * @type {NotificationPreference}
   * @memberof Application
   */
  notificationPreference?: NotificationPreference;
  /**
   *
   * @type {ReferralMethod}
   * @memberof Application
   */
  referralMethod?: ReferralMethod;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  otherReferralMethod?: string | null;
  /**
   *
   * @type {BillingCycle}
   * @memberof Application
   */
  billingCycle?: BillingCycle;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  qsAccountNumber?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  intrastateFilingAuthority?: boolean | null;
  /**
   *
   * @type {StateSpecificValues}
   * @memberof Application
   */
  stateSpecificLabel?: StateSpecificValues;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  ineligibleReason?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  stateSpecificInput?: string | null;
  /**
   *
   * @type {Coverages}
   * @memberof Application
   */
  coverages?: Coverages;
  /**
   *
   * @type {Owner}
   * @memberof Application
   */
  owner?: Owner;
  /**
   *
   * @type {Quote}
   * @memberof Application
   */
  currentQuote?: Quote;
  /**
   *
   * @type {Business}
   * @memberof Application
   */
  business?: Business;
  /**
   *
   * @type {Factors}
   * @memberof Application
   */
  factors?: Factors;
  /**
   *
   * @type {Acknowledgements}
   * @memberof Application
   */
  acknowledgements?: Acknowledgements;
  /**
   *
   * @type {PdfDocuments}
   * @memberof Application
   */
  pdfDocuments?: PdfDocuments;
  /**
   *
   * @type {InsuranceProgram}
   * @memberof Application
   */
  insuranceProgram?: InsuranceProgram;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  coveragesId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  ownerId?: number;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  currentQuoteId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  businessId?: number;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  factorsId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  acknowledgementsId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  pdfDocumentsId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  insuranceProgramId?: number | null;
  /**
   *
   * @type {Array<Equipment>}
   * @memberof Application
   */
  coveredEquipment?: Array<Equipment> | null;
  /**
   *
   * @type {Array<Driver>}
   * @memberof Application
   */
  coveredIndividuals?: Array<Driver> | null;
}

export function ApplicationFromJSON(json: any): Application {
  return ApplicationFromJSONTyped(json, false);
}

export function ApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Application {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    qualified: !exists(json, "qualified") ? undefined : json["qualified"],
    active: !exists(json, "active") ? undefined : json["active"],
    partnerName: !exists(json, "partnerName") ? undefined : json["partnerName"],
    speedGaugeScore: !exists(json, "speedGaugeScore") ? undefined : json["speedGaugeScore"],
    desiredEffectiveDate: !exists(json, "desiredEffectiveDate")
      ? undefined
      : json["desiredEffectiveDate"] === null
      ? null
      : new Date(json["desiredEffectiveDate"]),
    applicationState: !exists(json, "applicationState") ? undefined : json["applicationState"],
    applicationStatus: !exists(json, "applicationStatus")
      ? undefined
      : ApplicationStatusFromJSON(json["applicationStatus"]),
    factoringApprovalStatus: !exists(json, "factoringApprovalStatus")
      ? undefined
      : FactoringApprovalStatusFromJSON(json["factoringApprovalStatus"]),
    previousInsurer: !exists(json, "previousInsurer") ? undefined : json["previousInsurer"],
    notificationPreference: !exists(json, "notificationPreference")
      ? undefined
      : NotificationPreferenceFromJSON(json["notificationPreference"]),
    referralMethod: !exists(json, "referralMethod") ? undefined : ReferralMethodFromJSON(json["referralMethod"]),
    otherReferralMethod: !exists(json, "otherReferralMethod") ? undefined : json["otherReferralMethod"],
    billingCycle: !exists(json, "billingCycle") ? undefined : BillingCycleFromJSON(json["billingCycle"]),
    qsAccountNumber: !exists(json, "qsAccountNumber") ? undefined : json["qsAccountNumber"],
    intrastateFilingAuthority: !exists(json, "intrastateFilingAuthority")
      ? undefined
      : json["intrastateFilingAuthority"],
    stateSpecificLabel: !exists(json, "stateSpecificLabel")
      ? undefined
      : StateSpecificValuesFromJSON(json["stateSpecificLabel"]),
    ineligibleReason: !exists(json, "ineligibleReason") ? undefined : json["ineligibleReason"],
    stateSpecificInput: !exists(json, "stateSpecificInput") ? undefined : json["stateSpecificInput"],
    coverages: !exists(json, "coverages") ? undefined : CoveragesFromJSON(json["coverages"]),
    owner: !exists(json, "owner") ? undefined : OwnerFromJSON(json["owner"]),
    currentQuote: !exists(json, "currentQuote") ? undefined : QuoteFromJSON(json["currentQuote"]),
    business: !exists(json, "business") ? undefined : BusinessFromJSON(json["business"]),
    factors: !exists(json, "factors") ? undefined : FactorsFromJSON(json["factors"]),
    acknowledgements: !exists(json, "acknowledgements")
      ? undefined
      : AcknowledgementsFromJSON(json["acknowledgements"]),
    pdfDocuments: !exists(json, "pdfDocuments") ? undefined : PdfDocumentsFromJSON(json["pdfDocuments"]),
    insuranceProgram: !exists(json, "insuranceProgram")
      ? undefined
      : InsuranceProgramFromJSON(json["insuranceProgram"]),
    coveragesId: !exists(json, "coveragesId") ? undefined : json["coveragesId"],
    ownerId: !exists(json, "ownerId") ? undefined : json["ownerId"],
    currentQuoteId: !exists(json, "currentQuoteId") ? undefined : json["currentQuoteId"],
    businessId: !exists(json, "businessId") ? undefined : json["businessId"],
    factorsId: !exists(json, "factorsId") ? undefined : json["factorsId"],
    acknowledgementsId: !exists(json, "acknowledgementsId") ? undefined : json["acknowledgementsId"],
    pdfDocumentsId: !exists(json, "pdfDocumentsId") ? undefined : json["pdfDocumentsId"],
    insuranceProgramId: !exists(json, "insuranceProgramId") ? undefined : json["insuranceProgramId"],
    coveredEquipment: !exists(json, "coveredEquipment")
      ? undefined
      : json["coveredEquipment"] === null
      ? null
      : (json["coveredEquipment"] as Array<any>).map(EquipmentFromJSON),
    coveredIndividuals: !exists(json, "coveredIndividuals")
      ? undefined
      : json["coveredIndividuals"] === null
      ? null
      : (json["coveredIndividuals"] as Array<any>).map(DriverFromJSON),
  };
}

export function ApplicationToJSON(value?: Application | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    qualified: value.qualified,
    active: value.active,
    partnerName: value.partnerName,
    speedGaugeScore: value.speedGaugeScore,
    desiredEffectiveDate:
      value.desiredEffectiveDate === undefined
        ? undefined
        : value.desiredEffectiveDate === null
        ? null
        : value.desiredEffectiveDate.toISOString(),
    applicationState: value.applicationState,
    applicationStatus: ApplicationStatusToJSON(value.applicationStatus),
    factoringApprovalStatus: FactoringApprovalStatusToJSON(value.factoringApprovalStatus),
    previousInsurer: value.previousInsurer,
    notificationPreference: NotificationPreferenceToJSON(value.notificationPreference),
    referralMethod: ReferralMethodToJSON(value.referralMethod),
    otherReferralMethod: value.otherReferralMethod,
    billingCycle: BillingCycleToJSON(value.billingCycle),
    qsAccountNumber: value.qsAccountNumber,
    intrastateFilingAuthority: value.intrastateFilingAuthority,
    stateSpecificLabel: StateSpecificValuesToJSON(value.stateSpecificLabel),
    ineligibleReason: value.ineligibleReason,
    stateSpecificInput: value.stateSpecificInput,
    coverages: CoveragesToJSON(value.coverages),
    owner: OwnerToJSON(value.owner),
    currentQuote: QuoteToJSON(value.currentQuote),
    business: BusinessToJSON(value.business),
    factors: FactorsToJSON(value.factors),
    acknowledgements: AcknowledgementsToJSON(value.acknowledgements),
    pdfDocuments: PdfDocumentsToJSON(value.pdfDocuments),
    insuranceProgram: InsuranceProgramToJSON(value.insuranceProgram),
    coveragesId: value.coveragesId,
    ownerId: value.ownerId,
    currentQuoteId: value.currentQuoteId,
    businessId: value.businessId,
    factorsId: value.factorsId,
    acknowledgementsId: value.acknowledgementsId,
    pdfDocumentsId: value.pdfDocumentsId,
    insuranceProgramId: value.insuranceProgramId,
    coveredEquipment:
      value.coveredEquipment === undefined
        ? undefined
        : value.coveredEquipment === null
        ? null
        : (value.coveredEquipment as Array<any>).map(EquipmentToJSON),
    coveredIndividuals:
      value.coveredIndividuals === undefined
        ? undefined
        : value.coveredIndividuals === null
        ? null
        : (value.coveredIndividuals as Array<any>).map(DriverToJSON),
  };
}
