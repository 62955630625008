import { CardField, EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { ELDProvider } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  containerClass: {
    paddingBottom: "0.625rem",
  },
}));

type Props = {
  eldProvider: string;
  otherEldProvider?: string;
  eldAdmin?: string;
  eldAccount?: string;
  onEdit: EditableDeletableCardProps["onEdit"];
};

const EldCard: React.FC<Props> = ({ eldProvider, otherEldProvider, eldAdmin, eldAccount, onEdit }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <EditableDeletableCard
      title={t("finish-application-page.eld-card.title")}
      titleVariant={"h2"}
      titleComponent={"h4"}
      containerClass={classes.containerClass}
      variant="edit"
      onEdit={onEdit}
      IconButtonProps={{ "aria-label": t("finish-application-page.eld-card.edit-button") }}
    >
      <Grid container>
        <CardField
          id="eldProvider"
          label={t("finish-application-page.eld-card.eld-provider")}
          values={[{ value: eldProvider === ELDProvider.Other ? otherEldProvider : eldProvider }]}
          nullFiller="---"
        />
        {eldProvider === ELDProvider.Other && (
          <CardField
            id="eldAdmin"
            label={t("finish-application-page.eld-card.eld-admin")}
            values={[{ value: eldAdmin }]}
            nullFiller="---"
          />
        )}
        <CardField
          id="eldAccount"
          label={t("finish-application-page.eld-card.eld-account")}
          values={[{ value: eldAccount ? eldAccount : "" }]}
          nullFiller="---"
        />
      </Grid>
    </EditableDeletableCard>
  );
};

export default EldCard;
