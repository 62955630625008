import { EmailInput, NumberPicker, NumericInput, Select, SliderInput, TooltipHelp } from "@chq/components";
import { State } from "@chq/enrollment-api";
import { Card, CardContent, makeStyles, Theme } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useRadiusOfOperations } from "../data/useRadiusOfOperation";
import { useStates } from "../data/useStates";

const useStyles = makeStyles((theme: Theme) => ({
  dotInput: {
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      "& div": {
        // This captures the tooltip button
        "& div": {
          left: 0,
        },
      },
    },
  },
  button: {
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginTop: "2rem",
    },
  },
  numberPicker: {
    whiteSpace: "nowrap",
  },
  emailInput: {
    paddingRight: "2rem",
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  slider: {
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 2.5rem 1rem 0rem",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0rem 1rem 2rem 0rem",
    },
    "& .MuiSlider-root": {
      margin: "0rem 0rem 0rem .5rem",
    },
    "& .MuiSlider-markLabel": {
      top: "40px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        top: "1.2rem",
      },
      [theme.breakpoints.up("sm")]: {
        top: "1.8rem",
      },
    },
  },
  infoNotice: {
    minHeight: "70px",
    backgroundColor: theme.palette.secondary.light,
    display: "flex",
    justifyContent: "center",
    padding: ".75rem",
    borderRadius: ".25rem",
    marginTop: "0.25rem",
  },
}));

export enum Fields {
  email = "email",
  dotNum = "dot-num",
  vehicleLocation = "vehicle-location",
  radiusOfOperation = "radius-of-operation",
  powerUnitCount = "power-unit-count",
}

export type QualificationFormProps = {
  [Fields.email]: string;
  [Fields.dotNum]: string;
  [Fields.vehicleLocation]: State | "";
  [Fields.radiusOfOperation]: number;
  [Fields.powerUnitCount]: number;
};

type Props = {
  email?: string;
  emailDisabled?: boolean;
  dotNum?: string;
  vehicleLocation?: State | "";
  radiusOfOperation?: number;
  powerUnitCount?: number;
  setPowerUnitError?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: FormikConfig<QualificationFormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.dotNum]: Yup.string().test(
      "length",
      t("errors.between-digits", { field: t(`qualification-form.dot-num.label`), min: "5", max: "8" }),
      (val) => (val ? val.toString().length >= 5 && val.toString().length <= 8 : true),
    ),
    [Fields.vehicleLocation]: Yup.string().required(
      t(`errors.required`, { field: t(`qualification-form.${Fields.vehicleLocation}.help-text-error`) }),
    ),
    [Fields.powerUnitCount]: Yup.number()
      .required(t(`errors.required`, { field: t(`qualification-form.${Fields.powerUnitCount}.help-text-error`) }))
      .typeError(t(`errors.required`, { field: t(`qualification-form.${Fields.powerUnitCount}.help-text-error`) })),
    [Fields.radiusOfOperation]: Yup.number()
      .required(t(`errors.required`, { field: t(`qualification-form.${Fields.radiusOfOperation}.help-text-error`) }))
      .min(50, t("qualification-form.radius-of-operation.error")),
    [Fields.email]: Yup.string()
      .email(t("errors.invalid-email"))
      .required(t("errors.required", { field: t("email-field.label") })),
  });
};

export const useFormikConfig = ({
  email = "",
  dotNum = "",
  vehicleLocation = "",
  radiusOfOperation = 0,
  powerUnitCount = 1,
}: Props = {}): Omit<FormikConfig<QualificationFormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.email]: email,
      [Fields.dotNum]: dotNum,
      [Fields.vehicleLocation]: vehicleLocation,
      [Fields.radiusOfOperation]: radiusOfOperation,
      [Fields.powerUnitCount]: powerUnitCount,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const QualificationForm: React.FC<Props> = ({ emailDisabled }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const states = useStates();
  const radiusOperations = useRadiusOfOperations();
  const minRadiusOfOperations = radiusOperations[0];
  const maxRadiusOfOperations = radiusOperations[radiusOperations.length - 1];
  const formik = useFormikContext<QualificationFormProps>();
  const marks = [
    {
      value: radiusOperations[0],
      label: "0",
    },
    {
      value: radiusOperations[1],
      label: "",
    },
    {
      value: radiusOperations[2],
      label: "100",
    },
    {
      value: radiusOperations[3],
      label: "",
    },
    {
      value: radiusOperations[4],
      label: "200",
    },
    {
      value: radiusOperations[5],
      label: "",
    },
    {
      value: radiusOperations[6],
      label: "300",
    },
    {
      value: radiusOperations[7],
      label: "",
    },
    {
      value: radiusOperations[8],
      label: "400",
    },
    {
      value: radiusOperations[9],
      label: "",
    },
    {
      value: radiusOperations[10],
      label: "500",
    },
    {
      value: radiusOperations[11],
      label: "500+",
    },
  ];

  return (
    <Card>
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <NumberPicker
            required
            min={1}
            id="powerUnitCount"
            name={Fields.powerUnitCount}
            color="primary"
            className={classes.numberPicker}
            label={t("qualification-form.power-unit-count.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.powerUnitCount]}
            error={formik.touched[Fields.powerUnitCount] && Boolean(formik.errors[Fields.powerUnitCount])}
            helperText={formik.touched[Fields.powerUnitCount] && formik.errors[Fields.powerUnitCount]}
          />
          <Select
            required
            fullWidth
            id="vehicleLocation"
            name={Fields.vehicleLocation}
            label={t("qualification-form.vehicle-location.label")}
            labelAdornment={
              <TooltipHelp title={t("qualification-form.vehicle-location.help-text").toString()} placement="left-end" />
            }
            items={states.map((state) => ({
              name: state.name,
              value: state.abv,
            }))}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.vehicleLocation]}
            error={formik.touched[Fields.vehicleLocation] && Boolean(formik.errors[Fields.vehicleLocation])}
            helperText={formik.touched[Fields.vehicleLocation] && formik.errors[Fields.vehicleLocation]}
          />
          <SliderInput
            id={Fields.radiusOfOperation}
            name={Fields.radiusOfOperation}
            label={t("qualification-form.radius-of-operation.label")}
            required
            fullWidth
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => (value <= 500 ? value + "mi" : "500+")}
            min={minRadiusOfOperations}
            max={maxRadiusOfOperations}
            step={null}
            marks={marks}
            onChangeCommitted={(_e, value) => {
              formik.setFieldTouched(Fields.radiusOfOperation);
              formik.setFieldValue(Fields.radiusOfOperation, value);
            }}
            onBlur={() => formik.setFieldTouched(Fields.radiusOfOperation)}
            value={formik.values[Fields.radiusOfOperation] || minRadiusOfOperations}
            error={formik.touched[Fields.radiusOfOperation] && Boolean(formik.errors[Fields.radiusOfOperation])}
            className={classes.slider}
            helperText={formik.touched[Fields.radiusOfOperation] && formik.errors[Fields.radiusOfOperation]}
          />
          <NumericInput
            fullWidth
            format="########"
            id="dotNum"
            name={Fields.dotNum}
            label={t("qualification-form.dot-num.label")}
            labelEmbellishment={t("qualification-form.dot-num.sub-label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.dotNum] && Boolean(formik.errors[Fields.dotNum])}
            value={formik.values[Fields.dotNum]}
            helperText={formik.touched[Fields.dotNum] && formik.errors[Fields.dotNum]}
          />
          <EmailInput
            required
            fullWidth
            id="email"
            name={Fields.email}
            label={t("email-field.label")}
            labelEmbellishment={t("email-field.sub-label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.email]}
            error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
            helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
            disabled={emailDisabled}
          />
        </form>
      </CardContent>
    </Card>
  );
};

export default QualificationForm;
