import {
  CurrencyInput,
  DeleteIcon,
  NumericInput,
  PlusCircleIcon as PlusCircle,
  Select,
  TextInput,
} from "@chq/components";
import { State } from "@chq/enrollment-api";
import { Button, Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { FieldArray, FormikConfig, FormikErrors, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useStates } from "../data/useStates";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: "40px",
    width: "auto",
  },
  iconButton: {
    padding: "0px",
  },
  title: {
    paddingTop: "2rem",
  },
  container: {
    background: theme.palette.grey[600],
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.5rem",
    borderBottom: `solid .0625rem ${theme.palette.grey[400]}`,
  },
  addIcon: {
    height: "40px",
    width: "auto",
  },
  addButton: {
    textTransform: "uppercase",
    fontSize: "1rem",
  },
  buttonGrid: {
    paddingTop: "1.625rem",
    paddingBottom: "2rem",
  },
  addButtonBackground: {
    background: "none",
  },
}));

export enum Fields {
  name = "name",
  mcNumber = "mc-number",
  addressLineOne = "address-line-one",
  addressLineTwo = "address-line-two",
  city = "city",
  state = "state",
  zip = "zip",
  sales = "sales",
}

export type ShipperBroker = {
  [Fields.name]: string;
  [Fields.mcNumber]: string;
  [Fields.addressLineOne]: string;
  [Fields.addressLineTwo]?: string;
  [Fields.city]: string;
  [Fields.state]: State | "";
  [Fields.zip]: string;
  [Fields.sales]?: string;
};

export type FormProps = {
  shipperBrokers: ShipperBroker[];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();
  return Yup.object({
    shipperBrokers: Yup.array().of(
      Yup.object({
        [Fields.name]: Yup.string().required(
          t(`errors.required`, { field: t(`factoring.shipper-broker-form.${Fields.name}.label`) }),
        ),
        [Fields.mcNumber]: Yup.string().test(
          "length",
          t("errors.between-digits", {
            field: t(`factoring.business-details.${Fields.mcNumber}.label`),
            min: "6",
            max: "8",
          }),
          (val) => (val ? val.toString().length >= 6 && val.toString().length <= 8 : true),
        ),
        [Fields.addressLineOne]: Yup.string().required(
          t(`errors.required`, { field: t(`factoring.shipper-broker-form.${Fields.addressLineOne}.label`) }),
        ),
        [Fields.addressLineTwo]: Yup.string(),
        [Fields.city]: Yup.string().required(
          t(`errors.required`, { field: t(`factoring.shipper-broker-form.${Fields.city}.label`) }),
        ),
        [Fields.state]: Yup.string().required(
          t(`errors.required`, { field: t(`factoring.shipper-broker-form.${Fields.state}.label`) }),
        ),
        [Fields.zip]: Yup.string().required(
          t(`errors.required`, { field: t(`factoring.shipper-broker-form.${Fields.zip}.label`) }),
        ),

        [Fields.sales]: Yup.string(),
      }),
    ),
  });
};

export const useFormikConfig = ({
  shipperBrokers: initialShipperBrokers = [
    {
      [Fields.name]: "",
      [Fields.mcNumber]: "",
      [Fields.addressLineOne]: "",
      [Fields.addressLineTwo]: "",
      [Fields.city]: "",
      [Fields.state]: "",
      [Fields.zip]: "",
      [Fields.sales]: "",
    },
  ],
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      shipperBrokers: initialShipperBrokers.length
        ? initialShipperBrokers
        : [
            {
              [Fields.name]: "",
              [Fields.mcNumber]: "",
              [Fields.addressLineOne]: "",
              [Fields.addressLineTwo]: "",
              [Fields.city]: "",
              [Fields.state]: "",
              [Fields.zip]: "",
              [Fields.sales]: "",
            },
          ],
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

type Props = {
  shipperBrokers?: ShipperBroker[];
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const FactoringShipperBrokerForm: React.FC<Props> = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const states = useStates();
  const formik = useFormikContext<FormProps>();
  return (
    <Paper elevation={0} className={classes.addButtonBackground}>
      <form onSubmit={formik.handleSubmit}>
        <FieldArray name={"shipperBrokers"}>
          {({ push, remove }) => {
            return (
              <>
                {formik.values.shipperBrokers.length > 0 &&
                  formik.values.shipperBrokers.map((shipperBroker, index) => {
                    const shipperTouched = formik.touched.shipperBrokers || [];
                    const shipperError = (formik.errors.shipperBrokers as FormikErrors<ShipperBroker>[]) || [];
                    return (
                      <Grid key={index} container spacing={1} className={classes.container}>
                        <Grid
                          item
                          container
                          direction="row"
                          alignItems="center"
                          justify="space-between"
                          className={classes.title}
                        >
                          <Grid item>
                            <Typography variant="h2">
                              {t("factoring.shipper-broker-form.title", { number: index + 1 })}
                            </Typography>
                          </Grid>
                          {(index !== 0 || formik.values.shipperBrokers.length > 1) && (
                            <Grid item>
                              <IconButton
                                aria-label={t(`factoring.shipper-broker-form.delete-icon`)}
                                className={classes.iconButton}
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="disabled" className={classes.icon} />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            required
                            fullWidth
                            label={t("factoring.shipper-broker-form.name.label")}
                            type="text"
                            id={Fields.name}
                            name={`shipperBrokers.${[index]}.${[Fields.name]}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shipperBrokers[index][Fields.name]}
                            error={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.name] &&
                              Boolean(shipperError[index] && shipperError[index][Fields.name])
                            }
                            helperText={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.name] &&
                              shipperError[index] &&
                              shipperError[index][Fields.name]
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <NumericInput
                            fullWidth
                            label={t("factoring.shipper-broker-form.mc-number.label")}
                            type="text"
                            format="#######"
                            id={Fields.mcNumber}
                            name={`shipperBrokers.${[index]}.${[Fields.mcNumber]}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shipperBrokers[index][Fields.mcNumber]}
                            error={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.mcNumber] &&
                              Boolean(shipperError[index] && shipperError[index][Fields.mcNumber])
                            }
                            helperText={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.mcNumber] &&
                              shipperError[index] &&
                              shipperError[index][Fields.mcNumber]
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            required
                            fullWidth
                            label={t("factoring.shipper-broker-form.address-line-one.label")}
                            type="text"
                            id={Fields.addressLineOne}
                            name={`shipperBrokers.${[index]}.${[Fields.addressLineOne]}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shipperBrokers[index][Fields.addressLineOne]}
                            error={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.addressLineOne] &&
                              Boolean(shipperError[index] && shipperError[index][Fields.addressLineOne])
                            }
                            helperText={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.addressLineOne] &&
                              shipperError[index] &&
                              shipperError[index][Fields.addressLineOne]
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            fullWidth
                            label={t("factoring.shipper-broker-form.address-line-two.label")}
                            type="text"
                            id={Fields.addressLineTwo}
                            name={`shipperBrokers.${[index]}.${[Fields.addressLineTwo]}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shipperBrokers[index][Fields.addressLineTwo]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            required
                            fullWidth
                            label={t("factoring.shipper-broker-form.city.label")}
                            type="text"
                            id={Fields.city}
                            name={`shipperBrokers.${[index]}.${[Fields.city]}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shipperBrokers[index][Fields.city]}
                            error={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.city] &&
                              Boolean(shipperError[index] && shipperError[index][Fields.city])
                            }
                            helperText={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.city] &&
                              shipperError[index] &&
                              shipperError[index][Fields.city]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            required
                            fullWidth
                            label={t("factoring.shipper-broker-form.state.label")}
                            items={states.map((state) => ({
                              name: state.abv,
                              value: state.abv,
                            }))}
                            id={Fields.state}
                            name={`shipperBrokers.${[index]}.${[Fields.state]}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shipperBrokers[index][Fields.state]}
                            error={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.state] &&
                              Boolean(shipperError[index] && shipperError[index][Fields.state])
                            }
                            helperText={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.state] &&
                              shipperError[index] &&
                              shipperError[index][Fields.state]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <NumericInput
                            format="#####"
                            required
                            fullWidth
                            label={t("factoring.shipper-broker-form.zip.label")}
                            type="text"
                            id={Fields.zip}
                            name={`shipperBrokers.${[index]}.${[Fields.zip]}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shipperBrokers[index][Fields.zip]}
                            error={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.zip] &&
                              Boolean(shipperError[index] && shipperError[index][Fields.zip])
                            }
                            helperText={
                              shipperTouched[index] &&
                              shipperTouched[index][Fields.zip] &&
                              shipperError[index] &&
                              shipperError[index][Fields.zip]
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CurrencyInput
                            fullWidth
                            label={t("factoring.shipper-broker-form.sales.label")}
                            type="text"
                            id={Fields.sales}
                            name={`shipperBrokers.${[index]}.${[Fields.sales]}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shipperBrokers[index][Fields.sales]}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}

                <Grid item className={classes.buttonGrid}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<PlusCircle className={classes.addIcon} />}
                    onClick={() => {
                      push({
                        [Fields.name]: "",
                        [Fields.mcNumber]: "",
                        [Fields.addressLineOne]: "",
                        [Fields.addressLineTwo]: "",
                        [Fields.city]: "",
                        [Fields.state]: "",
                        [Fields.zip]: "",
                        [Fields.sales]: "",
                      });
                    }}
                    className={classes.addButton}
                  >
                    {t("factoring.shipper-broker-form.add-button")}
                  </Button>
                </Grid>
              </>
            );
          }}
        </FieldArray>
      </form>
    </Paper>
  );
};

export default FactoringShipperBrokerForm;
