/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GenerateTokenChangePhoneRequest
 */
export interface GenerateTokenChangePhoneRequest {
  /**
   *
   * @type {string}
   * @memberof GenerateTokenChangePhoneRequest
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof GenerateTokenChangePhoneRequest
   */
  phoneNumber?: string | null;
}

export function GenerateTokenChangePhoneRequestFromJSON(json: any): GenerateTokenChangePhoneRequest {
  return GenerateTokenChangePhoneRequestFromJSONTyped(json, false);
}

export function GenerateTokenChangePhoneRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenerateTokenChangePhoneRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
  };
}

export function GenerateTokenChangePhoneRequestToJSON(value?: GenerateTokenChangePhoneRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    phoneNumber: value.phoneNumber,
  };
}
