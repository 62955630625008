/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  BusinessDetailsRequest,
  BusinessDetailsRequestToJSON,
  FactoringApplication,
  FactoringApplicationApiResponse,
  FactoringApplicationApiResponseFromJSON,
  FactoringApplicationCreateRequest,
  FactoringApplicationCreateRequestToJSON,
  FactoringApplicationToJSON,
  FactoringCustomer,
  FactoringCustomerToJSON,
  FactoringOwner,
  FactoringOwnerToJSON,
  ModelApiResponse,
  ModelApiResponseFromJSON,
} from "../models";
import * as runtime from "../runtime";

export interface FactoringApiV10FactoringApplicationAddFactoringBusinessDetailsPutRequest {
  applicationId?: number;
  businessDetailsRequest?: BusinessDetailsRequest;
}

export interface FactoringApiV10FactoringApplicationAddFactoringCustomersPutRequest {
  applicationId?: number;
  factoringCustomer?: Array<FactoringCustomer>;
}

export interface FactoringApiV10FactoringApplicationAddFactoringOwnersPutRequest {
  applicationId?: number;
  factoringOwner?: Array<FactoringOwner>;
}

export interface FactoringApiV10FactoringApplicationCreateFactoringApplicationPostRequest {
  factoringApplicationCreateRequest?: FactoringApplicationCreateRequest;
}

export interface FactoringApiV10FactoringApplicationGetApplicationFromIdGetRequest {
  id?: number;
}

export interface FactoringApiV10FactoringApplicationPutRequest {
  factoringApplication?: FactoringApplication;
}

/**
 *
 */
export class FactoringApplicationApi extends runtime.BaseAPI {
  /**
   * Adds business details to a factoring application
   */
  async factoringApiV10FactoringApplicationAddFactoringBusinessDetailsPutRaw(
    requestParameters: FactoringApiV10FactoringApplicationAddFactoringBusinessDetailsPutRequest,
  ): Promise<runtime.ApiResponse<FactoringApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringApplication/AddFactoringBusinessDetails`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: BusinessDetailsRequestToJSON(requestParameters.businessDetailsRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FactoringApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Adds business details to a factoring application
   */
  async factoringApiV10FactoringApplicationAddFactoringBusinessDetailsPut(
    requestParameters: FactoringApiV10FactoringApplicationAddFactoringBusinessDetailsPutRequest,
  ): Promise<FactoringApplicationApiResponse> {
    const response = await this.factoringApiV10FactoringApplicationAddFactoringBusinessDetailsPutRaw(requestParameters);
    return await response.value();
  }

  /**
   * Adds business details to a factoring application
   */
  async factoringApiV10FactoringApplicationAddFactoringCustomersPutRaw(
    requestParameters: FactoringApiV10FactoringApplicationAddFactoringCustomersPutRequest,
  ): Promise<runtime.ApiResponse<FactoringApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringApplication/AddFactoringCustomers`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.factoringCustomer?.map(FactoringCustomerToJSON),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FactoringApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Adds business details to a factoring application
   */
  async factoringApiV10FactoringApplicationAddFactoringCustomersPut(
    requestParameters: FactoringApiV10FactoringApplicationAddFactoringCustomersPutRequest,
  ): Promise<FactoringApplicationApiResponse> {
    const response = await this.factoringApiV10FactoringApplicationAddFactoringCustomersPutRaw(requestParameters);
    return await response.value();
  }

  /**
   * Adds owners to a factoring application
   */
  async factoringApiV10FactoringApplicationAddFactoringOwnersPutRaw(
    requestParameters: FactoringApiV10FactoringApplicationAddFactoringOwnersPutRequest,
  ): Promise<runtime.ApiResponse<FactoringApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringApplication/AddFactoringOwners`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.factoringOwner?.map(FactoringOwnerToJSON),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FactoringApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Adds owners to a factoring application
   */
  async factoringApiV10FactoringApplicationAddFactoringOwnersPut(
    requestParameters: FactoringApiV10FactoringApplicationAddFactoringOwnersPutRequest,
  ): Promise<FactoringApplicationApiResponse> {
    const response = await this.factoringApiV10FactoringApplicationAddFactoringOwnersPutRaw(requestParameters);
    return await response.value();
  }

  /**
   * Completes the application using the given id
   */
  async factoringApiV10FactoringApplicationCompleteApplicationPostRaw(): Promise<
    runtime.ApiResponse<ModelApiResponse>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringApplication/CompleteApplication`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelApiResponseFromJSON(jsonValue));
  }

  /**
   * Completes the application using the given id
   */
  async factoringApiV10FactoringApplicationCompleteApplicationPost(): Promise<ModelApiResponse> {
    const response = await this.factoringApiV10FactoringApplicationCompleteApplicationPostRaw();
    return await response.value();
  }

  /**
   * Creates a new FactoringApplication
   */
  async factoringApiV10FactoringApplicationCreateFactoringApplicationPostRaw(
    requestParameters: FactoringApiV10FactoringApplicationCreateFactoringApplicationPostRequest,
  ): Promise<runtime.ApiResponse<FactoringApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringApplication/CreateFactoringApplication`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FactoringApplicationCreateRequestToJSON(requestParameters.factoringApplicationCreateRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FactoringApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Creates a new FactoringApplication
   */
  async factoringApiV10FactoringApplicationCreateFactoringApplicationPost(
    requestParameters: FactoringApiV10FactoringApplicationCreateFactoringApplicationPostRequest,
  ): Promise<FactoringApplicationApiResponse> {
    const response = await this.factoringApiV10FactoringApplicationCreateFactoringApplicationPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Gets the application for the current user
   */
  async factoringApiV10FactoringApplicationGetApplicationForCurrentUserGetRaw(): Promise<
    runtime.ApiResponse<FactoringApplicationApiResponse>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringApplication/GetApplicationForCurrentUser`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FactoringApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets the application for the current user
   */
  async factoringApiV10FactoringApplicationGetApplicationForCurrentUserGet(): Promise<FactoringApplicationApiResponse> {
    const response = await this.factoringApiV10FactoringApplicationGetApplicationForCurrentUserGetRaw();
    return await response.value();
  }

  /**
   * Gets the application with the given id
   */
  async factoringApiV10FactoringApplicationGetApplicationFromIdGetRaw(
    requestParameters: FactoringApiV10FactoringApplicationGetApplicationFromIdGetRequest,
  ): Promise<runtime.ApiResponse<FactoringApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringApplication/GetApplicationFromId`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FactoringApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets the application with the given id
   */
  async factoringApiV10FactoringApplicationGetApplicationFromIdGet(
    requestParameters: FactoringApiV10FactoringApplicationGetApplicationFromIdGetRequest,
  ): Promise<FactoringApplicationApiResponse> {
    const response = await this.factoringApiV10FactoringApplicationGetApplicationFromIdGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates the provided factoring application
   */
  async factoringApiV10FactoringApplicationPutRaw(
    requestParameters: FactoringApiV10FactoringApplicationPutRequest,
  ): Promise<runtime.ApiResponse<FactoringApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/factoring/api/v1.0/FactoringApplication`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: FactoringApplicationToJSON(requestParameters.factoringApplication),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FactoringApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Updates the provided factoring application
   */
  async factoringApiV10FactoringApplicationPut(
    requestParameters: FactoringApiV10FactoringApplicationPutRequest,
  ): Promise<FactoringApplicationApiResponse> {
    const response = await this.factoringApiV10FactoringApplicationPutRaw(requestParameters);
    return await response.value();
  }
}
