import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import NextButton from "./buttons/next-button";

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ mobileBackgroundImage, desktopBackgroundImage }: Props) => {
    return {
      [theme.breakpoints.up("xs")]: {
        backgroundSize: "contain",
        backgroundPosition: "-50% 0%",
        backgroundImage: `url(${mobileBackgroundImage})`,
      },
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.up("sm")]: {
        backgroundImage: `url(${desktopBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "50% 53%",
      },
      boxShadow: "rgba(255, 255, 255, 0.5) 0 0 0 100rem inset",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100vh",
    };
  },
  textContainer: {
    textAlign: "center",
    paddingTop: "13rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
      paddingBottom: "1rem",
      paddingTop: "5rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "8rem",
      paddingBottom: "3rem",
      width: "25rem",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30rem",
      paddingBottom: "3rem",
      width: "25rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "3rem",
      paddingBottom: "5rem",
      width: "25rem",
    },
  },
  errorCode: {
    width: "3rem",
    margin: "auto",
    paddingBottom: "0.25rem",
    borderBottom: "black solid 2px",
  },
  text: {
    color: theme.palette.common.black,
  },
  textPadding: {
    padding: ".625rem 1.5rem 2rem 1.5rem",
  },
  headerText: {
    lineHeight: "1.5rem",
    paddingTop: "1rem",
  },
  buttonContainer: {
    background: theme.palette.secondary.dark,
    justifyContent: "center",
    paddingBottom: "3rem",
  },
  button: {
    padding: "0rem 1.5rem 0rem 1.5rem",
    marginTop: "-1.5rem",
  },
}));

type Props = {
  nextButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  numbersText?: string;
  headerText?: string;
  mainInfoText?: string;
  buttonText?: string;
  mobileBackgroundImage?: string;
  desktopBackgroundImage?: string;
};

const NotAuthorizedPage: React.FC<Props> = ({
  nextButtonOnClick,
  numbersText,
  headerText,
  mainInfoText,
  buttonText,
  mobileBackgroundImage,
  desktopBackgroundImage,
}) => {
  const classes = useStyles({ mobileBackgroundImage, desktopBackgroundImage });

  return (
    <div className={classes.root}>
      <Grid container item className={classes.textContainer}>
        <Grid item xs={12}>
          <Typography variant="h2" component="h1" className={classnames(classes.errorCode, classes.text)}>
            {numbersText}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h2"
            className={classnames(classes.textPadding, classes.text, classes.headerText)}
          >
            {headerText}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="h3" className={classnames(classes.textPadding, classes.text)}>
            {mainInfoText}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item className={classes.buttonContainer}>
        <Grid item xs={12} sm={6} md={4} className={classes.button}>
          <NextButton fullWidth variant="contained" onClick={nextButtonOnClick}>
            {buttonText}
          </NextButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotAuthorizedPage;
