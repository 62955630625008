/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  BooleanApiResponse,
  BooleanApiResponseFromJSON,
  BooleanApiResponseToJSON,
} from "../models";

export interface ApiV10AddressCheckAddressAccuracyPostRequest {
  address?: Address;
}

/**
 *
 */
export class AddressApi extends runtime.BaseAPI {
  /**
   */
  async apiV10AddressCheckAddressAccuracyPostRaw(
    requestParameters: ApiV10AddressCheckAddressAccuracyPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Address/CheckAddressAccuracy`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AddressToJSON(requestParameters.address),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10AddressCheckAddressAccuracyPost(
    requestParameters: ApiV10AddressCheckAddressAccuracyPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10AddressCheckAddressAccuracyPostRaw(requestParameters);
    return await response.value();
  }
}
