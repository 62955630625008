import { FactoringApplication, FactoringApplicationApi, FactoringApplicationApiResponse } from "@chq/factoring-api";
import { useMutation, UseMutationResult } from "react-query";
import { useCachedFactoringApplication } from ".";
import { useFactoringApi } from "../useFactoringApi";

export const useEditFactoringApplication = (): UseMutationResult<
  FactoringApplicationApiResponse,
  Error | unknown,
  FactoringApplication
> => {
  const factoringApplicationApi = useFactoringApi(FactoringApplicationApi);
  const { updateApplication } = useCachedFactoringApplication();
  const mutation = useMutation(
    async (request: FactoringApplication) => {
      const response = await factoringApplicationApi.factoringApiV10FactoringApplicationPut({
        factoringApplication: request,
      });
      return response;
    },
    {
      onSuccess: (response) => {
        updateApplication(response.data!);
      },
    },
  );
  return mutation;
};
