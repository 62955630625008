import { ApplicationApi, AuthenticationApi, UserAddPasswordRequest } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { RouteNames } from "../../utils/route-names";
import { useCachedApplication } from "../enrollment/useGetApplication";
import { useApi } from "../useApi";

export enum VerifiedStatus {
  verfiyEmailSentPasswordCreated = 0,
  accountIsVerifiedPasswordCreated,
}

export const useAddPassword = (): UseMutationResult<VerifiedStatus, Error | unknown, UserAddPasswordRequest> => {
  const authenticationApi = useApi(AuthenticationApi);
  const applicationApi = useApi(ApplicationApi);
  const { application } = useCachedApplication();

  const mutation = useMutation(async (request: UserAddPasswordRequest) => {
    await authenticationApi.apiV10AuthenticationUserAddPasswordPost({
      userAddPasswordRequest: request,
    });
    const { data } = await authenticationApi.apiV10AuthenticationUserStatusPost({
      userStatusRequest: { userId: request.userId },
    });
    if (data?.emailConfirmed) {
      await applicationApi.apiV10ApplicationPut({
        application: { ...application, applicationState: RouteNames.finishApplication },
      });
      return VerifiedStatus.accountIsVerifiedPasswordCreated;
    } else if (application?.business?.contactEmail) {
      await applicationApi.apiV10ApplicationResumeApplicationPost({ email: application?.business?.contactEmail });
      await applicationApi.apiV10ApplicationPut({
        application: { ...application, applicationState: RouteNames.success },
      });
      return VerifiedStatus.verfiyEmailSentPasswordCreated;
    } else {
      throw new Error("Application contact email missing");
    }
  });
  return mutation;
};
