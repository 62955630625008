import { useAuth } from "@chq/authentication";
import { Policy, PolicyApi } from "@chq/policy-management-api";
import { useQuery, UseQueryResult } from "react-query";
import { useApi } from "./usePolicyManagementApi";

export const policyCacheKey = (identity?: string) => {
  return ["policy", identity];
};

export const useGetPolicy = (): UseQueryResult<Policy, Error> => {
  const policyApi = useApi(PolicyApi);
  const { user } = useAuth();
  const query = useQuery<Policy, Error>(policyCacheKey(user?.sub), async () => {
    const response = await policyApi.portalApiV10PolicyGet({
      identityId: user?.sub,
    });
    if (response.error) {
      throw new Error("Policy not found: " + response.error?.message);
    }
    return response.data!;
  });
  return query;
};
