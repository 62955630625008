import { NextButton } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import SuccessCard from "../../../components/success-card";
import { useGetApplication } from "../../../data/enrollment";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  nextButtonContainer: {
    padding: "0rem 0.5rem 2rem 0.5rem",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  container: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  box: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const AccountSuccessPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  const { data: application } = useGetApplication(RouteNames.successAcount);

  if (application?.insuranceProgram?.active === false) {
    return <Redirect to={routes.enrollment.quote.inactive.path} />;
  }

  return (
    <>
      <Grid className={classes.box}>
        <Grid item className={classes.container}>
          <SuccessCard verified />
        </Grid>
        <Grid item className={classes.nextButtonContainer}>
          <NextButton
            fullWidth
            variant="contained"
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "continueQuoteEvent2",
                  continueQuoteText: "Continue Quote",
                },
              });
              history.push(routes.enrollment.finishApplication.path);
            }}
          >
            {t("enrollment.verify-success-page.continue")}
          </NextButton>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountSuccessPage;
