export enum RouteNames {
  home = "/enrollment",
  basicInfo = "/enrollment/basic-info",
  equipment = "/enrollment/equipment",
  drivers = "/enrollment/drivers",
  coverages = "/enrollment/coverage",
  quoteOverview = "/enrollment/quote",
  saveQuote = "/enrollment/save-quote",
  verify = "/enrollment/verify",
  success = "/enrollment/success",
  successAcount = "/enrollment/account-success",
  finishApplication = "/enrollment/finish-application",
  payment = "/enrollment/payment",
  review = "/enrollment/review-application",
  quotePending = "/enrollment/quote/pending",
  quoteBound = "/enrollment/quote/bound",
  policyManagementHome = "/policy-management",
  policyManagementCoverages = "/policy-management/coverages",
  policyManagementEditCoverages = "/policy-management/coverages/edit",
  policyManagementEquipment = "/policy-management/equipment",
  policyManagementEditEquipment = "/policy-management/equipment/edit",
  policyManagementReviewEquipmentAndCoverages = "/policy-management/review-equipment-and-coverages",
  policyManagementSubmittedChanges = "/policy-management/review-equipment-and-coverages/success",
  policyManagementSpeedgauge = "/policy-management/speedgauge",
  policyManagementPersonalInfo = "/policy-management/personal-info",
  policyManagementLogOut = "/policy-management/logout",
}
