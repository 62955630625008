import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const ArrowDownBoldIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.104 7.2H13.704V12H16.608L11.904 16.704L7.2 12H10.104V7.2Z" />
    </SvgIcon>
  );
};

export default ArrowDownBoldIcon;
