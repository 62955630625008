import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const SendIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M5.4 17.4L18 12L5.4 6.60001V10.8L14.4 12L5.4 13.2V17.4Z" />
    </SvgIcon>
  );
};

export default SendIcon;
