import { CardField, EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { FactoringOwner } from "@chq/factoring-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { fullName } from "../utils/full-name";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
  },
  mainContainer: {
    border: `1px solid ${theme.palette.grey[500]}`,
    background: theme.palette.grey[600],
    padding: "1.125rem .625rem 1rem .625rem",
  },
  container: {
    paddingTop: "1rem",
  },
  owner: {
    paddingBottom: "0.5rem",
  },
  beforeDivider: {
    paddingBottom: "1rem",
  },
  divider: {
    borderBottom: `solid .125rem ${theme.palette.grey[400]}`,
  },
}));

export type Props = {
  owners: FactoringOwner[];
  onEdit: EditableDeletableCardProps["onEdit"];
};

const FactoringOwnerInformationCard: React.FC<Props> = ({ owners, onEdit }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <EditableDeletableCard
      variant="edit"
      title={t("factoring.owner-information-form.title")}
      titleVariant="h2"
      titleComponent="h4"
      className={classNames(classes.title, classes.mainContainer)}
      elevation={1}
      onEdit={onEdit}
      border={false}
      IconButtonProps={{ "aria-label": t("factoring.owner-information-form.owner-information-card.edit-icon") }}
    >
      {owners
        .filter((owner) => owner.firstName !== "")
        .map((owner, index) => (
          <Grid
            key={`owner-${index + 1}`}
            container
            className={classNames(classes.container, {
              [classes.divider]: owners.length - 1 !== index && owners[owners.length - 1].firstName !== "",
            })}
          >
            <CardField
              id={`owner-number-${index + 1}`}
              label={t("factoring.owner-information-form.owner-information-card.owner", { number: index + 1 })}
              labelColor="textPrimary"
              values={[{ value: "" }]}
              className={classes.owner}
            />
            <CardField
              id={`owner-name-${index + 1}`}
              label={t("factoring.owner-information-form.owner-information-card.name")}
              values={[{ value: fullName(owner.firstName || "", owner.lastName || "") }]}
            />
            <CardField
              id={`owner-email-${index + 1}`}
              label={t("factoring.owner-information-form.email-address.label")}
              values={[{ value: owner.ownerEmail }]}
            />
            <CardField
              id={`owner-authorized-signor-${index + 1}`}
              label={t("factoring.owner-information-form.signor-party.label")}
              values={[{ value: owner.signorParty ? t("common.yes") : t("common.no") }]}
              className={classNames({ [classes.beforeDivider]: owners.length - 1 !== index })}
            />
          </Grid>
        ))}
    </EditableDeletableCard>
  );
};

export default FactoringOwnerInformationCard;
