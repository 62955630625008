/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  Policy,
  PolicyFromJSON,
  PolicyFromJSONTyped,
  PolicyToJSON,
} from "./";

/**
 *
 * @export
 * @interface PolicyApiResponse
 */
export interface PolicyApiResponse {
  /**
   *
   * @type {Policy}
   * @memberof PolicyApiResponse
   */
  data?: Policy;
  /**
   *
   * @type {ErrorResponse}
   * @memberof PolicyApiResponse
   */
  error?: ErrorResponse;
}

export function PolicyApiResponseFromJSON(json: any): PolicyApiResponse {
  return PolicyApiResponseFromJSONTyped(json, false);
}

export function PolicyApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : PolicyFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function PolicyApiResponseToJSON(value?: PolicyApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: PolicyToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
