/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum StripeAccountStatus {
  Unknown = "Unknown",
  New = "New",
  Validated = "Validated",
  Verified = "Verified",
  VerificationFailed = "Verification Failed",
  Errored = "Errored",
}

export function StripeAccountStatusFromJSON(json: any): StripeAccountStatus {
  return StripeAccountStatusFromJSONTyped(json, false);
}

export function StripeAccountStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeAccountStatus {
  return json as StripeAccountStatus;
}

export function StripeAccountStatusToJSON(value?: StripeAccountStatus | null): any {
  return value as any;
}
