import { Grid, Typography, Link, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatPhoneNumber } from "../utils/phone-number-format";

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    textAlign: "center",
  },
  container: {
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    backgroundColor: theme.palette.grey[500],
  },
}));

const FleetErrorEmailCard: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.item}>
        <Typography variant="body1">{t("fleet-error-email-card.error-email")}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.item}>
        <Link variant="h4" href={`mailto:${t("fleet-error-email-card.email")}`}>
          {t("fleet-error-email-card.email")}
        </Link>
      </Grid>
      <Grid item xs={12} className={classes.item}>
        <Typography variant="body1">{t("fleet-error-email-card.call-us")}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.item}>
        <Link variant="h4" href={"tel:866-621-4145"}>
          {formatPhoneNumber("8666214145")}
        </Link>
      </Grid>
    </Grid>
  );
};

export default FleetErrorEmailCard;
