/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  currentPassword?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  newPassword?: string | null;
}

export function ChangePasswordRequestFromJSON(json: any): ChangePasswordRequest {
  return ChangePasswordRequestFromJSONTyped(json, false);
}

export function ChangePasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePasswordRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    currentPassword: !exists(json, "currentPassword") ? undefined : json["currentPassword"],
    newPassword: !exists(json, "newPassword") ? undefined : json["newPassword"],
  };
}

export function ChangePasswordRequestToJSON(value?: ChangePasswordRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    currentPassword: value.currentPassword,
    newPassword: value.newPassword,
  };
}
