/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FactoringOwner
 */
export interface FactoringOwner {
  /**
   *
   * @type {number}
   * @memberof FactoringOwner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FactoringOwner
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringOwner
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof FactoringOwner
   */
  ownerEmail?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof FactoringOwner
   */
  signorParty?: boolean;
  /**
   *
   * @type {number}
   * @memberof FactoringOwner
   */
  factoringApplicationId?: number;
}

export function FactoringOwnerFromJSON(json: any): FactoringOwner {
  return FactoringOwnerFromJSONTyped(json, false);
}

export function FactoringOwnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): FactoringOwner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    ownerEmail: !exists(json, "ownerEmail") ? undefined : json["ownerEmail"],
    signorParty: !exists(json, "signorParty") ? undefined : json["signorParty"],
    factoringApplicationId: !exists(json, "factoringApplicationId") ? undefined : json["factoringApplicationId"],
  };
}

export function FactoringOwnerToJSON(value?: FactoringOwner | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    ownerEmail: value.ownerEmail,
    signorParty: value.signorParty,
    factoringApplicationId: value.factoringApplicationId,
  };
}
