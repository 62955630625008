import { makeStyles, Slider, SliderProps, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import FormControl, { FormControlProps } from "./form-control";

export type SliderInputProps = Omit<FormControlProps, "children" | "variant" | "onChange"> &
  Omit<SliderProps, "orientation" | "onChange"> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (event: React.ChangeEvent<any>, value: number | number[]) => void;
  };

const useStyles = makeStyles((theme: Theme) => ({
  slider: {
    "& .MuiSlider-rail": {
      top: "30px",
      backgroundColor: theme.palette.grey[100],
    },
    "& .MuiSlider-track": {
      top: "30px",
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiSlider-mark": {
      top: "26px",
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      backgroundColor: theme.palette.grey[100],
      transform: "translateX(-50%)",
    },
    "& .MuiSlider-markActive": {
      top: "26px",
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      backgroundColor: theme.palette.grey[100],
      opacity: 1,
      zIndex: -1,
      transform: "translateX(-50%)",
    },
    "& .MuiSlider-markLabel": {
      top: "0px",
    },
    "& .MuiSlider-thumb": {
      top: "30px",
    },
  },
}));

const SliderInput: React.FC<SliderInputProps> = ({
  classes,
  className,
  color = "primary",
  disabled = false,
  error = false,
  FormHelperTextProps,
  fullWidth = false,
  focused,
  helperText,
  hiddenLabel,
  id,
  InputLabelProps,
  label,
  labelAdornment,
  labelEmbellishment,
  required = false,
  margin = "normal",
  size,
  ...SliderProps
}) => {
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;
  const sliderClass = useStyles();
  return (
    <FormControl
      className={classNames(classes?.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      focused={focused}
      hiddenLabel={hiddenLabel}
      required={required}
      color={color}
      FormHelperTextProps={FormHelperTextProps}
      helperTextId={helperTextId}
      helperText={helperText}
      labelId={inputLabelId}
      label={label}
      labelAdornment={labelAdornment}
      labelEmbellishment={labelEmbellishment}
      margin={margin}
      id={id}
      InputLabelProps={InputLabelProps}
      size={size}
    >
      <Slider
        id={id}
        aria-labelledby={inputLabelId}
        aria-describedby={helperTextId}
        color={color}
        disabled={disabled}
        orientation="horizontal"
        className={sliderClass.slider}
        {...SliderProps}
      />
    </FormControl>
  );
};

export default SliderInput;
