import { ComponentConfirmOverlay, EditableDeletableCard, SaveButton } from "@chq/components";
import { EquipmentType } from "@chq/enrollment-api";
import { Grid, IconButtonProps, makeStyles, Theme } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import * as _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TrailerPowerUnitForm, { TrailerPowerUnitFormProps, useFormikConfig } from "./trailer-power-unit-form";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    padding: "0px",
  },
  trashIcon: {
    height: "40px",
    width: "auto",
  },
  addButton: {
    padding: "1.125rem 0rem",
  },
  card: {
    position: "relative",
  },
}));

export type Props = {
  variant: EquipmentType;
  formContainerClass?: string;
  policyState?: string;
  onDelete?: () => void;
  unitCount?: number;
  onSubmit?: FormikConfig<TrailerPowerUnitFormProps>["onSubmit"];
  IconButtonProps?: Omit<IconButtonProps, "onClick">;
  vehicleNumber?: string;
  vin?: string;
  make?: string;
  model?: string;
  year?: number;
  registrationZip?: string;
  destination?: string;
  ooIC?: string;
  registered?: string;
  whereIsUnitRegistered?: string;
  coverageOption?: string;
  statedValue?: number;
  gapCoverage?: string;
};

export const TrailerPowerUnitFormWrapper: React.FC<Props> = ({
  formContainerClass,
  variant,
  policyState,
  onDelete,
  unitCount = 1,
  onSubmit,
  IconButtonProps,
  vehicleNumber = "",
  vin = "",
  make = "",
  model = "",
  year = undefined,
  registrationZip = undefined,
  destination = "",
  ooIC = "",
  whereIsUnitRegistered = "",
  coverageOption = "",
  statedValue = undefined,
  gapCoverage = "",
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const kababCaseVariant = _.kebabCase(variant);
  const formConfig = useFormikConfig({
    vehicleNumber,
    vin,
    make,
    model,
    year,
    registrationZip,
    destination,
    ooIC,
    whereIsUnitRegistered,
    coverageOption,
    statedValue,
    gapCoverage,
  });

  const [showConfirmationOverlay, setShowConfirmationOverlay] = useState<boolean>(false);

  const deleteConfirmation = () => {
    if (onDelete) {
      onDelete();
    }
    setShowConfirmationOverlay(!showConfirmationOverlay);
  };

  return (
    <div className={classes.card}>
      <Formik
        initialValues={{
          ...formConfig.initialValues,
        }}
        enableReinitialize={true}
        validationSchema={formConfig.validationSchema}
        validateOnMount={true}
        onSubmit={(values, formikHelpers) => {
          onSubmit && onSubmit(values, formikHelpers);
        }}
      >
        {(formik) => {
          const checkIfErrorIsShowing = () => {
            const errors = Object.keys(formik.errors);
            const isErrorShowing = Object.keys(formik.touched).map((touched) => {
              return errors.includes(touched);
            });
            return isErrorShowing.includes(true);
          };

          return (
            <EditableDeletableCard
              className={formContainerClass}
              title={t(`trailer-power-unit-form.${kababCaseVariant}-title`, { number: unitCount })}
              error={checkIfErrorIsShowing()}
              variant="delete"
              onDelete={() => setShowConfirmationOverlay(!showConfirmationOverlay)}
              IconButtonProps={{
                "aria-label": t("trailer-power-unit-form.trashIcon"),
                ...IconButtonProps,
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <TrailerPowerUnitForm policyState={policyState} />
                <Grid item>
                  <SaveButton
                    label={t(`trailer-power-unit-form.${kababCaseVariant}-add-button`)}
                    variant="outlined"
                    fullWidth
                    type="submit"
                    disabled={!formik.isValid}
                    formikValid={formik.isValid}
                  />
                </Grid>
              </form>
            </EditableDeletableCard>
          );
        }}
      </Formik>
      {showConfirmationOverlay && (
        <ComponentConfirmOverlay
          confirmText={t("common.delete")}
          confirmAction={() => deleteConfirmation()}
          cancelText={t("common.cancel-delete")}
          cancelAction={() => setShowConfirmationOverlay(!showConfirmationOverlay)}
        />
      )}
    </div>
  );
};

export default TrailerPowerUnitFormWrapper;
