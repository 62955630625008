import { NumericInput, Select, XIcon as CloseIcon } from "@chq/components";
import { Button, Grid, IconButton, InputAdornment, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useGetApplicationReview, useGetCurrentStripeCustomer } from "../data/enrollment";
const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    padding: "1rem",
    marginBottom: "18rem",
  },
  greyBackground: {
    backgroundColor: theme.palette.grey[200],
    padding: "0.5rem",
  },
  submitButton: {
    minHeight: "56px",
  },
  errorMessageGeneric: {
    color: theme.palette.error.main,
    fontSize: ".75rem",
  },
  blueAsterisk: {
    color: theme.palette.primary.main,
    fontSize: "1.75rem",
    marginRight: "0.2rem",
    transform: "translate(0rem, 0.35rem);",
  },
  inputAdornment: {
    paddingTop: "0.125rem",
  },
}));

export enum Fields {
  account = "account",
  firstDeposit = "first-deposit",
  secondDeposit = "second-deposit",
}

export type VerifyBankAccountFormProps = {
  [Fields.account]: string;
  [Fields.firstDeposit]: string;
  [Fields.secondDeposit]: string;
};

type Props = {
  account?: string;
  firstDeposit?: string;
  secondDeposit?: string;
  error?: Error;
  onClose?: () => void;
  onSubmit?: FormikConfig<VerifyBankAccountFormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.account]: Yup.string().required(
      t(`errors.required`, {
        field: t(
          `finish-application.payment-method-form.verify-account.verify-bank-account-form.${Fields.account}.label`,
        ),
      }),
    ),
    [Fields.firstDeposit]: Yup.string().required(
      t(`errors.required`, {
        field: t(
          `finish-application.payment-method-form.verify-account.verify-bank-account-form.${Fields.firstDeposit}.label`,
        ),
      }),
    ),
    [Fields.secondDeposit]: Yup.string().required(
      t(`errors.required`, {
        field: t(
          `finish-application.payment-method-form.verify-account.verify-bank-account-form.${Fields.secondDeposit}.label`,
        ),
      }),
    ),
  });
};

const VerifyBankAccountForm: React.FC<Props> = ({
  account = "",
  firstDeposit = "",
  secondDeposit = "",
  error,
  onClose,
  onSubmit,
}) => {
  const { data: applicationReview } = useGetApplicationReview();

  useGetCurrentStripeCustomer();
  const [t] = useTranslation();
  const classes = useStyles();
  const validationSchema = useValidationSchema();
  const formik = useFormik<VerifyBankAccountFormProps>({
    initialValues: {
      [Fields.account]: account,
      [Fields.firstDeposit]: firstDeposit,
      [Fields.secondDeposit]: secondDeposit,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" justify="center" className={classes.formContainer}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2">
                {t("finish-application.payment-method-form.verify-account.verify-bank-account-form.title")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label={t(
                  "finish-application.payment-method-form.verify-account.verify-bank-account-form.close-icon-label",
                )}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              id={Fields.account}
              name={Fields.account}
              label={t(
                `finish-application.payment-method-form.verify-account.verify-bank-account-form.${Fields.account}.label`,
              )}
              items={[
                {
                  name: applicationReview?.bankAccountInfo,
                  value: applicationReview?.bankAccountInfo as string,
                },
              ]}
              value={formik.values[Fields.account]}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.account] && Boolean(formik.errors[Fields.account])}
              helperText={formik.touched[Fields.account] && formik.errors[Fields.account]}
            />
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <span className={classes.blueAsterisk}>{"*"}</span>
            <Typography variant="h4">
              {t(`finish-application.payment-method-form.verify-account.verify-bank-account-form.deposits.label`)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {t("finish-application.payment-method-form.verify-account.verify-bank-account-form.deposits.subtitle")}
            </Typography>
          </Grid>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs={6}>
              <NumericInput
                id={Fields.firstDeposit}
                name={Fields.firstDeposit}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.inputAdornment}>
                      $
                    </InputAdornment>
                  ),
                }}
                fullWidth
                decimalScale={0}
                format="0.##"
                value={formik.values[Fields.firstDeposit]}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.firstDeposit] && Boolean(formik.errors[Fields.firstDeposit])}
                helperText={formik.touched[Fields.firstDeposit] && formik.errors[Fields.firstDeposit]}
              />
            </Grid>
            <Grid item xs={6}>
              <NumericInput
                id={Fields.secondDeposit}
                name={Fields.secondDeposit}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={classes.inputAdornment}>
                      $
                    </InputAdornment>
                  ),
                }}
                fullWidth
                decimalScale={0}
                format="0.##"
                value={formik.values[Fields.secondDeposit]}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.secondDeposit] && Boolean(formik.errors[Fields.secondDeposit])}
                helperText={formik.touched[Fields.secondDeposit] && formik.errors[Fields.secondDeposit]}
              />
            </Grid>
          </Grid>
          {error && (
            <Grid item container>
              <Grid item xs={12}>
                <Typography className={classes.errorMessageGeneric} component="p" variant="h4">
                  {error.message}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Paper square elevation={0} className={classes.greyBackground}>
            <Grid container xs={12} justify="center">
              <Grid item xs={12} sm={8}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!formik.isValid || !formik.dirty}
                  type="submit"
                  className={classes.submitButton}
                  disableElevation
                >
                  {t("finish-application.payment-method-form.verify-account.verify-bank-account-form.submit-button")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>
    </>
  );
};

export default VerifyBankAccountForm;
