import { Header, NotAuthorizedPage } from "@chq/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SignImageMobile from "../../../images/dead-end-sign-mobile.png";
import SignImage from "../../../images/dead-end-sign.png";
import Footer from "../../components/footer";
import { routes } from "../../routes";

const UnauthorizedPage: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <>
      <Header
        chqTitleAccess={t("common.chq-logo")}
        chqAriaLabel={t("common.chq-logo")}
        headerBarTitleAccess={t("common.middle-bar")}
        headerBarAriaLabel={t("common.middle-bar")}
      />
      <NotAuthorizedPage
        nextButtonOnClick={() => history.push(routes.enrollment.path)}
        mobileBackgroundImage={SignImageMobile}
        desktopBackgroundImage={SignImage}
        numbersText={t("unauthorized-page.401")}
        headerText={t("unauthorized-page.header-text")}
        mainInfoText={t("unauthorized-page.main-info")}
        buttonText={t("unauthorized-page.home-button")}
      />
      <Footer onlyCarrierHQ />
    </>
  );
};

export default UnauthorizedPage;
