import { Grid, makeStyles, Paper, PaperProps, Theme, Typography } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { BlackCircleIcon as BlackCircle, FailIcon, SuccessIcon } from "./icons";
import { EligibilityStates } from "./utils/eligibility-states";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    padding: "1rem",
  },
  statusContainer: ({ status }: Props) => {
    const colorMap = {
      [EligibilityStates.ELIGIBLE]: {
        color: theme.palette.success.main,
        backgroundColor: fade(theme.palette.success.main, 0.1),
      },
      [EligibilityStates.LOADING]: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light,
      },
      [EligibilityStates.INELIGIBLE]: {
        color: theme.palette.error.main,
        backgroundColor: fade(theme.palette.error.main, 0.1),
      },
    };
    return {
      color: colorMap[status].color || theme.palette.secondary.main,
      backgroundColor: colorMap[status].backgroundColor || theme.palette.secondary.light,
      "& .MuiTypography-root": {
        color: colorMap[status].color || theme.palette.secondary.main,
      },
    };
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    width: "3rem",
    height: "auto",
  },
}));

type Props = PaperProps & {
  status: EligibilityStates;
  statusText: string;
};
const EligibilityStatus: React.FC<Props> = ({ status, statusText, ...PaperProps }) => {
  const classes = useStyles({ status, statusText });

  const iconMap = {
    [EligibilityStates.ELIGIBLE]: <SuccessIcon className={classes.icon} />,
    [EligibilityStates.LOADING]: <BlackCircle className={classes.icon} />,
    [EligibilityStates.INELIGIBLE]: <FailIcon className={classes.icon} />,
  };

  const IconComponent = iconMap[status];

  return (
    <Paper {...PaperProps} className={classNames(classes.statusContainer, PaperProps.className)} elevation={0}>
      <Grid container className={classes.container}>
        <Grid item className={classes.iconContainer}>
          {IconComponent}
        </Grid>
        <Grid item>
          <Typography variant="h4">{statusText}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EligibilityStatus;
