import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const SuccessIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.6 12.276L17.136 10.608L17.34 8.4L15.174 7.908L14.04 6L12 6.876L9.95999 6L8.82599 7.908L6.65999 8.394L6.86399 10.602L5.39999 12.276L6.86399 13.944L6.65999 16.158L8.82599 16.65L9.95999 18.558L12 17.676L14.04 18.552L15.174 16.644L17.34 16.152L17.136 13.944L18.6 12.276ZM10.8 15.276L8.39999 12.876L9.24599 12.03L10.8 13.578L14.754 9.624L15.6 10.476L10.8 15.276Z" />
    </SvgIcon>
  );
};

export default SuccessIcon;
