import { Header } from "@chq/components";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TruckLoadingAnimation } from "../../components/animations/truckLoadingAnimation";
import Footer from "../components/footer";

const useStyles = makeStyles((theme) => ({
  loaderPage: {
    justifyContent: "center",
    flexGrow: 1,
  },
  truckAnimationWrapper: {
    padding: "0 1rem 5rem",
    marginTop: "2rem",
    marginBottom: "2rem",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0 1rem 1rem",
    },
  },
  title: {
    marginBottom: "2rem",
    textAlign: "center",
  },
  loadingImage: {
    width: "100%",
    margin: "auto",
  },
  loadingImageMobile: {
    borderRadius: ".5rem",
  },
  loadingImageContainer: {
    [theme.breakpoints.up("xs")]: {
      "& > #loading-image-desktop": {
        display: "block",
      },
      "& > #loading-image-mobile": {
        display: "none",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& > #loading-image-mobile": {
        display: "block",
      },
      "& > #loading-image-desktop": {
        display: "none",
      },
    },
  },
  footer: {
    position: "absolute",
    bottom: 0,
  },
}));

type Props = {
  header?: boolean;
  footer?: boolean;
  setAsLoading: boolean; //This prop is to to indicate whether a user wants to only display "Loading", or to rotate through the 5 translation loading options
};

export const FullScreenLoader: React.FC<Props> = ({ header = false, footer = false, setAsLoading }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [state, setState] = useState(
    setAsLoading
      ? { title: "enrollment.quote-overview-page.loading-only", count: 1 }
      : { title: "enrollment.quote-overview-page.loading.1", count: 1 },
  );

  useEffect(() => {
    if (state.count > 4) {
      return;
    }

    const timer = setTimeout(() => {
      setState((prevState) => {
        return {
          title: setAsLoading
            ? "enrollment.quote-overview-page.loading-only"
            : `enrollment.quote-overview-page.loading.${prevState.count + 1}`,
          count: prevState.count + 1,
        };
      });
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [state.title, state.count, setAsLoading]);

  return (
    <Grid className={classes.loaderPage} wrap="nowrap" container direction="column">
      {header && (
        <Header
          chqTitleAccess={t("common.chq-logo")}
          chqAriaLabel={t("common.chq-logo")}
          headerBarTitleAccess={t("common.middle-bar")}
          headerBarAriaLabel={t("common.middle-bar")}
        />
      )}
      <Grid item className={classes.truckAnimationWrapper}>
        <TruckLoadingAnimation />
      </Grid>
      {state.title && (
        <Grid item className={classes.title}>
          <Typography variant="h4" className="classes.titleText">
            {t(state.title)}
          </Typography>
        </Grid>
      )}

      {footer && (
        <div className={classes.footer}>
          <Footer />
        </div>
      )}
    </Grid>
  );
};
