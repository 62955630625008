import { PlaidApi, StringApiResponse } from "@chq/enrollment-api";
import { useQuery, UseQueryResult } from "react-query";
import { useApi } from "../useApi";

export const useGetPlaidToken = (): UseQueryResult<StringApiResponse, Error | unknown> => {
  const plaidDemoApi = useApi(PlaidApi);
  const query = useQuery("get-token", async () => {
    const response = await plaidDemoApi.apiV10PlaidLinkTokenGet();
    return response;
  });
  return query;
};
