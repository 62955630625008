/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BooleanApiResponse,
  BooleanApiResponseFromJSON,
  BooleanApiResponseToJSON,
  Driver,
  DriverFromJSON,
  DriverToJSON,
  DriverApiResponse,
  DriverApiResponseFromJSON,
  DriverApiResponseToJSON,
} from "../models";

export interface ApiV10DriverDeleteRequest {
  id?: number;
}

export interface ApiV10DriverGetRequest {
  id?: number;
}

export interface ApiV10DriverPostRequest {
  applicationId?: number;
  driver?: Driver;
}

export interface ApiV10DriverPutRequest {
  driver?: Driver;
}

/**
 *
 */
export class DriverApi extends runtime.BaseAPI {
  /**
   * Updates an Enrollment Application
   */
  async apiV10DriverDeleteRaw(
    requestParameters: ApiV10DriverDeleteRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Driver`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10DriverDelete(requestParameters: ApiV10DriverDeleteRequest): Promise<BooleanApiResponse> {
    const response = await this.apiV10DriverDeleteRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10DriverGetRaw(requestParameters: ApiV10DriverGetRequest): Promise<runtime.ApiResponse<DriverApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Driver`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverApiResponseFromJSON(jsonValue));
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10DriverGet(requestParameters: ApiV10DriverGetRequest): Promise<DriverApiResponse> {
    const response = await this.apiV10DriverGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Adds a new driver to the provided application
   */
  async apiV10DriverPostRaw(
    requestParameters: ApiV10DriverPostRequest,
  ): Promise<runtime.ApiResponse<DriverApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Driver`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DriverToJSON(requestParameters.driver),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverApiResponseFromJSON(jsonValue));
  }

  /**
   * Adds a new driver to the provided application
   */
  async apiV10DriverPost(requestParameters: ApiV10DriverPostRequest): Promise<DriverApiResponse> {
    const response = await this.apiV10DriverPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10DriverPutRaw(
    requestParameters: ApiV10DriverPutRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Driver`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: DriverToJSON(requestParameters.driver),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10DriverPut(requestParameters: ApiV10DriverPutRequest): Promise<BooleanApiResponse> {
    const response = await this.apiV10DriverPutRaw(requestParameters);
    return await response.value();
  }
}
