import { AuthenticationApi, TokenPasswordResetRequest } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useTokenPasswordReset = (): UseMutationResult<void, Error | unknown, TokenPasswordResetRequest> => {
  const authenticationApi = useApi(AuthenticationApi);
  const mutation = useMutation(async (request: TokenPasswordResetRequest) => {
    const response = await authenticationApi.apiV10AuthenticationTokenPasswordResetPost({
      tokenPasswordResetRequest: request,
    });

    return response;
  });
  return mutation;
};
