import {
  ApiV10DriverDeleteRequest,
  ApiV10DriverPostRequest,
  ApiV10DriverPutRequest,
  DriverApi,
  Driver,
} from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useAddDriver = (): UseMutationResult<Driver | undefined, Error, ApiV10DriverPostRequest> => {
  const driverApi = useApi(DriverApi);

  const mutation = useMutation<Driver | undefined, Error, ApiV10DriverPostRequest>(
    async (request: ApiV10DriverPostRequest) => {
      const response = await driverApi.apiV10DriverPost(request);

      if (response.error) {
        throw new Error(response.error.message!);
      }

      return response.data;
    },
  );
  return mutation;
};

export const useEditDriver = (): UseMutationResult<void, Error, ApiV10DriverPutRequest> => {
  const driverApi = useApi(DriverApi);

  const mutation = useMutation<void, Error, ApiV10DriverPutRequest>(async (request: ApiV10DriverPutRequest) => {
    const response = await driverApi.apiV10DriverPut(request);

    if (response.error) {
      throw new Error(response.error.message!);
    }

    return;
  });
  return mutation;
};

export const useDeleteDriver = (): UseMutationResult<void, Error, ApiV10DriverDeleteRequest> => {
  const driverApi = useApi(DriverApi);

  const mutation = useMutation<void, Error, ApiV10DriverDeleteRequest>(async (request: ApiV10DriverDeleteRequest) => {
    const response = await driverApi.apiV10DriverDelete(request);

    if (response.error) {
      throw new Error(response.error.message!);
    }

    return;
  });
  return mutation;
};
