import { Checkbox, DownloadIcon, EditableDeletableCard } from "@chq/components";
import { CircularProgress, fade, Grid, IconButton, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.2rem",
    borderRadius: "0.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    width: "100%",
  },
  containerClass: {
    padding: "0",
  },
  accordion: {
    marginLeft: "2rem",
    marginTop: "-0.5rem",
    marginRight: "0.5rem",
    "& .MuiTypography-body1": {
      margin: ".5rem 0",
    },
    "& .MuiAccordionSummary-root": {
      backgroundColor: theme.palette.divider,
      marginTop: "1rem",
      minHeight: "2.5rem",
    },
    "& .MuiGrid-item": {
      marginTop: "0",
    },
    "& .MuiAccordionDetails-root": {
      padding: "0.5rem",
    },
    "& .MuiSvgIcon-root": {
      height: "2rem",
    },
  },
  checkbox: {
    marginBottom: ".5rem",
    "& .MuiFormControlLabel-root": {
      alignItems: "self-start",
    },
    "& .MuiFormControlLabel-label": {
      paddingTop: "0.5rem",
    },
    "& a.MuiIconButton-root": {
      margin: "-0.5rem",
      marginRight: "0",
    },
  },
  divider: {
    width: "100%",
    margin: "1rem 0",
  },
  icon: {
    height: "40px",
    width: "auto",
  },
  iconButton: {
    padding: "0",
  },
  heading: {
    marginBottom: "1.5rem",
  },
  nameDateContain: {
    margin: "1.5rem auto 0",
    maxWidth: 500,
  },
  subheading: {
    fontWeight: 700,
    marginBottom: "1rem",
  },
  signatureBlock: {
    fontStyle: "italic",
  },
  underline: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

export enum Fields {
  eConsent = "e-consent",
}

type Props = ConditionalProps & {
  eConsent?: boolean;
  signEDeliveryConsent?: () => void;
  eConsentSignedDownload?: string | null;
  deleteDocument?: () => void;
};

type ConditionalProps = {
  voluntaryEConsentState?: boolean;
  signingIsLoading?: boolean;
};

export type FormProps = {
  [Fields.eConsent]: boolean;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useValidationSchema = (voluntaryEConsentState: boolean) => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.eConsent]: voluntaryEConsentState
      ? yup.boolean()
      : yup
          .boolean()
          .oneOf(
            [true],
            t("errors.must-be-checked", {
              field: t("enrollment.quote-overview-page.e-consent-section.e-consent"),
            }),
          )
          .required(t("errors.required", { field: t("enrollment.quote-overview-page.e-consent-section.e-consent") })),
  });
};

export const useFormikConfig = ({
  eConsent: initialEConsent = false,
  voluntaryEConsentState = false,
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema(voluntaryEConsentState);
  return {
    initialValues: {
      [Fields.eConsent]: initialEConsent,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const EDeliveryConsentForm: React.FC<Props> = ({
  voluntaryEConsentState,
  signEDeliveryConsent,
  eConsentSignedDownload,
  signingIsLoading,
  deleteDocument,
}) => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();
  const eConsentUnsignedURL = "/templates/EDeliveryConsentForm.pdf";

  return (
    <EditableDeletableCard
      variant="default"
      border={false}
      className={classes.container}
      containerClass={classes.containerClass}
    >
      <Grid container direction="column">
        <form onSubmit={formik.handleSubmit}>
          <Grid item className={classes.checkbox}>
            <Checkbox
              id={Fields.eConsent}
              name={Fields.eConsent}
              label={
                <Grid>
                  {signingIsLoading ? (
                    <Grid>
                      <CircularProgress
                        color="inherit"
                        size="2rem"
                        aria-label={t("common.circular-progress-aria-label")}
                      />
                    </Grid>
                  ) : (
                    <Grid>
                      {formik.values[Fields.eConsent] ? (
                        <>
                          <IconButton
                            aria-label={t(`enrollment.quote-overview-page.e-consent-section.e-consent-download-icon`)}
                            className={classes.iconButton}
                          >
                            <Link target="_blank" rel="noreferrer" href={String(eConsentSignedDownload)}>
                              <DownloadIcon color="disabled" className={classes.icon} />
                            </Link>
                          </IconButton>
                          <label>
                            {t("enrollment.quote-overview-page.e-consent-section.e-consent-checked-1")}
                            <Link target="_blank" rel="noreferrer" href={String(eConsentSignedDownload)}>
                              <Typography color="primary" component="span">
                                {t("enrollment.quote-overview-page.e-consent-section.e-consent-link")}
                              </Typography>
                            </Link>
                            {t("enrollment.quote-overview-page.e-consent-section.e-consent-checked-2")}
                          </label>
                        </>
                      ) : (
                        <label>
                          {t("enrollment.quote-overview-page.e-consent-section.e-consent-unchecked-1")}
                          <Link target="_blank" rel="noreferrer" href={String(eConsentUnsignedURL)}>
                            <Typography component="span" color="primary" className={classes.underline}>
                              {t("enrollment.quote-overview-page.e-consent-section.e-consent-link")}
                            </Typography>
                          </Link>
                          {voluntaryEConsentState
                            ? t("enrollment.quote-overview-page.e-consent-section.voluntary-state-unchecked-2")
                            : t("enrollment.quote-overview-page.e-consent-section.e-consent-unchecked-2")}
                        </label>
                      )}
                    </Grid>
                  )}
                </Grid>
              }
              onChange={(changeEvent) => {
                if (changeEvent.target.checked) {
                  signEDeliveryConsent && signEDeliveryConsent();
                } else {
                  deleteDocument && deleteDocument();
                }
                formik.handleChange(changeEvent);
              }}
              onBlur={formik.handleBlur}
              value="on"
            />
          </Grid>
        </form>
      </Grid>
    </EditableDeletableCard>
  );
};

export default EDeliveryConsentForm;
