import { Button, CircularProgress, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: "auto",
    color: theme.palette.success.main,
  },
  button: {
    padding: "1.2rem",
    marginBottom: "1rem",
  },
}));

interface PlaidProps {
  token: string;
  onSuccess: PlaidLinkOnSuccess;
  onEvent: PlaidLinkOnEvent;
  onExit: PlaidLinkOnExit;
  isUpdatingStripeAccount?: boolean;
}

export const ChangeYourBankAccountLink: FunctionComponent<PlaidProps> = ({
  token,
  onSuccess,
  onEvent,
  onExit,
  isUpdatingStripeAccount,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    env: window.env?.REACT_APP_PLAID_ENVIRONMENT || "sandbox",
    product: ["auth"],
    onEvent,
    onExit,
  };

  const { open } = usePlaidLink(config);

  return (
    <Button
      variant="contained"
      fullWidth
      className={classes.button}
      disabled={isUpdatingStripeAccount}
      onClick={() => open()}
    >
      {isUpdatingStripeAccount ? (
        <CircularProgress color="inherit" size="2rem" aria-label={t("common.circular-progress-aria-label")} />
      ) : (
        t("payment-method-card.change-bank-account")
      )}
    </Button>
  );
};
export default ChangeYourBankAccountLink;
