import { ApplicationApi, ApplicationReview } from "@chq/enrollment-api";
import { useCallback } from "react";
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "react-query";
import { useApi } from "../useApi";

const getCurrentApplicationReviewCacheKey = () => "current-application-review";

export const useGetApplicationReview = (
  applicationState?: string,
  options?: UseQueryOptions<ApplicationReview, Error>,
): UseQueryResult<ApplicationReview, Error> => {
  const applicationApi = useApi(ApplicationApi);

  const query = useQuery<ApplicationReview, Error>(
    getCurrentApplicationReviewCacheKey(),
    async () => {
      if (applicationState) {
        await applicationApi.apiV10ApplicationUpdateApplicationStatePut({ applicationState });
      }
      const response = await applicationApi.apiV10ApplicationGetApplicationReviewGet();
      if (response.error) {
        throw new Error(response.error.message || "Unable to find application");
      }
      return response.data!;
    },
    options,
  );
  return query;
};

export const useGetCachedApplicationReview = () => {
  const cache = useQueryClient();
  return cache.getQueryData<ApplicationReview>(getCurrentApplicationReviewCacheKey());
};

export const useUpdateCachedApplicationReview = () => {
  const cache = useQueryClient();

  return useCallback(
    (application: ApplicationReview) => {
      cache.setQueryData(getCurrentApplicationReviewCacheKey(), application);
    },
    [cache],
  );
};

export const useCachedApplicationReview = () => {
  const application = useGetCachedApplicationReview();
  const updateApplication = useUpdateCachedApplicationReview();
  return { application, updateApplication };
};
