import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Fields as BusinessDetailsFields } from "../../components/factoring-business-details";
import { Fields as BasicInformationFields } from "../../components/factoring-contact-form";
import { Fields as OwnerInformationFields } from "../../components/factoring-owner-information-form";
import * as actions from "./actions";
import { FactoringForms, FactoringFormsStateType } from "./types";

export const initialFactoringFormsState: FactoringFormsStateType = {
  [FactoringForms.basicInformation]: {
    [BasicInformationFields.firstName]: "",
    [BasicInformationFields.lastName]: "",
    [BasicInformationFields.contactTitle]: "",
    [BasicInformationFields.contactEmail]: "",
    [BasicInformationFields.monthlyRevenue]: undefined,
    [BasicInformationFields.truckCount]: 1,
    [BasicInformationFields.currentFactoring]: "",
    completed: false,
  },
  [FactoringForms.businessDetails]: {
    [BusinessDetailsFields.businessName]: "",
    [BusinessDetailsFields.phoneNumber]: "",
    [BusinessDetailsFields.ein]: "",
    [BusinessDetailsFields.dotNumber]: "",
    [BusinessDetailsFields.mcNumber]: "",
    [BusinessDetailsFields.addressLineOne]: "",
    [BusinessDetailsFields.addressLineTwo]: "",
    [BusinessDetailsFields.city]: "",
    [BusinessDetailsFields.state]: "",
    [BusinessDetailsFields.zip]: "",
    completed: undefined,
  },
  [FactoringForms.ownerInformation]: { completed: undefined },
  [FactoringForms.shipperBrokerInfo]: { shipperBrokers: [], completed: undefined },
  [FactoringForms.documents]: {
    documents: ["Article.png", "Certificate-of-Ownership.png", "w9.jpg"],
    completed: undefined,
  },
  [FactoringForms.bankInformation]: { completed: undefined },
};

export const reducer = reducerWithInitialState(initialFactoringFormsState)
  .case(actions.startBasicInformationForm, (state) => ({
    ...state,
    [FactoringForms.basicInformation]: {
      ...state[FactoringForms.basicInformation],
      completed: false,
    },
  }))
  .case(actions.completeBasicInformationForm, (state, values) => ({
    ...state,
    [FactoringForms.basicInformation]: {
      ...values,
      completed: true,
    },
  }))
  .case(actions.startBusinessDetailsForm, (state) => ({
    ...state,
    [FactoringForms.businessDetails]: {
      ...state[FactoringForms.businessDetails],
      completed: false,
    },
  }))
  .case(actions.completeBusinessDetailsForm, (state, values) => ({
    ...state,
    [FactoringForms.businessDetails]: {
      ...values,
      completed: true,
    },
  }))
  .case(actions.startOwnerInformationForm, (state) => ({
    ...state,
    [FactoringForms.ownerInformation]: {
      ...state[FactoringForms.ownerInformation],
      completed: false,
    },
  }))
  .case(actions.completeOwnerInformationForm, (state, values) => ({
    ...state,
    [FactoringForms.ownerInformation]: {
      ...values,
      owners: [
        {
          firstName: values[OwnerInformationFields.firstName]!,
          lastName: values[OwnerInformationFields.lastName]!,
          emailAddress: values[OwnerInformationFields.emailAddress]!,
          signorParty: values[OwnerInformationFields.signorParty]!,
        },
        {
          firstName: values[OwnerInformationFields.firstName2]!,
          lastName: values[OwnerInformationFields.lastName2]!,
          emailAddress: values[OwnerInformationFields.emailAddress2]!,
          signorParty: values[OwnerInformationFields.signorParty2]!,
        },
      ],
      completed: true,
    },
  }))
  .case(actions.startShipperBrokerInfo, (state) => ({
    ...state,
    [FactoringForms.shipperBrokerInfo]: {
      ...state[FactoringForms.shipperBrokerInfo],
      completed: false,
    },
  }))
  .case(actions.completeEmptyShipperBrokerInfo, (state) => ({
    ...state,
    [FactoringForms.shipperBrokerInfo]: {
      hauling: "0",
      completed: true,
    },
  }))
  .case(actions.completeShipperBrokerInfo, (state, values) => ({
    ...state,
    [FactoringForms.shipperBrokerInfo]: {
      ...values,
      hauling: "1",
      shipperBrokers: values.shipperBrokers,
      completed: true,
    },
  }))
  .case(actions.startDocumentsForm, (state) => ({
    ...state,
    [FactoringForms.documents]: {
      ...state[FactoringForms.documents],
      completed: false,
    },
  }))
  .case(actions.completeDocumentsForm, (state) => ({
    ...state,
    [FactoringForms.documents]: {
      ...state[FactoringForms.documents],
      completed: true,
    },
  }))
  .case(actions.startBankInformationForm, (state) => ({
    ...state,
    [FactoringForms.bankInformation]: {
      completed: false,
    },
  }))
  .case(actions.completeBankInformationForm, (state) => ({
    ...state,
    [FactoringForms.bankInformation]: {
      completed: true,
    },
  }));
