import { booleanToYesNoString, CardField, EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { Coverages } from "@chq/policy-management-api";
import { createStyles, Grid, lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Fields as CoveragesFields } from "../add-coverage-form";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      "& > div": {
        paddingTop: "0.5rem",
        paddingBottom: "0rem",
        "& > div": {
          display: "flex",
          justifyContent: "center",
        },
        "& > div > div > .MuiTypography-root": {
          [theme.breakpoints.up("sm")]: {
            width: "25rem",
          },
          textAlign: "center",
        },
      },
    },
    labelIndent: {
      paddingLeft: "0.625rem",
    },
    uninsuredMotoristValue: {
      alignSelf: "flex-end",
    },
    trailerInterchangeContainer: {
      paddingBottom: "0.625rem",
      paddingTop: "1rem",
    },
    middleContainer: {
      paddingBottom: "0.625rem",
      paddingTop: "0.625rem",
    },
    lastContainer: {
      paddingTop: "0.625rem",
      paddingBottom: "1rem",
    },
    border: {
      borderBottom: `solid 1px ${lighten(theme.palette.secondary.main, 0.52)}`,
    },
  }),
);

export type Props = {
  editedFields?: string[];
  isEditable?: boolean;
  className?: string;
  coverages?: Coverages;
  cargoInsurance?: boolean | null;
  cargoInsuranceLimit?: number | null;
  cargoInsuranceDeductible?: number | null;
  cargoInsuranceReeferBreakdown?: boolean | null;
  onEdit?: EditableDeletableCardProps["onEdit"];
};

const CoveragesCard: React.FC<Props> = ({
  editedFields = [],
  isEditable = false,
  className,
  onEdit,
  coverages,
  cargoInsurance,
  cargoInsuranceLimit,
  cargoInsuranceDeductible,
  cargoInsuranceReeferBreakdown,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <EditableDeletableCard
      title={t("policy-management-page.coverages-page.coverages-card.title")}
      titleVariant={"h4"}
      variant={isEditable ? "edit" : "default"}
      className={classNames(classes.container, className)}
      IconButtonProps={
        isEditable
          ? { "aria-label": t("policy-management-page.review-coverages-and-equipment-page.coverages-card-edit-icon") }
          : undefined
      }
      onEdit={onEdit}
      border={false}
      elevation={1}
      bannerText={
        editedFields.length > 0 ? t("policy-management-page.coverages-page.coverages-card.banner-text") : undefined
      }
      bannerVariant={editedFields.length > 0 ? "primary" : undefined}
    >
      <Grid container className={classNames(classes.trailerInterchangeContainer, classes.border)}>
        <CardField
          id={"trailer-interchange"}
          label={t("policy-management-page.coverages-page.coverages-card.trailer-interchange")}
          values={[
            {
              value: t(booleanToYesNoString(coverages?.trailerInterchangeCoverage)),
              valueColor: editedFields.includes(CoveragesFields.trailerInterchange)
                ? coverages?.trailerInterchangeCoverage
                  ? "primary"
                  : "error"
                : undefined,
            },
          ]}
          labelColor={editedFields.includes(CoveragesFields.trailerInterchange) ? "primary" : undefined}
        />
        {booleanToYesNoString(coverages?.trailerInterchangeCoverage) === "common.yes" && (
          <CardField
            id={"coverage-limit"}
            labelClass={classes.labelIndent}
            label={t("policy-management-page.coverages-page.coverages-card.coverage-limit")}
            values={[
              {
                value: t("policy-management-page.coverages-page.coverages-card.coverage-limit-value", {
                  money: coverages?.trailerInterchangeCoverageLimit,
                }),
                valueColor: editedFields.includes(CoveragesFields.trailerCoverageLimit) ? "primary" : undefined,
              },
            ]}
            labelColor={editedFields.includes(CoveragesFields.trailerCoverageLimit) ? "primary" : undefined}
          />
        )}
      </Grid>
      <Grid container className={classNames(classes.middleContainer, classes.border)}>
        <CardField
          id={"hired-auto"}
          label={t("policy-management-page.coverages-page.coverages-card.hired-auto")}
          values={[
            {
              value: t(booleanToYesNoString(coverages?.hiredAutoCovers)),
              valueColor: editedFields.includes(CoveragesFields.hiredAuto)
                ? coverages?.hiredAutoCovers
                  ? "primary"
                  : "error"
                : undefined,
            },
          ]}
          labelColor={editedFields.includes(CoveragesFields.hiredAuto) ? "primary" : undefined}
        />
      </Grid>
      <Grid container className={classNames(classes.middleContainer, classes.border)}>
        <CardField
          id={"uninsured-motorist"}
          label={t("policy-management-page.coverages-page.coverages-card.uninsured-motorist")}
          values={[
            {
              value: t(booleanToYesNoString(coverages?.umUimCoverage)),
              valueColor: editedFields.includes(CoveragesFields.uninsuredMotorist)
                ? coverages?.umUimCoverage
                  ? "primary"
                  : "error"
                : undefined,
            },
          ]}
          labelColor={editedFields.includes(CoveragesFields.uninsuredMotorist) ? "primary" : undefined}
        />
      </Grid>
      <Grid container className={classes.lastContainer}>
        <CardField
          id={"cargo-insurance"}
          label={t("policy-management-page.coverages-page.coverages-card.cargo-insurance")}
          values={[
            {
              value: t(booleanToYesNoString(cargoInsurance)),
              valueColor: editedFields.includes(CoveragesFields.cargoInsurance)
                ? cargoInsurance
                  ? "primary"
                  : "error"
                : undefined,
            },
          ]}
          labelColor={editedFields.includes(CoveragesFields.cargoInsurance) ? "primary" : undefined}
        />
        {booleanToYesNoString(cargoInsurance) === "common.yes" && cargoInsuranceReeferBreakdown !== undefined && (
          <>
            <CardField
              id={"limit"}
              labelClass={classes.labelIndent}
              label={t("policy-management-page.coverages-page.coverages-card.limit")}
              values={[
                {
                  value: t("common.currencyWithout", { money: cargoInsuranceLimit }),
                  valueColor: editedFields.includes(CoveragesFields.cargoLimit) ? "primary" : undefined,
                },
              ]}
              labelColor={editedFields.includes(CoveragesFields.cargoLimit) ? "primary" : undefined}
            />
            <CardField
              id={"deductible"}
              labelClass={classes.labelIndent}
              label={t("policy-management-page.coverages-page.coverages-card.deductible")}
              values={[
                {
                  value: t("common.currencyWithout", { money: cargoInsuranceDeductible }),
                  valueColor: editedFields.includes(CoveragesFields.cargoDeductible) ? "primary" : undefined,
                },
              ]}
              labelColor={editedFields.includes(CoveragesFields.cargoDeductible) ? "primary" : undefined}
            />
            <CardField
              id={"reefer-breakdown"}
              labelClass={classes.labelIndent}
              label={t("policy-management-page.coverages-page.coverages-card.reefer-breakdown")}
              values={[
                {
                  value: t(booleanToYesNoString(cargoInsuranceReeferBreakdown)),
                  valueColor: editedFields.includes(CoveragesFields.reeferBreakdown)
                    ? cargoInsuranceReeferBreakdown
                      ? "primary"
                      : "error"
                    : undefined,
                },
              ]}
              labelColor={editedFields.includes(CoveragesFields.reeferBreakdown) ? "primary" : undefined}
            />
          </>
        )}
      </Grid>
    </EditableDeletableCard>
  );
};

export default CoveragesCard;
