import { CardField, EditableDeletableCardProps } from "@chq/components";
import { Coverages, Equipment } from "@chq/enrollment-api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  fade,
  Grid,
  IconButton,
  IconButtonProps,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import { Fields as CoverageFields } from "./add-coverage-form";
import { Fields as TrailerPowerUnitFormFields } from "./trailer-power-unit-form";

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    background: "transparent",
    minHeight: "3rem",
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0rem",
      color: theme.palette.primary,
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    width: "100%",
  },
  summary: {
    padding: "0px",
    minHeight: "3rem",
    background: theme.palette.background.paper,
    borderRadius: ".75rem",
    paddingRight: "1.25rem",
    paddingLeft: ".6rem",

    "&.Mui-expanded": {
      minHeight: "3rem",
    },
  },
  titleContainer: {
    padding: "0rem 0.5rem",
  },
  subtitle: {
    padding: "0.5rem 0rem 1.5rem",
    color: "#AEB3C4",
  },
  accordionIcon: {
    height: "2.5rem",
    width: "auto",
  },
  content: {
    background: "transparent",
    padding: "0rem 1.25rem",
  },
  coverageDetailsIconButton: {
    paddingLeft: "0rem",
  },
  iconButton: {
    padding: "0px",
  },
  icon: {
    height: "40px",
    width: "auto",
  },
  container: {
    padding: "0.5rem 4.5rem 1.2rem 1.5rem",
    borderRadius: "0.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    width: "100%",
  },
  containerClass: {
    padding: "0rem",
  },
  approvedIcon: {
    color: theme.palette.success.main,
  },
  cancelIcon: {
    color: theme.palette.error.main,
  },
  blue: {
    fontWeight: "bolder",
    padding: "0rem 0.1rem",
    color: theme.palette.primary.main,
  },
}));

export const Fields = {
  ...CoverageFields,
  ...TrailerPowerUnitFormFields,
  autoLiability: "auto-liability",
};

export type Props = {
  autoLiability: boolean;
  gapCoverage: string;
  physicalDamage: string;
  coverages: Coverages;
  coveredEquipment?: Equipment;

  onEdit: EditableDeletableCardProps["onEdit"];
  IconButtonProps?: Omit<IconButtonProps, "onClick">;
};

const CoverageDetailsCard: React.FC<Props> = ({
  autoLiability,
  gapCoverage,
  physicalDamage,
  coverages,
  coveredEquipment,
  onEdit,
  IconButtonProps,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const booleanToIcon = (b: boolean | undefined | null) => {
    return b ? <CheckCircle className={classes.approvedIcon} /> : <Cancel className={classes.cancelIcon} />;
  };

  const trailerInterchangeField = () => {
    if (coverages.trailerInterchangeCoverage) {
      return [
        { value: booleanToIcon(coverages.trailerInterchangeCoverage) },
        {
          value: t("enrollment.quote-overview-page.coverage-card.trailer-coverage-limit.value", {
            money: coverages.trailerInterchangeCoverageLimit,
          }),
        },
      ];
    } else return [{ value: booleanToIcon(false) }];
  };

  const cargoInsuranceField = () => {
    if (
      coveredEquipment?.cargoInsurance &&
      coveredEquipment?.cargoInsuranceLimit &&
      coveredEquipment?.cargoInsuranceDeductible
    ) {
      return [
        { value: booleanToIcon(coveredEquipment.cargoInsurance) },
        {
          value: t("enrollment.quote-overview-page.coverage-card.cargo-limit-deductible.value", {
            limit: coveredEquipment?.cargoInsuranceLimit,
            deductible: coveredEquipment?.cargoInsuranceDeductible,
          }),
        },
      ];
    } else return [{ value: booleanToIcon(coveredEquipment?.cargoInsurance) }];
  };

  return (
    <Paper>
      <Grid container>
        <Grid item>
          <Accordion square elevation={0} className={classes.mainContainer}>
            <AccordionSummary
              className={classes.summary}
              expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.titleContainer}
              >
                <Typography variant="h2">{t("enrollment.quote-overview-page.coverage-card.title")}</Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.content}>
              <Grid container>
                <IconButton className={classes.coverageDetailsIconButton} onClick={onEdit}>
                  <Typography variant={"h4"} component="p" className={classes.blue}>
                    {t("common.edit")}
                  </Typography>
                </IconButton>
                <Grid container direction="row" alignItems="center">
                  <Typography variant={"h4"} component="p" color={"textSecondary"} className={classes.subtitle}>
                    {t("enrollment.quote-overview-page.coverage-card.subtitle")}
                  </Typography>
                </Grid>
                <CardField
                  id={Fields.autoLiability}
                  coverageDetails={true}
                  labelGrid={5}
                  valueGrid={7}
                  labelColor="textPrimary"
                  label={t(`enrollment.quote-overview-page.coverage-card.${Fields.autoLiability}`)}
                  values={[{ value: booleanToIcon(autoLiability) }]}
                />
                <CardField
                  id={Fields.gapCoverage}
                  coverageDetails={true}
                  labelGrid={5}
                  valueGrid={7}
                  labelColor="textPrimary"
                  label={t(`enrollment.quote-overview-page.coverage-card.${Fields.gapCoverage}`)}
                  values={[{ value: gapCoverage }]}
                />
                <CardField
                  id={Fields.coverageOption}
                  coverageDetails={true}
                  labelGrid={5}
                  valueGrid={7}
                  labelColor="textPrimary"
                  label={t(`enrollment.quote-overview-page.coverage-card.${Fields.coverageOption}`)}
                  values={[{ value: physicalDamage }]}
                />
                <CardField
                  id={Fields.trailerInterchange}
                  coverageDetails={true}
                  labelGrid={5}
                  valueGrid={7}
                  wrap={"nowrap"}
                  labelColor="textPrimary"
                  label={t(`enrollment.quote-overview-page.coverage-card.${Fields.trailerInterchange}`)}
                  values={trailerInterchangeField()}
                />
                <CardField
                  id={Fields.hiredAuto}
                  coverageDetails={true}
                  labelGrid={5}
                  valueGrid={7}
                  labelColor="textPrimary"
                  label={t(`enrollment.quote-overview-page.coverage-card.${Fields.hiredAuto}`)}
                  values={[{ value: booleanToIcon(coverages?.hiredAutoCovers) }]}
                />
                <CardField
                  id={Fields.uninsuredMotorist}
                  coverageDetails={true}
                  labelGrid={5}
                  valueGrid={7}
                  labelColor="textPrimary"
                  label={t(`enrollment.quote-overview-page.coverage-card.${Fields.uninsuredMotorist}`)}
                  values={[{ value: booleanToIcon(coverages?.umUimCoverage) }]}
                />
                <CardField
                  id={Fields.cargoInsurance}
                  coverageDetails={true}
                  labelGrid={5}
                  valueGrid={7}
                  labelColor="textPrimary"
                  label={t(`enrollment.quote-overview-page.coverage-card.${Fields.cargoInsurance}`)}
                  values={cargoInsuranceField()}
                />
                {coveredEquipment?.cargoInsurance && (
                  <CardField
                    id={Fields.reeferBreakdown}
                    coverageDetails={true}
                    labelGrid={5}
                    valueGrid={7}
                    labelColor="textPrimary"
                    label={t(`enrollment.quote-overview-page.coverage-card.${Fields.reeferBreakdown}`)}
                    values={[{ value: booleanToIcon(coveredEquipment?.cargoInsuranceReeferBreakdown) }]}
                  />
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CoverageDetailsCard;
