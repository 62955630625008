import { CardField, EditableDeletableCard, EditableDeletableCardProps, format } from "@chq/components";
import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "1rem",
    paddingLeft: "0.5rem",
  },
}));

type Props = {
  effectiveDate?: Date | null;
  editable?: boolean;
  error?: boolean;
  onEdit?: EditableDeletableCardProps["onEdit"];
};

const CoverageBeginsCard: React.FC<Props> = ({ effectiveDate, editable, error, onEdit }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard
      variant={editable ? "edit" : "default"}
      onEdit={onEdit}
      IconButtonProps={{ "aria-label": t("coverage-begins-card.edit-button") }}
      title={t("coverage-begins-card.title")}
      titleVariant="h2"
      titleComponent="h1"
      elevation={1}
      border={false}
      className={classes.container}
    >
      <CardField
        id="beginDate"
        label={effectiveDate ? format(effectiveDate) : ""}
        labelVariant="h2"
        labelColor={error ? "error" : "primary"}
      />
    </EditableDeletableCard>
  );
};

export default CoverageBeginsCard;
