/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InsuranceProgramType,
  InsuranceProgramTypeFromJSON,
  InsuranceProgramTypeFromJSONTyped,
  InsuranceProgramTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface InsuranceProgram
 */
export interface InsuranceProgram {
  /**
   *
   * @type {number}
   * @memberof InsuranceProgram
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof InsuranceProgram
   */
  active?: boolean;
  /**
   *
   * @type {InsuranceProgramType}
   * @memberof InsuranceProgram
   */
  insuranceProgramType?: InsuranceProgramType;
}

export function InsuranceProgramFromJSON(json: any): InsuranceProgram {
  return InsuranceProgramFromJSONTyped(json, false);
}

export function InsuranceProgramFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceProgram {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    active: !exists(json, "active") ? undefined : json["active"],
    insuranceProgramType: !exists(json, "insuranceProgramType")
      ? undefined
      : InsuranceProgramTypeFromJSON(json["insuranceProgramType"]),
  };
}

export function InsuranceProgramToJSON(value?: InsuranceProgram | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    active: value.active,
    insuranceProgramType: InsuranceProgramTypeToJSON(value.insuranceProgramType),
  };
}
