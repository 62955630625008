import { Coverages } from "@chq/policy-management-api";
import { createContext, Dispatch, SetStateAction, useState } from "react";

export type EditedCoverages = {
  coverages: Coverages & {
    cargoInsurance?: boolean | null;
    cargoInsuranceLimit?: number | null;
    cargoInsuranceDeductible?: number | null;
    cargoInsuranceReeferBreakdown?: boolean | null;
  };
  editedFields: string[];
};

export type GlobalPolicyCoveragesContent = {
  policyCoverages: EditedCoverages | undefined;
  updatePolicyCoverages: Dispatch<SetStateAction<EditedCoverages | undefined>>;
};

export const PolicyCoveragesContext = createContext<GlobalPolicyCoveragesContent>({
  policyCoverages: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updatePolicyCoverages: () => {},
});

export const PolicyCoveragesProvider: React.FC = ({ children }) => {
  const [policyCoverages, updatePolicyCoverages] = useState<EditedCoverages>();

  return (
    <PolicyCoveragesContext.Provider value={{ policyCoverages, updatePolicyCoverages }}>
      {children}
    </PolicyCoveragesContext.Provider>
  );
};
