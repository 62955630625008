import { CompAndCollisionCoverage } from "@chq/enrollment-api";

export const useCoverageOptionTypes = (): CompAndCollisionCoverage[] => {
  return [
    CompAndCollisionCoverage.NoCoverage,
    CompAndCollisionCoverage._1000Deductible,
    CompAndCollisionCoverage._2000Deductible,
    CompAndCollisionCoverage._5000Deductible,
  ];
};
