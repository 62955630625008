import { EditableDeletableCard, EligibilityStates, EligibilityStatus } from "@chq/components";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Reason } from "../utils/qualify-redirect";

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    paddingTop: "1.5rem",
  },
  list: {
    paddingLeft: "1rem",
  },
  card: {
    backgroundColor: theme.palette.grey[200],
    paddingTop: "1rem",
  },
}));

type Props = {
  eligibilityStatusClass?: string;
  didNotQualifyReason?: number;
};

const FleetNotEligibleCard: React.FC<Props> = ({ eligibilityStatusClass, didNotQualifyReason = 0 }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <EditableDeletableCard
      titleElement={
        <EligibilityStatus
          status={EligibilityStates.INELIGIBLE}
          statusText={t("fleet-not-eligible-card.status-text")}
          className={eligibilityStatusClass}
        />
      }
      className={classes.card}
      variant="default"
    >
      {didNotQualifyReason === Reason.dotNum && (
        <>
          <Typography variant="body1" className={classes.message}>
            {t("fleet-not-eligible-card.rrg-message")}
          </Typography>
          <Typography variant="h4" className={classes.message}>
            {t("fleet-not-eligible-card.list-message.message")}
            <ul className={classes.list}>
              <li>{t("fleet-not-eligible-card.list-message.bullet-1")}</li>
              <li>{t("fleet-not-eligible-card.list-message.bullet-2")}</li>
              <li>{t("fleet-not-eligible-card.list-message.bullet-3")}</li>
            </ul>
          </Typography>
        </>
      )}
      {didNotQualifyReason !== Reason.dotNum && (
        <>
          <Typography variant="h4" className={classes.message}>
            {didNotQualifyReason === 1 && t("fleet-not-eligible-card.state-not-eligible.title")}
            {didNotQualifyReason === Reason.powerUnit && t("fleet-not-eligible-card.power-unit-not-eligible.title")}
            {didNotQualifyReason === Reason.stateAndPowerUnits &&
              t("fleet-not-eligible-card.power-unit-state-not-eligible.title")}
          </Typography>
          <Typography variant="body1" className={classes.message}>
            {didNotQualifyReason === Reason.state && t("fleet-not-eligible-card.state-not-eligible.description")}
            {didNotQualifyReason === Reason.powerUnit &&
              t("fleet-not-eligible-card.power-unit-not-eligible.description")}
            {didNotQualifyReason === Reason.stateAndPowerUnits &&
              t("fleet-not-eligible-card.power-unit-state-not-eligible.description")}
          </Typography>
        </>
      )}
    </EditableDeletableCard>
  );
};

export default FleetNotEligibleCard;
