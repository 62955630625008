/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GenerateTokenChangePhoneResponse
 */
export interface GenerateTokenChangePhoneResponse {
  /**
   *
   * @type {string}
   * @memberof GenerateTokenChangePhoneResponse
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof GenerateTokenChangePhoneResponse
   */
  token?: string | null;
}

export function GenerateTokenChangePhoneResponseFromJSON(json: any): GenerateTokenChangePhoneResponse {
  return GenerateTokenChangePhoneResponseFromJSONTyped(json, false);
}

export function GenerateTokenChangePhoneResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenerateTokenChangePhoneResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    token: !exists(json, "token") ? undefined : json["token"],
  };
}

export function GenerateTokenChangePhoneResponseToJSON(value?: GenerateTokenChangePhoneResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    token: value.token,
  };
}
