import { EditableDeletableCard, SaveButton, YesNoInput, YesNoValues } from "@chq/components";
import { FactoringCustomer } from "@chq/factoring-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { Formik, FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FactoringShipperBrokerForm, {
  Fields as ShipperBrokerFields,
  FormProps as ShipperBrokerFormProps,
  useFormikConfig,
} from "./factoring-shipper-broker-form";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: "1rem",
  },
  hauling: {
    whiteSpace: "nowrap",
    paddingTop: ".875rem",
    paddingBottom: ".25rem",
  },
  haulingLabel: {
    background: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      "& .MuiFormLabel-root": {
        whiteSpace: "normal",
      },
      "& .MuiFormGroup-root": {
        [theme.breakpoints.down("xs")]: {
          marginTop: "1rem",
        },
      },
    },
  },
  container: { background: theme.palette.grey[600] },
  addButton: {
    background: theme.palette.common.white,
    padding: "1.125rem 0rem",
    fontWeight: 400,
  },
}));

export enum Fields {
  hauling = "hauling",
}

export type FormProps = {
  hauling: string;
};

export type Props = {
  hauling?: string;
  shipperBrokers?: FactoringCustomer[];
  isLoading?: boolean;
  onNoShipperBrokersSubmit?: FormikConfig<FormProps>["onSubmit"];
  onSubmit?: FormikConfig<ShipperBrokerFormProps>["onSubmit"];
};

export const useValidateSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.hauling]: Yup.string().required(
      t(`errors.required`, { field: t(`factoring.shipper-broker-form-wrapper.${Fields.hauling}.label`) }),
    ),
  });
};

const FactoringShipperBrokerFormWrapper: React.FC<Props> = ({
  hauling = "",
  shipperBrokers,
  isLoading,
  onNoShipperBrokersSubmit,
  onSubmit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const validationSchema = useValidateSchema();
  const formikProps = useFormikConfig({
    shipperBrokers: shipperBrokers?.map((shipperBroker) => ({
      [ShipperBrokerFields.name]: shipperBroker.businessName || "",
      [ShipperBrokerFields.mcNumber]: shipperBroker.mcNumber || "",
      [ShipperBrokerFields.addressLineOne]: shipperBroker.customerAddress?.address1 || "",
      [ShipperBrokerFields.addressLineTwo]: shipperBroker.customerAddress?.address2 || "",
      [ShipperBrokerFields.city]: shipperBroker.customerAddress?.city || "",
      [ShipperBrokerFields.state]: shipperBroker.customerAddress?.state || "",
      [ShipperBrokerFields.zip]: shipperBroker.customerAddress?.zip || "",
      [ShipperBrokerFields.sales]: shipperBroker.monthlySales?.toString() || "",
    })),
  });
  const formikWrapper = useFormik<FormProps>({
    initialValues: {
      [Fields.hauling]: hauling,
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      onNoShipperBrokersSubmit && onNoShipperBrokersSubmit(values, formikHelpers);
    },
    validateOnMount: true,
    enableReinitialize: true,
  });
  return (
    <EditableDeletableCard
      variant="default"
      title={t("factoring.shipper-broker-form-wrapper.title")}
      titleVariant="h2"
      titleComponent="h3"
      className={classNames(classes.title, classes.container)}
    >
      <Grid container>
        <form onSubmit={formikWrapper.handleSubmit}>
          <Grid item xs={12} className={classes.hauling}>
            <YesNoInput
              className={classes.haulingLabel}
              required
              id={Fields.hauling}
              name={Fields.hauling}
              yesText={t("common.yes")}
              noText={t("common.no")}
              onChange={formikWrapper.handleChange}
              onBlur={formikWrapper.handleBlur}
              value={formikWrapper.values[Fields.hauling]}
              label={t("factoring.shipper-broker-form-wrapper.hauling.label")}
              error={formikWrapper.touched[Fields.hauling] && Boolean(formikWrapper.errors[Fields.hauling])}
              helperText={formikWrapper.touched[Fields.hauling] && formikWrapper.errors[Fields.hauling]}
            />
          </Grid>
        </form>
        {formikWrapper.values.hauling === YesNoValues.yes && (
          <Grid item>
            <Formik
              {...formikProps}
              onSubmit={(values, formikHelpers) => {
                onSubmit && onSubmit(values, formikHelpers);
              }}
            >
              {(formik) => (
                <>
                  <FactoringShipperBrokerForm />
                  <Grid item xs={12}>
                    <SaveButton
                      label={t("factoring.shipper-broker-form-wrapper.add-button")}
                      variant="outlined"
                      onClick={() => formik.handleSubmit()}
                      fullWidth
                      isLoading={isLoading}
                      type="submit"
                      disabled={!formik.isValid || !formikWrapper.isValid || isLoading}
                      formikValid={formikWrapper.isValid}
                    />
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        )}
        {formikWrapper.values.hauling === YesNoValues.no && (
          <Grid item xs={12}>
            <SaveButton
              label={t("factoring.shipper-broker-form-wrapper.add-button")}
              variant="outlined"
              onClick={() => formikWrapper.handleSubmit()}
              fullWidth
              isLoading={isLoading}
              type="submit"
              disabled={!formikWrapper.isValid || isLoading}
              formikValid={formikWrapper.isValid}
            />
          </Grid>
        )}
      </Grid>
    </EditableDeletableCard>
  );
};

export default FactoringShipperBrokerFormWrapper;
