import {
  ComponentConfirmOverlay,
  format,
  NextButton,
  PlusCircleIcon as PlusCircle,
  PreviousButton,
  YesNoValues,
} from "@chq/components";
import { Button, CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FieldArray, Formik } from "formik";
import React, { useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import AddDriverForm, { Fields, FormProps, useValidationSchema } from "../../../components/add-driver-form";
import DriverCard from "../../../components/driver-card";
import GenericPageAlert from "../../../components/generic-page-alert";
import { useGetApplication } from "../../../data/enrollment";
import { useAddDriver, useDeleteDriver, useEditDriver } from "../../../data/enrollment/useDrivers";
import { driverMapper } from "../../../utils/driver-mapper";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.5rem 1rem 2.25rem 1rem",
  },
  pageTitle: {
    paddingBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
    textAlign: "center",
  },
  driverCardContainer: {
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },
  card: {
    position: "relative",
  },
  addIcon: {
    height: "40px",
    width: "auto",
  },
  pageButtons: {
    paddingTop: "1rem",
    justifyContent: "center",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
}));

export type Driver = Partial<FormProps> & {
  id?: number;
  isEditing?: boolean;
  isAdding?: boolean;
  driverNumber?: number;
};

const DriversPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const validationSchema = useValidationSchema();
  const { data: application } = useGetApplication(RouteNames.drivers);
  const [showConfirmationOverlay, setShowConfirmationOverlay] = useState<number | null>(null);
  const { mutate: addDriver, isLoading: isAddingDriver, isError: isAddError } = useAddDriver();
  const { mutate: editDriver, isLoading: isEditingDriver, isError: isEditError } = useEditDriver();
  const { mutate: deleteDriver, isLoading: isDeletingDriver, isError: isDeleteError } = useDeleteDriver();

  const deleteConfirmation = (onDelete: () => void) => {
    onDelete();
    setShowConfirmationOverlay(null);
  };

  const initialDrivers = application?.coveredIndividuals || [];

  return (
    <>
      {(isAddError || isEditError || isDeleteError) && <GenericPageAlert />}
      {!application?.qualified && qualifyRedirect(application?.ineligibleReason || "")}
      <Formik
        initialValues={{
          drivers: initialDrivers.length
            ? initialDrivers.map((driver, index) => ({
                id: driver.id,
                driverNumber: index + 1,
                isEditing: false,
                isAdding: false,
                [Fields.firstName]: driver.firstName || undefined,
                [Fields.lastName]: driver.lastName || undefined,
                [Fields.dateOfBirth]: driver.dateOfBirth || undefined,
                [Fields.cdlNumber]: driver.cdlNumber || undefined,
                [Fields.cdlState]: driver.cdlState,
                [Fields.pastViolations]:
                  driver.violationDescription === "" || undefined
                    ? YesNoValues.no
                    : driver.violationDescription !== ""
                    ? YesNoValues.yes
                    : "",
                [Fields.violationDescription]: driver.violationDescription!,
              }))
            : [{ driverNumber: 1, isEditing: true, isAdding: true }],
        }}
        validateOnMount
        enableReinitialize
        validate={({ drivers }) => {
          const isValid =
            drivers.length &&
            drivers.every((driver) => {
              return !driver.isEditing && validationSchema.isValidSync(driver);
            });
          return isValid ? {} : { drivers: "invalid" };
        }}
        onSubmit={(values: { drivers: Driver[] }) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "step4CoverageEvent",
              step4CoverageText: "Step 4: Coverage",
            },
          });
          history.push(routes.enrollment.coverage.path);
        }}
      >
        {(formik) => (
          <Grid container direction="column" className={classes.container}>
            <Grid container item direction="column" className={classes.pageTitle}>
              <Grid item>
                <Typography variant="h2">{t("enrollment.add-driver.page-title")}</Typography>
              </Grid>
              <Grid item>
                <Typography component="p" variant="h4">
                  {t("enrollment.add-driver.page-subtitle")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2} className={classes.driverCardContainer}>
              <FieldArray name={"drivers"}>
                {({ push, remove }) => {
                  return (
                    <>
                      {formik.values.drivers.map((driver, index) => {
                        return (
                          <Grid item key={driver.driverNumber} xs={12} sm={6} lg={4}>
                            {!driver.isEditing ? (
                              <DriverCard
                                driverNumber={driver.driverNumber!}
                                firstName={driver[Fields.firstName]!}
                                lastName={driver[Fields.lastName]!}
                                dateOfBirth={format(driver[Fields.dateOfBirth]!)}
                                cdlNumber={driver[Fields.cdlNumber]!}
                                cdlState={driver[Fields.cdlState]!}
                                violationDescription={driver[Fields.violationDescription]!}
                                onEdit={() => {
                                  const drivers = formik.values.drivers;
                                  formik.setFieldValue(
                                    "drivers",
                                    drivers.map((item, i) =>
                                      index === i ? { ...item, isEditing: true, isAdding: false } : item,
                                    ),
                                  );
                                }}
                              />
                            ) : (
                              <div className={classes.card}>
                                <AddDriverForm
                                  driverNumber={driver.driverNumber}
                                  firstName={driver[Fields.firstName]}
                                  lastName={driver[Fields.lastName]}
                                  dateOfBirth={driver[Fields.dateOfBirth]}
                                  cdlNumber={driver[Fields.cdlNumber]}
                                  cdlState={driver[Fields.cdlState]}
                                  violationDescription={driver[Fields.violationDescription]}
                                  pastViolations={
                                    driver[Fields.violationDescription] === ""
                                      ? YesNoValues.no
                                      : driver[Fields.violationDescription]?.length
                                      ? YesNoValues.yes
                                      : ""
                                  }
                                  IconButtonProps={{ disabled: formik.values.drivers.length === 1 }}
                                  onDelete={() => setShowConfirmationOverlay(index)}
                                  onSubmit={(values) => {
                                    const drivers = formik.values.drivers;
                                    const newDrivers = drivers.map((item, i) =>
                                      index === i ? { ...item, ...values, isEditing: false, isAdding: false } : item,
                                    );
                                    const mappedDriver = driverMapper(newDrivers[index], application?.id);
                                    if (driver.isAdding) {
                                      addDriver(
                                        {
                                          applicationId: application?.id,
                                          driver: mappedDriver,
                                        },
                                        {
                                          onSuccess: (data: Driver | undefined) => {
                                            TagManager.dataLayer({
                                              dataLayer: {
                                                event: "saveDriverEvent",
                                                saveDriverText: "Save Driver",
                                              },
                                            });
                                            newDrivers[index].id = data?.id; // This is where we will set the driver id so we can identify which one to delete if needed
                                            formik.setFieldValue("drivers", newDrivers);
                                          },
                                        },
                                      );
                                    } else {
                                      editDriver({
                                        driver: mappedDriver,
                                      });
                                    }
                                    formik.setFieldValue("drivers", newDrivers);
                                  }}
                                />
                                {showConfirmationOverlay === index && (
                                  <ComponentConfirmOverlay
                                    confirmText={t("common.delete")}
                                    confirmAction={() =>
                                      deleteConfirmation(() => {
                                        if (driver.isAdding === true) {
                                          remove(index);
                                        } else {
                                          remove(index);
                                          deleteDriver({ id: driver.id });
                                        }
                                      })
                                    }
                                    cancelText={t("common.cancel-delete")}
                                    cancelAction={() => setShowConfirmationOverlay(null)}
                                  />
                                )}
                              </div>
                            )}
                          </Grid>
                        );
                      })}
                      <Grid item>
                        <Button
                          variant="text"
                          color="primary"
                          startIcon={<PlusCircle className={classes.addIcon} />}
                          onClick={() => {
                            const drivers = formik.values.drivers;
                            push({
                              driverNumber: drivers[drivers.length - 1]
                                ? drivers[drivers.length - 1].driverNumber! + 1
                                : 1,
                              isEditing: true,
                              isAdding: true,
                            });
                          }}
                        >
                          {t("enrollment.add-driver.add-button")}
                        </Button>
                      </Grid>
                    </>
                  );
                }}
              </FieldArray>
            </Grid>
            <Grid container item direction="row" className={classes.pageButtons} spacing={2}>
              <Grid item xs={3} sm={3} lg={1}>
                <PreviousButton
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.previousButton}
                  onClick={() => history.push(routes.enrollment.equipment.path)}
                  aria-label={t("enrollment.add-driver.previous-button-label")}
                ></PreviousButton>
              </Grid>
              <Grid item xs={9} sm={5} lg={3}>
                <NextButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!formik.isValid || isAddingDriver || isEditingDriver || isDeletingDriver}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {isAddingDriver || isEditingDriver || isDeletingDriver ? (
                    <CircularProgress
                      color="inherit"
                      size="2rem"
                      aria-label={t("common.circular-progress-aria-label")}
                    />
                  ) : (
                    t("enrollment.add-driver.next-button")
                  )}
                </NextButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default DriversPage;
