import { useAccessToken } from "@chq/authentication";
import { CompAndCollisionCoverage, Equipment, HTTPHeaders } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import AddDotCard from "../../../components/add-dot-card";
import CoverageDetailsCard from "../../../components/coverage-details-card";
import DisclosuresSection from "../../../components/disclosures-section";
import DocumentCard, { File } from "../../../components/document-card";
import EDeliveryConsentForm, {
  Fields as EConsentFields,
  FormProps as EConsentFormProps,
  useFormikConfig as useEConsentFormikConfig,
} from "../../../components/e-delivery-consent-form";
import GenericPageAlert from "../../../components/generic-page-alert";
import QuoteSummaryCard, { CardVariant } from "../../../components/quote-summary-card";
import TrucktasticQuoteCard from "../../../components/trucktastic-quote-card";
import { useUserStatus } from "../../../data/authentication/useUserStatus";
import { useGetApplication, useGetQuote, useSendQuoteBreakdownEmail } from "../../../data/enrollment";
import {
  useAddEDeliveryConsent,
  useDeleteEDeliveryConsentDocument,
} from "../../../data/enrollment/useEDeliveryConsent";
import { useTermsAndConditionLinks } from "../../../data/enrollment/useTermsAndConditionsLink";
import { useNonConsentStates } from "../../../data/useNonConsentStates";
import confettiAnimation from "../../../lotties/confetti-lottie.json";
import { getQuoteItems } from "../../../utils/get-quote-items";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";
import { FullScreenLoader } from "../../shared/fullScreenLoader";

const useStyles = makeStyles((theme: Theme) => ({
  fullHeight: {
    display: "flex",
    overflow: "hidden",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "center",
  },
  contentWidth: {
    maxWidth: "1000px",
    marginTop: "1.5rem",
    alignItems: "flex-start",
  },
  cardContainer: {
    width: "100%",
    margin: 0,
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 0.5rem 1rem 0.5rem",
    columnGap: "1rem",
    rowGap: "1rem",
  },
  confetti: {
    pointerEvents: "none",
    position: "fixed",
    height: "100vh",
    width: "100%",
    maxWidth: "1000px",
    top: "50%",
    left: "50%",
    zIndex: 10000,
    transform: "translate(-50%, -50%)",
    opacity: "50%",
  },
}));

type SSEDocument = {
  DocumentType: number;
  Id: number;
  Length: number;
  Name: string;
  PublicUri: string;
  StorageUri: string;
};

type DocumentAlertEvent = {
  Name: string;
  Ready: boolean;
  Document: SSEDocument;
};

const confettiOptions = {
  loop: false,
  autoplay: true,
  animationData: confettiAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const QuoteOverviewPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  const { data: application, isLoading: isLoadingApplication } = useGetApplication(RouteNames.quoteOverview);
  const { data: userStatus, isLoading: isLoadingUserStatus } = useUserStatus();
  const { data: quoteApplication, isFetching: isLoadingQuote, isError: isQuoteError } = useGetQuote();
  const { data: termsAndConditionsLinks } = useTermsAndConditionLinks();

  const states = useNonConsentStates();
  const quote = quoteApplication?.currentQuote;

  const hasCargoInsurance = application?.coveredEquipment?.some((equipment: Equipment) => equipment.cargoInsurance);
  const [files, setFiles] = useState<Array<File>>(() => {
    const filesArray: File[] = [
      {
        id: undefined,
        name: t("enrollment.quote-overview-page.pdf-download.pdf-name.SfaargProposalDoc"),
        publicUri: undefined,
      },
      {
        id: undefined,
        name: t("enrollment.quote-overview-page.pdf-download.pdf-name.DeclarationsDoc"),
        publicUri: undefined,
      },
    ];
    if (hasCargoInsurance) {
      filesArray.push({
        id: application?.pdfDocuments?.cargoFormUnsigned?.id || undefined,
        name: t("enrollment.quote-overview-page.pdf-download.pdf-name.CargoFormUnsigned"),
        publicUri: application?.pdfDocuments?.cargoFormUnsigned?.publicUri || undefined,
      } as File);

      filesArray.push({
        publicUri: termsAndConditionsLinks?.cargoPolicyWatermarkedLink,
        name: t("enrollment.quote-overview-page.pdf-download.pdf-name.cargo-forms-unsigned"),
      } as File);
    }
    return filesArray;
  });

  const [documentsLoading, setDocumentsLoading] = useState(files.some((file) => file.id === undefined));
  const [sseError, setSseError] = useState(false);

  const appId = application?.id || undefined;
  const token = useAccessToken();
  const headers = useMemo((): HTTPHeaders => {
    return token?.accessToken !== undefined ? { Authorization: `Bearer ${token.accessToken}` } : {};
  }, [token]);

  useEffect(() => {
    if (!isLoadingQuote) {
      if (!appId) {
        return;
      }

      const eventSourceInitDict = headers;
      const basePath = window.origin;

      const sse = new EventSource(
        `${basePath}/api/v1.0/ServerSentEvents/QuoteDocumentAlerts/${appId}`,
        eventSourceInitDict,
      );

      sse.onerror = (e: Event) => {
        setSseError(true);
        sse.close();
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sse.addEventListener("documentsLoading", (e: any) => {
        const newFiles = JSON.parse(e.data).DocumentAlertEvents;

        const tempFiles = files;
        newFiles.forEach((newFile: DocumentAlertEvent) => {
          const i = tempFiles.findIndex(
            (file) => file.name === t(`enrollment.quote-overview-page.pdf-download.pdf-name.${newFile.Name}`),
          );
          if (i >= 0) {
            tempFiles[i] = {
              name: t(`enrollment.quote-overview-page.pdf-download.pdf-name.${newFile.Name}`),
              id: newFile.Document.Id,
              publicUri: newFile.Document.PublicUri,
            };
          }
        });
        setFiles((files) => tempFiles);
        if (!tempFiles.some((file) => file.id === undefined || null)) {
          sse.close();
          setDocumentsLoading(false);
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sse.addEventListener("close", (e: any) => {
        sse.close();
        setDocumentsLoading(false);
      });
    }
  }, [isLoadingQuote, appId, hasCargoInsurance, files, setFiles, setDocumentsLoading, headers, t]);

  const {
    mutateAsync: sendQuoteBreakdownEmail,
    isLoading: isSendingQuoteBreakdownEmail,
  } = useSendQuoteBreakdownEmail();

  const isLoading = isLoadingApplication || isLoadingUserStatus || isLoadingQuote;

  const { mutate: addEDeliveryConsent, isLoading: signingIsLoading } = useAddEDeliveryConsent();

  const { mutate: deleteEDeliveryConsentDocument } = useDeleteEDeliveryConsentDocument();

  const signEDeliveryConsent = () => {
    addEDeliveryConsent({ applicationId: application?.id });
  };

  const isNonConsentState = () => {
    const nonConsentStates = states;
    const state = application?.business?.address?.state;
    return state ? nonConsentStates.includes(state) : false;
  };

  const formikProps = useEConsentFormikConfig({
    eConsent: application?.acknowledgements?.acceptedEDeliveryConsent || false,
    signingIsLoading: signingIsLoading,
    signEDeliveryConsent: signEDeliveryConsent,
    voluntaryEConsentState: isNonConsentState(),
  });

  // temporarily get first equipment value coveredEquipment[0]
  const firstEquipment = application!.coveredEquipment ? application!.coveredEquipment![0] : undefined;

  const quoteItems = useMemo(() => {
    const titles = [
      t("enrollment.get-quote.auto"),
      t("enrollment.get-quote.cargo"),
      t("enrollment.get-quote.saas"),
      t("enrollment.get-quote.down-payment"),
      t("enrollment.get-quote.full-payment"),
    ];
    return getQuoteItems(quote?.rates, firstEquipment?.cargoInsurance ?? false, titles);
  }, [firstEquipment?.cargoInsurance, quote?.rates, t]);

  const hasDot = Boolean(application?.business?.dotNumber);

  const loanLeaseAmount = application?.coveredEquipment?.reduce((count: number, equipment: Equipment) => {
    return equipment.loanLeaseGapCoverage ? count + 1 : count;
  }, 0);

  const physicalDamageAmount = application?.coveredEquipment?.reduce((count: number, equipment: Equipment) => {
    return equipment.compAndCollisionCoverage === CompAndCollisionCoverage.NoCoverage ||
      equipment.compAndCollisionCoverage == null
      ? count
      : count + 1;
  }, 0);

  const equipmentCoverageAmounts = {
    loanLeaseAmount,
    physicalDamageAmount,
  };

  if (application?.insuranceProgram?.active === false) {
    return <Redirect push to={routes.enrollment.quote.inactive.path} />;
  }

  return (
    <Grid container className={classes.fullHeight}>
      {/* Error returned from getQuote */}
      {isQuoteError && <GenericPageAlert errorTranslationKey="enrollment.quote-overview-page.quote-error-message" />}
      {/* Applicant is not qualified (CAB) */}
      {!application?.qualified && qualifyRedirect(application?.ineligibleReason || "")}
      {/* Full Screen Loader while quicksolver quote call is running */}
      {isLoading ? (
        <FullScreenLoader header={false} footer={false} setAsLoading={false} />
      ) : (
        <>
          <Grid item xs={12} className={classes.confetti}>
            <Lottie options={confettiOptions} width={"100%"} height={"100%"} />
          </Grid>
          <Formik
            {...formikProps}
            onSubmit={(values: EConsentFormProps) => {
              /* Empty Submit function */
            }}
          >
            {(formik) => (
              <Grid item className={classes.contentWidth}>
                <Grid container className={classes.cardContainer}>
                  <Grid container item direction="column" xs={12} spacing={2}>
                    <Grid container item xs={12}>
                      <TrucktasticQuoteCard />
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} sm={12} md={6} spacing={2}>
                    {!hasDot && (
                      <Grid item xs={12}>
                        <AddDotCard onClick={() => history.push(routes.enrollment.qualify.path)} />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <QuoteSummaryCard
                        title={t("enrollment.quote-overview-page.quote-summary.weekly")}
                        total={quote?.rates?.weeklyRate ?? 0}
                        summaryItems={quoteItems.weekly}
                        downPayment={quoteItems.downPayment[0]}
                        variant={hasDot ? CardVariant.weekly : CardVariant.error}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <QuoteSummaryCard
                        title={t("enrollment.quote-overview-page.quote-summary.monthly")}
                        total={quote?.rates?.monthlyRate ?? 0}
                        summaryItems={quoteItems.monthly}
                        downPayment={quoteItems.downPayment[1]}
                        variant={hasDot ? CardVariant.default : CardVariant.error}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <QuoteSummaryCard
                        title={t("enrollment.quote-overview-page.quote-summary.annually")}
                        total={quote?.rates?.annualizedRate ?? 0}
                        summaryItems={quoteItems.annually}
                        downPayment={quoteItems.downPayment[2]}
                        variant={hasDot ? CardVariant.default : CardVariant.error}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" xs={12} sm={12} md={6} spacing={2}>
                    <Grid item xs={12}>
                      <CoverageDetailsCard
                        autoLiability={true}
                        gapCoverage={t(
                          "enrollment.quote-overview-page.coverage-card.gap-coverage-and-coverage-option-value",
                          {
                            amount: equipmentCoverageAmounts.loanLeaseAmount,
                            total: application?.coveredEquipment?.length || 0,
                          },
                        )}
                        physicalDamage={t(
                          "enrollment.quote-overview-page.coverage-card.gap-coverage-and-coverage-option-value",
                          {
                            amount: equipmentCoverageAmounts.physicalDamageAmount,
                            total: application?.coveredEquipment?.length || 0,
                          },
                        )}
                        coverages={application?.coverages || {}}
                        coveredEquipment={firstEquipment}
                        onEdit={() => history.push(routes.enrollment.coverage.path)}
                      />
                    </Grid>
                    {sseError && (
                      <GenericPageAlert errorTranslationKey="enrollment.quote-overview-page.pdf-download.documents-error" />
                    )}
                    <Grid item xs={12}>
                      <DocumentCard files={files} isLoading={documentsLoading} />
                    </Grid>
                  </Grid>

                  <Grid container item direction="column" xs={12} spacing={2}>
                    <Grid container item xs={12}>
                      <EDeliveryConsentForm
                        eConsent={formik.values[EConsentFields.eConsent]}
                        signEDeliveryConsent={signEDeliveryConsent}
                        eConsentSignedDownload={application?.pdfDocuments?.eDeliveryConsentForm?.publicUri}
                        voluntaryEConsentState={isNonConsentState()}
                        signingIsLoading={signingIsLoading}
                        deleteDocument={() => {
                          deleteEDeliveryConsentDocument({
                            body: application?.pdfDocuments?.eDeliveryConsentForm?.storageUri || "",
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DisclosuresSection
                        disabledProceed={!formik.isValid || signingIsLoading || documentsLoading || isLoadingQuote}
                        onProceedClick={async () => {
                          await sendQuoteBreakdownEmail({
                            applicationId: application?.id,
                          });
                          await TagManager.dataLayer({
                            dataLayer: {
                              event: "proceedWithThisQuoteEvent",
                              proceedWithThisQuoteText: "Proceed With This Quote",
                            },
                          });
                          userStatus?.hasPassword
                            ? history.push(routes.enrollment.finishApplication.path)
                            : history.push(routes.enrollment.saveQuote.path);
                        }}
                        onPreviousClick={() => history.push(routes.enrollment.coverage.path)}
                        isSavingQuote={isSendingQuoteBreakdownEmail}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Formik>
        </>
      )}
    </Grid>
  );
};

export default QuoteOverviewPage;
