import { Checkbox, EmailInput, PasswordInput, TooltipHelp } from "@chq/components";
import { Theme } from "@chq/styles";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { usePasswordRules } from "../data/usePasswordRules";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px ${theme.palette.grey[500]} solid`,
    padding: "1rem 0.75rem 0.75rem 0.75rem",
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  success: {
    color: theme.palette.success.main,
  },
  radio: {
    justifyContent: "space-between",
    "& .MuiFormControlLabel-root": {
      marginLeft: "0.5rem",
      marginRight: "2.5rem",
    },
  },
  radioContainer: {
    marginBottom: "0px",
  },
  checkbox: {
    marginLeft: "1.25rem",
  },
  emailInput: {
    "& > div > label.MuiFormLabel-root.Mui-disabled": {
      color: theme.palette.common.black,
    },
    "& > div > input": {
      color: theme.palette.common.black,
      fontSize: "1rem",
    },
    "& > div > fieldset": {
      border: "none",
    },
  },
}));

export enum Fields {
  email = "email",
  password = "password",
  reenterPassword = "reenter-password",
  factoringCheckbox = "factoring-checkbox",
}

export enum VerifyType {
  email = "email",
  sms = "sms",
}

export type SaveQuoteFormProps = {
  [Fields.email]: string | null;
  [Fields.password]: string;
  [Fields.reenterPassword]: string;
  [Fields.factoringCheckbox]: boolean;
};

type Props = {
  email?: string | null | undefined;
  password?: string;
  reenterPassword?: string;
  factoringCheckbox?: boolean;
  onSubmit?: FormikConfig<SaveQuoteFormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();
  const passwordRules = usePasswordRules();

  return Yup.object({
    [Fields.password]: passwordRules,
    [Fields.reenterPassword]: Yup.string()
      .oneOf([Yup.ref([Fields.password].toString())], t("errors.noPasswordMatch"))
      .required(t(`enrollment.save-quote-page.save-quote-form.${Fields.reenterPassword}.help-text`)),
    [Fields.factoringCheckbox]: Yup.boolean(),
  });
};

export const useFormikConfig = ({
  email = "",
  password = "",
  reenterPassword = "",
  factoringCheckbox = false,
}: Props = {}): Omit<FormikConfig<SaveQuoteFormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.email]: email,
      [Fields.password]: password,
      [Fields.reenterPassword]: reenterPassword,
      [Fields.factoringCheckbox]: factoringCheckbox,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const SaveQuoteForm: React.FC<Props> = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<SaveQuoteFormProps>();

  return (
    <Paper elevation={0} className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <EmailInput
              fullWidth
              required
              label={t(`enrollment.save-quote-page.save-quote-form.${Fields.email}.label`)}
              id={Fields.email}
              name={Fields.email}
              labelAdornment={
                <TooltipHelp
                  title={t(`enrollment.save-quote-page.save-quote-form.${Fields.email}.help-text`).toString()}
                  placement="left-end"
                />
              }
              className={classes.emailInput}
              disabled={true}
              value={formik.values[Fields.email]}
            />
          </Grid>
          <Grid item>
            <PasswordInput
              fullWidth
              required
              label={t(`enrollment.save-quote-page.save-quote-form.${Fields.password}.label`)}
              id={Fields.password}
              name={Fields.password}
              rules={[
                {
                  humanReadableFormat: t("common.password-rules.min-length"),
                  regEx: /.{8,}/,
                },
                {
                  humanReadableFormat: t("common.password-rules.uppercase"),
                  regEx: /[A-Z]/,
                },
                { humanReadableFormat: t("common.password-rules.digit"), regEx: /[0-9]/ },
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.password] && Boolean(formik.errors[Fields.password])}
              value={formik.values[Fields.password]}
              helperText={formik.touched[Fields.password] && formik.errors[Fields.password]}
            />
          </Grid>
          <Grid item>
            <PasswordInput
              fullWidth
              required
              label={t(`enrollment.save-quote-page.save-quote-form.${Fields.reenterPassword}.label`)}
              rules={[
                {
                  humanReadableFormat: t("common.password-rules.match"),
                  regEx: formik.values[Fields.reenterPassword]
                    ? formik.values[Fields.reenterPassword] === formik.values[Fields.password]
                    : false,
                },
              ]}
              id={Fields.reenterPassword}
              name={Fields.reenterPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.reenterPassword] && Boolean(formik.errors[Fields.reenterPassword])}
              value={formik.values[Fields.reenterPassword]}
              helperText={formik.touched[Fields.reenterPassword] && formik.errors[Fields.reenterPassword]}
            />
          </Grid>
          <Grid item>
            <Checkbox
              className={classes.checkbox}
              label={t(`enrollment.save-quote-page.save-quote-form.${Fields.factoringCheckbox}.label`)}
              id={Fields.factoringCheckbox}
              name={Fields.factoringCheckbox}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.factoringCheckbox]}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SaveQuoteForm;
