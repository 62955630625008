/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NotificationPreference {
  Phone = "Phone",
  Text = "Text",
  Email = "Email",
  Any = "Any",
}

export function NotificationPreferenceFromJSON(json: any): NotificationPreference {
  return NotificationPreferenceFromJSONTyped(json, false);
}

export function NotificationPreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationPreference {
  return json as NotificationPreference;
}

export function NotificationPreferenceToJSON(value?: NotificationPreference | null): any {
  return value as any;
}
