/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Acknowledgements
 */
export interface Acknowledgements {
  /**
   *
   * @type {number}
   * @memberof Acknowledgements
   */
  id?: number;
  /**
   *
   * @type {Date}
   * @memberof Acknowledgements
   */
  bindDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Acknowledgements
   */
  printedName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Acknowledgements
   */
  signature?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  hasLegalAuthority?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  accuracyVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  acceptedTermsAndConditions?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Acknowledgements
   */
  tandCSignedOn?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  acceptedUmUimTerms?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Acknowledgements
   */
  umUimSignedOn?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  acceptedFraudStatement?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Acknowledgements
   */
  fraudSignedOn?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  acceptedRrgAgreement?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Acknowledgements
   */
  rrgSignedOn?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  acceptedSpeedgauge?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Acknowledgements
   */
  speedGaugeSignedOn?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  acceptedEDeliveryConsent?: boolean;
  /**
   *
   * @type {Date}
   * @memberof Acknowledgements
   */
  eConsentSignedOn?: Date | null;
  /**
   *
   * @type {string}
   * @memberof Acknowledgements
   */
  eConsentSignedBy?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  doesNotRequireFMCSAInsurance?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  doesNotUsePowerUnitsForLMD?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Acknowledgements
   */
  doesNotHaulRestrictedCommodities?: boolean;
}

export function AcknowledgementsFromJSON(json: any): Acknowledgements {
  return AcknowledgementsFromJSONTyped(json, false);
}

export function AcknowledgementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Acknowledgements {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    bindDate: !exists(json, "bindDate") ? undefined : new Date(json["bindDate"]),
    printedName: !exists(json, "printedName") ? undefined : json["printedName"],
    signature: !exists(json, "signature") ? undefined : json["signature"],
    hasLegalAuthority: !exists(json, "hasLegalAuthority") ? undefined : json["hasLegalAuthority"],
    accuracyVerified: !exists(json, "accuracyVerified") ? undefined : json["accuracyVerified"],
    acceptedTermsAndConditions: !exists(json, "acceptedTermsAndConditions")
      ? undefined
      : json["acceptedTermsAndConditions"],
    tandCSignedOn: !exists(json, "tandCSignedOn")
      ? undefined
      : json["tandCSignedOn"] === null
      ? null
      : new Date(json["tandCSignedOn"]),
    acceptedUmUimTerms: !exists(json, "acceptedUmUimTerms") ? undefined : json["acceptedUmUimTerms"],
    umUimSignedOn: !exists(json, "umUimSignedOn")
      ? undefined
      : json["umUimSignedOn"] === null
      ? null
      : new Date(json["umUimSignedOn"]),
    acceptedFraudStatement: !exists(json, "acceptedFraudStatement") ? undefined : json["acceptedFraudStatement"],
    fraudSignedOn: !exists(json, "fraudSignedOn")
      ? undefined
      : json["fraudSignedOn"] === null
      ? null
      : new Date(json["fraudSignedOn"]),
    acceptedRrgAgreement: !exists(json, "acceptedRrgAgreement") ? undefined : json["acceptedRrgAgreement"],
    rrgSignedOn: !exists(json, "rrgSignedOn")
      ? undefined
      : json["rrgSignedOn"] === null
      ? null
      : new Date(json["rrgSignedOn"]),
    acceptedSpeedgauge: !exists(json, "acceptedSpeedgauge") ? undefined : json["acceptedSpeedgauge"],
    speedGaugeSignedOn: !exists(json, "speedGaugeSignedOn")
      ? undefined
      : json["speedGaugeSignedOn"] === null
      ? null
      : new Date(json["speedGaugeSignedOn"]),
    acceptedEDeliveryConsent: !exists(json, "acceptedEDeliveryConsent") ? undefined : json["acceptedEDeliveryConsent"],
    eConsentSignedOn: !exists(json, "eConsentSignedOn")
      ? undefined
      : json["eConsentSignedOn"] === null
      ? null
      : new Date(json["eConsentSignedOn"]),
    eConsentSignedBy: !exists(json, "eConsentSignedBy") ? undefined : json["eConsentSignedBy"],
    doesNotRequireFMCSAInsurance: !exists(json, "doesNotRequireFMCSAInsurance")
      ? undefined
      : json["doesNotRequireFMCSAInsurance"],
    doesNotUsePowerUnitsForLMD: !exists(json, "doesNotUsePowerUnitsForLMD")
      ? undefined
      : json["doesNotUsePowerUnitsForLMD"],
    doesNotHaulRestrictedCommodities: !exists(json, "doesNotHaulRestrictedCommodities")
      ? undefined
      : json["doesNotHaulRestrictedCommodities"],
  };
}

export function AcknowledgementsToJSON(value?: Acknowledgements | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    bindDate: value.bindDate === undefined ? undefined : value.bindDate.toISOString(),
    printedName: value.printedName,
    signature: value.signature,
    hasLegalAuthority: value.hasLegalAuthority,
    accuracyVerified: value.accuracyVerified,
    acceptedTermsAndConditions: value.acceptedTermsAndConditions,
    tandCSignedOn:
      value.tandCSignedOn === undefined
        ? undefined
        : value.tandCSignedOn === null
        ? null
        : value.tandCSignedOn.toISOString(),
    acceptedUmUimTerms: value.acceptedUmUimTerms,
    umUimSignedOn:
      value.umUimSignedOn === undefined
        ? undefined
        : value.umUimSignedOn === null
        ? null
        : value.umUimSignedOn.toISOString(),
    acceptedFraudStatement: value.acceptedFraudStatement,
    fraudSignedOn:
      value.fraudSignedOn === undefined
        ? undefined
        : value.fraudSignedOn === null
        ? null
        : value.fraudSignedOn.toISOString(),
    acceptedRrgAgreement: value.acceptedRrgAgreement,
    rrgSignedOn:
      value.rrgSignedOn === undefined ? undefined : value.rrgSignedOn === null ? null : value.rrgSignedOn.toISOString(),
    acceptedSpeedgauge: value.acceptedSpeedgauge,
    speedGaugeSignedOn:
      value.speedGaugeSignedOn === undefined
        ? undefined
        : value.speedGaugeSignedOn === null
        ? null
        : value.speedGaugeSignedOn.toISOString(),
    acceptedEDeliveryConsent: value.acceptedEDeliveryConsent,
    eConsentSignedOn:
      value.eConsentSignedOn === undefined
        ? undefined
        : value.eConsentSignedOn === null
        ? null
        : value.eConsentSignedOn.toISOString(),
    eConsentSignedBy: value.eConsentSignedBy,
    doesNotRequireFMCSAInsurance: value.doesNotRequireFMCSAInsurance,
    doesNotUsePowerUnitsForLMD: value.doesNotUsePowerUnitsForLMD,
    doesNotHaulRestrictedCommodities: value.doesNotHaulRestrictedCommodities,
  };
}
