/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  GenerateTokenChangeEmailResponse,
  GenerateTokenChangeEmailResponseFromJSON,
  GenerateTokenChangeEmailResponseFromJSONTyped,
  GenerateTokenChangeEmailResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface GenerateTokenChangeEmailResponseApiResponse
 */
export interface GenerateTokenChangeEmailResponseApiResponse {
  /**
   *
   * @type {GenerateTokenChangeEmailResponse}
   * @memberof GenerateTokenChangeEmailResponseApiResponse
   */
  data?: GenerateTokenChangeEmailResponse;
  /**
   *
   * @type {ErrorResponse}
   * @memberof GenerateTokenChangeEmailResponseApiResponse
   */
  error?: ErrorResponse;
}

export function GenerateTokenChangeEmailResponseApiResponseFromJSON(
  json: any,
): GenerateTokenChangeEmailResponseApiResponse {
  return GenerateTokenChangeEmailResponseApiResponseFromJSONTyped(json, false);
}

export function GenerateTokenChangeEmailResponseApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenerateTokenChangeEmailResponseApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : GenerateTokenChangeEmailResponseFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function GenerateTokenChangeEmailResponseApiResponseToJSON(
  value?: GenerateTokenChangeEmailResponseApiResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: GenerateTokenChangeEmailResponseToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
