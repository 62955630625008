import { DownloadIcon, EditableDeletableCard, PdfIcon } from "@chq/components";
import { CircularProgress, fade, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.2rem",
    borderRadius: "0.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
  },
  containerClass: {
    padding: 0,
  },
  pdfItemContainer: {
    padding: "1rem 0.75rem 0.25rem 0.75rem",
    alignItems: "flex-start",
  },
  buttonContainer: {
    flexGrow: 1,
    textAlign: "center",
  },
  icon: {
    width: "auto",
    height: "60px",
    color: theme.palette.grey[400],
  },
  iconBadge: {
    position: "absolute",
    top: "-2px",
    right: "12px",
    width: "20px",
    height: "20px",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.dark,
  },
  fileName: {
    textAlign: "center",
    fontSize: "0.9rem",
    lineHeight: "1.3rem",
    fontWeight: 400,
    overflowWrap: "break-word",
  },
  iconButton: {
    height: "40px",
    padding: "0rem",
  },
  loadingText: {
    textAlign: "center",
  },
  inactiveLink: {
    pointerEvents: "none",
    cursor: "default",
  },
}));

export type File = {
  id?: number;
  name?: string;
  publicUri?: string | null;
  storageUri?: string | null;
  documentType?: string;
  regenerateThisFile?: boolean;
};

export type DocumentCardProps = {
  files: File[];
  isLoading?: boolean;
};

const DocumentCard: React.FC<DocumentCardProps> = ({ files, isLoading = false }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <EditableDeletableCard
      variant="default"
      border={false}
      title={t("enrollment.quote-overview-page.pdf-download.title")}
      titleVariant="h2"
      containerClass={classes.containerClass}
      className={classes.container}
    >
      {isLoading ? (
        <Grid container>
          <Grid container direction="column" spacing={2} alignItems={"center"}>
            <Grid item>
              <CircularProgress
                color="primary"
                size="2rem"
                aria-label={t("enrollment.quote-overview-page.pdf-download.loading")}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1" className={classes.loadingText}>
                {t("enrollment.quote-overview-page.pdf-download.loading")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row">
          {files.map((file, index) => {
            return (
              <Grid
                container
                item
                direction="row"
                xs={3}
                justify="space-between"
                className={classes.pdfItemContainer}
                key={`document-${file.name}-${index}`}
              >
                <Grid item container direction="column" alignContent="center">
                  <Grid item className={classes.buttonContainer}>
                    <a
                      href={String(file.publicUri)}
                      target="_blank"
                      rel="noreferrer"
                      className={file.publicUri ? "" : classes.inactiveLink}
                    >
                      <IconButton
                        className={classes.iconButton}
                        aria-label={t("enrollment.quote-overview-page.pdf-download.download-button")}
                      >
                        <PdfIcon className={classes.icon} />
                        <DownloadIcon className={classes.iconBadge} />
                      </IconButton>
                    </a>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" component="p" className={classes.fileName}>
                      {file.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </EditableDeletableCard>
  );
};

export default DocumentCard;
