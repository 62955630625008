import { OperatingAuthorityStatus } from "@chq/enrollment-api";

export const useOperatingAuthorityStatues = (): OperatingAuthorityStatus[] => {
  return [
    OperatingAuthorityStatus.AuthorizeForHire,
    OperatingAuthorityStatus.ApplicationSubmitted,
    OperatingAuthorityStatus.Pending,
    OperatingAuthorityStatus.NotAuthorized,
    OperatingAuthorityStatus.Inactive,
    OperatingAuthorityStatus.Unknown,
  ];
};
