/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BooleanApiResponse,
  BooleanApiResponseFromJSON,
  BooleanApiResponseToJSON,
  StripeBalanceApiResponse,
  StripeBalanceApiResponseFromJSON,
  StripeBalanceApiResponseToJSON,
  StripeBankAccountApiResponse,
  StripeBankAccountApiResponseFromJSON,
  StripeBankAccountApiResponseToJSON,
  StripeBankAccountCreateRequest,
  StripeBankAccountCreateRequestFromJSON,
  StripeBankAccountCreateRequestToJSON,
  StripeBankAccountListApiResponse,
  StripeBankAccountListApiResponseFromJSON,
  StripeBankAccountListApiResponseToJSON,
  StripeChargeListApiResponse,
  StripeChargeListApiResponseFromJSON,
  StripeChargeListApiResponseToJSON,
  StripeCustomerApiResponse,
  StripeCustomerApiResponseFromJSON,
  StripeCustomerApiResponseToJSON,
  StripePayoutApiResponse,
  StripePayoutApiResponseFromJSON,
  StripePayoutApiResponseToJSON,
} from "../models";

export interface ApiV10StripeChargeCustomerGetRequest {
  userId?: number;
  amount?: number;
  description?: string;
  limit?: number;
}

export interface ApiV10StripeCreateBankAccountPostRequest {
  stripeBankAccountCreateRequest?: StripeBankAccountCreateRequest;
}

export interface ApiV10StripePayCustomerGetRequest {
  userId?: number;
  amount?: number;
  description?: string;
}

export interface ApiV10StripeVerifyBankAccountPostRequest {
  deposit1?: number;
  deposit2?: number;
}

/**
 *
 */
export class StripeApi extends runtime.BaseAPI {
  /**
   * Gets the Stripe account balance
   */
  async apiV10StripeBalanceGetRaw(): Promise<runtime.ApiResponse<StripeBalanceApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Stripe/balance`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StripeBalanceApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets the Stripe account balance
   */
  async apiV10StripeBalanceGet(): Promise<StripeBalanceApiResponse> {
    const response = await this.apiV10StripeBalanceGetRaw();
    return await response.value();
  }

  /**
   * Charges a customer the given amount
   */
  async apiV10StripeChargeCustomerGetRaw(
    requestParameters: ApiV10StripeChargeCustomerGetRequest,
  ): Promise<runtime.ApiResponse<StripeChargeListApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.userId !== undefined) {
      queryParameters["userId"] = requestParameters.userId;
    }

    if (requestParameters.amount !== undefined) {
      queryParameters["amount"] = requestParameters.amount;
    }

    if (requestParameters.description !== undefined) {
      queryParameters["description"] = requestParameters.description;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Stripe/chargeCustomer`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StripeChargeListApiResponseFromJSON(jsonValue));
  }

  /**
   * Charges a customer the given amount
   */
  async apiV10StripeChargeCustomerGet(
    requestParameters: ApiV10StripeChargeCustomerGetRequest,
  ): Promise<StripeChargeListApiResponse> {
    const response = await this.apiV10StripeChargeCustomerGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Adds a bank account to the current logged in user\'s Stripe account.
   */
  async apiV10StripeCreateBankAccountPostRaw(
    requestParameters: ApiV10StripeCreateBankAccountPostRequest,
  ): Promise<runtime.ApiResponse<StripeBankAccountApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Stripe/createBankAccount`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: StripeBankAccountCreateRequestToJSON(requestParameters.stripeBankAccountCreateRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StripeBankAccountApiResponseFromJSON(jsonValue));
  }

  /**
   * Adds a bank account to the current logged in user\'s Stripe account.
   */
  async apiV10StripeCreateBankAccountPost(
    requestParameters: ApiV10StripeCreateBankAccountPostRequest,
  ): Promise<StripeBankAccountApiResponse> {
    const response = await this.apiV10StripeCreateBankAccountPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Creates a stripe customer account for the logged in user.
   */
  async apiV10StripeCreateCustomerPostRaw(): Promise<runtime.ApiResponse<StripeCustomerApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Stripe/createCustomer`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StripeCustomerApiResponseFromJSON(jsonValue));
  }

  /**
   * Creates a stripe customer account for the logged in user.
   */
  async apiV10StripeCreateCustomerPost(): Promise<StripeCustomerApiResponse> {
    const response = await this.apiV10StripeCreateCustomerPostRaw();
    return await response.value();
  }

  /**
   * Returns all the bank accounts associated with the current Stripe user
   */
  async apiV10StripeGetBankAccountsGetRaw(): Promise<runtime.ApiResponse<StripeBankAccountListApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Stripe/getBankAccounts`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StripeBankAccountListApiResponseFromJSON(jsonValue));
  }

  /**
   * Returns all the bank accounts associated with the current Stripe user
   */
  async apiV10StripeGetBankAccountsGet(): Promise<StripeBankAccountListApiResponse> {
    const response = await this.apiV10StripeGetBankAccountsGetRaw();
    return await response.value();
  }

  /**
   * Gets the StripeCustomer account for the currently logged in user.
   */
  async apiV10StripeGetCustomerGetRaw(): Promise<runtime.ApiResponse<StripeCustomerApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Stripe/getCustomer`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StripeCustomerApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets the StripeCustomer account for the currently logged in user.
   */
  async apiV10StripeGetCustomerGet(): Promise<StripeCustomerApiResponse> {
    const response = await this.apiV10StripeGetCustomerGetRaw();
    return await response.value();
  }

  /**
   * Pays a customer the given amount
   */
  async apiV10StripePayCustomerGetRaw(
    requestParameters: ApiV10StripePayCustomerGetRequest,
  ): Promise<runtime.ApiResponse<StripePayoutApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.userId !== undefined) {
      queryParameters["userId"] = requestParameters.userId;
    }

    if (requestParameters.amount !== undefined) {
      queryParameters["amount"] = requestParameters.amount;
    }

    if (requestParameters.description !== undefined) {
      queryParameters["description"] = requestParameters.description;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Stripe/payCustomer`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StripePayoutApiResponseFromJSON(jsonValue));
  }

  /**
   * Pays a customer the given amount
   */
  async apiV10StripePayCustomerGet(
    requestParameters: ApiV10StripePayCustomerGetRequest,
  ): Promise<StripePayoutApiResponse> {
    const response = await this.apiV10StripePayCustomerGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10StripeVerifyBankAccountPostRaw(
    requestParameters: ApiV10StripeVerifyBankAccountPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.deposit1 !== undefined) {
      queryParameters["deposit1"] = requestParameters.deposit1;
    }

    if (requestParameters.deposit2 !== undefined) {
      queryParameters["deposit2"] = requestParameters.deposit2;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Stripe/verifyBankAccount`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10StripeVerifyBankAccountPost(
    requestParameters: ApiV10StripeVerifyBankAccountPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10StripeVerifyBankAccountPostRaw(requestParameters);
    return await response.value();
  }
}
