import { AlertType, PageAlert } from "@chq/components";
import { Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core/";
import { Formik } from "formik";
import React from "react";
import { Trans } from "react-i18next";
import ResetPasswordEmail, {
  Fields as ResetPassFields,
  useFormikConfig as useResetPassFormikConfig,
} from "./components/reset-password-email";
import ResetPasswordEmailConfirmation from "./components/reset-password-email-confirmation";
import { ResetPasswordErrorMessage, useResetPassword } from "./useResetPassword";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: "0rem 1rem 0rem 1rem",
    margin: "auto",
  },
  pageAlert: {
    background: "linear-gradient(to right, rgba(204,0,0,0.15), rgba(204,0,0,0.15))",
    backgroundColor: "rgba(255,255,255,0.8)",
  },
}));

enum ErrorStatus {
  noError = 0,
  noAccount,
  accountNoPassword,
}

type Props = {
  backToLoginOnClick: () => void;
};

export const ForgotPasswordForm: React.FC<Props> = ({ backToLoginOnClick }) => {
  const classes = useStyles();
  const resetPassFormikProps = useResetPassFormikConfig();
  const [{ error: resetPasswordError, value: isSuccess }, resetPassword] = useResetPassword();
  let error: ErrorStatus = ErrorStatus.noError;

  if (resetPasswordError?.message === ResetPasswordErrorMessage.noPasswordFound) {
    error = ErrorStatus.accountNoPassword;
  } else if (resetPasswordError?.message === ResetPasswordErrorMessage.noAccountFound) {
    error = ErrorStatus.noAccount;
  }

  return (
    <>
      {error === ErrorStatus.noAccount && (
        <PageAlert
          alertType={AlertType.error}
          className={classes.pageAlert}
          message={
            <Typography variant="h4" component="p">
              <Trans i18nKey={"login-page.no-account-error"}>
                <Typography variant="h4" component="span"></Typography>
                <Link href={window.location.origin + "/enrollment"}></Link>
                <Typography variant="h4" component="span"></Typography>
              </Trans>
            </Typography>
          }
        />
      )}
      {error === ErrorStatus.accountNoPassword && (
        <PageAlert
          alertType={AlertType.error}
          className={classes.pageAlert}
          message={
            <Typography variant="h4" component="p">
              <Trans i18nKey={"login-page.account-no-password-error"}>
                <Typography variant="h4" component="span"></Typography>
                <Link href={window.location.origin + "/enrollment"}></Link>
                <Typography variant="h4" component="span"></Typography>
              </Trans>
            </Typography>
          }
        />
      )}
      <Formik {...resetPassFormikProps} onSubmit={(values) => resetPassword(values[ResetPassFields.email])}>
        {(formik) => (
          <Grid item className={classes.form} xs={12} sm={8} lg={5}>
            {!isSuccess ? (
              <ResetPasswordEmail backToLoginOnClick={backToLoginOnClick} />
            ) : (
              <ResetPasswordEmailConfirmation
                email={formik.values[ResetPassFields.email]}
                onClick={backToLoginOnClick}
              />
            )}
          </Grid>
        )}
      </Formik>
    </>
  );
};
