import { EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { BillingCycle } from "@chq/enrollment-api";
import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    padding: "0.5rem 0.5rem 0rem",
  },
  moneyContainer: {
    [theme.breakpoints.up("sm")]: {
      margin: "0.5rem",
    },
  },
  payment: {
    marginRight: "0.5rem",
    color: theme.palette.success.main,
  },
  paymentText: {
    marginBottom: "0.1rem",
  },
  error: {
    color: theme.palette.error.main,
  },
  downPayment: {
    marginRight: "0.1rem",
    lineHeight: "1.5rem",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      lineHeight: "1rem",
    },
  },
  downPaymentText: {
    lineHeight: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      lineHeight: "1rem",
    },
  },
  factoringText: {
    lineHeight: "1rem",
  },
  addDotButton: {
    marginTop: "0.625rem",
    minHeight: "3.5rem",
  },
  defaultPadding: {
    marginTop: "0.5rem",
  },
}));

export type Props = {
  quotePayment?: string | number;
  downPayment?: string | number;
  billingCycle?: string;
  dotNumber?: string | number | null;
  editable?: boolean;
  onEdit?: EditableDeletableCardProps["onEdit"];
  onAddDotNumber?: React.MouseEventHandler<HTMLButtonElement>;
};

const ReviewYourQuoteCard: React.FC<Props> = ({
  quotePayment,
  downPayment,
  billingCycle,
  dotNumber,
  editable,
  onEdit,
  onAddDotNumber,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <EditableDeletableCard
      title={t("review-application-page.your-quote-card.title")}
      variant={editable ? "edit" : "default"}
      onEdit={onEdit}
      editText={t("review-application-page.your-quote-card.edit-application")}
      editTextVariant="h4"
      blueEdit={true}
      titleVariant="h2"
      elevation={0}
      border={false}
      className={classes.cardContainer}
      IconButtonProps={{ "aria-label": t("review-application-page.your-quote-card.edit-button") }}
    >
      <Grid container direction="column">
        <Grid container alignItems="flex-end" className={classes.moneyContainer}>
          <Trans i18nKey={`review-application-page.your-quote-card.${billingCycle?.toLowerCase()}-payment`}>
            <Typography variant="h1" className={classNames(classes.payment, { [classes.error]: !dotNumber })}>
              {{ money: quotePayment }}
            </Typography>
            <Typography variant="h3" component="p" className={classes.paymentText}></Typography>
          </Trans>
        </Grid>
        {billingCycle === BillingCycle.Factoring && (
          <Grid container alignItems="flex-end" className={classes.moneyContainer}>
            <Typography variant="body1" component="p" className={classes.factoringText}>
              {t("review-application-page.your-quote-card.factoring-downpayment-text")}
            </Typography>
          </Grid>
        )}
        {downPayment && (
          <Grid container alignItems="flex-end" className={classes.moneyContainer}>
            <Trans i18nKey={"review-application-page.your-quote-card.down-payment"}>
              <Typography variant="body1" className={classNames(classes.downPayment, { [classes.error]: !dotNumber })}>
                {{ money: downPayment }}
              </Typography>
              <Typography variant="body1" component="p" className={classes.downPaymentText}></Typography>
            </Trans>
          </Grid>
        )}
        {!dotNumber && (
          <>
            <Typography variant="subtitle1" component="p" className={classes.error}>
              {t("review-application-page.your-quote-card.dot-number-required")}
            </Typography>
            <Button variant="outlined" color="primary" className={classes.addDotButton} onClick={onAddDotNumber}>
              {t("review-application-page.your-quote-card.dot-button")}
            </Button>
          </>
        )}
      </Grid>
    </EditableDeletableCard>
  );
};

export default ReviewYourQuoteCard;
