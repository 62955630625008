import { EditableDeletableCard } from "@chq/components";
import { Badge, Divider, fade, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1rem 1.5rem 1rem 1.5rem",
    borderRadius: "0.5rem",
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    width: "100%",
  },
  containerClass: {
    padding: "0",
  },
  containerWeekly: {
    paddingTop: "1.5rem",
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.common.white}`,
    "& *, $total": {
      color: theme.palette.common.white,
    },
    "& $divider": {
      backgroundColor: fade(theme.palette.common.white, 0.5),
    },
  },
  badge: {
    display: "flex",
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      fontSize: "0.9rem",
      fontWeight: "700",
      padding: "1rem 0.75rem",
      top: "-12px",
      left: "12px",
      marginRight: "12px",
      transform: "none",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.75rem",
      },
    },
    "& .MuiBadge-invisible": {
      display: "none",
    },
  },
  title: {
    paddingBottom: "0.5rem",
  },
  total: {
    color: theme.palette.primary.main,
  },
  titleTotalError: {
    color: theme.palette.error.main,
  },
  summary: {
    padding: "0 0.5rem",
    rowGap: "0.2rem",
  },
  textSmaller: {
    fontSize: ".9rem",
  },
  divider: {
    height: "2px",
    margin: "0.75rem 0",
    backgroundColor: fade(theme.palette.primary.dark, 0.25),
  },
  downpaymentFactoring: {
    padding: "0.5rem 0 0 0",
  },
}));

export type QuoteField = {
  title: string;
  total: string | number;
};

export enum CardVariant {
  default = "default",
  weekly = "weekly",
  error = "error",
}

type Props = {
  title: string;
  total: string | number;
  variant?: CardVariant;
  summaryItems?: QuoteField[];
  downPayment?: QuoteField;
};

const QuoteSummaryCard: React.FC<Props> = ({
  title,
  total,
  variant = CardVariant.default,
  summaryItems = [],
  downPayment = { title: "", total: 0 },
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Badge
      badgeContent={
        variant === CardVariant.weekly ? t("enrollment.quote-overview-page.quote-summary.weekly-value-prop") : 0
      }
      className={classes.badge}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      showZero={false}
    >
      <EditableDeletableCard
        variant="default"
        border={false}
        className={classNames(classes.container, {
          [classes.containerWeekly]: variant === CardVariant.weekly,
        })}
        containerClass={classes.containerClass}
      >
        <Grid container direction="column">
          <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.title}>
            <Grid item>
              <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h1"
                className={classNames(classes.total, {
                  [classes.titleTotalError]: variant === CardVariant.error,
                })}
              >
                {t("common.currency", { money: total })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.summary}>
          {summaryItems.map((item, index) => {
            return (
              <Grid
                key={`quote-summary-${index}`}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h4" component="p" className={classes.textSmaller}>
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" component="p" className={classes.textSmaller}>
                    {t("common.currency", { money: item.total, cents: true })}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
          <Divider flexItem className={classes.divider} />
          <Grid>
            <Grid
              key={`quote-summary-downPayment`}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h4" component="p" className={classes.textSmaller}>
                  {downPayment.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" component="p" className={classes.textSmaller}>
                  {t("common.currency", { money: downPayment.total, cents: true })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {variant === CardVariant.weekly && (
            <Grid>
              <Typography variant="body2" align="center" className={classes.downpaymentFactoring}>
                {t("enrollment.quote-overview-page.quote-summary.downpayment-factoring")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </EditableDeletableCard>
    </Badge>
  );
};

export default QuoteSummaryCard;
