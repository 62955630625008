/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ApplicationCheck
 */
export interface ApplicationCheck {
  /**
   *
   * @type {string}
   * @memberof ApplicationCheck
   */
  email?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationCheck
   */
  doesApplicationExist?: boolean;
}

export function ApplicationCheckFromJSON(json: any): ApplicationCheck {
  return ApplicationCheckFromJSONTyped(json, false);
}

export function ApplicationCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationCheck {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, "email") ? undefined : json["email"],
    doesApplicationExist: !exists(json, "doesApplicationExist") ? undefined : json["doesApplicationExist"],
  };
}

export function ApplicationCheckToJSON(value?: ApplicationCheck | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    doesApplicationExist: value.doesApplicationExist,
  };
}
