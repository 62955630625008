import { BooleanApiResponse } from "@chq/lastmiledelivery-api";
import { PolicyApi, PortalApiV10PolicySendGeotabRequestEmailPostRequest } from "@chq/policy-management-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi as usePolicyApi } from "./usePolicyManagementApi";

export const useSendGeotabRequest = (): UseMutationResult<
  BooleanApiResponse,
  Error | unknown,
  PortalApiV10PolicySendGeotabRequestEmailPostRequest
> => {
  const policyApi = usePolicyApi(PolicyApi);
  const mutation = useMutation(async (request: PortalApiV10PolicySendGeotabRequestEmailPostRequest) => {
    const response = await policyApi.portalApiV10PolicySendGeotabRequestEmailPost(request);
    return response;
  });
  return mutation;
};
