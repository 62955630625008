import { AlertType, DownloadIcon, LoadingOverlay, PageAlert } from "@chq/components";
import { ApplicationStatus } from "@chq/enrollment-api";
import { Button, CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { startOfToday } from "date-fns";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import GenericPageAlert from "../../../components/generic-page-alert";
import { useBindPolicy, useGetApplicationReview } from "../../../data/enrollment";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";
import QuoteSavedPageContent from "../components/quote-saved-page-content";

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    minHeight: "56px",
  },
  submitButtonContainer: {
    padding: "1.125rem 0rem",
  },
  textCenter: {
    textAlign: "center",
  },
  buttonContainer: {
    padding: "0.625rem 0rem 0.375rem",
    margin: ".25rem auto",
  },
  downloadButton: {
    ...theme.typography.h4,
    margin: "1rem 0 0",
    minHeight: "40px",
    backgroundColor: theme.palette.secondary.light,
  },
  icon: {
    width: "auto",
    height: "30px",
  },
}));

const QuoteHeader: React.FC<{ applicationStatus?: ApplicationStatus }> = ({ applicationStatus }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  switch (applicationStatus) {
    case ApplicationStatus.ApprovedToBind:
      return (
        <Typography variant="body1" className={classes.textCenter}>
          {t("quote-saved-page.valid-text", { days: 45 })}
        </Typography>
      );
    case ApplicationStatus.Submitted:
      return (
        <Typography variant="body1" className={classes.textCenter}>
          {t("quote-saved-page.wait-text", { days: 45 })}
        </Typography>
      );
    default:
      return null;
  }
};

const QuotePendingPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const today = startOfToday();

  const { data: applicationReview, isFetching: isLoadingApplication } = useGetApplicationReview(
    RouteNames.quotePending,
  );
  const application = applicationReview?.application;
  const isApprovedToBind = application?.applicationStatus === ApplicationStatus.ApprovedToBind;
  const { mutate: bindPolicy, isLoading: isBindingPolicy, isSuccess, isError, error } = useBindPolicy();

  if (application?.insuranceProgram?.active === false) {
    return <Redirect to={routes.enrollment.quote.inactive.path} />;
  }

  if (isSuccess) {
    return <Redirect to={routes.enrollment.quote.bound.path} />;
  }

  return (
    <>
      {isBindingPolicy && <LoadingOverlay title={t("quote-saved-page.binding-policy")} />}
      {isError &&
        (error?.message ? <PageAlert alertType={AlertType.error} message={error.message} /> : <GenericPageAlert />)}
      {application?.desiredEffectiveDate && application?.desiredEffectiveDate < today && (
        <GenericPageAlert errorTranslationKey="review-application-page.date-past-error" />
      )}
      {applicationReview?.application?.currentQuote?.expirationDate &&
        applicationReview?.application?.currentQuote?.expirationDate <= today && (
          <GenericPageAlert errorTranslationKey="review-application-page.quote-expired-error" />
        )}
      <QuoteSavedPageContent
        bound={false}
        applicationReview={applicationReview}
        isLoadingApplication={isLoadingApplication}
        topComponent={
          <Grid item xs={10}>
            <QuoteHeader applicationStatus={application?.applicationStatus} />
          </Grid>
        }
        bottomComponent={
          <>
            {isApprovedToBind && application?.qualified && application?.insuranceProgram?.active ? (
              <Grid item xs={12} className={classes.submitButtonContainer}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.submitButton}
                  disabled={
                    isBindingPolicy ||
                    (application?.desiredEffectiveDate ? application?.desiredEffectiveDate < today : true)
                  }
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "bindPolicyEvent",
                        bindPolicyText: "Bind Policy",
                      },
                    });
                    if (application?.qualified) bindPolicy();
                  }}
                >
                  {isBindingPolicy ? (
                    <CircularProgress
                      color="inherit"
                      size="2rem"
                      aria-label={t("common.circular-progress-aria-label")}
                    />
                  ) : (
                    t("quote-saved-page.approved-button")
                  )}
                </Button>
              </Grid>
            ) : (
              <>
                <Grid container item xs={12} spacing={1} className={classes.buttonContainer} justify="center">
                  <Grid item xs={6} md={4} lg={3}>
                    <Button
                      href={String(application?.currentQuote?.sfaargProposalDoc?.publicUri)}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                      component="a"
                      fullWidth
                      variant="contained"
                      disableElevation
                      className={classes.downloadButton}
                    >
                      <DownloadIcon className={classes.icon} />
                      {t("review-application-page.download-pdf")}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth className={classes.submitButton} disabled={true}>
                    {t("quote-saved-page.pending-button")}
                  </Button>
                </Grid>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default QuotePendingPage;
