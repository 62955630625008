import { makeStyles, Paper, PaperProps, Theme, Typography } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: "center",
    padding: "0.75rem 1.5rem",
  },
  errorContainer: {
    backgroundColor: fade(theme.palette.error.main, 0.1),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  successContainer: {
    backgroundColor: fade(theme.palette.success.main, 0.1),
  },
  successText: {
    color: theme.palette.success.main,
  },
}));

export enum AlertType {
  success = "success",
  error = "error",
}

type Props = Omit<PaperProps, "elevation" | "square"> & {
  alertType: AlertType;
  message: string | React.ReactElement;
};

const PageAlert: React.FC<Props> = ({ message, alertType, ...PaperProps }) => {
  const classes = useStyles();

  return (
    <Paper
      {...PaperProps}
      square
      className={classNames(classes.container, PaperProps.className, {
        [classes.errorContainer]: alertType === AlertType.error,
        [classes.successContainer]: alertType === AlertType.success,
      })}
      elevation={0}
    >
      <Typography
        variant="h4"
        className={classNames({
          [classes.errorText]: alertType === AlertType.error,
          [classes.successText]: alertType === AlertType.success,
        })}
      >
        {message}
      </Typography>
    </Paper>
  );
};

export default PageAlert;
