import { PencilIcon, SuccessIcon } from "@chq/components";
import { Divider, Grid, IconButton, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatBankAccountNumber } from "../utils/format-bank-account-number";

const useStyles = makeStyles((theme: Theme) => ({
  warningContainer: {
    display: "flex",
  },
  bankText: {
    fontSize: ".9rem",
  },
  editIcon: {
    height: "2rem",
    width: "auto",
  },
  iconButton: {
    padding: "0px",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  accountNumberText: {
    color: theme.palette.grey[400],
  },
  connectYourAccountContainer: {
    padding: "0rem 0rem 0.5rem",
  },
  accountNumberContainer: {
    padding: "0.625rem 0rem",
  },
  successIcon: {
    color: theme.palette.success.main,
    width: "auto",
    height: "40px",
  },
  verifiedContainer: {
    padding: "0.625rem 0.625rem 0rem",
    "& .MuiTypography-root": {
      textAlign: "center",
    },
  },
}));

type Props = {
  onAddNewBankAccount: () => void;
  bankName: string;
  accountNumber: string;
};

const VerifyAccountCompleteCard: React.FC<Props> = ({ accountNumber, bankName, onAddNewBankAccount }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.verifiedContainer}>
      <Grid container direction="column">
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.connectYourAccountContainer}
        >
          <Grid item>
            <Typography component="h3" variant="h2">
              {t("review-application-page.payment-method-card.bank-account-label")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              className={classes.iconButton}
              aria-label={t("review-application-page.payment-method-card.edit-your-bank")}
              onClick={onAddNewBankAccount}
            >
              <PencilIcon color="action" className={classes.editIcon} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justify="space-between"
          className={classes.accountNumberContainer}
        >
          <Grid container item alignItems="center">
            <Typography variant="h4" className={classes.bankText}>
              {bankName}
            </Typography>
          </Grid>
          <Grid item container direction="row" justify="flex-end" alignItems="center">
            <Grid item>
              <Typography variant="h4" className={classes.bankText}>
                {formatBankAccountNumber(accountNumber)}
              </Typography>
            </Grid>
            <Grid item className={classes.warningContainer}>
              <SuccessIcon
                className={classes.successIcon}
                aria-label={t("finish-application.payment-method-form.verified-account.success-icon-label")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VerifyAccountCompleteCard;
