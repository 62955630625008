import {
  FactoringApiV10FactoringDocumentAddDocumentPostRequest,
  FactoringApiV10FactoringDocumentDeleteDocumentDeleteRequest,
  FactoringApplicationApi,
  FactoringDocumentApi,
} from "@chq/factoring-api";
import { useMutation, UseMutationResult } from "react-query";
import { useCachedFactoringApplication } from ".";
import { useFactoringApi } from "../useFactoringApi";

export const useDeleteFactoringDocument = (): UseMutationResult<
  void,
  Error,
  Omit<FactoringApiV10FactoringDocumentDeleteDocumentDeleteRequest, "applicationId">
> => {
  const documentApi = useFactoringApi(FactoringDocumentApi);
  const applicationApi = useFactoringApi(FactoringApplicationApi);
  const { application, updateApplication } = useCachedFactoringApplication();
  const mutation = useMutation<void, Error, FactoringApiV10FactoringDocumentAddDocumentPostRequest>(
    async (request: Omit<FactoringApiV10FactoringDocumentDeleteDocumentDeleteRequest, "applicationId">) => {
      await documentApi.factoringApiV10FactoringDocumentDeleteDocumentDelete({
        applicationId: application?.id,
        ...request,
      });
      const response = await applicationApi.factoringApiV10FactoringApplicationGetApplicationForCurrentUserGet();
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};
