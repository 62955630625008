import {
  createStyles,
  lighten,
  makeStyles,
  Step,
  StepIcon,
  StepIconProps,
  StepLabel,
  Stepper,
  StepperProps,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { SuccessIcon } from "./icons";

type Props = Omit<StepperProps, "alternativeLabel" | "children"> & {
  steps: string[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    successIcon: {
      position: "relative",
      color: theme.palette.success.main,
      fontSize: "1.5rem",
      height: "3rem",
      width: "auto",
      bottom: "9px",
      right: "8px",
    },
    greyOutlineSuccess: {
      height: "2rem",
      width: "2rem",
      backgroundColor: lighten(theme.palette.secondary.main, 0.97),
      borderRadius: "50%",
    },
    greyOutline: {
      height: "2rem",
      width: "2rem",
      backgroundColor: lighten(theme.palette.secondary.main, 0.97),
      borderRadius: "50%",
    },
  }),
);

const StepIndicatorIcon: React.FC<StepIconProps> = ({ ...StepIconProps }) => {
  const classes = useStyles();
  const { completed } = StepIconProps;

  return (
    <div className={classes.greyOutline}>
      {completed ? (
        <SuccessIcon className={classes.successIcon} data-testid="success" />
      ) : (
        <StepIcon {...StepIconProps} data-testid="step" />
      )}
    </div>
  );
};

const StepIndicator: React.FC<Props> = ({ className, steps, ...StepperProps }) => {
  const classes = useStyles();

  return (
    <Stepper className={classNames(classes.root, className)} alternativeLabel {...StepperProps}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepIndicatorIcon}>
            <Typography variant="h4">{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepIndicator;
