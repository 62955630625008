import { ButtonProps, SvgIconProps } from "@material-ui/core";
import React from "react";
import ArrowRightIcon from "../icons/arrow-right";
import CommonButton from "./common-button";

type Props = ButtonProps & {
  iconProps?: SvgIconProps;
};

const NextButton: React.FC<Props> = ({ iconProps, ...buttonProp }) => {
  return <CommonButton {...buttonProp} endIcon={<ArrowRightIcon viewBox="4 4 16 16" {...iconProps} />} />;
};

export default NextButton;
