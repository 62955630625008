import { ApiV10ApplicationQuoteBreakdownPostRequest, ApplicationApi, BooleanApiResponse } from "@chq/enrollment-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useSendQuoteBreakdownEmail = (): UseMutationResult<
  BooleanApiResponse,
  Error | unknown,
  ApiV10ApplicationQuoteBreakdownPostRequest
> => {
  const applicationApi = useApi(ApplicationApi);
  const mutation = useMutation(async (request: ApiV10ApplicationQuoteBreakdownPostRequest) => {
    const response = await applicationApi.apiV10ApplicationQuoteBreakdownPost(request);

    return response;
  });
  return mutation;
};
