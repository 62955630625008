/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputAdornment, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import CheckIcon from "./icons/check";
import EyeIcon from "./icons/eye";
import XIcon from "./icons/x";
import TextInput, { TextInputProps } from "./text-input";

export interface RegexPasswordRequirement {
  humanReadableFormat: string;
  regEx: RegExp | boolean;
}

type Props = Omit<TextInputProps, "children|type"> & {
  rules?: RegexPasswordRequirement[];
  value?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  passwordRequirements: {
    listStyle: "none",
    margin: "0 0 3px 0",
    padding: 0,
  },
  requirement: {
    display: "flex",
    alignItems: "center",
  },
  requirementIcon: {
    padding: "3px",
  },
  invisible: {
    color: "transparent",
  },
  passwordRuleFail: {
    color: theme.palette.error.main,
  },
  passwordRuleSuccess: {
    color: theme.palette.success.main,
  },
  rules: {
    fontWeight: 400,
  },
  eye: {
    cursor: "pointer",
  },
}));

const evaluateRules = (rules: RegexPasswordRequirement[], password: string): boolean[] => {
  return rules.map(({ regEx }) => {
    if (typeof regEx === "boolean") {
      return regEx;
    }
    return regEx.test(password);
  });
};

const PasswordInput: React.FC<Props> = ({ rules, onChange, InputProps, value, ...props }) => {
  const [password, setPassword] = useState(value || "");
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [ruleResults, setRuleResults] = useState<boolean[]>(() => {
    if (rules) {
      return evaluateRules(rules, password);
    }
    return [];
  });
  const styles = useStyles();

  useEffect(() => {
    if (rules) {
      setRuleResults(evaluateRules(rules, password));
    }
  }, [password, rules]);

  return (
    <TextInput
      {...props}
      type={isPasswordVisible ? "text" : "password"}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end" className={styles.eye}>
            <EyeIcon color={"disabled"} onClick={() => setIsPasswordVisible(!isPasswordVisible)} fontSize={"large"} />
          </InputAdornment>
        ),
      }}
      value={password}
      onChange={(e: any) => {
        setPassword(e.target.value);
        if (onChange) onChange(e);
      }}
      labelEmbellishment={
        rules && (
          <ul className={styles.passwordRequirements}>
            {rules?.map(({ humanReadableFormat }, index) => {
              const result = rules && ruleResults[index];
              return (
                <li className={styles.requirement} key={"rule" + index}>
                  {result ? (
                    <CheckIcon
                      className={classNames(styles.passwordRuleSuccess, styles.requirementIcon)}
                      data-testid={`success-visible-${index}`}
                    />
                  ) : (
                    <XIcon
                      className={classNames(styles.requirementIcon, {
                        [styles.invisible]: password === "",
                        [styles.passwordRuleFail]: password !== "",
                      })}
                      data-testid={`fail-${password === "" ? "invisible" : "visible"}-${index}`}
                    />
                  )}
                  <Typography
                    variant="h4"
                    component="p"
                    className={classNames(
                      styles.rules,
                      password !== "" ? (result ? styles.passwordRuleSuccess : styles.passwordRuleFail) : "",
                    )}
                  >
                    {humanReadableFormat}
                  </Typography>
                </li>
              );
            })}
          </ul>
        )
      }
    />
  );
};

export default PasswordInput;
