import { NextButton } from "@chq/components";
import { Grid, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    backgroundColor: theme.palette.common.white,
    padding: "2.25rem 1rem 1rem",
  },
  warningIcon: {
    height: "50px",
    width: "auto",
  },
  header: {
    margin: "1rem 0rem 0.5rem",
  },
  body: {
    marginBottom: "2rem",
  },
  phoneText: {
    marginBottom: "1.5rem",
  },
  continueButton: {
    minHeight: "3.5rem",
  },
  bold: {
    fontWeight: "bold",
  },
  link: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

type Props = {
  onClick: () => void;
};

export const NoDotWarningModal: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Paper elevation={0} className={classes.modalContainer}>
      <Grid container justify="center">
        <Grid container item xs={12} justify="center">
          <Grid>
            <WarningIcon color="error" fontSize="large" className={classes.warningIcon} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.header}>
          <Typography variant="h2">{t("enrollment.qualify-page.no-dot-modal.dot-number-required")}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.body}>
          <Typography variant="body1">{t("enrollment.qualify-page.no-dot-modal.body")}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.phoneText}>
          <Trans i18nKey={"enrollment.qualify-page.no-dot-modal.call-this"}>
            <Typography variant="body1" className={classes.bold} component="span"></Typography>
            <Link href={"tel:866-621-4145"} className={classes.link}>
              enrollment.qualify-page.no-dot-modal.call-this
            </Link>
          </Trans>
        </Grid>
        <Grid item xs={12}>
          <NextButton
            fullWidth
            color="primary"
            variant="contained"
            className={classes.continueButton}
            onClick={onClick}
          >
            {t("enrollment.qualify-page.no-dot-modal.continue-button")}
          </NextButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NoDotWarningModal;
