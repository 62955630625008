import { StepIndicator } from "@chq/components";
import { makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    padding: "0rem",
  },
}));

export enum EnrollmentSteps {
  unknown = -1,
  company = 0,
  equipment = 1,
  drivers = 2,
  coverage = 3,
}

export const useEnrollmentSteps = () => {
  const [t] = useTranslation();
  return [
    EnrollmentSteps.company,
    EnrollmentSteps.equipment,
    EnrollmentSteps.drivers,
    EnrollmentSteps.coverage,
  ].map((step) => t(`enrollment.steps.${step}`));
};

export const useCurrentEnrollmentStep = () => {
  const location = useLocation();
  const routeMap = {
    [routes.enrollment.basicInfo.path]: EnrollmentSteps.company,
    [routes.enrollment.equipment.path]: EnrollmentSteps.equipment,
    [routes.enrollment.drivers.path]: EnrollmentSteps.drivers,
    [routes.enrollment.coverage.path]: EnrollmentSteps.coverage,
  };
  return routeMap[location.pathname.toLowerCase()] >= 0
    ? routeMap[location.pathname.toLowerCase()]
    : EnrollmentSteps.unknown;
};

export const EnrollmentStepper = () => {
  const classes = useStyles();
  const steps = useEnrollmentSteps();
  const currentStep = useCurrentEnrollmentStep();
  if (currentStep === EnrollmentSteps.unknown) {
    return null;
  } else return <StepIndicator steps={steps} activeStep={currentStep} className={classes.stepper} />;
};
