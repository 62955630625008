import { EditableDeletableCard, PasswordInput } from "@chq/components";
import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { usePasswordRules } from "../data/usePasswordRules";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: "1rem",
  },
  container: {
    paddingTop: "0.75rem",
    borderBottom: `solid .0625rem ${theme.palette.secondary.light}`,
  },
  button: {
    padding: ".5rem 1rem",
  },
  buttonContainer: {
    paddingTop: ".625rem",
  },
  passwordContainer: {
    paddingTop: "1.5rem",
  },
  form: {
    padding: "0rem 1rem 0rem 1rem",
    margin: "auto",
  },
}));

export enum Fields {
  newPassword = "new-password",
  reEnterPassword = "re-enter-password",
}
export type FormProps = {
  [Fields.newPassword]: string;
  [Fields.reEnterPassword]: string;
};

type Props = {
  newPassword?: string;
  reEnterPassword?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
  backToLoginOnClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();
  const passwordRules = usePasswordRules();

  return Yup.object({
    [Fields.newPassword]: passwordRules,
    [Fields.reEnterPassword]: Yup.string()
      .oneOf([Yup.ref([Fields.newPassword].toString())], "")
      .required(t(`reset-password-form.${Fields.reEnterPassword}.help-text`)),
  });
};

export const useFormikConfig = ({
  newPassword: initialNewPassword = "",
  reEnterPassword: initialReEnterPassword = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.newPassword]: initialNewPassword,
      [Fields.reEnterPassword]: initialReEnterPassword,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const ResetPasswordForm: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  return (
    <Grid item className={classes.form} xs={12} sm={8} lg={5}>
      <EditableDeletableCard
        variant="default"
        title={t("reset-password-form.title")}
        titleVariant="h3"
        className={classes.title}
      >
        <Grid container>
          <form onSubmit={formik.handleSubmit}>
            <Grid container className={classes.passwordContainer}>
              <Grid item xs={12}>
                <PasswordInput
                  fullWidth
                  required
                  label={t("reset-password-form.new-password.label")}
                  rules={[
                    {
                      humanReadableFormat: t("common.password-rules.min-length"),
                      regEx: /.{8,}/,
                    },
                    {
                      humanReadableFormat: t("common.password-rules.uppercase"),
                      regEx: /[A-Z]/,
                    },
                    { humanReadableFormat: t("common.password-rules.digit"), regEx: /[0-9]/ },
                  ]}
                  id={Fields.newPassword}
                  name={Fields.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[Fields.newPassword] && Boolean(formik.errors[Fields.newPassword])}
                  value={formik.values[Fields.newPassword]}
                  helperText={formik.touched[Fields.newPassword] && formik.errors[Fields.newPassword]}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  fullWidth
                  required
                  label={t("reset-password-form.re-enter-password.label")}
                  rules={[
                    {
                      humanReadableFormat: t("common.password-rules.match"),
                      regEx: formik.values[Fields.newPassword]
                        ? formik.values[Fields.newPassword] === formik.values[Fields.reEnterPassword]
                        : false,
                    },
                  ]}
                  id={Fields.reEnterPassword}
                  name={Fields.reEnterPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[Fields.reEnterPassword] && Boolean(formik.errors[Fields.reEnterPassword])}
                  value={formik.values[Fields.reEnterPassword]}
                  helperText={formik.touched[Fields.reEnterPassword] && formik.errors[Fields.reEnterPassword]}
                />
              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  {t("reset-password-form.reset-password-button")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </EditableDeletableCard>
    </Grid>
  );
};

export default ResetPasswordForm;
