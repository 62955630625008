import { CardField, EditableDeletableCard, EligibilityStates } from "@chq/components";
import { BillingCycle, StringApiResponse } from "@chq/enrollment-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { PlaidLinkOnEvent, PlaidLinkOnExit, PlaidLinkOnSuccess } from "react-plaid-link";
import { ChangeYourBankAccountLink } from "../../ui/policy-management/components/change-your-bank-account";
import EligibilityStatusWithTrans from "./eligibility-status-with-trans";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textTransform: "uppercase",
    paddingTop: "1.25rem",
  },
  titleContainer: {
    textAlign: "center",
  },
  unitCardContainer: {
    width: "100%",
    paddingTop: "1.25rem",
  },
  button: {
    padding: "1.2rem",
    marginBottom: "1rem",
  },
  buttonContainer: {
    margin: "0rem 0rem",
  },
  messageContainer: {
    textAlign: "center",
    alignSelf: "center",
    width: "100%",
    margin: "0.5rem 0rem 0.5rem",
  },
  bankInformation: {
    paddingTop: "0.2rem",
  },
}));

type Props = {
  paymentMethod?: string;
  paymentCycle?: BillingCycle;
  bankAccountInfo?: string;
  token?: StringApiResponse;
  plaidOnEvent: PlaidLinkOnEvent;
  plaidOnExit: PlaidLinkOnExit;
  plaidOnSuccess: PlaidLinkOnSuccess;
  plaidSuccess?: boolean;
  complete?: boolean;
  stripeBankAccountAdded?: boolean;
  isUpdatingStripeAccount?: boolean;
};

const PaymentMethodCard: React.FC<Props> = ({
  paymentMethod,
  paymentCycle,
  bankAccountInfo,
  token,
  plaidOnEvent,
  plaidOnExit,
  plaidOnSuccess,
  plaidSuccess,
  complete,
  stripeBankAccountAdded,
  isUpdatingStripeAccount,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <EditableDeletableCard variant="default" elevation={1} border={false}>
      <Grid container>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h4" className={classes.title}>
            {t("payment-method-card.title")}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.unitCardContainer}>
          <CardField
            id="paymentMethod"
            className={classes.bankInformation}
            values={[{ value: paymentMethod }]}
            label={t("payment-method-card.title")}
            nullFiller="---"
          />
          <CardField
            id="paymentCycle"
            className={classes.bankInformation}
            values={[{ value: paymentCycle }]}
            label={t("payment-method-card.payment-cycle")}
            nullFiller="---"
          />
          <CardField
            id="bankAccount"
            className={classes.bankInformation}
            values={[{ value: bankAccountInfo }]}
            label={t("payment-method-card.bank-account")}
            nullFiller="---"
          />
        </Grid>
        <Grid container item xs={12} direction="row" alignContent="center" className={classes.messageContainer}>
          {!plaidSuccess && (
            <Grid item xs={12}>
              <EligibilityStatusWithTrans
                status={EligibilityStates.INELIGIBLE}
                statusText={"payment-method-card.connection-failed"}
              />
            </Grid>
          )}
          {plaidSuccess && complete && stripeBankAccountAdded && (
            <Grid item xs={12}>
              <EligibilityStatusWithTrans
                status={EligibilityStates.ELIGIBLE}
                statusText={"payment-method-card.connection-success"}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <ChangeYourBankAccountLink
            onSuccess={plaidOnSuccess}
            onEvent={plaidOnEvent}
            onExit={plaidOnExit}
            token={token!.data!}
            isUpdatingStripeAccount={isUpdatingStripeAccount}
          />
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default PaymentMethodCard;
