import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

const ArrowRightIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M7.20001 11.352V12.552H14.4L11.1 15.852L11.952 16.704L16.704 11.952L11.952 7.20001L11.1 8.05201L14.4 11.352H7.20001Z" />
    </SvgIcon>
  );
};

export default ArrowRightIcon;
