import { Checkbox, StyleLabel, TextInput } from "@chq/components";
import { TermsAndConditionsLinks } from "@chq/enrollment-api";
import { Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: "1.125rem",
    textAlign: "center",
  },
  subtitle: {
    paddingTop: "1.5rem",
    textAlign: "center",
  },
  checkbox: {
    width: "100%",
    paddingLeft: "1rem",
    display: "flex",
    whiteSpace: "pre-wrap",

    "& .MuiFormControlLabel-root": {
      alignItems: "start",

      "& .MuiFormControlLabel-label": {
        marginTop: "0.875rem",
      },
    },
  },
  haulingExclusions: {
    flexDirection: "column",
  },
  haulingExclusionsList: {
    marginTop: ".75rem",
  },
  speedGauge: {
    display: "flex",
    flexWrap: "nowrap",
  },
  background: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: "2rem",
  },
  signLabel: {},
  signature: {
    margin: "2rem auto auto auto",
  },
}));

export enum Fields {
  doesNotHaulRestrictedCommodities = "doesNotHaulRestrictedCommodities",
  legalAuthority = "legal-authority",
  accurateInfo = "accurate-info",
  termsConditions = "terms-conditions",
  umTermsConditions = "um-terms-conditions",
  fraudStatement = "fraud-statement",
  rrgAgreement = "rrg-agreement",
  speedGauge = "speed-gauge",
  fmcsaInsurance = "fmcsa-insurance",
  powerUnitExclusion = "power-unit-exclusion",
  signature = "signature",
}

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.doesNotHaulRestrictedCommodities]: yup.boolean().required().oneOf([true]),
    [Fields.legalAuthority]: yup.boolean().required().oneOf([true]),
    [Fields.accurateInfo]: yup.boolean().required().oneOf([true]),
    [Fields.termsConditions]: yup.boolean().required().oneOf([true]),
    [Fields.umTermsConditions]: yup.boolean().required().oneOf([true]),
    [Fields.fraudStatement]: yup.boolean().required().oneOf([true]),
    [Fields.rrgAgreement]: yup.boolean().required().oneOf([true]),
    [Fields.speedGauge]: yup.boolean().required().oneOf([true]),
    [Fields.fmcsaInsurance]: yup.boolean().required().oneOf([true]),
    [Fields.powerUnitExclusion]: yup.boolean().required().oneOf([true]),
    [Fields.signature]: yup.string().when(Fields.doesNotHaulRestrictedCommodities, {
      is: (doesNotHaulRestrictedCommodities?: boolean) => {
        return Boolean(doesNotHaulRestrictedCommodities);
      },
      then: yup.string().required(t(`errors.required`, { field: t("disclosures-form.signature") })),
      otherwise: yup.string(),
    }),
  });
};
export const useFormikConfig = ({
  doesNotHaulRestrictedCommodities: initialRestrictedCommodities = false,
  legalAuthority: initialLegalAuthority = false,
  accurateInfo: initialAccurateInfo = false,
  termsConditions: initialTermsConditions = false,
  umTermsConditions: initialUmTermsConditions = false,
  fraudStatement: initialFraudStatment = false,
  rrgAgreement: initialRrgAgreement = false,
  speedGauge: initialSpeedGauge = false,
  fmcsaInsurance: initialFmcsaInsurance = false,
  powerUnitExclusion: initialPowerUnitExclusion = false,
  signature: initialSignature = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.doesNotHaulRestrictedCommodities]: initialRestrictedCommodities,
      [Fields.legalAuthority]: initialLegalAuthority,
      [Fields.accurateInfo]: initialAccurateInfo,
      [Fields.termsConditions]: initialTermsConditions,
      [Fields.umTermsConditions]: initialUmTermsConditions,
      [Fields.fraudStatement]: initialFraudStatment,
      [Fields.rrgAgreement]: initialRrgAgreement,
      [Fields.speedGauge]: initialSpeedGauge,
      [Fields.fmcsaInsurance]: initialFmcsaInsurance,
      [Fields.powerUnitExclusion]: initialPowerUnitExclusion,
      [Fields.signature]: initialSignature,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    enableReinitialize: true,
  };
};
export type FormProps = {
  [Fields.doesNotHaulRestrictedCommodities]?: boolean;
  [Fields.legalAuthority]?: boolean;
  [Fields.accurateInfo]?: boolean;
  [Fields.termsConditions]?: boolean;
  [Fields.umTermsConditions]?: boolean;
  [Fields.fraudStatement]?: boolean;
  [Fields.rrgAgreement]?: boolean;
  [Fields.speedGauge]?: boolean;
  [Fields.fmcsaInsurance]?: boolean;
  [Fields.powerUnitExclusion]?: boolean;
  [Fields.signature]?: string;
};

type Props = {
  doesNotHaulRestrictedCommodities?: boolean;
  legalAuthority?: boolean;
  accurateInfo?: boolean;
  termsConditions?: boolean;
  umTermsConditions?: boolean;
  fraudStatement?: boolean;
  rrgAgreement?: boolean;
  speedGauge?: boolean;
  fmcsaInsurance?: boolean;
  powerUnitExclusion?: boolean;
  signature?: string;
  terms?: TermsAndConditionsLinks;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const DisclosuresForm: React.FC<Props> = ({ terms }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<FormProps>();

  return (
    <form onSubmit={formik.handleSubmit} className={classes.background}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" color="primary" className={classes.title}>
            {t("disclosures-form.title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="h2" className={classes.subtitle}>
            {t("disclosures-form.subtitle")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.legalAuthority}
            name={Fields.legalAuthority}
            label={t("disclosures-form.checkbox-1")}
            required
            value={formik.values[Fields.legalAuthority]}
            checked={formik.values[Fields.legalAuthority]}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.accurateInfo}
            name={Fields.accurateInfo}
            label={t("disclosures-form.checkbox-2")}
            required
            value={formik.values[Fields.accurateInfo]}
            checked={formik.values[Fields.accurateInfo]}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.termsConditions}
            name={Fields.termsConditions}
            label={
              <Trans i18nKey={"disclosures-form.checkbox-3"}>
                <Typography>disclosures-form.checkbox-3</Typography>
                <Link target="_blank" href={terms?.termsOfUseLink || "#"}>
                  disclosures-form.checkbox-3-link
                </Link>
              </Trans>
            }
            required
            value={formik.values[Fields.termsConditions]}
            checked={formik.values[Fields.termsConditions]}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.umTermsConditions}
            name={Fields.umTermsConditions}
            label={
              <Trans i18nKey={"disclosures-form.checkbox-4"}>
                <Typography>disclosures-form.checkbox-4</Typography>
                <Link target="_blank" href={terms?.umUimTermsLink || "#"}>
                  disclosures-form.checkbox-4-link
                </Link>
              </Trans>
            }
            required
            value={formik.values[Fields.umTermsConditions]}
            checked={formik.values[Fields.umTermsConditions]}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.fraudStatement}
            name={Fields.fraudStatement}
            label={
              <Trans i18nKey={"disclosures-form.checkbox-5"}>
                <Typography>disclosures-form.checkbox-5</Typography>
                <Link target="_blank" href={terms?.fraudStatementLink || "#"}>
                  disclosures-form.checkbox-5-link
                </Link>
              </Trans>
            }
            required
            value={formik.values[Fields.fraudStatement]}
            checked={formik.values[Fields.fraudStatement]}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.rrgAgreement}
            name={Fields.rrgAgreement}
            label={
              <Trans i18nKey={"disclosures-form.checkbox-6"}>
                <Typography>disclosures-form.checkbox-6</Typography>
                <Link target="_blank" href={terms?.rrgAgreementLink || "#"}>
                  disclosures-form.checkbox-6-link
                </Link>
              </Trans>
            }
            required
            value={formik.values[Fields.rrgAgreement]}
            checked={formik.values[Fields.rrgAgreement]}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.speedGauge}
            name={Fields.speedGauge}
            label={
              <Typography component="p">
                <Trans i18nKey={"disclosures-form.checkbox-7"}>
                  <Typography component="span">disclosures-form.checkbox-7</Typography>
                  <Link target="_blank" href={terms?.speedGaugeTermsLink || "#"}>
                    disclosures-form.checkbox-7-link
                  </Link>
                  <Typography component="span"></Typography>
                </Trans>
              </Typography>
            }
            required
            value={formik.values[Fields.speedGauge]}
            checked={formik.values[Fields.speedGauge]}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.fmcsaInsurance}
            name={Fields.fmcsaInsurance}
            label={
              <Typography component="p">
                <Trans i18nKey={"disclosures-form.checkbox-8"}>
                  <Typography component="span">disclosures-form.checkbox-8</Typography>
                  <Link target="_blank" href={"#"}>
                    disclosures-form.checkbox-8-link
                  </Link>
                  <Typography component="span"></Typography>
                </Trans>
              </Typography>
            }
            required
            value={formik.values[Fields.fmcsaInsurance]}
            checked={formik.values[Fields.fmcsaInsurance]}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.powerUnitExclusion}
            name={Fields.powerUnitExclusion}
            label={
              <Typography component="p">
                <Trans i18nKey={"disclosures-form.checkbox-9"}>
                  <Typography component="span">disclosures-form.checkbox-9</Typography>
                  <Link target="_blank" href={"#"}>
                    disclosures-form.checkbox-9-link
                  </Link>
                  <Typography component="span"></Typography>
                </Trans>
              </Typography>
            }
            required
            value={formik.values[Fields.powerUnitExclusion]}
            checked={formik.values[Fields.powerUnitExclusion]}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.doesNotHaulRestrictedCommodities}
            name={Fields.doesNotHaulRestrictedCommodities}
            label={
              <div className={classes.haulingExclusions}>
                <Typography component="p">
                  <Trans i18nKey={"disclosures-form.checkbox-10.label"}>
                    <Typography component="span">disclosures-form.checkbox-restricted-commodities-label</Typography>
                    <Link target="_blank" href={"#"}>
                      disclosures-form.checkbox-restricted-commodities-link
                    </Link>
                    <Typography component="span"></Typography>
                  </Trans>
                </Typography>
                <Typography className={classes.haulingExclusionsList} component="ul">
                  <Typography component="li">{t("disclosures-form.checkbox-10.item-1")}</Typography>
                  <Typography component="li">{t("disclosures-form.checkbox-10.item-2")}</Typography>
                  <Typography component="li">{t("disclosures-form.checkbox-10.item-3")}</Typography>
                  <Typography component="li">{t("disclosures-form.checkbox-10.item-4")}</Typography>
                  <Typography component="li">{t("disclosures-form.checkbox-10.item-5")}</Typography>
                  <Typography component="li">{t("disclosures-form.checkbox-10.item-6")}</Typography>
                </Typography>
              </div>
            }
            required
            value={formik.values[Fields.doesNotHaulRestrictedCommodities]}
            checked={formik.values[Fields.doesNotHaulRestrictedCommodities]}
            onChange={formik.handleChange}
          />
        </Grid>
        {formik.values[Fields.doesNotHaulRestrictedCommodities] && (
          <Grid item xs={10} sm={3} className={classes.signature}>
            <StyleLabel variant="h4" className={classes.signLabel} labelText={t("disclosures-form.sign")} />
            <TextInput
              fullWidth
              inputProps={{
                "aria-label": t("disclosures-form.sign"),
              }}
              id={Fields.signature}
              value={formik.values[Fields.signature]}
              onChange={formik.handleChange}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default DisclosuresForm;
