import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import LoadingWheel from "./loading-wheel";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    background: "rgba(255, 255, 255, 0.8)",
    position: "fixed",
    zIndex: 9,
    height: "100vh",
    width: "100vw",
    top: 0,
  },
  loading: {
    animation: `$rotation 2s infinite linear`,
    minHeight: "8.5rem",
    width: "auto",
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(359deg)",
    },
  },
  title: {
    fontSize: "2rem",
    marginTop: "1.5rem",
  },
}));

type Props = {
  title: string;
};

const LoadingOverlay: React.FC<Props> = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.overlay}>
      <div className={classes.content}>
        <LoadingWheel className={classes.loading} />
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default LoadingOverlay;
