import { AlertType, DownloadIcon, PageAlert } from "@chq/components";
import { Equipment, EquipmentType, State, StripeAccountStatus } from "@chq/enrollment-api";
import { Button, CircularProgress, Divider, Grid, makeStyles, Theme } from "@material-ui/core";
import { startOfToday } from "date-fns";
import { Formik } from "formik";
import _ from "lodash";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import { FormProps as AdditionalInsuredFormProps } from "../../../components/additional-insured-form";
import { Fields } from "../../../components/address-form";
import CompanyDetailsCard from "../../../components/company-details-card";
import DisclosuresForm, {
  Fields as DisclosuresFormFields,
  useFormikConfig as useDisclosuresFormFormikConfig,
} from "../../../components/disclosures-form";
import DriversCard from "../../../components/drivers-card";
import GaragingForm, {
  Fields as GaragingFormFields,
  specialGaragingStates,
  useFormikConfig as useGaragingFormFormikConfig,
} from "../../../components/garaging-form";
import GenericPageAlert from "../../../components/generic-page-alert";
import IntrastateFilingForm, {
  Fields as IntrastateFilingFormFields,
  useFormikConfig as useIntrastateFilingFormFormikConfig,
} from "../../../components/intrastate-filing-form";
import { FormProps as LossPayeeFormProps } from "../../../components/loss-payee-form";
import { Fields as QualificationFormFields } from "../../../components/qualification-form";
import ReviewEffectiveDateCard, {
  Fields as ReviewEffectiveDateFields,
  useFormikConfig as useReviewEffectiveDateFormikConfig,
} from "../../../components/review-effective-date-card";
import ReviewPaymentMethodCard from "../../../components/review-payment-method-card";
import {
  CheckboxFields,
  Fields as ReviewPowerUnitsFormFields,
  useFormikConfig as useReviewPowerUnitsFormikConfig,
} from "../../../components/review-power-unit-trailer-form";
import ReviewPowerUnitsForm from "../../../components/review-power-units-trailers-form";
import ReviewYourQuoteCard from "../../../components/review-your-quote-card";
import {
  useGetApplicationReview,
  useGetFactoringApplication,
  useSubmitApplication,
  useTermsAndConditionLinks,
} from "../../../data/enrollment";
import { getQuoteByCycle } from "../../../utils/get-quote-items";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.25rem 0.625rem 0rem",
    alignItems: "start",
    "& > .MuiGrid-root": {
      marginTop: "0.25rem",
    },
    margin: "auto",
  },
  titleItem: {
    margin: "auto",
  },
  button: {
    margin: "auto",
  },
  submitButton: {
    minHeight: "56px",
  },
  submitButtonContainer: {
    padding: "1.125rem 0rem",
    margin: "2rem auto 2rem auto",
  },
  dividerSection: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    margin: "auto",
  },
  divider: { width: "100%", height: "1px" },
  downloadButton: {
    ...theme.typography.h4,
    minHeight: "40px",
    backgroundColor: theme.palette.secondary.light,
  },
  icon: {
    width: "auto",
    height: "30px",
  },
  buttonContainer: {
    padding: "0.625rem 0rem 0.375rem",
    margin: "4px",
  },
  leftButton: {
    marginRight: "0.625rem",
  },
}));

const additionalInsuredMapper = (additionalInsured?: AdditionalInsuredFormProps) => {
  return _.isEmpty(additionalInsured)
    ? []
    : [
        {
          name: additionalInsured?.[Fields.entityName],
          einNumber: additionalInsured?.[Fields.ein],
          address: {
            address1: additionalInsured?.[Fields.addressLine1],
            address2: additionalInsured?.[Fields.addressLine2],
            city: additionalInsured?.[Fields.city],
            state: additionalInsured?.[Fields.state] as State,
            zip: additionalInsured?.[Fields.zipcode],
          },
        },
      ];
};

const lossPayeeMapper = (lossPayee?: LossPayeeFormProps) => {
  return _.isEmpty(lossPayee)
    ? []
    : [
        {
          name: lossPayee?.[Fields.entityName],
          address: {
            address1: lossPayee?.[Fields.addressLine1],
            address2: lossPayee?.[Fields.addressLine2],
            city: lossPayee?.[Fields.city],
            state: lossPayee?.[Fields.state] as State,
            zip: lossPayee?.[Fields.zipcode],
          },
        },
      ];
};

const ReviewApplicationPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const today = startOfToday();
  const history = useHistory();
  const { data: factoringApplication, isFetching: isLoadingFactoringApplication } = useGetFactoringApplication();
  const { data: terms } = useTermsAndConditionLinks();

  const { data: applicationReview, isFetching: isLoadingApplication } = useGetApplicationReview(RouteNames.review);
  const application = applicationReview?.application;

  const disclosuresFormConfig = useDisclosuresFormFormikConfig({
    signature: application?.acknowledgements?.printedName || "",
    legalAuthority: application?.acknowledgements?.hasLegalAuthority || false,
    doesNotHaulRestrictedCommodities: application?.acknowledgements?.doesNotHaulRestrictedCommodities || false,
    accurateInfo: application?.acknowledgements?.accuracyVerified || false,
    termsConditions: application?.acknowledgements?.acceptedTermsAndConditions || false,
    umTermsConditions: application?.acknowledgements?.acceptedUmUimTerms || false,
    fraudStatement: application?.acknowledgements?.acceptedFraudStatement || false,
    rrgAgreement: application?.acknowledgements?.acceptedRrgAgreement || false,
    speedGauge: application?.acknowledgements?.acceptedSpeedgauge || false,
    fmcsaInsurance: application?.acknowledgements?.doesNotRequireFMCSAInsurance || false,
    powerUnitExclusion: application?.acknowledgements?.doesNotUsePowerUnitsForLMD || false,
  });

  const intrastateFormConfig = useIntrastateFilingFormFormikConfig({ intrastateFilingAuthority: "" });

  const garagingState = applicationReview?.application?.business?.garagingAddressSameAsBusiness
    ? applicationReview?.application?.business?.address?.state
    : applicationReview?.application?.business?.garagingAddress?.state;

  const garagingFormConfig = useGaragingFormFormikConfig({
    garagingState: garagingState as State,
  });

  const reviewEffectiveDateConfig = useReviewEffectiveDateFormikConfig({
    effectiveDate: application?.desiredEffectiveDate,
  });

  const equipment = (application?.coveredEquipment || []).map((unit: Equipment) => {
    const hasAdditionalInsured = Boolean(unit.additionalInsured && unit.additionalInsured.length);
    const hasLossPayee = Boolean(unit.lossPayees && unit.lossPayees.length);
    const additionalInsured = hasAdditionalInsured
      ? {
          [Fields.entityName]: unit.additionalInsured![0]!.name,
          [Fields.ein]: unit.additionalInsured![0]!.einNumber,
          [Fields.addressLine1]: unit.additionalInsured![0]!.address?.address1,
          [Fields.addressLine2]: unit.additionalInsured![0]!.address?.address2,
          [Fields.city]: unit.additionalInsured![0]!.address?.city,
          [Fields.state]: unit.additionalInsured![0]!.address?.state,
          [Fields.zipcode]: unit.additionalInsured![0]!.address?.zip,
        }
      : undefined;
    const lossPayee = hasLossPayee
      ? {
          [Fields.entityName]: unit.lossPayees![0]!.name,
          [Fields.addressLine1]: unit.lossPayees![0]!.address?.address1,
          [Fields.addressLine2]: unit.lossPayees![0]!.address?.address2,
          [Fields.city]: unit.lossPayees![0]!.address?.city,
          [Fields.state]: unit.lossPayees![0]!.address?.state,
          [Fields.zipcode]: unit.lossPayees![0]!.address?.zip,
        }
      : undefined;
    return {
      unitInfo: unit,
      [ReviewPowerUnitsFormFields.additionalInsured]: additionalInsured,
      [CheckboxFields.additionalInsuredCheckbox]: hasAdditionalInsured,
      [CheckboxFields.additionalInsuredComplete]: hasAdditionalInsured,
      [ReviewPowerUnitsFormFields.lossPayee]: lossPayee,
      [CheckboxFields.lossPayeeCheckbox]: hasLossPayee,
      [CheckboxFields.lossPayeeComplete]: hasLossPayee,
    };
  });

  const reviewPowerUnitsFormConfig = useReviewPowerUnitsFormikConfig({
    powerUnits: equipment.filter((equipment) => equipment.unitInfo?.equipmentType === EquipmentType.PowerUnit),
    trailers: equipment.filter((equipment) => equipment.unitInfo?.equipmentType === EquipmentType.Trailer),
  });

  const quote = getQuoteByCycle(
    applicationReview?.application?.currentQuote?.rates,
    applicationReview?.application?.billingCycle,
  );

  const {
    mutate: submitApplication,
    isLoading: isSubmittingApplication,
    isSuccess,
    isError,
    error,
  } = useSubmitApplication();

  if (isSuccess) {
    return <Redirect push to={routes.enrollment.quote.pending.path} />;
  }

  if (application?.insuranceProgram?.active === false) {
    return <Redirect to={routes.enrollment.quote.inactive.path} />;
  }

  return (
    <>
      {isError &&
        (error?.message ? <PageAlert alertType={AlertType.error} message={error.message} /> : <GenericPageAlert />)}
      {applicationReview?.application?.currentQuote?.expirationDate &&
        applicationReview?.application?.currentQuote?.expirationDate <= today && (
          <GenericPageAlert errorTranslationKey="review-application-page.quote-expired-error" />
        )}
      {!application?.qualified && qualifyRedirect(application?.ineligibleReason || "")}
      <Grid item container className={classes.container} xs={12} sm={10} spacing={2}>
        <Formik
          initialValues={{
            ...disclosuresFormConfig.initialValues,
            ...reviewPowerUnitsFormConfig.initialValues,
            ...garagingFormConfig.initialValues,
            [QualificationFormFields.dotNum]: application?.business?.dotNumber,
            [IntrastateFilingFormFields.intrastateFilingAuthority]: application?.intrastateFilingAuthority,
            [ReviewEffectiveDateFields.effectiveDate]: application?.desiredEffectiveDate,
            bankAccountStatus: applicationReview?.bankAccount?.accountStatus,
          }}
          enableReinitialize={true}
          validationSchema={disclosuresFormConfig.validationSchema
            .concat(reviewPowerUnitsFormConfig.validationSchema)
            .concat(intrastateFormConfig.validationSchema)
            .concat(garagingFormConfig.validationSchema)
            .concat(reviewEffectiveDateConfig.validationSchema)
            .concat(
              yup
                .object({
                  [QualificationFormFields.dotNum]: yup
                    .string()
                    .required()
                    .test(
                      "length",
                      t("errors.between-digits", { field: t(`qualification-form.dot-num.label`), min: "5", max: "8" }),
                      (val) => (val ? val.toString().length >= 5 && val.toString().length <= 8 : true),
                    ),
                  [ReviewEffectiveDateFields.effectiveDate]: yup
                    .date()
                    .nullable()
                    .required(
                      t(`errors.required`, {
                        field: t(`enrollment.basic-info.fields.${ReviewEffectiveDateFields.effectiveDate}`),
                      }),
                    )
                    .typeError(
                      t("errors.date", {
                        field: t(`enrollment.basic-info.fields.${ReviewEffectiveDateFields.effectiveDate}`),
                      }),
                    )
                    .min(today, t("enrollment.basic-info.errors.min-effective-date")),
                })
                .concat(
                  yup.object({
                    bankAccountStatus: yup
                      .string()
                      .required()
                      .test("verified", "message", (val) => val === StripeAccountStatus.Verified),
                  }),
                ),
            )}
          validateOnMount={true}
          onSubmit={(values) => {
            if (!application?.qualified) {
              qualifyRedirect(application?.ineligibleReason || "");
            } else {
              TagManager.dataLayer({
                dataLayer: {
                  event: "submitApplicationEvent",
                  submitApplicationText: "Submit Application",
                },
              });
              const submitPowerUnits = (values.powerUnits || []).map(
                ({
                  unitInfo,
                  [ReviewPowerUnitsFormFields.additionalInsured]: additionalInsured,
                  [ReviewPowerUnitsFormFields.lossPayee]: lossPayee,
                }) => ({
                  ...unitInfo,
                  additionalInsured: additionalInsuredMapper(additionalInsured),
                  lossPayees: lossPayeeMapper(lossPayee),
                }),
              );
              const submitTrailers = (values.trailers || []).map(
                ({
                  unitInfo,
                  [ReviewPowerUnitsFormFields.additionalInsured]: additionalInsured,
                  [ReviewPowerUnitsFormFields.lossPayee]: lossPayee,
                }) => ({
                  ...unitInfo,
                  additionalInsured: additionalInsuredMapper(additionalInsured),
                  lossPayees: lossPayeeMapper(lossPayee),
                }),
              );
              const acknowledgements = {
                ...application?.acknowledgements,
                printedName: values[DisclosuresFormFields.signature],
                signature: values[DisclosuresFormFields.signature],
                hasLegalAuthority: values[DisclosuresFormFields.legalAuthority],
                accuracyVerified: values[DisclosuresFormFields.accurateInfo],
                acceptedTermsAndConditions: values[DisclosuresFormFields.termsConditions],
                acceptedUmUimTerms: values[DisclosuresFormFields.umTermsConditions],
                acceptedFraudStatement: values[DisclosuresFormFields.fraudStatement],
                acceptedRrgAgreement: values[DisclosuresFormFields.rrgAgreement],
                acceptedSpeedgauge: values[DisclosuresFormFields.speedGauge],
                doesNotRequireFMCSAInsurance: values[DisclosuresFormFields.fmcsaInsurance],
                doesNotUsePowerUnitsForLMD: values[DisclosuresFormFields.powerUnitExclusion],
                doesNotHaulRestrictedCommodities: values[DisclosuresFormFields.doesNotHaulRestrictedCommodities],
              };
              submitApplication({
                ...application,
                acknowledgements,
                desiredEffectiveDate: values[ReviewEffectiveDateFields.effectiveDate],
                coveredEquipment: [...submitPowerUnits, ...submitTrailers],
                intrastateFilingAuthority: values[IntrastateFilingFormFields.intrastateFilingAuthority],
                stateSpecificInput: values[GaragingFormFields.stateSpecificField],
              });
            }
          }}
        >
          {(formik) => {
            return (
              <>
                <Grid item xs={12} sm={6}>
                  <ReviewYourQuoteCard
                    editable={true}
                    dotNumber={applicationReview?.application?.business?.dotNumber}
                    quotePayment={quote.quotePayment}
                    downPayment={quote.downPayment}
                    billingCycle={applicationReview?.application?.billingCycle?.toString()}
                    onEdit={() => history.push(routes.enrollment.quote.path)}
                    onAddDotNumber={() => history.push(routes.enrollment.qualify.path)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReviewEffectiveDateCard quoteCreationDate={applicationReview?.quote?.quoteCreationDate} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReviewPaymentMethodCard
                    editable={true}
                    isLoading={isLoadingApplication || isLoadingFactoringApplication}
                    bankInfo={
                      applicationReview?.bankAccount?.accountStatus === StripeAccountStatus.Verified
                        ? applicationReview?.bankAccountInfo
                        : null
                    }
                    factoringStatus={application?.factoringApprovalStatus}
                    isFactoring={Boolean(factoringApplication)}
                    onEdit={() => history.push(routes.enrollment.payment.path)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CompanyDetailsCard
                    dotNumber={applicationReview?.application?.business?.dotNumber}
                    legalEntity={applicationReview?.application?.business?.legalEntity}
                    einNumber={applicationReview?.application?.business?.einNumber}
                    radiusOfOperation={applicationReview?.application?.business?.radiusOfOperation}
                  />
                </Grid>
                <Grid item container xs={12} className={classes.dividerSection}>
                  <Divider flexItem className={classes.divider} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReviewPowerUnitsForm equipmentType={EquipmentType.PowerUnit} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReviewPowerUnitsForm equipmentType={EquipmentType.Trailer} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DriversCard drivers={applicationReview?.application?.coveredIndividuals || []} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <IntrastateFilingForm state={garagingState} />
                </Grid>
                {specialGaragingStates.includes(garagingState as State) && (
                  <Grid item xs={12} sm={6}>
                    <GaragingForm />
                  </Grid>
                )}
                <Grid
                  container
                  item
                  xs={12}
                  spacing={1}
                  className={classes.buttonContainer}
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={6}>
                    <Button
                      href={String(application?.currentQuote?.sfaargProposalDoc?.publicUri)}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                      component="a"
                      fullWidth
                      variant="contained"
                      disableElevation
                      className={classes.downloadButton}
                    >
                      <DownloadIcon className={classes.icon} />
                      {t("review-application-page.download-pdf")}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item container xs={12} className={classes.dividerSection}>
                  <Divider flexItem className={classes.divider} />
                </Grid>
                <Grid item container justify="center" xs={12}>
                  <Grid item xs={12}>
                    <DisclosuresForm terms={terms} />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.submitButtonContainer}>
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.submitButton}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    disabled={
                      !formik.isValid || isSubmittingApplication || application?.insuranceProgram?.active === false
                    }
                  >
                    {isSubmittingApplication ? (
                      <CircularProgress
                        color="inherit"
                        size="2rem"
                        aria-label={t("common.circular-progress-aria-label")}
                      />
                    ) : (
                      t("enrollment.review-page.submit")
                    )}
                  </Button>
                </Grid>
              </>
            );
          }}
        </Formik>
      </Grid>
    </>
  );
};

export default ReviewApplicationPage;
