import { NumericInput, Select, TextInput } from "@chq/components";
import { Grid } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useStates } from "../data/useStates";

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.entityName]: Yup.string().required(
      t("errors.required", {
        field: t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.entityName}.label`),
      }),
    ),
    [Fields.addressLine1]: Yup.string().required(
      t("errors.required", {
        field: t(
          `review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.addressLine1}.required-text`,
        ),
      }),
    ),
    [Fields.addressLine2]: Yup.string(),
    [Fields.city]: Yup.string().required(
      t("errors.required", {
        field: t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.city}.label`),
      }),
    ),
    [Fields.state]: Yup.string().required(
      t("errors.required", {
        field: t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.state}.label`),
      }),
    ),
    [Fields.zipcode]: Yup.string()
      .test(
        "length",
        t("errors.exact-digits", {
          field: t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.zipcode}.label`),
          exact: 5,
        }),
        (val) => val !== undefined && val.toString().length === 5,
      )
      .required(
        t("errors.required", {
          field: t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.zipcode}.label`),
        }),
      ),
  });
};

export enum Fields {
  entityName = "entity-name",
  addressLine1 = "address-line-1",
  addressLine2 = "address-line-2",
  city = "city",
  state = "state",
  zipcode = "zipcode",
  ein = "ein",
}

export type FormProps = {
  [Fields.entityName]?: string | null;
  [Fields.addressLine1]?: string | null;
  [Fields.addressLine2]?: string | null;
  [Fields.city]?: string | null;
  [Fields.state]?: string | null;
  [Fields.zipcode]?: string | null;
};

export type Props = {
  entityName?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
};

export const useFormikConfig = ({
  entityName: initialEntityName = "",
  addressLine1: initialAddressLine1 = "",
  addressLine2: initialAddressLine2 = "",
  city: initialCity = "",
  state: initialState = "",
  zipcode: initialZipcode = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.entityName]: initialEntityName,
      [Fields.addressLine1]: initialAddressLine1,
      [Fields.addressLine2]: initialAddressLine2,
      [Fields.city]: initialCity,
      [Fields.state]: initialState,
      [Fields.zipcode]: initialZipcode,
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    enableReinitialize: true,
  };
};

const AddressForm: React.FC<Props> = () => {
  const [t] = useTranslation();
  const states = useStates();
  const formik = useFormikContext<FormProps>();

  return (
    <>
      <Grid item>
        <TextInput
          fullWidth
          required
          label={t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.entityName}.label`)}
          type="text"
          id={Fields.entityName}
          name={Fields.entityName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[Fields.entityName] && Boolean(formik.errors[Fields.entityName])}
          value={formik.values[Fields.entityName]}
          helperText={formik.touched[Fields.entityName] && formik.errors[Fields.entityName]}
        />
      </Grid>
      <Grid item>
        <TextInput
          fullWidth
          required
          label={t(
            `review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.addressLine1}.label`,
          )}
          type="text"
          id={Fields.addressLine1}
          name={Fields.addressLine1}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[Fields.addressLine1] && Boolean(formik.errors[Fields.addressLine1])}
          value={formik.values[Fields.addressLine1]}
          helperText={formik.touched[Fields.addressLine1] && formik.errors[Fields.addressLine1]}
        />
      </Grid>
      <Grid item>
        <TextInput
          fullWidth
          label={t(
            `review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.addressLine2}.label`,
          )}
          type="text"
          id={Fields.addressLine2}
          name={Fields.addressLine2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[Fields.addressLine2]}
        />
      </Grid>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={8}>
          <TextInput
            fullWidth
            required
            label={t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.city}.label`)}
            type="text"
            id={Fields.city}
            name={Fields.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.city] && Boolean(formik.errors[Fields.city])}
            value={formik.values[Fields.city]}
            helperText={formik.touched[Fields.city] && formik.errors[Fields.city]}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            fullWidth
            id={Fields.state}
            name={Fields.state}
            label={t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.state}.label`)}
            items={states.map((state) => ({
              name: state.abv,
              value: state.abv,
            }))}
            value={formik.values[Fields.state]}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.state] && Boolean(formik.errors[Fields.state])}
            helperText={formik.touched[Fields.state] && formik.errors[Fields.state]}
          />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <NumericInput
          fullWidth
          required
          label={t(`review-application-page.review-power-units-trailers-form.checkbox-form.${Fields.zipcode}.label`)}
          format="#####"
          id={Fields.zipcode}
          name={Fields.zipcode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[Fields.zipcode] && Boolean(formik.errors[Fields.zipcode])}
          value={formik.values[Fields.zipcode]}
          helperText={formik.touched[Fields.zipcode] && formik.errors[Fields.zipcode]}
        />
      </Grid>
    </>
  );
};

export default AddressForm;
