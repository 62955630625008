import { SaveButton } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import AddressForm, {
  FormProps as AddressFormProps,
  useFormikConfig as useAddressFormFormikConfig,
} from "./address-form";

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    padding: "0rem 0.125rem 1.125rem 0.25rem",
  },
  submitButton: {
    minHeight: "3.5rem",
  },
}));

export type FormProps = AddressFormProps;

type Props = {
  entityName?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  onSubmit: FormikConfig<AddressFormProps>["onSubmit"];
};

const LossPayeeForm: React.FC<Props> = ({
  entityName = "",
  addressLine1 = "",
  addressLine2 = "",
  city = "",
  state = "",
  zipcode = "",
  onSubmit,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const addressFormConfig = useAddressFormFormikConfig({
    entityName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipcode,
  });
  return (
    <Formik
      initialValues={{
        ...addressFormConfig.initialValues,
      }}
      enableReinitialize={true}
      validationSchema={addressFormConfig.validationSchema}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Grid container direction="column" className={classes.formContainer}>
            <AddressForm />
            <Grid item xs={12}>
              <SaveButton
                label={t("review-application-page.review-power-units-trailers-form.add-loss-payee.submit-button")}
                variant="outlined"
                fullWidth
                color="primary"
                onClick={() => formik.handleSubmit()}
                type="submit"
                disabled={!formik.isValid}
                formikValid={formik.isValid}
              />
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default LossPayeeForm;
