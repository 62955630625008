import { InputLabel, InputLabelProps, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";

type Props = InputLabelProps & {
  labelAdornment?: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "space-between",
    transform: "translate(0rem, -1.5rem)",
    "& .MuiInputLabel-outlined.Mui-error": {
      color: theme.palette.error.main,
    },
    textTransform: "capitalize",
  },
  labelAdornment: {
    position: "absolute",
    top: "-.25rem",
    right: 0,
  },
}));

export const Label: React.FC<Props> = ({ children, labelAdornment, className, ...InputLabelProps }) => {
  const styles = useStyles();

  return (
    <div className={classNames(styles.label, className)}>
      <InputLabel {...InputLabelProps}>{children}</InputLabel>
      {labelAdornment && <div className={styles.labelAdornment}>{labelAdornment}</div>}
    </div>
  );
};
