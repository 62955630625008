import { makeStyles, SvgIconProps } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import ArrowRightIcon from "./arrow-right";

const useStyles = makeStyles(() => ({
  root: {
    transform: "rotate(180deg)",
  },
}));

const ArrowLeftIcon: React.FC<SvgIconProps> = ({ className, ...props }) => {
  const classes = useStyles();
  return <ArrowRightIcon className={classNames(classes.root, className)} {...props}></ArrowRightIcon>;
};

export default ArrowLeftIcon;
