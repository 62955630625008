/* eslint-disable @typescript-eslint/no-explicit-any */
import { AlertType, PageAlert, PencilIcon as EditIcon } from "@chq/components";
import { EquipmentType } from "@chq/enrollment-api";
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import EquipmentCard from "../../../components/policy-management-components/equipment-card";
import { PolicyCoveragesContext } from "../../../data/policy-management/PolicyManagementCoveragesContext";
import { PolicyEquipmentContext } from "../../../data/policy-management/PolicyManagementEquipmentContext";
import { useGetPolicy } from "../../../data/policy-management/useGetPolicy";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "0rem 0.75rem 2.5rem",
  },
  manageButton: {
    marginTop: "2rem",
    marginBottom: "1.125rem",
    "& .MuiButton-label": {
      ...theme.typography.h4,
      color: theme.palette.common.white,
    },
  },
  editIcon: {
    height: "30px",
    width: "auto",
  },
  tabs: {
    width: "100%",
    "& .MuiTabs-indicator": {
      height: "4px",
    },
    "& .MuiTabs-scroller": {
      display: "flex",
      justifyContent: "center",
    },
    marginBottom: "1.125rem",
  },
  tab: {
    ...theme.typography.h2,
    width: "50%",
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
    color: theme.palette.grey[400],
  },
  tabPanel: {
    "& .MuiPaper-root": {
      marginBottom: "0.5rem",
    },
  },
  loadingPanel: {
    marginTop: "1rem",
  },
}));

function TabPanel(props: { value: number; index: number; className: string; children: any }) {
  const { children, value, index, className } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`equipment-page-tab-value-${index}`} className={className}>
      {value === index && children}
    </div>
  );
}

const EquipmentPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, isLoading, isError, error } = useGetPolicy();
  const { updatePolicyCoverages } = useContext(PolicyCoveragesContext);
  const { updatePolicyEquipment } = useContext(PolicyEquipmentContext);

  const firstEquipment = (data?.equipment || [])[0];
  const cargoInsurance = firstEquipment?.cargoInsurance;
  const cargoInsuranceLimit = firstEquipment?.cargoInsuranceLimit;
  const cargoInsuranceDeductible = firstEquipment?.cargoInsuranceDeductible;
  const cargoInsuranceReeferBreakdown = firstEquipment?.cargoInsuranceReeferBreakdown;

  const powerUnitsList = useMemo(() => {
    return data
      ? data?.equipment
          ?.filter((item) => item.equipmentType === EquipmentType.PowerUnit)
          .map((powerUnit) => {
            return { equipment: powerUnit, isEditing: false, editedFields: [], isNew: false, isDeleted: false };
          })
      : [];
  }, [data]);

  const trailersList = useMemo(() => {
    return data
      ? data?.equipment
          ?.filter((item) => item.equipmentType === EquipmentType.Trailer)
          .map((trailer) => {
            return { equipment: trailer, isEditing: false, editedFields: [], isNew: false, isDeleted: false };
          })
      : [];
  }, [data]);

  return (
    <>
      {isError && error && <PageAlert alertType={AlertType.error} message={error.message} />}
      <Grid item container justify="center" className={classes.container} alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            disableElevation
            startIcon={<EditIcon className={classes.editIcon} />}
            onClick={() => {
              updatePolicyCoverages({
                coverages: data
                  ? {
                      ...data.coverages,
                      cargoInsurance,
                      cargoInsuranceLimit,
                      cargoInsuranceDeductible,
                      cargoInsuranceReeferBreakdown,
                    }
                  : {},
                editedFields: [],
              });
              updatePolicyEquipment({ powerUnits: powerUnitsList!, trailers: trailersList! });
              history.push(routes.policyManagement.coverages.edit.path);
            }}
            className={classes.manageButton}
          >
            {t("policy-management-page.coverages-page.update-coverages-and-equipment")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={(_event, value) => setValue(value)}
            aria-label="Equipment Tabs"
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            <Tab label={t("enrollment.equipment-page.power-units-title")} className={classes.tab} />
            <Tab label={t("enrollment.equipment-page.trailers-title")} className={classes.tab} />
          </Tabs>
        </Grid>
        <Grid item>
          <TabPanel
            value={value}
            index={0}
            className={classNames(classes.tabPanel, { [classes.loadingPanel]: isLoading })}
          >
            <Grid container direction="row" justify="center" spacing={isSmall ? 2 : 5}>
              {powerUnitsList && powerUnitsList.length > 0
                ? powerUnitsList.map((powerUnit, index) => {
                    return (
                      <Grid item xs={12} sm={8} md={6} lg={4} key={`powerUnit-${index}=${powerUnit.equipment.id}`}>
                        <EquipmentCard equipment={powerUnit.equipment} equipmentIndex={index} />
                      </Grid>
                    );
                  })
                : isLoading && (
                    <Grid item>
                      <CircularProgress
                        color="primary"
                        size="2.5rem"
                        aria-label={t("common.circular-progress-aria-label")}
                      />
                    </Grid>
                  )}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tabPanel}>
            <Grid container direction="row" justify="center" spacing={isSmall ? 2 : 5}>
              {trailersList && trailersList.length > 0
                ? trailersList.map((trailer, index) => {
                    return (
                      <Grid item xs={12} sm={8} md={6} lg={4} key={`trailer-${index}-${trailer.equipment.id}`}>
                        <EquipmentCard equipment={trailer.equipment} equipmentIndex={index} />
                      </Grid>
                    );
                  })
                : isLoading && (
                    <Grid item>
                      <CircularProgress
                        color="primary"
                        size="2.5rem"
                        aria-label={t("common.circular-progress-aria-label")}
                      />
                    </Grid>
                  )}
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default EquipmentPage;
