import { ArrowDownBoldIcon as Arrow, EditableDeletableCard, format } from "@chq/components";
import { Button, createStyles, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { getTrendDirectionDown } from "../../utils/get-trend-direction-down";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      "& > .MuiGrid-root": {
        paddingTop: "1rem",
        paddingBottom: "0rem",
      },
      width: "100%",
    },
    policyNumberContainer: {
      marginBottom: "1rem",
      alignItems: "center",
    },
    scoreHeading: {
      marginBottom: "0.5rem",
    },
    green: {
      color: theme.palette.success.main,
    },
    red: {
      color: theme.palette.error.main,
    },
    redIcon: {
      color: theme.palette.error.main,
      transform: "rotate(180deg)",
    },
    icon: {
      width: "auto",
      height: "40px",
    },
    lastPaymentContainer: {
      marginBottom: "0.3125rem",
    },
    uppercase: {
      textTransform: "uppercase",
    },
    paymentsContainer: {
      marginTop: "1.25rem",
      marginBottom: "1.5rem",
    },
    paymentHistoryButton: {
      minHeight: "56px",
    },
    bottomMargin: {
      paddingBottom: "1rem",
    },
  }),
);

export type Props = {
  policyNumber: string;
  startCoverage?: Date;
  endCoverage?: Date;
  lastPayment: number;
  currentPayment: number;
  paymentHistoryEnabled: boolean;
};

const DashboardMainCard: React.FC<Props> = ({
  policyNumber,
  startCoverage,
  endCoverage,
  lastPayment,
  currentPayment,
  paymentHistoryEnabled,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const isTrendDirectionDown = getTrendDirectionDown(currentPayment, lastPayment);

  return (
    <EditableDeletableCard
      variant="default"
      className={classNames(classes.container, { [classes.bottomMargin]: !paymentHistoryEnabled })}
      border={false}
      elevation={1}
    >
      <Grid container item direction="row" className={classes.policyNumberContainer}>
        <Grid item xs={4}>
          <Typography variant="h4" color="textSecondary" className={classes.uppercase}>
            {t("policy-management-page.dashboard-page.main-card.policy-number")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h3">
            <strong>{policyNumber}</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid container item direction="row">
        <Grid item xs={4}>
          <Typography variant="h4" color="textSecondary" className={classes.uppercase}>
            {t("policy-management-page.dashboard-page.main-card.coverage")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {startCoverage && endCoverage && (
            <Typography variant="h3">{`${format(startCoverage)} - ${format(endCoverage)}`}</Typography>
          )}
        </Grid>
      </Grid>
      {paymentHistoryEnabled && (
        <>
          <Grid container item direction="row" justify="space-between" className={classes.paymentsContainer}>
            <Grid item container direction="column" alignItems="center" justify="space-between" xs={5}>
              <Grid item className={classes.scoreHeading}>
                <Typography color="textSecondary" variant="h4" className={classes.uppercase}>
                  {t("policy-management-page.dashboard-page.main-card.last-payment")}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.lastPaymentContainer}
              >
                <Typography variant="h1" component="h4" color="primary">
                  {t("common.currency", { money: lastPayment })}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" alignItems="center" xs={6}>
              <Grid item className={classes.scoreHeading}>
                <Typography color="textSecondary" variant="h4" className={classes.uppercase}>
                  {t("policy-management-page.dashboard-page.main-card.this-payment")}
                </Typography>
              </Grid>
              <Grid item container direction="row" justify="center" alignItems="center">
                <>
                  <Typography
                    variant="h1"
                    component="h4"
                    className={classNames({
                      [classes.green]: isTrendDirectionDown !== undefined && isTrendDirectionDown,
                      [classes.red]: isTrendDirectionDown !== undefined && !isTrendDirectionDown,
                    })}
                  >
                    {t("common.currency", { money: currentPayment })}
                  </Typography>
                  {currentPayment !== lastPayment && (
                    <Arrow
                      className={classNames(classes.icon, {
                        [classes.green]: isTrendDirectionDown !== undefined && isTrendDirectionDown,
                        [classes.redIcon]: isTrendDirectionDown !== undefined && !isTrendDirectionDown,
                      })}
                    />
                  )}
                </>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Button variant="text" fullWidth color="primary" className={classes.paymentHistoryButton}>
              {t("policy-management-page.dashboard-page.main-card.payment-history")}
            </Button>
          </Grid>
        </>
      )}
    </EditableDeletableCard>
  );
};

export default DashboardMainCard;
