import { Button, Divider, Grid, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { formatBankAccountNumber } from "../utils/format-bank-account-number";
import { Fields } from "./payment-method-form";

const useStyles = makeStyles((theme: Theme) => ({
  alternateText: {
    marginTop: "0.625rem",
    textTransform: "uppercase",
    textAlign: "center",
  },
  alternateLink: {
    display: "flex",
    justifyContent: "center",
    whiteSpace: "pre-wrap",
  },
  warningIcon: {
    color: theme.palette.success.main,
  },
  verifyAccountContainer: {
    backgroundColor: fade(theme.palette.success.main, 0.1),
    marginBottom: "1.25rem",
    padding: "0.625rem",
  },
  warningContainer: {
    display: "flex",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  accountNumberText: {
    color: theme.palette.grey[400],
  },
  connectYourAccountContainer: {
    padding: "0rem 0rem 0.5rem",
  },
  verifyContainer: {
    padding: "0.5rem 0.625rem",
    "& .MuiTypography-root": {
      textAlign: "center",
    },
  },
  accountNumberContainer: {
    padding: "0.625rem 0rem",
  },
  verifyAccountButton: {
    backgroundColor: theme.palette.common.white,
    minHeight: "56px",
    marginTop: "0.625rem",
  },
  bold: {
    fontWeight: "bold",
  },
  link: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

type Props = {
  accountNumber: string;
  onVerifyAccountDialogOpen: () => void;
};

const AddBankAccountCompleteCard: React.FC<Props> = ({ accountNumber, onVerifyAccountDialogOpen }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.verifyContainer}>
      <Grid container direction="column">
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.connectYourAccountContainer}
        >
          <Grid item>
            <Typography component="h3" variant="h4" className={classNames(classes.uppercase)}>
              {t(`finish-application.payment-method-form.${Fields.setUpBank}.button`)}
            </Typography>
          </Grid>
          <Grid item className={classes.warningContainer}>
            <WarningIcon
              className={classes.warningIcon}
              aria-label={t("finish-application.payment-method-form.verify-account.warning-icon-label")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container direction="row" justify="space-between" className={classes.accountNumberContainer}>
          <Grid item>
            <Typography variant="h4" className={classNames(classes.uppercase, classes.accountNumberText)}>
              {t("finish-application.payment-method-form.add-account-manual-form.account-number.label")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">&bull;&bull;&bull;&bull;{formatBankAccountNumber(accountNumber)}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Paper className={classes.verifyAccountContainer} square elevation={0}>
            <Typography variant="body1">
              {t("finish-application.payment-method-form.verify-account.verify-explanation")}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.verifyAccountButton}
              onClick={() => onVerifyAccountDialogOpen()}
            >
              {t("finish-application.payment-method-form.verify-account.verify-button")}
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item>
          <Typography variant="h4" className={classes.alternateText}>
            {t("finish-application.payment-method-form.plaid-failure.alternate-text")}
          </Typography>
        </Grid>
        <Grid item className={classes.alternateLink}>
          <Trans i18nKey={"finish-application.payment-method-form.plaid-failure.alternate-phone"}>
            <Typography variant="body1" className={classes.bold} component="span"></Typography>
            <Link href={"tel:866-621-4145"} className={classes.link}>
              finish-application.payment-method-form.plaid-failure.alternate-phone
            </Link>
          </Trans>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddBankAccountCompleteCard;
