import { FactoringApplication, FactoringApplicationApi, ModelApiResponse } from "@chq/factoring-api";
import { useMutation, UseMutationResult } from "react-query";
import { useFactoringApi } from "../useFactoringApi";

export const useCompleteFactoringApplication = (): UseMutationResult<
  ModelApiResponse,
  Error | unknown,
  FactoringApplication
> => {
  const factoringApplicationApi = useFactoringApi(FactoringApplicationApi);
  const mutation = useMutation(async (request: FactoringApplication) => {
    const response = await factoringApplicationApi.factoringApiV10FactoringApplicationCompleteApplicationPost();
    return response;
  });
  return mutation;
};
