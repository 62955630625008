import React, { createContext, ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};

interface FooterContext {
  footerOpen: boolean;
  setFooterOpen: (open: boolean) => void;
}

export const FooterFormContext = createContext<FooterContext | null>(null);

const FooterFormStateProvider: React.FC<Props> = ({ children }) => {
  const [footerFormOpen, setFooterFormOpen] = useState<boolean>(false);

  return (
    <FooterFormContext.Provider value={{ footerOpen: footerFormOpen, setFooterOpen: setFooterFormOpen }}>
      {children}
    </FooterFormContext.Provider>
  );
};

export default FooterFormStateProvider;
