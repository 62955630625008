import { Button, Fade, Grid, IconButton, Link, makeStyles, Modal, Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    height: 56,
    padding: "0 6rem",
    width: "100%",
  },
  buttonContain: {
    padding: ".75rem",
    boxShadow: "0px 1px 20px 5px rgba(0, 0, 0, 0.3)",
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  contentContain: {
    overflowY: "scroll",
    maxHeight: 620,
    padding: "1rem",
    "& ol": {
      padding: "0 0 0 1rem",
      "& > li": {
        padding: ".075rem 0",
      },
    },
  },
  modal: {
    background: theme.palette.common.white,
    borderRadius: 4,
    maxWidth: 576,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  modalContain: {
    position: "relative",
  },
}));

type Props = {
  onClose: () => void;
  open: boolean;
  buttonText: string;
  buttonLink?: string;
};

export const GeneralModal: React.FC<Props> = ({ onClose, open, children, buttonText, buttonLink }) => {
  const classes = useStyles();

  return (
    <Modal onClose={onClose} open={open} className={classes.modalContain}>
      <Fade in={open}>
        <Grid container direction="column" alignItems="center" wrap="nowrap" className={classes.modal}>
          <Grid container className={classes.contentContain}>
            <IconButton className={classes.closeButton}>
              <CloseIcon onClick={() => onClose()} />
            </IconButton>
            {children}
          </Grid>
          <Grid item xs={12} className={classes.buttonContain}>
            {buttonLink ? (
              <Button fullWidth color="primary" variant="contained" className={classes.button}>
                <Link target="_blank" rel="noopener" href={buttonLink}>
                  {buttonText}
                </Link>
              </Button>
            ) : (
              <Button onClick={onClose} fullWidth color="primary" variant="contained" className={classes.button}>
                {buttonText}
              </Button>
            )}
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default GeneralModal;
