/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BooleanApiResponse,
  BooleanApiResponseFromJSON,
  BooleanApiResponseToJSON,
  Equipment,
  EquipmentFromJSON,
  EquipmentToJSON,
  EquipmentApiResponse,
  EquipmentApiResponseFromJSON,
  EquipmentApiResponseToJSON,
} from "../models";

export interface ApiV10EquipmentDeleteRequest {
  id?: number;
}

export interface ApiV10EquipmentGetRequest {
  id?: number;
}

export interface ApiV10EquipmentGetMakeModelGetRequest {
  vin?: string;
}

export interface ApiV10EquipmentPostRequest {
  applicationId?: number;
  equipment?: Equipment;
}

export interface ApiV10EquipmentPutRequest {
  equipment?: Equipment;
}

/**
 *
 */
export class EquipmentApi extends runtime.BaseAPI {
  /**
   * Updates an Enrollment Application
   */
  async apiV10EquipmentDeleteRaw(
    requestParameters: ApiV10EquipmentDeleteRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Equipment`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10EquipmentDelete(requestParameters: ApiV10EquipmentDeleteRequest): Promise<BooleanApiResponse> {
    const response = await this.apiV10EquipmentDeleteRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10EquipmentGetRaw(
    requestParameters: ApiV10EquipmentGetRequest,
  ): Promise<runtime.ApiResponse<EquipmentApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Equipment`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentApiResponseFromJSON(jsonValue));
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10EquipmentGet(requestParameters: ApiV10EquipmentGetRequest): Promise<EquipmentApiResponse> {
    const response = await this.apiV10EquipmentGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Autofill Make and Model from VIN
   */
  async apiV10EquipmentGetMakeModelGetRaw(
    requestParameters: ApiV10EquipmentGetMakeModelGetRequest,
  ): Promise<runtime.ApiResponse<EquipmentApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.vin !== undefined) {
      queryParameters["vin"] = requestParameters.vin;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Equipment/getMakeModel`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentApiResponseFromJSON(jsonValue));
  }

  /**
   * Autofill Make and Model from VIN
   */
  async apiV10EquipmentGetMakeModelGet(
    requestParameters: ApiV10EquipmentGetMakeModelGetRequest,
  ): Promise<EquipmentApiResponse> {
    const response = await this.apiV10EquipmentGetMakeModelGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10EquipmentPostRaw(
    requestParameters: ApiV10EquipmentPostRequest,
  ): Promise<runtime.ApiResponse<EquipmentApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Equipment`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: EquipmentToJSON(requestParameters.equipment),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10EquipmentPost(requestParameters: ApiV10EquipmentPostRequest): Promise<EquipmentApiResponse> {
    const response = await this.apiV10EquipmentPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10EquipmentPutRaw(
    requestParameters: ApiV10EquipmentPutRequest,
  ): Promise<runtime.ApiResponse<EquipmentApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Equipment`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: EquipmentToJSON(requestParameters.equipment),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentApiResponseFromJSON(jsonValue));
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10EquipmentPut(requestParameters: ApiV10EquipmentPutRequest): Promise<EquipmentApiResponse> {
    const response = await this.apiV10EquipmentPutRaw(requestParameters);
    return await response.value();
  }
}
