export const radioValueToBoolean = (value?: string) => {
  try {
    if (value) {
      const number = parseInt(value);
      return Boolean(number);
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
