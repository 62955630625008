import { theme } from "@chq/styles";
import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import CardField from "./card-field";
import EditableDeletableCard, { EditableDeletableCardProps } from "./editable-deletable-card";

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    background: theme.palette.grey[600],
    border: `1px ${theme.palette.grey[500]} solid`,
  },
}));

type Props = {
  documents: string[];
  title: string;
  editHelperText: string;
  onEdit: EditableDeletableCardProps["onEdit"];
};

const DocumentCard: React.FC<Props> = ({ documents, title, editHelperText, onEdit }) => {
  const classes = useStyles();
  return (
    <EditableDeletableCard
      title={title}
      variant="edit"
      titleVariant="h2"
      titleComponent="h4"
      onEdit={onEdit}
      border={false}
      className={classes.container}
      IconButtonProps={{ "aria-label": editHelperText }}
    >
      <Grid container>
        {documents.map((document, index) => {
          return (
            <CardField
              key={`document-${index}-${document}`}
              id={`document-${index}-${document}`}
              label={document}
              labelColor="textPrimary"
            />
          );
        })}
      </Grid>
    </EditableDeletableCard>
  );
};

export default DocumentCard;
