import {
  fade,
  Grid,
  IconButton,
  IconButtonProps,
  makeStyles,
  Paper,
  PaperProps,
  Theme,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import classNames from "classnames";
import React, { ElementType } from "react";
import { DeleteIcon, PencilIcon } from "./icons";
import { variantToComponent } from "./utils/title-variant-map";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "0rem 0.625rem 1.25rem 0.625rem",
  },
  containerBorder: {
    border: `1px ${theme.palette.grey[500]} solid`,
  },
  titleError: {
    color: theme.palette.error.main,
  },
  iconButton: {
    padding: "0px",
  },
  icon: {
    height: "40px",
    width: "auto",
  },

  squareIcon: {
    borderRadius: "0%",
    paddingLeft: "0.75rem",
  },
  banner: {
    width: "100%",
    height: "1.25rem",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.common.white,
    borderRadius: ".2rem .2rem 0rem 0rem",
    textTransform: "uppercase",
    paddingTop: "0.125rem",
  },
  blue: {
    color: theme.palette.primary.main,
  },
  blueBanner: {
    background: theme.palette.primary.main,
  },
  blueBackground: {
    backgroundColor: fade(theme.palette.secondary.contrastText, 0.2),
  },
  redBanner: {
    background: theme.palette.error.main,
  },
  redBackground: {
    backgroundColor: fade(theme.palette.error.main, 0.05),
  },
  deletedTitle: {
    minHeight: "2.5rem",
  },
}));

export type EditableDeletableCardProps = Omit<PaperProps, "elevation" | "variant"> & {
  title?: React.ReactNode;
  titleElement?: JSX.Element;
  titleClass?: string;
  variant: "edit" | "delete" | "default" | "error";
  editText?: string;
  editTextVariant?: TypographyProps["variant"];
  blueEdit?: boolean;
  titleVariant?: TypographyProps["variant"];
  titleComponent?: ElementType;
  elevation?: PaperProps["elevation"];
  border?: boolean;
  error?: boolean;
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  IconButtonProps?: Omit<IconButtonProps, "onClick">;
  bannerText?: string;
  containerClass?: string;
  bannerVariant?: "primary" | "error";
};

const EditableDeletableCard: React.FC<EditableDeletableCardProps> = ({
  className,
  title,
  titleClass,
  error = false,
  onDelete,
  onEdit,
  IconButtonProps,
  children,
  variant,
  editText,
  editTextVariant = "h3",
  blueEdit = false,
  elevation = variant === "edit" ? 1 : 0,
  border = true,
  titleVariant,
  titleElement,
  titleComponent,
  bannerText,
  containerClass,
  bannerVariant,
  ...PaperProps
}) => {
  const classes = useStyles();
  const hasBanner = Boolean(bannerText);

  if (!titleComponent && titleVariant) {
    titleComponent = variantToComponent(titleVariant) as ElementType;
  }

  return (
    <Paper
      elevation={elevation}
      className={classNames(className, { [classes.containerBorder]: border })}
      {...PaperProps}
    >
      {hasBanner && (
        <Typography
          variant="h4"
          className={classNames(classes.banner, {
            [classes.blueBanner]: bannerVariant === "primary",
            [classes.redBanner]: bannerVariant === "error",
          })}
        >
          {bannerText}
        </Typography>
      )}
      <Grid
        container
        direction="column"
        className={classNames(classes.container, containerClass, {
          [classes.redBackground]: bannerVariant === "error",
          [classes.blueBackground]: bannerVariant === "primary",
        })}
      >
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classNames({ [classes.deletedTitle]: bannerVariant === "error" })}
        >
          {titleElement && (
            <Grid item xs={title ? 6 : 12}>
              {titleElement}
            </Grid>
          )}
          {title && (
            <Grid item>
              <Typography
                variant={titleVariant ? titleVariant : "h4"}
                component={titleComponent ? titleComponent : "h4"}
                className={classNames(titleClass, {
                  [classes.titleError]: error,
                })}
              >
                {title}
              </Typography>
            </Grid>
          )}
          <Grid item>
            {variant === "delete" && (
              <IconButton
                className={classNames(classes.iconButton, IconButtonProps?.className)}
                onClick={onDelete}
                {...IconButtonProps}
              >
                <DeleteIcon color="disabled" className={classes.icon} />
              </IconButton>
            )}
            {variant === "edit" && (
              <IconButton
                className={classNames(classes.iconButton, IconButtonProps?.className, {
                  [classes.squareIcon]: Boolean(editText),
                })}
                onClick={onEdit}
                {...IconButtonProps}
              >
                {Boolean(editText) && (
                  <Typography
                    variant={editTextVariant}
                    component="p"
                    className={classNames({ [classes.blue]: blueEdit })}
                  >
                    {editText}
                  </Typography>
                )}
                <PencilIcon color={blueEdit ? "primary" : "disabled"} className={classes.icon} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        {children}
      </Grid>
    </Paper>
  );
};

export default EditableDeletableCard;
