import { StripeApi, StripeCustomer } from "@chq/enrollment-api";
import { useQuery, UseQueryResult } from "react-query";
import { useApi } from "../useApi";

export const cacheKey = "get-current-stripe-customer";

export const useGetCurrentStripeCustomer = (): UseQueryResult<StripeCustomer, Error | unknown> => {
  const stripeApi = useApi(StripeApi);

  const query = useQuery(cacheKey, async () => {
    const response = await stripeApi.apiV10StripeGetCustomerGet();

    return response.data!;
  });

  return query;
};
