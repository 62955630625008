import { EditableDeletableCard } from "@chq/components";
import { Button, fade, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";
import { useTranslation } from "react-i18next";

export type Props = {
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  titleSection: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  icon: {
    display: "inline",
  },
  title: {
    textAlign: "left",
    display: "inline",
    maxWidth: "60%",
    marginLeft: "0.5rem",
  },
  button: {
    boxShadow: `0px 1px 4px ${fade(theme.palette.common.black, 0.1)}`,
    padding: "1rem",
    fontSize: "1rem",
    fontWeight: 700,
  },
  link: {
    fontSize: "1rem",
    fontWeight: 700,
    textDecoration: "none",
  },
}));

const AddDotCard: React.FC<Props> = ({ onClick }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <EditableDeletableCard border={false} variant="default">
      <Grid container justify="center" alignItems="center">
        <Grid item container className={classes.titleSection} xs={12} sm={8} justify="center">
          <Grid item>
            <WarningIcon color="error" className={classes.icon} />{" "}
          </Grid>
          <Typography variant="h2" component="h2" color="error" className={classes.title}>
            {t("add-dot-card.title")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button fullWidth variant="outlined" color="primary" className={classes.button} onClick={onClick}>
            {t("add-dot-card.link")}
          </Button>
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default AddDotCard;
