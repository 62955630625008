/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Application,
  ApplicationFromJSON,
  ApplicationToJSON,
  ApplicationApiResponse,
  ApplicationApiResponseFromJSON,
  ApplicationApiResponseToJSON,
  ApplicationApiResponseWithDefaults,
  ApplicationApiResponseWithDefaultsFromJSON,
  ApplicationApiResponseWithDefaultsToJSON,
  ApplicationCheckApiResponse,
  ApplicationCheckApiResponseFromJSON,
  ApplicationCheckApiResponseToJSON,
  ApplicationCreateRequest,
  ApplicationCreateRequestFromJSON,
  ApplicationCreateRequestToJSON,
  ApplicationReviewApiResponse,
  ApplicationReviewApiResponseFromJSON,
  ApplicationReviewApiResponseToJSON,
  BooleanApiResponse,
  BooleanApiResponseFromJSON,
  BooleanApiResponseToJSON,
  TermsAndConditionsLinksApiResponse,
  TermsAndConditionsLinksApiResponseFromJSON,
  TermsAndConditionsLinksApiResponseToJSON,
} from "../models";

export interface ApiV10ApplicationCargoFormPostRequest {
  applicationId?: number;
}

export interface ApiV10ApplicationDoesApplicationExistGetRequest {
  emailAddress?: string;
}

export interface ApiV10ApplicationPasswordResetPostRequest {
  email?: string;
}

export interface ApiV10ApplicationPostRequest {
  applicationCreateRequest?: ApplicationCreateRequest;
}

export interface ApiV10ApplicationPutRequest {
  application?: Application;
}

export interface ApiV10ApplicationQuoteBreakdownPostRequest {
  applicationId?: number;
}

export interface ApiV10ApplicationResumeApplicationPostRequest {
  email?: string;
}

export interface ApiV10ApplicationSendGeotabRequestEmailPostRequest {
  applicationId?: number;
}

export interface ApiV10ApplicationSendHelpEmailPostRequest {
  name?: string;
  emailAddress?: string;
  phoneNumber?: string;
  problem?: string;
  description?: string;
}

export interface ApiV10ApplicationSignCargoFormPostRequest {
  applicationId?: number;
}

export interface ApiV10ApplicationSignEConsentPostRequest {
  applicationId?: number;
}

export interface ApiV10ApplicationSubmitApplicationPutRequest {
  application?: Application;
}

export interface ApiV10ApplicationUpdateApplicationStatePutRequest {
  applicationState?: string;
}

/**
 *
 */
export class ApplicationApi extends runtime.BaseAPI {
  /**
   */
  async apiV10ApplicationCargoFormPostRaw(
    requestParameters: ApiV10ApplicationCargoFormPostRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/CargoForm`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10ApplicationCargoFormPost(
    requestParameters: ApiV10ApplicationCargoFormPostRequest,
  ): Promise<ApplicationApiResponse> {
    const response = await this.apiV10ApplicationCargoFormPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10ApplicationDoesApplicationExistGetRaw(
    requestParameters: ApiV10ApplicationDoesApplicationExistGetRequest,
  ): Promise<runtime.ApiResponse<ApplicationCheckApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/DoesApplicationExist`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationCheckApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10ApplicationDoesApplicationExistGet(
    requestParameters: ApiV10ApplicationDoesApplicationExistGetRequest,
  ): Promise<ApplicationCheckApiResponse> {
    const response = await this.apiV10ApplicationDoesApplicationExistGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Gets the application for the current user
   */
  async apiV10ApplicationGetRaw(): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets the application for the current user
   */
  async apiV10ApplicationGet(): Promise<ApplicationApiResponse> {
    const response = await this.apiV10ApplicationGetRaw();
    return await response.value();
  }

  /**
   * Returns info related to the application for the user to review
   */
  async apiV10ApplicationGetApplicationReviewGetRaw(): Promise<runtime.ApiResponse<ApplicationReviewApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/getApplicationReview`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationReviewApiResponseFromJSON(jsonValue));
  }

  /**
   * Returns info related to the application for the user to review
   */
  async apiV10ApplicationGetApplicationReviewGet(): Promise<ApplicationReviewApiResponse> {
    const response = await this.apiV10ApplicationGetApplicationReviewGetRaw();
    return await response.value();
  }

  /**
   * Gets links to various terms and policy documents in S3
   */
  async apiV10ApplicationGetTermsOfUseGetRaw(): Promise<runtime.ApiResponse<TermsAndConditionsLinksApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/GetTermsOfUse`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => TermsAndConditionsLinksApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets links to various terms and policy documents in S3
   */
  async apiV10ApplicationGetTermsOfUseGet(): Promise<TermsAndConditionsLinksApiResponse> {
    const response = await this.apiV10ApplicationGetTermsOfUseGetRaw();
    return await response.value();
  }

  /**
   * Send a \"reset password\" link to the user
   */
  async apiV10ApplicationPasswordResetPostRaw(
    requestParameters: ApiV10ApplicationPasswordResetPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.email !== undefined) {
      queryParameters["email"] = requestParameters.email;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/passwordReset`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   * Send a \"reset password\" link to the user
   */
  async apiV10ApplicationPasswordResetPost(
    requestParameters: ApiV10ApplicationPasswordResetPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10ApplicationPasswordResetPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Creates a new Application, owner and business  This is the very first call to the backend
   */
  async apiV10ApplicationPostRaw(
    requestParameters: ApiV10ApplicationPostRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ApplicationCreateRequestToJSON(requestParameters.applicationCreateRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Creates a new Application, owner and business  This is the very first call to the backend
   */
  async apiV10ApplicationPost(requestParameters: ApiV10ApplicationPostRequest): Promise<ApplicationApiResponse> {
    const response = await this.apiV10ApplicationPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10ApplicationPutRaw(
    requestParameters: ApiV10ApplicationPutRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponseWithDefaults>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ApplicationToJSON(requestParameters.application),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseWithDefaultsFromJSON(jsonValue));
  }

  /**
   * Updates an Enrollment Application
   */
  async apiV10ApplicationPut(
    requestParameters: ApiV10ApplicationPutRequest,
  ): Promise<ApplicationApiResponseWithDefaults> {
    const response = await this.apiV10ApplicationPutRaw(requestParameters);
    return await response.value();
  }

  /**
   * Send a quote breakdown to the user
   */
  async apiV10ApplicationQuoteBreakdownPostRaw(
    requestParameters: ApiV10ApplicationQuoteBreakdownPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/quoteBreakdown`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   * Send a quote breakdown to the user
   */
  async apiV10ApplicationQuoteBreakdownPost(
    requestParameters: ApiV10ApplicationQuoteBreakdownPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10ApplicationQuoteBreakdownPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Send a \"resume application\" link to the user
   */
  async apiV10ApplicationResumeApplicationPostRaw(
    requestParameters: ApiV10ApplicationResumeApplicationPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.email !== undefined) {
      queryParameters["email"] = requestParameters.email;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/resumeApplication`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   * Send a \"resume application\" link to the user
   */
  async apiV10ApplicationResumeApplicationPost(
    requestParameters: ApiV10ApplicationResumeApplicationPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10ApplicationResumeApplicationPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Sends a email to request a geotab for the given application
   */
  async apiV10ApplicationSendGeotabRequestEmailPostRaw(
    requestParameters: ApiV10ApplicationSendGeotabRequestEmailPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/SendGeotabRequestEmail`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   * Sends a email to request a geotab for the given application
   */
  async apiV10ApplicationSendGeotabRequestEmailPost(
    requestParameters: ApiV10ApplicationSendGeotabRequestEmailPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10ApplicationSendGeotabRequestEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10ApplicationSendHelpEmailPostRaw(
    requestParameters: ApiV10ApplicationSendHelpEmailPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    if (requestParameters.phoneNumber !== undefined) {
      queryParameters["phoneNumber"] = requestParameters.phoneNumber;
    }

    if (requestParameters.problem !== undefined) {
      queryParameters["problem"] = requestParameters.problem;
    }

    if (requestParameters.description !== undefined) {
      queryParameters["description"] = requestParameters.description;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/SendHelpEmail`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10ApplicationSendHelpEmailPost(
    requestParameters: ApiV10ApplicationSendHelpEmailPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10ApplicationSendHelpEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10ApplicationSignCargoFormPostRaw(
    requestParameters: ApiV10ApplicationSignCargoFormPostRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/SignCargoForm`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10ApplicationSignCargoFormPost(
    requestParameters: ApiV10ApplicationSignCargoFormPostRequest,
  ): Promise<ApplicationApiResponse> {
    const response = await this.apiV10ApplicationSignCargoFormPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10ApplicationSignEConsentPostRaw(
    requestParameters: ApiV10ApplicationSignEConsentPostRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationId !== undefined) {
      queryParameters["applicationId"] = requestParameters.applicationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/SignEConsent`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10ApplicationSignEConsentPost(
    requestParameters: ApiV10ApplicationSignEConsentPostRequest,
  ): Promise<ApplicationApiResponse> {
    const response = await this.apiV10ApplicationSignEConsentPostRaw(requestParameters);
    return await response.value();
  }

  /**
   * Submits an Enrollment Application
   */
  async apiV10ApplicationSubmitApplicationPutRaw(
    requestParameters: ApiV10ApplicationSubmitApplicationPutRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/submitApplication`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ApplicationToJSON(requestParameters.application),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Submits an Enrollment Application
   */
  async apiV10ApplicationSubmitApplicationPut(
    requestParameters: ApiV10ApplicationSubmitApplicationPutRequest,
  ): Promise<ApplicationApiResponse> {
    const response = await this.apiV10ApplicationSubmitApplicationPutRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates the current application state
   */
  async apiV10ApplicationUpdateApplicationStatePutRaw(
    requestParameters: ApiV10ApplicationUpdateApplicationStatePutRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.applicationState !== undefined) {
      queryParameters["applicationState"] = requestParameters.applicationState;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", ["api1"]);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Application/UpdateApplicationState`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Updates the current application state
   */
  async apiV10ApplicationUpdateApplicationStatePut(
    requestParameters: ApiV10ApplicationUpdateApplicationStatePutRequest,
  ): Promise<ApplicationApiResponse> {
    const response = await this.apiV10ApplicationUpdateApplicationStatePutRaw(requestParameters);
    return await response.value();
  }
}
