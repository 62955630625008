import { FactoringApplication, FactoringApplicationApi } from "@chq/factoring-api";
import { useCallback } from "react";
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "react-query";
import { useFactoringApi } from "../useFactoringApi";

const getCurrentFactoringApplicationCacheKey = () => "current-factoring-application";

export const useGetFactoringApplication = (
  options?: UseQueryOptions<FactoringApplication, Error>,
): UseQueryResult<FactoringApplication, Error> => {
  const factoringApplicationApi = useFactoringApi(FactoringApplicationApi);
  const query = useQuery<FactoringApplication, Error>(
    getCurrentFactoringApplicationCacheKey(),
    async () => {
      const response = await factoringApplicationApi.factoringApiV10FactoringApplicationGetApplicationForCurrentUserGet();
      if (response.error) {
        throw new Error(response.error.message || "Unable to find factoring application");
      }
      return response.data!;
    },
    options,
  );
  return query;
};

export const useGetCachedFactoringApplication = () => {
  const cache = useQueryClient();
  return cache.getQueryData<FactoringApplication>(getCurrentFactoringApplicationCacheKey());
};

export const useUpdateCachedFactoringApplication = () => {
  const cache = useQueryClient();

  return useCallback(
    (application: FactoringApplication) => {
      cache.setQueryData(getCurrentFactoringApplicationCacheKey(), application);
    },
    [cache],
  );
};

export const useCachedFactoringApplication = () => {
  const application = useGetCachedFactoringApplication();
  const updateApplication = useUpdateCachedFactoringApplication();
  return { application, updateApplication };
};
