/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Factoring API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  FactoringApplication,
  FactoringApplicationFromJSON,
  FactoringApplicationFromJSONTyped,
  FactoringApplicationToJSON,
} from "./";

/**
 *
 * @export
 * @interface FactoringApplicationApiResponse
 */
export interface FactoringApplicationApiResponse {
  /**
   *
   * @type {FactoringApplication}
   * @memberof FactoringApplicationApiResponse
   */
  data?: FactoringApplication;
  /**
   *
   * @type {ErrorResponse}
   * @memberof FactoringApplicationApiResponse
   */
  error?: ErrorResponse;
}

export function FactoringApplicationApiResponseFromJSON(json: any): FactoringApplicationApiResponse {
  return FactoringApplicationApiResponseFromJSONTyped(json, false);
}

export function FactoringApplicationApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FactoringApplicationApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : FactoringApplicationFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function FactoringApplicationApiResponseToJSON(value?: FactoringApplicationApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: FactoringApplicationToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
