import { LegalEntity } from "@chq/enrollment-api";

export const useLegalEntityTypes = (): LegalEntity[] => {
  return [
    LegalEntity.Partnership,
    LegalEntity.LLC,
    LegalEntity.Corporation,
    LegalEntity.DontKnow,
    LegalEntity.SoleProprietor,
  ];
};
