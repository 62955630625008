import qs from "qs";
import { Redirect } from "react-router-dom";
import { routes } from "../ui/routes";

export enum Reason {
  dotNum = 0,
  state,
  powerUnit,
  stateAndPowerUnits,
}

export const qualifyRedirect = (message: string) => {
  if (message?.includes("state at this time.\nThe number of")) {
    return (
      <Redirect
        to={{
          pathname: routes.enrollment.notQualify.path,
          search: qs.stringify({ reason: Reason.stateAndPowerUnits }),
        }}
      />
    );
  } else if (message?.includes("state at this time")) {
    return (
      <Redirect
        push
        to={{ pathname: routes.enrollment.notQualify.path, search: qs.stringify({ reason: Reason.state }) }}
      />
    );
  } else if (message?.includes("power units")) {
    return (
      <Redirect
        push
        to={{ pathname: routes.enrollment.notQualify.path, search: qs.stringify({ reason: Reason.powerUnit }) }}
      />
    );
  } else {
    return (
      <Redirect
        push
        to={{ pathname: routes.enrollment.notQualify.path, search: qs.stringify({ reason: Reason.dotNum }) }}
      />
    );
  }
};
