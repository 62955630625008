import { ClickAwayListener, makeStyles, SvgIconProps, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import DarkTooltip, { DarkTooltipProps } from "./dark-tooltip";
import { HelpCircleOutlineIcon } from "./icons";

const HelpCircleOutlineIconWithRef = React.forwardRef<HTMLInputElement, SvgIconProps>(
  function HelpCircleOutlineIconWithRef(props, ref) {
    return (
      <div ref={ref}>
        <HelpCircleOutlineIcon {...props} />
      </div>
    );
  },
);

type Props = Omit<DarkTooltipProps, "children">;

const useStyles = makeStyles((theme: Theme) => ({
  tooltipText: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
}));

const TooltipHelp = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  const formattedTitle =
    typeof props.title === "string" ? (
      <Typography variant="h4" className={classes.tooltipText}>
        {props.title}
      </Typography>
    ) : (
      props.title
    );

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <DarkTooltip {...props} title={formattedTitle} open={open}>
        <HelpCircleOutlineIconWithRef onClick={() => setOpen(true)} />
      </DarkTooltip>
    </ClickAwayListener>
  );
};

export default TooltipHelp;
