export interface OIDCConfiguration {
  authority: string;
  clientId: string;
}

export const useOIDCConfiguration = (): OIDCConfiguration => {
  const config: Partial<OIDCConfiguration> = {
    authority: window?.env?.REACT_APP_OIDC_AUTHORITY || "",
    clientId: window?.env?.REACT_APP_OIDC_CLIENT_ID || "",
  };

  for (const [key, value] of Object.entries(config)) {
    if (value === undefined) {
      throw new Error(`${key} must be defined in env.js`);
    }
  }

  return config as OIDCConfiguration;
};
