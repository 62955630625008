import { Drawer, DrawerProps } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";

export const DRAWER_WIDTH = "22.5rem";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    flexShrink: 0,
    "& .MuiPaper-root": {
      position: "absolute",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        width: DRAWER_WIDTH,
      },
    },
  },
}));

const HamburgerMenu: React.FC<DrawerProps> = ({
  open,
  variant = "persistent",
  anchor = "left",
  className,
  children,
  ...drawerProps
}) => {
  const classes = useStyles();
  return (
    <Drawer
      variant={variant}
      anchor={anchor}
      open={open}
      className={classNames(classes.drawer, className)}
      {...drawerProps}
    >
      {children}
    </Drawer>
  );
};

export default HamburgerMenu;
