/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AccountInfo,
  AccountInfoFromJSON,
  AccountInfoFromJSONTyped,
  AccountInfoToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface AccountInfoIEnumerableApiResponse
 */
export interface AccountInfoIEnumerableApiResponse {
  /**
   *
   * @type {Array<AccountInfo>}
   * @memberof AccountInfoIEnumerableApiResponse
   */
  data?: Array<AccountInfo> | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof AccountInfoIEnumerableApiResponse
   */
  error?: ErrorResponse;
}

export function AccountInfoIEnumerableApiResponseFromJSON(json: any): AccountInfoIEnumerableApiResponse {
  return AccountInfoIEnumerableApiResponseFromJSONTyped(json, false);
}

export function AccountInfoIEnumerableApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AccountInfoIEnumerableApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
      ? null
      : (json["data"] as Array<any>).map(AccountInfoFromJSON),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function AccountInfoIEnumerableApiResponseToJSON(value?: AccountInfoIEnumerableApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
        ? null
        : (value.data as Array<any>).map(AccountInfoToJSON),
    error: ErrorResponseToJSON(value.error),
  };
}
