/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface StripeCharge
 */
export interface StripeCharge {
  /**
   *
   * @type {string}
   * @memberof StripeCharge
   */
  id?: string | null;
  /**
   *
   * @type {number}
   * @memberof StripeCharge
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof StripeCharge
   */
  customerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeCharge
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeCharge
   */
  status?: string | null;
}

export function StripeChargeFromJSON(json: any): StripeCharge {
  return StripeChargeFromJSONTyped(json, false);
}

export function StripeChargeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeCharge {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    amount: !exists(json, "amount") ? undefined : json["amount"],
    customerId: !exists(json, "customerId") ? undefined : json["customerId"],
    description: !exists(json, "description") ? undefined : json["description"],
    status: !exists(json, "status") ? undefined : json["status"],
  };
}

export function StripeChargeToJSON(value?: StripeCharge | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    amount: value.amount,
    customerId: value.customerId,
    description: value.description,
    status: value.status,
  };
}
